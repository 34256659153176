export const DownloadIcon = () => {
  return (
    <svg
      xmlns="
http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-download"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      stroke-width="1"
      stroke="#000000"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
      <path d="M7 11l5 5l5 -5" />
      <path d="M12 4l0 12" />
    </svg>
  );
};

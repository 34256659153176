import React from "react";

const NewClaimIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="🔍-System-Icons"
        stroke="none"
        stroke-width="1"
        fill-rule="evenodd"
      >
        <g id="ic_fluent_new_24_regular" fill="#212121" fill-rule="nonzero">
          <path
            fill="#007a56"
            d="M13.25,17.5 C13.6642,17.5 14,17.8358 14,18.25 C14,18.6642 13.6642,19 13.25,19 L4.75,19 C4.33579,19 4,18.6642 4,18.25 C4,17.8358 4.33579,17.5 4.75,17.5 L13.25,17.5 Z M7.21967,7.21967 C7.48593364,6.95340636 7.90260223,6.92920058 8.19621222,7.14705264 L8.28033,7.21967 L14.7803,13.7197 C15.0732,14.0126 15.0732,14.4874 14.7803,14.7803 C14.5140273,15.0465727 14.097424,15.0707793 13.8038184,14.8529198 L13.7197,14.7803 L7.21967,8.28033 C6.92678,7.98744 6.92678,7.51256 7.21967,7.21967 Z M18.25,4 C18.6642,4 19,4.33579 19,4.75 L19,13.25 C19,13.6642 18.6642,14 18.25,14 C17.8358,14 17.5,13.6642 17.5,13.25 L17.5,4.75 C17.5,4.33579 17.8358,4 18.25,4 Z"
            id="🎨-Color"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default NewClaimIcon;

import { createSlice } from "@reduxjs/toolkit";
import {
  resetListingState,
  setListingState,
} from "../filters/claimManagerFilters";
import { toast } from "react-toastify";

import {
  commonPagination,
  getMonthNumbersAndYear,
  getMultiSelectFilterValueForApi,
} from "../../../components/common/Utils/utils";
import { serverRoutes } from "../../../constants/serverRoutes";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";

export const AiDashboardSlice = createSlice({
  name: "AiDashboardSlice",
  initialState: {
    aiDashboardDataObj: [
      {
        denailAmount: 0,
        denailCount: 0,
        missedOpprAmount: 0,
        missedOpprAmountPer: 0,
        missedOpprCount: 0,
        missedOpprCountPer: 0,
      },
    ],
    availableHospitalsList: [],
  },
  reducers: {
    setAIDashboardTabular_dataAction: (state, action) => {
      let data = action.payload;

      state.aiDashboardDataObj = data?.aiData?.length ? data?.aiData[0] : {};
      //Hospital List
      if (data?.hospitalList && data?.hospitalList?.length > 0) {
        let newArr = [];
        data?.hospitalList?.forEach((item) => {
          if (item?.displayName && item?.payeeId) {
            newArr.push({
              label: item?.displayName,
              value: item?.hospital,
            });
          }
        });
        state.availableHospitalsList = newArr;
      } else {
        state.availableHospitalsList = [];
      }
    },
  },
});

export const { setAIDashboardTabular_dataAction } = AiDashboardSlice.actions;

// get dataSources list
export const gettingAiDashboardTabularData_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "aiDashboardFilter",
          //   ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.aiDashboardFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      dispatch(
        setListingState({
          listing: "aiDashboardFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      let params1 = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
      };

      let result = getMonthNumbersAndYear(params1);

      let params = {
        ...(result?.monthNumbers !== "" && {
          Month: result?.monthNumbers,
        }),
        ...(result?.year !== "" && {
          Year: result?.year,
        }),
        ...{
          filter: sliceState.dateMainState == "Months" ? "m" : "y",
        },
        ...(sliceState?.selHospitalsArr?.length > 0 && {
          hospitals: getMultiSelectFilterValueForApi(
            sliceState?.selHospitalsArr
          ),
        }),
      };

      const res = await genericAxiosCall(
        `${serverRoutes?.GET_AI_DASHBOARD_SCREEN}`,
        "get",
        "",
        params
      );

      if (res?.data?.success) {
        dispatch(setAIDashboardTabular_dataAction(res?.data?.data));
      } else {
        dispatch(setAIDashboardTabular_dataAction([]));
        toast.error(`Somthing Went Wrong`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      // commonPagination(
      //   getState,
      //   paramsTemp,
      //   params,
      //   dispatch,
      //   setAIDashboardTabular_dataAction,
      //   'aiDashboardFilter',
      //   'GET_AI_DASHBOARD_SCREEN',
      //   null
      // );
    } catch (e) {
      // console.log("e--->>", e);
      toast.error(`${e?.response?.data?.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      dispatch(
        setListingState({
          listing: "aiDashboardFilter",
          value: {
            loadingMore: "",
          },
        })
      );
    }
  };

export default AiDashboardSlice.reducer;

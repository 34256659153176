import { msalInstance } from "../..";
import { getApplicationToken } from "../../components/common/Utils/utils";
import browserRoute from "../../constants/browserRoutes";
import {
  logoutUser,
  updateApplicationToken,
  updateUserToken,
} from "../../Redux/Reducers/UserReducer";
import store from "../../Redux/store";
const singleLogout = (dispatch) => {
  // window.localStorage.clear();
  // console.log("Logout_func--->>");

  const { loginType } = store.getState().pReducers.user;

  store.dispatch(updateApplicationToken(""));

  store.dispatch(updateUserToken(""));
  store.dispatch(logoutUser());

  // just for track user history
  // saveLogForLogActivity("Successfully logged out...");

  window.localStorage.removeItem("persist:root");
  localStorage.removeItem("NoOfRecordInTable");
  window.localStorage.setItem("logged_in", false);
  window.localStorage.clear();

  if (loginType === "Azure AD") {
    msalInstance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  } else {
    window.location.href = "/";
  }
  // setUserToken("");

  // history.push("/");
  // window.location.href = "/";
};
export const singleLogout3 = (dispatch) => {
  // window.localStorage.clear();
  // console.log("Logout_func--->>");

  const { loginType } = store.getState().pReducers.user;
  const redirect = getApplicationToken();
  store.dispatch(updateApplicationToken(""));

  store.dispatch(updateUserToken(""));
  store.dispatch(logoutUser());

  // just for track user history
  // saveLogForLogActivity("Successfully logged out...");

  window.localStorage.removeItem("persist:root");
  localStorage.removeItem("NoOfRecordInTable");
  window.localStorage.setItem("logged_in", false);
  window.localStorage.clear();
  if (redirect) {
    window.location.href = browserRoute?.SESSION_TIMEOUT;
  } else {
    window.localStorage.clear();
    window.location.href = browserRoute?.SESSION_TIMEOUT;
  }

  // if (loginType === "Azure AD") {
  //   msalInstance.logoutRedirect({
  //     postLogoutRedirectUri: "/",
  //   });
  // } else {
  //   window.location.href = "/";
  // }
  // setUserToken("");

  // history.push("/");
  // window.location.href = "/";
};

export const singleLogout2 = (dispatch) => {
  const { loginType } = store.getState().pReducers.user;

  store.dispatch(updateApplicationToken(""));

  store.dispatch(updateUserToken(""));
  store.dispatch(logoutUser());
  // just for track user history
  // saveLogForLogActivity("Successfully logged out...");

  window.localStorage.removeItem("persist:root");
  localStorage.removeItem("NoOfRecordInTable");
  window.localStorage.setItem("logged_in", false);
  window.localStorage.clear();

  // if (loginType === "Azure AD") {
  //   msalInstance.logoutRedirect({
  //     postLogoutRedirectUri: "/sessionTimeout",
  //   });
  // } else {
  //   window.location.href = "/sessionTimeout";
  // }
  window.location.href = "/sessionTimeout";

  // setUserToken("");

  // history.push("/");
  // window.location.href = "/";
};

export default singleLogout;

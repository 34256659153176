import React from "react";
import { Box, Fade, Paper, Popper } from "@mui/material";

const RichTooltip = ({
  placement = "top",
  arrow = true,
  open,
  setOpen,
  onClose = () => {},
  content,
  children,
}) => {
  // const classes = useStyles();
  const [arrowRef, setArrowRef] = React.useState(null);
  const [childNode, setChildNode] = React.useState(null);

  const refOutside = React.useRef(null);

  const handleClickOutside = ({ target }) => {
    if (!refOutside.current?.contains(target)) {
      setOpen(false);

      // console.log("click outside");
    } else {
      // console.log("click inside");
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, []);

  return (
    <div>
      {React.cloneElement(children, { ...children.props, ref: setChildNode })}
      <Popper
        open={open}
        anchorEl={childNode}
        placement={placement}
        transition
        className="Mui-popper-custom"
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: "window",
          },
          arrow: {
            enabled: arrow,
            element: arrowRef,
          },
        }}
        ref={refOutside}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              {/* <ClickAwayListener onClickAway={onClose}> */}
              <Paper className="popover-main">
                {arrow ? (
                  <span className="popover-arrow" ref={setArrowRef} />
                ) : null}
                <Box
                  className="p-4 mt-4"
                  style={{
                    backgroundColor: "#fff",
                    boxShadow: "0px 0px 50px #d9d9d9",
                  }}
                >
                  {content}
                </Box>
              </Paper>
              {/* </ClickAwayListener> */}
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default RichTooltip;

import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { TextField, Typography } from "@mui/material";
import CrossIcon from "../../assets/svgs/CrossIcon";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DRRightArrow from "./DRRightArrow";
import BootstrapTooltip from "../../components/common/TooltipUI/customToolTip";

export default function DRGroupQuestions({
  title,
  sampleQuestion,
  handleClick,
  loading,
  openCollaps,
  setOpenCollaps,
  toggleFunction,
  toggleStates,
}) {
  const [state, setState] = React.useState(false);

  const OpenSideDraw = () => {
    setState(true);
  };

  const handleWidthCollaps = () => {
    if (toggleFunction) {
      toggleStates("openCollapsQuestion");
    } else {
      if (openCollaps === true) {
        setOpenCollaps(false);
      } else {
        setOpenCollaps(true);
      }
    }
  };
  return (
    <div>
      <Button
        className="DR-side-btn-hover flex justify-center items-center"
        onClick={handleWidthCollaps}
        style={{
          position: "fixed",
          top: "49px",
          // right: openCollaps === true ? "80px" : "330px",
          right: "40px",
          border: "none",
          cursor: "pointer",
          backgroundColor: "#f3f3f3",
          borderRadius: "50%",
          minWidth: "35px",
          width: "35px",
          height: "35px",
          padding: "0",
        }}
      >
        <BootstrapTooltip
          title={
            title ? (
              <spna>{title} Sample Questions</spna>
            ) : (
              "Smart Insights Sample Questions"
            )
          }
        >
          {openCollaps === true ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#131722"
              stroke-linecap="round"
              stroke-linejoin="round"
              width="24"
              height="24"
              stroke-width="1"
            >
              {" "}
              <path d="M4 16l6 -7l5 5l5 -6"></path>{" "}
              <path d="M15 14m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>{" "}
              <path d="M10 9m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>{" "}
              <path d="M4 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>{" "}
              <path d="M20 8m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>{" "}
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-x"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="1"
              stroke="#2c3e50"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M18 6l-12 12" />
              <path d="M6 6l12 12" />
            </svg>
          )}
        </BootstrapTooltip>
      </Button>

      <div
        style={{
          position: "fixed",
          right: "20px",
          top: "70px",
          width: "285px",
          height: "100vh",
          // paddingLeft: "15px",
          borderLeft: "1px solid #e9e9e9",
          display: openCollaps === true ? "none" : "block",
        }}
      >
        <div style={{ width: "285px" }}>
          <div
            className="side-bar-title flex items-center justify-between "
            style={{
              borderBottom: "1px solid rgb(239, 239, 239)",
              paddingBottom: "10px",
              paddingTop: "10px",
            }}
          >
            <Typography
              variant=""
              className="noticication-bar-title-1"
              style={{ fontWeight: 600 }}
            >
              {title ? title : "Smart Insights"}
            </Typography>
            <div className="flex items-center ">
              <div className="flex items-center">
                {/* <div
              className="flex justify-center items-center cursor-pointer"
              onClick={() => setState(false)}
            >
              <CrossIcon />
            </div> */}
              </div>
            </div>
          </div>
          <div
            className="p-4"
            style={{ maxHeight: "calc(100vh - 125px)", overflowY: "auto" }}
          >
            <p className="mb-4 DR-group-que-text-top">
              Select a question from the list below or type your question
              directly in the input field.
            </p>
            {sampleQuestion?.map((item, index) => {
              return (
                <div className="DR-Group-question-collaps mt-2" key={index}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      {item?.title}
                    </AccordionSummary>
                    <AccordionDetails>
                      <ul className="DR-grp-question-list">
                        {item?.questions?.map((question, index2) => {
                          return (
                            <li
                              key={index2}
                              style={{
                                opacity: question?.disabled ? "0.5" : "1",
                                cursor: question?.disabled
                                  ? "not-allowed"
                                  : "pointer",
                              }}
                              onClick={() => {
                                if (!loading && !question?.disabled) {
                                  handleClick(question?.value);
                                  setState(false);
                                }
                              }}
                            >
                              <span>{question?.value}</span>
                              <span className="DR-grp-question-list-icon">
                                <DRRightArrow />
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

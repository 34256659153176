import { createSlice } from "@reduxjs/toolkit";

import {
  commonPaginationWithCatch,
  getMultiSelectFilterValueForApi,
} from "../../../components/common/Utils/utils";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";
import { serverRoutes } from "../../../constants/serverRoutes";

export const claimAllFiltersSlice = createSlice({
  name: "claimAllFiltersSlice",
  initialState: {
    // new filters
    filterLoader: {
      payers: true,
      serviceLine: true,
      claimStatus: true,
      reasoncode: true,
      facility: true,
      provider: true,
      fillingIndicator: true,
      billingProvider: true,
      renderingProvider: true,
      ipRemarkCode: true,
      cptCode: true,
      groupReasonCode: true,
      drgCode: true,
    },

    // drg filter
    drgCodeRAFilterArr: [],
    drgCodeForUPFilterArr: [],

    // filters for (denial by claims) screen
    serviceLineFilterArr: [],
    // cpt filters
    cptCodesForFilterArr: [],
    cptCodesForRAFilterArr: [],
    cptCodesForUPFilterArr: [],
    cptCodesForCLFilterArr: [],
    cptCodesForRDFilterArr: [],
    cptCodesForRACFilterArr: [],

    cptCodesForDBCFilterArr: [],
    cptCodesForCAdjCLFilterArr: [],
    cptCodesForDBPFilterArr: [],

    selectedCptFilter: [],
    groupReasonCodeFilterArr: [],
    claimStatusForFilterArr: [],
    payersForFilterArr: [],
    payeeForFilterArr: [],
    facilityForFilterArr: [],
    claimAdjReasonCodesForFilterArr: [],
    claimfillingIndiCodeForFilterArr: [],
    jCodesForFilterArr: [],
    IPRemarkCodesForFilterArr: [],
    OPRemarkCodesForFilterArr: [],

    // BillingingProvider
    billingProviderForRAFilterArr: [], // Remittance Advice  == "claimPayment"
    billingProviderForUPFilterArr: [], // Under Payment  == "underPaymentFilters"
    billingProviderForDBCFilterArr: [], // Denial by Claims  == "denialDetailsFilter"
    billingProviderForCAGroupByCLFilterArr: [], // Claim Adjustments (group by Claim Level) == "denialByServiceLineGroupByPYCNFilter"
    billingProviderForCAGroupBySLFilterArr: [], // Claim Adjustments (group by Service Level)  == "denialByServiceLineFilter"
    billingProviderForRDFilterArr: [], // Recurring Denials (onCology last Tab) == "denialsByCptScreenFilter"
    billingProviderForDBPFilterArr: [], //  Denials by Procedure (reports section) == "serviceLineDenialsReportFilter"
    billingProviderForPDFilterArr: [], //  Provider dashboard (insights section) == "providerDashboardFilter"

    // RenderingProvider
    renderingProviderForRAFilterArr: [], // Remittance Advice  == "claimPayment"
    renderingProviderForUPFilterArr: [], // Under Payment  == "underPaymentFilters"
    renderingProviderForDBCFilterArr: [], // Denial by Claims  == "denialDetailsFilter"
    renderingProviderForCAGroupByCLFilterArr: [], // Claim Adjustments (group by Claim Level) == "denialByServiceLineGroupByPYCNFilter"
    renderingProviderForCAGroupBySLFilterArr: [], // Claim Adjustments (group by Service Level)  == "denialByServiceLineFilter"
    renderingProviderForRDFilterArr: [], // Recurring Denials (onCology last Tab) == "denialsByCptScreenFilter"
    renderingProviderForDBPFilterArr: [], //  Denials by Procedure (reports section) == "serviceLineDenialsReportFilter"
    renderingProviderForPDFilterArr: [], //  Provider dashboard (insights section) == "providerDashboardFilter"

    // reports -> denialReports screen filter
    reasonCodesForDRFilterArr: [],
    cptCodesForDRFilterArr: [],
  },
  reducers: {
    //Service Line Filters
    gettingServiceLineFilter: (state, action) => {
      state.filterLoader.serviceLine = true;
    },
    successServiceLineFilter: (state, action) => {
      const data = action?.payload;

      if (data?.CPTCodeJSON) {
        state.serviceLineFilterArr = JSON.parse(data?.CPTCodeJSON);
      } else {
        state.serviceLineFilterArr = [];
      }
      state.filterLoader.serviceLine = false;
    },
    failedServiceLineFilter: (state, action) => {
      state.filterLoader.serviceLine = false;
    },

    //Payers Filters
    gettingPayerFilter: (state, action) => {
      state.filterLoader.payers = true;
    },
    successPayerFilter: (state, action) => {
      const data = action?.payload;
      if (data?.length) {
        let newArr = [];
        data?.forEach((item) => {
          // Check if item?.name is not null, not an empty string, and not undefined
          if (
            item?.name !== null &&
            item?.name !== "" &&
            item?.name !== undefined
          ) {
            newArr.push({
              label: item?.name,
              value: item?.payerId,
            });
          }
        });

        state.payersForFilterArr = newArr;
      } else {
        state.payersForFilterArr = [];
      }
      state.filterLoader.payers = false;
    },
    failedPayerFilter: (state, action) => {
      state.filterLoader.payers = false;
    },

    //Claim Status Code Filters
    gettingClaimStatusFilter: (state, action) => {
      state.filterLoader.claimStatus = true;
    },
    successClaimStatusFilter: (state, action) => {
      const data = action?.payload;

      if (data?.length) {
        let newArr = [];
        data?.forEach((item) => {
          let userFriendlyName = `${item?.claimStatusCodeId} - ${item?.description}`;
          newArr.push({
            value: item?.claimStatusCodeId,
            label: userFriendlyName,
          });
        });

        state.claimStatusForFilterArr = newArr;
      } else {
        state.claimStatusForFilterArr = [];
      }
      state.filterLoader.claimStatus = false;
    },
    failedClaimStatusFilter: (state, action) => {
      state.filterLoader.claimStatus = false;
    },
    //REASON CODE FILTER
    gettingReasonCodeFilter: (state, action) => {
      state.filterLoader.reasoncode = true;
    },
    successReasonCodeFilter: (state, action) => {
      const data = action?.payload;
      if (data?.length) {
        let newArr = [];
        data?.forEach((item) => {
          let userFriendlyName = `${item?.code} - ${item?.description}`;
          newArr.push({
            value: item?.code,
            label: userFriendlyName,
            count: item?.claimCount,
            // ServiceCount: item?.ServiceCount,
          });
        });

        let descending = newArr.sort(
          (a, b) => Number(b.count) - Number(a.count)
        );

        state.claimAdjReasonCodesForFilterArr = descending;
        state.reasonCodesForDRFilterArr = descending;
      } else {
        state.claimAdjReasonCodesForFilterArr = [];
        state.reasonCodesForDRFilterArr = [];
      }
      state.filterLoader.reasoncode = false;
    },
    failedReasonCodeFilter: (state, action) => {
      state.filterLoader.reasoncode = false;
    },
    //FACILITY FILTER
    gettingFacilityFilter: (state, action) => {
      state.filterLoader.facility = true;
    },
    successFacilityFilter: (state, action) => {
      const data = action?.payload;
      if (data?.length) {
        let newArr = [];
        data?.forEach((item) => {
          let facility_With_PrimaryId = `${item?.serviceFacilityName}`;
          newArr.push({
            label: facility_With_PrimaryId,
            value: item?.serviceFacilityId,
            // id: item.id,
          });
        });

        state.facilityForFilterArr = newArr;
      } else {
        state.facilityForFilterArr = [];
      }
      state.filterLoader.facility = false;
    },
    failedFacilityFilter: (state, action) => {
      state.filterLoader.facility = false;
      state.facilityForFilterArr = [];
    },
    //Provider FILTER
    gettingProviderFilter: (state, action) => {
      state.filterLoader.provider = true;
    },
    successProviderFilter: (state, action) => {
      const data = action?.payload;
      if (data?.length) {
        let newArr = [];
        data?.forEach((item) => {
          let payeeName_With_PrimaryId = `${item?.name} - ${item?.primaryId}`;
          newArr.push({
            label: payeeName_With_PrimaryId,
            value: item?.payeeId,
          });
        });

        state.payeeForFilterArr = newArr;
      } else {
        state.payeeForFilterArr = [];
      }
      state.filterLoader.provider = false;
    },
    failedProviderFilter: (state, action) => {
      state.filterLoader.provider = false;
      state.payeeForFilterArr = [];
    },
    //Filling Indicator
    gettingFillingIndicatorFilter: (state, action) => {
      state.filterLoader.fillingIndicator = true;
    },
    successFillingIndicatorFilter: (state, action) => {
      const data = action?.payload;
      if (data?.length) {
        let newArr = [];
        data?.forEach((item) => {
          let userFriendlyName = `${item?.description}`;
          newArr.push({
            value: item?.name,
            label: userFriendlyName,
          });
        });

        state.claimfillingIndiCodeForFilterArr = newArr;
      } else {
        state.claimfillingIndiCodeForFilterArr = [];
      }
      state.filterLoader.fillingIndicator = false;
    },
    failedFillingIndicatorFilter: (state, action) => {
      state.filterLoader.fillingIndicator = false;
      state.claimfillingIndiCodeForFilterArr = [];
    },
    //IP Remork Codes
    gettingIpRemarkCodeFilter: (state, action) => {
      state.filterLoader.ipRemarkCode = true;
    },
    successIpRemarkCodeFilter: (state, action) => {
      const data = action?.payload;
      if (data?.length) {
        let newArr = [];
        data?.forEach((item) => {
          let userFriendlyName = `${item?.code}${
            item?.description ? ` - ${item?.description}` : ""
          }`;
          newArr.push({
            value: item?.code,
            label: userFriendlyName,
          });
        });

        state.IPRemarkCodesForFilterArr = newArr;
        state.OPRemarkCodesForFilterArr = newArr;
      } else {
        state.IPRemarkCodesForFilterArr = [];
        state.OPRemarkCodesForFilterArr = [];
      }
      state.filterLoader.ipRemarkCode = false;
    },
    failedIpRemarkCodeFilter: (state, action) => {
      state.filterLoader.ipRemarkCode = false;
      state.IPRemarkCodesForFilterArr = [];
    },
    //CPT CODES
    gettingCptCodeFilter: (state, action) => {
      state.filterLoader.cptCode = true;
    },
    successCptCodeFilter: (state, action) => {
      try {
        const data = action?.payload;
        // console.log("successCptCodeFilter--->>", data);
        if (data?.listingFilter) {
          // console.log("successCptCodeFilter__if--->>", data);
          const output = data?.data?.output;
          if (output?.length) {
            if (data?.data?.selectedCodes?.length > 0) {
              const excludedValues = data?.data?.selectedCodes.map(
                (obj) => obj?.cptCode
              );
              const excludedArray = data?.data?.selectedCodes.map((obj) => {
                let userFriendlyName = obj?.description
                  ? `${obj?.cptCode} - ${obj?.description}`
                  : `${obj?.cptCode}`;

                return {
                  value: obj?.cptCode,
                  label: userFriendlyName,
                };
              });

              let arrTemp = [];
              let newArr1 = [];
              output?.filter((obj) => {
                if (excludedValues.includes(obj?.cptCode)) {
                  arrTemp.push(obj);
                } else {
                  let userFriendlyName = obj?.description
                    ? `${obj?.cptCode} - ${obj?.description}`
                    : `${obj?.cptCode}`;
                  newArr1.push({
                    value: obj?.cptCode,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...excludedArray);

              state.cptCodesForFilterArr = newArr1;
              state.cptCodesForRAFilterArr = newArr1;
              state.cptCodesForRACFilterArr = newArr1; //acceptence rate

              state.cptCodesForUPFilterArr = newArr1; // Under Payment screen
              state.cptCodesForRDFilterArr = newArr1;
              state.cptCodesForDRFilterArr = newArr1;
              state.cptCodesForDBCFilterArr = newArr1;
              state.cptCodesForCAdjCLFilterArr = newArr1;
              state.cptCodesForDBPFilterArr = newArr1;
              state.cptCodesForCLFilterArr = newArr1;
            } else {
              let newArr = [];

              output?.forEach((item) => {
                let userFriendlyName = item?.description
                  ? `${item?.cptCode} - ${item?.description}`
                  : `${item?.cptCode}`;
                newArr.push({
                  value: item?.cptCode,

                  label: userFriendlyName,
                });
              });

              state.cptCodesForFilterArr = newArr;
              state.cptCodesForRAFilterArr = newArr;
              state.cptCodesForRACFilterArr = newArr; //acceptence rate

              state.cptCodesForUPFilterArr = newArr; // Under Payment screen
              state.cptCodesForDRFilterArr = newArr;
              state.cptCodesForCLFilterArr = newArr;
              state.cptCodesForRDFilterArr = newArr;
              state.cptCodesForDBCFilterArr = newArr;
              state.cptCodesForCAdjCLFilterArr = newArr;
              state.cptCodesForDBPFilterArr = newArr;
            }
          } else {
            state.cptCodesForFilterArr = [];
            state.cptCodesForRAFilterArr = [];
            state.cptCodesForRACFilterArr = [];

            state.cptCodesForUPFilterArr = []; // Under Payment screen
            state.cptCodesForDRFilterArr = [];
            state.cptCodesForCLFilterArr = [];
            state.cptCodesForRDFilterArr = [];
            state.cptCodesForDBCFilterArr = [];
            state.cptCodesForCAdjCLFilterArr = [];
            state.cptCodesForDBPFilterArr = [];
          }

          state.filterLoader.cptCode = false;
        } else {
          // console.log("successCptCodeFilter__else--->>", data);
          const output = data?.output;
          if (output?.length) {
            if (data?.selectedCodes?.length > 0) {
              const excludedValues = data?.selectedCodes.map(
                (obj) => obj?.cptCode
              );
              const excludedArray = data?.selectedCodes.map((obj) => {
                let userFriendlyName = obj?.description
                  ? `${obj?.cptCode} - ${obj?.description}`
                  : `${obj?.cptCode}`;

                return {
                  value: obj?.cptCode,
                  label: userFriendlyName,
                };
              });

              let arrTemp = [];
              let newArr1 = [];
              output?.filter((obj) => {
                if (excludedValues.includes(obj?.cptCode)) {
                  arrTemp.push(obj);
                } else {
                  let userFriendlyName = obj?.description
                    ? `${obj?.cptCode} - ${obj?.description}`
                    : `${obj?.cptCode}`;
                  newArr1.push({
                    value: obj?.cptCode,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...excludedArray);

              state.cptCodesForFilterArr = newArr1;
              state.cptCodesForRAFilterArr = newArr1;
              state.cptCodesForRACFilterArr = newArr1; //acceptence rate

              state.cptCodesForUPFilterArr = newArr1; // Under Payment screen
              state.cptCodesForDRFilterArr = newArr1;
              state.cptCodesForRDFilterArr = newArr1;
              state.cptCodesForDBCFilterArr = newArr1;
              state.cptCodesForCAdjCLFilterArr = newArr1;
              state.cptCodesForDBPFilterArr = newArr1;
              state.cptCodesForCLFilterArr = newArr1;
            } else {
              let newArr = [];

              output?.forEach((item) => {
                let userFriendlyName = item?.description
                  ? `${item?.cptCode} - ${item?.description}`
                  : `${item?.cptCode}`;
                newArr.push({
                  value: item?.cptCode,
                  label: userFriendlyName,
                });
              });

              state.cptCodesForFilterArr = newArr;
              state.cptCodesForRAFilterArr = newArr;
              state.cptCodesForRACFilterArr = newArr; //acceptence rate

              state.cptCodesForUPFilterArr = newArr; // Under Payment screen
              state.cptCodesForDRFilterArr = newArr;
              state.cptCodesForCLFilterArr = newArr;
              state.cptCodesForRDFilterArr = newArr;
              state.cptCodesForDBCFilterArr = newArr;
              state.cptCodesForCAdjCLFilterArr = newArr;
              state.cptCodesForDBPFilterArr = newArr;
            }
          } else {
            state.cptCodesForFilterArr = [];
            state.cptCodesForRAFilterArr = [];
            state.cptCodesForRACFilterArr = [];

            state.cptCodesForUPFilterArr = []; // Under Payment screen
            state.cptCodesForDRFilterArr = [];
            state.cptCodesForCLFilterArr = [];
            state.cptCodesForRDFilterArr = [];
            state.cptCodesForDBCFilterArr = [];
            state.cptCodesForCAdjCLFilterArr = [];
            state.cptCodesForDBPFilterArr = [];
          }

          state.filterLoader.cptCode = false;
        }
      } catch (e) {
        // console.log("e--->>", e);
      }
    },
    successCptCodeFilter2: (state, action) => {
      const data = action?.payload;
      // console.log("successCptCodeFilter2_--->>", data);

      try {
        if (data?.listingFilter) {
          let finalArr = [];
          let data1 = data?.data;
          // console.log("successCptCodeFilter2_if--->>", data1);

          if (data1?.output?.length) {
            if (data1?.selectedCodes?.length > 0) {
              const excludedValues = data1?.selectedCodes.map(
                (obj) => obj?.cptCode
              );
              const excludedArray = data1?.selectedCodes.map((obj) => {
                let userFriendlyName = obj?.description
                  ? `${obj?.cptCode} - ${obj?.description}`
                  : `${obj?.cptCode}`;

                return {
                  value: obj?.cptCode,

                  label: userFriendlyName,
                };
              });

              let arrTemp = [];
              let newArr1 = [];
              data1?.output?.filter((obj) => {
                let userFriendlyName = obj?.description
                  ? `${obj?.cptCode} - ${obj?.description}`
                  : `${obj?.cptCode}`;
                if (excludedValues.includes(obj?.cptCode)) {
                  arrTemp.push({
                    value: obj?.cptCode,

                    label: userFriendlyName,
                  });
                } else {
                  newArr1.push({
                    value: obj?.cptCode,

                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...(arrTemp?.length ? arrTemp : excludedArray));

              // newArr1.unshift(...arrTemp);

              finalArr = newArr1;

              handleMultiFilter(
                state,
                finalArr,
                data?.listingFilter,
                "cptCode"
              );
            } else {
              let newArr = [];

              data1?.output?.forEach((item) => {
                let userFriendlyName = item?.description
                  ? `${item?.cptCode} - ${item?.description}`
                  : `${item?.cptCode}`;
                newArr.push({
                  value: item?.cptCode,

                  label: userFriendlyName,
                });
              });

              finalArr = newArr;

              handleMultiFilter(
                state,
                finalArr,
                data?.listingFilter,
                "cptCode"
              );
            }
          } else {
            finalArr = [];

            handleMultiFilter(state, finalArr, data?.listingFilter, "cptCode");
          }
        } else {
          // console.log("successCptCodeFilter2_else--->>", data);
        }
      } catch (e) {
        // console.log("successCptCodeFilter2_e--->>", e);
      }
    },
    failedCptCodeFilter: (state, action) => {
      state.filterLoader.cptCode = false;
      state.cptCodesForFilterArr = [];
    },
    //Group Reason Code
    gettingGroupReasonCodeFilter: (state, action) => {
      state.filterLoader.groupReasonCode = true;
    },
    successGroupReasonCodeFilter: (state, action) => {
      const data = action?.payload;
      if (data?.length) {
        let newArr = [];
        data?.forEach((item) => {
          let userFriendlyName = item?.description
            ? `${item?.groupCode} - ${item?.description}`
            : `${item?.groupCode}`;
          newArr.push({
            value: item?.groupCode,

            label: userFriendlyName,
          });
        });

        state.groupReasonCodeFilterArr = newArr;
      } else {
        state.groupReasonCodeFilterArr = [];
      }
      state.filterLoader.groupReasonCode = false;
    },
    failedGroupReasonCodeFilter: (state, action) => {
      state.filterLoader.groupReasonCode = false;
      state.groupReasonCodeFilterArr = [];
    },

    //Billing Provider
    gettingBillingProviderFilter: (state, action) => {
      state.filterLoader.billingProvider = true;
    },
    successBillingProviderFilter: (state, action) => {
      try {
        const data = action?.payload;
        // console.log("successBillingProvider--->>", data);
        if (data?.listingFilter) {
          // console.log("successBillingProvider__if--->>", data);
          const output = data?.data?.output;
          if (output?.length) {
            if (data?.data?.selectedBillingProviderList?.length > 0) {
              const excludedValues =
                data?.data?.selectedBillingProviderList.map((obj) => obj?.id);
              const excludedArray = data?.data?.selectedBillingProviderList.map(
                (obj) => {
                  let userFriendlyName = `${obj?.name}`;
                  return {
                    value: obj?.id,
                    label: userFriendlyName,
                  };
                }
              );

              let arrTemp = [];
              let newArr1 = [];
              output?.filter((obj) => {
                if (excludedValues.includes(obj?.id)) {
                  arrTemp.push(obj);
                } else {
                  let userFriendlyName = `${obj?.name}`;
                  newArr1.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...excludedArray);

              state.billingProviderForRAFilterArr = newArr1; // Remittance Advice
              state.billingProviderForUPFilterArr = newArr1; // Under Payment
              state.billingProviderForDBCFilterArr = newArr1; // Denial by Claims
              state.billingProviderForCAGroupByCLFilterArr = newArr1; // Claim Adjustments (group by Claim Level)
              state.billingProviderForCAGroupBySLFilterArr = newArr1; // Claim Adjustments (group by Service Level)
              state.billingProviderForRDFilterArr = newArr1; // Recurring Denials (onCology last Tab)
              state.billingProviderForDBPFilterArr = newArr1; //  Denials by Procedure (reports section)
              state.billingProviderForPDFilterArr = newArr1; //  Provider dashboard (insights section)
            } else {
              let newArr = [];

              output?.forEach((item) => {
                let userFriendlyName = `${item?.name}`;
                newArr.push({
                  value: item?.id,
                  label: userFriendlyName,
                });
              });

              state.billingProviderForRAFilterArr = newArr; // Remittance Advice
              state.billingProviderForUPFilterArr = newArr; // Under Payment
              state.billingProviderForDBCFilterArr = newArr; // Denial by Claims
              state.billingProviderForCAGroupByCLFilterArr = newArr; // Claim Adjustments (group by Claim Level)
              state.billingProviderForCAGroupBySLFilterArr = newArr; // Claim Adjustments (group by Service Level)
              state.billingProviderForRDFilterArr = newArr; // Recurring Denials (onCology last Tab)
              state.billingProviderForDBPFilterArr = newArr; //  Denials by Procedure (reports section)
              state.billingProviderForPDFilterArr = newArr; //  Provider dashboard (insights section)
            }
          } else {
            state.billingProviderForRAFilterArr = []; // Remittance Advice
            state.billingProviderForUPFilterArr = []; // Under Payment
            state.billingProviderForDBCFilterArr = []; // Denial by Claims
            state.billingProviderForCAGroupByCLFilterArr = []; // Claim Adjustments (group by Claim Level)
            state.billingProviderForCAGroupBySLFilterArr = []; // Claim Adjustments (group by Service Level)
            state.billingProviderForRDFilterArr = []; // Recurring Denials (onCology last Tab)
            state.billingProviderForDBPFilterArr = []; //  Denials by Procedure (reports section)
            state.billingProviderForPDFilterArr = []; //  Provider dashboard (insights section)
          }

          state.filterLoader.billingProvider = false;
        } else {
          // console.log("successBillingProvider__else--->>", data);
          const output = data?.output;
          if (output?.length) {
            if (data?.selectedBillingProviderList?.length > 0) {
              const excludedValues = data?.selectedBillingProviderList.map(
                (obj) => obj?.id
              );
              const excludedArray = data?.selectedBillingProviderList.map(
                (obj) => {
                  let userFriendlyName = `${obj?.name}`;
                  return {
                    value: obj?.id,
                    label: userFriendlyName,
                  };
                }
              );

              let arrTemp = [];
              let newArr1 = [];
              output?.filter((obj) => {
                if (excludedValues.includes(obj?.id)) {
                  arrTemp.push(obj);
                } else {
                  let userFriendlyName = `${obj?.name}`;
                  newArr1.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...excludedArray);

              state.billingProviderForRAFilterArr = newArr1; // Remittance Advice
              state.billingProviderForUPFilterArr = newArr1; // Under Payment
              state.billingProviderForDBCFilterArr = newArr1; // Denial by Claims
              state.billingProviderForCAGroupByCLFilterArr = newArr1; // Claim Adjustments (group by Claim Level)
              state.billingProviderForCAGroupBySLFilterArr = newArr1; // Claim Adjustments (group by Service Level)
              state.billingProviderForRDFilterArr = newArr1; // Recurring Denials (onCology last Tab)
              state.billingProviderForDBPFilterArr = newArr1; //  Denials by Procedure (reports section)
              state.billingProviderForPDFilterArr = newArr1; //  Provider dashboard (insights section)
            } else {
              let newArr = [];

              output?.forEach((item) => {
                let userFriendlyName = `${item?.name}`;
                newArr.push({
                  value: item?.id,
                  label: userFriendlyName,
                });
              });

              state.billingProviderForRAFilterArr = newArr; // Remittance Advice
              state.billingProviderForUPFilterArr = newArr; // Under Payment
              state.billingProviderForDBCFilterArr = newArr; // Denial by Claims
              state.billingProviderForCAGroupByCLFilterArr = newArr; // Claim Adjustments (group by Claim Level)
              state.billingProviderForCAGroupBySLFilterArr = newArr; // Claim Adjustments (group by Service Level)
              state.billingProviderForRDFilterArr = newArr; // Recurring Denials (onCology last Tab)
              state.billingProviderForDBPFilterArr = newArr; //  Denials by Procedure (reports section)
              state.billingProviderForPDFilterArr = newArr; //  Provider dashboard (insights section)
            }
          } else {
            state.billingProviderForRAFilterArr = []; // Remittance Advice
            state.billingProviderForUPFilterArr = []; // Under Payment
            state.billingProviderForDBCFilterArr = []; // Denial by Claims
            state.billingProviderForCAGroupByCLFilterArr = []; // Claim Adjustments (group by Claim Level)
            state.billingProviderForCAGroupBySLFilterArr = []; // Claim Adjustments (group by Service Level)
            state.billingProviderForRDFilterArr = []; // Recurring Denials (onCology last Tab)
            state.billingProviderForDBPFilterArr = []; //  Denials by Procedure (reports section)
            state.billingProviderForPDFilterArr = []; //  Provider dashboard (insights section)
          }

          state.filterLoader.billingProvider = false;
        }
      } catch (e) {
        // console.log("e_successBillingProvider--->>", e);
      }
    },
    successBillingProviderFilter2ForSearch: (state, action) => {
      const data = action?.payload;
      // console.log("successProviderFilter2ForSearch--->>", data);
      try {
        if (data?.listingFilter) {
          let finalArr = [];
          let data1 = data?.data;
          // console.log("successProviderFilter2ForSearchif--->>", data1);
          if (data1?.output?.length) {
            if (data1?.selectedBillingProviderList?.length > 0) {
              const excludedValues = data1?.selectedBillingProviderList.map(
                (obj) => obj?.id
              );
              const excludedArray = data1?.selectedBillingProviderList.map(
                (obj) => {
                  let userFriendlyName = `${obj?.name}`;
                  return {
                    value: obj?.id,
                    label: userFriendlyName,
                  };
                }
              );
              let arrTemp = [];
              let newArr1 = [];
              data1?.output?.filter((obj) => {
                let userFriendlyName = `${obj?.name}`;
                if (excludedValues.includes(obj?.cptCode)) {
                  arrTemp.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                } else {
                  newArr1.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                }
              });
              newArr1.unshift(...(arrTemp?.length ? arrTemp : excludedArray));
              // newArr1.unshift(...arrTemp);
              finalArr = newArr1;
              handleMultiFilter(
                state,
                finalArr,
                data?.listingFilter,
                "billingProvider"
              );
            } else {
              let newArr = [];
              data1?.output?.forEach((item) => {
                let userFriendlyName = `${item?.name}`;
                newArr.push({
                  value: item?.id,
                  label: userFriendlyName,
                });
              });
              finalArr = newArr;
              handleMultiFilter(
                state,
                finalArr,
                data?.listingFilter,
                "billingProvider"
              );
            }
          } else {
            finalArr = [];
            handleMultiFilter(
              state,
              finalArr,
              data?.listingFilter,
              "billingProvider"
            );
          }
        } else {
          // console.log("successProviderFilter2ForSearchelse--->>", data);
        }
      } catch (e) {
        // console.log("successProviderFilter2ForSearche--->>", e);
      }
    },
    failedBillingProviderFilter: (state, action) => {
      state.filterLoader.billingProvider = false;
      state.billingProviderForRAFilterArr = [];
      state.billingProviderForUPFilterArr = []; // Under Payment
    },

    //Rendering Provider
    gettingRenderingProviderFilter: (state, action) => {
      state.filterLoader.renderingProvider = true;
    },
    successRenderingProviderFilter: (state, action) => {
      try {
        const data = action?.payload;
        const outputSelected = data?.data?.selectedRenderingProviderList;
        // console.log("successRenderingProvider--->>", data);
        if (data?.listingFilter) {
          // console.log("successRenderingProvider__if--->>", data);
          const output = data?.data?.output;

          if (output?.length) {
            if (data?.data?.electedRenderingProvidersList?.length > 0) {
              const excludedValues =
                data?.data?.electedRenderingProvidersList.map((obj) => obj?.id);
              const excludedArray =
                data?.data?.electedRenderingProvidersList.map((obj) => {
                  let userFriendlyName = `${obj?.name}`;
                  return {
                    value: obj?.id,
                    label: userFriendlyName,
                  };
                });

              let arrTemp = [];
              let newArr1 = [];
              output?.filter((obj) => {
                if (excludedValues.includes(obj?.id)) {
                  arrTemp.push(obj);
                } else {
                  let userFriendlyName = `${obj?.name}`;
                  newArr1.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...excludedArray);

              state.renderingProviderForRAFilterArr = newArr1; // Remittance Advice
              state.renderingProviderForUPFilterArr = newArr1; // Under Payment screen
              state.renderingProviderForDBCFilterArr = newArr1; // Denial by Claims
              state.renderingProviderForCAGroupByCLFilterArr = newArr1; // Claim Adjustments (group by Claim Level)
              state.renderingProviderForCAGroupBySLFilterArr = newArr1; // Claim Adjustments (group by Service Level)
              state.renderingProviderForRDFilterArr = newArr1; // Recurring Denials (onCology last Tab)
              state.renderingProviderForDBPFilterArr = newArr1; //  Denials by Procedure (reports section)
              state.renderingProviderForPDFilterArr = newArr1; //  Provider dashboard (insights section)
            } else {
              let newArr = [];

              output?.forEach((item) => {
                let userFriendlyName = `${item?.name}`;
                newArr.push({
                  value: item?.id,
                  label: userFriendlyName,
                });
              });

              state.renderingProviderForRAFilterArr = newArr; // Remittance Advice
              state.renderingProviderForUPFilterArr = newArr; // Under Payment screen
              state.renderingProviderForDBCFilterArr = newArr; // Denial by Claims
              state.renderingProviderForCAGroupByCLFilterArr = newArr; // Claim Adjustments (group by Claim Level)
              state.renderingProviderForCAGroupBySLFilterArr = newArr; // Claim Adjustments (group by Service Level)
              state.renderingProviderForRDFilterArr = newArr; // Recurring Denials (onCology last Tab)
              state.renderingProviderForDBPFilterArr = newArr; //  Denials by Procedure (reports section)
              state.renderingProviderForPDFilterArr = newArr; //  Provider dashboard (insights section)
            }
          } else {
            let newArr = [];
            if (outputSelected?.length) {
              outputSelected?.forEach((item) => {
                let userFriendlyName = `${item?.name}`;
                newArr.push({
                  value: item?.id,
                  label: userFriendlyName,
                });
              });
            }

            handleMultiFilter(
              state,
              newArr,
              data?.listingFilter,
              "renderingProvider"
            );
            // state.renderingProviderForRAFilterArr = []; // Remittance Advice
            // state.renderingProviderForUPFilterArr = []; // Under Payment screen
            // state.renderingProviderForDBCFilterArr = []; // Denial by Claims
            // state.renderingProviderForCAGroupByCLFilterArr = []; // Claim Adjustments (group by Claim Level)
            // state.renderingProviderForCAGroupBySLFilterArr = []; // Claim Adjustments (group by Service Level)
            // state.renderingProviderForRDFilterArr = []; // Recurring Denials (onCology last Tab)
            // state.renderingProviderForDBPFilterArr = []; //  Denials by Procedure (reports section)
          }

          state.filterLoader.billingProvider = false;
        } else {
          // console.log("successRenderingProvider__else--->>", data);
          const output = data?.output;
          if (output?.length) {
            if (outputSelected?.length > 0) {
              const excludedValues = outputSelected.map((obj) => obj?.id);
              const excludedArray = outputSelected.map((obj) => {
                let userFriendlyName = `${obj?.name}`;
                return {
                  value: obj?.id,
                  label: userFriendlyName,
                };
              });
              let arrTemp = [];
              let newArr1 = [];
              output?.filter((obj) => {
                if (excludedValues.includes(obj?.id)) {
                  arrTemp.push(obj);
                } else {
                  let userFriendlyName = `${obj?.name}`;
                  newArr1.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...excludedArray);

              state.renderingProviderForRAFilterArr = newArr1; // Remittance Advice
              state.renderingProviderForUPFilterArr = newArr1; // Under Payment screen
              state.renderingProviderForDBCFilterArr = newArr1; // Denial by Claims
              state.renderingProviderForCAGroupByCLFilterArr = newArr1; // Claim Adjustments (group by Claim Level)
              state.renderingProviderForCAGroupBySLFilterArr = newArr1; // Claim Adjustments (group by Service Level)
              state.renderingProviderForRDFilterArr = newArr1; // Recurring Denials (onCology last Tab)
              state.renderingProviderForDBPFilterArr = newArr1; //  Denials by Procedure (reports section)
              state.renderingProviderForPDFilterArr = newArr1; //  Provider dashboard (insights section)
            } else {
              let newArr = [];

              output?.forEach((item) => {
                let userFriendlyName = `${item?.name}`;
                newArr.push({
                  value: item?.id,
                  label: userFriendlyName,
                });
              });

              state.renderingProviderForRAFilterArr = newArr; // Remittance Advice
              state.renderingProviderForUPFilterArr = newArr; // Under Payment screen
              state.renderingProviderForDBCFilterArr = newArr; // Denial by Claims
              state.renderingProviderForCAGroupByCLFilterArr = newArr; // Claim Adjustments (group by Claim Level)
              state.renderingProviderForCAGroupBySLFilterArr = newArr; // Claim Adjustments (group by Service Level)
              state.renderingProviderForRDFilterArr = newArr; // Recurring Denials (onCology last Tab)
              state.renderingProviderForDBPFilterArr = newArr; //  Denials by Procedure (reports section)
              state.renderingProviderForPDFilterArr = newArr; //  Provider dashboard (insights section)
            }
          } else {
            state.renderingProviderForRAFilterArr = []; // Remittance Advice
            state.renderingProviderForUPFilterArr = []; // Under Payment screen
            state.renderingProviderForDBCFilterArr = []; // Denial by Claims
            state.renderingProviderForCAGroupByCLFilterArr = []; // Claim Adjustments (group by Claim Level)
            state.renderingProviderForCAGroupBySLFilterArr = []; // Claim Adjustments (group by Service Level)
            state.renderingProviderForRDFilterArr = []; // Recurring Denials (onCology last Tab)
            state.renderingProviderForDBPFilterArr = []; //  Denials by Procedure (reports section)
            state.renderingProviderForPDFilterArr = []; //  Provider dashboard (insights section)
          }

          state.filterLoader.billingProvider = false;
        }
      } catch (e) {
        // console.log("e_successRenderingProvider--->>", e);
      }
    },
    successRenderingProviderFilter2ForSearch: (state, action) => {
      const data = action?.payload;
      // console.log("successRenderingProviderFilter2ForSearch--->>", data);
      try {
        if (data?.listingFilter) {
          let finalArr = [];
          let data1 = data?.data;
          const outputSelected = data?.data?.selectedRenderingProviderList;

          if (data1?.output?.length) {
            if (data1?.selectedRenderingProviderList?.length > 0) {
              const excludedValues = data1?.selectedRenderingProviderList.map(
                (obj) => obj?.id
              );
              const excludedArray = data1?.selectedRenderingProviderList.map(
                (obj) => {
                  let userFriendlyName = `${obj?.name}`;
                  return {
                    value: obj?.id,
                    label: userFriendlyName,
                  };
                }
              );

              let arrTemp = [];
              let newArr1 = [];
              data1?.output?.filter((obj) => {
                let userFriendlyName = `${obj?.name}`;
                if (excludedValues.includes(obj?.id)) {
                  arrTemp.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                } else {
                  newArr1.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                }
              });
              newArr1.unshift(...(arrTemp?.length ? arrTemp : excludedArray));
              // newArr1.unshift(...arrTemp);

              finalArr = newArr1;

              handleMultiFilter(
                state,
                finalArr,
                data?.listingFilter,
                "renderingProvider"
              );
            } else {
              let newArr = [];
              data1?.output?.forEach((item) => {
                let userFriendlyName = `${item?.name}`;
                newArr.push({
                  value: item?.id,
                  label: userFriendlyName,
                });
              });
              finalArr = newArr;

              handleMultiFilter(
                state,
                finalArr,
                data?.listingFilter,
                "renderingProvider"
              );
            }
          } else {
            let newArr = [];
            if (outputSelected?.length) {
              outputSelected?.forEach((item) => {
                let userFriendlyName = `${item?.name}`;
                newArr.push({
                  value: item?.id,
                  label: userFriendlyName,
                });
              });
            }

            handleMultiFilter(
              state,
              newArr,
              data?.listingFilter,
              "renderingProvider"
            );
          }
        } else {
          // console.log(
          //   "successRenderingProviderFilter2ForSearchelse--->>",
          //   data
          // );
        }
      } catch (e) {
        // console.log("successRenderingProviderFilter2ForSearche--->>", e);
      }
    },
    failedRenderingProviderFilter: (state, action) => {
      state.filterLoader.renderingProvider = false;
      state.renderingProviderForRAFilterArr = [];
      state.renderingProviderForUPFilterArr = []; // Under Payment screen
    },

    //DRG CODES
    gettingDrgCodeFilter: (state, action) => {
      state.filterLoader.drgCode = true;
    },
    successDrgCodeFilter: (state, action) => {
      try {
        const data = action?.payload;
        // console.log("successDrgCodeFilter--->>", data);
        if (data?.listingFilter) {
          // console.log("successDrgCodeFilter__if--->>", data);
          const output = data?.data?.drgList;
          if (output?.length) {
            if (data?.data?.selectedDRGsList?.length > 0) {
              const excludedValues = data?.data?.selectedDRGsList.map(
                (obj) => obj?.drgCode
              );
              const excludedArray = data?.data?.selectedDRGsList.map((obj) => {
                let userFriendlyName = obj?.description
                  ? `${obj?.drgCode} - ${obj?.description}`
                  : `${obj?.drgCode}`;

                return {
                  value: obj?.drgCode,
                  label: userFriendlyName,
                };
              });

              let arrTemp = [];
              let newArr1 = [];
              output?.filter((obj) => {
                if (excludedValues.includes(obj?.drgCode)) {
                  arrTemp.push(obj);
                } else {
                  let userFriendlyName = obj?.description
                    ? `${obj?.drgCode} - ${obj?.description}`
                    : `${obj?.drgCode}`;
                  newArr1.push({
                    value: obj?.drgCode,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...excludedArray);

              state.drgCodeRAFilterArr = newArr1;
              state.drgCodeForUPFilterArr = newArr1;
            } else {
              let newArr = [];

              output?.forEach((item) => {
                let userFriendlyName = item?.description
                  ? `${item?.drgCode} - ${item?.description}`
                  : `${item?.drgCode}`;
                newArr.push({
                  value: item?.drgCode,
                  label: userFriendlyName,
                });
              });

              state.drgCodeRAFilterArr = newArr;
              state.drgCodeForUPFilterArr = newArr;
            }
          } else {
            state.drgCodeRAFilterArr = [];
            state.drgCodeForUPFilterArr = [];
          }

          state.filterLoader.drgCode = false;
        } else {
          // console.log("successdrgCodeFilter__else--->>", data);
          const output = data?.drgList;
          if (output?.length) {
            if (data?.selectedDRGsList?.length > 0) {
              const excludedValues = data?.selectedDRGsList.map(
                (obj) => obj?.drgCode
              );
              const excludedArray = data?.selectedDRGsList.map((obj) => {
                let userFriendlyName = obj?.description
                  ? `${obj?.drgCode} - ${obj?.description}`
                  : `${obj?.drgCode}`;

                return {
                  value: obj?.drgCode,
                  label: userFriendlyName,
                };
              });

              let arrTemp = [];
              let newArr1 = [];
              output?.filter((obj) => {
                if (excludedValues.includes(obj?.drgCode)) {
                  arrTemp.push(obj);
                } else {
                  let userFriendlyName = obj?.description
                    ? `${obj?.drgCode} - ${obj?.description}`
                    : `${obj?.drgCode}`;
                  newArr1.push({
                    value: obj?.drgCode,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...excludedArray);

              state.drgCodeRAFilterArr = newArr1;
              state.drgCodeForUPFilterArr = newArr1;
            } else {
              let newArr = [];

              output?.forEach((item) => {
                let userFriendlyName = item?.description
                  ? `${item?.drgCode} - ${item?.description}`
                  : `${item?.drgCode}`;
                newArr.push({
                  value: item?.drgCode,
                  label: userFriendlyName,
                });
              });

              state.drgCodeRAFilterArr = newArr;
              state.drgCodeForUPFilterArr = newArr;
            }
          } else {
            state.drgCodeRAFilterArr = [];
            state.drgCodeForUPFilterArr = [];
          }

          state.filterLoader.drgCode = false;
        }
      } catch (err) {
        console.log("err--->>", err);
      }
    },
    successDrgCodeFilter2: (state, action) => {
      const data = action?.payload;
      // console.log("successDrgCodeFilter2--->>", data);

      try {
        if (data?.listingFilter) {
          let finalArr = [];
          let data1 = data?.data;
          console.log("successCptCodeFilter2_if--->>", data1);

          if (data1?.drgList?.length) {
            if (data1?.selectedDRGsList?.length > 0) {
              const excludedValues = data1?.selectedDRGsList.map(
                (obj) => obj?.drgCode
              );

              const excludedArray = data1?.selectedDRGsList.map((obj) => {
                let userFriendlyName = obj?.description
                  ? `${obj?.drgCode} - ${obj?.description}`
                  : `${obj?.drgCode}`;

                return {
                  value: obj?.drgCode,
                  label: userFriendlyName,
                };
              });

              let arrTemp = [];
              let newArr1 = [];
              data1?.drgList?.filter((obj) => {
                let userFriendlyName = obj?.description
                  ? `${obj?.drgCode} - ${obj?.description}`
                  : `${obj?.drgCode}`;
                if (excludedValues.includes(obj?.drgCode)) {
                  arrTemp.push({
                    value: obj?.drgCode,
                    label: userFriendlyName,
                  });
                } else {
                  newArr1.push({
                    value: obj?.drgCode,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...(arrTemp?.length ? arrTemp : excludedArray));

              finalArr = newArr1;

              // state.drgCodeRAFilterArr = finalArr;
              handleMultiFilter(
                state,
                finalArr,
                data?.listingFilter,
                "drgCode"
              );
              state.filterLoader.drgCode = false;
            } else {
              let newArr = [];

              data1?.drgList?.forEach((item) => {
                let userFriendlyName = item?.description
                  ? `${item?.drgCode} - ${item?.description}`
                  : `${item?.drgCode}`;
                newArr.push({
                  value: item?.drgCode,
                  label: userFriendlyName,
                });
              });

              finalArr = newArr;

              // state.drgCodeRAFilterArr = finalArr;
              handleMultiFilter(
                state,
                finalArr,
                data?.listingFilter,
                "drgCode"
              );
              state.filterLoader.drgCode = false;
            }
          } else {
            finalArr = [];

            if (data1?.selectedDRGsList?.length) {
              data1?.selectedDRGsList?.forEach((item) => {
                let userFriendlyName = item?.description
                  ? `${item?.drgCode} - ${item?.description}`
                  : `${item?.drgCode}`;
                finalArr.push({
                  value: item?.drgCode,
                  label: userFriendlyName,
                });
              });
            }
            // state.drgCodeRAFilterArr = finalArr;
            handleMultiFilter(state, finalArr, data?.listingFilter, "drgCode");
            state.filterLoader.drgCode = false;
          }
        } else {
          // console.log("successCptCodeFilter2_else--->>", data);
        }
      } catch (e) {
        // console.log("successCptCodeFilter2_e--->>", e);
      }
    },
    failedDrgCodeFilter: (state, action) => {
      state.filterLoader.drgCode = false;
      state.drgCodeRAFilterArr = [];
    },
  },
});

export const {
  //filters

  //DRG Code
  gettingDrgCodeFilter,
  successDrgCodeFilter,
  successDrgCodeFilter2,
  failedDrgCodeFilter,

  //Rendering Provider
  gettingRenderingProviderFilter,
  successRenderingProviderFilter,
  successRenderingProviderFilter2ForSearch,
  failedRenderingProviderFilter,

  //Billing Provider
  gettingBillingProviderFilter,
  successBillingProviderFilter,
  successBillingProviderFilter2ForSearch,
  failedBillingProviderFilter,

  //Payers
  gettingPayerFilter,
  successPayerFilter,
  failedPayerFilter,

  //Service Line
  gettingServiceLineFilter,
  successServiceLineFilter,
  failedServiceLineFilter,

  //Claim Status Code
  gettingClaimStatusFilter,
  successClaimStatusFilter,
  failedClaimStatusFilter,
  //Reason Code
  gettingReasonCodeFilter,
  successReasonCodeFilter,
  failedReasonCodeFilter,
  //Facility
  gettingFacilityFilter,
  successFacilityFilter,
  failedFacilityFilter,
  //Provider
  gettingProviderFilter,
  successProviderFilter,
  failedProviderFilter,
  //Filling Indicator
  gettingFillingIndicatorFilter,
  successFillingIndicatorFilter,
  failedFillingIndicatorFilter,
  //IP Remark Code
  gettingIpRemarkCodeFilter,
  successIpRemarkCodeFilter,
  failedIpRemarkCodeFilter,
  //CPT Code
  gettingCptCodeFilter,
  successCptCodeFilter,
  successCptCodeFilter2,
  failedCptCodeFilter,
  //Group Code
  gettingGroupReasonCodeFilter,
  successGroupReasonCodeFilter,
  failedGroupReasonCodeFilter,
} = claimAllFiltersSlice.actions;

export const getClaimAllFiltersData_Call =
  (
    cptParams,
    payerParams,
    reasonCodeParams,
    providerParams,
    billingProviderParams,
    renderingProviderParams,
    drgCodeParams,
    {
      isServiceLineAPI,
      isPayersAPI,
      isClaimStatusAPI,
      isReasonCodeAPI,
      isProviderAPI,
      isFillingIndicatorAPI,
      isBillingProviderAPI,
      isRenderingProviderAPI,
      isIPOPRemartCodeAPI,
      isCptCodeAPI,
      isGroupCodeAPI,
      isDrgAPI,
    }
  ) =>
  async (dispatch, getState) => {
    try {
      //ServiceLine Filters
      {
        isServiceLineAPI == true
          ? commonPaginationWithCatch({
              getState: getState,
              paramsTemp: {},
              params: {},
              dispatch: dispatch,
              stateValueGetter: gettingServiceLineFilter,
              stateValueSetter: successServiceLineFilter,
              stateValueFailed: failedServiceLineFilter,
              listingAction: "claimPaymentServiceLineFilter",
              apiURL: "CLAIM_PAYMENT_SERVICELINE_FILTER",
              conditionalDispatch: false,
              conditionalApiURL: "",
              conditionalStateValueSetter: "",
            })
          : null;
      }

      //Payer Filters
      {
        isPayersAPI == true
          ? commonPaginationWithCatch({
              getState: getState,
              paramsTemp: payerParams,
              params: payerParams,
              dispatch: dispatch,
              stateValueGetter: gettingPayerFilter,
              stateValueSetter: successPayerFilter,
              stateValueFailed: failedPayerFilter,
              listingAction: "claimPaymentPayerFilter",
              apiURL: "CLAIM_PAYMENT_PAYERS_FILTER",
              conditionalDispatch: false,
              conditionalApiURL: "",
              conditionalStateValueSetter: "",
            })
          : null;
      }

      //Claim Status Code
      {
        isClaimStatusAPI == true
          ? commonPaginationWithCatch({
              getState: getState,
              paramsTemp: {},
              params: {},
              dispatch: dispatch,
              stateValueGetter: gettingClaimStatusFilter,
              stateValueSetter: successClaimStatusFilter,
              stateValueFailed: failedClaimStatusFilter,
              listingAction: "claimPaymentClaimStatusFilter",
              apiURL: "CLAIM_PAYMENT_CLAIMSTATUS_FILTER",
              conditionalDispatch: false,
              conditionalApiURL: "",
              conditionalStateValueSetter: "",
            })
          : null;
      }

      // Reason Code
      {
        isReasonCodeAPI == true
          ? commonPaginationWithCatch({
              getState: getState,
              paramsTemp: reasonCodeParams,
              params: reasonCodeParams,
              dispatch: dispatch,
              stateValueGetter: gettingReasonCodeFilter,
              stateValueSetter: successReasonCodeFilter,
              stateValueFailed: failedReasonCodeFilter,
              listingAction: "claimPaymentReasonCodeFilter",
              apiURL: "CLAIM_PAYMENT_REASONCODE_FILTER",
              conditionalDispatch: false,
              conditionalApiURL: "",
              conditionalStateValueSetter: "",
            })
          : null;
      }

      //Provider
      {
        isProviderAPI == true
          ? commonPaginationWithCatch({
              getState: getState,
              paramsTemp: providerParams,
              params: providerParams,
              dispatch: dispatch,
              stateValueGetter: gettingProviderFilter,
              stateValueSetter: successProviderFilter,
              stateValueFailed: failedProviderFilter,
              listingAction: "claimPaymentProviderFilter",
              apiURL: "CLAIM_PAYMENT_PROVIDER_FILTER",
              conditionalDispatch: false,
              conditionalApiURL: "",
              conditionalStateValueSetter: "",
            })
          : null;
      }

      //FILLING INDICATOR
      {
        isFillingIndicatorAPI == true
          ? commonPaginationWithCatch({
              getState: getState,
              paramsTemp: {},
              params: {},
              dispatch: dispatch,
              stateValueGetter: gettingFillingIndicatorFilter,
              stateValueSetter: successFillingIndicatorFilter,
              stateValueFailed: failedFillingIndicatorFilter,
              listingAction: "claimPaymentFillingIndicatorFilter",
              apiURL: "CLAIM_PAYMENT_FILLING_INDICATOR_FILTER",
              conditionalDispatch: false,
              conditionalApiURL: "",
              conditionalStateValueSetter: "",
            })
          : null;
      }

      //BILLING PROVIDER
      {
        isBillingProviderAPI == true
          ? commonPaginationWithCatch({
              getState: getState,
              paramsTemp: billingProviderParams,
              params: billingProviderParams,
              dispatch: dispatch,
              stateValueGetter: gettingBillingProviderFilter,
              stateValueSetter: successBillingProviderFilter,
              stateValueFailed: failedBillingProviderFilter,
              listingAction: "claimPaymentBillingProviderFilter",
              apiURL: "CLAIM_PAYMENT_BILLING_PROVIDER_FILTER",
              conditionalDispatch: false,
              conditionalApiURL: "",
              conditionalStateValueSetter: "",
            })
          : null;
      }

      //Rendering PROVIDER
      {
        isRenderingProviderAPI == true
          ? commonPaginationWithCatch({
              getState: getState,
              paramsTemp: renderingProviderParams,
              params: renderingProviderParams,
              dispatch: dispatch,
              stateValueGetter: gettingRenderingProviderFilter,
              stateValueSetter: successRenderingProviderFilter,
              stateValueFailed: failedRenderingProviderFilter,
              listingAction: "claimPaymentRenderingProviderFilter",
              apiURL: "CLAIM_PAYMENT_RENDERING_PROVIDER_FILTER",
              conditionalDispatch: false,
              conditionalApiURL: "",
              conditionalStateValueSetter: "",
            })
          : null;
      }

      //IP Remark Code
      {
        isIPOPRemartCodeAPI == true
          ? commonPaginationWithCatch({
              getState: getState,
              paramsTemp: {},
              params: {},
              dispatch: dispatch,
              stateValueGetter: gettingIpRemarkCodeFilter,
              stateValueSetter: successIpRemarkCodeFilter,
              stateValueFailed: failedIpRemarkCodeFilter,
              listingAction: "claimPaymentIpRemarkCodeFilter",
              apiURL: "CLAIM_PAYMENT_IPREMARK_CODE_FILTER",
              conditionalDispatch: false,
              conditionalApiURL: "",
              conditionalStateValueSetter: "",
            })
          : null;
      }

      //DRG Filter
      {
        isDrgAPI == true
          ? commonPaginationWithCatch({
              getState: getState,
              paramsTemp: drgCodeParams,
              params: drgCodeParams, // ---- drgCodeParams
              dispatch: dispatch,
              stateValueGetter: gettingDrgCodeFilter,
              stateValueSetter: successDrgCodeFilter,
              stateValueFailed: failedDrgCodeFilter,
              listingAction: "claimPaymentDrgCodeFilter",
              apiURL: "CLAIM_PAYMENT_DRG_FILTER",
              conditionalDispatch: false,
              conditionalApiURL: "",
              conditionalStateValueSetter: "",
            })
          : null;
      }

      //CPT Code
      {
        isCptCodeAPI == true
          ? commonPaginationWithCatch({
              getState: getState,
              paramsTemp: cptParams,
              params: cptParams, // ---- cptParams
              dispatch: dispatch,
              stateValueGetter: gettingCptCodeFilter,
              stateValueSetter: successCptCodeFilter,
              stateValueFailed: failedCptCodeFilter,
              listingAction: "claimPaymentCptCodeFilter",
              apiURL: "CLAIM_PAYMENT_CPTCODE_FILTER",
              conditionalDispatch: false,
              conditionalApiURL: "",
              conditionalStateValueSetter: "",
            })
          : null;
      }

      //Group Reason Code
      {
        isGroupCodeAPI == true
          ? commonPaginationWithCatch({
              getState: getState,
              paramsTemp: {},
              params: {},
              dispatch: dispatch,
              stateValueGetter: gettingGroupReasonCodeFilter,
              stateValueSetter: successGroupReasonCodeFilter,
              stateValueFailed: failedGroupReasonCodeFilter,
              listingAction: "claimPaymentGroupReasonCodeFilter",
              apiURL: "CLAIM_PAYMENT_GROUPREASONCODE_FILTER",
              conditionalDispatch: false,
              conditionalApiURL: "",
              conditionalStateValueSetter: "",
            })
          : null;
      }
    } catch (e) {
      console.log("error --->>", e);

      return false;
    }
  };

// common search handler start here ===============================
const createCommonCustomSearchHandler = (
  filterKey,
  filterKeyword,
  apiRoute,
  dispatchSuccessAction,
  dispatchSuccessAction2,
  dispatchFailureAction,
  dispatchFailureAction2
) => {
  // console.log("createCommonCustomSearchHandler--->>");
  return (searchVal, setSearchLoading, listingFilter) =>
    async (dispatch, getState) => {
      const sliceState = getState().npReducers.filters[listingFilter];

      // Define an array of listing filters where you want to apply a common filter
      const filtersWithCommonFilter = [
        "claimPayment",
        "denialByServiceLineFilter",
        "denialByServiceLineGroupByPYCNFilter",
        "denialDetailsFilter",
        "drugAccRateCommonFilter",
        "underPaymentFilters",
        // "serviceLineDenialsReportFilter",
      ];

      // Determine the filter key based on the listing filter type
      const listingFilterKey = filtersWithCommonFilter.includes(listingFilter)
        ? "denialSreensCommonFilter"
        : listingFilter === "providerDashboardFilter"
        ? "insightsSreensCommonFilter"
          ? "denialSreensCommonFilter"
          : listingFilter === "underPaymentFilters"
        : listingFilter;

      // Get the slice state for the selected filter
      const sliceStateForDate = getState().npReducers.filters[listingFilterKey];

      try {
        const filterParams = {
          ...(sliceStateForDate?.from !== "" && {
            from: sliceStateForDate?.from,
          }),
          ...(sliceStateForDate?.to !== "" && {
            to: sliceStateForDate?.to,
          }),
          [filterKeyword]: searchVal ? searchVal : "",
          start: 0,
          limit: 100,
          ...(sliceState?.[filterKey]?.length > 0 && {
            [`selected${
              filterKey.charAt(0).toUpperCase() + filterKey.slice(1)
            }`]: getMultiSelectFilterValueForApi(sliceState?.[filterKey]),
          }),
        };

        // console.log("filterParams--->>", filterParams);

        const res = await genericAxiosCall(apiRoute, "get", "", filterParams)
          .then((response) => {
            if (response?.data?.success) {
              const data = {
                data: response?.data?.data,
                listingFilter,
              };

              if (searchVal === "") {
                dispatch(dispatchSuccessAction(data));
              } else {
                dispatch(dispatchSuccessAction2(data));
              }

              setSearchLoading(false);
              return data;
            } else {
              if (searchVal === "") {
                dispatch(dispatchFailureAction([]));
              } else {
                dispatch(dispatchFailureAction2([]));
              }
            }
          })
          .catch((error) => {
            if (searchVal === "") {
              dispatch(dispatchFailureAction([]));
            } else {
              dispatch(dispatchFailureAction2([]));
            }
          });

        return res;
      } catch (err) {
        // Handle errors if needed
        console.log("createCommonCustomSearchHandler_err--->>", err);
      }
    };
};

export const commonCustomSearch835BillingProviderHandler =
  createCommonCustomSearchHandler(
    "billingProviders",
    "searchKeyword",
    serverRoutes.CLAIM_PAYMENT_BILLING_PROVIDER_FILTER,
    successBillingProviderFilter,
    successBillingProviderFilter2ForSearch,
    successBillingProviderFilter,
    successBillingProviderFilter2ForSearch
  );

export const commonCustomSearch835RenderingProviderHandler =
  createCommonCustomSearchHandler(
    "renderingProviders",
    "searchKeyword",
    serverRoutes.CLAIM_PAYMENT_RENDERING_PROVIDER_FILTER,
    successRenderingProviderFilter,
    successRenderingProviderFilter2ForSearch,
    successRenderingProviderFilter,
    successRenderingProviderFilter2ForSearch
  );

export const commonCustomSearch835DrgHandler = createCommonCustomSearchHandler(
  "DRGs",
  "DRGCode",
  serverRoutes.CLAIM_PAYMENT_DRG_FILTER,
  successDrgCodeFilter,
  successDrgCodeFilter2,
  successDrgCodeFilter,
  successDrgCodeFilter2
);

// common cpt filter handler
export const commonCustomSearchCPTHandler =
  (searchVal, setSearchLoading, listingFilter) =>
  async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters[listingFilter];

    // Define an array of listing filters where you want to apply a common filter
    const filtersWithCommonFilter = [
      "claimPayment",
      "denialByServiceLineFilter",
      "denialByServiceLineGroupByPYCNFilter",
      "denialDetailsFilter",
      "drugAccRateCommonFilter",
      "underPaymentFilters",
      "drugAccRateFilter",
      "denialsByCptScreenFilter",

      // "serviceLineDenialsReportFilter",
    ];

    console.log("commonCustomSearchCPTHandler--->> 11");

    // console.log("listingFilter--->>", listingFilter);
    // console.log(
    //   " filtersWithCommonFilter.includes(listingFilter)--->>",
    //   filtersWithCommonFilter.includes(listingFilter)
    // );

    // Determine the filter key based on the listing filter type
    const filterKey = filtersWithCommonFilter.includes(listingFilter)
      ? listingFilter == "denialSreensCommonFilter"
        ? "denialSreensCommonFilter"
        : listingFilter == "drugAccRateFilter"
        ? "insightsSreensCommonFilter"
        : listingFilter == "denialsByCptScreenFilter"
        ? "insightsSreensCommonFilter"
        : listingFilter === "drugsRejectedGBClaimFilter"
        ? "drugsRejectedFilter"
        : listingFilter === "claimPayment"
        ? "denialSreensCommonFilter"
        : listingFilter === "underPaymentFilters"
        ? "denialSreensCommonFilter"
        : listingFilter === "denialByServiceLineFilter"
        ? "denialSreensCommonFilter"
        : listingFilter === "denialByServiceLineGroupByPYCNFilter"
        ? "denialSreensCommonFilter"
        : listingFilter === "denialDetailsFilter"
        ? "denialSreensCommonFilter"
        : listingFilter
      : listingFilter;

    // const filterKey = filtersWithCommonFilter.includes(listingFilter)
    //   ? "denialSreensCommonFilter"
    //   : listingFilter == "drugAccRateFilter"
    //   ? "drugAccRateCommonFilter"
    //   : listingFilter == "denialsByCptScreenFilter"
    //   ? "drugAccRateCommonFilter"
    //   : listingFilter === "drugsRejectedGBClaimFilter"
    //   ? "drugsRejectedFilter"
    //   : listingFilter;

    // Get the slice state for the selected filter
    const sliceStateForDate = getState().npReducers.filters[filterKey];

    try {
      const cptParams = {
        CPTCode: searchVal ? searchVal : "",
        start: 0,
        limit: 100,
        ...(sliceState?.cptCodes?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),
        ...(!sliceStateForDate?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceStateForDate?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
      };

      let res = await genericAxiosCall(
        serverRoutes?.CLAIM_PAYMENT_CPTCODE_FILTER,
        "get",
        "",
        cptParams
      )
        .then((res) => {
          if (res?.data?.success) {
            const data = {
              data: res?.data?.data,
              listingFilter: listingFilter,
            };
            // const data = res?.data?.data;

            if (searchVal === "") {
              dispatch(successCptCodeFilter(data));
            } else {
              dispatch(successCptCodeFilter2(data));
            }
            setSearchLoading(false);
            return data;
          } else {
            if (searchVal === "") {
              dispatch(successCptCodeFilter([]));
            } else {
              dispatch(successCptCodeFilter2([]));
            }
          }
        })
        .catch((error) => {
          if (searchVal === "") {
            dispatch(successCptCodeFilter([]));
          } else {
            dispatch(successCptCodeFilter2([]));
          }
        });
      return res;
    } catch (e) {
      // console.log(_e--->>", e);
    }
  };

// hanlder filters switch statement
function handleMultiFilter(state, arr, listingFilter, filterType) {
  // console.log("handleMultiFilter--->>", arr, listingFilter, filterType);

  switch (listingFilter) {
    case "drugAccRateFilter": // acceptence rate first Tab
      if (filterType == "cptCode") {
        state.cptCodesForRACFilterArr = arr;
        state.filterLoader.cptCode = false;
      } else if (filterType == "billingProvider") {
        state.billingProviderForRAFilterArr = arr;
        state.filterLoader.billingProvider = false;
      } else if (filterType == "renderingProvider") {
        state.renderingProviderForRAFilterArr = arr;
        state.filterLoader.renderingProvider = false;
      }
      break;
    case "claimPayment": // #01 Remittance advice first Tab
      if (filterType == "cptCode") {
        state.cptCodesForRAFilterArr = arr;
        state.filterLoader.cptCode = false;
      } else if (filterType == "billingProvider") {
        state.billingProviderForRAFilterArr = arr;
        state.filterLoader.billingProvider = false;
      } else if (filterType == "renderingProvider") {
        state.renderingProviderForRAFilterArr = arr;
        state.filterLoader.renderingProvider = false;
      } else if (filterType == "drgCode") {
        state.drgCodeRAFilterArr = arr;
        state.filterLoader.drgCode = false;
      }
      break;

    case "underPaymentFilters": // Under Payment screen
      if (filterType == "cptCode") {
        state.cptCodesForUPFilterArr = arr;
        state.filterLoader.cptCode = false;
      } else if (filterType == "billingProvider") {
        state.billingProviderForUPFilterArr = arr;
        state.filterLoader.billingProvider = false;
      } else if (filterType == "renderingProvider") {
        state.renderingProviderForUPFilterArr = arr;
        state.filterLoader.renderingProvider = false;
      } else if (filterType == "drgCode") {
        state.drgCodeForUPFilterArr = arr;
        state.filterLoader.drgCode = false;
      }
      break;

    case "denialByServiceLineFilter": // #03 Remittance advice third Tab (Claim Adjustments group by service Line)
      if (filterType == "cptCode") {
        state.cptCodesForFilterArr = arr;
        state.filterLoader.cptCode = false;
      } else if (filterType == "billingProvider") {
        state.billingProviderForCAGroupBySLFilterArr = arr;
        state.filterLoader.billingProvider = false;
      } else if (filterType == "renderingProvider") {
        state.renderingProviderForCAGroupBySLFilterArr = arr;
        state.filterLoader.renderingProvider = false;
      }
      break;

    case "denialDetailsFilter": // #02 Remittance advice second Tab (Denial by Claims)
      if (filterType == "cptCode") {
        state.cptCodesForDBCFilterArr = arr;
        state.filterLoader.cptCode = false;
      } else if (filterType == "billingProvider") {
        state.billingProviderForDBCFilterArr = arr;
        state.filterLoader.billingProvider = false;
      } else if (filterType == "renderingProvider") {
        state.renderingProviderForDBCFilterArr = arr;
        state.filterLoader.renderingProvider = false;
      }
      break;

    case "denialByServiceLineGroupByPYCNFilter": // #04 Remittance advice third Tab (Claim Adjustments group by claim)
      if (filterType == "cptCode") {
        state.cptCodesForCLFilterArr = arr;
        state.filterLoader.cptCode = false;
      } else if (filterType == "billingProvider") {
        state.billingProviderForCAGroupByCLFilterArr = arr;
        state.filterLoader.billingProvider = false;
      } else if (filterType == "renderingProvider") {
        state.renderingProviderForCAGroupByCLFilterArr = arr;
        state.filterLoader.renderingProvider = false;
      }
      break;

    case "denialsByCptScreenFilter": // #05 Recurring Denials (onCology last Tab)
      if (filterType == "cptCode") {
        state.cptCodesForRDFilterArr = arr;
        state.filterLoader.cptCode = false;
      } else if (filterType == "billingProvider") {
        state.billingProviderForRDFilterArr = arr;
        state.filterLoader.billingProvider = false;
      } else if (filterType == "renderingProvider") {
        state.renderingProviderForRDFilterArr = arr;
        state.filterLoader.renderingProvider = false;
      }
      break;

    case "serviceLineDenialsReportFilter": // #06 Denials by Procedure (reports section)
      if (filterType == "cptCode") {
        state.cptCodesForDBPFilterArr = arr;
        state.filterLoader.cptCode = false;
      } else if (filterType == "billingProvider") {
        state.billingProviderForDBPFilterArr = arr;
        state.filterLoader.billingProvider = false;
      } else if (filterType == "renderingProvider") {
        state.renderingProviderForDBPFilterArr = arr;
        state.filterLoader.renderingProvider = false;
      }
      break;

    case "providerDashboardFilter": // #07 Denials by Procedure (reports section)
      if (filterType == "billingProvider") {
        state.billingProviderForPDFilterArr = arr;
        state.filterLoader.billingProvider = false;
      } else if (filterType == "renderingProvider") {
        state.renderingProviderForPDFilterArr = arr;
        state.filterLoader.renderingProvider = false;
      }

    case "drugsRejectedFilter": // #08 Denial Report (reports section)
      if (filterType == "cptCode") {
        state.cptCodesForDRFilterArr = arr;
        state.filterLoader.cptCode = false;
      }

      //
      break;

    default:
      // billingProvider filters
      state.billingProviderForRAFilterArr = arr; // Remittance Advice  == "claimPayment"
      state.billingProviderForDBCFilterArr = arr; // Denial by Claims  == "denialDetailsFilter"
      state.billingProviderForCAGroupByCLFilterArr = arr; // Claim Adjustments (group by Claim Level) == "denialByServiceLineGroupByPYCNFilter"
      state.billingProviderForCAGroupBySLFilterArr = arr; // Claim Adjustments (group by Service Level)  == "denialByServiceLineFilter"
      state.billingProviderForRDFilterArr = arr; // Recurring Denials (onCology last Tab) == "denialsByCptScreenFilter"
      state.billingProviderForDBPFilterArr = arr; //  Denials by Procedure (reports section) == "serviceLineDenialsReportFilter"
      state.billingProviderForPDFilterArr = arr; //  Provider dashboard (insights section) == "providerDashboardFilter"

      // RenderingProvider filters
      state.renderingProviderForRAFilterArr = arr; // Remittance Advice  == "claimPayment"
      state.renderingProviderForDBCFilterArr = arr; // Denial by Claims  == "denialDetailsFilter"
      state.renderingProviderForCAGroupByCLFilterArr = arr; // Claim Adjustments (group by Claim Level) == "denialByServiceLineGroupByPYCNFilter"
      state.renderingProviderForCAGroupBySLFilterArr = arr; // Claim Adjustments (group by Service Level)  == "denialByServiceLineFilter"
      state.renderingProviderForRDFilterArr = arr; // Recurring Denials (onCology last Tab) == "denialsByCptScreenFilter"
      state.renderingProviderForDBPFilterArr = arr; //  Denials by Procedure (reports section) == "serviceLineDenialsReportFilter"
      state.renderingProviderForPDFilterArr = arr; //  Provider dashboard (insights section) == "providerDashboardFilter"

      // cptCode filter arrays
      state.cptCodesForFilterArr = arr;
      state.cptCodesForRAFilterArr = arr;
      state.cptCodesForRACFilterArr = arr;

      state.cptCodesForDRFilterArr = arr;
      state.cptCodesForCLFilterArr = arr;
      state.cptCodesForRDFilterArr = arr;
      state.cptCodesForDBCFilterArr = arr;
      state.cptCodesForCAdjCLFilterArr = arr;
      state.cptCodesForDBPFilterArr = arr;

      // drgCode filter array
      state.drgCodeRAFilterArr = arr;
      state.drgCodeForUPFilterArr = arr;

      // loaders
      state.filterLoader.cptCode = false;
      state.filterLoader.billingProvider = false;
      state.filterLoader.renderingProvider = false;
      state.filterLoader.drgCode = false;

      break;
  }
}

export default claimAllFiltersSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

import report from "./../../../actions/report";
import {
  setListingState,
  resetListingState,
} from "../filters/claimManagerFilters";
import {
  commonPagination,
  getCurrentClientId,
  getMultiSelectFilterValueFor837Payers,
  getMultiSelectFilterValueForApi,
} from "../../../components/common/Utils/utils";
import { getClaimAllFiltersData_Call } from "../DenialDetails/claimAllFiltersSlice";
import store from "../../store";

export const revenuebyPayerSlice = createSlice({
  name: "revenuebyPayerSlice",
  initialState: {
    revenebyAllPayer: [],
    denialsByPayerData: { tabularArr: [], count: 0 },

    revenuebyPayerDetailList: [],
  },
  reducers: {
    setRevenueByAllPayer: (state, action) => {
      state.revenebyAllPayer = action.payload;
    },
    setDenialsByPayer_Data: (state, action) => {
      let data = action.payload;

      if (data?.output?.length) {
        state.denialsByPayerData.tabularArr = data?.output;
        state.denialsByPayerData.count = data?.output[0]?.TotalNoRecords;
      } else {
        state.denialsByPayerData.tabularArr = [];
        state.denialsByPayerData.count = 0;
      }
    },

    setRevenuebyPayerDetailList: (state, action) => {
      state.revenuebyPayerDetailList = action.payload;
    },
  },
});

export const {
  setRevenueByAllPayer,
  setRevenuebyPayerDetailList,
  setDenialsByPayer_Data,
} = revenuebyPayerSlice.actions;

export const getRevenuebyPayerAllViewCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "revenueByPayerFilter",
          ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.revenueByPayerFilter;
    let sliceStateForDate =
      getState().npReducers.filters.reportSreensCommonFilter;

    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      ///// API CALL //////

      let paramsTemp = {
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate?.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate?.to,
        }),
      };

      let params = {
        start,
        limit,
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate?.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate?.to,
        }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setRevenuebyPayerDetailList,
        "revenueByPayerFilter",
        "GET_REVENUE_BY_PAYER_GB_ALL",
        null,
        "",
        ""
      );

      return 0;
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

export const getRevenuebyPayerViewCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "revenueByPayerFilter",
          ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.revenueByPayerFilter;

    let sliceStateForDate = getState().npReducers.filters.revenueByPayerFilter;
    let sliceStateForClientId =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceStateUser = getState().pReducers.user;

    let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;

    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable
        ?.revenueByPayerFilterNoOfRecordInTable;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      ///// API CALL //////

      let paramsTemp = {
        // start,
        // limit,
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };

      let params = {
        start,
        limit,
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setRevenueByAllPayer,
        "revenueByPayerFilter",
        "GET_REVENUE_BY_PAYER_GB_PAYER_BY_REVENUE",
        null,
        "",
        ""
      );

      ////// API CALL END //////////////
      return 0;
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

export const getOnePayerDataCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "revenueByPayerFilter",
          ignore: [],
        })
      );
    }

    let sliceState = getState().npReducers.filters.revenueByPayerFilter;
    let sliceStateForDate =
      getState().npReducers.filters.reportSreensCommonFilter;

    let start = sliceState?.pageNumber;
    let limit = sliceState?.pageSize;

    try {
      ///// API CALL //////

      const res = await report.getOnePayerData({
        start,
        limit,
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate?.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate?.to,
        }),
        ...(sliceState?.payers !== "" && {
          payers: sliceState.payers,
        }),
      });

      ////// API CALL END //////////////

      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };

// Denials by payer screen on reports section
export const getDenialsByPayer_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialByPayerFilter",
          ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.denialByPayerFilter;
    let sliceStateUser = getState().pReducers.user;

    let sliceStateForDate =
      getState().npReducers.filters.reportSreensCommonFilter;
    let sliceStateForClientId =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;
    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable
        ?.denialByPayerFilterNoOfRecordInTable;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      ///// API CALL //////

      let paramsTemp = {
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };

      let params = {
        start,
        limit,
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
          // from: "01/01/2021",
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
          // to: "01/31/2021",
        }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };

      const payerParams = {
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };
      const drgCodeParams = {};

      // console.log("params--->>", params);

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      await commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setDenialsByPayer_Data,
        "denialByPayerFilter",
        "GET_DENIAL_BY_PAYERS",
        null,
        "",
        ""
      );

      // filters api call here
      dispatch(
        getClaimAllFiltersData_Call(
          {},
          payerParams,
          {},
          {},
          {},
          {},
          drgCodeParams,
          {
            isServiceLineAPI: false,
            isPayersAPI: true,
            isClaimStatusAPI: false,
            isReasonCodeAPI: false,
            isProviderAPI: false,
            isFillingIndicatorAPI: false,
            isBillingProviderAPI: false,
            isRenderingProviderAPI: false,
            isIPOPRemartCodeAPI: false,
            isCptCodeAPI: false,
            isGroupCodeAPI: false,
            isDrgAPI: false,
          }
        )
      );

      ////// API CALL END //////////////
      return 0;
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

export default revenuebyPayerSlice.reducer;

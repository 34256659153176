import { createSlice } from "@reduxjs/toolkit";
import CommonService from "../../../actions/common";

export const commonData = createSlice({
  name: "commonData",
  initialState: {
    cptCodes: [],
    payers: [],
    reasonCodes: [],
  },
  reducers: {
    setCptCodes: (state, action) => {
      state.cptCodes = action.payload;
    },

    setPayers: (state, action) => {
      state.payers = action.payload;
    },

    setReasonCodes: (state, action) => {
      state.reasonCodes = action.payload;
    },
  },
});

export const { setCptCodes, setPayers, setReasonCodes } = commonData.actions;

export const gettingPayers = () => async (dispatch) => {
  const payers = await CommonService.getPayers();
  dispatch(setPayers(payers.data.data));
  return payers.data.data;
};

export const gettingcptCodes = () => async (dispatch) => {
  const cptCodes = await CommonService.getCPTCodes();
  dispatch(setCptCodes(cptCodes.data.data));
  return cptCodes.data.data;
};

export const gettingReasonCodes = () => async (dispatch) => {
  const reasonCodes = await CommonService.getReasonCodes();
  dispatch(setReasonCodes(reasonCodes.data.data));
  return reasonCodes.data.data;
};

export default commonData.reducer;

import { createSlice } from "@reduxjs/toolkit";

import UserService from "./../../../actions//user";

import {
  setListingState,
  resetListingState,
} from "../filters/claimManagerFilters";
import { publicIpv4 } from "public-ip";

export const activityLogSlice = createSlice({
  name: "activityLogSlice",
  initialState: {
    allActivityLog: [],
    count: 0,
  },
  reducers: {
    setAllActivityLog_Action: (state, action) => {
      state.allActivityLog = action.payload?.activityLog;
      state.count = action.payload?.count;
    },
  },
});

export const { setAllActivityLog_Action } = activityLogSlice.actions;

export const getActivityLogData =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "activityLogFilter",
          ignore: [],
        })
      );
    }

    let sliceState = getState().npReducers.filters.activityLogFilter;
    // let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;

    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;
    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      dispatch(
        setListingState({
          listing: "activityLogFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const res = await UserService.getActivityLogs({
        start,
        limit,
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceState.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState.to !== "" && {
          to: sliceState.to,
        }),
      });

      ////// API CALL END //////////////

      dispatch(
        setListingState({
          listing: "activityLogFilter",
          value: {
            hasMore:
              res?.data?.data?.activityLog?.length === sliceState.pageSize,
            loadingMore: "",
          },
        })
      );
      dispatch(setAllActivityLog_Action(res?.data?.data));
      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "activityLogFilter",
          value: {
            initialLoading: false,
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      return false;
    }
  };

// save logAcitivity
export const saveLogForLogActivity =
  (activity) => async (dispatch, getState) => {
    let sliceState = getState().pReducers.user;

    let userId = sliceState.loginUserData.userId;

    let ip = await publicIpv4();

    let body = {
      Activity: activity,
      Category: "",
      UserId: userId,
      IPAddress: ip,
    };

    try {
      ///// API CALL //////

      const res = await UserService.setActivityLogs(body);

      ////// API CALL END //////////////

      return res?.data;
    } catch (e) {
      // console.log("saveLogForLogActivity_e--->>", e);
      return false;
    }
  };

export default activityLogSlice.reducer;

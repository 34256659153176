import React from "react";

const ArrowUpMenu = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-chevron-up"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="#9e9e9e"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <polyline points="6 15 12 9 18 15" />
    </svg>
  );
};

export default ArrowUpMenu;

import React from "react";

const V2ReportsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-pie-chart"
    >
      <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
      <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
    </svg>
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 14 14"
    //   height="24"
    //   width="24"
    // >
    //   <g>
    //     <path
    //       d="M8.5,3.5v-1a1,1,0,0,0-1-1h-1"
    //       fill="none"

    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //     ></path>
    //     <path
    //       d="M4,10.5H1.5a1,1,0,0,1-1-1v-7a1,1,0,0,1,1-1h1"
    //       fill="none"

    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //     ></path>
    //     <rect
    //       x="6.5"
    //       y="5.5"
    //       width="7"
    //       height="8"
    //       rx="1"
    //       fill="none"

    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //     ></rect>
    //     <path
    //       d="M6.75.5H2.25l.41,1.62a.49.49,0,0,0,.48.38H5.86a.49.49,0,0,0,.48-.38Z"
    //       fill="none"

    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //     ></path>
    //     <line
    //       x1="8.5"
    //       y1="8.5"
    //       x2="11.5"
    //       y2="8.5"
    //       fill="none"

    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //     ></line>
    //     <line
    //       x1="8.5"
    //       y1="10.5"
    //       x2="11.5"
    //       y2="10.5"
    //       fill="none"

    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //     ></line>
    //   </g>
    // </svg>

    // <svg
    //   width="24"
    //   height="24"
    //   viewBox="0 0 24 24"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <g opacity="0.7">
    //     <path
    //       d="M7 14.69C6.59 14.69 6.25 14.35 6.25 13.94V10.73C6.25 10.32 6.59 9.98 7 9.98C7.41 9.98 7.75 10.32 7.75 10.73V13.94C7.75 14.36 7.41 14.69 7 14.69Z"
    //       fill="#092C4C"
    //     />
    //     <path
    //       d="M12 16.43C11.59 16.43 11.25 16.09 11.25 15.68V9C11.25 8.59 11.59 8.25 12 8.25C12.41 8.25 12.75 8.59 12.75 9V15.68C12.75 16.09 12.41 16.43 12 16.43Z"
    //       fill="#092C4C"
    //     />
    //     <path
    //       d="M17 14.69C16.59 14.69 16.25 14.35 16.25 13.94V10.73C16.25 10.32 16.59 9.98 17 9.98C17.41 9.98 17.75 10.32 17.75 10.73V13.94C17.75 14.36 17.41 14.69 17 14.69Z"
    //       fill="#092C4C"
    //     />
    //     <path
    //       d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
    //       fill="#092C4C"
    //     />
    //   </g>
    // </svg>
  );
};

export default V2ReportsIcon;

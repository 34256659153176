import React, { Fragment, useEffect, useState } from "react";
import CrossIcon from "../../assets/svgs/CrossIcon";
import { DownloadIcon } from "../../assets/svgs/DownloadIcon";
import PDFFileIcon from "../../assets/ZoeImages/pdf-file.svg";
import { common } from "../../actions";
import {
  base64ToPdfAndDownload,
  encodeString,
  ErrorToast,
  UnSuccessfullToast,
} from "../../components/common/Utils/utils";
import Loading from "../../components/common/ExpandableTable/Loading";
import { returnCotentMessage } from "../../components/common/Utils/copilot";
import browserRoute from "../../constants/browserRoutes";

const DetailContentBot = ({
  showTableColumnFilter,
  handleTableColumnOpen,
  detailContent,
}) => {
  const [content, setContent] = useState([]);
  const [showFullText, setShowFullText] = useState({});

  const intitalRenderContent = (detailContent) => {
    if (Object?.keys(detailContent)?.length) {
      let arr = [];
      const modifiedAndMergedArray = detailContent?.referenceArr;
      const mainRow = detailContent?.mainRow;
      modifiedAndMergedArray?.forEach((item, index) => {
        const contentMessage =
          item?.contentNew?.[Object?.keys(item?.contentNew)?.[0]];
        const citationString = returnCotentMessage(contentMessage);
        arr.push({
          content: citationString,
          mainRow: mainRow,
          citationString: citationString,
          title: item?.title,
        });
      });
      setContent(arr);
    }
  };
  const DownloadButton = ({ item }) => {
    const [loading, setLoading] = useState(false);
    const handleClick = () => {
      if (!loading) {
        setLoading(true);
        common
          .getPolicyDoc(
            item?.title,
            item?.mainRow?.state,
            item?.mainRow?.index,
            item?.mainRow?.allMac
          )
          .then((res) => {
            if (res?.data?.content) {
              base64ToPdfAndDownload(
                res?.data?.content,
                res?.data?.contentType,
                item?.title
              );
            } else {
              UnSuccessfullToast(res);
            }
          })
          .catch((err) => {
            ErrorToast(err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    };
    const handleClickRedirect = () => {
      const mainRow = item?.mainRow;
      const url = `${browserRoute?.PDF_VIEW_COPILOT}?fileName=${encodeString(
        item?.title
      )}&mac=${mainRow?.state}${
        mainRow?.allMac ? `&allMac=${mainRow?.allMac}` : ""
      }&index=${mainRow?.index}&botName=policycopilot${
        mainRow?.stateMac ? `&stateMac=${mainRow?.stateMac}` : ""
      }`;

      window.open(url);
    };
    return (
      <div className="DR-pdf-bot-file" onClick={handleClickRedirect}>
        <img src={PDFFileIcon} />
        <span className="download-text-bot">{item?.title}</span>
        {loading && (
          <span className="download-icon-bot">
            <Loading loaderWidth={20} loaderHeight={20} />
          </span>
        )}
      </div>
    );
  };
  const toggleFullText = (index) => {
    setShowFullText((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const displayContent = (content) => {
    return (
      <>
        {content?.split("\n")?.map((item, index) => (
          <pre key={index} style={{ whiteSpace: "pre-wrap" }}>
            {index === 0 ? (
              <span dangerouslySetInnerHTML={{ __html: `<b>${item}</b>` }} />
            ) : (
              item
            )}
          </pre>
        ))}
      </>
    );
  };
  const renderContent = (item, index) => {
    const wordLimit = 30;
    const words = item?.content?.split(" ");
    const limitedText = words.slice(0, wordLimit).join(" ");
    const isContentLong = words.length > wordLimit;

    return (
      <div className="DR-refrance-card" key={index}>
        <pre style={{ whiteSpace: "pre-wrap" }}>
          {showFullText[index] || !isContentLong
            ? displayContent(`${index + 1}. ${item?.content}`)
            : displayContent(`${index + 1}. ${limitedText}...`)}
          {isContentLong && (
            <p onClick={() => toggleFullText(index)} className="DR-tb-link">
              {showFullText[index] ? "See Less" : "See More"}
            </p>
          )}
        </pre>

        <div
          className=" mb-1"
          style={{
            maxWidth: "300px",
            height: "34px",
            marginTop: "40px",
          }}
        >
          <DownloadButton item={item} />
        </div>
      </div>
    );
  };
  useEffect(() => {
    intitalRenderContent(detailContent);
  }, [detailContent]);

  return (
    <div
      className={
        "filter-sidebar dr-v2-side DR-Alet-msg DR-Ref-detail " +
        showTableColumnFilter
      }
      style={{ width: "650px", overflowY: "hidden" }}
    >
      <div>
        <div
          className="flex items-center justify-between"
          style={{
            borderBottom: "1px solid #efefef",
            backgroundColor: "#efefef",
            paddingTop: "5px",
            paddingBottom: "5px",
          }}
        >
          <h3 className="pl-3" style={{ fontWeight: "500" }}>
            Reference
          </h3>
          <div className="flex items-center gap-1">
            <span
              className="pr-3 cursor-pointer flex items-center justify-center"
              onClick={handleTableColumnOpen}
            >
              <span
              // className="Cross-icon-white-colr"
              >
                <CrossIcon />
              </span>
            </span>
          </div>
        </div>
        <div
          className="DR-Chat-ref-details p-3"
          style={{ maxHeight: "calc(100vh - 100px)", overflowY: "auto" }}
        >
          {/* {content?.map((item, index) => {
            return (
              <div
                style={{ marginBottom: "20vh", marginTop: "40px" }}
                key={index}
              >
                <pre style={{ whiteSpace: "pre-wrap" }}>{item?.content}</pre>
                <div
                  className="DR-pdf-bot-file mb-1"
                  style={{
                    maxWidth: "300px",
                    height: "34px",
                    marginTop: "40px",
                    backgroundColor: "#edf0ff",
                  }}
                >
                  <DownloadButton item={item} />
                </div>
              </div>
            );
          })} */}
          {content?.map((item, index) => renderContent(item, index))}
        </div>
      </div>
    </div>
  );
};

export default DetailContentBot;

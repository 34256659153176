import axios from '../AxiosConfig/axios';
import { serverRoutes } from './../constants/serverRoutes';
const subId = localStorage.getItem('subscriptionID');

const tags = {
  getAllTags() {
    return axios.get(serverRoutes.GET_TAGS, {
      headers: { 'X-Subscription': subId },
    });
  },
  getTagFlagAssignmentDetails(ids) {
    return axios.get(
      `/Common/GetTagFlagAssignmentDetails?regardingIds=${ids}`,
      {
        headers: { 'X-Subscription': subId },
      }
    );
  },
  updateLabel(id, name, description, color) {
    color = color.replace('#', '');
    // return false;
    return axios.put(`/tag/${id}/${name}/${color}/${description}`, {
      headers: { 'X-Subscription': subId },
    });
  },
  getAllFlags() {
    return axios.get(serverRoutes.GET_FLAGS, {
      headers: { 'X-Subscription': subId },
    });
  },
  createAssigning(body) {
    return axios.post(serverRoutes.CREATE_ASSIGNEE, body, {
      headers: { 'X-Subscription': subId },
    });
  },
  createFlaging(body) {
    return axios.post(serverRoutes.CREATE_FLAGS, body, {
      headers: { 'X-Subscription': subId },
    });
  },
  createTagging(body) {
    return axios.post(serverRoutes.CREATE_TAGS, body, {
      headers: { 'X-Subscription': subId },
    });
  },
};

export default tags;

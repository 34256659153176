import { createSlice } from "@reduxjs/toolkit";

import {
  resetListingState,
  setListingState,
} from "../filters/claimManagerFilters";
import {
  commonPagination,
  getDescArray,
  getRandom_rgba,
  getTotalDenialAmt,
  getMultiSelectFilterValueForApi,
  getFilterValuesByKey,
  getCurrentClientId,
} from "../../../components/common/Utils/utils";
import { gettingSmartFilterForOncologyDenial_Call } from "./OncologyDashboardKpisSlice";

export const OnCologyDenialDashKpisSlice = createSlice({
  name: "OnCologyDenialDashKpisSlice",
  initialState: {
    // cptCodeTreeMap Arrays here
    cptAllCodesArr: [],
    cptAdminCodesArr: [],
    cptAncillaryCodesArr: [],
    cptJcodeCodesArr: [],
    cptPremedCodesArr: [],

    // reasonCodeTreeMap Arrays here
    carcAllCodesArr: [],
    carcAdminCodesArr: [],
    carcAncillaryCodesArr: [],
    carcJcodeCodesArr: [],
    carcPremedCodesArr: [],
    serviceLineFilterArr: [],

    filterLoader: {
      // carc loader
      carcAllCodeLoading: true,
      carcJCodeLoading: true,
      carcAncillaryLoading: true,
      carcPreMedLoading: true,
      carcAdminCodeLoading: true,

      // cpt loader
      cptAllCodeLoading: true,
      cptJCodeLoading: true,
      cptAncillaryLoading: true,
      cptPreMedLoading: true,
      cptAdminCodeLoading: true,
    },

    filterData: {
      payerFilterArray: [],
      payerFilterLoader: true,
    },
  },
  reducers: {
    // setOncologyDenialDashCptAndCarc_Data: (state, action) => {
    //   let data = action.payload;
    //   console.log("slice--->>", data);

    //   // handle cptCodeTreeMap data
    //   // jCodes Array handle here

    // if (data?.cptLevelASJSON) {
    //   state.serviceLineFilterArr = JSON.parse(data?.cptLevelASJSON);
    // } else {
    //   state.serviceLineFilterArr = [];
    // }

    //   if (
    //     data?.reasonCodeTreeMap &&
    //     data?.reasonCodeTreeMap?.jCode &&
    //     data?.reasonCodeTreeMap?.jCode?.length
    //   ) {
    //     let res_data = data?.reasonCodeTreeMap?.jCode;
    //     const tempArr = [];
    //     res_data.forEach((item, i) => {
    //       tempArr.push({
    //         name: item?.reasonCode,
    //         code: item?.reasonCode,
    //         denailAmount: item?.denailAmount,
    //         tempAmount: 10,
    //         isClickable: true,
    //         tempdenailAmount: Math.abs(item?.denailAmount),
    //         description: item?.description,
    //         cptCode: item?.cptCode,
    //         denailCount: item?.denailCount,
    //       });
    //     });

    //     const newArr = [];
    //     const unique_code = [...new Set(tempArr.map((item) => item?.code))];

    //     unique_code.forEach((code_item) => {
    //       let obj = {};
    //       const newCodeArr = tempArr.filter((item) => item?.code === code_item);
    //       if (newCodeArr?.length) {
    //         let sortArr = getDescArray(newCodeArr, "denailAmount");

    //         obj["name"] = `${code_item} - ${getTotalDenialAmt(sortArr)}`;
    //         obj["color"] = getRandom_rgba();
    //         obj["children"] =
    //           sortArr?.length > 4 ? sortArr.slice(0, 5) : sortArr;
    //       }
    //       newArr.push(obj);
    //     });

    //     let obj2 = {
    //       name: "Top 5 JCodes",
    //       color: "hsl(294, 70%, 50%)",
    //       children: newArr,
    //     };

    //     state.carcJcodeCodesArr = obj2;
    //   } else {
    //     state.carcJcodeCodesArr = [];
    //   }
    // },

    // CptJCode Array handle here
    setCptJCod_Data: (state, action) => {
      let data = action.payload;
      if (
        data?.cptCodeTreeMap &&
        data?.cptCodeTreeMap?.jCode &&
        data?.cptCodeTreeMap?.jCode?.length
      ) {
        let res_data = data?.cptCodeTreeMap?.jCode;
        const tempArr = [];
        res_data.forEach((item, i) => {
          tempArr.push({
            name: item?.cptCode,
            code: item?.cptCode,
            denailAmount: item?.denailAmount,
            tempAmount: 10,
            isClickable: true,
            tempdenailAmount: Math.abs(item?.denailAmount),
            description: item?.description,
            reasonCode: item?.reasonCode,
            denailCount: item?.denailCount,
          });
        });

        const newArr = [];
        const unique_code = [...new Set(tempArr.map((item) => item?.code))];

        unique_code.forEach((code_item) => {
          let obj = {};
          const newCodeArr = tempArr.filter((item) => item?.code === code_item);
          if (newCodeArr?.length) {
            let sortArr = getDescArray(newCodeArr, "denailAmount");

            obj["name"] = `${code_item} - ${getTotalDenialAmt(sortArr)}`;
            obj["color"] = getRandom_rgba();
            obj["children"] =
              sortArr?.length > 4 ? sortArr.slice(0, 5) : sortArr;
          }
          newArr.push(obj);
        });

        let obj2 = {
          name: "Top 5 JCodes",
          color: "hsl(294, 70%, 50%)",
          children: newArr,
        };

        state.cptJcodeCodesArr = obj2;
      } else {
        state.cptJcodeCodesArr = [];
      }
      state.filterLoader.cptJCodeLoading = false;
    },
    // gettingCptJCodFilter: (state, action) => {
    //   state.filterLoader.cptJCodeLoading = true;
    // },

    // Cptancillary Array handle here
    setCptAncillary_Data: (state, action) => {
      let data = action.payload;

      if (
        data?.cptCodeTreeMap &&
        data?.cptCodeTreeMap?.ancillary &&
        data?.cptCodeTreeMap?.ancillary?.length
      ) {
        let res_data = data?.cptCodeTreeMap?.ancillary;
        const tempArr = [];
        res_data.forEach((item, i) => {
          tempArr.push({
            name: item?.cptCode,
            code: item?.cptCode,
            denailAmount: item?.denailAmount,
            tempAmount: 10,
            isClickable: true,
            tempdenailAmount: Math.abs(item?.denailAmount),
            description: item?.description,
            reasonCode: item?.reasonCode,
            denailCount: item?.denailCount,
          });
        });

        const newArr = [];
        const unique_code = [...new Set(tempArr.map((item) => item?.code))];

        unique_code.forEach((code_item) => {
          let obj = {};
          const newCodeArr = tempArr.filter((item) => item?.code === code_item);
          if (newCodeArr?.length) {
            let sortArr = getDescArray(newCodeArr, "denailAmount");

            obj["name"] = `${code_item} - ${getTotalDenialAmt(sortArr)}`;
            obj["color"] = getRandom_rgba();
            obj["children"] =
              sortArr?.length > 4 ? sortArr.slice(0, 5) : sortArr;
          }
          newArr.push(obj);
        });

        let obj2 = {
          name: "Top 5 Ancillary Codes",
          color: "hsl(294, 70%, 50%)",
          children: newArr,
        };

        state.cptAncillaryCodesArr = obj2;
      } else {
        state.cptAncillaryCodesArr = [];
      }
      state.filterLoader.cptAncillaryLoading = false;
    },
    // gettingCptAncillaryFilter: (state, action) => {
    //   state.filterLoader.cptAncillaryLoading = true;
    // },

    // Cptpremed Array handle here
    setCptPreMed_Data: (state, action) => {
      let data = action.payload;
      if (
        data?.cptCodeTreeMap &&
        data?.cptCodeTreeMap?.premed &&
        data?.cptCodeTreeMap?.premed?.length
      ) {
        let res_data = data?.cptCodeTreeMap?.premed;
        const tempArr = [];
        res_data.forEach((item, i) => {
          tempArr.push({
            name: item?.cptCode,
            code: item?.cptCode,
            denailAmount: item?.denailAmount,
            tempAmount: 10,
            isClickable: true,
            tempdenailAmount: Math.abs(item?.denailAmount),
            description: item?.description,
            reasonCode: item?.reasonCode,
            denailCount: item?.denailCount,
          });
        });

        const newArr = [];
        const unique_code = [...new Set(tempArr.map((item) => item?.code))];

        unique_code.forEach((code_item) => {
          let obj = {};
          const newCodeArr = tempArr.filter((item) => item?.code === code_item);
          if (newCodeArr?.length) {
            let sortArr = getDescArray(newCodeArr, "denailAmount");

            obj["name"] = `${code_item} - ${getTotalDenialAmt(sortArr)}`;
            obj["color"] = getRandom_rgba();
            obj["children"] =
              sortArr?.length > 4 ? sortArr.slice(0, 5) : sortArr;
          }
          newArr.push(obj);
        });

        let obj2 = {
          name: "Top 5 Pre - Med Codes",
          color: "hsl(294, 70%, 50%)",
          children: newArr,
        };

        state.cptPremedCodesArr = obj2;
      } else {
        state.cptPremedCodesArr = [];
      }
      state.filterLoader.cptPreMedLoading = false;
    },
    // gettingCptPreMedFilter: (state, action) => {
    //   state.filterLoader.cptPreMedLoading = true;
    // },

    // CptallCodes Array handle here
    setCptAllCode_Data: (state, action) => {
      let data = action.payload;
      if (
        data?.cptCodeTreeMap &&
        data?.cptCodeTreeMap?.allCodes &&
        data?.cptCodeTreeMap?.allCodes?.length
      ) {
        let res_data = data?.cptCodeTreeMap?.allCodes;
        const tempArr = [];
        res_data.forEach((item, i) => {
          tempArr.push({
            name: item?.cptCode,
            code: item?.cptCode,
            denailAmount: item?.denailAmount,
            tempAmount: 10,
            isClickable: true,
            tempdenailAmount: Math.abs(item?.denailAmount),
            description: item?.description,
            reasonCode: item?.reasonCode,
            denailCount: item?.denailCount,
          });
        });

        const newArr = [];
        const unique_code = [...new Set(tempArr.map((item) => item?.code))];

        unique_code.forEach((code_item) => {
          let obj = {};
          const newCodeArr = tempArr.filter((item) => item?.code === code_item);
          if (newCodeArr?.length) {
            let sortArr = getDescArray(newCodeArr, "denailAmount");

            obj["name"] = `${code_item} - ${getTotalDenialAmt(sortArr)}`;
            obj["color"] = getRandom_rgba();
            obj["children"] =
              sortArr?.length > 4 ? sortArr.slice(0, 5) : sortArr;
          }
          newArr.push(obj);
        });

        let obj2 = {
          name: "Top 10 Oncology Codes",
          color: "hsl(294, 70%, 50%)",
          children: newArr,
        };

        state.cptAllCodesArr = obj2;
      } else {
        state.cptAllCodesArr = [];
      }
      state.filterLoader.cptAllCodeLoading = false;

      // handle payer filter array here
      if (data?.payer?.length) {
        // removing duplicate payerName here start
        const uniquePayerNames = {};
        const uniqueArrayOfObjects = data?.payer.filter((obj) => {
          if (!uniquePayerNames[obj.payerName]) {
            uniquePayerNames[obj.payerName] = true;
            return true;
          }
          return false;
        });
        // removing duplicate payerName here end

        // Sort the array alphabetically based on payerName
        uniqueArrayOfObjects.sort((a, b) => {
          const payerNameA = a.payerName.toLowerCase();
          const payerNameB = b.payerName.toLowerCase();
          if (payerNameA < payerNameB) return -1;
          if (payerNameA > payerNameB) return 1;
          return 0;
        });

        let newArr = [];
        uniqueArrayOfObjects?.forEach((item) => {
          newArr.push({
            label: item?.payerName,
            value: item?.payerId,
            // payerId: item?.payerId,
          });
        });

        state.filterData.payerFilterArray = newArr;
        state.filterData.payerFilterLoader = false;
      } else {
        state.filterData.payerFilterArray = [];
        state.filterData.payerFilterLoader = false;
      }
    },
    setLoaderForFilters: (state, action) => {
      // carc loader
      state.filterLoader.carcAllCodeLoading = true;
      state.filterLoader.carcJCodeLoading = true;
      state.filterLoader.carcAncillaryLoading = true;
      state.filterLoader.carcPreMedLoading = true;
      state.filterLoader.carcAdminCodeLoading = true;

      // cpt loader
      state.filterLoader.cptAllCodeLoading = true;
      state.filterLoader.cptJCodeLoading = true;
      state.filterLoader.cptAncillaryLoading = true;
      state.filterLoader.cptPreMedLoading = true;
      state.filterLoader.cptAdminCodeLoading = true;
    },

    // CptadminCode Array handle here
    setCptAdminCode_Data: (state, action) => {
      let data = action.payload;
      if (
        data?.cptCodeTreeMap &&
        data?.cptCodeTreeMap?.adminCode &&
        data?.cptCodeTreeMap?.adminCode?.length
      ) {
        let res_data = data?.cptCodeTreeMap?.adminCode;
        const tempArr = [];
        res_data.forEach((item, i) => {
          tempArr.push({
            name: item?.cptCode,
            code: item?.cptCode,
            denailAmount: item?.denailAmount,
            tempAmount: 10,
            isClickable: true,
            tempdenailAmount: Math.abs(item?.denailAmount),
            description: item?.description,
            reasonCode: item?.reasonCode,
            denailCount: item?.denailCount,
          });
        });

        const newArr = [];
        const unique_code = [...new Set(tempArr.map((item) => item?.code))];

        unique_code.forEach((code_item) => {
          let obj = {};
          const newCodeArr = tempArr.filter((item) => item?.code === code_item);
          if (newCodeArr?.length) {
            let sortArr = getDescArray(newCodeArr, "denailAmount");

            obj["name"] = `${code_item} - ${getTotalDenialAmt(sortArr)}`;
            obj["color"] = getRandom_rgba();
            obj["children"] =
              sortArr?.length > 4 ? sortArr.slice(0, 5) : sortArr;
          }
          newArr.push(obj);
        });

        let obj2 = {
          name: "Top 5 Admin Codes",
          color: "hsl(294, 70%, 50%)",
          children: newArr,
        };

        state.cptAdminCodesArr = obj2;
      } else {
        state.cptAdminCodesArr = [];
      }
      state.filterLoader.cptAdminCodeLoading = false;
    },
    // gettingCptAdminCodeFilter: (state, action) => {
    //   state.filterLoader.cptAdminCodeLoading = true;
    // },

    // CarcallCodes Array handle here
    setCarcAllCode_data: (state, action) => {
      let data = action.payload;
      if (
        data?.treeMapByAllCode &&
        data?.treeMapByAllCode?.allCodes &&
        data?.treeMapByAllCode?.allCodes?.length
      ) {
        let res_data = data?.treeMapByAllCode?.allCodes;
        const tempArr = [];
        res_data.forEach((item, i) => {
          tempArr.push({
            name: item?.reasonCode,
            code: item?.reasonCode,
            denailAmount: item?.denailAmount,
            tempAmount: 10,
            isClickable: true,
            tempdenailAmount: Math.abs(item?.denailAmount),
            description: item?.description,
            cptCode: item?.cptCode,
            denailCount: item?.denailCount,
          });
        });

        const newArr = [];
        const unique_code = [...new Set(tempArr.map((item) => item?.code))];

        unique_code.forEach((code_item) => {
          let obj = {};
          const newCodeArr = tempArr.filter((item) => item?.code === code_item);
          if (newCodeArr?.length) {
            let sortArr = getDescArray(newCodeArr, "denailAmount");

            obj["name"] = `${code_item} - ${getTotalDenialAmt(sortArr)}`;
            obj["color"] = getRandom_rgba();
            obj["children"] =
              sortArr?.length > 4 ? sortArr.slice(0, 5) : sortArr;
          }
          newArr.push(obj);
        });

        let obj2 = {
          name: "Top 10 Oncology Codes",
          color: "hsl(294, 70%, 50%)",
          children: newArr,
        };

        state.carcAllCodesArr = obj2;
      } else {
        state.carcAllCodesArr = [];
      }
      state.filterLoader.carcAllCodeLoading = false;
    },
    // gettingCarcAllCodeFilter: (state, action) => {
    //   state.filterLoader.carcAllCodeLoading = true;
    // },

    // CarcJCodes Array handle here
    setCarcJCode_Data: (state, action) => {
      let data = action.payload;
      if (
        data?.treeMapByJCode &&
        data?.treeMapByJCode?.jCode &&
        data?.treeMapByJCode?.jCode?.length
      ) {
        let res_data = data?.treeMapByJCode?.jCode;
        const tempArr = [];
        res_data.forEach((item, i) => {
          tempArr.push({
            name: item?.reasonCode,
            code: item?.reasonCode,
            denailAmount: item?.denailAmount,
            tempAmount: 10,
            isClickable: true,
            tempdenailAmount: Math.abs(item?.denailAmount),
            description: item?.description,
            cptCode: item?.cptCode,
            denailCount: item?.denailCount,
          });
        });

        const newArr = [];
        const unique_code = [...new Set(tempArr.map((item) => item?.code))];

        unique_code.forEach((code_item) => {
          let obj = {};
          const newCodeArr = tempArr.filter((item) => item?.code === code_item);
          if (newCodeArr?.length) {
            let sortArr = getDescArray(newCodeArr, "denailAmount");

            obj["name"] = `${code_item} - ${getTotalDenialAmt(sortArr)}`;
            obj["color"] = getRandom_rgba();
            obj["children"] =
              sortArr?.length > 4 ? sortArr.slice(0, 5) : sortArr;
          }
          newArr.push(obj);
        });

        let obj2 = {
          name: "Top 5 JCodes",
          color: "hsl(294, 70%, 50%)",
          children: newArr,
        };

        state.carcJcodeCodesArr = obj2;
      } else {
        state.carcJcodeCodesArr = [];
      }
      state.filterLoader.carcJCodeLoading = false;
    },
    // gettingCarcJCodeFilter: (state, action) => {
    //   state.filterLoader.carcJCodeLoading = true;
    // },

    // CarcAncillary Array handle here
    setCarcAncillary_Data: (state, action) => {
      let data = action.payload;
      if (
        data?.treeMapByAcillary &&
        data?.treeMapByAcillary?.ancillary &&
        data?.treeMapByAcillary?.ancillary?.length
      ) {
        let res_data = data?.treeMapByAcillary?.ancillary;
        const tempArr = [];
        res_data.forEach((item, i) => {
          tempArr.push({
            name: item?.reasonCode,
            code: item?.reasonCode,
            denailAmount: item?.denailAmount,
            tempAmount: 10,
            isClickable: true,
            tempdenailAmount: Math.abs(item?.denailAmount),
            description: item?.description,
            cptCode: item?.cptCode,
            denailCount: item?.denailCount,
          });
        });

        const newArr = [];
        const unique_code = [...new Set(tempArr.map((item) => item?.code))];

        unique_code.forEach((code_item) => {
          let obj = {};
          const newCodeArr = tempArr.filter((item) => item?.code === code_item);
          if (newCodeArr?.length) {
            let sortArr = getDescArray(newCodeArr, "denailAmount");

            obj["name"] = `${code_item} - ${getTotalDenialAmt(sortArr)}`;
            obj["color"] = getRandom_rgba();
            obj["children"] =
              sortArr?.length > 4 ? sortArr.slice(0, 5) : sortArr;
          }
          newArr.push(obj);
        });

        let obj2 = {
          name: "Top 5 Ancillary Codes",
          color: "hsl(294, 70%, 50%)",
          children: newArr,
        };

        state.carcAncillaryCodesArr = obj2;
      } else {
        state.carcAncillaryCodesArr = [];
      }
      state.filterLoader.carcAncillaryLoading = false;
    },
    // gettingCarcAncillaryFilter: (state, action) => {
    //   state.filterLoader.carcAncillaryLoading = true;
    // },

    // CarcPremed Array handle here
    setCarcPreMed_Data: (state, action) => {
      let data = action.payload;
      if (
        data?.treeMapByPreMed &&
        data?.treeMapByPreMed?.premed &&
        data?.treeMapByPreMed?.premed?.length
      ) {
        let res_data = data?.treeMapByPreMed?.premed;
        const tempArr = [];
        res_data.forEach((item, i) => {
          tempArr.push({
            name: item?.reasonCode,
            code: item?.reasonCode,
            denailAmount: item?.denailAmount,
            tempAmount: 10,
            isClickable: true,
            tempdenailAmount: Math.abs(item?.denailAmount),
            description: item?.description,
            cptCode: item?.cptCode,
            denailCount: item?.denailCount,
          });
        });

        const newArr = [];
        const unique_code = [...new Set(tempArr.map((item) => item?.code))];

        unique_code.forEach((code_item) => {
          let obj = {};
          const newCodeArr = tempArr.filter((item) => item?.code === code_item);
          if (newCodeArr?.length) {
            let sortArr = getDescArray(newCodeArr, "denailAmount");

            obj["name"] = `${code_item} - ${getTotalDenialAmt(sortArr)}`;
            obj["color"] = getRandom_rgba();
            obj["children"] =
              sortArr?.length > 4 ? sortArr.slice(0, 5) : sortArr;
          }
          newArr.push(obj);
        });

        let obj2 = {
          name: "Top 5 Pre - Med Codes",
          color: "hsl(294, 70%, 50%)",
          children: newArr,
        };

        state.carcPremedCodesArr = obj2;
      } else {
        state.carcPremedCodesArr = [];
      }
      state.filterLoader.carcPreMedLoading = false;
    },
    // gettingCarcPreMedFilter: (state, action) => {
    //   state.filterLoader.carcPreMedLoading = true;
    // },

    // CarcAdminCode Array handle here
    setCarcAdminCode_Data: (state, action) => {
      let data = action.payload;
      if (
        data?.treeMapByAdmin &&
        data?.treeMapByAdmin?.adminCode &&
        data?.treeMapByAdmin?.adminCode?.length
      ) {
        let res_data = data?.treeMapByAdmin?.adminCode;
        const tempArr = [];
        res_data.forEach((item, i) => {
          tempArr.push({
            name: item?.reasonCode,
            code: item?.reasonCode,
            denailAmount: item?.denailAmount,
            tempAmount: 10,
            isClickable: true,
            tempdenailAmount: Math.abs(item?.denailAmount),
            description: item?.description,
            cptCode: item?.cptCode,
            denailCount: item?.denailCount,
          });
        });

        const newArr = [];
        const unique_code = [...new Set(tempArr.map((item) => item?.code))];

        unique_code.forEach((code_item) => {
          let obj = {};
          const newCodeArr = tempArr.filter((item) => item?.code === code_item);
          if (newCodeArr?.length) {
            let sortArr = getDescArray(newCodeArr, "denailAmount");

            obj["name"] = `${code_item} - ${getTotalDenialAmt(sortArr)}`;
            obj["color"] = getRandom_rgba();
            obj["children"] =
              sortArr?.length > 4 ? sortArr.slice(0, 5) : sortArr;
          }
          newArr.push(obj);
        });

        let obj2 = {
          name: "Top 5 Admin Codes",
          color: "hsl(294, 70%, 50%)",
          children: newArr,
        };

        state.carcAdminCodesArr = obj2;
      } else {
        state.carcAdminCodesArr = [];
      }
      state.filterLoader.carcAdminCodeLoading = false;
    },
    // gettingCarcAdminCodeFilter: (state, action) => {
    //   state.filterLoader.carcAdminCodeLoading = true;
    // },
    setServiceLineFilter_Data: (state, action) => {
      const data = action?.payload;

      if (data?.CPTCodeJSON) {
        state.serviceLineFilterArr = JSON.parse(data?.CPTCodeJSON);
      } else {
        state.serviceLineFilterArr = [];
      }
      state.filterLoader.serviceLine = false;
    },
  },
});

export const {
  // graph data handle here
  // setOncologyDenialDashCptAndCarc_Data,
  setCptJCod_Data,
  setCptAncillary_Data,
  setCptPreMed_Data,
  setCptAllCode_Data,
  setCptAdminCode_Data,
  setCarcAllCode_data,
  setCarcJCode_Data,
  setCarcAncillary_Data,
  setCarcPreMed_Data,
  setCarcAdminCode_Data,
  setServiceLineFilter_Data,

  // filter handle here
  // gettingCptJCodFilter,
  // gettingCptAncillaryFilter,
  // gettingCptPreMedFilter,
  setLoaderForFilters,
  // gettingCptAdminCodeFilter,
  // gettingCarcAllCodeFilter,
  // gettingCarcJCodeFilter,
  // gettingCarcAncillaryFilter,
  // gettingCarcPreMedFilter,
  // gettingCarcAdminCodeFilter,
} = OnCologyDenialDashKpisSlice.actions;

// get onCology Denial Dash carc and cpt Code data
export const gettingOncologyDenialDashCptAndCarc_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "oncologyDenialDashboardFilter",
          ignore: ["claimStatus,payers,cptCodes,CARC"],
        })
      );
      // ========= below code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - start ==============
      let advStngServiceLineExecList =
        getState().pReducers.user.advStngServiceLineExecList;
      let data = advStngServiceLineExecList?.split(",")?.map((item) => {
        return {
          label: item.toString(),
          value: item.toString(),
        };
      });
      dispatch(
        setListingState({
          listing: "oncologyDenialDashboardFilter",
          value: {
            smartFilterArr: data,
            pageNumber: 1,
          },
        })
      );
      // ========= above code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - end ==============
    }

    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;
    let sliceState =
      getState().npReducers.filters.oncologyDenialDashboardFilter;
    let sliceStateUser = getState().pReducers.user;

    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      ///// API CALL //////

      let params = {
        // start,
        // limit,
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.selFilterType !== "" && {
          filter:
            sliceStateForDate.selFilterType == "3M"
              ? "3M"
              : sliceStateForDate.selFilterType == "1Y"
              ? "1Y"
              : sliceStateForDate.selFilterType == "3Y"
              ? "3Y"
              : "Q",
        }),

        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
        ...(sliceState?.payers?.length > 0 && {
          PayerId: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : sliceStateForDate?.clientId || getCurrentClientId(),
        }),
      };
      let paramsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.selFilterType !== "" && {
          filter:
            sliceStateForDate.selFilterType == "3M"
              ? "3M"
              : sliceStateForDate.selFilterType == "1Y"
              ? "1Y"
              : sliceStateForDate.selFilterType == "3Y"
              ? "3Y"
              : "Q",
        }),

        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
        ...(sliceState?.payers?.length > 0 && {
          PayerId: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : sliceStateForDate?.clientId || getCurrentClientId(),
        }),
      };

      // console.log("api_call--->>", params);

      // handle loading here
      dispatch(setLoaderForFilters());
      // dispatch(gettingCarcAllCodeFilter());
      // dispatch(gettingCarcJCodeFilter());
      // dispatch(gettingCarcAncillaryFilter());
      // dispatch(gettingCarcPreMedFilter());
      // dispatch(gettingCarcAdminCodeFilter());
      // dispatch(gettingCptJCodFilter());
      // dispatch(gettingCptAdminCodeFilter());
      // dispatch(gettingCptAncillaryFilter());
      // dispatch(gettingCptPreMedFilter());

      // Getting serviceLine filters api Call
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setServiceLineFilter_Data,
        "testFilter",
        // "oncologyDenialDashboardFilter",
        "CLAIM_PAYMENT_SERVICELINE_FILTER",
        null,
        "",
        ""
      );

      // CPT AllCodes API Call
      await commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setCptAllCode_Data,
        "oncologyDenialDashboardFilter",
        "GET_ONCOLOGY_DENIAL_DASH_CPT_ALLCODES_KPIS",
        null,
        "",
        ""
      );

      // CARC AllCodes API Call

      await commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setCarcAllCode_data,
        "oncologyDenialDashboardCarcAllCodFilter",
        "GET_ONCOLOGY_DENIAL_DASH_CARC_ALLCODES_KPIS",
        null,
        "",
        ""
      );

      // CARC JCode API Call

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setCarcJCode_Data,
        "oncologyDenialDashboardCarcJCodFilter",
        "GET_ONCOLOGY_DENIAL_DASH_CARC_JCODE_KPIS",
        null,
        "",
        ""
      );

      // CARC Ancillary API Call

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setCarcAncillary_Data,
        "oncologyDenialDashboardCarcAncillaryFilter",
        "GET_ONCOLOGY_DENIAL_DASH_CARC_ANCILLARY_KPIS",
        null,
        "",
        ""
      );

      // CARC PreMed API Call

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setCarcPreMed_Data,
        "oncologyDenialDashboardCarcPreMedFilter",
        "GET_ONCOLOGY_DENIAL_DASH_CARC_PREMED_KPIS",
        null,
        "",
        ""
      );

      // CARC AdminCode API Call

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setCarcAdminCode_Data,
        "oncologyDenialDashboardCarcAdminCodeFilter",
        "GET_ONCOLOGY_DENIAL_DASH_CARC_ADMINCODE_KPIS",
        null,
        "",
        ""
      );

      // CPT JCode API Call

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setCptJCod_Data,
        "oncologyDenialDashboardCptJCodeFilter",
        "GET_ONCOLOGY_DENIAL_DASH_CPT_JCODE_KPIS",
        null,
        "",
        ""
      );

      // CPT AdminCode API Call

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setCptAdminCode_Data,
        "oncologyDenialDashboardCptAdminCodeFilter",
        "GET_ONCOLOGY_DENIAL_DASH_CPT_ADMIN_CODE_KPIS",
        null,
        "",
        ""
      );

      // CPT Ancillary API Call

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setCptAncillary_Data,
        "oncologyDenialDashboardCptAncillaryFilter",
        "GET_ONCOLOGY_DENIAL_DASH_CPT_ANCILLARY_KPIS",
        null,
        "",
        ""
      );

      // CPT Pre-Med API Call

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setCptPreMed_Data,
        "oncologyDenialDashboardCptPreMedFilter",
        "GET_ONCOLOGY_DENIAL_DASH_CPT_PREMED_KPIS",
        null,
        "",
        ""
      );

      return false;
      ////// API CALL END //////////////
    } catch (e) {
      // console.log("e--->>", e);
      return false;
    }
  };

export default OnCologyDenialDashKpisSlice.reducer;

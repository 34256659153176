import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Button from "@mui/material/Button";
import ArrowUpMenu from "../../assets/svgs/ArrowUpMenu";
import ArrowDownMenu from "../../assets/svgs/ArrowDownMenu";

export default function MenuNewChatBot() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          style={{
            position: "absolute",
            left: "250px",
            top: "48px",
            zIndex: "10",
          }}
          className="DR-Copliot-dropdown"
        >
          SAM {open ? <ArrowUpMenu /> : <ArrowDownMenu />}
        </Button>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className="DR-Moudual-drop-items"
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                left: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClose}>
          <span className="flex items-center DR-mod-lable-outer">
            Alpha Health
          </span>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <span className="flex items-center DR-mod-lable-outer">Payer</span>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <span className="flex items-center DR-mod-lable-outer">
            Department
          </span>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <span className="flex items-center DR-mod-lable-outer">
            Code Explorer
          </span>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import claims from "../../../../actions/claims";

export const barChartSlice = createSlice({
  name: "barChart",
  initialState: {
    barChartData: [],
  },
  reducers: {
    barChartAction: (state, action) => {
      state.barChartData = action.payload;
    },
  },
});

export const { barChartAction } = barChartSlice.actions;

export const barChartMonthly = () => async (dispatch) => {
  const barChartData = await claims.getClaimMonthly();
  dispatch(barChartAction(barChartData.data.data));
  return barChartData.data.data;
};

export const barChartWeekly = () => async (dispatch) => {
  const barChartData = await claims.getClaimWeekly();
  dispatch(barChartAction(barChartData.data.data));
  return barChartData.data.data;
};

export default barChartSlice.reducer;

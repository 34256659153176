import React, { useEffect, useState } from "react";
import { Select, Space } from "antd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  saveUserPreferences_Call,
  saveUserPreferences_Call2,
} from "../../Redux/Reducers/Settings/userPreferenceSlice";
import { setPreferences } from "../../Redux/Reducers/UserReducer";
import { changeMacAddress } from "../../components/common/Utils/copilot";
// const options = [];
// for (let i = 10; i < 36; i++) {
//   options.push({
//     label: i.toString(36) + i,
//     value: i.toString(36) + i,
//   });
// }

const MacDropDownZoe = ({
  options,
  value,
  setValue,
  noChange,
  deleteSavedMessages,
  mainMac,
  setJurisdictionOptions,
  setJurisdiction,
  macAddress,
  disabled,
  target,
}) => {
  const handleChange = (value, item) => {
    // if (!noChange) {
    //   changeMacAddress(item);
    // }
    if (deleteSavedMessages) {
      deleteSavedMessages();
    }

    setValue(item);

    if (mainMac) {
      setJurisdictionOptions(item?.children);
      setJurisdiction(item?.children?.[0]);
      changeMacAddress(item, item?.children?.[0]);
    } else {
      changeMacAddress(macAddress, item);
    }
  };

  return (
    <Select
      // mode="multiple"
      // allowClear
      disabled={disabled}
      showSearch
      style={{
        width: "250px",
        textAlign: "left",
      }}
      placeholder="Please select"
      // defaultValue={["Palmetto GBA"]}
      className="choose-mac-drop"
      value={value?.[target ? target : "value"]}
      onChange={handleChange}
      options={options}
    />
  );
};
export default MacDropDownZoe;

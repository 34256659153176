import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
// import DataSource from "../../../actions/dataSource";
import { serverRoutes } from "../../../constants/serverRoutes";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";

import {
  setListingState,
  resetListingState,
} from "../filters/claimManagerFilters";
import {
  getMultiSelectFilterIdForApi_ForId,
  getMultiSelectFilterLabelSpitOnlyNames,
  getMultiSelectFilterValueForApi,
  roundToTwo,
} from "../../../components/common/Utils/utils";
import { getLegendsExtraShortName } from "./../../../components/common/Utils/utils";
import { getClaimAllFiltersData_Call } from "../DenialDetails/claimAllFiltersSlice";

export const ExectiveDashboardKpisSlice = createSlice({
  name: "ExectiveDashboardKpisSlice",
  initialState: {
    // Exective DashBoard data here

    executiveDashBoardKpi: {},
    revenueByCptCodeArr: [],
    revenueByCptCodeArrCodes: [],
    revenueByICD10Arr: [],
    revenueByICD10ArrCodes: [],
    revenueByPayersArr: [],
    revenueByPayersArrCodes: [],
    firstPassFailureRate: {},

    // Provider DashBoard data here
    providerDashBoardKpiData: {
      providerTimeLineArr: [],
      providerTimeLineArrForBarChart: [],
      providerFilterArr: [],
    },
  },
  reducers: {
    setExectiveDashBaordKpi_data: (state, action) => {
      let data = action.payload;
      state.executiveDashBoardKpi = data;

      // // handle for revenueByCPTs array here
      // if (data?.revenueByCPTs?.length) {
      //   let newArr = [];
      //   let codes = [];
      //   data?.revenueByCPTs.forEach((item) => {
      //     let cpt_code = item?.cpTcode;
      //     let obj = {};
      //     obj["country"] = cpt_code;
      //     obj[cpt_code] = item?.paymentAmount;
      //     newArr.push(obj);
      //     codes.push(item?.cpTcode);
      //   });
      //   state.revenueByCptCodeArr = newArr;
      //   state.revenueByCptCodeArrCodes = codes;
      // } else {
      //   state.revenueByCptCodeArr = [];
      //   state.revenueByCptCodeArrCodes = [];
      // }

      // // handle for revenueByICD10s array here
      // if (data?.revenueByICD10s?.length) {
      //   let newArr = [];
      //   let codes = [];
      //   data?.revenueByICD10s.forEach((item) => {
      //     let cpt_icD10code = item?.icD10code;
      //     let obj = {};
      //     obj["country"] = cpt_icD10code;
      //     obj[cpt_icD10code] = item?.paymentAmount;
      //     newArr.push(obj);
      //     codes.push(item?.icD10code);
      //   });
      //   state.revenueByICD10Arr = newArr;
      //   state.revenueByICD10ArrCodes = codes;
      // } else {
      //   state.revenueByICD10Arr = [];
      //   state.revenueByICD10ArrCodes = [];
      // }

      // // handle for revenueByPayers array here
      // if (data?.revenueByPayers?.length) {
      //   // let newArr = [];
      //   // data?.revenueByPayers.forEach((item) => {
      //   //   newArr.push({
      //   //     id: item?.payerName
      //   //       ? getLegendsExtraShortName(item?.payerName)
      //   //       : "",
      //   //     label: item?.payerName
      //   //       ? getLegendsExtraShortName(item?.payerName)
      //   //       : "",
      //   //     value: item?.paymentAmount ? roundToTwo(item?.paymentAmount) : "",
      //   //   });
      //   // });

      //   let newArr = [];
      //   let codes = [];
      //   data?.revenueByPayers.forEach((item) => {
      //     let payerName = item?.payerName
      //       ? getLegendsExtraShortName(item?.payerName)
      //       : "";
      //     let obj = {};
      //     obj["country"] = payerName;
      //     obj["payerFullName"] = item?.payerName;
      //     obj[payerName] = item?.paymentAmount
      //       ? roundToTwo(item?.paymentAmount)
      //       : "";
      //     newArr.push(obj);
      //     codes.push(
      //       item?.payerName ? getLegendsExtraShortName(item?.payerName) : ""
      //     );
      //   });
      //   state.revenueByPayersArrCodes = codes;
      //   state.revenueByPayersArr = newArr;
      // } else {
      //   state.revenueByPayersArrCodes = [];
      //   state.revenueByPayersArr = [];
      // }
    },

    setExecutiveDashFirstPass_dataAction: (state, action) => {
      let data = action.payload;
      if (data?.length > -1) {
        state.firstPassFailureRate = data[0];
      } else {
        state.firstPassFailureRate = {};
      }
    },

    setProviderDashBaordKpi_data: (state, action) => {
      let data = action.payload;
      state.providerDashBoardKpiData = data;
      if (data?.providerTimeline && data?.providerTimeline?.length) {
        let denailAmount = [];
        let paymentAmount = [];
        let totalChargeAmount = [];
        let comboChartArr = [];

        data?.providerTimeline.forEach((item, index) => {
          // handle comboChartArr

          comboChartArr.push({
            name: `${
              ["m", "w"].includes(item?.filterType?.toLowerCase())
                ? `Week ${index + 1}`
                : item?.year
            }`,
            "Total Charge Amount": item?.chargeAmount,
            "Payment Amount": item?.paymentAmount,
            "Denial Amount": item?.softAdjustment,
          });

          // handle lineChartArr
          denailAmount.push({
            x: `${
              ["m", "w"].includes(item?.filterType?.toLowerCase())
                ? `Week ${index + 1}`
                : item?.year
            }`,
            y: item?.softAdjustment,
          });

          paymentAmount.push({
            x: `${
              ["m", "w"].includes(item?.filterType?.toLowerCase())
                ? `Week ${index + 1}`
                : item?.year
            }`,
            y: item?.paymentAmount,
          });

          totalChargeAmount.push({
            x: `${
              ["m", "w"].includes(item?.filterType?.toLowerCase())
                ? `Week ${index + 1}`
                : item?.year
            }`,
            y: item?.chargeAmount,
          });
        });

        let newAmountArr = [
          {
            id: "Denial Amount",
            color: "hsl(116, 70%, 50%)",
            data: denailAmount,
          },

          {
            id: "Payment Amount",
            color: "hsl(116, 70%, 50%)",
            data: paymentAmount,
          },

          {
            id: "Total Charge Amount",
            color: "hsl(116, 70%, 50%)",
            data: totalChargeAmount,
          },
        ];

        state.providerDashBoardKpiData.providerTimeLineArr = newAmountArr;
        state.providerDashBoardKpiData.providerTimeLineComboChartArr =
          comboChartArr;
      } else {
        state.providerDashBoardKpiData.providerTimeLineArr = [];
        state.providerDashBoardKpiData.providerTimeLineComboChartArr = [];
      }
      // if (data?.providerFilter && data?.providerFilter?.length) {
      //   // removing duplicate providerName here start
      //   const uniqueProviderName = {};
      //   const uniqueArrayOfObjects = data?.providerFilter.filter((obj) => {
      //     if (!uniqueProviderName[obj.providerName]) {
      //       uniqueProviderName[obj.providerName] = true;
      //       return true;
      //     }
      //     return false;
      //   });
      //   // removing duplicate providerName here end

      //   // Sort the array alphabetically based on providerName
      //   uniqueArrayOfObjects.sort((a, b) => {
      //     const providerNameA = a.providerName.toLowerCase();
      //     const providerNameB = b.providerName.toLowerCase();
      //     if (providerNameA < providerNameB) return -1;
      //     if (providerNameA > providerNameB) return 1;
      //     return 0;
      //   });

      //   let newArr = [];
      //   uniqueArrayOfObjects?.forEach((item) => {
      //     newArr.push({
      //       label: item?.providerName,
      //       value: item?.providerName,
      //     });
      //   });
      //   state.providerDashBoardKpiData.providerFilterArr = newArr;
      // } else {
      //   state.providerDashBoardKpiData.providerFilterArr = [];
      // }

      // handle for providerTimeline array for barChart here
      if (data?.providerTimeline && data?.providerTimeline?.length) {
        let newArr = [];
        // let codes = [];
        data?.providerTimeline.forEach((item) => {
          let sel_year = item?.year;
          let obj = {};
          obj["country"] = sel_year;
          obj[sel_year] = item?.paymentAmount;
          obj["Payment Amount"] = item?.paymentAmount;
          obj["Denial Amount"] = item?.softAdjustment;
          obj["Total Charge Amount"] = item?.chargeAmount;
          newArr.push(obj);
          // codes.push(item?.icD10code);
        });
        state.providerDashBoardKpiData.providerTimeLineArrForBarChart = newArr;
        // state.revenueByICD10ArrCodes = codes;
      } else {
        state.providerDashBoardKpiData.providerTimeLineArrForBarChart = [];
        // state.revenueByICD10ArrCodes = [];
      }
    },
  },
});

export const {
  setExectiveDashBaordKpi_data,
  setExecutiveDashFirstPass_dataAction,
  setProviderDashBaordKpi_data,
} = ExectiveDashboardKpisSlice.actions;

// get exec dash list
export const gettingExecutiveDashBoardKpis_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "exectiveDashboardFilter",
          //   ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.exectiveDashboardFilter;

    try {
      dispatch(
        setListingState({
          listing: "exectiveDashboardFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      ///// API CALL //////

      let params = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_EXECTIVE_DASH_KPIS,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      if (!res?.data?.success) {
        // toast.error(`Something went wrong`, {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        dispatch(setExectiveDashBaordKpi_data([]));
        dispatch(
          setListingState({
            listing: "exectiveDashboardFilter",
            value: {
              loadingMore: "",
            },
          })
        );
      } else {
        dispatch(setExectiveDashBaordKpi_data(res?.data?.data));
        dispatch(
          setListingState({
            listing: "exectiveDashboardFilter",
            value: {
              // hasMore: res?.data?.data?.length === sliceState.pageSize,
              loadingMore: "",
            },
          })
        );
      }
      return res;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "exectiveDashboardFilter",
          value: {
            initialLoading: false,
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      dispatch(setExectiveDashBaordKpi_data([]));

      return false;
    }
  };

// get exec dash list
export const gettingExecutiveDashFirstPass_FailureRate_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "exectiveDashboardFilter",
          //   ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.exectiveDashboardFilter;

    try {
      dispatch(
        setListingState({
          listing: "exectiveDashboardFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      ///// API CALL //////

      let params = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_EXECTIVE_DASH_FIRST_PASS_FAILURE_RATE,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////
      if (!res?.data?.success) {
        dispatch(setExecutiveDashFirstPass_dataAction([]));
        dispatch(
          setListingState({
            listing: "exectiveDashboardFilter",
            value: {
              loadingMore: "",
            },
          })
        );
      } else {
        dispatch(setExecutiveDashFirstPass_dataAction(res?.data?.data));
        dispatch(
          setListingState({
            listing: "exectiveDashboardFilter",
            value: {
              // hasMore: res?.data?.data?.length === sliceState.pageSize,
              loadingMore: "",
            },
          })
        );
      }
      return res;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "exectiveDashboardFilter",
          value: {
            initialLoading: false,
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      dispatch(setExecutiveDashFirstPass_dataAction([]));

      return false;
    }
  };

// get Provider Dashboard KPI's data
export const gettingProviderDashBoardKpis_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "providerDashboardFilter",
          ignore: ["claimStatus,payers,cptCodes,CARC"],
        })
      );
    }

    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceState = getState().npReducers.filters.providerDashboardFilter;

    try {
      dispatch(
        setListingState({
          listing: "providerDashboardFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      ///// API CALL //////

      let params = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filter: sliceStateForDate.dateMainState == "Months" ? "W" : "Q",
        }),

        ...(sliceState?.billingProviders?.length > 0 && {
          billingProvider: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...(sliceState?.renderingProviders?.length > 0 && {
          renderingProvider: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),
      };

      const billingProviderParams = {
        searchkeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.billingProviders?.length > 0 && {
          selectedBillingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };
      const renderingProviderParams = {
        searchkeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.renderingProviders?.length > 0 && {
          selectedRenderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      const drgCodeParams = {};

      const res = await genericAxiosCall(
        serverRoutes?.GET_PROVIDER_DASH_KPIS,
        "get",
        "",
        params
      );

      dispatch(
        getClaimAllFiltersData_Call(
          {},
          {},
          {},
          {},
          billingProviderParams,
          renderingProviderParams,
          drgCodeParams,
          {
            isServiceLineAPI: false,
            isPayersAPI: false,
            isClaimStatusAPI: false,
            isReasonCodeAPI: false,
            isProviderAPI: false,
            isFillingIndicatorAPI: false,
            isBillingProviderAPI: true,
            isRenderingProviderAPI: true,
            isIPOPRemartCodeAPI: false,
            isCptCodeAPI: false,
            isGroupCodeAPI: false,
            isDrgAPI: false,
          }
        )
      );
      ////// API CALL END //////////////

      if (!res?.data?.success) {
        // toast.error(`Something went wrong`, {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        dispatch(setProviderDashBaordKpi_data([]));
        dispatch(
          setListingState({
            listing: "providerDashboardFilter",
            value: {
              loadingMore: "",
            },
          })
        );
      } else {
        dispatch(setProviderDashBaordKpi_data(res?.data?.data));
        dispatch(
          setListingState({
            listing: "providerDashboardFilter",
            value: {
              // hasMore: res?.data?.data?.length === sliceState.pageSize,
              loadingMore: "",
            },
          })
        );
      }
      return res;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "providerDashboardFilter",
          value: {
            initialLoading: false,
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      dispatch(setProviderDashBaordKpi_data([]));
      return false;
    }
  };
export default ExectiveDashboardKpisSlice.reducer;

import React from "react";

const ScoreDown = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trending-down" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#eb5273" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <polyline points="3 7 9 13 13 9 21 17" />
  <polyline points="21 10 21 17 14 17" />
</svg>
  );
};

export default ScoreDown;

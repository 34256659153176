export function ClaimAdjGroupCodeMapping(code) {
  let desc = "";
  if (code == "CO") {
    return (desc = "Contractual Obligations");
  } else if (code == "CR") {
    return (desc = "Correction and Reversals");
  } else if (code == "OA") {
    return (desc = "Other Adjustments");
  } else if (code == "PI") {
    return (desc = "Payer Initiated Reductions");
  } else if (code == "PR") {
    return (desc = "Patient Responsibility");
  } else {
    return (desc = `There is no mapping for "${code}" code`);
  }
}

import { createSlice } from "@reduxjs/toolkit";
import {
  resetListingState,
  setListingState,
} from "../filters/claimManagerFilters";
import { toast } from "react-toastify";

import { commonPagination } from "../../../components/common/Utils/utils";
import { serverRoutes } from "../../../constants/serverRoutes";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";
import axios from "axios";
import store from "../../store";

export const AppealConfiguration = createSlice({
  name: "AppealConfiguration",
  initialState: {
    list: {
      rowCount: 0,
      outputList: [],
    },
    payerList: {
      rowCount: 0,
      outputList: [],
      filters: [],
      filterCount: 0,
    },
  },
  reducers: {
    setAppealConfigOutputList: (state, action) => {
      let data = action.payload;
      state.list.outputList = data;
      state.list.rowCount =
        data?.length && data[0]?.totalRows ? data[0]?.totalRows : 0;
    },
    setPayerContactInfoList: (state, action) => {
      let data = action.payload;
      state.payerList.outputList = data;
      state.payerList.rowCount =
        data?.length && data[0]?.totalCount ? data[0]?.totalCount : 0;
    },
    setPayerContactFilterList: (state, action) => {
      let data = action.payload;
      if (data?.length) {
        state.payerList.filters = data?.map((item, index) => {
          return {
            ...item,
            value: item?.id,
            label: `${item?.payerName}${
              item?.primaryId ? ` - ${item?.primaryId}` : ""
            }`,
          };
        });
        state.payerList.filterCount =
          data?.length && data[0]?.totalCount ? data[0]?.totalCount : 0;
      } else {
        state.payerList.filters = [];
        state.payerList.filterCount = 0;
      }
    },
  },
});

export const {
  setAppealConfigOutputList,
  setPayerContactInfoList,
  setPayerContactFilterList,
} = AppealConfiguration.actions;
//Get Payer Contact Info List
export const gettingPayerList =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "appealConfigurationFilterPayer",
          //   ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState =
      getState().npReducers.filters.appealConfigurationFilterPayer;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    let paramsTemp = {
      ...(sliceState.order !== "" && {
        Order: sliceState.order,
      }),
      ...(sliceState.orderBy !== "" && {
        OrderBy: sliceState.orderBy,
      }),
      isCustom: true,
    };
    let params = {
      start,
      limit,
      ...(sliceState.order !== "" && {
        Order: sliceState.order,
      }),
      ...(sliceState.orderBy !== "" && {
        OrderBy: sliceState.orderBy,
      }),
      isCustom: true,
    };
    commonPagination(
      getState,
      paramsTemp,
      params,
      dispatch,
      setPayerContactInfoList,
      "appealConfigurationFilterPayer",
      "PAYER_CONTACT_LIST",
      null
    );
  };
export const gettingFilterList =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "appealConfigurationFilterDropDown",
          //   ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState =
      getState().npReducers.filters.appealConfigurationFilterDropDown;
    // let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;
    let start = 0;
    let limit = 200;
    // if (start >= 2) {
    //   let rec_val = (start - 1) * limit;
    //   start = rec_val;
    // }
    // if (start == 1) {
    //   start = 0;
    // }

    let paramsTemp = {
      ...(sliceState.order !== "" && {
        Order: sliceState.order,
      }),
      ...(sliceState.orderBy !== "" && {
        OrderBy: sliceState.orderBy,
      }),
      isCustom: false,
      timestamp: new Date().getTime(),
    };
    let params = {
      start,
      limit,
      ...(sliceState.order !== "" && {
        Order: sliceState.order,
      }),
      ...(sliceState.orderBy !== "" && {
        OrderBy: sliceState.orderBy,
      }),
      isCustom: false,
      timestamp: new Date().getTime(),
    };
    commonPagination(
      getState,
      paramsTemp,
      params,
      dispatch,
      setPayerContactFilterList,
      "appealConfigurationFilterDropDown",
      "PAYER_CONTACT_LIST",
      null
    );
  };
const changeLoading = (setState, status) => {
  setState(status);
};
export const gettingFilterListBySearch =
  (value, setSearchLoading, setData) => async (dispatch, getState) => {
    let sliceState =
      getState().npReducers.filters.appealConfigurationFilterDropDown;
    // let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;
    let start = 0;
    let limit = 200;
    // if (start >= 2) {
    //   let rec_val = (start - 1) * limit;
    //   start = rec_val;
    // }
    // if (start == 1) {
    //   start = 0;
    // }

    let paramsTemp = {
      ...(sliceState.order !== "" && {
        Order: sliceState.order,
      }),
      ...(sliceState.orderBy !== "" && {
        OrderBy: sliceState.orderBy,
      }),
      searchName: value,

      isCustom: false,
      timestamp: new Date().getTime(),
    };
    let params = {
      start,
      limit,
      // ...(sliceState.order !== "" && {
      //   Order: sliceState.order,
      // }),
      // ...(sliceState.orderBy !== "" && {
      //   OrderBy: sliceState.orderBy,
      // }),
      searchName: value,
      isCustom: false,
      timestamp: new Date().getTime(),
    };
    genericAxiosCall(`${serverRoutes?.PAYER_CONTACT_LIST}`, "get", "", params)
      .then(async (res) => {
        if (res?.data?.success) {
          const data = res?.data?.data;

          await setData(
            data?.map((item) => {
              return {
                ...item,
                value: item?.id,
                label: `${item?.payerName}${
                  item?.primaryId ? ` - ${item?.primaryId}` : ""
                }`,
              };
            })
          );
          setSearchLoading(false);

          // await dispatch(setPayerContactFilterList(res?.data?.data));
          // setTimeout(changeLoading(setSearchLoading, false), 0);
        } else {
          setData([]);
          setSearchLoading(false);
        }
      })
      .catch((error) => {
        setData([]);
        setSearchLoading(false);
      });
  };
export const gettingFilterListBySearch2 = (value) => {
  let sliceState =
    store.getState().npReducers.filters.appealConfigurationFilterDropDown;
  // let start = sliceState.pageNumber;
  // let limit = sliceState.pageSize;
  let start = 0;
  let limit = 200;
  // if (start >= 2) {
  //   let rec_val = (start - 1) * limit;
  //   start = rec_val;
  // }
  // if (start == 1) {
  //   start = 0;
  // }

  let paramsTemp = {
    ...(sliceState.order !== "" && {
      Order: sliceState.order,
    }),
    ...(sliceState.orderBy !== "" && {
      OrderBy: sliceState.orderBy,
    }),
    searchName: value,

    isCustom: false,
    timestamp: new Date().getTime(),
  };
  let params = {
    start,
    limit,
    // ...(sliceState.order !== "" && {
    //   Order: sliceState.order,
    // }),
    // ...(sliceState.orderBy !== "" && {
    //   OrderBy: sliceState.orderBy,
    // }),
    searchName: value,
    isCustom: false,
    timestamp: new Date().getTime(),
  };
  return genericAxiosCall(
    `${serverRoutes?.PAYER_CONTACT_LIST}`,
    "get",
    "",
    params
  );
};

export const gettingPayerListTimeStamp =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "appealConfigurationFilterPayer",
          //   ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState =
      getState().npReducers.filters.appealConfigurationFilterPayer;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    let paramsTemp = {
      ...(sliceState.order !== "" && {
        Order: sliceState.order,
      }),
      ...(sliceState.orderBy !== "" && {
        OrderBy: sliceState.orderBy,
      }),
      isCustom: true,
      timestamp: new Date().getTime(),
    };
    let params = {
      start,
      limit,
      ...(sliceState.order !== "" && {
        Order: sliceState.order,
      }),
      ...(sliceState.orderBy !== "" && {
        OrderBy: sliceState.orderBy,
      }),
      isCustom: true,
      timestamp: new Date().getTime(),
    };
    commonPagination(
      getState,
      paramsTemp,
      params,
      dispatch,
      setPayerContactInfoList,
      "appealConfigurationFilterPayer",
      "PAYER_CONTACT_LIST",
      null
    );
  };
// get dataSources list
export const gettingAppealList =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "appealConfigurationFilter",
          //   ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }
    let sliceStateUser = getState().pReducers.user;
    let sliceState = getState().npReducers.filters.appealConfigurationFilter;

    let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;
    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable
        ?.appealConfigurationFilterNoOfRecordInTable;

    //

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    let paramsTemp = {
      ...(sliceState.order !== "" && {
        Order: sliceState.order,
      }),
      ...(sliceState.orderBy !== "" && {
        OrderBy: sliceState.orderBy,
      }),
    };
    let params = {
      start,
      limit,
      ...(sliceState.order !== "" && {
        Order: sliceState.order,
      }),
      ...(sliceState.orderBy !== "" && {
        OrderBy: sliceState.orderBy,
      }),
    };
    commonPagination(
      getState,
      paramsTemp,
      params,
      dispatch,
      setAppealConfigOutputList,
      "appealConfigurationFilter",
      "APPEAL_CONFIG_LIST",
      null
    );
  };

export const gettingAppealListTimeStamp =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "appealConfigurationFilter",
          //   ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.appealConfigurationFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    let paramsTemp = {
      ...(sliceState.order !== "" && {
        Order: sliceState.order,
      }),
      ...(sliceState.orderBy !== "" && {
        OrderBy: sliceState.orderBy,
      }),
      timestamp: new Date().getTime(),
    };
    let params = {
      start,
      limit,
      ...(sliceState.order !== "" && {
        Order: sliceState.order,
      }),
      ...(sliceState.orderBy !== "" && {
        OrderBy: sliceState.orderBy,
      }),
      timestamp: new Date().getTime(),
    };
    commonPagination(
      getState,
      paramsTemp,
      params,
      dispatch,
      setAppealConfigOutputList,
      "appealConfigurationFilter",
      "APPEAL_CONFIG_LIST",
      null
    );
  };

export const addNewList = (param) => {
  return genericAxiosCall(
    `${serverRoutes?.ADD_NEW_APPEAL_CONFIG}`,
    "post",
    param,
    ""
  );
};
export const addNewPayer = (param) => {
  return genericAxiosCall(
    `${serverRoutes?.CREATE_PAYER_CONTACT_LIST}`,
    "post",
    param,
    ""
  );
};
export const updatePayerList = (param) => {
  return genericAxiosCall(
    `${serverRoutes?.UPDATE_PAYER_CONTACT_LIST}`,
    "put",
    param,
    ""
  );
};
export const updateList = (param) => {
  return genericAxiosCall(
    `${serverRoutes?.UPDATE_APPEAL_CONFIG}`,
    "put",
    param,
    ""
  );
};
export const deleteCoordinators = (param) => {
  return genericAxiosCall(`${serverRoutes?.DELETE_BY_ID}`, "delete", "", param);
};
export const deletePayers = (param) => {
  return genericAxiosCall(
    `${serverRoutes?.DELETE_PAYER_BY_ID}`,
    "delete",
    "",
    param
  );
};
export const getAppealById = (param) => {
  return genericAxiosCall(
    `${serverRoutes?.GET_APPEAL_BY_ID}`,
    "get",
    "",
    param
  );
};
export const getPayerById = (param) => {
  return genericAxiosCall(`${serverRoutes?.GET_PAYER_BY_ID}`, "get", "", param);
};

export default AppealConfiguration.reducer;

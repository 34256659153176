import React from "react";

const LoadingIcon = ({ loaderWidth, loaderHeight, loaderColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={loaderWidth}
      height={loaderHeight ? loaderHeight : "100px"}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      shapeRendering="auto"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke={loaderColor ? loaderColor : "#3CA54F"}
        stroke-width="3"
        r="31"
        stroke-dasharray="146.08405839192537 50.69468613064179"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
  );
};

export default LoadingIcon;

import { createSlice } from "@reduxjs/toolkit";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";
import { serverRoutes } from "../../../constants/serverRoutes";
import { toast } from "react-toastify";
import { setListingState } from "../filters/claimManagerFilters";

export const CommentsSlice = createSlice({
  name: "CommentsSlice",
  initialState: {
    // data
    commentsData: [],
    commentsApiLimit: 100,
    commentCount: 0,
    // flags
    fetchCommentLoader: false,
    postCommentLoader: false,
    deleteCommentLoader: false,
    updateCommentLoader: false,
    setHasMore: false,
  },
  reducers: {
    gettingComments: (state, action) => {
      state.fetchCommentLoader = true;
    },
    setCommentsData: (state, action) => {
      state.commentsData = action.payload;
      (state.fetchCommentLoader = false),
        (state.postCommentLoader = false),
        (state.deleteCommentLoader = false),
        (state.updateCommentLoader = false);
    },
    setCommentsCount: (state, action) => {
      state.commentCount = action.payload;
    },

    postingComment: (state, action) => {
      state.postCommentLoader = true;
    },
    updatingComment: (state, action) => {
      state.updateCommentLoader = true;
    },
    deletingComment: (state, action) => {
      state.deleteCommentLoader = true;
    },
    setHasMore: (state, action) => {
      state.setHasMore = action.payload;
    },
    setCommentsApiLimit: (state, action) => {
      state.setHasMore += 4;
    },
  },
});

export const {
  gettingComments,
  postingComment,
  updatingComment,
  deletingComment,
  setCommentsData,
  setHasMore,
  setCommentsApiLimit,
  setCommentsCount,
} = CommentsSlice.actions;

// get all comments
export const gettingAllComments_Call =
  (userId, claimId) => async (dispatch, getState) => {
    dispatch(gettingComments());
    const params = {
      UserId: userId,
      ClaimId: claimId,
      timeStamp: new Date().getTime(),
      start: 0,
      limit: getState().npReducers.commentsModule.commentsApiLimit,
    };
    try {
      genericAxiosCall(serverRoutes?.GET_COMMENTS, "get", "", params).then(
        (res) => {
          if (!res?.data?.success) {
            // toast.error(`Something went wrong`, {
            //   position: toast.POSITION.TOP_CENTER,
            // });
            // setLoading(false);
          } else {
            dispatch(setCommentsData(res?.data?.data?.commentModel));
            dispatch(setCommentsCount(res?.data?.data?.count));
            dispatch(setHasMore(res?.data?.data?.hasMoreComments));
            // setLoading(false);
          }
        }
      );
    } catch (error) {
      dispatch(setCommentsData([]));
      // toast.error(
      //   error.response.data.message
      //     ? error.response.data.message
      //     : "Something went wrong",
      //   {
      //     position: toast.POSITION.TOP_CENTER,
      //   }
      // );
    }
  };

// post a comment
export const postComment_Call =
  (obj, setLoadingClass, setComment, loadData) =>
  async (dispatch, getState) => {
    try {
      genericAxiosCall(`${serverRoutes.CREATE_COMMENT}`, "post", obj, "")
        .then((res) => {
          if (!res?.data?.success) {
            toast.error(`Something went wrong`, {
              position: toast.POSITION.TOP_CENTER,
            });
            setLoadingClass("");
          } else {
            // toast.success("Note was successfully posted");

            const userId = obj?.UserId;
            const claimId = obj?.ClaimId;
            dispatch(gettingAllComments_Call(userId, claimId));
            setComment("");
            setLoadingClass("");

            loadData && loadData();
            dispatch(
              setListingState({
                listing: "appealsDetailFilter",

                value: {
                  fetchLatest: true,
                },
              })
            );
          }
        })
        .catch((error) => {
          // console.log("123123 === err", error);
          // toast.error(
          //   error?.response?.data?.message
          //     ? error?.response?.data?.message
          //     : "Something went wrong",
          //   {
          //     position: toast.POSITION.TOP_CENTER,
          //   }
          // );
        });
    } catch (err) {
      // console.log("err--->>", err);
    } finally {
      setLoadingClass("");
      setComment("");
      const userId = obj?.UserId;
      const claimId = obj?.ClaimId;
      dispatch(gettingAllComments_Call(userId, claimId));
    }
  };

// delete a comment
export const deleteComment_Call =
  (userId, commentId, claimId, loadData, handleCloseDeleteModal) =>
  async (dispatch, getState) => {
    try {
      genericAxiosCall(
        `${serverRoutes?.DELETE_COMMENT}UserId=${userId}&commentId=${commentId}&claimId=${claimId}`,
        "delete",
        "",
        ""
      )
        .then((res) => {
          if (!res?.data?.success) {
            toast.error(`Something went wrong`, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            // toast.success(res?.data?.message);
            const allComments =
              getState().npReducers.commentsModule.commentsData;
            const data = allComments?.filter(
              (item) => item?.commentId != commentId
            );
            dispatch(setCommentsData(data));
            dispatch(
              setCommentsCount(
                getState().npReducers.commentsModule.commentCount - 1
              )
            );
            loadData && loadData();
          }
        })
        .catch((error) => {
          // toast.error(
          //   error.response.data.message
          //     ? error.response.data.message
          //     : "Something went wrong",
          //   {
          //     position: toast.POSITION.TOP_CENTER,
          //   }
          // );
        });
    } catch (e) {
      // console.log("e--->>", e);
    } finally {
      handleCloseDeleteModal();
    }
  };

// update comment
export const updateComment_Call =
  (data, setUpdateComment) => async (dispatch, getState) => {
    try {
      genericAxiosCall(`${serverRoutes?.UPDATE_COMMENT}`, "put", data, "")
        .then((res) => {
          if (!res?.data?.success) {
            toast.error(`Something went wrong`, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            // toast.success("Note was successfully updated");

            const allComments =
              getState().npReducers.commentsModule.commentsData.map((a) => ({
                ...a,
              }));
            const data2 = allComments?.filter(
              (item) => item?.commentId == data?.commentId
            );
            const newData = { ...data2[0], commentText: data?.CommentText };

            const index = allComments?.findIndex(
              (item) => item.commentId == data?.commentId
            );
            let newArr = allComments;
            newArr[index] = newData;
            dispatch(setCommentsData(newArr));
            setUpdateComment(false);
          }
        })
        .catch((error) => {
          // console.log("123123 err == ", error);
          // toast.error(
          //   error.response.data.message
          //     ? error.response.data.message
          //     : "Something went wrong",
          //   {
          //     position: toast.POSITION.TOP_CENTER,
          //   }
          // );
        });
    } catch (e) {
    } finally {
    }
  };

export default CommentsSlice.reducer;

import React from "react";

const V2RemittanceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-dollar-sign"
    >
      <line x1="12" y1="1" x2="12" y2="23"></line>
      <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
    </svg>
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 14 14"
    //   height="24"
    //   width="24"
    // >
    //   <g>
    //     <line
    //       x1="7"
    //       y1="4.5"
    //       x2="7"
    //       y2="3"
    //       fill="none"

    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //     ></line>
    //     <g>
    //       <path
    //         d="M5.5,8.5c0,.75.67,1,1.5,1s1.5,0,1.5-1c0-1.5-3-1.5-3-3,0-1,.67-1,1.5-1s1.5.38,1.5,1"
    //         fill="none"

    //         stroke-linecap="round"
    //         stroke-linejoin="round"
    //       ></path>
    //       <line
    //         x1="7"
    //         y1="9.5"
    //         x2="7"
    //         y2="11"
    //         fill="none"

    //         stroke-linecap="round"
    //         stroke-linejoin="round"
    //       ></line>
    //     </g>
    //     <circle
    //       cx="7"
    //       cy="7"
    //       r="6.5"
    //       fill="none"

    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //     ></circle>
    //   </g>
    // </svg>

    // <svg
    //   width="24"
    //   height="24"
    //   viewBox="0 0 24 24"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <g opacity="0.7">
    //     <path
    //       d="M13.3999 17.42H10.8899C9.24991 17.42 7.91991 16.04 7.91991 14.34C7.91991 13.93 8.25991 13.59 8.66991 13.59C9.07991 13.59 9.41991 13.93 9.41991 14.34C9.41991 15.21 10.0799 15.92 10.8899 15.92H13.3999C14.0499 15.92 14.5899 15.34 14.5899 14.64C14.5899 13.77 14.2799 13.6 13.7699 13.42L9.73991 12C8.95991 11.73 7.90991 11.15 7.90991 9.35996C7.90991 7.81996 9.11991 6.57996 10.5999 6.57996H13.1099C14.7499 6.57996 16.0799 7.95996 16.0799 9.65996C16.0799 10.07 15.7399 10.41 15.3299 10.41C14.9199 10.41 14.5799 10.07 14.5799 9.65996C14.5799 8.78996 13.9199 8.07996 13.1099 8.07996H10.5999C9.94991 8.07996 9.40991 8.65996 9.40991 9.35996C9.40991 10.23 9.71991 10.4 10.2299 10.58L14.2599 12C15.0399 12.27 16.0899 12.85 16.0899 14.64C16.0799 16.17 14.8799 17.42 13.3999 17.42Z"
    //       fill="#092C4C"
    //     />
    //     <path
    //       d="M12 18.75C11.59 18.75 11.25 18.41 11.25 18V6C11.25 5.59 11.59 5.25 12 5.25C12.41 5.25 12.75 5.59 12.75 6V18C12.75 18.41 12.41 18.75 12 18.75Z"
    //       fill="#092C4C"
    //     />
    //     <path
    //       d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
    //       fill="#092C4C"
    //     />
    //   </g>
    // </svg>
  );
};

export default V2RemittanceIcon;

// import React, { useEffect } from "react";
// import { Select } from "antd";
// import { toast } from "react-toastify";

// const TagsDropdown = ({
//   setInputs,
//   fieldName,
//   data,
//   inputs,
//   lable,
//   setError,
//   error,
//   mode,
// }) => {
//   const handleChange = (value) => {
//     // if (inputs && inputs[fieldName]?.length <= 4) {
//     // do something
//     setInputs((values) => ({ ...values, [fieldName]: value }));
//     setError({
//       ...error,
//       [fieldName]: false,
//     });
//     // }
//     // else {
//     //   // do nothing
//     // }
//   };

//   // useEffect(() => {
//   //   if (inputs && inputs[fieldName]?.length > 5) {
//   //     // do something
//   //     toast.error("You can choose only max 5 NPI's", {
//   //       position: toast.POSITION.TOP_CENTER,
//   //     });
//   //   }
//   // }, [inputs]);

//   return (
//     <>
//       <div style={{ position: "relative" }}>
//         <span className="DR-goal-span-lb">{lable}</span>
//         <Select
//           mode={mode}
//           placeholder="Please select"
//           onChange={handleChange}
//           // name="npiTags"
//           className="DR-goal-tags"
//           size={"large"}
//           style={{
//             width: "100%",
//           }}
//           value={
//             inputs &&
//             inputs[`${fieldName}`] &&
//             inputs[`${fieldName}`].length > 0
//               ? inputs[`${fieldName}`]
//               : []
//           }
//           options={data}
//         />
//       </div>
//     </>
//   );
// };
// export default TagsDropdown;

import React, { useEffect } from "react";
import { Select } from "antd";

const TagsDropdown = ({
  setInputs,
  fieldName,
  data,
  inputs,
  label,
  setError,
  error,
  mode,
  disabledValues = [],
}) => {
  const handleChange = (value) => {
    setInputs((values) => ({ ...values, [fieldName]: value }));
    setError({
      ...error,
      [fieldName]: false,
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <span className="DR-goal-span-lb">{label}</span>
      <Select
        mode={mode}
        placeholder="Please select"
        onChange={handleChange}
        className="DR-goal-tags"
        size="large"
        style={{ width: "100%" }}
        value={
          inputs && inputs[fieldName] && inputs[fieldName].length > 0
            ? inputs[fieldName]
            : []
        }
        options={data.map((option) => ({
          ...option,
          disabled: disabledValues.includes(option.value),
        }))}
        showSearch
        filterOption={(input, option) =>
          option.label.toLowerCase().includes(input.toLowerCase())
        }
      />
    </div>
  );
};

export default TagsDropdown;

import { useSelector } from "react-redux";

function useModules() {
  const { userToken, loginUserData } = useSelector(
    (state) => state.pReducers.user
  );
  const roles = loginUserData?.modules;
  return [userToken, roles];
}

export default useModules;

import React from "react";

const MyWorkListIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 5.2L3.875 7L7 4"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 12.2L3.875 14L7 11"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 18.2L3.875 20L7 17"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 5H21"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 13H11.5"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 19H12"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 11.3554C15 11.9801 15.2482 12.5792 15.6899 13.0209C16.1316 13.4626 16.7307 13.7107 17.3554 13.7107C17.9801 13.7107 18.5792 13.4626 19.0209 13.0209C19.4626 12.5792 19.7107 11.9801 19.7107 11.3554C19.7107 10.7307 19.4626 10.1316 19.0209 9.68987C18.5792 9.24815 17.9801 9 17.3554 9C16.7307 9 16.1316 9.24815 15.6899 9.68987C15.2482 10.1316 15 10.7307 15 11.3554Z"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21 21V19.3333C21 18.4493 20.7366 17.6014 20.2678 16.9763C19.7989 16.3512 19.163 16 18.5 16H16"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default MyWorkListIcon;

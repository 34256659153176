import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
const defaultStartDate = moment().subtract(1, "month").format("YYYY-MM-DD");
const defaultEndDate = moment().format("YYYY-MM-DD");
const overstart = moment().subtract(5, "years").format("YYYY-MM-DD");
const overend = moment().format("YYYY-MM-DD");
const initialState = {
  defaultStartDate,
  defaultEndDate,
  overallAdoptionRates: {},
  oneMonthAdoptionRates: {},
  threeMonthAdoptionRates: {},
  loadingClass: "",
  startDate: defaultStartDate,
  endDate: defaultEndDate,
  overViewList: {},
  overViewLoadingClass: "",
  overViewstartDate: overstart,
  overViewendDate: overend,
  newStartLoadingClass: "",
  newStartStartDate: defaultStartDate,
  newStartEndDate: defaultEndDate,
  newStartMonth: 1,
  newStartResultType: "Week",
  newStartBarList: [],
  newStartCategories: ["Parent", "Boisimilar"],
  typeLoadingClass: "",
  typeStartDate: defaultStartDate,
  typeendDate: defaultEndDate,
  defaultType: "Chemo",
  typeMonth: 1,
  typeResult: "Week",
  typeCategories: ["Parent", "Chem/Bio"],
  typeBarList: [],
  tabStartDate: defaultStartDate,
  tabEndDate: defaultEndDate,
  tabsMonth: 1,
  tabResultType: "Week",
};

export const BoisimilarReducer = createSlice({
  name: "BoisimilarDashboard",
  initialState,
  reducers: {
    setDate(state, action) {
      const { startDate, endDate } = action.payload;
      return { ...state, startDate: startDate, endDate: endDate };
    },
    setOverallAdoptionRates(state, action) {
      return { ...state, overallAdoptionRates: action.payload };
    },
    setOneMonthAdoptionRates(state, action) {
      return { ...state, oneMonthAdoptionRates: action.payload };
    },
    setThreeMonthAdoptionRates(state, action) {
      return { ...state, threeMonthAdoptionRates: action.payload };
    },
    setLoadingClass(state, action) {
      return { ...state, loadingClass: action.payload };
    },
    setOverViewList(state, action) {
      return { ...state, overViewList: action.payload };
    },
    setOverViewLoadingClass(state, action) {
      return { ...state, overViewLoadingClass: action.payload };
    },
    setOverViewStartDate(state, action) {
      return { ...state, overViewstartDate: action.payload };
    },
    setOverViewEndDate(state, action) {
      return { ...state, overViewendDate: action.payload };
    },
    setNewStartLoadingClass(state, action) {
      return { ...state, newStartLoadingClass: action.payload };
    },
    setNewStartDate(state, action) {
      const { startDate, endDate } = action.payload;
      return {
        ...state,
        newStartStartDate: startDate,
        newStartEndDate: endDate,
      };
    },
    setNewStartMonth(state, action) {
      return { ...state, newStartMonth: action.payload };
    },
    setNewStartResultType(state, action) {
      return { ...state, newStartResultType: action.payload };
    },
    setNewStartBarList(state, action) {
      return { ...state, newStartBarList: action.payload };
    },
    setNewStartCategories(state, action) {
      return { ...state, newStartCategories: action.payload };
    },
    setTypeLoadingClass(state, action) {
      return { ...state, typeLoadingClass: action.payload };
    },
    setTypeDate(state, action) {
      const { startDate, endDate } = action.payload;
      return { ...state, typeStartDate: startDate, typeendDate: endDate };
    },
    setDefaultType(state, action) {
      return { ...state, defaultType: action.payload };
    },
    setTypeMonth(state, action) {
      return { ...state, typeMonth: action.payload };
    },
    setTypeResult(state, action) {
      return { ...state, typeResult: action.payload };
    },
    setTypeBarList(state, action) {
      return { ...state, typeBarList: action.payload };
    },
    setTabDate(state, action) {
      const { startDate, endDate } = action.payload;
      return { ...state, tabStartDate: startDate, tabEndDate: endDate };
    },
    setTabsMonth(state, action) {
      return { ...state, tabsMonth: action.payload };
    },
    setTabResultType(state, action) {
      return { ...state, tabResultType: action.payload };
    },
  },
});

export const {
  setDate,
  setOverallAdoptionRates,
  setOneMonthAdoptionRates,
  setThreeMonthAdoptionRates,
  setLoadingClass,
  setOverViewList,
  setOverViewLoadingClass,
  setOverViewStartDate,
  setOverViewEndDate,
  setNewStartLoadingClass,
  setNewStartDate,
  setNewStartMonth,
  setNewStartResultType,
  setNewStartBarList,
  setNewStartCategories,
  setTypeLoadingClass,
  setTypeDate,
  setDefaultType,
  setTypeMonth,
  setTypeResult,
  setTypeBarList,
  setTabDate,
  setTabsMonth,
  setTabResultType,
} = BoisimilarReducer.actions;
export default BoisimilarReducer.reducer;

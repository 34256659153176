import React from "react";

const DRCopyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-copy"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      stroke-width="1"
      stroke="#2c3e50"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      cursor="pointer"
    >
      {/* <path stroke="none" d="M0 0h24v24H0z" fill="none" /> */}
      <path d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z" />
      <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
    </svg>
  );
};

export default DRCopyIcon;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Avatar,
  Button,
  ListItemText,
  MenuItem,
  MenuList,
  Badge,
  Popper,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { notifications } from "../../actions";
import User from "../../actions/user";

import { ClickAwayListener } from "@mui/base";

import singleLogout from "../../Routes/Logout";
import { saveLogForLogActivity } from "../../Redux/Reducers/Settings/activityLogSlice";

import NewPreference from "./NewPreference";
import NotificationsDrawer from "./notificationsDrawer";
import DRLogoutIcon from "../../assets/svgs/LogoutIcon";
import DRUserIcon from "../../assets/svgs/DRUserIcon";
import V2NotificationIcon from "../../assets/svgs/V2NotificationIcon";
import ArrowDownMenu from "../../assets/svgs/ArrowDownMenu";
import { useMsal } from "@azure/msal-react";
// import SettingsIcon from "../../assets/svgs/SettingsIcon";
import browserRoute from "../../constants/browserRoutes";
import { setListingState } from "../../Redux/Reducers/filters/claimManagerFilters";
// import BotModelNew from "../ChatBotNew/BotModel";
// import BotModel from "../ChatBot/BotModel";
import BotModelDashboard from "../ChatBotNew/BotModelDash";
import { environment } from "../../constants/serverRoutes";
import InfoIconHelp from "../../assets/svgs/InfoIconHelp";
import { getUserName } from "../common/Utils/utils";
import V2ChatIcon from "../../assets/svgs/V2ChatIcon";

export default function MenuListComposition({ target, hideBot }) {
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();

  const {
    loginUserData: user,

    userToken,
    loginType,
  } = useSelector((state) => state.pReducers.user);
  const { loginUserData, currentModule } = useSelector(
    (state) => state.pReducers.user
  );
  const {
    //  newNotificationValue,
    newNotification,
    newNotificationCount,
  } = useSelector((state) => state.npReducers.filters.notificationsFilter);
  let userInfo = user;

  // let userNameDisplay = userInfo.displayName
  //   ? userInfo.displayName
  //       .split(/\s/)
  //       .reduce((response, word) => (response += word.slice(0, 1)), "")
  //   : "";

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [notify, setNotify] = React.useState(false);
  const [notificationsArr, setNotificationsArr] = React.useState([]);
  const [newNotify, setNewNotifiy] = useState(newNotification);
  const [newNotifyCount, setNewNotifiyCount] = useState(newNotificationCount);
  const [notificationCount, setNotificationCount] = React.useState(1);
  const [singleNotification, setSingleNotification] = React.useState({});
  const [preferenceFilterBar, setPreferenceFilterBar] = useState("");
  const [preferenceFilterBarSub, setPreferenceFilterBarSub] = useState("");
  const [loadingClass, setLoadingClass] = useState("");

  // for handle Role base access
  // const [SuperAdmin, setSuperAdmin] = useState(false);
  // const [administrator, setAdministrator] = useState(false);
  // const [analyst, setAnalyst] = useState(false);
  // const [manager, setManager] = useState(false);
  // const [executive, setExecutive] = useState(false);

  const { roles } = user;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClickLogout = async () => {
    singleLogout(dispatch, instance);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  const renderUsername = () => {
    if (loginType === "Local") {
      return userInfo?.Username
        ? userInfo?.Username
        : userInfo?.email
        ? userInfo?.email
        : "";
    } else {
      return userInfo?.email ? userInfo?.email : "";
    }
  };
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    let hasUserRole = [];
    if (roles?.length) {
      hasUserRole = roles.map((a) => a.name);
    }
    if (hasUserRole?.length) {
      // setSuperAdmin(hasUserRole.includes("Super Administrator"));
      // setAdministrator(hasUserRole.includes("Administrator"));
      // setAnalyst(hasUserRole.includes("Analyst"));
      // setManager(hasUserRole.includes("Manager"));
      // setExecutive(hasUserRole.includes("Executive"));
    }
  }, [userToken]);

  let callNotification = true;

  const readAllNotification = () => {
    // dispatch(
    //   setListingState({
    //     listing: "notificationsFilter",
    //     value: {
    //       newNotification: false,
    //       newNotificationValue: {},
    //     },
    //   })
    // );
    dispatch(
      setListingState({
        listing: "userPreferencesFilter",
        value: {
          openDrawerChatbot: false,
          openDrawerCutomChatbot: false,
          openClaimNotes: false,
        },
      })
    );
    dispatch(
      setListingState({
        listing: "askZoeFilter",
        value: {
          showTableColumnFilter: "",
        },
      })
    );
    // for clsoing filter siderBar
    dispatch(
      setListingState({
        listing: target,
        value: {
          showFilterBody: "",
        },
      })
    );
    setNotify(true);
  };

  // const markAllAsRead = () => {
  //   readAllNotification();
  //   setSingleNotification({});
  //   setNotificationsArr([]);
  //   setNotificationCount(0);
  //   setNotify(false);
  // };

  const handlePreferenceFilterBarSub = () => {
    if (preferenceFilterBarSub === "open") {
      setPreferenceFilterBarSub("");
      // setMessage({ type: "", message: "" });
    } else {
      setPreferenceFilterBarSub("open");
    }
  };
  const handlePreferenceFilterBarSubC = () => {
    if (preferenceFilterBarSub === "open") {
      setPreferenceFilterBarSub("");
      // setMessage({ type: "", message: "" });
    } else {
      setPreferenceFilterBarSub("");
    }
  };

  const handleSavePreference = (preferences) => {
    setLoadingClass("loading");
    User.savePreferences({
      ...preferences,
      userId: userToken,
      email: userInfo.email,
    })
      .then((res) => {
        setLoadingClass("");

        if (res?.data && res?.data?.success) {
          // setUserPreferences(preferences);
          setPreferenceFilterBar("");
          localStorage.setItem(
            "NoOfRecordInTable",
            preferences?.NoOfRecordInTable
          );

          dispatch(saveLogForLogActivity(`${res.data.message}...`));
        }
      })
      .catch((error) => {
        setLoadingClass("");
      });
  };

  const getProactiveNotification = () => {
    notifications
      .getProactiveNotification()
      .then((response) => {
        setLoadingClass("");
        const { data: result } = response;
        let list = [];
        if (typeof result.data !== "undefined" && result.data !== "") {
          result.data.forEach((item) => {
            const msg = JSON.parse(item.message);
            list.push({
              listItem: `${item.monthName} ${item.notificationTypeName}`,
              date: item.createdOn,
              message: msg,
              type: "proactivenotification",
              markAasRead: false,
              id: item.proactiveNotificationId,
            });
          });
        }
        const newarray = [...notificationsArr, ...list];
        setNotificationsArr(newarray);
      })
      .catch((error) => {
        setLoadingClass("");
      });
  };

  const getNotification = () => {
    if (callNotification === true) {
      callNotification = false;
      notifications
        .getNotifications(userToken)
        .then((response) => {
          let notificationcount = 0;
          callNotification = true;
          var obj = response.data.data.notifications;
          if (response.data.data.count > 0) {
            setNotificationsArr([]);
            for (let i = 0; i < obj.length; i++) {
              let notificationObj = {
                listItem: obj[i].title,
                date: obj[i].createdOn,
                message: obj[i].message,
                type: "notification",
                markAasRead: true,
                id: obj[i].notificationId,
              };
              setNotificationsArr((notificationsArr) => [
                ...notificationsArr,
                notificationObj,
              ]);
            }
            setNotificationCount(response.data.data.count);
          }
        })
        .catch((error) => {});
    }
  };

  const handleSingleNotification = (item) => {
    if (item.type === "proactivenotification")
      notifications
        .markProactiveNotificationAsRead(item.id)
        .then((data) => {
          let notification = notificationsArr;
          const index = notificationsArr.indexOf(item);
          item.markAasRead = true;
          notification[index] = item;
          setNotificationsArr(notification);
        })
        .catch((err) => {});

    setSingleNotification(item);
  };

  useEffect(() => {
    setNewNotifiy(newNotification);
    setNewNotifiyCount(newNotificationCount);
  }, [newNotification, newNotificationCount]);

  return (
    <ClickAwayListener onClickAway={handlePreferenceFilterBarSubC}>
      <div className="DR-user-drop-topnav">
        <div className="notify-icons">
          {/* <span className="DR-line-new-design mt-0 mr-2 line-op-sea"></span> */}
          {/* <BotModel /> */}
          {!hideBot && (
            // <BotModelDashboard target={target} />
            <>
              <NavLink
                className="DR-lida-bot-link"
                to={browserRoute?.CHAT_BOT_RESULT}
              >
                <V2ChatIcon />
              </NavLink>
            </>
          )}

          {!loginUserData?.isOnlyPolicyCopilotSelected &&
            currentModule.label !== "Copilot" && (
              <Button
                aria-label="show 11 new notifications"
                aria-haspopup="true"
                onClick={() => readAllNotification()}
                className={notify ? "DR-noti-icon Chat-active" : "DR-noti-icon"}
              >
                {newNotify ? (
                  <Badge badgeContent={newNotifyCount} color="secondary">
                    <V2NotificationIcon target={target} />
                  </Badge>
                ) : (
                  <Badge color="secondary">
                    <V2NotificationIcon target={target} />
                  </Badge>
                )}
              </Button>
            )}
          {/* {!loginUserData?.isOnlyPolicyCopilotSelected &&
            currentModule.label !== "Copilot" && (
              <span className="DR-line-new-design mt-0 mr-2"></span>
            )} */}
          <Button
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <ListItemText id="Step#3">
              <span className="flex items-center">
                <Avatar className="role-avatar">{getUserName()}</Avatar>
                {/* <span className="user-name"></span> */}
                <span className="flex items-center justify-center">
                  <ArrowDownMenu color="#092c4c" />
                </span>
              </span>
            </ListItemText>
          </Button>
          <Popper
            className="dropdown-menu-ui"
            open={open}
            anchorEl={anchorRef.current}
            // role={undefined}
            transition
            disablePortal={true}
            placement="bottom"
          >
            <>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  className="pb-0 pt-3"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem className="dropdown-menu-ui-name ui-drop-name-bold">
                    {userInfo.displayName ? `${userInfo?.displayName}` : "-"}
                  </MenuItem>
                  <MenuItem className="dropdown-menu-ui-name DR-email-grey-user">
                    {renderUsername()}
                  </MenuItem>
                  <span className="br-line"></span>
                  <MenuItem>
                    <NavLink to={browserRoute.PROFILE}>
                      <DRUserIcon color="#131722" />
                      <span className="link-one-text">My Profile</span>
                    </NavLink>
                  </MenuItem>

                  {/* {administrator && (
                    <MenuItem>
                      <NavLink to={browserRoute.SETTING_DASHBOARD}>
                        <SettingsIcon color="#131722" />
                        <span className="link-one-text">Settings</span>
                      </NavLink>
                    </MenuItem>
                  )} */}
                  <NewPreference setOpen={setOpen} />
                  <MenuItem>
                    <NavLink
                      target="_blank"
                      to="https://support.datarovers.ai/"
                    >
                      <InfoIconHelp color="#131722" />
                      <span className="link-one-text">Help</span>
                    </NavLink>
                  </MenuItem>
                  <MenuItem
                    className="dropdown-menu-ui-logout"
                    onClick={handleClickLogout}
                  >
                    <a>
                      <DRLogoutIcon color="#131722" />
                      <span className="link-one-text">Logout</span>
                    </a>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </>
          </Popper>
          {/* <div className={'filter-sidebar ' + preferenceFilterBar}>
            <h3>
              <img src={SettingIconGr} alt="Icon" /> 
              Preferences{' '}
            </h3>
          </div>
          <div
            className={
              'filter-sidebar sub-left-d-menu ' + preferenceFilterBarSub
            }
          >
            <h3>
              <img src={SettingIconGr} alt="Icon" /> 
              Subscription{' '}
            </h3>
            <div className="actions">
              <CloseIcon onClick={() => handlePreferenceFilterBarSub()} />
            </div>
          </div> */}
          <NotificationsDrawer
            notify={notify}
            setNotify={setNotify}
            newNotification={newNotification}
          />
        </div>
      </div>
    </ClickAwayListener>
  );
}

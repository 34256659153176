const browserRoute = {
  // HOST: "http://localhost:5000",
  // HOST: "http://localhost:5000",
  BLANK_LINK: "#!",
  REACT_SERVER_DEFAULT_URL: "/",
  LOGIN: "/login",
  LOGINSSO: "/login-sso",

  AUTH_CODE_VERIFICATION: "/code",
  NEW_TANENT_EMAIL_VERIFICATION: "/email-verification/:id",

  REGISTER: "/register",
  NEW_REGISTER: "/new-register",
  FORGOT_PASSWORD: "/forget",
  CHANGE_PASSWORD: "/user/changePassword/:autCode",

  ERROR_500: "/500",
  ERROR_404: "/404",

  OLD_DASHBOARD: "/old-dashboard",
  ONCOLOGY_DASHBOARD: "/oncology",
  DRUG_ACC_RATE: "/onco-accrate",
  DRUG_ACC_RATE_DETAIL: "/onco-accrate-detail",
  CARC_ANALYSIS: "/carc-analysis",
  ONCOLOGY_DENIAL: "/Onco-denials",
  ONCOLOGY_CHARTS: "/oncology-charts",
  CPT_ANALYSIS_DASHBOARD: "/codes",
  CPT_ANALYSIS_DETAILS: "/CPT-details",

  DENIALS_OVERVIEW_DASHBOARD: "/denials-old",
  DENIALS_OVERVIEW_DASHBOARD_New: "/denials",
  DENIALS_DASHBOARD_DETAIL: "/denials-dashboard-detail",
  DENIALS_SNAPSHOT_DASHBOARD: "/denials-dashboard",
  DENIALS_DASHBOARD: "/old-denials-dashboard",
  DENIALS_BY_CPT: "/denials-cpt",
  DENIALS_BY_PAYER: "/denials-payer",
  DENIALS_BY_REASONCODE: "/denials-reason-code",
  DENIALS_DETAIL: "/ra-den",
  DENAIL_BY_SERVICELINE: "/ra-sl",
  REVERSAL_DENIED: "/rev",
  PARIALLY_DENIED: "/partial",
  DENIALS_BY_PAYER_NEW: "/denial-by-payer-new",
  FACITILY_DENIALS: "/in-facility",

  BIOSIMILAR_CODE_DETAIL: "/biosimilar",
  BIOSIMILAR_DASHBOARD: "/biosim",

  USERS_LIST: "/users",
  USERS_ACTIVITY_LOG: "/activity-log",
  USERS_LOGIN_ATTEMPTS: "/login-attempts",
  USERS_PROACTIVE_NOTIFICATIONS: "/proactive-notifications",

  GALLARY_FOR_TEST: "/nevo-charts",
  LABELS_FOR_TEST: "/labels",
  PREDICTIVE_ANALYTICS_FOR_TEST: "/Predictive-analytics",

  INSIGHTS_SMART_INSIGHTS: "/smart-insights",
  INSIGHTS_SMART_INSIGHTS_BROWNBAGGING_DETAIL: "/Brown-Bagging-Detail",
  INSIGHTS_SMART_INSIGHTS_BIOSIMILAR_DETAIL: "/Biosimilar-Detail",
  INSIGHTS_SMART_INSIGHTS_POLICYTERMED_DETAIL: "/Policy-Termed-Detail",
  INSIGHTS_SMART_INSIGHTS_PRIORAUTH_DETAIL: "/Prior-Auth-Detail",
  INSIGHTS_SMART_INSIGHTS_DENIALRATIO_DETAIL: "/Denial-Ratio-Detaill",

  INSIGHTS_PAYER_DASHBOARD: "/in-payers",
  NEW_INSIGHTS_PAYER_DASHBOARD: "/new-in-payers",

  DEPARTMENT_DASHBOARD: "/dep",

  // INSIGHTS_DASHBOARD: "/Exective-New",
  EXECTIVE_DASHBOARD_NEW: "/",
  PROVIDER_DASHBOARD: "/in-Providers",

  // EXECTIVE_DASHBOARD_FOR_DESIGN: "/new-insights",
  // EXECTIVE_DASHBOARD_FOR_DESIGN: "/new-insights",

  USE_CASES: "/usecases",

  REPORTS_DASHBOARD: "/reports-main",
  REPORTS_DRUGS_REJECTED: "/denials-rep",
  REPORTS_REVENUE_BY_PAYER: "/revenue-by-payer",
  REPORTS_SERVICE_LINE_DENIALS: "/report",

  ALERT_CENTER: "/alert-center",
  ALERT_CENTER_Details: "/alert-details",
  ALL_NOTIFICATION: "/all-notification",
  APPEALS_QUEUE_CLAIM: "/appeal-queue",
  APPEALS_CONFIG: "/appeal-config",
  AETNA_FORM: "/aetna",
  UPLOAD_CLAIM_NEW: "/claim-upload",
  ASK_AVA: "/ask-ava",
  ASK_ZOE: "/ask-zoe",
  ASK_ZOE_CHAT: "/c",
  SESSION_TIMEOUT: "/sessionTimeout",
  ASK_CUSTOM: "/custom-copilot",
  ASK_ZOE2: "/ask-zoe-experimental",

  ASK_ROVERS: "/eligibility",

  RACR_DASHBOARD: "/rarc",

  SMART_QUEUES_DASHBOARD: "/queue-dashboard",

  SMART_QUEUES_SETTINGS: "/queues-settings",

  CLAIM_QUEUE_DETAIL_MY_TASK: "/my-task",

  PAYER_CPT_ANALYSIS: "/cpt-analysis",

  CSV_CONFIGRATION: "/csv-config",

  UPLOAD_DOCOMENTS: "/upload-doc",
  PDF_VIEW_COPILOT: "/pdf-view-copilot",
  PDF_VIEW_DOCUMENT: "/pdf-view-document",

  SYSTEM_MAINTENANCE: "/sys-main",

  CLIENTS: "/customer",
  COSTOM_STATUS_CODES_SCREEN: "/custom-status",
  CALLBACK: "/callback",

  CUSTOM_PROCEDURE_CODE: "/custom-proce-code",

  DENIALS_CATEGORY: "/den-cat",

  QUESTION_TEMPLATE: "/questions",

  CLAIM_PROGRESS: "/claim-pro",

  ACTIVITY_TIMELINE: "/activity-timeline",

  POLICY_GUIDELINES: "/policy-Guidelines",

  APPEALS_DASHBOARD: "/appeals-dashboard",

  Queue_COMMENTS: "/queue-comments/:id/:claim/:queue/:queueName/:queueId",

  CLAIM_TIMELINE: "/time-line/:PCN",
  CLAIM_PRIOR_AUTH: "/prior-auth",

  CLAIMS_DASHBOARD: "/insights",

  CUSTOMER_CONFIG_COLUMNS: "/customer-config",

  FILES_INTIGRATION: "/files-integration",

  NEW_HOME: "/new-home",
  AI_HOME: "/ai-home",
  APPEAL_HOME: "/appeal-home",

  QUEUES_BUCKET: "/queues-bucket",

  CLAIM_PAYMENT_835: "/ra",
  CLAIM_PAYMENT_835_UNDER_PAYMENT: "/ra-up",
  CLAIM_PAYMENT_835_DETAIL: "/ra-det/:id",
  NEW_CLAIM_PAYMENT_835_DETAIL: "/nw-ra-det/:id",
  OLD_CLAIM_PAYMENT_835_DETAIL: "/old-ra-det/:id",
  CLAIM_ADVANCE_SEARCH: "/advance-search",
  MAPBOX_TESTING: "/mapbox-testing",

  CLAIM_ACK: "/ack",

  CLAIM_MANAGER_837: "/claims",
  CLAIM_MANAGER_837_TEST: "/claim-manager",
  CLAIM_MANAGER_837_DETAIL: "/claim-detail/:id",
  NEW_CLAIM_MANAGER_837_DETAIL: "/new-claim-detail/:id",
  CLAIM_MANAGER_837_PRO_DETAIL: "/Pro-claim-detail/:id",
  NEW_CLAIM_MANAGER_837_PRO_DETAIL: "/new-Pro-claim-detail/:id",
  CLAIM_MANAGER_837_CLAIM_AGING_REPORT: "/claim-aging-report",

  CLAIM_277_DETAIL: "/claim277/:id/:requestId",

  DRUG_NEW: "/drug-new",
  DENIAL_BY_REASON_CODE: "/denial-by-reason",
  CARC_TRENDS: "/carc-trends-Details",
  CARC_TRENDS_DASHBOARD: "/carc-trends",

  DENIAL_DASHBOARD_DR: "/denial-DR",

  REASON_CODE: "/reason-code",
  CARC_DISTRIBUTION_ANALYSIS: "/carc-distribution-analysis",
  CODE_EXPLORER_DASHBOARD: "/code-explorer",
  DENIAL_OVERVIEW: "/denial-overview",

  PROCEDURE_CODE: "/Procedure-code",

  PAYER_DENIAL_DASHBOARD: "/payer-denial",

  CHAT_BOT_RESULT: "/bot-res",

  CHAT_BOT_RESULT_NEW: "/bot-res-new",

  VIDEO_TUTORIAL: "/video",

  CARC_CATEGORY: "/carc-cat",

  CARC_DISTRIBUTOIN: "/carc-dis",

  DR_LANDING_PAGE: "/new-home",
  DR_LANDING_PAGE_TWO: "/",

  DEPARTMENT_MAIN: "/sl",

  HEALTH_SYSTEM: "/hs",

  EXCEPTION_LIST: "/exception-list",
  DENAILS_BY_PAYER: "/denail-payer",
  REASON_CODE_TRENDS_FOR_PAYER: "/reasoncode-payer",

  GOALS_TRAKING: "/goals",
  NEW_GOAL: "/new-goal",
  EDIT_GOAL: "/edit-goal/:id",
  GOAL_HISTORY: "/goal-history/:id",

  REASON_CODE_HEATMAP: "/sys-map",
  REVENUE_HEATMAP: "/revenue-heatmap",
  ANESTHESIA_HEATMAP: "/anesthesia-heatmap",
  SURGERY_HEATMAP: "/surgery-heatmap",
  RADIOLOGY_HEATMAP: "/radiology-heatmap",
  PATHOLOGY_HEATMAP: "/pathology-heatmap",
  MEDICINE_HEATMAP: "/medicine-heatmap",

  BUILD_REDIRECT: "/build-redirect",

  PATIENT_DETAIL_BY_ID: "/pt-det/:id",
  PATIENT_DETAIL_CLAIM_PAYMENT_BY_ID: "/patientDetails-claimPayment/:id",
  PATIENT_DETAIL_CLAIM_MANAGER_BY_ID: "/patientDetails-claimManager/:id",

  DATA_SOURCES: "/sources",
  LOGS_PAGE: "/logs/:id",
  SUBSCRIPTION: "/sub",
  DRUG_DENIAL_DETAIL: "/drug-dendet",

  PROFILE: "/profile",

  UPLOAD_CLAIMS: "/uploads",

  SETTING_DASHBOARD: "/settings",
  Other_Links_Experimental: "/other-links",
  DASHBOARD_LINKS: "/dashboard-links",

  SETTING_ADVANCE_SETTING: "/advance",

  CLAIM_QUEUE: "/claim-queue",
  CLAIM_QUEUE_DETAIL: "/queue-claim-details",
  APPEAL_QUEUE_DETAIL: "/appeal-claim-details",

  DR_SEARCH: "/DR-search",

  SSO_SCREEN: "/sso",
  API_SCREEN: "/api",

  LOCATIONS: "/locations",
  DELETE_CLAIM: "/delete-claim",

  SEARCH_RESULTS: "/search-results",

  DRAG_AND_DROP: "/DragDropLayout",

  GRAPH_DETAILS: "/graph-details",

  DENIALS_BY_CPT_SCREEN: "/pt-repeatden",
  DENIALS_BY_ZIP_CODE: "/zip",
  DETAILS_BY_PTCN: "/details-ptcn",

  // Super admin panel
  // Super admin panel
  SUPER_ADMIN_PANEL_DASHBOARD: "/dr-services",
  SUPER_ADMIN_PANEL_LICENSE_MANAGEMENT: "/lincense-management",
  SUPER_ADMIN_PANEL_SEND_NOTIFICATION: "/send-notification",
  SUPER_ADMIN_PANEL_CODE_MANAGEMENT_JCODE: "/code-management/jcode",
  SUPER_ADMIN_PANEL_CODE_MANAGEMENT_ADMINCODE: "/code-management/adminCode",
  SUPER_ADMIN_PANEL_CUSTOMERS: "/customers",
  SUPER_ADMIN_PANEL_UPDATE_CUSTOMER: "/update-customer/:id",
  SUPER_ADMIN_PANEL_BIOSIMILAR_CODE_LIST: "/biosimilars",
  SUPER_ADMIN_PANEL_CLAIM_RULES: "/rules",
  SUPER_ADMIN_PANEL_SUBSCRIPTION_RESET_PASSWORD: "/reset-password",
  SUPER_ADMIN_PANEL_SUPPORT: "/support",
  SUPER_ADMIN_PANEL_MESSAGE_CENTER: "/message-center",
  SUPER_ADMIN_PANEL_CLAIM_DENIAL_CODE: "/claim-denial-code",

  // test
  TEST_COMPO_CODE: "/test",
  DRAG_AND_DROP_DEMO: "/dnd-demo",
  DRAG_AND_DROP_INT: "/dnd-int",
  CLAIM_PAYMENT_835_DND: "/ra-dnd",
  DENIALS_DETAIL_DND: "/ra-den-tbd",
  CLAIM_MANAGER_837_DND: "/claims-dnd",

  //Attachments Preview
  ATTACHMENTS_PREVIEW: "/attachments",
  //Moudle Urls
  SMART_APPEAL_HOME: "/appeals-dashboard",
  DENIALS_360_HOME: "/",
  POLICY_ADVISOR_HOME: "/ask-zoe",
  DOMAIN_COPILOT: "/domain-copilot",

  DEPARTMENTS_DASHBOARD: "/dep-carc",
  DEPARTMENTS_CPT: "/dep-cpt",
  DEPARTMENTS_RARC: "/dep-rarc",
};

export default browserRoute;

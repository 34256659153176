import { createSlice } from "@reduxjs/toolkit";

import denialSerive from "../../../actions/danialService";
import { getLegendsShortName } from "../../../components/common/Utils/utils";
import {
  setListingState,
  resetListingState,
} from "../filters/claimManagerFilters";

export const denialByPayerSlice = createSlice({
  name: "denialByPayerSlice",
  initialState: {
    denialByPayerData: {
      volumeList: [],
      volumePayerList: [],

      amountList: [],
      amountPayerList: [],
    },
  },
  reducers: {
    denialByPayerVolumeGraphData_Action: (state, action) => {
      let data = action.payload;

      let list = [];
      let payers = [];
      data.forEach((item) => {
        const payerName = item.Name;
        const amount = item.DenialsVolume;
        const colorName = `${payerName}Color`;
        const colorValue = `hsl(${amount}, 70%, 50%)`;
        const newPayerName = getLegendsShortName(payerName);
        payers.push(newPayerName);
        let obj = {};
        obj["country"] = newPayerName;
        obj[newPayerName] = amount;
        obj[colorName] = colorValue;
        obj["payerName"] = payerName;
        list.push(obj);
      });
      const uniquePayers = [...new Set(payers)];

      state.denialByPayerData.volumeList = list;
      state.denialByPayerData.volumePayerList = uniquePayers;
    },

    denialByPayerAmountGraphData_Action: (state, action) => {
      let data = action.payload;

      let list = [];
      let payers = [];
      data.forEach((item, index) => {
        const payerName = item.Name;
        const amount = Math.round(item.DenialsAmount);
        const newPayerName = getLegendsShortName(payerName);
        payers.push(newPayerName);

        const colorName = `${newPayerName}Color`;
        const colorValue = `hsl(${amount}, 70%, 50%)`;
        let obj = {};
        obj["country"] = newPayerName;
        obj[newPayerName] = amount;
        obj[colorName] = colorValue;
        obj["payerName"] = payerName;
        list.push(obj);
      });
      const uniquePayers = [...new Set(payers)];

      state.denialByPayerData.amountList = list;
      state.denialByPayerData.amountPayerList = uniquePayers;
    },
  },
});

export const {
  denialByPayerAmountGraphData_Action,
  denialByPayerVolumeGraphData_Action,
} = denialByPayerSlice.actions;

export const denialsByPayerGraphDataByRevenueCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialsByPayerFilter",
          ignore: [],
        })
      );
    }

    let sliceState = getState().npReducers.filters.denialsByPayerFilter;

    try {
      dispatch(
        setListingState({
          listing: "denialsByPayerFilter",
          value: {
            revenueGraphLoadingMore: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const res = await denialSerive.getDenialsAmountByPayer({
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
      });

      ////// API CALL END //////////////

      dispatch(
        setListingState({
          listing: "denialsByPayerFilter",
          value: {
            // hasMore: res?.data?.data?.output?.length === sliceState.pageSize,
            revenueGraphLoadingMore: "",
            // totalRecord_DB: res?.data?.data?.count,
          },
        })
      );
      dispatch(denialByPayerAmountGraphData_Action(res?.data?.data));
      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "denialsByPayerFilter",
          value: {
            initialLoading: false,
            revenueGraphLoadingMore: "",
            hasMore: false,
          },
        })
      );
      return false;
    }
  };

export const denialsByPayerGraphDataByVolumeCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialsByPayerFilter",
          ignore: [],
        })
      );
    }

    let sliceState = getState().npReducers.filters.denialsByPayerFilter;

    try {
      dispatch(
        setListingState({
          listing: "denialsByPayerFilter",
          value: {
            volumeGraphLoadingMore: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const res = await denialSerive.getDenialsVolumeByPayer({
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
      });

      ////// API CALL END //////////////

      dispatch(
        setListingState({
          listing: "denialsByPayerFilter",
          value: {
            // hasMore: res?.data?.data?.output?.length === sliceState.pageSize,
            volumeGraphLoadingMore: "",
            // totalRecord_DB: res?.data?.data?.count,
          },
        })
      );
      dispatch(denialByPayerVolumeGraphData_Action(res?.data?.data));
      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "denialsByPayerFilter",
          value: {
            initialLoading: false,
            volumeGraphLoadingMore: "",
            hasMore: false,
          },
        })
      );
      return false;
    }
  };

export default denialByPayerSlice.reducer;

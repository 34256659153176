import { createSlice } from "@reduxjs/toolkit";

import { serverRoutes } from "../../../constants/serverRoutes";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";

import { resetListingState } from "../filters/claimManagerFilters";
import {
  commonPagination,
  getCurrentClientId,
} from "../../../components/common/Utils/utils";
import store from "../../store";

export const MapBoxSlice = createSlice({
  name: "MapBoxSlice",
  initialState: {
    mapBoxTabularDataArr: [],
  },
  reducers: {
    setMapBoxTabular_dataAction: (state, action) => {
      let data = action.payload;
      if (data?.length > -1) {
        state.mapBoxTabularDataArr = data;
      } else {
        state.mapBoxTabularDataArr = [];
      }
    },
  },
});

export const { setMapBoxTabular_dataAction } = MapBoxSlice.actions;

// get dataSources list
export const gettingMapBoxTabularData_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "mapBoxFilter",
          //   ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;
    let sliceState = getState().npReducers.filters.mapBoxFilter;
    let sliceStateUser = getState().pReducers.user;

    let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;
    //
    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable?.mapBoxFilterNoOfRecordInTable;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : sliceStateForDate?.clientId || getCurrentClientId(),
        }),
      };
      let params = {
        start,
        limit,
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : sliceStateForDate?.clientId || getCurrentClientId(),
        }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setMapBoxTabular_dataAction,
        "mapBoxFilter",
        "GET_MAPBOX_TABULAR_DATA",
        null
      );
    } catch (e) {
      //   console.log("e--->>", e);
    }
  };

export const getMapboxTabularLevelOne_Data =
  (zipCode) => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.insightsSreensCommonFilter;
    let start = 0;
    let limit = 500;
    try {
      ///// API CALL //////

      let params = {
        start,
        limit,
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...{
          postalcode: zipCode,
        },
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_LEVEL_ONE_MAPBOX_TABULAR_DATA,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };

export default MapBoxSlice.reducer;

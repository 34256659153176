import { Skeleton } from "@mui/material";
import React from "react";

const FilterLoading = ({ width, height }) => {
  return (
    <Skeleton
      width={width ? `${width}px` : "100px"}
      height={height ? `${height}px` : "80px"}
    />
  );
};

export default FilterLoading;

import { createSlice } from "@reduxjs/toolkit";

export const showErrorSuccessMsg = createSlice({
  name: "showErrorSuccessMsg",
  initialState: {
    messageInfo: { type: false, message: "", open: false },
  },
  reducers: {
    setMessage: (state, action) => {
      const { payload } = action;
      let messageInfo = {
        ...state.messageInfo,
        type: payload.type,
        message: payload.message,
        open: payload.open,
      };
      state = { ...state, messageInfo: messageInfo };
      return state;
    },
  },
});

export const { setMessage } = showErrorSuccessMsg.actions;

export default showErrorSuccessMsg.reducer;

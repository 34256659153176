import { Component } from "react";
import singleLogout, { singleLogout3 } from "./Routes/Logout";
// import singleLogout from "./Routes/Logout";

class ErrorBoundary extends Component {
  componentDidCatch(error, info) {
    // Log the error or send it to a logging service here
    console.error(error, info);

    // Automatically refresh the page
    setTimeout(() => {
      singleLogout3();
    }, 0); // Refresh the page immediately
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;

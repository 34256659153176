import React from 'react';

const PrefrencesIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-adjustments-horizontal"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke-width="1"
      stroke={color ? `${color}` : '#9da3b0'}
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <circle cx="14" cy="6" r="2" />
      <line x1="4" y1="6" x2="12" y2="6" />
      <line x1="16" y1="6" x2="20" y2="6" />
      <circle cx="8" cy="12" r="2" />
      <line x1="4" y1="12" x2="6" y2="12" />
      <line x1="10" y1="12" x2="20" y2="12" />
      <circle cx="17" cy="18" r="2" />
      <line x1="4" y1="18" x2="15" y2="18" />
      <line x1="19" y1="18" x2="20" y2="18" />
    </svg>
  );
};

export default PrefrencesIcon;

import React from "react";

const CloseIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="15" fill="none" fill-opacity="0.59" />
      <path
        d="M15 13.8891L18.8891 10L20 11.1109L16.1109 15L20 18.8891L18.8891 20L15 16.1109L11.1109 20L10 18.8891L13.8891 15L10 11.1109L11.1109 10L15 13.8891Z"
        fill="#3CA54F"
      />
    </svg>
  );
};

export default CloseIcon;

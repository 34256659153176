import React from "react";
import {
  Button,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Popover,
  TableSortLabel,
} from "@mui/material";
import TickIcon from "../../../assets/svgs/TickIcon";
import BootstrapTooltip from "../TooltipUI/customToolTip";
import UpDownArrow from "../../../assets/svgs/UpDownArrow";
import { useSelector } from "react-redux";

export default function NewSortingDropDown(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const { orderBy, order, pageSize, loadingMore } = useSelector(
    (state) => state.npReducers.filters?.[props?.target]
  );

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleSorting = (value) => {
    try {
      if (value === "asc") {
        props.handler(props.label, "asc");
      } else if (value === "desc") {
        props.handler(props.label, "desc");
      }
    } catch (error) {
      // console.log("error", error);
    }
  };
  const toggleSorting = () => {
    handleSorting(order === "asc" ? "desc" : "asc");
  };
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <div className="DR-p-rel">
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        disableRipple="true"
        // onClick={handleToggle}
        className="title-drop-btn p-0"
      >
        {/* <TableSortLabel
          active={true}
          direction={props.orderBy === props.label ? props.order : "asc"}
          style={{ fontSize: "11px", textTransform: "capitalize" }}
        >
          {props.toolTip ? (
            <BootstrapTooltip placement="bottom" title={props.toolTipTitle}>
              <span>{props.title}</span>
            </BootstrapTooltip>
          ) : (
            <>{props.title}</>
          )}
        </TableSortLabel> */}
        <span
          onClick={toggleSorting}
          style={{
            fontSize: "11px",
            textTransform: "capitalize",
            cursor: "pointer",
          }}
        >
          {props.toolTip ? (
            <BootstrapTooltip
              placement="bottom"
              title={props.toolTipTitle}
            ></BootstrapTooltip>
          ) : (
            <span>{props.title}</span>
          )}
        </span>
        <span
          className="DR-sorting-icon"
          onClick={() => {
            handleSorting(order === "asc" ? "desc" : "asc");
          }}
        >
          <UpDownArrow
            bottonColor={
              orderBy === props.label
                ? order === "desc"
                  ? true
                  : false
                : false
            }
            topColor={
              orderBy === props.label ? (order === "asc" ? true : false) : false
            }
          />
        </span>
      </Button>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: "1" }}
        className="DR-title-drop-inner V2-Sort-drop"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList
            autoFocusItem={open}
            id="menu-list-grow"
            onKeyDown={handleListKeyDown}
          >
            {props.orderBy === props.label ? (
              <>
                <MenuItem
                  onClick={handleClose}
                  className={props.order === "asc" ? "dr-active" : null}
                >
                  <Button
                    className="px-1"
                    style={{ color: "#131722" }}
                    onClick={() => {
                      handleSorting("asc");
                    }}
                  >
                    Sort A to Z
                  </Button>
                  {props.order === "asc" ? (
                    <span className="dr-tick">
                      <TickIcon />
                    </span>
                  ) : null}
                </MenuItem>
                <MenuItem
                  onClick={handleClose}
                  className={props.order === "desc" ? "dr-active" : null}
                >
                  <Button
                    style={{ color: "#131722" }}
                    onClick={() => {
                      handleSorting("desc");
                    }}
                  >
                    Sort Z to A
                  </Button>
                  {props.order === "desc" ? (
                    <span className="dr-tick">
                      <TickIcon />
                    </span>
                  ) : null}
                </MenuItem>
              </>
            ) : (
              <>
                <MenuItem onClick={handleClose}>
                  <Button
                    onClick={() => {
                      handleSorting("asc");
                    }}
                  >
                    Sort A to Z
                  </Button>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Button
                    onClick={() => {
                      handleSorting("desc");
                    }}
                  >
                    Sort Z to A
                  </Button>
                </MenuItem>
              </>
            )}
          </MenuList>
        </ClickAwayListener>
      </Popover>
    </div>
  );
}

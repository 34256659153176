import { createSlice } from "@reduxjs/toolkit";
import { tag, users } from "../../../../actions";

export const tagSlice = createSlice({
  name: "tagSlice",
  initialState: {
    flags: [],
    tagsList: [],
    usersList: [],
  },
  reducers: {
    getFlags: (state, action) => {
      state.flags = action.payload;
    },
    getTags: (state, action) => {
      state.tagsList = action.payload;
    },
    getUsers: (state, action) => {
      state.usersList = action.payload;
    },
  },
});

const { getFlags, getTags, getUsers } = tagSlice.actions;

export const getFlagCall = () => async (dispatch, getState) => {
  // const { applicationToken, domainID, subscriptionID, userToken } =
  //   getState().pReducers.user;
  const res = await tag.getAllFlags();

  dispatch(getFlags(res?.data?.data));
  return res?.data?.data;
};

export const getTagsCall = () => async (dispatch) => {
  const tagsList = await tag.getAllTags();
  dispatch(getTags(tagsList.data.data));
  return tagsList.data.data;
};

export const getUsersCall = () => async (dispatch) => {
  const usersList = await users.getAllUsers();

  dispatch(getUsers(usersList.data.data));
  return usersList.data.data;
};

export const createTagsCall = (tags, userId) => async () => {
  try {
    const tagData = {
      regardingId: userId,
      regardingTypeId: "837",
      tagIds: tags.toString(),
      description: null,
    };

    const res = await tag.createTagging(tagData);

    return res?.data;
  } catch (error) {
    // console.log("error--->>", error);
  }
};

export const createFlagCall = (flag, userId) => async () => {
  try {
    const flagData = {
      flagId: flag,
      regardingId: userId,
      regardingTypeId: "837",
      description: "Tagging Description",
    };

    const res = await tag.createFlaging(flagData);

    return res?.data;
  } catch (error) {
    // console.log("error--->>", error);
  }
};

export const createAssigneeCall = (user, userId) => async () => {
  try {
    const userdata = {
      userId: user,
      regardingId: userId,
      regardingTypeId: "837",
      description: "Assigning Description",
    };
    const res = await tag.createAssigning(userdata);

    return res?.data;
  } catch (error) {
    // console.log("error--->>", error);
  }
};

export default tagSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import denialService from "../../../../actions/danialService";
import {
  getLegendsShortName,
  getMultiSelectFilterValueForApi,
} from "../../../../components/common/Utils/utils";
import {
  resetListingState,
  setListingState,
} from "../../filters/claimManagerFilters";

export const cptOverviewSlice = createSlice({
  name: "cptOverviewSlice",
  initialState: {
    denialsByCPT: {
      cptCodes: [],
      cptData: [],
    },

    denialsByVolume: {
      lineChartData: [],
      lineLegend: [],
    },
    denialsByPayer: {
      payerNameArr: [],
      chartArr: [],
    },

    denialByCARCClamLevl: {
      reasonCodesCL: [],
      reasonDataCL: [],
    },
  },
  reducers: {
    setCptCodeArr: (state, action) => {
      if (action.payload) {
        let data = action.payload;

        let chartData = [];
        let codes = [];
        if (data?.length) {
          if (data?.length > 10) {
            data.slice(0, 10).forEach((item) => {
              const code = item.CPTCode;
              const color = `${code}Color`;
              const colorValue = `hsl(${item.DeniedServiceLineCount}, 70%, 50%)`;
              codes.push(code);
              let obj = {};
              obj["country"] = code;
              obj[code] = item.DeniedServiceLineCount;
              obj[color] = colorValue;
              chartData.push(obj);
            });

            const uniqueCodes = [...new Set(codes)];
            state.denialsByCPT.cptCodes = uniqueCodes;
            state.denialsByCPT.cptData = chartData;
          } else {
            data.forEach((item) => {
              const code = item.CPTCode;
              const color = `${code}Color`;
              const colorValue = `hsl(${item.DeniedServiceLineCount}, 70%, 50%)`;
              codes.push(code);
              let obj = {};
              obj["country"] = code;
              obj[code] = item.DeniedServiceLineCount;
              obj[color] = colorValue;
              chartData.push(obj);
            });

            const uniqueCodes = [...new Set(codes)];
            state.denialsByCPT.cptCodes = uniqueCodes;
            state.denialsByCPT.cptData = chartData;
          }
        } else {
          state.denialsByCPT.cptCodes = [];
          state.denialsByCPT.cptData = [];
        }
      }
    },

    setClaimDenialsByVolume: (state, action) => {
      if (action.payload) {
        let data = action.payload;
        if (data?.length) {
          let chartData = [];
          let year = "";
          if (typeof data !== "undefined" && data !== "") {
            data.forEach((item) => {
              chartData.push({
                x: item.MonthName,
                y: item.DeniedClaimCount,
              });
              year = item.Year;
            });
          }
          state.denialsByVolume.lineChartData = chartData;
          state.denialsByVolume.lineLegend = `Year - ${year}`;
        } else {
          state.denialsByVolume.lineChartData = [];
          state.denialsByVolume.lineLegend = [];
        }
      }
    },

    setClaimDenialsByPayer: (state, action) => {
      if (action?.payload) {
        let data = action.payload;
        if (data?.length) {
          //=============================================
          let month_data = [
            { MonthName: "January" },
            { MonthName: "February" },
            { MonthName: "March" },
            { MonthName: "April" },
            { MonthName: "May" },
            { MonthName: "June" },
            { MonthName: "July" },
            { MonthName: "August" },
            { MonthName: "September" },
            { MonthName: "October" },
            { MonthName: "November" },
            { MonthName: "December" },
          ];
          let codes = [];
          if (typeof data !== "undefined" && data !== "") {
            data.forEach((item) => {
              const code = getLegendsShortName(item.PayerName);
              codes.push(code);
            });
          }
          let uniquePayers = [...new Set(codes)];
          for (let i = 0; i < month_data.length; i++) {
            for (let j = 0; j < data.length; j++) {
              if (month_data[i].MonthName === data[j].MonthName) {
                let key = getLegendsShortName(data[j].PayerName);
                month_data[i][key] = data[j].DeniedAmount;
              }
            }
          }
          let tempData = month_data.filter(
            (month) => Object.keys(month).length > 1
          );
          state.denialsByPayer.payerNameArr = uniquePayers;
          state.denialsByPayer.chartArr = tempData;
          //============================================
        } else {
          state.denialsByPayer.payerNameArr = [];
          state.denialsByPayer.chartArr = [];
        }
      }
    },

    setDenialByCARCClamLevl: (state, action) => {
      if (action.payload) {
        let data = action.payload;
        let chartData = [];
        let codes = [];
        if (data?.length) {
          data.slice(0, 100).forEach((item) => {
            const code = item.ReasonCode;
            const color = `${code}Color`;
            const colorValue = `hsl(${item.DeniedClaimCount}, 70%, 50%)`;
            codes.push(code);
            let obj = {};
            obj["country"] = code;
            obj[code] = item.DeniedClaimCount;
            obj[color] = colorValue;
            chartData.push(obj);
          });
        }

        const uniqueCodes = [...new Set(codes)];

        state.denialByCARCClamLevl.reasonCodesCL = uniqueCodes;
        state.denialByCARCClamLevl.reasonDataCL = chartData;
      } else {
        state.denialByCARCClamLevl.reasonCodesCL = [];
        state.denialByCARCClamLevl.reasonDataCL = [];
      }
    },
  },
});

export const {
  setCptCodeArr,
  setClaimDenialsByVolume,
  setClaimDenialsByPayer,
  setDenialByCARCClamLevl,
  settingAllcptCodesList,
} = cptOverviewSlice.actions;

export const denialCptCodeListCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialOverviewFilter",
          ignore: ["payers"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.denialOverviewFilter;

    try {
      dispatch(
        setListingState({
          listing: "denialOverviewFilter",
          value: {
            loadingMore: "data-loading",
            loadingDenialByCpt: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const res = await denialService.getCptData({
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),
        ...(sliceState.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState.to !== "" && {
          to: sliceState.to,
        }),
      });

      ////// API CALL END //////////////

      dispatch(
        setListingState({
          listing: "denialOverviewFilter",
          value: {
            initialLoading: false,
            loadingMore: "",
            loadingDenialByCpt: "",
            hasMore: false,
          },
        })
      );
      if (res?.data?.success) {
        dispatch(setCptCodeArr(res?.data?.data));
      } else {
        dispatch(setCptCodeArr([]));
      }

      return res?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "denialOverviewFilter",
          value: {
            initialLoading: false,
            loadingMore: "",
            loadingDenialByCpt: "",
            hasMore: false,
          },
        })
      );
      dispatch(setCptCodeArr([]));
      return false;
    }
  };

export const claimDenialsByVolumeCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialOverviewFilter",
          ignore: ["payers"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.denialOverviewFilter;

    try {
      dispatch(
        setListingState({
          listing: "denialOverviewFilter",
          value: {
            loadingMore: "data-loading",
            loadingDenialsVolume: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const res = await denialService.getClaimDenialsByVolume({
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),
        ...(sliceState.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState.to !== "" && {
          to: sliceState.to,
        }),
      });

      ////// API CALL END //////////////

      dispatch(
        setListingState({
          listing: "denialOverviewFilter",
          value: {
            initialLoading: false,
            loadingMore: "",
            loadingDenialsVolume: "",
            hasMore: false,
          },
        })
      );

      if (res?.data?.success) {
        dispatch(setClaimDenialsByVolume(res?.data?.data));
      } else {
        dispatch(setClaimDenialsByVolume([]));
      }
      return res?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "denialOverviewFilter",
          value: {
            initialLoading: false,
            loadingDenialsVolume: "",
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      dispatch(setClaimDenialsByVolume([]));
      return false;
    }
  };

export const claimDenialsByPayerCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialOverviewFilter",
          ignore: ["payers"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.denialOverviewFilter;

    try {
      dispatch(
        setListingState({
          listing: "denialOverviewFilter",
          value: {
            loadingMore: "data-loading",
            loadingDenialsPayer: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const res = await denialService.getClaimDenialsByPayer({
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),
        ...(sliceState.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState.to !== "" && {
          to: sliceState.to,
        }),
      });

      ////// API CALL END //////////////

      dispatch(
        setListingState({
          listing: "denialOverviewFilter",
          value: {
            initialLoading: false,
            loadingMore: "",
            loadingDenialsPayer: "",
            hasMore: false,
          },
        })
      );

      if (res?.data?.success) {
        dispatch(setClaimDenialsByPayer(res?.data?.data));
      } else {
        dispatch(setClaimDenialsByPayer([]));
      }

      return res?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "denialOverviewFilter",
          value: {
            initialLoading: false,
            loadingDenialsPayer: "",
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      dispatch(setClaimDenialsByPayer([]));
      return false;
    }
  };

export const DenialByClaimAdjResnCodeCLCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialOverviewFilter",
          ignore: ["payers"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.denialOverviewFilter;

    try {
      dispatch(
        setListingState({
          listing: "denialOverviewFilter",
          value: {
            // loadingMore: "data-loading",
            loadingClaimByCL: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const res = await denialService.getDenialByClaimAdjResnCodeCL({
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),
        ...(sliceState.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState.to !== "" && {
          to: sliceState.to,
        }),
      });

      ////// API CALL END //////////////

      dispatch(
        setListingState({
          listing: "denialOverviewFilter",
          value: {
            initialLoading: false,
            loadingMore: "",
            loadingClaimByCL: "",
            hasMore: false,
          },
        })
      );

      if (res?.data?.success) {
        dispatch(setDenialByCARCClamLevl(res?.data?.data));
      } else {
        dispatch(setDenialByCARCClamLevl([]));
      }

      return res?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "denialOverviewFilter",
          value: {
            initialLoading: false,
            loadingClaimByCL: "",
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      dispatch(setDenialByCARCClamLevl([]));
      return false;
    }
  };

export const dispatchAllOverViewFunctions =
  ({ clear = false } = {}) =>
  async (dispatch) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialOverviewFilter",
          ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
      dispatch(
        setListingState({
          listing: "denialOverviewFilter",
          value: {
            payers: [],
          },
        })
      );
    }
    try {
      ///// dispatch  //////
      await dispatch(DenialByClaimAdjResnCodeCLCall());
      await dispatch(claimDenialsByPayerCall());
      await dispatch(claimDenialsByVolumeCall());
      await dispatch(denialCptCodeListCall());

      return 0;
      ////// dispatch  END //////////////
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };

// /========================================

export const dispatchAllOverViewFunctionsNew =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialOverviewFilter",
          ignore: ["payers"],
        })
      );
    }
    try {
      ///// API CALL //////
      if (clear) {
        await dispatch(DenialByClaimAdjResnCodeCLCall());
        await dispatch(claimDenialsByPayerCall());
        await dispatch(claimDenialsByVolumeCall());
        await dispatch(denialCptCodeListCall());
      } else {
        await dispatch(DenialByClaimAdjResnCodeCLCall({ clear: true }));
        await dispatch(claimDenialsByPayerCall({ clear: true }));
        await dispatch(claimDenialsByVolumeCall({ clear: true }));
        await dispatch(denialCptCodeListCall({ clear: true }));
      }

      ////// API CALL END //////////////
      return 0;
    } catch (e) {
      // console.log("e--->>", e);
      return false;
    }
  };

export default cptOverviewSlice.reducer;

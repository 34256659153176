import { createSlice } from "@reduxjs/toolkit";

import claims from "../../../actions/claims";
import denialSerive from "../../../actions/danialService";

import { descendingArray } from "./../../../components/common/Utils/utils";
import { getLegendsShortName } from "../../../components/common/Utils/utils";

import {
  setListingState,
  resetListingState,
} from "../filters/claimManagerFilters";

export const denialByCptSlice = createSlice({
  name: "denialByCptSlice",
  initialState: {
    denialByCptData: {
      codesList: [],
      revenueList: [],
      volumeList: [],
    },
    denialByPayerData: {
      amountList: [],
      amountPayerList: [],
      volumeListArr: [],
      volumePayerList: [],
    },
  },
  reducers: {
    denialByCptGraphData_Action: (state, action) => {
      var data = action.payload;

      var codes = [];
      var amountChart = [];
      var volumeChart = [];

      if (data?.length) {
        // below code for ChargeAmount

        var newArr_ChargeAmount = descendingArray(data, "ChargeAmount");

        (newArr_ChargeAmount?.length > 5
          ? newArr_ChargeAmount.slice(0, 5)
          : newArr_ChargeAmount
        ).forEach((item) => {
          const code = item?.JCODE;
          // MAKE CODES ARRAY
          codes.push(code);
          // MAKE AMOUNT ARRAYS
          const amountColor = `${code}Color`;
          const amountColorValue = `hsl(${item?.ChargeAmount}, 70%, 50%)`;
          let newobj = {};
          newobj["country"] = code;
          newobj[code] = item?.ChargeAmount;
          newobj[amountColor] = amountColorValue;
          amountChart.push(newobj);
        });

        // below code for NoOfDenied
        var newArr_NoOfDenied = descendingArray(data, "NoOfDenied");

        (newArr_NoOfDenied?.length > 5
          ? newArr_NoOfDenied.slice(0, 5)
          : newArr_NoOfDenied
        ).forEach((item) => {
          const code = item?.JCODE;
          // MAKE CODES ARRAY
          codes.push(code);
          // MAKE AMOUNT ARRAYS
          const amountColor = `${code}Color`;
          const amountColorValue = `hsl(${item?.ChargeAmount}, 70%, 50%)`;
          let newobj2 = {};
          newobj2["country"] = code;
          newobj2[code] = item?.NoOfDenied;
          newobj2[amountColor] = amountColorValue;
          volumeChart.push(newobj2);
        });

        const uniquCodes = [...new Set(codes)];

        state.denialByCptData.codesList = uniquCodes;
        state.denialByCptData.revenueList = amountChart;
        state.denialByCptData.volumeList = volumeChart;
      } else {
        state.denialByCptData.codesList = [];
        state.denialByCptData.revenueList = [];
        state.denialByCptData.volumeList = [];
      }
    },

    denialByPayerAmountGraphData_Action: (state, action) => {
      let data = action.payload;

      if (data?.length) {
        let list = [];
        let payers = [];

        var newArr_DenialsAmount = descendingArray(data, "DenialsAmount");

        (newArr_DenialsAmount?.length > 5
          ? newArr_DenialsAmount.slice(0, 5)
          : newArr_DenialsAmount
        ).forEach((item, index) => {
          const payerName = item.Name;
          const amount = Math.round(item.DenialsAmount);
          const newPayerName = getLegendsShortName(payerName);
          payers.push(newPayerName);

          const colorName = `${newPayerName}Color`;
          const colorValue = `hsl(${amount}, 70%, 50%)`;
          let obj = {};
          obj["country"] = newPayerName;
          obj[newPayerName] = amount;
          obj[colorName] = colorValue;
          obj["payerName"] = payerName;
          list.push(obj);
        });
        const uniquePayers = [...new Set(payers)];

        state.denialByPayerData.amountList = list;
        state.denialByPayerData.amountPayerList = uniquePayers;
      } else {
        state.denialByPayerData.amountList = [];
        state.denialByPayerData.amountPayerList = [];
      }
    },

    denialByPayerVolumeGraphData_Action: (state, action) => {
      let data = action.payload;

      if (data?.length) {
        let list = [];
        let payers = [];

        var newArr_DenialsVolume = descendingArray(data, "DenialsVolume");

        (newArr_DenialsVolume?.length > 5
          ? newArr_DenialsVolume.slice(0, 5)
          : newArr_DenialsVolume
        ).forEach((item) => {
          const payerName = item.Name;
          const amount = item.DenialsVolume;
          const colorName = `${payerName}Color`;
          const colorValue = `hsl(${amount}, 70%, 50%)`;
          const newPayerName = getLegendsShortName(payerName);
          payers.push(newPayerName);
          let obj = {};
          obj["country"] = newPayerName;
          obj[newPayerName] = amount;
          obj[colorName] = colorValue;
          obj["payerName"] = payerName;
          list.push(obj);
        });
        const uniquePayers = [...new Set(payers)];

        state.denialByPayerData.volumeListArr = list;
        state.denialByPayerData.volumePayerList = uniquePayers;
      } else {
        state.denialByPayerData.volumeListArr = [];
        state.denialByPayerData.volumePayerList = [];
      }
    },
  },
});

export const {
  denialByCptGraphData_Action,
  denialByPayerAmountGraphData_Action,
  denialByPayerVolumeGraphData_Action,
} = denialByCptSlice.actions;

export const denialsByCptGraphDataCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialDetailsFilter",
          ignore: ["cptCodes", "payers", "reasonCode", "search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.denialDetailsFilter;
    let Start = 0;
    let Limit = 10;
    let noOfRecord = 10;

    try {
      dispatch(
        setListingState({
          listing: "denialDetailsFilter",
          value: {
            denialsByCptLoading: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const res = await claims.getRejectedCptCodes({
        noOfRecord,
        Start,
        Limit,
        ...(sliceState?.from !== "" && {
          From: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          To: sliceState.to,
        }),
      });

      ////// API CALL END //////////////

      if (res?.data?.success) {
        dispatch(
          setListingState({
            listing: "denialDetailsFilter",
            value: {
              denialsByCptLoading: "",
            },
          })
        );

        dispatch(denialByCptGraphData_Action(res?.data?.data?.output));
      } else {
        dispatch(
          setListingState({
            listing: "denialDetailsFilter",
            value: {
              denialsByCptLoading: "",
            },
          })
        );

        dispatch(denialByCptGraphData_Action([]));
      }

      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "denialDetailsFilter",
          value: {
            initialLoading: false,
            denialsByCptLoading: "",
            hasMore: false,
          },
        })
      );
      return false;
    }
  };

export const denialsByPayerGraphDataByRevenueCall_ForChart =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialDetailsFilter",
          ignore: [],
        })
      );
    }

    let sliceState = getState().npReducers.filters.denialDetailsFilter;

    try {
      dispatch(
        setListingState({
          listing: "denialDetailsFilter",
          value: {
            denialsByPayerLoading: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const res = await denialSerive.getDenialsAmountByPayer({
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
      });

      ////// API CALL END //////////////

      if (res?.data?.success) {
        dispatch(
          setListingState({
            listing: "denialDetailsFilter",
            value: {
              denialsByPayerLoading: "",
            },
          })
        );
        dispatch(denialByPayerAmountGraphData_Action(res?.data?.data));
      } else {
        dispatch(
          setListingState({
            listing: "denialDetailsFilter",
            value: {
              denialsByPayerLoading: "",
            },
          })
        );
        dispatch(denialByPayerAmountGraphData_Action([]));
      }

      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "denialDetailsFilter",
          value: {
            initialLoading: false,
            denialsByPayerLoading: "",
            hasMore: false,
          },
        })
      );
      return false;
    }
  };

export const denialsByPayerGraphDataByVolumeCall_ForChart =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialDetailsFilter",
          ignore: [],
        })
      );
    }

    let sliceState = getState().npReducers.filters.denialDetailsFilter;

    try {
      dispatch(
        setListingState({
          listing: "denialDetailsFilter",
          value: {
            denialsByPayerLoading: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const res = await denialSerive.getDenialsVolumeByPayer({
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
      });

      ////// API CALL END //////////////

      if (res?.data?.data) {
        dispatch(
          setListingState({
            listing: "denialDetailsFilter",
            value: {
              denialsByPayerLoading: "",
            },
          })
        );
        dispatch(denialByPayerVolumeGraphData_Action(res?.data?.data));
      } else {
        dispatch(
          setListingState({
            listing: "denialDetailsFilter",
            value: {
              denialsByPayerLoading: "",
            },
          })
        );
        dispatch(denialByPayerVolumeGraphData_Action([]));
      }

      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "denialDetailsFilter",
          value: {
            initialLoading: false,
            denialsByPayerLoading: "",
            hasMore: false,
          },
        })
      );
      return false;
    }
  };

export default denialByCptSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import queryBuilder from "../../../actions/queryBuilder";
import { toast } from "react-toastify";
import { setListingState } from "../filters/claimManagerFilters";
import { getUserFriendlyColumnNamesForQuerybuilder } from "../../../components/common/Utils/utils";

export const queryGeneratorSlice = createSlice({
  name: "queryGenerator",
  initialState: {
    selectedQueryType: "",
    runQueryType: "",
    queryResult: [],
    prevQuery: [],

    queryBuilderFields_837: {},
    queryBuilderColumns_837: [],

    queryBuilderFields_835: {},
    queryBuilderColumns_835: [],

    queryBuilderType: [
      // {
      //   QueryBuilderTypeid: "6d5e8a72-9db3-40cf-b78b-83c8ad0b0ab8",
      //   Name: "Claim Manager - 837",
      //   ViewName: "View_ClaimManager",
      // },
      // {
      //   QueryBuilderTypeid: "a2c26a04-15ab-4e22-a16a-bd732b1eb0f8",
      //   Name: "Claim Payment - 835",
      //   ViewName: "View_ClaimPayment",
      // },
    ],
  },

  reducers: {
    setQueryBuilderType: (state, action) => {
      let { payload } = action;
      state.queryBuilderType = payload;
    },

    setQueryType: (state, action) => {
      let { payload } = action;
      state.selectedQueryType = payload;
      state.queryResult = [];
    },

    loadingPrevQuery: (state, action) => {
      let { payload } = action;
      state.prevQuery = payload;
    },

    runningQuery: (state, action) => {
      let { payload } = action;
      state.queryResult = payload.output;
    },

    setQueryBuilderFields_837: (state, action) => {
      let { payload } = action;
      let originalState = {};
      payload.forEach((item) => {
        let label = item.COLUMN_NAME;
        let type =
          item.DATA_TYPE === "uniqueidentifier"
            ? "text"
            : item.DATA_TYPE === "nvarchar"
            ? "text"
            : item.DATA_TYPE === "money"
            ? "number"
            : item.DATA_TYPE === "int"
            ? "number"
            : item.DATA_TYPE === "date"
            ? "date"
            : "text";
        let valueSources = ["value"];

        let obj = {};

        if (item.DATA_TYPE === "date") {
          obj = {
            label: label,
            type: type,
            valueSources: valueSources,
            fieldSettings: {
              dateFormat: "MM/DD/YYYY",
            },
          };
        } else {
          obj = {
            label: label,
            type: type,
            valueSources: valueSources,
            // fieldSettings: {
            //   dateFormat: "MM/DD/YYYY",
            // },
          };
        }

        originalState[item.COLUMN_NAME] = obj;
      });

      let columnName = [];
      payload.forEach((item) => {
        // var name = getUserFriendlyColumnNamesForQuerybuilder(item?.COLUMN_NAME);

        columnName.push({
          label: item?.COLUMN_NAME,
          value: item?.COLUMN_NAME,
        });
      });

      state.queryBuilderFields_837 = originalState;
      state.queryBuilderColumns_837 = columnName;
    },

    setQueryBuilderFields_835: (state, action) => {
      let { payload } = action;
      let originalState = {};
      payload.forEach((item) => {
        let label = item.COLUMN_NAME;
        let type =
          item.DATA_TYPE === "uniqueidentifier"
            ? "text"
            : item.DATA_TYPE === "nvarchar"
            ? "text"
            : item.DATA_TYPE === "money"
            ? "number"
            : item.DATA_TYPE === "int"
            ? "number"
            : item.DATA_TYPE === "date"
            ? "date"
            : "text";
        let valueSources = ["value"];

        let obj = {};

        if (item.DATA_TYPE === "date") {
          obj = {
            label: label,
            type: type,
            valueSources: valueSources,
            fieldSettings: {
              dateFormat: "MM/DD/YYYY",
            },
          };
        } else {
          obj = {
            label: label,
            type: type,
            valueSources: valueSources,
            // fieldSettings: {
            //   dateFormat: "MM/DD/YYYY",
            // },
          };
        }

        originalState[item.COLUMN_NAME] = obj;
      });

      let columnName = [];
      payload.forEach((item) => {
        // let as = getUserFriendlyColumnNamesForQuerybuilder(item?.COLUMN_NAME);
        // console.log("as--->>", as);
        columnName.push({
          label: item?.COLUMN_NAME,
          // label: as,
          value: item?.COLUMN_NAME,
        });
      });

      state.queryBuilderFields_835 = originalState;
      state.queryBuilderColumns_835 = columnName;
    },
  },
});

export const {
  loadingPrevQuery,
  runningQuery,
  setLoadingMore,
  setQueryType,
  setQueryBuilderType,
  setQueryBuilderFields_837,
  setQueryBuilderFields_835,
} = queryGeneratorSlice.actions;

//=====================================

// get quries type, id's and there title for 837
export const gettingQueryBuilderFields_837 =
  () => async (dispatch, getState) => {
    try {
      ///// API CALL //////
      const res = await queryBuilder.getQueryBuilderTypeColumns(
        "View_ClaimManager"
      );
      ////// API CALL END //////////////
      dispatch(setQueryBuilderFields_837(res.data.data));
      return res.data.data;
    } catch (e) {
      // console.log("error--->>", e);
      return false;
    } finally {
      // console.log("error--->>", e);
    }
  };

// get quries type, id's and there title for 835
export const gettingQueryBuilderFields_835 =
  () => async (dispatch, getState) => {
    try {
      ///// API CALL //////
      const res = await queryBuilder.getQueryBuilderTypeColumns(
        "View_ClaimPayment"
      );
      ////// API CALL END //////////////
      dispatch(setQueryBuilderFields_835(res.data.data));
      return res.data.data;
    } catch (e) {
      // console.log("error--->>", e);
      return false;
    } finally {
      // console.log("error--->>", e);
    }
  };

export const queryBuilderTypeCall = () => async (dispatch, getState) => {
  try {
    dispatch(
      setListingState({
        listing: "queryGeneratorFilter",
        value: {
          loadingMore: "data-loading",
        },
      })
    );

    ///// API CALL //////
    const res = await queryBuilder.getQueryBuilderType();
    ////// API CALL END //////////////
    dispatch(setQueryBuilderType(res.data.data));
    return res.data.data;
  } catch (e) {
    // console.log("error--->>", e);
    return false;
  } finally {
    dispatch(
      setListingState({
        listing: "queryGeneratorFilter",
        value: {
          loadingMore: "",
        },
      })
    );
  }
};

export const runQueryRequestCall = (data) => async (dispatch, getState) => {
  // getting state here

  try {
    dispatch(
      setListingState({
        listing: "queryGeneratorFilter",
        value: {
          loadingMore: "data-loading",
        },
      })
    );
    ///// API CALL //////
    const res = await queryBuilder.runQuery(data);
    ////// API CALL END //////////////

    if (res.data.success) {
      if (!res.data.data.output.length) {
        toast.error("No data found for this query", {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(runningQuery([]));
      } else {
        toast.success(`Query ${res?.data?.message}`, {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(runningQuery(res.data.data));
        dispatch(
          setListingState({
            listing: "queryGeneratorFilter",
            value: {
              totalRecord_DB: res.data.data.count,
            },
          })
        );
      }
    } else {
      toast.error(`${res?.data?.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(runningQuery([]));
    }

    return res.data.data;
  } catch (e) {
    // console.log("e--->>", e);

    toast.error(e?.response?.data.message, {
      position: toast.POSITION.TOP_CENTER,
    });
    return false;
  } finally {
    dispatch(
      setListingState({
        listing: "queryGeneratorFilter",
        value: {
          loadingMore: "",
          runQueryFuncType: "",
        },
      })
    );
  }
};

export const saveQueryRequestCall =
  (data, setOpenSaveQuery, setSaveQueryData) => async (dispatch, getState) => {
    try {
      dispatch(
        setListingState({
          listing: "queryGeneratorFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );
      ///// API CALL //////
      const res = await queryBuilder.saveQuery(data);
      ////// API CALL END //////////////

      if (res.data.success) {
        toast.success(`${res?.data?.message}`, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(`${res?.data?.message}`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      return false;
    } catch (e) {
      toast.error(e?.response?.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    } finally {
      dispatch(
        setListingState({
          listing: "queryGeneratorFilter",
          value: {
            loadingMore: "",
          },
        })
      );
      setOpenSaveQuery(false);
      setSaveQueryData({
        QueryBuilderId: "",
        QueryBuilderTypeId: "",
        Name: "",
        WhereCondition: "",
        WhereJsonTree: "",
        UserId: "",
      });
    }
  };

export const loadPrevQueryRequestCall =
  (setOpenPrevList, id) => async (dispatch, getState) => {
    try {
      dispatch(
        setListingState({
          listing: "queryGeneratorFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      ///// API CALL //////
      const res = await queryBuilder.getPrevQuery(id);
      ////// API CALL END //////////////

      if (res.data.success) {
        toast.success(`${res?.data?.message}`, {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(loadingPrevQuery(res.data.data));
      } else {
        toast.error(`${res?.data?.message}`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      return res.data.data;
    } catch (e) {
      toast.error(e?.response?.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    } finally {
      dispatch(
        setListingState({
          listing: "queryGeneratorFilter",
          value: {
            loadingMore: "",
          },
        })
      );

      setOpenPrevList(true);
    }
  };

export default queryGeneratorSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

import UserService from "./../../../actions//user";
import { toast } from "react-toastify";

import {
  setListingState,
  resetListingState,
} from "../filters/claimManagerFilters";
import { getCommaSeperated_IntIds_ForMultiselect_Dropdown } from "../../../components/common/Utils/utils";
import { setAdvStngServiceLineExcepList } from "../UserReducer";
import genericAxiosCall from "./../../../AxiosConfig/genericAxiosCall";
import { serverRoutes } from "../../../constants/serverRoutes";

export const AdvanceSettingSlice = createSlice({
  name: "AdvanceSettingSlice",
  initialState: {
    denialConfiOptions: [],
    denialConfigVal: [],
    writeOffDaysVal: null,

    serviceLineDenialConfiOptions: [],
    serviceLineDenialConfigVal: [],
  },
  reducers: {
    setAdvSettingData_Action: (state, action) => {
      let data = action.payload;

      // handle write off claims value
      if (data && data?.savedCode && data?.savedCode?.WriteOffDaysFrom) {
        state.writeOffDaysVal =
          data?.savedCode?.WriteOffDaysFrom === 30
            ? "30 Days"
            : data?.savedCode?.WriteOffDaysFrom === 60
            ? "60 Days"
            : data?.savedCode?.WriteOffDaysFrom === 90
            ? "90 Days"
            : data?.savedCode?.WriteOffDaysFrom === 120
            ? "120 Days"
            : data?.savedCode?.WriteOffDaysFrom === 180
            ? "180 Days"
            : data?.savedCode?.WriteOffDaysFrom === 365
            ? "365 Days"
            : null;
      } else {
        state.writeOffDaysVal = null;
      }

      // for denial status code data manipulation start here ----------------------

      if (
        data &&
        data?.savedCode &&
        data?.savedCode?.ClaimStatusCodeIds &&
        data?.savedCode?.ClaimStatusCodeIds?.length
      ) {
        let commaSeperated_ClaimStatusCode =
          getCommaSeperated_IntIds_ForMultiselect_Dropdown(
            data?.savedCode?.ClaimStatusCodeIds
          );
        state.denialConfigVal = commaSeperated_ClaimStatusCode;
      } else {
        state.denialConfigVal = [];
      }

      if (data && data?.claimStatusCode && data?.claimStatusCode?.length) {
        let newArr = [];
        data?.claimStatusCode.forEach((item) => {
          newArr.push({
            label: `${item.claimStatusCodeId} - ${item.description}`,
            value: item?.claimStatusCodeId,
          });
        });
        state.denialConfiOptions = newArr;
      } else {
        state.denialConfiOptions = [];
      }

      // for denial status code data manipulation end here ----------------------

      if (
        data &&
        data?.savedCode &&
        data?.savedCode?.ServiceDenialReasonCodeIds &&
        data?.savedCode?.ServiceDenialReasonCodeIds?.length
      ) {
        state.serviceLineDenialConfigVal =
          data?.savedCode?.ServiceDenialReasonCodeIds.split(",");
      } else {
        state.serviceLineDenialConfigVal = [];
      }

      if (
        data &&
        data?.serviceDenialReasonCode &&
        data?.serviceDenialReasonCode?.length
      ) {
        let newArr = [];
        data?.serviceDenialReasonCode.forEach((item) => {
          newArr.push({
            label: `${item.code} - ${item.description}`,
            value: item?.code,
          });
        });
        state.serviceLineDenialConfiOptions = newArr;
      } else {
        state.serviceLineDenialConfiOptions = [];
      }
    },
  },
});

export const { setAdvSettingData_Action } = AdvanceSettingSlice.actions;

// save Denials Status Code Config Settings
export const saveDenialStausCodeConfig =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "advanceSettingFilter",
          ignore: ["asdfasdf,asdfasdf,adf,adfasdf,adsfasdf,asfd"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.advanceSettingFilter;
    let ClaimStatusCodeIds = sliceState.ClaimStatusCodeIds;
    let ServiceDenialReasonCodeIds = sliceState.ServiceDenialReasonCodeIds;
    let WriteOffDaysFrom = sliceState.selWriteOffDays;

    try {
      dispatch(
        setListingState({
          listing: "advanceSettingFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const res = await UserService.saveDenialsStatusCodeConfig({
        ClaimStatusCodeIds,
        ServiceDenialReasonCodeIds,
        WriteOffDaysFrom,
      });

      ////// API CALL END //////////////

      if (res.data.success) {
        toast.success(`Denial Setting has been saved successfully`, {
          position: toast.POSITION.TOP_CENTER,
        });

        dispatch(setAdvStngServiceLineExcepList(ServiceDenialReasonCodeIds));
        dispatch(gettingAdvSettingData_Call());
      } else {
        toast.error(`${res?.data?.message}`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      dispatch(
        setListingState({
          listing: "advanceSettingFilter",
          value: {
            loadingMore: "",
          },
        })
      );

      return res;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "advanceSettingFilter",
          value: {
            // initialLoading: false,
            loadingMore: "",
            // hasMore: false,
          },
        })
      );
      return false;
    }
  };

// get advance setting initial data
export const gettingAdvSettingData_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.advanceSettingFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      // console.log("gettingAdvSettingData_Call--->>");

      dispatch(
        setListingState({
          listing: "advanceSettingFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      const res = await genericAxiosCall(
        `${serverRoutes?.GET_SERVICE_LINE_EXECPTIONS}`,
        "get",
        "",
        ""
      );
      if (res?.data?.success) {
        dispatch(setAdvSettingData_Action(res?.data?.data));

        const { savedCode } = res?.data?.data;
        const { ServiceDenialReasonCodeIds } = savedCode;

        if (
          ServiceDenialReasonCodeIds &&
          ServiceDenialReasonCodeIds.length > 0
        ) {
          dispatch(setAdvStngServiceLineExcepList(ServiceDenialReasonCodeIds));

          let data = ServiceDenialReasonCodeIds?.split(",")?.map((item) => {
            return {
              label: item.toString(),
              value: item.toString(),
            };
          });

          if (data?.length) {
            dispatch(
              setListingState({
                listing: "mainOrgDashboardFilter",
                value: {
                  smartFilterArr: data,
                  pageNumber: 1,
                },
              })
            );
          }
        } else {
          // do nothing
          dispatch(setAdvStngServiceLineExcepList(""));
        }
      } else {
        console.log("res_else--->>", res);
      }
    } catch (e) {
      console.log("e--->>", e);
    } finally {
      dispatch(
        setListingState({
          listing: "advanceSettingFilter",
          value: {
            loadingMore: "",
          },
        })
      );
    }
  };

export default AdvanceSettingSlice.reducer;

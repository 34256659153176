import React from "react";
import PropTypes from "prop-types";
import { Backdrop, Button, Modal } from "@mui/material";
// import Messages from "../../../components/Messages";

import { useSpring, animated } from "react-spring"; // web.cjs is required for IE 11 support

import TrashIcon from "../../../assets/svgs/TrashIcon";
import CloseIcon from "../../../assets/svgs/CloseIcon";
// import "./styles.scss";
// import { RootContext } from "../../../context/RootContext";

// const useStyles = makeStyles((theme) => ({
//   modal: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
// }));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

const DeleteModal = ({
  modalData,
  handleConfirm,
  open,
  handleClose,
  size,
  action,
  loadingClass,
}) => {
  // const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className="newModalOverlayDelete"
        open={open}
      >
        <div className="newModal">
          <div
            className={size ? "custom-modal p-0 " + size : "custom-modal p-0 "}
            style={{ borderRadius: "10px" }}
          >
            <div className="newModalHeader newModalHeader-red">
              <div className="newModalHeaderTitle">
                <h3
                  className="headerTitle"
                  id="simple-modal-title"
                  style={{ color: "#131722" }}
                >
                  {modalData.title}
                </h3>
                <div
                  className="headerCloseIcon delete-close-icon"
                  onClick={handleClose}
                >
                  <CloseIcon />
                </div>
              </div>
            </div>
            <div
              className="newModalBody"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <div>
                <span className="trash-icon flex justify-center mt-2 mb-2">
                  <TrashIcon />
                </span>
                <p>{modalData?.description}</p>
              </div>
              {action && (
                <div className="flex flex-row-reverse gap-3 mt-2">
                  <Button
                    variant="contained"
                    type="submit"
                    onClick={handleConfirm}
                    className={`delete-button ${loadingClass ? "loading" : ""}`}
                  >
                    Yes, Delete it!
                  </Button>
                  <Button
                    variant="contained"
                    className="cancel-button"
                    type="submit"
                    disabled={loadingClass}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteModal;

import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { serverRoutes } from "./../../../constants/serverRoutes";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";
import {
  resetListingState,
  setListingState,
} from "../filters/claimManagerFilters";
import { commonPagination } from "../../../components/common/Utils/utils";
import { userData } from "../UserReducer";

export const ApplicationUsersSlice = createSlice({
  name: "ApplicationUsersSlice",
  initialState: {
    applicationUsersList: {},
  },
  reducers: {
    setApplicationUsers_Data: (state, action) => {
      let data = action.payload;

      if (data && data?.users && data?.users?.length) {
        // state.applicationUsersList = data;
        return { ...state, applicationUsersList: data };
      } else {
        // state.applicationUsersList = [];
        return { ...state, applicationUsersList: {} };
      }
    },

    addNewUserToUserArr_Data: (state, action) => {
      let data = action.payload;

      return {
        ...state,
        applicationUsersList: [...state.applicationUsersList, data],
      };
    },
    updateExistingUserToUserArr_Data: (state, action) => {
      let data = action.payload;

      let index = state.applicationUsersList.findIndex(
        (item) => item.userId === data.userId
      );

      return {
        ...state,
        applicationUsersList: [...(state.applicationUsersList[index] = data)],
      };
    },
    deleteUserFromUserArr_Data: (state, action) => {
      let userId = action.payload;

      return {
        ...state,
        applicationUsersList: state.applicationUsersList.users?.filter(
          (item) => item.userId !== userId
        ),
      };
    },
  },
});

export const {
  setApplicationUsers_Data,
  addNewUserToUserArr_Data,
  updateExistingUserToUserArr_Data,
  deleteUserFromUserArr_Data,
} = ApplicationUsersSlice.actions;

// getting all application users list
export const getApplicationUsers_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "applicationUsersFilter",
          ignore: ["claimStatus"],
        })
      );
    }
    dispatch(
      setListingState({
        listing: "applicationUsersFilter",
        value: {
          selectedItem_id: "",
        },
      })
    );
    let sliceStateUser = getState().pReducers.user;

    let sliceState = getState().npReducers.filters.applicationUsersFilter;
    let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;

    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable
        ?.applicationUsersFilterNoOfRecordInTable;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState?.search && { search: sliceState?.search }),

        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        timestamp: new Date().getTime(),
      };
      let params = {
        start,
        limit,
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState?.search && { search: sliceState?.search }),

        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        timestamp: new Date().getTime(),
      };
      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)

      await commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setApplicationUsers_Data,
        "applicationUsersFilter",
        "GET_USER",
        null,
        "",
        "",
        true, // isCustomErrMsg
        false // isError (error or info)
      );
    } catch (e) {
      // console.log("e--->>", e);
    } finally {
      // dispatch(
      //   setListingState({
      //     listing: "applicationUsersFilter",
      //     value: {
      //       // search: "",
      //       // pageNumber: 1,
      //     },
      //   })
      // );
    }
  };

export const addNewApplicationUser_Call = (body) => async (dispatch) => {
  try {
    const res = await genericAxiosCall(serverRoutes.ADD_NEW_USER, "post", body);

    return res;
  } catch (error) {
    toast.error(
      error?.response?.data?.message
        ? error?.response?.data?.message
        : "Something went wrong",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
    // console.log("catch_e--->>", e);
  }
};

export const getUserTypes = async () => {
  try {
    const res = await genericAxiosCall(serverRoutes.GET_USER_TYPES, "get", "");
    // if (res?.data?.success) {
    //   dispatch(addNewUserToUserArr_Data(body));
    // }
    return res;
  } catch (e) {
    // console.log("catch_e--->>", e);
  }
};

export const updateApplicationUser_Call =
  (body) => async (dispatch, getState) => {
    let user = getState().pReducers.user;

    try {
      const res = await genericAxiosCall(
        serverRoutes.ADD_NEW_USER,
        "put",
        body
      );

      let finalUserModifiedObj = {};

      if (user?.loginUserData?.userId === res?.data?.data?.userId) {
        let roleIdsOnly = res?.data?.data?.roles?.map((item) => item.roleId);

        finalUserModifiedObj = {
          ...user,
          loginUserData: {
            ...user?.loginUserData,
            roles: res?.data?.data?.roles,
          },
          userRoles: roleIdsOnly,
          permission: roleIdsOnly,
        };

        dispatch(userData(finalUserModifiedObj));
      }

      return res;
    } catch (e) {
      // console.log("catch_e--->>", e);
    }
  };

export const deleteApplicationUser_Call =
  ({ userId, event, target, count, pageNumber, users }) =>
  async (dispatch) => {
    try {
      let res = await genericAxiosCall(
        `${serverRoutes.ADD_NEW_USER}/${userId}`,
        "delete"
      );

      dispatch(deleteUserFromUserArr_Data(userId));
      dispatch(
        setListingState({
          listing: "applicationUsersFilter",
          value: {
            search: "",
          },
        })
      );
      if (users.length == 1) {
        await dispatch(
          setListingState({
            listing: "applicationUsersFilter",
            value: {
              pageNumber: pageNumber - 1 === 0 ? 0 : pageNumber - 1,
            },
          })
        );
      }
      dispatch(event());
      return res;
    } catch (e) {
      throw new Error(e);
      // console.log("catch_e--->>", e);
    }
  };

export const resetPwdApplicationUser_Call = (body) => async (dispatch) => {
  try {
    let res = await genericAxiosCall(
      `${serverRoutes.UPDATE_USER_PWD}?userId=${body?.id}&newPassword=${body?.password}&email=${body?.email}`,
      "put"
    );

    return res;
  } catch (e) {
    // console.log("catch_e--->>", e);
  }
};

export default ApplicationUsersSlice.reducer;

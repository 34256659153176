import { createSlice } from "@reduxjs/toolkit";

import {
  commonPagination,
  getCurrentClientId,
  getMultiSelectFilterIdForApi_ForId,
  getMultiSelectFilterValueFor837Payers,
  getMultiSelectFilterValueForApi,
} from "../../../../components/common/Utils/utils";
import {
  resetListingState,
  resetListingStateForResetButton,
} from "../../filters/claimManagerFilters";
import { getClaimAll837FiltersData_Call } from "../../DenialDetails/claim837AllFiltersSlice";
import store from "../../../store";

export const durationWiseBucketSlice = createSlice({
  name: "durationWiseBucketSlice",
  initialState: {
    claimData: { count: null, totalChargeAmount: null, tabularArr: [] },
  },
  reducers: {
    setDurationWise_data: (state, action) => {
      let data = action.payload;
      if (data?.claims?.length) {
        state.claimData.tabularArr = data?.claims;
        state.claimData.count = data?.claims[0]?.count;
        state.claimData.totalChargeAmount = data?.claims[0]?.totalChargeAmount;
      } else {
        state.claimData.tabularArr = [];
        state.claimData.count = null;
        state.claimData.totalChargeAmount = null;
      }
    },
  },
});

export const { setDurationWise_data } = durationWiseBucketSlice.actions;

// get duration wise bucket data
export const gettingClaimAgingReportData_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      if (clear === true) {
        dispatch(
          resetListingState({
            listing: "claimAgingReportFilter",
            ignore: ["claimStatus", "payers", "frequencyType", "search"],
          })
        );
      }
      if (clear === "resetBtn") {
        dispatch(
          resetListingStateForResetButton({
            listing: "claimAgingReportFilter",
            ignore: ["claimStatus", "payers", "frequencyType", "search"],
          })
        );
      }
    } else {
    }

    console.log("gettingClaimAgingReportData_Call--->>");

    gettingClaimAgingReportData_Call;
    let sliceState = getState().npReducers.filters.claimAgingReportFilter;
    let sliceStateUser = getState().pReducers.user;

    let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;
    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable
        ?.claimAgingReportFilterNoOfRecordInTable;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    try {
      ///// API CALL //////
      let paramsTemp = {
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterIdForApi_ForId(sliceState?.payers),
        }),
        ...(sliceState?.filingIndicators?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterIdForApi_ForId(
            sliceState?.filingIndicators
          ),
        }),
        // ...(sliceState?.billingProviders?.length > 0 && {
        //   billingProviders: getMultiSelectFilterIdForApi_ForId(
        //     sliceState?.billingProviders
        //   ),
        // }),
        ...(sliceState?.billingProviders?.length > 0 && {
          billingProvider: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),

        ...(sliceState?.renderingProviders?.length > 0 && {
          renderingProvider: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceState?.attendingProviders?.length > 0 && {
          attendingProvider: getMultiSelectFilterValueForApi(
            sliceState?.attendingProviders
          ),
        }),
        ...(sliceState?.frequencyType?.length > 0 && {
          frequencyType: getMultiSelectFilterIdForApi_ForId(
            sliceState?.frequencyType
          ),
        }),
        ...(sliceState?.diagnosisCode?.length > 0 && {
          diagnosisCode: getMultiSelectFilterIdForApi_ForId(
            sliceState?.diagnosisCode
          ),
        }),

        ...(sliceState?.cptCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterIdForApi_ForId(sliceState?.cptCodes),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceState?.fromClaim !== "" && {
          from: sliceState.fromClaim,
        }),
        ...(sliceState?.toClaim !== "" && {
          to: sliceState.toClaim,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: getCurrentClientId(),
        }),
      };

      let params = {
        start,
        limit,
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterIdForApi_ForId(sliceState?.payers),
        }),
        ...(sliceState?.filingIndicators?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterIdForApi_ForId(
            sliceState?.filingIndicators
          ),
        }),
        // ...(sliceState?.billingProviders?.length > 0 && {
        //   billingProviders: getMultiSelectFilterIdForApi_ForId(
        //     sliceState?.billingProviders
        //   ),
        // }),
        ...(sliceState?.billingProviders?.length > 0 && {
          billingProvider: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),

        ...(sliceState?.renderingProviders?.length > 0 && {
          renderingProvider: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceState?.attendingProviders?.length > 0 && {
          attendingProvider: getMultiSelectFilterValueForApi(
            sliceState?.attendingProviders
          ),
        }),
        ...(sliceState?.frequencyType?.length > 0 && {
          frequencyType: getMultiSelectFilterIdForApi_ForId(
            sliceState?.frequencyType
          ),
        }),
        ...(sliceState?.diagnosisCode?.length > 0 && {
          diagnosisCode: getMultiSelectFilterIdForApi_ForId(
            sliceState?.diagnosisCode
          ),
        }),

        ...(sliceState?.cptCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterIdForApi_ForId(sliceState?.cptCodes),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceState?.fromClaim !== "" && {
          from: sliceState.fromClaim,
        }),
        ...(sliceState?.toClaim !== "" && {
          to: sliceState.toClaim,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: getCurrentClientId(),
        }),
      };

      const cptParams = {
        CPTCode: "",
        start: 0,
        limit: 100,
        ...(sliceState?.cptCodes?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),
        ...{
          from: "2021-09-21",
        },
        ...{
          to: "2022-09-21",
        },
        // timeStamp: new Date().getTime(),
      };

      const payersParams = {
        searchKeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.payers?.length > 0 && {
          selectedPayers: getMultiSelectFilterValueFor837Payers(
            sliceState?.payers
          ),
        }),
        ...{
          from: "2021-09-21",
        },
        ...{
          to: "2022-09-21",
        },
      };

      const diagnosisParams = {
        searchKeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.diagnosisCode?.length > 0 && {
          SelectedDiagnosisCode: getMultiSelectFilterValueForApi(
            sliceState?.diagnosisCode
          ),
        }),
        ...{
          from: "2021-09-21",
        },
        ...{
          to: "2022-09-21",
        },
      };

      const billingProviderParams = {
        searchKeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.billingProviders?.length > 0 && {
          selectedBillingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...{
          from: "2021-09-21",
        },
        ...{
          to: "2022-09-21",
        },
      };

      const renderingProviderParams = {
        searchKeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.renderingProviders?.length > 0 && {
          selectedRenderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),
        ...{
          from: "2021-09-21",
        },
        ...{
          to: "2022-09-21",
        },
      };

      const attendingProviderParams = {
        searchKeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.attendingProviders?.length > 0 && {
          selectedAttendingProviders: getMultiSelectFilterValueForApi(
            sliceState?.attendingProviders
          ),
        }),
        ...{
          from: "2021-09-21",
        },
        ...{
          to: "2022-09-21",
        },
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setDurationWise_data,
        "claimAgingReportFilter",
        "GET_DURATION_BUCKET_WISE_837",
        false,
        "",
        ""
      );

      // for filters api call

      dispatch(
        getClaimAll837FiltersData_Call(
          cptParams,
          payersParams,
          diagnosisParams,
          billingProviderParams,
          renderingProviderParams,
          attendingProviderParams,
          {},
          {
            isServiceLineAPI: true,
            isPayersAPI: true,
            isFillingIndicatorAPI: true,
            isBillingProviderAPI: true,
            isRenderingProviderAPI: true,
            isAttendingProviderAPI: true,
            isProviderAPI: false,
            isFrequencyAPI: true,
            isDiagCodeAPI: true,
            isDrgCodeAPI: true,
            isCptAPI: false,
            isDrgCodeAPI: false,
          }
        )
      );

      return 0;
    } catch (e) {
      console.log("e--->>", e);

      return false;
    }
  };

// get duration wise bucket data
export const gettingWriteOffClaimData_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      if (clear === true) {
        dispatch(
          resetListingState({
            listing: "claimAgingReportFilter",
            ignore: ["claimStatus,payers,frequencyType,search"],
          })
        );
      }
      if (clear === "resetBtn") {
        dispatch(
          resetListingStateForResetButton({
            listing: "claimAgingReportFilter",
            ignore: ["claimStatus,payers,frequencyType,search"],
          })
        );
      }
    }

    // console.log("gettingWriteOffClaimData_Call--->>");

    let sliceState = getState().npReducers.filters.claimAgingReportFilter;
    let sliceStateUser = getState().pReducers.user;

    let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;
    //
    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable
        ?.claimAgingReportFilterNoOfRecordInTable;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    try {
      ///// API CALL //////

      let paramsTemp = {
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterIdForApi_ForId(sliceState?.payers),
        }),
        ...(sliceState?.filingIndicators?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterIdForApi_ForId(
            sliceState?.filingIndicators
          ),
        }),
        // ...(sliceState?.billingProviders?.length > 0 && {
        //   billingProviders: getMultiSelectFilterIdForApi_ForId(
        //     sliceState?.billingProviders
        //   ),
        // }),
        ...(sliceState?.billingProviders?.length > 0 && {
          billingProvider: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),

        ...(sliceState?.renderingProviders?.length > 0 && {
          renderingProvider: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceState?.attendingProviders?.length > 0 && {
          attendingProvider: getMultiSelectFilterValueForApi(
            sliceState?.attendingProviders
          ),
        }),
        ...(sliceState?.frequencyType?.length > 0 && {
          frequencyType: getMultiSelectFilterIdForApi_ForId(
            sliceState?.frequencyType
          ),
        }),
        ...(sliceState?.diagnosisCode?.length > 0 && {
          diagnosisCode: getMultiSelectFilterIdForApi_ForId(
            sliceState?.diagnosisCode
          ),
        }),

        ...(sliceState?.cptCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterIdForApi_ForId(sliceState?.cptCodes),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: getCurrentClientId(),
        }),
      };
      let params = {
        start,
        limit,
        timeStamp: new Date().getTime(),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterIdForApi_ForId(sliceState?.payers),
        }),
        ...(sliceState?.filingIndicators?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterIdForApi_ForId(
            sliceState?.filingIndicators
          ),
        }),
        // ...(sliceState?.billingProviders?.length > 0 && {
        //   billingProviders: getMultiSelectFilterIdForApi_ForId(
        //     sliceState?.billingProviders
        //   ),
        // }),
        ...(sliceState?.billingProviders?.length > 0 && {
          billingProvider: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),

        ...(sliceState?.renderingProviders?.length > 0 && {
          renderingProvider: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceState?.attendingProviders?.length > 0 && {
          attendingProvider: getMultiSelectFilterValueForApi(
            sliceState?.attendingProviders
          ),
        }),
        ...(sliceState?.frequencyType?.length > 0 && {
          frequencyType: getMultiSelectFilterIdForApi_ForId(
            sliceState?.frequencyType
          ),
        }),
        ...(sliceState?.diagnosisCode?.length > 0 && {
          diagnosisCode: getMultiSelectFilterIdForApi_ForId(
            sliceState?.diagnosisCode
          ),
        }),
        ...(sliceState?.cptCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterIdForApi_ForId(sliceState?.cptCodes),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        // ...(sliceState?.from !== "" && {
        //   from: sliceState.from,
        // }),
        ...{
          from: "2021-09-21",
        },
        ...{
          to: "2022-09-21",
        },
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: getCurrentClientId(),
        }),
      };

      const cptParams = {
        CPTCode: "",
        start: 0,
        limit: 100,
        ...(sliceState?.cptCodes?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),
        ...{
          from: "2021-09-21",
        },
        ...{
          to: "2022-09-21",
        },
        // timeStamp: new Date().getTime(),
      };
      const payersParams = {
        searchKeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.payers?.length > 0 && {
          selectedPayers: getMultiSelectFilterValueFor837Payers(
            sliceState?.payers
          ),
        }),
        ...{
          from: "2021-09-21",
        },
        ...{
          to: "2022-09-21",
        },
      };

      const diagnosisParams = {
        searchKeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.diagnosisCode?.length > 0 && {
          SelectedDiagnosisCode: getMultiSelectFilterValueForApi(
            sliceState?.diagnosisCode
          ),
        }),
        ...{
          from: "2021-09-21",
        },
        ...{
          to: "2022-09-21",
        },
      };

      const billingProviderParams = {
        searchKeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.billingProviders?.length > 0 && {
          selectedBillingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...{
          from: "2021-09-21",
        },
        ...{
          to: "2022-09-21",
        },
      };

      const renderingProviderParams = {
        searchKeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.renderingProviders?.length > 0 && {
          selectedRenderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),
        ...{
          from: "2021-09-21",
        },
        ...{
          to: "2022-09-21",
        },
      };

      const attendingProviderParams = {
        searchKeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.attendingProviders?.length > 0 && {
          selectedAttendingProviders: getMultiSelectFilterValueForApi(
            sliceState?.attendingProviders
          ),
        }),
        ...{
          from: "2021-09-21",
        },
        ...{
          to: "2022-09-21",
        },
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setDurationWise_data,
        "claimAgingReportFilter",
        "GET_WRITE_OFF_CLAIM_837",
        false,
        "",
        ""
      );

      // for filters api call

      dispatch(
        getClaimAll837FiltersData_Call(
          cptParams,
          payersParams,
          diagnosisParams,
          billingProviderParams,
          renderingProviderParams,
          attendingProviderParams,
          {},
          {
            isServiceLineAPI: true,
            isPayersAPI: true,
            isFillingIndicatorAPI: true,
            isBillingProviderAPI: true,
            isRenderingProviderAPI: true,
            isAttendingProviderAPI: true,
            isProviderAPI: false,
            isFrequencyAPI: true,
            isDiagCodeAPI: true,
            isCptAPI: true,
            isDrgCodeAPI: false,
          }
        )
      );

      ////// API CALL END //////////////

      return 0;
    } catch (e) {
      console.log("e--->>", e);

      return false;
    }
  };

export default durationWiseBucketSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

import {
  // setListingState,
  resetListingState,
  setListingState,
} from "../filters/claimManagerFilters";
import {
  commonPagination,
  commonPaginationForPost,
  exceptionArray,
  getCurrentClientId,
  getDataForLineChart,
  getGroupByHeatMapDataCARC,
  getGroupByHeatMapDataCARCCategory,
  getGroupByHeatMapDataCARCDepartment,
  getGroupByHeatMapDataCPT,
  getGroupByHeatMapDataCPTDepartment,
  getGroupByRevenueHeatmapDataCPTDepartment,
  getTreeNodeObj,
  getTreeNodeObjFullPath,
  numberFormatIntoShortForm,
} from "../../../components/common/Utils/utils";
import { getMultiSelectFilterValueForApi } from "./../../../components/common/Utils/utils";
import genericAxiosCall from "./../../../AxiosConfig/genericAxiosCall";
import { serverRoutes } from "../../../constants/serverRoutes";
import { gettingSmartFilterForOncologyDenial_Call } from "../OncologyDashboard/OncologyDashboardKpisSlice";

let constDate = null;

// carcHandleYearOverYear
const carcHandleYearOverYear = (data) => {
  let resData = data.oncologyCARCYearByYear;

  try {
    if (constDate === "YOY") {
      let allDate = {};
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      // data.oncologyCARCYearByYear
      const uniqueYear = [...new Set(resData.map((item) => item.year))];

      for (let i = 0; i < uniqueYear.length; i++) {
        let arr = [];
        let year = uniqueYear[i];

        resData.map((item, index) => {
          if (item?.year === year) {
            arr.push(item);
          }
        });

        // console.log("arr--->>", year, arr);

        allDate[`${year}`] = arr;
      }

      // console.log("uniqueYear--->>", uniqueYear, uniqueYear.length);
      let newObj = {};
      for (let i = 0; i < uniqueYear.length; i++) {
        let newArr = [];
        let j = 0;
        let year = uniqueYear[i];
        let tempArr = allDate[`${year}`];
        monthNames.map((month, index) => {
          // console.log("allDate--->>", month, tempArr[j], j);
          if (j < tempArr?.length) {
            if (month === tempArr[j]?.monthName && j < tempArr.length) {
              newArr.push(tempArr[j]);
              j++;
            } else {
              let t = {
                monthName: month,
                year: uniqueYear[i],
                totalAmount: 0,
                denialAmount: 0,
                denialCount: 0,
              };
              newArr.push(t);
            }
          } else {
            let t = {
              monthName: month,
              year: uniqueYear[i],
              totalAmount: 0,
              denialAmount: 0,
              denialCount: 0,
            };
            newArr.push(t);
          }
        });
        newObj[`${year}`] = newArr;
      }
      //update from here
      allDate = newObj;
      return { allDate, uniqueYear };
    } else {
      return false;
    }
  } catch (e) {
    // console.log("e--->>", e);

    return false;
  }
};

export const RevenueHeatmapSlice = createSlice({
  name: "RevenueHeatmapSlice",
  initialState: {
    //Filter Array
    carcReasonCodeFilterArr: [],
    carcTrendsLineChartArr: [],
    carcDetail: {
      DenialPer: null,
      DenialCount: null,
      DenialAmount: null,
      ChargeAmount: null,
      tabularDataArr: [],
    },
    carcReasonCodeCards: [],
    carcTrendsGraphArr: [],

    // resonCode Dash KPI's graph data
    reasonCodeDashGraphArr: [],
    reasonCodeDashFilterArr: [],

    // ReasonCodeHeatMap here
    insightsReasonCodeHeatMapArr: {},
    insightsDenialbyCPTCodeHeatMapArr: {},
    rangesArray: {
      CPTCodeHeatMapArr: "",
      ReasonCodeHeatMapArr: "",
    },
    //loader
    groupByAllLoader: false,
    groupByDepartmentLoader: false,
    groupByCategoryLoader: false,
    //setters
    groupByDepartmentCPT: [],
    groupByDepartmentCARC: [],
    groupByCategoryCARC: [],
    departmentArr: [],
    payerFilterArr: [],
    availableFacilitiesList: [],
    availableHospitalsList: [],
    insightsSmartFilterArr: [],
    claimStatus23FilterArr: [],
  },
  reducers: {
    setCARCFilters_Data: (state, action) => {
      let data = action.payload;
      // for  reasonCode filter Arr
      if (data?.carcReasonCodeFilter?.length > 0) {
        let newArr = [];
        data?.carcReasonCodeFilter?.forEach((item) => {
          let reasonCode_and_desc = `${item?.reasonCode}${
            item?.description ? ` - ${item?.description}` : ""
          }`;
          newArr.push({
            label: reasonCode_and_desc,
            value: item?.reasonCode,
          });
        });

        state.carcReasonCodeFilterArr = newArr;
      } else {
        state.carcReasonCodeFilterArr = [];
      }
    },
    //Loader true

    //Group By All
    gettingGroupByAll: (state, action) => {
      state.groupByAllLoader = true;
    },
    successGroupByAll: (state, action) => {
      state.groupByAllLoader = false;

      const data = action?.payload?.revenueHeatmapData;

      if (data?.revenueHeatmapData?.length > 0) {
        let newArr = [];
        let countValue = 0;
        const finalData = data?.revenueHeatmapData;

        finalData?.map((item, index) => {
          newArr.push({
            name: `${item?.levelName} - $${numberFormatIntoShortForm(
              item?.paymentAmount
            )}`,
            code: item?.levelName,
            actualValue: item?.paymentAmount,

            value:
              item?.paymentAmount < 0
                ? item?.paymentAmount * -1
                : item?.paymentAmount,

            denielCount: item?.paymentCount,
            description: item?.description,
            totalCount: item?.totalCount,
          });
          countValue = countValue + item?.paymentAmount;
        });
        if (countValue === 0) {
          state.insightsReasonCodeHeatMapArr = {};
          state.rangesArray.ReasonCodeHeatMapArr = "";
        } else {
          let obj = {
            name: "root",
            children: [
              {
                brand: "All",
                children: newArr,
                name: "All",
                value: countValue,
              },
            ],
          };
          state.insightsReasonCodeHeatMapArr = obj;
        }
      } else {
        state.insightsReasonCodeHeatMapArr = {};
        state.rangesArray.ReasonCodeHeatMapArr = "";
      }
      // handle denialbyCPTCodeHeatmap here

      if (data?.departments?.length > 0) {
        state.departmentArr = JSON.parse(data?.departments);
        // var newArr = [];
        // data?.departments.forEach((item, index) => {
        //   newArr.push(item?.name);
        // });
        // newArr.unshift("Departments(All)");
        // state.departmentArr = newArr;
      } else {
        state.departmentArr = [];
      }
      if (data?.facilities?.length > 0) {
        let newArr = [];

        data?.facilities.splice(
          data?.facilities.findIndex((a) => a?.name === ""),
          1
        );
        data?.facilities?.forEach((item) => {
          newArr.push({
            label: item?.name,
            value: item?.name,
          });
        });

        state.availableFacilitiesList = newArr;
      } else {
        state.availableFacilitiesList = [];
      }
      if (data?.revenueHeatmapPayerFilter?.length > 0) {
        let newArr = [];
        data?.revenueHeatmapPayerFilter?.map((item, index) => {
          newArr.push({
            label: `${item?.payerName}-${item?.payerPrimaryId}`,
            value: item?.payerId,
          });
        });
        state.payerFilterArr = newArr;
      } else {
        state.payerFilterArr = [];
      }
      if (data?.hospitals?.length > 0) {
        let newArr = [];
        data?.hospitals?.forEach((item) => {
          if (item?.displayName && item?.healthSystemId) {
            newArr.push({
              label: item?.displayName,
              value: item?.healthSystemId,
              fullName: item?.hospital,
            });
          }
        });
        state.availableHospitalsList = newArr;
      } else {
        state.availableHospitalsList = [];
      }
    },
    failedGroupByAll: (state, action) => {
      state.groupByAllLoader = false;
    },
    //Group By Category
    gettingGroupByCategory: (state, action) => {
      state.groupByCategoryLoader = true;
    },
    successGroupByCategory: (state, action) => {
      const data = action.payload;
      state.groupByCategoryLoader = false;
      if (data?.denialByReasonCodeHeatmap?.length > 0) {
        const finalData = data?.denialByReasonCodeHeatmap;

        const {
          countValue,
          newArr,
          groupByDeptObj,
          finalArrByDept,
          finalArrByCategory,
        } = getGroupByHeatMapDataCARCCategory(finalData);
        // state.groupByDepartmentCARC = finalArrByDept;
        state.groupByCategoryCARC = finalArrByCategory;
        // let obj = {
        //   name: "root",
        //   children: [
        //     {
        //       brand: "All",
        //       children: newArr,
        //       name: "All",
        //       value: countValue,
        //     },
        //   ],
        // };

        // state.insightsReasonCodeHeatMapArr = obj;
      } else {
        // state.groupByDepartmentCARC = [];
        state.groupByCategoryCARC = [];
        // state.insightsReasonCodeHeatMapArr = {};
      }
    },
    failedGroupByCategory: (state, action) => {
      state.groupByCategoryLoader = false;
    },
    //Group By Department
    gettingGroupByDepartment: (state, action) => {
      state.groupByDepartmentLoader = true;
    },
    successGroupByDepartment: (state, action) => {
      const data = action.payload?.revenueHeatmapData;
      state.groupByDepartmentLoader = false;
      if (data?.revenueHeatmapData?.length > 0) {
        const finalDataCPT = data?.revenueHeatmapData;

        const { finalArr } =
          getGroupByRevenueHeatmapDataCPTDepartment(finalDataCPT);

        state.groupByDepartmentCPT = finalArr;
      } else {
        state.groupByDepartmentCPT = [];
        state.rangesArray.CPTCodeHeatMapArr = [];
      }
    },
    failedGroupByDepartment: (state, action) => {
      state.groupByDepartmentLoader = false;
    },

    setCARCDash_Data: (state, action) => {
      let data = action.payload;

      // for  oncologyCARCTable Arr
      if (data?.CARCTable?.length > 0) {
        state.carcDetail.tabularDataArr = data?.CARCTable;
        state.carcDetail.DenialPer = data?.DenialPer;
        state.carcDetail.DenialCount = data?.DenialCount;
        state.carcDetail.DenialAmount = data?.DenialAmount;
        state.carcDetail.ChargeAmount = data?.ChargeAmount;
      } else {
        state.carcDetail.tabularDataArr = [];
        state.carcDetail.DenialPer = null;
        state.carcDetail.DenialCount = null;
        state.carcDetail.DenialAmount = null;
        state.carcDetail.ChargeAmount = null;
      }

      // for  oncologyCARCCard Arr
      if (data?.oncologyCARCCard?.length > 0) {
        state.carcReasonCodeCards = data?.oncologyCARCCard;
      } else {
        state.carcReasonCodeCards = [];
      }
    },

    setCARCDashKPIS_Data: (state, action) => {
      let data = action.payload;

      let returnType = carcHandleYearOverYear(data);

      let newAmountArr = [];

      if (returnType) {
        // let denailAmount = [];
        for (let i = 0; i < returnType.uniqueYear.length; i++) {
          newAmountArr.push({
            id: `Year - ${returnType.uniqueYear[i]}`,
            color: "hsl(116, 70%, 50%)",
            data: getDataForLineChart(
              returnType.allDate[`${returnType.uniqueYear[i]}`]
            ),
          });
        }

        if (newAmountArr.length) {
          state.carcTrendsGraphArr = newAmountArr;
        } else {
          state.carcTrendsGraphArr = [];
        }
      } else {
        if (data?.oncologyCARCTrend && data?.oncologyCARCTrend?.length) {
          let denailAmount = [];

          let totalChargeAmount = [];

          data?.oncologyCARCTrend.forEach((item, index) => {
            denailAmount.push({
              x: item?.dateRange,
              y:
                item?.denialAmount < 0
                  ? item?.denialAmount * -1
                  : item?.denialAmount,
              denialCount: item?.denialCount,
              denialAmount: item?.denialAmount,
              billedAmount: item?.totalAmount,
            });

            totalChargeAmount.push({
              x: item?.dateRange,
              y: item?.totalAmount,
              denialCount: item?.denialCount,
              billedAmount: item?.totalAmount,
            });
          });

          newAmountArr = [
            {
              id: "Denial Amount",
              color: "hsl(116, 70%, 50%)",
              data: denailAmount,
            },
          ];

          state.carcTrendsGraphArr = newAmountArr;
        } else {
          state.carcTrendsGraphArr = [];
        }
      }
    },

    // reasonCode Dash KPI's data
    setReasonCodDashKPIS_Data: (state, action) => {
      let data = action.payload;

      let returnType = carcHandleYearOverYear(data);

      let newAmountArr = [];

      if (returnType) {
        // let denailAmount = [];
        for (let i = 0; i < returnType.uniqueYear.length; i++) {
          newAmountArr.push({
            id: `Year - ${returnType.uniqueYear[i]}`,
            color: "hsl(116, 70%, 50%)",
            data: getDataForLineChart(
              returnType.allDate[`${returnType.uniqueYear[i]}`]
            ),
          });
        }

        if (newAmountArr.length) {
          state.reasonCodeDashGraphArr = newAmountArr;
        } else {
          state.reasonCodeDashGraphArr = [];
        }
      } else {
        if (data?.oncologyCARCTrend && data?.oncologyCARCTrend?.length) {
          let denailAmount = [];

          let totalChargeAmount = [];

          data?.oncologyCARCTrend.forEach((item, index) => {
            denailAmount.push({
              x: item?.dateRange,
              y:
                item?.denialAmount < 0
                  ? item?.denialAmount * -1
                  : item?.denialAmount,
              denialCount: item?.denialCount,
              denialAmount: item?.denialAmount,
              billedAmount: item?.totalAmount,
            });

            totalChargeAmount.push({
              x: item?.dateRange,
              y: item?.totalAmount,
              denialCount: item?.denialCount,
              billedAmount: item?.totalAmount,
            });
          });

          newAmountArr = [
            {
              id: "Denial Amount",
              color: "hsl(116, 70%, 50%)",
              data: denailAmount,
            },
          ];

          state.reasonCodeDashGraphArr = newAmountArr;
        } else {
          state.reasonCodeDashGraphArr = [];
        }
      }
    },

    // reasonCode Dash filter data
    setReasonCodeFilters_Data: (state, action) => {
      let data = action.payload;
      // for  reasonCode filter Arr
      if (data?.carcReasonCodeFilter?.length > 0) {
        let newArr = [];
        data?.carcReasonCodeFilter?.forEach((item) => {
          let reasonCode_and_desc = `${item?.reasonCode}${
            item?.description ? ` - ${item?.description}` : ""
          }`;
          newArr.push({
            label: reasonCode_and_desc,
            value: item?.reasonCode,
          });
        });

        state.reasonCodeDashFilterArr = newArr;
      } else {
        state.reasonCodeDashFilterArr = [];
      }
    },

    // InsightsReasonCodeHeatmap

    setInsightsReasonCodeMap_Data: (state, action) => {
      let data = action.payload;
      // handle denialByReasonCodeHeatmap here
      if (data?.denialByReasonCodeHeatmap?.length > 0) {
        const finalData = data?.denialByReasonCodeHeatmap;

        const highest = finalData?.reduce(
          (prev, cur) => (prev?.denielAmount > cur.denielAmount ? prev : cur),
          null
        );
        const lowest = finalData?.reduce(
          (prev, cur) => (prev?.denielAmount < cur.denielAmount ? prev : cur),
          null
        );

        const range = (highest?.denielAmount - lowest?.denielAmount) / 4;
        state.rangesArray.ReasonCodeHeatMapArr = range;

        const {
          countValue,
          newArr,
          groupByDeptObj,
          finalArrByDept,
          finalArrByCategory,
        } = getGroupByHeatMapDataCARC(finalData, range);
        state.groupByDepartmentCARC = finalArrByDept;
        state.groupByCategoryCARC = finalArrByCategory;
        // let obj = {
        //   name: "root",
        //   children: [
        //     {
        //       brand: "All",
        //       children: newArr,
        //       name: "All",
        //       value: countValue,
        //     },
        //   ],
        // };

        // state.insightsReasonCodeHeatMapArr = obj;
      } else {
        state.groupByDepartmentCARC = [];
        state.groupByCategoryCARC = [];
        // state.insightsReasonCodeHeatMapArr = {};
      }
      if (data?.denialByReasonCodeHeatmap_WithoutRepeation?.length > 0) {
        let newArr = [];
        let countValue = 0;
        const finalData = data?.denialByReasonCodeHeatmap_WithoutRepeation;
        const highest = finalData?.reduce(
          (prev, cur) => (prev?.denielAmount > cur.denielAmount ? prev : cur),
          null
        );
        const lowest = finalData?.reduce(
          (prev, cur) => (prev?.denielAmount < cur.denielAmount ? prev : cur),
          null
        );

        const range = (highest?.denielAmount - lowest?.denielAmount) / 4;
        state.rangesArray.ReasonCodeHeatMapArr = range;
        finalData?.map((item, index) => {
          newArr.push({
            name: item?.reasonCode,
            actualValue: item?.denielAmount,
            value:
              item?.denielAmount < 0
                ? item?.denielAmount * -1
                : item?.denielAmount,
            ranges: { range: range, denialAmount: item?.denielAmount },

            chargeAmount: item?.chargeAmount,
            denialPercentage: item?.denialPercentage,
            denielCount: item?.denielCount,
            description: item?.description,
            totalCount: item?.totalCount,
          });
          countValue = countValue + item?.denielAmount;
        });

        let obj = {
          name: "root",
          children: [
            {
              brand: "All",
              children: newArr,
              name: "All",
              value: countValue,
            },
          ],
        };
        state.insightsReasonCodeHeatMapArr = obj;
      } else {
        state.insightsReasonCodeHeatMapArr = {};
      }
      // handle denialbyCPTCodeHeatmap here
      if (data?.denialbyCPTCodeHeatmap?.length > 0) {
        const finalData = data?.denialbyCPTCodeHeatmap;
        const highest = data?.denialbyCPTCodeHeatmap?.reduce(
          (prev, cur) => (prev?.denielAmount > cur.denielAmount ? prev : cur),
          null
        );
        const lowest = data?.denialbyCPTCodeHeatmap?.reduce(
          (prev, cur) => (prev?.denielAmount < cur.denielAmount ? prev : cur),
          null
        );

        const range = (highest?.denielAmount - lowest?.denielAmount) / 4;
        state.rangesArray.CPTCodeHeatMapArr = range;

        const { countValue, newArr, groupByDeptObj, finalArr } =
          getGroupByHeatMapDataCPT(finalData, range);

        // let objGroupByDept = {
        //   name: "root",
        //   children: [
        //     {
        //       brand: "All",
        //       children: finalArr,
        //       name: "All",
        //     },
        //   ],
        // };
        state.groupByDepartmentCPT = finalArr;

        let obj = {
          name: "root",
          children: [
            {
              brand: "All",
              children: newArr,
              name: "All",
              value: countValue,
            },
          ],
        };

        state.insightsDenialbyCPTCodeHeatMapArr = obj;
      } else {
        state.insightsDenialbyCPTCodeHeatMapArr = {};
        state.rangesArray.CPTCodeHeatMapArr = [];
      }

      // handle Departments here
      if (data?.departments?.length > 0) {
        state.departmentArr = JSON.parse(data?.departments);
        // var newArr = [];
        // data?.departments.forEach((item, index) => {
        //   newArr.push(item?.name);
        // });
        // newArr.unshift("Departments(All)");
        // state.departmentArr = newArr;
      } else {
        state.departmentArr = [];
      }

      // handle Facilities here
      if (data?.facilities?.length > 0) {
        let newArr = [];

        data?.facilities.splice(
          data?.facilities.findIndex((a) => a?.name === ""),
          1
        );
        data?.facilities?.forEach((item) => {
          newArr.push({
            label: item?.name,
            value: item?.name,
          });
        });

        state.availableFacilitiesList = newArr;
      } else {
        state.availableFacilitiesList = [];
      }

      // handle hospitals here
      if (data?.hospitals?.length > 0) {
        let newArr = [];
        data?.hospitals?.forEach((item) => {
          if (item?.name) {
            newArr.push({
              label: item?.name,
              value: item?.name,
              fullName: item?.hospital,
            });
          }
        });
        state.availableHospitalsList = newArr;
      } else {
        state.availableHospitalsList = [];
      }
    },

    // filter for smart filter
    setInsightsSmartFilters: (state, action) => {
      let data = action.payload;

      // for  serviceDenialReasonCode filter Arr
      if (data?.serviceDenialReasonCode?.length) {
        let newArr = [];
        data?.serviceDenialReasonCode?.forEach((item) => {
          let desc = item?.code + ` - ${item?.description}`;
          newArr.push({
            label: desc,
            value: item?.code,
          });
        });

        state.insightsSmartFilterArr = newArr;
      } else {
        state.insightsSmartFilterArr = [];
      }
      if (data?.claimStatusCode?.length) {
        let newArr = [];
        data?.claimStatusCode?.forEach((item) => {
          let desc = item?.claimStatusCodeId + ` - ${item?.description}`;
          newArr.push({
            label: desc,
            value: item?.claimStatusCodeId,
          });
        });

        state.claimStatus23FilterArr = newArr;
      } else {
        state.claimStatus23FilterArr = [];
      }
    },
  },
});

export const {
  setCARCFilters_Data,
  setInsightsSmartFilters,
  setReasonCodeFilters_Data,
  setCARCDash_Data,
  setCARCDashKPIS_Data,
  setInsightsReasonCodeMap_Data,
  setReasonCodDashKPIS_Data,
  gettingGroupByAll,
  successGroupByAll,
  failedGroupByAll,
  gettingGroupByCategory,
  successGroupByCategory,
  failedGroupByCategory,
  gettingGroupByDepartment,
  successGroupByDepartment,
  failedGroupByDepartment,
} = RevenueHeatmapSlice.actions;

// get CARC Trends KPIs
export const gettingOncologyCARCKPIS_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "carcTrendsDashboardFilter",
          ignore: ["claimStatus,payers,frequencyType,search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.carcTrendsDashboardFilter;

    try {
      dispatch(
        gettingSmartFilterForOncologyDenial_Call("carcTrendsDashboardFilter")
      );

      let paramsTemp = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),

        ...(sliceState.oncologyType === true && {
          Oncology: sliceState.oncologyType,
        }),
        ...(sliceState?.selFilterType !== "" && {
          filter:
            sliceState.selFilterType == "3M"
              ? "3M"
              : sliceState.selFilterType == "1Y"
              ? "1Y"
              : sliceState.selFilterType == "3Y"
              ? "3Y"
              : "Q",
        }),
        ...(!sliceState?.smartFilter &&
          sliceState?.ReasonCode?.length > 0 && {
            ReasonCode: getMultiSelectFilterValueForApi(sliceState?.ReasonCode),
          }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
      };

      let params = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...(sliceState.oncologyType === true && {
          Oncology: sliceState.oncologyType,
        }),
        ...(sliceState?.selFilterType !== "" && {
          filter:
            sliceState.selFilterType == "3M"
              ? "3M"
              : sliceState.selFilterType == "1Y"
              ? "1Y"
              : sliceState.selFilterType == "3Y"
              ? "3Y"
              : "Q",
        }),
        ...(!sliceState?.smartFilter &&
          sliceState?.ReasonCode?.length > 0 && {
            ReasonCode: getMultiSelectFilterValueForApi(sliceState?.ReasonCode),
          }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
      };

      constDate = sliceState?.selFilterType;

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setCARCDashKPIS_Data,
        "carcTrendsDashboardFilter",
        "GET_OCOLOGY_CARC_DASHBOARD_KPIS_GRAPH",
        true,
        "GET_OCOLOGY_CARC_FILTER_KPIS",
        setCARCFilters_Data
      );
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

//

// get reaconCode dashboard KPIs
export const gettingReasonCodeDashKPIS_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "reasonCodeDashboardFilter",
          ignore: ["claimStatus,payers,frequencyType,search"],
        })
      );
    }

    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceState = getState().npReducers.filters.reasonCodeDashboardFilter;

    try {
      dispatch(
        gettingSmartFilterForOncologyDenial_Call("reasonCodeDashboardFilter")
      );

      let paramsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.selFilterType !== "" && {
          filter:
            sliceStateForDate.selFilterType == "3M"
              ? "3M"
              : sliceStateForDate.selFilterType == "1Y"
              ? "1Y"
              : sliceStateForDate.selFilterType == "3Y"
              ? "3Y"
              : "Q",
        }),
        ...(!sliceState?.smartFilter &&
          sliceState?.ReasonCode?.length > 0 && {
            ReasonCode: getMultiSelectFilterValueForApi(sliceState?.ReasonCode),
          }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
      };

      let params = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.selFilterType !== "" && {
          filter:
            sliceStateForDate.selFilterType == "3M"
              ? "3M"
              : sliceStateForDate.selFilterType == "1Y"
              ? "1Y"
              : sliceStateForDate.selFilterType == "3Y"
              ? "3Y"
              : "Q",
        }),
        ...(!sliceState?.smartFilter &&
          sliceState?.ReasonCode?.length > 0 && {
            ReasonCode: getMultiSelectFilterValueForApi(sliceState?.ReasonCode),
          }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
      };

      constDate = sliceState?.selFilterType;

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setReasonCodDashKPIS_Data,
        "reasonCodeDashboardFilter",
        "GET_OCOLOGY_CARC_DASHBOARD_KPIS_GRAPH",
        true,
        "GET_OCOLOGY_CARC_FILTER_KPIS",
        setReasonCodeFilters_Data
      );
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

//

export const gettingTabularOncologyCARC_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "carcTrendsDashboardFilter",
          ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState =
      getState().npReducers.filters.carcTrendsDashboardDetailFilter;

    let sliceStateForFilter =
      getState().npReducers.filters.carcTrendsDashboardFilter;

    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceState?.selFilterType !== "" && {
          filter:
            sliceState.selFilterType == "3M"
              ? "3M"
              : sliceState.selFilterType == "1Y"
              ? "1Y"
              : sliceState.selFilterType == "3Y"
              ? "3Y"
              : "Q",
        }),
        ...(sliceStateForFilter?.ReasonCode?.length > 0 && {
          ReasonCode: getMultiSelectFilterValueForApi(
            sliceStateForFilter?.ReasonCode
          ),
        }),
      };

      let params = {
        start,
        limit,
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),

        ...(sliceState?.selFilterType !== "" && {
          filter:
            sliceState.selFilterType == "3M"
              ? "3M"
              : sliceState.selFilterType == "1Y"
              ? "1Y"
              : sliceState.selFilterType == "3Y"
              ? "3Y"
              : "Q",
        }),
        ...(sliceStateForFilter?.ReasonCode?.length > 0 && {
          ReasonCode: getMultiSelectFilterValueForApi(
            sliceStateForFilter?.ReasonCode
          ),
        }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setCARCDash_Data,
        "carcTrendsDashboardDetailFilter",
        "GET_OCOLOGY_CARC_DASHBOARD_TABULAR_DATA",
        true,
        "GET_OCOLOGY_CARC_FILTER_KPIS",
        setCARCFilters_Data
      );
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

// get one reasonCode details
export const getOneReasonCodeCARCTrendData_Call =
  (code) => async (dispatch, getState) => {
    let sliceState =
      getState().npReducers.filters.carcTrendsDashboardDetailFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      ///// API CALL //////

      let params = {
        start,
        limit,
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...{
          ReasonCode: code,
        },
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_ONE_REASON_CODE_OCOLOGY_CARC_DASHBOARD_KPIS,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };

// Reason Code Heatmap Dashboard
const groupByAllCall = (paramsTemp, params) => async (dispatch, getState) => {
  // let sliceState =
  //   getState().npReducers.filters.insightReasonCodeHeatMapFilterAll;

  try {
    params = { ...params, groupBy: "all" };
    paramsTemp = { ...paramsTemp, groupBy: "all" };

    // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)

    await commonPaginationForPost(
      getState,
      paramsTemp,
      params,
      dispatch,
      successGroupByAll,
      "revenueHeatMapFilterAll",
      "GET_REVENUE_HEAT_MAP",
      null,
      "",
      ""
    );
  } catch (e) {
    // console.log("catch--->>", e);
  }
};
const groupByCategoryCall =
  (paramsTemp, params) => async (dispatch, getState) => {
    let sliceState =
      getState().npReducers.filters.insightReasonCodeHeatMapFilterCategory;

    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      params = { ...params, groupBy: "subdepartment" };
      paramsTemp = { ...paramsTemp, groupBy: "subdepartment" };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        successGroupByCategory,
        "revenueHeatMapFilter",
        "GET_REVENUE_HEAT_MAP",
        null,
        "",
        ""
      );
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };
const groupByDepartmentCall =
  (paramsTemp, params) => async (dispatch, getState) => {
    let sliceState =
      getState().npReducers.filters.insightReasonCodeHeatMapFilterDepartment;

    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      params = { ...params, groupBy: "subdepartment" };
      paramsTemp = { ...paramsTemp, groupBy: "subdepartment" };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)

      await commonPaginationForPost(
        getState,
        paramsTemp,
        params,
        dispatch,
        successGroupByDepartment,
        "revenueHeatMapFilterSub",
        "GET_REVENUE_HEAT_MAP",
        null,
        "",
        ""
      );
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };
export const gettingRevenueHeatmap_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "revenueHeatMapFilter",
          ignore: [
            "selDeptHeatMapFilter",
            "selFacilitiesArr",
            "selHospitalsArr",
          ],
        })
      );
    }
    let sliceStateUser = getState().pReducers.user;

    // let sliceStateForDate =
    //   getState().npReducers.filters.insightReasonCodeHeatMapFilter;
    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;
    let sliceState = getState().npReducers.filters.revenueHeatMapFilter;
    // const sliceStateFilterArr = getState().npReducers.carcTrendsDash;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      // dispatch(gettingSmartInsightReasonCodeHeatmap_Call());

      let paramsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        // ...(sliceState?.selDeptHeatMapFilter !== "" &&
        //   sliceState?.selDeptHeatMapFilter !== "Departments(All)" && {
        //     Department: sliceState.selDeptHeatMapFilter,
        //   }),

        ...(sliceState?.selFacilitiesArr?.length > 0 && {
          Facility: getMultiSelectFilterValueForApi(
            sliceState?.selFacilitiesArr
          ),
        }),
        ...(sliceState?.selHospitalsArr?.length > 0 && {
          healthSystemId: getMultiSelectFilterValueForApi(
            sliceState?.selHospitalsArr
          ),
        }),

        ...(sliceState?.payerFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(
            sliceState?.payerFilter
          )?.toString(),
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };

      let params = {
        start,
        limit,
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        // ...(sliceState?.selDeptHeatMapFilter !== "" &&
        //   sliceState?.selDeptHeatMapFilter !== "Departments(All)" && {
        //     Department: sliceState.selDeptHeatMapFilter,
        //   }),

        ...(sliceState?.selFacilitiesArr?.length > 0 && {
          Facility: getMultiSelectFilterValueForApi(
            sliceState?.selFacilitiesArr
          ),
        }),
        ...(sliceState?.selHospitalsArr?.length > 0 && {
          healthSystemId: getMultiSelectFilterValueForApi(
            sliceState?.selHospitalsArr
          )?.toString(),
        }),
        ...(sliceState?.payerFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(
            sliceState?.payerFilter
          )?.toString(),
        }),
        // ...(sliceState?.smartFilter &&
        //   sliceState?.smartFilterArr?.length > 0 && {
        //     SmartFilter: getMultiSelectFilterValueForApi(
        //       sliceState?.smartFilterArr
        //     ),
        //   }),
        // ...(sliceState?.claimStatusFilter &&
        //   sliceState?.claimStatusIds?.length > 0 && {
        //     ClaimStatusIds: exceptionArray(
        //       sliceState?.claimStatusIds,
        //       sliceStateFilterArr?.claimStatus23FilterArr
        //     ),
        //   }),
        // groupBy: "All",
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };
      if (sliceState?.selDeptHeatMapFilter?.length > 0) {
        const treeSelected = getTreeNodeObjFullPath(
          sliceState.selDeptHeatMapFilter
        );
        if (treeSelected) {
          params = { ...params, ...treeSelected };
          paramsTemp = { ...paramsTemp, ...treeSelected };
        }
        // if (treeSelected) {
        //   params = { ...params, ...treeSelected };
        //   paramsTemp = { ...paramsTemp, ...treeSelected };
        // }
      }

      // console.log("api_call--->>", params);

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      await dispatch(groupByAllCall(paramsTemp, params));
      // dispatch(groupByCategoryCall(paramsTemp, params));
      await dispatch(groupByDepartmentCall(paramsTemp, params));

      // commonPagination(
      //   getState,
      //   paramsTemp,
      //   params,
      //   dispatch,
      //   setInsightsReasonCodeMap_Data,
      //   "revenueHeatMapFilter",
      //   "GET_INSIGHTS_REASON_CODE_HEAT_MAP",
      //   null,
      //   "",
      //   ""
      // );
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };

// get filters
export const gettingSmartInsightReasonCodeHeatmap_Call =
  (target) => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.revenueHeatMapFilter;

    let filterArr = sliceState?.smartFilterArr;

    try {
      dispatch(
        setListingState({
          listing: "revenueHeatMapFilter",
          value: {
            loadingSmartFilter: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const res = await genericAxiosCall(
        serverRoutes?.GET_SERVICE_LINE_EXECPTIONS,
        "get"
      );

      ////// API CALL END //////////////

      if (!res?.data?.success) {
        dispatch(
          setListingState({
            listing: "revenueHeatMapFilter",
            value: {
              loadingSmartFilter: "",
            },
          })
        );
        dispatch(setInsightsSmartFilters([]));
      } else {
        dispatch(
          setListingState({
            listing: "revenueHeatMapFilter",
            value: {
              loadingSmartFilter: "",
            },
          })
        );
        dispatch(setInsightsSmartFilters(res?.data?.data));
      }

      return res?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: target,
          value: {
            loadingSmartFilter: "",
          },
        })
      );
      return false;
    }
  };

export default RevenueHeatmapSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

import { serverRoutes } from "../../../constants/serverRoutes";

import { toast } from "react-toastify";
import {
  commonPagination,
  commonPaginationForPost,
  getCurrentClientId,
  getShortNameForToastText,
  getUserId,
} from "../../../components/common/Utils/utils";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";
import { setListingState } from "../filters/claimManagerFilters";
import { toggleQueueForceFlag } from "../HealthSystem/healthSystemSlice";
import genericAxiosCallFormData from "../../../AxiosConfig/genericAxiosCallFormData";

export const validator = (r) => !!r.value;

export const ClaimQueueSlice = createSlice({
  name: "ClaimQueueSlice",
  initialState: {
    //Filter Array
    movingClaimBucketsData: [],
    movingClaimBucketsData835: [],
    selectedBucketsTab: 0,
    userListArr: [],
    categoryListArr: [],
    queryRuesultArr: [],
    workQueueTabularArr: [],
    allViewsArr: [],
    totalQueuesCount: 0,
    transactionTypeListData: [],
    workQueryTabularloading: false,
    addingQueryloading: false,
    addingviewloading: false,
    queryExecuteloading: false,
    querylookuploading: false,
    getAllViewListloading: false,
    queueByIdloading: false,
    deleteItemLoading: false,
    deleteViewItemLoading: false,
    glossary: {
      AppealedData: [],
      hcpcs: [],
      glossaryData: [],
    },
    querylookupData: {
      fieldsFor835: [
        { name: "DOS", label: "Date of Service", inputType: "date", validator },
        {
          name: "ClaimStatusCodeId",
          label: "Claim Status",
          valueEditorType: "multiselect",
          values: [
            // { name: "ClaimStatus1", label: "Claim Status 1" },
            // { name: "ClaimStatus2", label: "Claim Status 2" },
            // { name: "ClaimStatus3", label: "Claim Status 3" },
          ],
          validator,
        },
        {
          name: "FilingIndicatorCodeId",
          label: "Filing Indicator Code",
          valueEditorType: "select",
          values: [
            // { name: "FilingIndicatorCode1", label: "Filing Indicator Code 1" },
            // { name: "FilingIndicatorCode2", label: "Filing Indicator Code 2" },
            // { name: "FilingIndicatorCode3", label: "Filing Indicator Code 3" },
          ],
          validator,
        },
        {
          name: "PayeeId",
          label: "Provider ",
          valueEditorType: "select",
          values: [
            // { name: "PayeeID1", label: "PayeeId 1" },
            // { name: "PayeeID2", label: "PayeeId 2" },
            // { name: "PayeeID3", label: "PayeeId 3" },
          ],
          validator,
        },
        {
          name: "PayerId",
          label: "Payer",
          valueEditorType: "select",
          values: [
            // { name: "PayerID1", label: "PayerId 1" },
            // { name: "PayerID2", label: "PayerId 2" },
            // { name: "PayerID3", label: "PayerId 3" },
          ],
          validator,
        },
        {
          name: "ChargeAmount",
          label: "Charge Amount",
          inputType: "number",
          validator,
        },

        {
          name: "AllowedAmount",
          label: "Allowed Amount",
          inputType: "number",
          validator,
        },
        {
          name: "PaymentAmount",
          label: "Payment Amount",
          inputType: "number",
          validator,
        },
        {
          name: "PatientResponsibilityAmount",
          label: "Patient Responsibility Amount",
          inputType: "number",
          validator,
        },
        {
          name: "AdjudicatedProcedureCode1",
          label: "Procedure Code",
          validator,
        },
        {
          name: "ReasonCode",
          label: "Reason Code",
          validator,
        },
        {
          name: "IPRCPaymentRemarkCode",
          label: "Payment Remark Code IPRC",
          validator,
        },
        {
          name: "OPRCPaymentRemarkCode",
          label: "Payment Remark Code OPRC",
          validator,
        },

        {
          name: "MLCPMLProbability",
          label: "AI Probability",
          inputType: "number",
          validator,
        },
        {
          name: "MLCPMLExpectedPayout",
          label: "Expected Payout",
          inputType: "number",
          validator,
        },
        {
          name: "CPMedicalRecordNo",
          label: "Medical Record Number",
          validator,
        },
      ],
      fieldsFor837: [
        { name: "DOS", label: "Date of Service", inputType: "date", validator },
        {
          name: "PayerId",
          label: "Payer",
          valueEditorType: "select",
          values: [
            // { name: "PayerID1", label: "PayerId 1" },
            // { name: "PayerID2", label: "PayerId 2" },
            // { name: "PayerID3", label: "PayerId 3" },
          ],
          validator,
        },
        {
          name: "ChargeAmount",
          label: "Billed Amount",
          inputType: "number",
          validator,
        },
        {
          name: "MLServiceLinePer",
          label: "Approval Prediction",
          inputType: "number",
          validator,
        },
        {
          name: "CRPhysicianId",
          label: "Physician NPI",
          // inputType: "number",
          validator,
        },
        {
          name: "CRMedicalRecordNo",
          label: "Medical Record Number",
          // inputType: "number",
          validator,
        },
        {
          name: "CRRepricedAllowedAmount",
          label: "Allowed Amount",
          inputType: "number",
          validator,
        },
        {
          name: "CRBillingProviderId",
          label: "Provider",
          // inputType: "number",
          validator,
        },
        {
          name: "MLCRPApprovedPer",
          label: "Charge Amount Prediction",
          inputType: "number",
          validator,
        },
        {
          name: "CRFrequencyCode",
          label: "Frequency Code",
          inputType: "number",
          validator,
        },
        {
          name: "SRProcedureCode",
          label: "Procedure Code",
          // inputType: "number",
          validator,
        },
        {
          name: "CPPrincipalDiagCode",
          label: "Diagnosis Code",
          valueEditorType: "select",
          validator,
        },
      ],
    },
    claimQueueTabularArr: [],
    myTasksTabularArr: [],
    myTasksTabularArr837: [],

    queueDetailRowCount: 0,
    myTasksRowCount: 0,
    myTasksRowCount837: 0,

    queryBuilderData: { fieldsFor835: [], fieldsFor837: [] },
    queryBuilderDataWSections: { fieldsFor835: [], fieldsFor837: [] },
    payerArr: [],
    payer837Arr: [],
    payeeArr: [],
    claimStatusArr: [],
    claimStatus837Arr: [],
    diagnoseCodeArr: [],
    procedureCodeArr: [],
    reasonCodeArr: [],
    groupCodeArr: [],
    filingIndicatorArr: [],
    revenueCodeArr: [],
    remarkCodeIPRC: [],
    remarkCodeOPRC: [],
    customerArr: [],
    claimPaymentRemarkCodes: [],
    drgCodes: [],
    denialReasonCategoriesArr: [],

    appealsLoader: true,
    appealsPageSize: 10,
    appealsPage: 1,
    appealsLimit: 10,
    appealsForceFlag: false,

    stateChangeLoader: false,
    moveClaimLoader: false,
    claimPriorityLoader: false,

    appealsDetailsPageFlag: false,

    claimAttachmentsLoader: false,
    deleteClaimAttachmentsLoader: false,
    claimAttachmentsData: [],
    claimAttachmentsPayer: null,

    insuranceFormLoader: false,
    insuranceFormData: [],

    insuranceForms: [],
    insuranceFormsLoader: false,

    confirmEditLoader: false,

    currentClaimAttachmentFlags: {},
    currentClaimFormFlag: null,
    currentClaimFormName: null,

    appealQueueFilterCount: null,

    appealDashboardUsersAndQueues: null,
    appealDashboardPayersDropdown: null,

    faxPayers: [],
    faxInfo: null,
    existingClaims: null,
    existingClaimsLoader: false,

    payerFormsSaveLoader: false,
  },
  reducers: {
    setCurrentClaimAttachmentFlags: (state, action) => {
      state.currentClaimAttachmentFlags = action.payload;
    },
    setCurrentClaimFormFlag: (state, action) => {
      state.currentClaimFormFlag = action.payload;
    },
    setCurrentClaimFormName: (state, action) => {
      state.currentClaimFormName = action.payload;
    },
    setMovingClaimBucketsData: (state, action) => {
      state.movingClaimBucketsData = action.payload;
    },
    setMovingClaimBucketsData835: (state, action) => {
      let data = action.payload;
      state.movingClaimBucketsData835 = data?.queueBuckets;
    },
    setAppealsDetailsPageFlag: (state, action) => {
      state.appealsDetailsPageFlag = action.payload;
    },
    setSelectedBucketsTab: (state, action) => {
      state.selectedBucketsTab = action.payload;
    },
    setQueryExecuteLoading: (state, action) => {
      state.queryExecuteloading = action.payload;
    },
    setAddingQueryLoading: (state, action) => {
      state.addingQueryloading = action.payload;
    },
    setAddingViewLoading: (state, action) => {
      state.addingviewloading = action.payload;
    },
    setWorkQueryTabularLoading: (state, action) => {
      state.workQueryTabularloading = action.payload;
    },
    SetQueryLookupLoading: (state, action) => {
      state.querylookuploading = action.payload;
    },
    SetAllViewsListLoading: (state, action) => {
      state.getAllViewListloading = action.payload;
    },
    SetQueueByIdLoading: (state, action) => {
      state.queueByIdloading = action.payload;
    },
    setDeleteItemLoading: (state, action) => {
      state.deleteItemLoading = action.payload;
    },

    setQueryLookupData: (state, action) => {
      let data = action.payload;

      let tempState = state.querylookupData;

      const transType = data?.TransactionTypes?.map((item) => {
        return {
          label: item?.transactionTypeName,
          value: item?.transactionTypeID,
        };
      });

      state.transactionTypeListData = transType;
      state.payerArr = data?.Payer;
      state.payer837Arr = data?.PayersClaimRequest;
      state.payeeArr = data?.Payee;
      state.claimStatusArr = data?.ClaimStatusCode;
      state.claimStatus837Arr = data?.ClaimStatus837;
      // state.diagnoseCodeArr = data?.DiagnoseCodes;
      // state.procedureCodeArr = data?.CPTCodes;
      state.reasonCodeArr = data?.ReasonCodes;
      state.groupCodeArr = data?.GroupCode;
      state.filingIndicatorArr = data?.FilingIndicatorCode;
      state.revenueCodeArr = data?.RevenueCodes;
      state.remarkCodeIPRC = data?.IPRemarkCode;
      state.remarkCodeOPRC = data?.OPRemarkCode;
      state.customerArr = data?.Customer;
      state.claimPaymentRemarkCodes = data?.ClaimPaymentRemarkCode;
      state.drgCodes = data?.DRGCodes;
      state.denialReasonCategoriesArr = data?.DenialReasonCategories;

      //  ClaimStatusCode arry handle here
      if (data?.ClaimStatusCode && data?.ClaimStatusCode?.length > 0) {
        let newArr = [];
        data?.ClaimStatusCode.forEach((item) => {
          newArr.push({
            name: item?.claimStatusCodeId,
            label: item?.description,
          });
        });
        tempState.fieldsFor835.map((item) => {
          if (item.name === "ClaimStatusCodeId") {
            item.values = newArr;
          }
        });
      } else {
        // do nothing
      }
      const groupAll = data?.FieldsFor835?.filter(
        (item) => item.groupBy === "All"
      );
      const groupAI = data?.FieldsFor835?.filter(
        (item) => item.groupBy === "AI"
      );
      const groupCodes = data?.FieldsFor835?.filter(
        (item) => item.groupBy === "Codes"
      );

      const groupAll2 = data?.FieldsFor837?.filter(
        (item) => item.groupBy === "All"
      );
      const groupAI2 = data?.FieldsFor837?.filter(
        (item) => item.groupBy === "AI"
      );
      const groupCodes2 = data?.FieldsFor837?.filter(
        (item) => item.groupBy === "Codes"
      );

      const modifiedFields835 = [
        {
          // label: "All",
          options: groupAll,
        },
        {
          // label: "AI",
          options: groupAI,
        },
        {
          // label: "Codes",
          options: groupCodes,
        },
      ];
      const modifiedFields837 = [
        {
          // label: "All",
          options: groupAll2,
        },
        {
          // label: "AI",
          options: groupAI2,
        },
        {
          // label: "Codes",
          options: groupCodes2,
        },
      ];

      state.queryBuilderData = {
        fieldsFor835: data?.FieldsFor835,
        fieldsFor837: data?.FieldsFor837,
      };
      state.queryBuilderDataWSections = {
        fieldsFor835: modifiedFields835,
        fieldsFor837: modifiedFields837,
      };
      //  FilingIndicatorCode arry handle here
      if (data?.FilingIndicatorCode && data?.FilingIndicatorCode?.length > 0) {
        let newArr = [];
        data?.FilingIndicatorCode.forEach((item) => {
          newArr.push({
            name: item?.filingIndicatorCodeId,
            label: item?.description,
          });
        });
        tempState.fieldsFor835.map((item) => {
          if (item.name === "FilingIndicatorCodeId") {
            item.values = newArr;
          }
        });
      } else {
        // do nothing
      }
      //  Payee arry handle here
      if (data?.Payee && data?.Payee?.length > -1) {
        let newArr = [];
        data?.Payee.forEach((item) => {
          newArr.push({
            name: item?.payeeId,
            // label: item?.name,
            label: `${item?.name}${
              item?.primaryId ? ` - ${item?.primaryId}` : ""
            }`,
          });
        });
        tempState.fieldsFor835.map((item) => {
          if (item.name === "PayeeId") {
            item.values = newArr;
          }
        });
      } else {
        // do nothing
      }
      //  Payer arry handle here
      if (data?.Payer && data?.Payer?.length > 0) {
        let newArr = [];
        data?.Payer.forEach((item) => {
          newArr.push({
            name: item?.payerId,
            label: `${item?.name}${
              item?.primaryId ? ` - ${item?.primaryId}` : ""
            }`,
          });
        });
        tempState.fieldsFor835.map((item) => {
          if (item.name === "PayerId") {
            item.values = newArr;
          }
        });
        tempState.fieldsFor837.map((item) => {
          if (item.name === "PayerId") {
            item.values = newArr;
          }
        });
      } else {
        // do nothing
      }

      //  User arry handle here
      if (data?.User && data?.User?.length > 0) {
        let newArr = [];
        data?.User.forEach((item) => {
          newArr.push({
            value: `${item?.displayName} - ${item?.userType} User -${item?.userId}`,
            label: `${item?.displayName} - ${item?.userType} User`,
            userId: item?.userId,
          });
        });
        if (newArr) {
          newArr.sort((a, b) => {
            const labelA = a?.label;
            const labelB = b?.label;
            return labelA.localeCompare(labelB);
          });
          state.userListArr = newArr;
        }
      } else {
        state.userListArr = [];
      }
      //  DenialReasonCategories arry handle here
      if (
        data?.DenialReasonCategories &&
        data?.DenialReasonCategories?.length > 0
      ) {
        let newArr = [];
        data?.DenialReasonCategories.forEach((item) => {
          newArr.push({
            value: item?.category,
            label: item?.category,
          });
        });
        state.categoryListArr = newArr;
      } else {
        state.categoryListArr = [];
      }
    },
    setIcd10LookupData: (state, action) => {
      let data = action.payload?.data;
      // console.log(">>>>> icd 10 >>> ", data);
      state.procedureCodeArr = data?.output;
    },
    setProcedureCodeLookupData: (state, action) => {
      let data = action.payload?.data;
      state.diagnoseCodeArr = data?.output;
      // console.log(">>>>> Procedure code >>> ", data);
    },
    setQueryResultArr: (state, action) => {
      let data = action.payload;
      if (data?.length > 0) {
        state.queryRuesultArr = data?.length > 5 ? data.slice(0, 5) : data;
      } else {
        state.queryRuesultArr = [];
      }
    },
    emptyQueryResultArr: (state, action) => {
      state.queryRuesultArr = [];
    },
    setDeleteViewItemLoading: (state, action) => {
      state.deleteViewItemLoading = action.payload;
    },
    setAllQueues_Data: (state, action) => {
      let data = action.payload;

      if (data?.length) {
        state.totalQueuesCount = data[0]?.countRecords;
        state.workQueueTabularArr = data;
      } else {
        state.totalQueuesCount = 0;
        state.workQueueTabularArr = [];
      }
    },
    setAllViews_Data: (state, action) => {
      let data = action.payload;
      if (data?.length) {
        state.allViewsArr = data;
      } else {
        state.allViewsArr = [];
      }
    },

    setSpecificQueueDetail: (state, action) => {
      const { payload: data } = action;
      if (data?.length) {
        state.claimQueueTabularArr = data;
        state.queueDetailRowCount = data[0]?.rowsCount;
      } else {
        state.claimQueueTabularArr = [];
        state.queueDetailRowCount = 0;
      }
    },
    setSpecificTaskDetail835: (state, action) => {
      const { payload: data } = action;
      if (data?.length) {
        state.myTasksTabularArr = data;
        state.myTasksRowCount = data[0]?.totalCount;
      } else {
        state.myTasksTabularArr = [];
        state.myTasksRowCount = 0;
      }
    },
    setSpecificTaskDetail837: (state, action) => {
      const { payload: data } = action;
      if (data?.length) {
        state.myTasksTabularArr837 = data;
        state.myTasksRowCount837 = data[0]?.totalCount;
      } else {
        state.myTasksTabularArr837 = [];
        state.myTasksRowCount837 = 0;
      }
    },
    setAppealsLoader: (state, action) => {
      state.appealsLoader = action?.payload?.data;
    },

    setAppealsPage: (state, action) => {
      state.appealsPage = action?.payload?.data;
    },
    setAppealsPageRows: (state, action) => {
      state.appealsPageSize = action?.payload?.data;
    },
    setStateChangeLoader: (state, action) => {
      state.stateChangeLoader = action?.payload;
    },
    setPriorityChangeLoader: (state, action) => {
      state.claimPriorityLoader = action?.payload;
    },
    setMoveClaimLoader: (state, action) => {
      state.moveClaimLoader = action?.payload;
    },
    setClaimAttachmentsLoader: (state, action) => {
      state.claimAttachmentsLoader = action?.payload;
    },
    setDeleteClaimAttachmentsLoader: (state, action) => {
      state.deleteClaimAttachmentsLoader = action?.payload;
    },
    setClaimAttachmentsData: (state, action) => {
      state.claimAttachmentsData = action?.payload;
    },
    setClaimAttachmentsPayer: (state, action) => {
      state.claimAttachmentsPayer = action?.payload;
    },
    setInsuranceFormLoader: (state, action) => {
      state.insuranceFormLoader = action?.payload;
    },
    setInsuranceFormData: (state, action) => {
      state.insuranceFormData = action?.payload;
    },
    setAppealGlossary: (state, action) => {
      if (action?.payload) {
        const data = action.payload;
        if (data?.appealLineItems) {
          state.glossary.AppealedData = JSON.parse(data?.appealLineItems);
        } else {
          state.glossary.AppealedData = [];
        }
        if (data?.procedureCodes) {
          state.glossary.hcpcs = JSON.parse(data?.procedureCodes);
        } else {
          state.glossary.hcpcs = [];
        }
        if (data?.glossoryItems) {
          state.glossary.glossaryData = JSON.parse(data?.glossoryItems);
        } else {
          state.glossary.glossaryData = [];
        }
      } else {
        state.glossary.AppealedData = [];
        state.glossary.glossaryData = [];
        state.glossary.hcpcs = [];
      }
    },
    setInsuranceFormsLoader: (state, action) => {
      state.insuranceFormsLoader = action?.payload;
    },
    setInsuranceForms: (state, action) => {
      state.insuranceForms = action?.payload;
    },

    setConfirmEditLoader: (state, action) => {
      state.confirmEditLoader = action?.payload;
    },
    setAppealQueueFilterCount: (state, action) => {
      state.appealQueueFilterCount = action?.payload;
    },
    setAppealDashboardUsersAndQueues: (state, action) => {
      // state.appealDashboardUsersAndQueues = action?.payload;
      (state.appealDashboardUsersAndQueues = action?.payload?.users),
        (state.appealDashboardPayersDropdown = action?.payload?.payers);
    },
    setFaxPayers: (state, action) => {
      // state.appealDashboardUsersAndQueues = action?.payload;
      state.faxPayers = action?.payload;
    },
    setFaxInfo: (state, action) => {
      state.faxInfo = action?.payload;
    },
    setExistingClaims: (state, action) => {
      state.existingClaims = action?.payload;
    },
    setExistingClaimsLoader: (state, action) => {
      state.existingClaimsLoader = action?.payload;
    },
    setPayerFormsSaveLoader: (state, action) => {
      state.payerFormsSaveLoader = action?.payload;
    },
  },
});

export const {
  setIcd10LookupData,
  setProcedureCodeLookupData,
  setQueryExecuteLoading,
  setQueryResultArr,
  emptyQueryResultArr,
  SetQueryLookupLoading,
  SetQueueByIdLoading,
  setAddingQueryLoading,
  setQueryLookupData,
  setAllQueues_Data,
  setWorkQueryTabularLoading,
  setSpecificQueueDetail,
  setSpecificTaskDetail835,
  setSpecificTaskDetail837,
  setDeleteItemLoading,
  setAddingViewLoading,
  SetAllViewsListLoading,
  setAllViews_Data,
  setDeleteViewItemLoading,
  setSelectedBucketsTab,
  setMovingClaimBucketsData,
  setMovingClaimBucketsData835,
  setAppealsLoader,
  setAppealsPage,
  setAppealsPageRows,
  setStateChangeLoader,
  setPriorityChangeLoader,
  setMoveClaimLoader,
  setAppealsDetailsPageFlag,
  setClaimAttachmentsLoader,
  setClaimAttachmentsData,
  setClaimAttachmentsPayer,
  setDeleteClaimAttachmentsLoader,
  setInsuranceFormData,
  setAppealGlossary,
  setInsuranceFormLoader,
  setInsuranceForms,
  setInsuranceFormsLoader,
  setConfirmEditLoader,
  setCurrentClaimAttachmentFlags,
  setCurrentClaimFormFlag,
  setCurrentClaimFormName,
  setAppealQueueFilterCount,
  setAppealDashboardUsersAndQueues,
  setFaxPayers,
  setFaxInfo,
  setExistingClaims,
  setExistingClaimsLoader,

  setPayerFormsSaveLoader,
} = ClaimQueueSlice.actions;

// get all lookup list
export const gettingAllLookUptData_Call = () => async (dispatch, getState) => {
  try {
    dispatch(SetQueryLookupLoading(true));
    // console.log("gettingAllLookUptData_Call--->>");

    // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
    // hanlde lookup api
    await commonPagination(
      getState,
      {},
      {},
      dispatch,
      setQueryLookupData,
      "workQueueLookUpFilter",
      "GET_ALL_LOOKUP_LIST",
      false,
      "",
      ""
    );
    try {
      // dispatch(SetQueueByIdLoading(true));
      await genericAxiosCall(
        `${serverRoutes?.GET_ALL_LOOKUP_ICD10_LIST}`,
        "get",
        "",
        ""
      ).then((res) => {
        dispatch(setIcd10LookupData(res.data));
      });
    } catch (error) {
      console.log("error--->>", error);
    } finally {
      // dispatch(SetQueueByIdLoading(false));
    }
    try {
      // dispatch(SetQueueByIdLoading(true));
      await genericAxiosCall(
        `${serverRoutes?.GET_ALL_LOOKUP_PROCEDURE_CODE_LIST}`,
        "get",
        "",
        ""
      ).then((res) => {
        dispatch(setProcedureCodeLookupData(res.data));
      });
    } catch (error) {
      console.log("error--->>", error);
    } finally {
      // dispatch(SetQueueByIdLoading(false));
    }
  } catch (error) {
    console.log(">>> error--->>", error);
  } finally {
    dispatch(SetQueryLookupLoading(false));
  }
};

// get queue by id
export const gettingQueueById_Call = (id) => async (dispatch, getState) => {
  try {
    dispatch(SetQueueByIdLoading(true));
    let res = await genericAxiosCall(
      `${serverRoutes?.GET_QUEUE_BY_ID}${id}`,
      "get",
      "",
      ""
    );
    return res?.data;
  } catch (error) {
    // console.log("error--->>", error);
  } finally {
    dispatch(SetQueueByIdLoading(false));
  }
};

export const getAllWorkQueues =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    // if (clear === true) {
    //   dispatch(
    //     resetListingState({
    //       listing: "workQueueFilter",
    //       ignore: ["claimStatus,payers,cptCodes,CARC"],
    //     })
    //   );
    // }

    // if (clear === "resetBtn") {
    //   dispatch(
    //     resetListingStateForResetButton({
    //       listing: "workQueueFilter",
    //       ignore: ["claimStatus,payers,cptCodes,CARC,search"],
    //     })
    //   );
    // }
    // let sliceState = getState().npReducers.filters.workQueueFilter;
    // let userId = getState().pReducers?.user?.loginUserData?.userId;

    // let start = sliceState?.pageNumber;
    // let limit = sliceState?.pageSize;

    // if (start >= 2) {
    //   let rec_val = (start - 1) * limit;
    //   start = rec_val;
    // }
    // if (start === 1) {
    //   start = 0;
    // }

    try {
      dispatch(setWorkQueryTabularLoading(true));
      dispatch(toggleQueueForceFlag(Date.now()));

      // console.log("api_call--->>");
      //
      // let paramsTemp = {
      //   ...{
      //     Order: sliceState?.order,
      //   },
      //   ...{
      //     OrderBy: sliceState?.orderBy,
      //   },
      //   ...{
      //     type:
      //       sliceState?.groupByFilter === "ALL"
      //         ? ""
      //         : sliceState?.groupByFilter === "Assigned to Me"
      //         ? ""
      //         : sliceState?.groupByFilter,
      //   },
      //   ...(sliceState?.groupByFilter === "Assigned to Me" && {
      //     assigneeUserId: userId,
      //   }),
      // };
      // let params = {
      //   start,
      //   limit,
      //   ...{
      //     Order: sliceState?.order,
      //   },
      //   ...{
      //     OrderBy: sliceState?.orderBy,
      //   },
      //   ...{
      //     type:
      //       sliceState?.groupByFilter === "ALL"
      //         ? ""
      //         : sliceState?.groupByFilter === "Assigned to Me"
      //         ? ""
      //         : sliceState?.groupByFilter,
      //   },
      //   ...(sliceState?.groupByFilter === "Assigned to Me" && {
      //     assigneeUserId: userId,
      //   }),
      // };
      // // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      // commonPagination(
      //   getState,
      //   paramsTemp,
      //   params,
      //   dispatch,
      //   setAllQueues_Data,
      //   "workQueueFilter",
      //   "GET_ALL_QUEUES",
      //   null
      // );
    } catch (e) {
      // console.log("catch_e --->>", e);

      return false;
    } finally {
      // dispatch(setWorkQueryTabularLoading(false));
    }
  };

// execute query
export const executeWorkQueueQuery_Call =
  (obj) => async (dispatch, getState) => {
    dispatch(setQueryExecuteLoading(true));

    try {
      await genericAxiosCall(`${serverRoutes.EXECUTE_QUERY}`, "POST", obj, "")
        .then((res) => {
          if (res?.data?.success) {
            dispatch(setQueryResultArr(res?.data?.data));
            if (
              res &&
              res?.data &&
              res?.data?.data &&
              !res?.data?.data?.length
            ) {
              // toast.info(`Your selected Query don't have data`, {
              //   position: toast.POSITION.TOP_CENTER,
              // });
            }
          } else {
            dispatch(setQueryResultArr([]));
          }
          return res;
        })
        .catch((err) => {
          dispatch(setQueryResultArr([]));
        });
    } catch (e) {
      // console.log("e--->>", e);
      dispatch(setQueryResultArr([]));
    } finally {
      dispatch(setQueryExecuteLoading(false));
    }
  };

// adding new query
export const addingNewQuery_Call =
  (
    obj,
    handleClose,
    setFormValues,
    setState,
    setIsFormUpdate,
    setSaveBtnDisabled,
    loadInitialData,
    handleCloseBtn,
    loadInitialDataAppeals
  ) =>
  async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.workQueueFilter;

    dispatch(setAddingQueryLoading(true));

    try {
      const resApi = await genericAxiosCall(
        `${serverRoutes.CLAIM_QUEUE_SAVE_QUERY}`,
        "post",
        obj,
        ""
      );

      if (resApi?.data && resApi?.data?.success) {
        toast.success(getShortNameForToastText(resApi?.data?.message, 40, 40), {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(toggleQueueForceFlag(Date.now()));
        setFormValues({
          name: "",
          category: "",
          owner: "",
          noOfRecords: "1000",
          type: "835",
          assignee: "",
          description: "",
          isLiveQueue: false,
          approval: false,
        });

        handleCloseBtn();
        setSaveBtnDisabled(false);
        setIsFormUpdate(false);
        handleClose();
        dispatch(setQueryResultArr([]));
        loadInitialData ? loadInitialData() : null;
        loadInitialDataAppeals ? loadInitialDataAppeals() : null;

        // dispatching All Queue API here
        dispatch(
          setListingState({
            listing: "workQueueFilter",
            value: {
              orderBy: sliceState?.orderBy ? "" : ``,
              // orderBy: sliceState?.orderBy ? "" : `createdOn`,
              order: sliceState?.order ? "" : "desc",
              selectedItem_id: "",
              pageNumber: 0,
            },
          })
        );

        dispatch(getAllWorkQueues());
      } else {
        setSaveBtnDisabled(false);

        if (resApi?.data?.message.includes("same name")) {
          dispatch(setQueryResultArr([]));
        }

        toast.error(
          resApi?.data?.message
            ? resApi?.data?.message
            : "Something went wrong",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      }
      return resApi;
    } catch (e) {
      // console.log("addingNewQuery_Call_e--->>", e);
    } finally {
      dispatch(setAddingQueryLoading(false));
    }
  };

export const getAllDefaultColumns = () => {
  return genericAxiosCall(serverRoutes?.GET_ALL_DEFAULT_COLUMNS, "get", "", "");
};

// adding new View
export const addingNewView_Call =
  (obj, handleClose, setState, setViewName, parentId, viewId, viewType) =>
  async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.workQueueDetailFilter;

    dispatch(setAddingViewLoading(true));

    try {
      genericAxiosCall(
        `${serverRoutes.CLAIM_QUEUE_SAVE_QUERY}`,
        "post",
        obj,
        ""
      )
        .then((res) => {
          if (res?.data?.success) {
            handleClose();
            setState({
              queryJsonFormat: "",
              actualQuery: "",
              querySqlFormat: "",
            });
            setViewName("");
            dispatch(setQueryResultArr([]));
            dispatch(gettingAllViewsList_Call(parentId));
            dispatch(
              setListingState({
                listing: "workQueueDetailFilter",
                value: {
                  orderBy: "dos",
                  order: sliceState?.order
                    ? sliceState?.order === "asc"
                      ? "desc"
                      : "asc"
                    : "asc",
                  pageNumber: 1,
                },
              })
            );

            dispatch(
              getSpecificWorkQueue({
                id: viewId,
                type: viewType,
                isFilter: false,
                WhereSQL: "",
                WhereJson: "",
              })
            );
          } else {
            toast.error(
              res?.data?.message ? res?.data?.message : "Something went wrong",
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
            dispatch(emptyQueryResultArr());
          }
          // return res?.data;
        })
        .catch((err) => {
          // console.log("err--->>", err);
          toast.error(`${err?.response?.data?.message}`, {
            position: toast.POSITION.TOP_CENTER,
          });
          dispatch(emptyQueryResultArr());
        });
    } catch (e) {
      // console.log("e--->>", e);
    } finally {
      dispatch(setAddingViewLoading(false));
    }
  };

// get all view list
export const gettingAllViewsList_Call = (id) => async (dispatch, getState) => {
  try {
    dispatch(SetAllViewsListLoading(true));
    await genericAxiosCall(
      `${serverRoutes?.GET_ALL_VIEWS_LIST}${id}`,
      "get",
      "",
      ""
    )
      .then((res) => {
        if (res?.data?.success) {
          dispatch(setAllViews_Data(res?.data?.data));
        } else {
          dispatch(setAllViews_Data([]));
        }
        return res;
      })
      .catch((err) => {
        // console.log("err--->>", err);
      });
  } catch (error) {
    // console.log("error--->>", error);
  } finally {
    dispatch(SetAllViewsListLoading(false));
  }
};

// get specific queue by id
export const getSpecificWorkQueue =
  ({
    noReload = false,
    id,
    type,
    isFilter,
    WhereSQL,
    WhereJson,
    AppealStatus,
    isAppeals,
    timestamp = 0,
  }) =>
  async (dispatch, getState) => {
    // appealsDetailFilterNoOfRecordInTable: 10,
    // workQueueDetailFilterFilterNoOfRecordInTable: 10,

    let sliceStateUser = getState().pReducers.user;
    let sliceState =
      getState().npReducers.filters[
        isAppeals ? "appealsDetailFilter" : "workQueueDetailFilter"
      ];
    let sliceStateForDate =
      getState().npReducers.filters.denialSreensCommonFilter;
    let start = sliceState?.pageNumber;
    // let limit = sliceState?.pageSize;
    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable[
        isAppeals
          ? "appealsDetailFilterNoOfRecordInTable"
          : "workQueueDetailFilterFilterNoOfRecordInTable"
      ];

    // console.log("getSpecificWorkQueue_sliceState--->>", sliceState);

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    try {
      // =======================
      let paramsTemp = {
        WorkQueueId: isFilter ? 0 : id,
        type: type === "837 AI" ? "AI 837" : type,
        NoOfRecords: isFilter ? "1000" : "",
        WhereSQL: isFilter ? WhereSQL : "",
        WhereJson: isFilter ? WhereJson : "",
        OrderBy: sliceState?.orderBy,
        Order: sliceState?.order,
        QueueState: sliceState?.QueueState,
        RunById: isFilter ? false : true,
        ReturnCount: false,
        ...(sliceState?.fetchLatest === true && {
          timestamp: new Date().getTime(),
        }), // Add a timestamp if fetchLatest is true
        AppealStatus: AppealStatus ? AppealStatus : null,
        Filter:
          !sliceState?.search && sliceStateForDate?.to !== ""
            ? { to: sliceStateForDate.to }
            : "",
        // Conditionally add FilterView if isAppeals is true
        ...(!isAppeals && {
          FilterView: sliceState?.groupBy ? sliceState?.groupBy : "",
        }),
      };

      let params = {
        start: start,
        limit: limit,
        WorkQueueId: isFilter ? 0 : id,
        type: type === "837 AI" ? "AI 837" : type,
        NoOfRecords: isFilter ? "1000" : "",
        WhereSQL: isFilter ? WhereSQL : "",
        WhereJson: isFilter ? WhereJson : "",
        OrderBy: sliceState?.orderBy,
        Order: sliceState?.order,
        QueueState:
          typeof sliceState?.QueueState == "string"
            ? sliceState?.QueueState
            : "",
        RunById: isFilter ? false : true,
        ReturnCount: false,
        AppealStatus: AppealStatus ? AppealStatus : null,
        Filter: sliceState?.search ? sliceState?.search : "",
        // Conditionally add FilterView if isAppeals is true
        ...(!isAppeals && {
          FilterView: sliceState?.groupBy ? sliceState?.groupBy : "",
        }),
      };
      // console.log("getSpecificWorkQueue_params--->>", params);
      let res = await commonPaginationForPost(
        getState,
        paramsTemp,
        params,
        dispatch,
        setSpecificQueueDetail,
        isAppeals ? "appealsDetailFilter" : "workQueueDetailFilter",
        "EXECUTE_QUERY",
        null,
        null,
        null,
        noReload
      );

      if (res?.success && sliceState?.lastMovedWorkQueueId !== "") {
        dispatch(
          afterMoveWorkQueueTriggerExecute_Call({ isAppeals, isFilter, type })
        );
      }
    } catch (e) {
      console.log("catch_e --->>", e);
    } finally {
      dispatch(
        setListingState({
          listing: isAppeals ? "appealsDetailFilter" : "workQueueDetailFilter",
          value: {
            fetchLatest: false,
          },
        })
      );
    }
  };

export const getSpecificMyTask = (type) => async (dispatch, getState) => {
  let sliceState =
    getState().npReducers.filters?.[
      type === "835" ? "myTasksFilter835" : "myTasksFilter837"
    ];

  let sliceStateUser = getState().pReducers.user;

  let start = sliceState?.pageNumber;
  // let limit = sliceState?.pageSize;

  let limit =
    sliceStateUser?.globalObjNoOfRecordInTable[
      type === "835"
        ? "myTasksFilter835NoOfRecordInTable"
        : "myTasksFilter837NoOfRecordInTable"
    ];

  // console.log("limit--->>", limit);

  if (start >= 2) {
    let rec_val = (start - 1) * limit;
    // start = rec_val + 1;
    start = rec_val;
  }
  if (start === 1) {
    start = 0;
  }

  try {
    // =======================
    let paramsTemp = {
      OrderBy: sliceState?.orderBy,
      Order: sliceState?.order,
      Assignee: getUserId(),
      Filter: sliceState?.groupBy,
      ...(sliceState?.fetchLatest === true && {
        timestamp: new Date().getTime(),
      }), // Add a timestamp if fetchLatest is true
      Type: type,
      QueueState: sliceState?.queueState,
    };

    let params = {
      start: start,
      limit: limit,
      OrderBy: sliceState?.orderBy,
      Order: sliceState?.order,
      Assignee: getUserId(),
      Filter: sliceState?.groupBy, //Pastdue
      Type: type,
      QueueState: sliceState?.queueState,
    };
    // console.log("getSpecificWorkQueue--->>", params);
    commonPaginationForPost(
      getState,
      paramsTemp,
      params,
      dispatch,
      type === "835" ? setSpecificTaskDetail835 : setSpecificTaskDetail837,
      type === "835" ? "myTasksFilter835" : "myTasksFilter837",
      "EXECUTE_QUERY_MY_TASKS",
      null
    );
  } catch (e) {
  } finally {
    dispatch(
      setListingState({
        listing: type === "835" ? "myTasksFilter835" : "myTasksFilter837",
        value: {
          fetchLatest: false,
        },
      })
    );
  }
};
// get specific queue by id
export const getMovedClaims =
  ({
    id,
    type,
    isFilter,
    WhereSQL,
    WhereJson,
    AppealStatus,
    isAppeals,
    timestamp = 0,
  }) =>
  async (dispatch, getState) => {
    let sliceState =
      getState().npReducers.filters[
        isAppeals ? "appealsDetailFilter" : "workQueueDetailFilter"
      ];
    let start = sliceState?.pageNumber;
    let limit = sliceState?.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }
    let userId = getState().pReducers?.user?.loginUserData?.userId;
    try {
      // =======================
      let paramsTemp = {
        WorkQueueId: isFilter ? 0 : id,
        type: type === "837 AI" ? "AI 837" : type,
        transferBy: userId,
        // NoOfRecords: isFilter ? "1000" : "",
        // WhereSQL: isFilter ? WhereSQL : "",
        // WhereJson: isFilter ? WhereJson : "",
        // OrderBy: sliceState?.orderBy,
        // Order: sliceState?.order,
        // QueueState: sliceState?.QueueState,
        // RunById: isFilter ? false : true,
        // ReturnCount: false,
        ...(sliceState?.fetchLatest === true && {
          timestamp: new Date().getTime(),
        }), // Add a timestamp if fetchLatest is true
        // AppealStatus: AppealStatus ? AppealStatus : null,
      };

      let params = {
        start: start,
        limit: limit,
        transferedFromId: isFilter ? 0 : id,
        type: type === "837 AI" ? "AI 837" : type,
        transferBy: userId,
        // NoOfRecords: isFilter ? "1000" : "",
        // WhereSQL: isFilter ? WhereSQL : "",
        // WhereJson: isFilter ? WhereJson : "",
        // OrderBy: sliceState?.orderBy,
        // Order: sliceState?.order,
        // QueueState: sliceState?.QueueState,
        // RunById: isFilter ? false : true,
        // ReturnCount: false,
        // AppealStatus: AppealStatus ? AppealStatus : null,
      };

      let res = await commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setSpecificQueueDetail,
        isAppeals ? "appealsDetailFilter" : "workQueueDetailFilter",
        "GET_MOVED_CLAIMS",
        null
      );

      // if (res?.success && sliceState?.lastMovedWorkQueueId !== "") {
      //   dispatch(
      //     afterMoveWorkQueueTriggerExecute_Call({ isAppeals, isFilter, type })
      //   );
      // }
    } catch (e) {
      console.log("catch_e --->>", e);
    } finally {
      dispatch(
        setListingState({
          listing: isAppeals ? "appealsDetailFilter" : "workQueueDetailFilter",
          value: {
            fetchLatest: false,
          },
        })
      );
    }
  };
// get specific queue by id2
export const getSpecificWorkQueue2 =
  ({ id, type, isFilter, WhereSQL, WhereJson, AppealStatus }) =>
  async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.workQueueDetailFilter;
    let start = sliceState?.pageNumber;
    let limit = sliceState?.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    try {
      // =======================
      let paramsTemp = {
        WorkQueueId: isFilter ? 0 : id,
        type: type === "837 AI" ? "AI 837" : type,
        NoOfRecords: isFilter ? "1000" : "",
        WhereSQL: isFilter ? WhereSQL : "",
        WhereJson: isFilter ? WhereJson : "",
        OrderBy: sliceState?.orderBy,
        Order: sliceState?.order,
        RunById: isFilter ? false : true,
        AppealStatus: AppealStatus,
        ReturnCount: false,
        // date: new Date(),
      };

      let params = {
        start: start,
        limit: limit,
        WorkQueueId: isFilter ? 0 : id,
        type: type === "837 AI" ? "AI 837" : type,
        NoOfRecords: isFilter ? "1000" : "",
        WhereSQL: isFilter ? WhereSQL : "",
        WhereJson: isFilter ? WhereJson : "",
        OrderBy: sliceState?.orderBy,
        Order: sliceState?.order,
        AppealStatus: AppealStatus,
        RunById: isFilter ? false : true,
        ReturnCount: false,
      };
      commonPaginationForPost(
        getState,
        paramsTemp,
        params,
        dispatch,
        setSpecificQueueDetail,
        "workQueueDetailFilter",
        "EXECUTE_QUERY",
        null
      );
    } catch (e) {
      // console.log("catch_e --->>", e);
    }
  };

// delete item work queue
export const afterMoveWorkQueueTriggerExecute_Call =
  ({ isAppeals, isFilter, type }) =>
  async (dispatch, getState) => {
    let sliceState =
      getState().npReducers.filters[
        isAppeals ? "appealsDetailFilter" : "workQueueDetailFilter"
      ];
    let start = sliceState?.pageNumber;
    let limit = sliceState?.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    let params = {
      start: start,
      limit: limit,
      WorkQueueId: sliceState?.lastMovedWorkQueueId,
      type: type === "837 AI" ? "AI 837" : type,
      NoOfRecords: isFilter ? "1000" : "",
      WhereSQL: "",
      WhereJson: "",
      OrderBy: sliceState?.orderBy,
      Order: sliceState?.order,
      QueueState: sliceState?.QueueState,
      RunById: isFilter ? false : true,
      ReturnCount: false,
      AppealStatus: null,
    };

    try {
      genericAxiosCall(`${serverRoutes.EXECUTE_QUERY}`, "post", params, "")
        .then((res) => {
          // console.log("res--->>", res?.data);

          if (res?.data?.success) {
          }
        })
        .catch((error) => {
          // console.log("error--->>", error);
        });
    } catch (e) {
      // console.log("err--->>", e);
    } finally {
      dispatch(
        setListingState({
          listing: "workQueueDetailFilter",
          value: {
            lastMovedWorkQueueId: "",
          },
        })
      );
      dispatch(
        setListingState({
          listing: "appealsDetailFilter",
          value: {
            lastMovedWorkQueueId: "",
          },
        })
      );
    }
  };

// delete item work queue
export const deleteWorkQueueItem_Call =
  (id, handleCloseDeleteModal, setState, setFormValues, setIsFormUpdate) =>
  async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.workQueueFilter;

    try {
      setDeleteItemLoading(true);
      genericAxiosCall(
        `${serverRoutes.DELETE_WORK_QUEUE_ITEM}${id}`,
        "delete",
        "",
        ""
      )
        .then((res) => {
          toast.success("Deleted Successfully", {
            position: toast.POSITION.TOP_CENTER,
          });

          if (res?.data?.success) {
            dispatch(
              setListingState({
                listing: "workQueueFilter",
                value: {
                  orderBy: sliceState?.orderBy ? "" : `createdOn`,
                  order: sliceState?.order ? "" : "desc",
                  selectedItem_id: "",
                  pageNumber: 1,
                },
              })
            );
            setIsFormUpdate(false);
            handleCloseDeleteModal(false);
            dispatch(getAllWorkQueues());

            setFormValues({
              name: "",
              category: "",
              owner: "",
              noOfRecords: "1000",
              type: "835",
              assignee: "",
              description: "",
              isLiveQueue: false,
            });
            setState({
              actualQuery: "",
            });
          }

          // return res;
        })
        .catch((error) => {
          toast.error(
            error.response.data.message
              ? error.response.data.message
              : "Something went wrong",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        });
    } catch (e) {
      // console.log("err--->>", e);
    } finally {
      setDeleteItemLoading(false);
    }
  };

// delete view item
export const deleteViewItem_Call =
  (id, setDeleteView) => async (dispatch, getState) => {
    try {
      setDeleteViewItemLoading(true);
      let res = await genericAxiosCall(
        `${serverRoutes.DELETE_WORK_QUEUE_ITEM}${id}`,
        "delete",
        "",
        ""
      );
      return res;
    } catch (e) {
      // console.log("e--->>", e);
    } finally {
      setDeleteViewItemLoading(false);
    }
  };

// get buckets n queue for movable claim
export const getMoveClaimDropdownData =
  (transType) => async (dispatch, getState) => {
    try {
      await dispatch(gettingAllLookUptData_Call());
      let sliceState = getState().npReducers.claimQueue;
      const { transactionTypeListData } = sliceState;
      const selectedTransType = transactionTypeListData?.filter(
        (item) => item.label == transType
      );

      const params = {
        TransactionTypeID: selectedTransType[0]?.value,
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_MOVE_CLAIM_DROPDOWN_DATA,
        "get",
        "",
        params
      );

      dispatch(setMovingClaimBucketsData(res?.data?.data?.queueBuckets));
    } catch (err) {
      console.log("err--->>", err);
    }
  };

// get buckets for move on 835 screen
export const getBucketDropdownData835 =
  (transType) => async (dispatch, getState) => {
    try {
      await dispatch(gettingAllLookUptData_Call());
      let sliceState = getState().npReducers.claimQueue;
      let sliceStateForClientId =
        getState().npReducers.filters.insightsSreensCommonFilter;
      let sliceStateUser = getState().pReducers.user;

      const { transactionTypeListData } = sliceState;

      const selectedTransType = transactionTypeListData?.filter(
        (item) => item.label == transType
      );

      const params = {
        TransactionTypeID: selectedTransType[0]?.value,
        // ...(sliceStateUser?.loginUserData?.isRcm && {
        //   clientId:
        //     Array.isArray(sliceStateForClientId?.clientId) &&
        //     sliceStateForClientId?.clientId.length > 0
        //       ? sliceStateForClientId?.clientId[0].value
        //       : sliceStateForClientId?.clientId || getCurrentClientId(),
        // }),
        // ...(from !== "" && {
        //   from: from,
        // }),
        // ...(to !== "" && {
        //   to: to,
        // }),
      };
      const paramsTemp = {
        TransactionTypeID: selectedTransType[0]?.value,
        // ...(sliceStateUser?.loginUserData?.isRcm && {
        //   clientId:
        //     Array.isArray(sliceStateForClientId?.clientId) &&
        //     sliceStateForClientId?.clientId.length > 0
        //       ? sliceStateForClientId?.clientId[0].value
        //       : sliceStateForClientId?.clientId || getCurrentClientId(),
        // }),
        // ...(from !== "" && {
        //   from: from,
        // }),
        // ...(to !== "" && {
        //   to: to,
        // }),
      };

      // getBucketDropdownData835Filter

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setMovingClaimBucketsData835,
        "getBucketDropdownData835Filter",
        "GET_MOVE_CLAIM_DROPDOWN_DATA",
        null
      );
    } catch (err) {
      // console.log("err--->>", err);
    }
  };

// move a claim
export const moveClaim =
  (
    type,
    workQueueId,
    queueId,
    userId,
    claimId,
    handleClick,
    loadData,
    isMulti,
    setActiveBtn,
    isAppealSelected,
    tabularArrLength,
    pageSize,
    selectedCheckboxes,
    dispatchFunc
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch(setMoveClaimLoader(true));
      // await dispatch(gettingAllLookUptData_Call());
      let params = {
        WorkQueueId: workQueueId,
        ClaimId: claimId, //claimRequestId || claimPaymentId
        TransferedFromId: queueId,
        ClaimType: type,
        TransferBy: userId,
        // status: isAppealSelected ? "Appeal" : null,
      };
      if (isMulti) {
        params = {
          WorkQueueId: workQueueId,
          ClaimIds: claimId, //claimRequestId || claimPaymentId
          TransferedFromId: queueId,
          ClaimType: type,
          TransferBy: userId,
          // status: isAppealSelected ? "Appeal" : null,
        };
      }

      const res = await genericAxiosCall(
        isMulti ? serverRoutes?.MOVE_CLAIM_BULK : serverRoutes?.MOVE_CLAIM,
        "post",
        params,
        ""
      );

      if (res?.data?.success == true) {
        dispatch(setMoveClaimLoader(false));
        dispatch(
          setListingState({
            listing: "workQueueDetailFilter",
            value: {
              lastMovedWorkQueueId: workQueueId,
            },
          })
        );
        dispatch(
          setListingState({
            listing: "appealsDetailFilter",
            value: {
              lastMovedWorkQueueId: workQueueId,
            },
          })
        );

        if (pageSize === selectedCheckboxes?.length) {
          dispatch(
            setListingState({
              listing: "workQueueDetailFilter",
              value: {
                pageNumber: 1,
              },
            })
          );
          dispatch(
            setListingState({
              listing: "appealsDetailFilter",
              value: {
                pageNumber: 1,
              },
            })
          );
        }

        if (tabularArrLength) {
          if (tabularArrLength === 1) {
            dispatch(
              setListingState({
                listing: "workQueueDetailFilter",
                value: {
                  pageNumber: 1,
                },
              })
            );
            dispatch(
              setListingState({
                listing: "appealsDetailFilter",
                value: {
                  pageNumber: 1,
                },
              })
            );
          }
        }

        handleClick();
        setActiveBtn && setActiveBtn(1);
        if (dispatchFunc) {
          dispatch(loadData());
        } else {
          loadData();
        }
        // toast.success(`Claim${isMulti ? "s" : ""} Moved`);
        toast.success(res?.data?.message);
        dispatch(toggleQueueForceFlag(Date.now()));

        //just for hard reload to appeals screen
        dispatch(
          setListingState({
            listing: "appealsDetailFilter",
            value: {
              fetchLatest: true,
            },
          })
        );
        dispatch(
          setListingState({
            listing: "workQueueDetailFilter",
            value: {
              fetchLatest: true,
            },
          })
        );

        return res?.data?.data;
      } else {
        dispatch(setMoveClaimLoader(false));
      }
    } catch (error) {
      // console.log("err0r--->>", error);
      dispatch(setMoveClaimLoader(false));
      handleClick();
      toast.error(error?.response?.data?.message);
      // console.log("123123 err", error);
    }
  };
export const moveClaimERA =
  (
    type,
    workQueueId,
    queueId,
    userId,
    claimId,
    handleClick,
    loadData,
    isMulti,
    setActiveBtn,
    isAppealSelected,
    tabularArrLength,
    pageSize,
    selectedCheckboxes,
    dispatchFunc,
    target,
    setLoading,
    setSelected
  ) =>
  async (dispatch, getState) => {
    try {
      setLoading(true);
      // await dispatch(gettingAllLookUptData_Call());
      let params = {
        WorkQueueId: workQueueId,
        ClaimId: claimId, //claimRequestId || claimPaymentId
        TransferedFromId: queueId,
        ClaimType: type,
        TransferBy: userId,
        // status: isAppealSelected ? "Appeal" : null,
      };
      if (isMulti) {
        params = {
          WorkQueueId: workQueueId,
          ClaimIds: claimId, //claimRequestId || claimPaymentId
          TransferedFromId: queueId,
          ClaimType: type,
          TransferBy: userId,
          // status: isAppealSelected ? "Appeal" : null,
        };
      }

      const res = await genericAxiosCall(
        isMulti ? serverRoutes?.MOVE_CLAIM_BULK : serverRoutes?.MOVE_CLAIM,
        "post",
        params,
        ""
      );

      if (res?.data?.success == true) {
        setLoading(false);
        dispatch(
          setListingState({
            listing: target,
            value: {
              lastMovedWorkQueueId: workQueueId,
            },
          })
        );
        dispatch(
          setListingState({
            listing: target,
            value: {
              lastMovedWorkQueueId: workQueueId,
            },
          })
        );

        if (pageSize === selectedCheckboxes?.length) {
          dispatch(
            setListingState({
              listing: target,
              value: {
                pageNumber: 1,
              },
            })
          );
        }

        if (tabularArrLength) {
          if (tabularArrLength === 1) {
            dispatch(
              setListingState({
                listing: target,
                value: {
                  pageNumber: 1,
                },
              })
            );
          }
        }

        handleClick();
        setActiveBtn && setActiveBtn(1);
        setSelected([]);

        if (dispatchFunc) {
          dispatch(loadData());
        } else {
          loadData();
        }
        // toast.success(`Claim${isMulti ? "s" : ""} Moved`);
        toast.success(res?.data?.message);
        // dispatch(toggleQueueForceFlag(Date.now()));

        //just for hard reload to appeals screen
        dispatch(
          setListingState({
            listing: target,
            value: {
              fetchLatest: true,
            },
          })
        );

        return res?.data?.data;
      } else {
        setLoading(false);
      }
    } catch (error) {
      // console.log("err0r--->>", error);
      setLoading(false);
      handleClick();
      toast.error(error?.response?.data?.message);
      // console.log("123123 err", error);
    }
  };
// change claim status
export const changeClaimStatus =
  (
    workQueueId,
    transferedFromId,
    claimType,
    status,
    claimId,
    transferBy,
    handleClick,
    loadData,
    multi,
    setSelectedCheckboxes,
    isAppealForLen
  ) =>
  async (dispatch, getState) => {
    try {
      let sliceState =
        getState().npReducers.filters[
          // isAppealForLen ? "appealsDetailFilter" : "workQueueDetailFilter"
          "workQueueDetailFilter"
        ];

      dispatch(setStateChangeLoader(true));
      await dispatch(gettingAllLookUptData_Call());
      let params = {
        WorkQueueId: workQueueId,
        TransferedFromId: transferedFromId,
        ClaimType: claimType,
        Status: status,
        ClaimId: claimId,
        TransferBy: transferBy,
      };
      if (multi) {
        params = {
          WorkQueueId: workQueueId,
          TransferedFromId: transferedFromId,
          ClaimType: claimType,
          Status: status,
          ClaimIds: multi ? claimId : claimId,
          TransferBy: transferBy,
        };
      }

      const res = await genericAxiosCall(
        multi
          ? serverRoutes?.MOVE_CLAIM_BULK
          : serverRoutes?.CHANGE_CLAIM_STATUS,
        "post",
        params,
        ""
      );

      if (res?.data?.success == true) {
        dispatch(setStateChangeLoader(false));
        setSelectedCheckboxes && setSelectedCheckboxes([]);

        if (sliceState?.tabulrArrayLength) {
          if (sliceState?.tabulrArrayLength === 1) {
            dispatch(
              setListingState({
                listing: "workQueueDetailFilter",
                value: {
                  pageNumber: 1,
                },
              })
            );
          }
        }

        handleClick();
        loadData(true);
        // toast.success("Claim Status Changed");
        setSelectedCheckboxes && setSelectedCheckboxes([]);

        return res?.data?.data;
      } else {
        dispatch(setStateChangeLoader(false));

        // console.log("123123 res", res?.data);
      }
    } catch (error) {
      // console.log("error--->>", error);
      dispatch(setStateChangeLoader(false));

      handleClick();
      // console.log("123123 err", error);
      toast.error(error?.response?.data?.message);
    }
  };

// change claim priority
export const changeClaimPriority =
  (claimType, status, claimId, handleClick, loadData, setSelectedCheckboxes) =>
  async (dispatch, getState) => {
    try {
      let sliceState = getState().npReducers.filters["workQueueDetailFilter"];
      dispatch(setPriorityChangeLoader(true));
      await dispatch(gettingAllLookUptData_Call());
      let params = {
        ClaimType: claimType,
        Priority: status,
        ClaimId: claimId,
      };

      const res = await genericAxiosCall(
        serverRoutes?.CHANGE_CLAIM_PRIORITY,
        "post",
        params,
        ""
      );

      if (res?.data?.success == true) {
        dispatch(setPriorityChangeLoader(false));
        setSelectedCheckboxes && setSelectedCheckboxes([]);

        // if (sliceState?.tabulrArrayLength) {
        //   if (sliceState?.tabulrArrayLength === 1) {
        //     dispatch(
        //       setListingState({
        //         listing: "workQueueDetailFilter",
        //         value: {
        //           pageNumber: 1,
        //         },
        //       })
        //     );
        //   }
        // }

        handleClick();
        loadData(true);
        setSelectedCheckboxes && setSelectedCheckboxes([]);

        return res?.data?.data;
      } else {
        dispatch(setPriorityChangeLoader(false));
      }
    } catch (error) {
      dispatch(setPriorityChangeLoader(false));
      handleClick();
      toast.error(error?.response?.data?.message);
    }
  };
export const changeClaimStatusWithTrigger =
  (
    workQueueId,
    transferedFromId,
    claimType,
    status,
    claimId,
    transferBy,
    handleClick,
    loadData,
    multi,
    setSelectedCheckboxes,
    triggerFollowup
  ) =>
  async (dispatch, getState) => {
    try {
      let sliceState =
        getState().npReducers.filters[
          // isAppealForLen ? "appealsDetailFilter" : "workQueueDetailFilter"
          "workQueueDetailFilter"
        ];

      dispatch(setStateChangeLoader(true));
      await dispatch(gettingAllLookUptData_Call());
      let params = {
        WorkQueueId: workQueueId,
        TransferedFromId: transferedFromId,
        ClaimType: claimType,
        Status: status,
        ClaimId: claimId,
        TransferBy: transferBy,
      };
      if (multi) {
        params = {
          WorkQueueId: workQueueId,
          TransferedFromId: transferedFromId,
          ClaimType: claimType,
          Status: status,
          ClaimIds: multi ? claimId : claimId,
          TransferBy: transferBy,
        };
      }
      await triggerFollowup(status);
      const res = await genericAxiosCall(
        multi
          ? serverRoutes?.MOVE_CLAIM_BULK
          : serverRoutes?.CHANGE_CLAIM_STATUS,
        "post",
        params,
        ""
      );

      if (res?.data?.success == true) {
        dispatch(setStateChangeLoader(false));
        setSelectedCheckboxes && setSelectedCheckboxes([]);

        if (sliceState?.tabulrArrayLength) {
          if (sliceState?.tabulrArrayLength === 1) {
            dispatch(
              setListingState({
                listing: "workQueueDetailFilter",
                value: {
                  pageNumber: 1,
                },
              })
            );
          }
        }

        handleClick();
        loadData(true);
        // toast.success("Claim Status Changed");
        setSelectedCheckboxes && setSelectedCheckboxes([]);

        return res?.data?.data;
      } else {
        dispatch(setStateChangeLoader(false));

        // console.log("123123 res", res?.data);
      }
    } catch (error) {
      console.log("error--->>", error);
      dispatch(setStateChangeLoader(false));

      handleClick();
      // console.log("123123 err", error);
      toast.error(error?.response?.data?.message);
    }
  };

// execute Query builder
export const executeQuery = (obj) => async (dispatch, getState) => {
  try {
    dispatch(setQueryExecuteLoading(true));

    const res = await genericAxiosCall(
      serverRoutes.EXECUTE_QUERY,
      "post",
      obj,
      ""
    );

    if (res?.data?.success == true) {
      dispatch(setQueryResultArr(res?.data?.data));
      dispatch(setQueryExecuteLoading(false));

      return res?.data?.data;
    } else {
      dispatch(setQueryExecuteLoading(false));
    }
  } catch (error) {
    dispatch(setQueryExecuteLoading(false));
    toast.error(
      "We apologize for the inconvenience. The requested data is taking longer to retrieve the data than expected. "
    );
    return null;
  }
};

// get appeals data
export const getAppealsQueue =
  (appealsPage, appealsLimit, queueForceFlag, orderBy, order) =>
  async (dispatch, getState) => {
    // console.log("getAppealsQueue--->>");
    let sliceState = getState().npReducers.filters.appealsQueueFilter;

    let limit = sliceState.pageSize;
    let sliceState_hasMoreDataOnPage = sliceState.hasMoreDataOnPage;
    let start = sliceState.pageNumber;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }
    // console.log(
    //   "sliceState_hasMoreDataOnPage--->>",
    //   sliceState_hasMoreDataOnPage
    // );

    try {
      dispatch(
        setListingState({
          listing: "appealsQueueFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );
      dispatch(setAppealsLoader(true));

      const params = {
        time: new Date().getTime(),
        // start: sliceState_hasMoreDataOnPage === true ? 1 : start,
        start: start,
        limit: limit,
        orderBy: sliceState.orderBy,
        order: sliceState.order,
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_APPEALS,
        "get",
        "",
        params
      );

      if (res?.data?.success == true) {
        // dispatch(setBucketsList(res?.data?.data));
        dispatch(setAppealsLoader(false));
        if (res?.data?.data?.length === 0) {
          // Dispatch pageNumber as 1 using sliceState.pageNumber
          dispatch(
            setListingState({
              listing: "appealsQueueFilter",
              value: {
                pageNumber: 1,
              },
            })
          );
        }
        // dispatch(
        //   setListingState({
        //     listing: "appealsQueueFilter",
        //     value: {
        //       hasMoreDataOnPage: res?.data?.data?.length === 1 ? true : false,
        //     },
        //   })
        // );
        return res?.data?.data;
      } else {
        dispatch(setAppealsLoader(false));
      }
    } catch (err) {
      // console.log("err--->>", err);
    } finally {
      dispatch(
        setListingState({
          listing: "appealsQueueFilter",
          value: {
            loadingMore: "",
          },
        })
      );
    }
  };

// get attachments against claim
export const getAttachmentsAgainstClaims =
  (claimPaymentId) => async (dispatch, getState) => {
    try {
      dispatch(setClaimAttachmentsLoader(true));
      dispatch(getGlossary(claimPaymentId));

      let sliceState = getState().npReducers.claimQueue;
      const { insuranceFormData } = sliceState;

      const params = {
        claimPaymentId: claimPaymentId,
        time: new Date(),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_ATTACHMENTS,
        "get",
        "",
        params
      );

      const flags = res?.data?.data?.appealFlags?.[0];
      const payerFormFlag = res?.data?.data?.flagType;
      const payerFormName = res?.data?.data?.flagName;

      if (res?.data?.success == true) {
        dispatch(setCurrentClaimAttachmentFlags(flags));
        dispatch(
          setCurrentClaimFormFlag(payerFormFlag > 0 ? payerFormFlag : null)
        );
        dispatch(setCurrentClaimFormName(payerFormName));
        let payerData = res?.data?.data?.payer?.[0];
        dispatch(setClaimAttachmentsPayer(payerData));

        // console.log("row data api if-- ", res?.data?.data?.attachments);
        if (!payerFormFlag) {
          await dispatch(getResetInsuranceFormData(claimPaymentId));
          dispatch(setClaimAttachmentsLoader(false));
          let data = res?.data?.data?.attachments;
          dispatch(setClaimAttachmentsData(data));
        } else {
          await dispatch(getInsuranceFormData(claimPaymentId));

          let customFormData = {
            customForm: true,
            fileName: "Insurance Cover Form",
            ...insuranceFormData,
          };
          let data;
          if (res?.data?.data?.attachments) {
            // console.log("123123 data --- ", res?.data?.data);
            if (
              res?.data?.data?.attachments?.[0]?.fileName == "Appeal letter.txt"
            ) {
              // console.log(
              //   "123123 dataSecond --- ",
              //   res?.data?.data?.attachments
              // );

              data = [customFormData, ...res?.data?.data?.attachments];
              // data?.splice(1, 0, customFormData);
              // console.log("123123 dataThird --- ", data);
            } else {
              data = [customFormData, ...res?.data?.data?.attachments];
            }
          } else {
            data = [customFormData];
          }
          // console.log("123123 dataFourth --- ", data);

          dispatch(setClaimAttachmentsData(data));
          dispatch(setClaimAttachmentsLoader(false));
          // let data = [customFormData, ...res?.data?.data];
        }
      } else {
        // let data = res?.data?.data;
        // console.log("123123 test -- ", currentRow);
        if (!payerFormFlag) {
          dispatch(setClaimAttachmentsData(null));
          dispatch(setClaimAttachmentsPayer(null));
          dispatch(setClaimAttachmentsLoader(false));
        } else {
          let customFormData = {
            customForm: true,
            // fileName: currentRow?.isAetnaForm ? "Aetna" : "BlueCross",
            fileName: "Insurance Cover Form",
            ...insuranceFormData,
          };
          let data;
          if (res?.data?.data?.attachments) {
            data = [customFormData, ...res?.data?.data?.attachments];
          } else {
            data = [customFormData];
          }
          dispatch(setClaimAttachmentsData(data));
          dispatch(setClaimAttachmentsLoader(false));
        }
      }
    } catch (error) {
      // console.log("123123 err ---", error);
    }
  };

// upload attachments against claim
export const uploadAttachmentsAgainstClaims =
  (
    claimPaymentId,
    filesToUpload,
    uid,
    newhandleClose,
    fromAppealLetter,
    customSuccessMessage,
    setOpenEditModal
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch(setConfirmEditLoader(true));
      let formData = new FormData();
      let sliceState = getState().npReducers.claimQueue;

      const { currentClaimAttachmentFlags, currentClaimFormFlag } = sliceState;

      // console.log("files to up ", filesToUpload);
      if (fromAppealLetter) {
        formData.append(`files`, filesToUpload, "Appeal letter.txt");
      } else {
        filesToUpload.forEach((document, index) => {
          formData.append(`files`, document?.originFileObj);
        });
      }
      formData.append(`ClaimPaymentId`, claimPaymentId);
      formData.append(
        `FileNames`,
        fromAppealLetter
          ? "Appeal Letter.txt"
          : filesToUpload
              ?.map((item) => {
                return item?.name;
              })
              .join(",")
      );
      formData.append(`createdBy`, uid);

      const res = await genericAxiosCallFormData(
        serverRoutes?.UPLOAD_ATTACHMENTS,
        "post",
        formData,
        ""
      );
      if (res?.data?.success == true) {
        dispatch(setConfirmEditLoader(false));
        const params = {
          claimPaymentId: claimPaymentId,
          isLetterGenerated: fromAppealLetter
            ? true
            : currentClaimAttachmentFlags?.isLetterGenerated
            ? currentClaimAttachmentFlags?.isLetterGenerated
            : false,
          isAppealFormGenerated:
            currentClaimAttachmentFlags?.isAppealFormGenerated,
          isAppealWorkCompleted:
            currentClaimAttachmentFlags?.isAppealWorkCompleted,
          flagType: currentClaimFormFlag,
          isAttachmentAvailable: true,
        };
        dispatch(updateAppealFormFlags(params));

        toast.success(
          `${customSuccessMessage ? customSuccessMessage : res?.data?.message}`,
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        setOpenEditModal(false);
        newhandleClose && newhandleClose();

        await dispatch(getAttachmentsAgainstClaims(claimPaymentId));
      }

      return res.data.success;
    } catch (error) {
      dispatch(setConfirmEditLoader(false));

      // console.log("error--->>", error);
    }
  };

export const uploadAttachmentsAgainstClaims2 =
  (
    claimPaymentId,
    filesToUpload,
    uid,
    newhandleClose,
    fromAppealLetter,
    customSuccessMessage,
    setIsSaved,
    setSaveLoading
  ) =>
  async (dispatch, getState) => {
    try {
      let sliceState = getState().npReducers.claimQueue;
      const { currentClaimAttachmentFlags, currentClaimFormFlag } = sliceState;
      // console.log("123123 --- ", currentClaimAttachmentFlags,currentClaimFormFlag);
      setIsSaved(true);
      let formData = new FormData();
      // console.log("files to up ", filesToUpload);
      if (fromAppealLetter) {
        formData.append(`files`, filesToUpload, "Appeal letter.txt");
      } else {
        filesToUpload.forEach((document, index) => {
          formData.append(`files`, document?.originFileObj);
        });
      }
      formData.append(`ClaimPaymentId`, claimPaymentId);
      formData.append(
        `FileNames`,
        fromAppealLetter
          ? "Appeal Letter.txt"
          : filesToUpload
              ?.map((item) => {
                return item?.name;
              })
              .join(",")
      );
      formData.append(`createdBy`, uid);

      const res = await genericAxiosCallFormData(
        serverRoutes?.UPLOAD_ATTACHMENTS,
        "post",
        formData,
        ""
      );
      if (res?.data?.success == true) {
        setIsSaved(true);
        toast.success(
          `${customSuccessMessage ? customSuccessMessage : res?.data?.message}`,
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );

        const params = {
          claimPaymentId: claimPaymentId,
          isLetterGenerated: true,
          isAppealFormGenerated:
            currentClaimAttachmentFlags?.isAppealFormGenerated
              ? currentClaimAttachmentFlags?.isAppealFormGenerated
              : false,
          isAppealWorkCompleted:
            currentClaimAttachmentFlags?.isAppealWorkCompleted
              ? currentClaimAttachmentFlags?.isAppealWorkCompleted
              : false,
          flagType: currentClaimFormFlag,
        };

        fromAppealLetter && dispatch(updateAppealFormFlags(params));
        await dispatch(getAttachmentsAgainstClaims(claimPaymentId));

        newhandleClose && newhandleClose();
      }
      setSaveLoading(false);
      return res.data.success;
    } catch (error) {
      setIsSaved(false);
      setSaveLoading(false);
    }
  };

// update attachments against claim
export const updateAttachmentsAgainstClaims =
  (data, claimPaymentId) => async (dispatch, getState) => {
    try {
      const res = await genericAxiosCallFormData(
        serverRoutes?.UPDATE_ATTACHMENTS,
        "put",
        data,
        ""
      );
      if (res?.data?.success == true) {
        dispatch(getAttachmentsAgainstClaims(claimPaymentId));
      }

      // console.log("data---- ", res?.data);
    } catch (error) {}
  };

// delete single attachment against claim
export const deleteSingleAttachmentAgainstClaims =
  (claimPaymentId, fileName, setCurrentIndex) => async (dispatch, getState) => {
    try {
      dispatch(setDeleteClaimAttachmentsLoader(true));
      const params = {
        claimPaymentId: claimPaymentId,
        fileName: fileName,
      };

      const res = await genericAxiosCall(
        serverRoutes?.DELETE_SINGLE_ATTACHMENT,
        "delete",
        "",
        params
      );
      if (res?.data?.success == true) {
        setCurrentIndex(0);
        dispatch(getAttachmentsAgainstClaims(claimPaymentId));
        dispatch(setDeleteClaimAttachmentsLoader(false));
      } else {
        dispatch(setDeleteClaimAttachmentsLoader(false));
      }

      // console.log("data---- ", res?.data);
    } catch (error) {
      dispatch(setDeleteClaimAttachmentsLoader(false));
    }
  };

// delete multiple attachment against claim
export const deleteMultipleAttachmentAgainstClaims =
  (claimPaymentId, fileName, setCurrentIndex) => async (dispatch, getState) => {
    try {
      dispatch(setDeleteClaimAttachmentsLoader(true));

      const params = {
        claimPaymentId: claimPaymentId,
        fileNames: [fileName],
      };

      const res = await genericAxiosCall(
        serverRoutes?.DELETE_MULTIPLE_ATTACHMENTS,
        "delete",
        params,
        ""
      );
      if (res?.data?.success == true) {
        setCurrentIndex(0);
        toast.success(`${res?.data?.message}`, {
          position: toast.POSITION.TOP_CENTER,
        });
        await dispatch(getAttachmentsAgainstClaims(claimPaymentId));
        dispatch(setDeleteClaimAttachmentsLoader(false));
      } else {
        dispatch(setDeleteClaimAttachmentsLoader(false));
      }
      return res;
      // console.log("data---- ", res?.data);
    } catch (error) {
      // console.log("dlt_error--->>", error);
    }
  };

// get data for insurance form
export const getInsuranceFormData =
  (claimPaymentId) => async (dispatch, getState) => {
    try {
      dispatch(setInsuranceFormLoader(true));

      const params = {
        claimPaymentId: claimPaymentId,
        time: new Date(),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_INSURANCE_FORM_DATA,
        "get",
        "",
        params
      );

      if (res?.data?.success == true) {
        dispatch(setInsuranceFormLoader(false));
        let data = res?.data?.data[0];
        // console.log("123123 data -- ", res?.data?.data);

        dispatch(setInsuranceFormData(data));
        if (res?.data?.data?.length < 1) {
          dispatch(getResetInsuranceFormData(claimPaymentId));
        }
        // console.log("123123 data -- ", res?.data?.data);
      } else {
        dispatch(setInsuranceFormLoader(false));
        let data = res?.data?.data;
        dispatch(setInsuranceFormData(data));
      }
    } catch (error) {}
  };
export const getGlossary = (claimPaymentId) => async (dispatch, getState) => {
  try {
    const params = {
      claimPaymentId: claimPaymentId,
      timestamp: new Date().getTime(),
    };

    const res = await genericAxiosCall(
      serverRoutes?.GET_APPEAL_GLOSSARY,
      "get",
      "",
      params
    );
    if (res?.data?.success == true) {
      // dispatch(setInsuranceFormLoader(false));
      let data = res?.data?.data[0];
      dispatch(setAppealGlossary(data));
    } else {
      dispatch(setAppealGlossary({}));
      // dispatch(setInsuranceFormLoader(false));
      // let data = res?.data?.data;
      // dispatch(setInsuranceFormData(data));
    }
  } catch (error) {}
};

// get insurance forms
export const getInsuranceForms = () => async (dispatch, getState) => {
  try {
    dispatch(setInsuranceFormsLoader(true));
    const res = await genericAxiosCall(
      serverRoutes?.GET_INSURANCE_FORMS,
      "get",
      ""
    );

    if (res?.data?.success == true) {
      dispatch(setInsuranceFormsLoader(false));
      let data = res?.data?.data;
      dispatch(setInsuranceForms(data));
    } else {
      dispatch(setInsuranceFormsLoader(false));
      let data = res?.data?.data;

      dispatch(setInsuranceForms(data));
    }
    dispatch(setInsuranceFormsLoader(false));
  } catch (error) {
    dispatch(setInsuranceFormsLoader(false));

    // console.log("123123 -- err ", error);
  }
};

// upload insurrance form
export const uploadFormAgainstClaims =
  (claimPaymentId, filesToUpload, uid, name) => async (dispatch, getState) => {
    try {
      const res = await genericAxiosCallFormData(
        serverRoutes?.UPLOAD_ATTACHMENTS,
        "post",
        filesToUpload,
        ""
      );
      if (res?.data?.success == true) {
        toast.success(`${res?.data?.message}`, {
          position: toast.POSITION.TOP_CENTER,
        });

        dispatch(getAttachmentsAgainstClaims(claimPaymentId));
      }

      return res.data.success;
    } catch (error) {
      // console.log(error);
    }
  };

// post insurance forms
export const postInsuranceForms =
  (params, setFormViewVisible) => async (dispatch, getState) => {
    try {
      dispatch(setPayerFormsSaveLoader(true));

      // dispatch(setInsuranceFormsLoader(true));
      // console.log("123123 --p- ", params);
      const res = await genericAxiosCall(
        serverRoutes?.POST_INSURANCE_FORM_DATA,
        "post",
        params,
        ""
      );

      if (res?.data?.success == true) {
        toast.success(`${res?.data?.message}`, {
          position: toast.POSITION.TOP_CENTER,
        });
        setFormViewVisible(false);
        dispatch(setPayerFormsSaveLoader(false));

        return true;
        // dispatch(setInsuranceFormsLoader(false));
        // let data = res?.data?.data;
        // dispatch(setInsuranceForms(data));
      } else {
        dispatch(setPayerFormsSaveLoader(false));

        return false;
        // dispatch(setInsuranceFormsLoader(false));
        // let data = res?.data?.data;
        // dispatch(setInsuranceForms(data));
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.message || "Internal Server Error");
    }
  };

// update form flags
export const updateAppealFormFlags =
  (data, loadData, setCurrentIndex) => async (dispatch, getState) => {
    try {
      const res = await genericAxiosCall(
        serverRoutes?.UPDATE_APPEAL_LETTER_STATUS,
        "put",
        data,
        ""
      );
      if (res?.data?.success == true) {
        loadData ? loadData() : null;
        dispatch(getAttachmentsAgainstClaims(data?.claimPaymentId));
        setCurrentIndex && setCurrentIndex(0);

        //just for hard reload to appeals screen 8888
        dispatch(
          setListingState({
            listing: "appealsDetailFilter",
            value: {
              fetchLatest: true,
            },
          })
        );
      }
    } catch (err) {
      // console.log("err--->>", err);
    }
  };

// get reset data for insurance form
export const getResetInsuranceFormData =
  (claimPaymentId) => async (dispatch, getState) => {
    try {
      dispatch(setInsuranceFormLoader(true));

      const params = {
        claimPaymentId: claimPaymentId,
        time: new Date(),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_INSURANCE_FORM_RESET_DATA,
        "get",
        "",
        params
      );

      if (res?.data?.success == true) {
        dispatch(setInsuranceFormLoader(false));
        let data = {
          ...res?.data?.data[0],
          // npi: "",
          // priorAuthorizationNo: "",
          // billingAddress: "",
          // state: "",
          // zip: "",
          // memberName: "",
          // memberDob: "",
          // groupNo: "",
          // city: "",
          // authorizationNo: "",
          // providerName: "",
        };
        dispatch(setInsuranceFormData(data));
        // console.log("123123 data -- ", res?.data?.data);
        return res?.data?.success;
      } else {
        dispatch(setInsuranceFormLoader(false));
        let data = res?.data?.data;
        dispatch(setInsuranceFormData(data));
        return res?.data?.success;
      }
    } catch (error) {}
  };

// upload Seperate attachments 837
// export const uploadAttachments837 =
//   (filesToUpload, postUploadHandler, setUploadLoading, setTotalSize) =>
//   async (dispatch, getState) => {
//     try {
//       setUploadLoading(true);
//       let formData = new FormData();

//       filesToUpload.forEach((document, index) => {
//         formData.append(`claimfiles`, document?.originFileObj);
//       });
//       formData.append(
//         "ClientId",
//         store.getState()?.npReducers?.clientsConfig?.selectedClientIds || ""
//       );

//       const res = await genericAxiosCallFormData(
//         serverRoutes?.UPLOAD_ATTACHMENTS_837,
//         "post",
//         formData,
//         ""
//       );

//       if (res?.data?.success == true) {
//         postUploadHandler();
//         toast.success(`${res?.data?.message}`, {
//           position: toast.POSITION.TOP_CENTER,
//         });
//         setTotalSize(0);
//       } else {
//         postUploadHandler();
//         toast.error(`${res?.data?.message}`, {
//           position: toast.POSITION.TOP_CENTER,
//         });
//       }
//       setUploadLoading(false);
//       return res.data.success;
//     } catch (err) {
//       setUploadLoading(false);

//       // console.log("err--->>", err);
//     }
//   };
export const uploadAttachments837 =
  (filesToUpload, postUploadHandler, setUploadLoading, setTotalSize) =>
  async (dispatch, getState) => {
    try {
      setUploadLoading(true);

      let sliceStateUser = getState().pReducers.user;
      const formData = new FormData();

      // Check if `isRcm` is true and append `ClientId` if necessary
      if (sliceStateUser?.loginUserData?.isRcm) {
        const selectedClientId =
          getState()?.npReducers?.clientsConfig?.selectedClientIds || "";
        formData.append("ClientId", selectedClientId);
      }

      // Append claimfiles
      filesToUpload.forEach((document, index) => {
        formData.append(`claimfiles`, document?.originFileObj);
      });

      // Make the API call
      const res = await genericAxiosCallFormData(
        serverRoutes?.UPLOAD_ATTACHMENTS_837,
        "post",
        formData,
        ""
      );

      if (res?.data?.success == true) {
        postUploadHandler();
        toast.success(`${res?.data?.message}`, {
          position: toast.POSITION.TOP_CENTER,
        });
        setTotalSize(0);
      } else {
        postUploadHandler();
        toast.error(`${res?.data?.message}`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      setUploadLoading(false);
      return res.data.success;
    } catch (err) {
      setUploadLoading(false);
      // console.log("err--->>", err);
    }
  };

// upload Seperate attachments 835
export const uploadAttachments835 =
  (filesToUpload, postUploadHandler) => async (dispatch, getState) => {
    try {
      let formData = new FormData();

      filesToUpload.forEach((document, index) => {
        formData.append(`claimfiles`, document?.originFileObj);
      });

      const res = await genericAxiosCallFormData(
        serverRoutes?.UPLOAD_ATTACHMENTS_835,
        "post",
        formData,
        ""
      );
      if (res?.data?.success == true) {
        postUploadHandler && postUploadHandler();
        toast.success(`${res?.data?.message}`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }

      return res.data.success;
    } catch (error) {
      // console.log(error, filesToUpload);
    }
  };

// get appeal queue filterCount
export const getAppealQueueFilterCount = (id) => async (dispatch, getState) => {
  try {
    const params = {
      workQueueId: id,
    };
    const res = await genericAxiosCall(
      serverRoutes?.GET_APPEAL_QUEUE_FILTER_COUNT,
      "get",
      "",
      params
    );
    if (res?.data?.success == true) {
      let data = res?.data?.data;
      dispatch(setAppealQueueFilterCount(data));
    }
  } catch (error) {
    // console.log("123123 -- err ", error);
  }
};

// upload Fax
export const uploadFax =
  (filesToUpload, faxTo, setExportLoader) => async (dispatch, getState) => {
    try {
      let formData = new FormData();
      formData.append(`FaxContent`, filesToUpload);
      formData.append("FaxTo", faxTo);

      const res = await genericAxiosCallFormData(
        serverRoutes?.UPLOAD_FAX,
        "post",
        formData,
        ""
      );
      if (res?.data?.success == true) {
        setExportLoader(false);
        toast.success(`${res?.data?.message}`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }

      return res.data;
    } catch (error) {
      toast.dismiss();
      setExportLoader(false);
      toast.error(`${error?.response?.data?.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
      // console.log(error.response);
    }
  };

// get appeal users and queues for appeal dashboard
export const getAppealDashboardUsersAndQueues =
  (id) => async (dispatch, getState) => {
    try {
      const params = {
        workQueueId: id,
      };
      const res = await genericAxiosCall(
        serverRoutes?.GET_APPEAL_DASHBOARD_USERS_AND_QUEUES,
        "get",
        "",
        ""
      );
      if (res?.data?.success == true) {
        let data = res?.data?.data;
        const users = data?.queueBuckets?.map((item) => {
          return {
            value: item?.key,
            label: item?.label,
            children: item?.children
              ? item?.children?.map((item2) => {
                  return {
                    value: item2?.key,
                    label: item2?.label,
                  };
                })
              : null,
          };
        });
        const payers = data?.payerInfo?.map((item) => {
          return {
            value: item?.payerId,
            label: item?.name,
          };
        });

        const finalData = {
          users,
          payers,
        };

        dispatch(setAppealDashboardUsersAndQueues(finalData));
      }
    } catch (error) {
      // console.log("123123 -- err ", error);
    }
  };

// get appeal queues for appeal dashboard
export const getAppealDashboardQueues = (id) => async (dispatch, getState) => {
  try {
    // const params = {
    //   workQueueId: id,
    // };
    const res = await genericAxiosCall(
      serverRoutes?.GET_APPEAL_DASHBOARD_QUEUES,
      "get",
      "",
      ""
    );
    if (res?.data?.success == true) {
      let data = res?.data?.data;
      const users = data?.users?.map((item) => {
        return {
          value: item?.userId,
          label: item?.displayName,
          // value: item?.key,
          // label: item?.label,
          // children: item?.children
          //   ? item?.children?.map((item2) => {
          //       return {
          //         value: item2?.key,
          //         label: item2?.label,
          //       };
          //     })
          //   : null,
        };
      });
      const payers = data?.queues?.map((item) => {
        return {
          value: item?.queueId,
          label: item?.queueName,
        };
      });

      const finalData = {
        users,
        payers,
      };

      dispatch(setAppealDashboardUsersAndQueues(finalData));
    }
  } catch (error) {
    // console.log("123123 -- err ", error);
  }
};

// GET FAX PAYERS
export const getFaxPayers = () => async (dispatch, getState) => {
  try {
    let params = {
      start: 0,
      limit: 500,
      // ...(sliceState.order !== "" && {
      //   Order: sliceState.order,
      // }),
      // ...(sliceState.orderBy !== "" && {
      //   OrderBy: sliceState.orderBy,
      // }),
      isCustom: true,
    };

    const res = await genericAxiosCall(
      serverRoutes?.PAYER_CONTACT_LIST,
      "get",
      "",
      params
    );
    if (res?.data?.success == true) {
      let data = res?.data?.data;

      const faxPayers = data?.map((item, i) => {
        return {
          label: `${item?.payerName}-${item?.primaryId}`,
          value: item?.faxNumber,
          id: item?.id,
          disabled: `${item?.faxNumber}`?.length < 5 ? true : false,
        };
      });

      dispatch(setFaxPayers(faxPayers));
    }
  } catch (error) {
    // console.log("123123 -- err ", error);
  }
};

// GET FAX INFO
export const getFaxInfo = (faxId) => async (dispatch, getState) => {
  try {
    const params = {
      faxId: faxId,
    };
    const res = await genericAxiosCall(
      serverRoutes?.GET_FAX_INFO,
      "get",
      "",
      params
    );
    if (res?.data?.success == true) {
      let data = res?.data?.data;
      dispatch(setFaxInfo(data));
    }
  } catch (error) {
    // console.log("123123 -- err ", error);
  }
};

// CANCEL FAX
export const cancelFax = (faxId) => async (dispatch, getState) => {
  try {
    const params = {
      faxId: faxId,
    };
    const res = await genericAxiosCall(
      serverRoutes?.CANCEL_FAX,
      "patch",
      "",
      params
    );
    if (res?.data?.success == true) {
      let data = res?.data;
      toast.success(`${data?.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    } else {
      // console.log("123123 rrrrrrrrrrrrrrrr ", data);
      toast.error(`${res?.data?.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  } catch (error) {
    toast.error(`${error?.data?.message}`, {
      position: toast.POSITION.TOP_CENTER,
    });
    // console.log("123123 -- err ", error);
  }
};

// Preview existing claims
export const getExistingClaims =
  ({ whereJson, type, bucketId = "" }) =>
  async (dispatch, getState) => {
    try {
      const params = {
        WorkQueueId: 0,
        start: 0,
        limit: 10,
        type: type,
        WhereJson: whereJson,
        AddAllClaims: true,
        RunById: false,
        ...(bucketId && {
          bucketId: bucketId,
        }),
      };
      dispatch(setExistingClaimsLoader(true));
      const res = await genericAxiosCall(
        serverRoutes?.GET_EXISTING_CLAIMS,
        "post",
        params,
        ""
      );
      if (res?.data?.success == true) {
        let data = res?.data?.data;
        dispatch(setExistingClaims(data));
      }
      dispatch(setExistingClaimsLoader(false));
    } catch (error) {
      console.log("hey khizer===", error);
      dispatch(setExistingClaimsLoader(false));

      // console.log("error getting existing claims", error);
    }
  };

// Preview existing claims
export const moveAllClaims =
  ({ whereJson }) =>
  async (dispatch, getState) => {
    try {
      const params = {
        WhereJson: whereJson,
      };
      dispatch(setExistingClaimsLoader(true));
      const res = await genericAxiosCall(
        serverRoutes?.MOVE_ALL_CLAIMS,
        "post",
        params,
        ""
      );
      if (res?.data?.success == true) {
        let data = res?.data;
        // dispatch(setExistingClaims(data));
        toast.success(`${data?.message}`, {
          position: toast.POSITION.TOP_CENTER,
        });
        return true;
      }
      dispatch(setExistingClaimsLoader(false));
    } catch (error) {
      dispatch(setExistingClaimsLoader(false));

      // console.log("error getting existing claims", error);
    }
  };

export default ClaimQueueSlice.reducer;

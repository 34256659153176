import { createSlice } from "@reduxjs/toolkit";
import claims from "../../../../actions/claims";

export const claimCardSlice = createSlice({
  name: "claimCardSlice",
  initialState: {
    claimCardData: [],
  },
  reducers: {
    claimCardRequest: (state, action) => {
      state.claimCardData = action.payload;
    },
  },
});

const { claimCardRequest } = claimCardSlice.actions;

export const claimRequestCards = () => async (dispatch) => {
  const res = await claims.getClaimRequestCards();
  if (res?.data?.success) {
    dispatch(claimCardRequest(res.data.data));
  } else {
    dispatch(claimCardRequest([]));
  }

  return res;
};

//export const claimSumFiveYear  = state => state.claimDashboard.claimSumLastFiveYear;

export default claimCardSlice.reducer;

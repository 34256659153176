export function claimFilingIndicatorMapping(code) {
  let desc = "";
  if (code == "09") {
    return (desc = "Self-pay");
  } else if (code == "11") {
    return (desc = "Other Non-Federal Programs");
  } else if (code == "12") {
    return (desc = "Preferred Provider Organization (PPO)");
  } else if (code == "13") {
    return (desc = "Point of Service (POS)");
  } else if (code == "14") {
    return (desc = "Exclusive Provider Organization (EPO)");
  } else if (code == "15") {
    return (desc = "Indemnity Insurance");
  } else if (code == "16") {
    return (desc = "Health Maintenance Organization (HMO) Medicare Risk");
  } else if (code == "17") {
    return (desc = "Dental Maintenance Organization");
  } else if (code == "AM") {
    return (desc = "Automobile Medical");
  } else if (code == "BL") {
    return (desc = "Blue Cross/Blue Shield");
  } else if (code == "CH") {
    return (desc = "Champus");
  } else if (code == "CI") {
    return (desc = "Commercial Insurance Co.");
  } else if (code == "DS") {
    return (desc = "Disability");
  } else if (code == "FI") {
    return (desc = "Federal Employees Program");
  } else if (code == "HM") {
    return (desc = "Health Maintenance Organization");
  } else if (code == "HM") {
    return (desc = "Health Maintenance Organization");
  } else if (code == "LM") {
    return (desc = "Liability Medical");
  } else if (code == "MA") {
    return (desc = "Medicare Part A");
  } else if (code == "MB") {
    return (desc = "Medicare Part B");
  } else if (code == "MC") {
    return (desc = "Medicaid");
  } else if (code == "OF") {
    return (desc = "Other Federal Program");
  } else if (code == "TV") {
    return (desc = "Title V");
  } else if (code == "VA") {
    return (desc = "Veterans Affairs Plan");
  } else if (code == "WC") {
    return (desc = "Workers' Compensation Health Claim");
  } else if (code == "ZZ") {
    return (desc = "Mutually Defined");
  } else {
    return (desc = `Ther is no mapping for "${code}" code`);
  }
}

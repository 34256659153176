import React from 'react';

const HeatmapIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 3H19C20.1046 3 21 3.89543 21 5V10.2M12 3V10.2M12 3H5C3.89543 3 3 3.89543 3 5V13.8M12 21H19C20.1046 21 21 20.1046 21 19V10.2M12 21H5C3.89543 21 3 20.1046 3 19V13.8M12 21V13.8M21 10.2H12M12 10.2V13.8M3 13.8H12"
        stroke="black"
        stroke-linecap="round"
      ></path>
    </svg>
  );
};

export default HeatmapIcon;

import { createSlice } from "@reduxjs/toolkit";

import claims from "../../../../actions/claims";

import {
  setListingState,
  resetListingState,
} from "../../filters/claimManagerFilters";

export const claimAckSlice = createSlice({
  name: "claimAckSlice",
  initialState: {
    claimAckData: [],
  },
  reducers: {
    setClaimAckData: (state, action) => {
      state.claimAckData = action.payload;
    },
  },
});

export const { setClaimAckData } = claimAckSlice.actions;

export const claimAckCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "claimAckFilter",
          ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.claimAckFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    try {
      dispatch(
        setListingState({
          listing: "claimAckFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const res = await claims.getClaimAck({
        start,
        limit,
        ...(sliceState.order !== "asc" && {
          order: sliceState.order,
        }),
        ...(sliceState.order !== "asc" && {
          orderBy: sliceState.orderBy,
        }),
        ...(sliceState.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState.to !== "" && {
          to: sliceState.to,
        }),
      });

      ////// API CALL END //////////////

      dispatch(
        setListingState({
          listing: "claimAckFilter",
          value: {
            hasMore: res?.data?.data?.output?.length === sliceState.pageSize,
            loadingMore: "",
            totalRecord_DB: res?.data?.data?.count,
          },
        })
      );
      dispatch(setClaimAckData(res?.data?.data));
      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "claimAckFilter",
          value: {
            initialLoading: false,
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      return false;
    }
  };

export default claimAckSlice.reducer;

import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";
import { saveUserPreferences_Call2 } from "../../../Redux/Reducers/Settings/userPreferenceSlice";
import {
  setAllFiltersNoOfRecordInTable,
  setPreferences,
} from "../../../Redux/Reducers/UserReducer";
import store from "../../../Redux/store";
import PromptQuIcon from "../../../assets/ZoeImages/list.svg";
import { serverRoutes } from "../../../constants/serverRoutes";
import pako from "pako";

export const POLICY_COPILOT_BOTNAME = "Luna";
export const ELIGIBILITY_COPILOT_BOTNAME = "EMA";
export const COPILOT_NAME = "AI Agent";

export const copilotStatesOld = [
  {
    label: "Albama",
    value: "Albama",
    children: [
      {
        mainValue: "CGS Administrators",

        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Palmetto GBA",
        label: "A/B MAC - JJ",
        value: "A/B MAC - JJ",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "American Samoa",
    value: "American Samoa",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "Noridian",
        label: "A/B MAC - JE",
        value: "A/B MAC - JE",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Arizona",
    value: "Arizona",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "Noridian",
        label: "A/B MAC - JF",
        value: "A/B MAC - JF",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Arkansas",
    value: "Arkansas",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JH",
        value: "A/B MAC - JH",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "California",
    value: "California",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "Noridian",
        label: "A/B MAC - JE",
        value: "A/B MAC - JE",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Colorado",
    value: "Colorado",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JH",
        value: "A/B MAC - JH",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Connecticut",
    value: "Connecticut",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JA",
        value: "DME MAC - JA",
      },
      {
        mainValue: "NGS",
        label: "A/B MAC - JK",
        value: "A/B MAC - JK",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - JK",
        value: "HH + H MAC - JK",
      },
    ],
  },
  {
    label: "Delaware",
    value: "Delaware",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JA",
        value: "DME MAC - JA",
      },
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JL",
        value: "A/B MAC - JL",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "District of Columbia",
    value: "District of Columbia",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JA",
        value: "DME MAC - JA",
      },
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JL",
        value: "A/B MAC - JL",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Florida",
    value: "Florida",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "First Coast Service Options, Inc.",
        label: "A/B MAC - JN",
        value: "A/B MAC - JN",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "Georgia",
    value: "Georgia",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Palmetto GBA",
        label: "A/B MAC - JJ",
        value: "A/B MAC - JJ",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "Guam",
    value: "Guam",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "Noridian",
        label: "A/B MAC - JE",
        value: "A/B MAC - JE",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Hawaii",
    value: "Hawaii",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "Noridian",
        label: "A/B MAC - JE",
        value: "A/B MAC - JE",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Idaho",
    value: "Idaho",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "Noridian",
        label: "A/B MAC - JF",
        value: "A/B MAC - JF",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Illinois",
    value: "Illinois",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JB",
        value: "DME MAC - JB",
      },
      {
        mainValue: "NGS",
        label: "A/B MAC - J6",
        value: "A/B MAC - J6",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "Indiana",
    value: "Indiana",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JB",
        value: "DME MAC - JB",
      },
      {
        mainValue: "WPS Insurance Corporation",
        label: "A/B MAC - J8",
        value: "A/B MAC - J8",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "Iowa",
    value: "Iowa",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "WPS Insurance Corporation",
        label: "A/B MAC - J5",
        value: "A/B MAC - J5",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Kansas",
    value: "Kansas",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "WPS Insurance Corporation",
        label: "A/B MAC - J5",
        value: "A/B MAC - J5",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Kentucky",
    value: "Kentucky",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JB",
        value: "DME MAC - JB",
      },
      {
        mainValue: "CGS Administrators",
        label: "A/B MAC - J15",
        value: "A/B MAC - J15",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "Louisiana",
    value: "Louisiana",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JH",
        value: "A/B MAC - JH",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "Maine",
    value: "Maine",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JA",
        value: "DME MAC - JA",
      },
      {
        mainValue: "NGS",
        label: "A/B MAC - JK",
        value: "A/B MAC - JK",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - JK",
        value: "HH + H MAC - JK",
      },
    ],
  },
  {
    label: "Maryland",
    value: "Maryland",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JA",
        value: "DME MAC - JA",
      },
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JL",
        value: "A/B MAC - JL",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Massachusetts",
    value: "Massachusetts",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JA",
        value: "DME MAC - JA",
      },
      {
        mainValue: "NGS",
        label: "A/B MAC - JK",
        value: "A/B MAC - JK",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - JK",
        value: "HH + H MAC - JK",
      },
    ],
  },
  {
    label: "Michigan",
    value: "Michigan",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JB",
        value: "DME MAC - JB",
      },
      {
        mainValue: "WPS Insurance Corporation",
        label: "A/B MAC - J8",
        value: "A/B MAC - J8",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Minnesota",
    value: "Minnesota",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JB",
        value: "DME MAC - JB",
      },
      {
        mainValue: "NGS",
        label: "A/B MAC - J6",
        value: "A/B MAC - J6",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Mississippi",
    value: "Mississippi",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JH",
        value: "A/B MAC - JH",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "Missouri",
    value: "Missouri",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "WPS Insurance Corporation",
        label: "A/B MAC - J5",
        value: "A/B MAC - J5",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Montana",
    value: "Montana",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "Noridian",
        label: "A/B MAC - JF",
        value: "A/B MAC - JF",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Nebraska",
    value: "Nebraska",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "WPS Insurance Corporation",
        label: "A/B MAC - J5",
        value: "A/B MAC - J5",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Nevada",
    value: "Nevada",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "Noridian",
        label: "A/B MAC - JE",
        value: "A/B MAC - JE",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "New Hampshire",
    value: "New Hampshire",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JA",
        value: "DME MAC - JA",
      },
      {
        mainValue: "NGS",
        label: "A/B MAC - JK",
        value: "A/B MAC - JK",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - JK",
        value: "HH + H MAC - JK",
      },
    ],
  },
  {
    label: "New Jersey",
    value: "New Jersey",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JA",
        value: "DME MAC - JA",
      },
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JL",
        value: "A/B MAC - JL",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "New Mexico",
    value: "New Mexico",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JH",
        value: "A/B MAC - JH",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "New York",
    value: "New York",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JA",
        value: "DME MAC - JA",
      },
      {
        mainValue: "NGS",
        label: "A/B MAC - JK",
        value: "A/B MAC - JK",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "North Carolina",
    value: "North Carolina",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Palmetto GBA",
        label: "A/B MAC - JM",
        value: "A/B MAC - JM",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "North Dakota",
    value: "North Dakota",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "Noridian",
        label: "A/B MAC - JF",
        value: "A/B MAC - JF",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Northern Mariana Islands",
    value: "Northern Mariana Islands",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "Noridian",
        label: "A/B MAC - JE",
        value: "A/B MAC - JE",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Ohio",
    value: "Ohio",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JB",
        value: "DME MAC - JB",
      },
      {
        mainValue: "CGS Administrators",
        label: "A/B MAC - J15",
        value: "A/B MAC - J15",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "Oklahoma",
    value: "Oklahoma",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JH",
        value: "A/B MAC - JH",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "Oregon",
    value: "Oregon",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "Noridian",
        label: "A/B MAC - JF",
        value: "A/B MAC - JF",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Pennsylvania",
    value: "Pennsylvania",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JA",
        value: "DME MAC - JA",
      },
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JL",
        value: "A/B MAC - JL",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Puerto Rico",
    value: "Puerto Rico",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "First Coast Service Options, Inc.",
        label: "A/B MAC - JN",
        value: "A/B MAC - JN",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Rhode Island",
    value: "Rhode Island",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JA",
        value: "DME MAC - JA",
      },
      {
        mainValue: "NGS",
        label: "A/B MAC - JK",
        value: "A/B MAC - JK",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - JK",
        value: "HH + H MAC - JK",
      },
    ],
  },
  {
    label: "South Carolina",
    value: "South Carolina",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Palmetto GBA",
        label: "A/B MAC - JM",
        value: "A/B MAC - JM",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - J M",
        value: "HH + H MAC - J M",
      },
    ],
  },
  {
    label: "South Dakota",
    value: "South Dakota",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "Noridian",
        label: "A/B MAC - JF",
        value: "A/B MAC - JF",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Tennessee",
    value: "Tennessee",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Palmetto GBA",
        label: "A/B MAC - JJ",
        value: "A/B MAC - JJ",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "Texas",
    value: "Texas",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JH",
        value: "A/B MAC - JH",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "Utah",
    value: "Utah",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "Noridian",
        label: "A/B MAC - JF",
        value: "A/B MAC - JF",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Vermont",
    value: "Vermont",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JA",
        value: "DME MAC - JA",
      },
      {
        mainValue: "NGS",
        label: "A/B MAC - JK",
        value: "A/B MAC - JK",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - JK",
        value: "HH + H MAC - JK",
      },
    ],
  },
  {
    label: "Virgin Islands",
    value: "Virgin Islands",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "First Coast Service Options, Inc.",
        label: "A/B MAC - JN",
        value: "A/B MAC - JN",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Virginia",
    value: "Virginia",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JL",
        value: "A/B MAC - JL",
      },
      {
        mainValue: "Palmetto GBA",
        label: "A/B MAC - JM",
        value: "A/B MAC - JM",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Washington",
    value: "Washington",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "Noridian",
        label: "A/B MAC - JF",
        value: "A/B MAC - JF",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "West Virginia",
    value: "West Virginia",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Palmetto GBA",
        label: "A/B MAC - JM",
        value: "A/B MAC - JM",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Wisconsin",
    value: "Wisconsin",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JB",
        value: "DME MAC - JB",
      },
      {
        mainValue: "NGS",
        label: "A/B MAC - J6",
        value: "A/B MAC - J6",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Wyoming",
    value: "Wyoming",
    children: [
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "Noridian",
        label: "A/B MAC - JF",
        value: "A/B MAC - JF",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
];
export const copilotStates = [
  {
    label: "Albama",
    value: "Albama",
    children: [
      {
        mainValue: "Palmetto GBA",
        label: "A/B MAC - JJ",
        value: "A/B MAC - JJ",
      },
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "American Samoa",
    value: "American Samoa",
    children: [
      {
        mainValue: "Noridian",
        label: "A/B MAC - JE",
        value: "A/B MAC - JE",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Arizona",
    value: "Arizona",
    children: [
      {
        mainValue: "Noridian",
        label: "A/B MAC - JF",
        value: "A/B MAC - JF",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Arkansas",
    value: "Arkansas",
    children: [
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JH",
        value: "A/B MAC - JH",
      },
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "California",
    value: "California",
    children: [
      {
        mainValue: "Noridian",
        label: "A/B MAC - JE",
        value: "A/B MAC - JE",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Colorado",
    value: "Colorado",
    children: [
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JH",
        value: "A/B MAC - JH",
      },
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Connecticut",
    value: "Connecticut",
    children: [
      {
        mainValue: "NGS",
        label: "A/B MAC - JK",
        value: "A/B MAC - JK",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JA",
        value: "DME MAC - JA",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - JK",
        value: "HH + H MAC - JK",
      },
    ],
  },
  {
    label: "Delaware",
    value: "Delaware",
    children: [
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JL",
        value: "A/B MAC - JL",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JA",
        value: "DME MAC - JA",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "District of Columbia",
    value: "District of Columbia",
    children: [
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JL",
        value: "A/B MAC - JL",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JA",
        value: "DME MAC - JA",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Florida",
    value: "Florida",
    children: [
      {
        mainValue: "First Coast Service Options, Inc.",
        label: "A/B MAC - JN",
        value: "A/B MAC - JN",
      },
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "Georgia",
    value: "Georgia",
    children: [
      {
        mainValue: "Palmetto GBA",
        label: "A/B MAC - JJ",
        value: "A/B MAC - JJ",
      },
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "Guam",
    value: "Guam",
    children: [
      {
        mainValue: "Noridian",
        label: "A/B MAC - JE",
        value: "A/B MAC - JE",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Hawaii",
    value: "Hawaii",
    children: [
      {
        mainValue: "Noridian",
        label: "A/B MAC - JE",
        value: "A/B MAC - JE",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Idaho",
    value: "Idaho",
    children: [
      {
        mainValue: "Noridian",
        label: "A/B MAC - JF",
        value: "A/B MAC - JF",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Illinois",
    value: "Illinois",
    children: [
      {
        mainValue: "NGS",
        label: "A/B MAC - J6",
        value: "A/B MAC - J6",
      },
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JB",
        value: "DME MAC - JB",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "Indiana",
    value: "Indiana",
    children: [
      {
        mainValue: "WPS Insurance Corporation",
        label: "A/B MAC - J8",
        value: "A/B MAC - J8",
      },
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JB",
        value: "DME MAC - JB",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "Iowa",
    value: "Iowa",
    children: [
      {
        mainValue: "WPS Insurance Corporation",
        label: "A/B MAC - J5",
        value: "A/B MAC - J5",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Kansas",
    value: "Kansas",
    children: [
      {
        mainValue: "WPS Insurance Corporation",
        label: "A/B MAC - J5",
        value: "A/B MAC - J5",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Kentucky",
    value: "Kentucky",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "A/B MAC - J15",
        value: "A/B MAC - J15",
      },
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JB",
        value: "DME MAC - JB",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "Louisiana",
    value: "Louisiana",
    children: [
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JH",
        value: "A/B MAC - JH",
      },
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "Maine",
    value: "Maine",
    children: [
      {
        mainValue: "NGS",
        label: "A/B MAC - JK",
        value: "A/B MAC - JK",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JA",
        value: "DME MAC - JA",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - JK",
        value: "HH + H MAC - JK",
      },
    ],
  },
  {
    label: "Maryland",
    value: "Maryland",
    children: [
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JL",
        value: "A/B MAC - JL",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JA",
        value: "DME MAC - JA",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Massachusetts",
    value: "Massachusetts",
    children: [
      {
        mainValue: "NGS",
        label: "A/B MAC - JK",
        value: "A/B MAC - JK",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JA",
        value: "DME MAC - JA",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - JK",
        value: "HH + H MAC - JK",
      },
    ],
  },
  {
    label: "Michigan",
    value: "Michigan",
    children: [
      {
        mainValue: "WPS Insurance Corporation",
        label: "A/B MAC - J8",
        value: "A/B MAC - J8",
      },
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JB",
        value: "DME MAC - JB",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Minnesota",
    value: "Minnesota",
    children: [
      {
        mainValue: "NGS",
        label: "A/B MAC - J6",
        value: "A/B MAC - J6",
      },
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JB",
        value: "DME MAC - JB",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Mississippi",
    value: "Mississippi",
    children: [
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JH",
        value: "A/B MAC - JH",
      },
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "Missouri",
    value: "Missouri",
    children: [
      {
        mainValue: "WPS Insurance Corporation",
        label: "A/B MAC - J5",
        value: "A/B MAC - J5",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Montana",
    value: "Montana",
    children: [
      {
        mainValue: "Noridian",
        label: "A/B MAC - JF",
        value: "A/B MAC - JF",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Nebraska",
    value: "Nebraska",
    children: [
      {
        mainValue: "WPS Insurance Corporation",
        label: "A/B MAC - J5",
        value: "A/B MAC - J5",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Nevada",
    value: "Nevada",
    children: [
      {
        mainValue: "Noridian",
        label: "A/B MAC - JE",
        value: "A/B MAC - JE",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "New Hampshire",
    value: "New Hampshire",
    children: [
      {
        mainValue: "NGS",
        label: "A/B MAC - JK",
        value: "A/B MAC - JK",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JA",
        value: "DME MAC - JA",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - JK",
        value: "HH + H MAC - JK",
      },
    ],
  },
  {
    label: "New Jersey",
    value: "New Jersey",
    children: [
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JL",
        value: "A/B MAC - JL",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JA",
        value: "DME MAC - JA",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "New Mexico",
    value: "New Mexico",
    children: [
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JH",
        value: "A/B MAC - JH",
      },
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "New York",
    value: "New York",
    children: [
      {
        mainValue: "NGS",
        label: "A/B MAC - JK",
        value: "A/B MAC - JK",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JA",
        value: "DME MAC - JA",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "North Carolina",
    value: "North Carolina",
    children: [
      {
        mainValue: "Palmetto GBA",
        label: "A/B MAC - JM",
        value: "A/B MAC - JM",
      },
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "North Dakota",
    value: "North Dakota",
    children: [
      {
        mainValue: "Noridian",
        label: "A/B MAC - JF",
        value: "A/B MAC - JF",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Northern Mariana Islands",
    value: "Northern Mariana Islands",
    children: [
      {
        mainValue: "Noridian",
        label: "A/B MAC - JE",
        value: "A/B MAC - JE",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Ohio",
    value: "Ohio",
    children: [
      {
        mainValue: "CGS Administrators",
        label: "A/B MAC - J15",
        value: "A/B MAC - J15",
      },
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JB",
        value: "DME MAC - JB",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "Oklahoma",
    value: "Oklahoma",
    children: [
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JH",
        value: "A/B MAC - JH",
      },
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "Oregon",
    value: "Oregon",
    children: [
      {
        mainValue: "Noridian",
        label: "A/B MAC - JF",
        value: "A/B MAC - JF",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Pennsylvania",
    value: "Pennsylvania",
    children: [
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JL",
        value: "A/B MAC - JL",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JA",
        value: "DME MAC - JA",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Puerto Rico",
    value: "Puerto Rico",
    children: [
      {
        mainValue: "First Coast Service Options, Inc.",
        label: "A/B MAC - JN",
        value: "A/B MAC - JN",
      },
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Rhode Island",
    value: "Rhode Island",
    children: [
      {
        mainValue: "NGS",
        label: "A/B MAC - JK",
        value: "A/B MAC - JK",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JA",
        value: "DME MAC - JA",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - JK",
        value: "HH + H MAC - JK",
      },
    ],
  },
  {
    label: "South Carolina",
    value: "South Carolina",
    children: [
      {
        mainValue: "Palmetto GBA",
        label: "A/B MAC - JM",
        value: "A/B MAC - JM",
      },
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - J M",
        value: "HH + H MAC - J M",
      },
    ],
  },
  {
    label: "South Dakota",
    value: "South Dakota",
    children: [
      {
        mainValue: "Noridian",
        label: "A/B MAC - JF",
        value: "A/B MAC - JF",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Tennessee",
    value: "Tennessee",
    children: [
      {
        mainValue: "Palmetto GBA",
        label: "A/B MAC - JJ",
        value: "A/B MAC - JJ",
      },
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "Texas",
    value: "Texas",
    children: [
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JH",
        value: "A/B MAC - JH",
      },
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "Palmetto GBA",
        label: "HH + H MAC - JM",
        value: "HH + H MAC - JM",
      },
    ],
  },
  {
    label: "Utah",
    value: "Utah",
    children: [
      {
        mainValue: "Noridian",
        label: "A/B MAC - JF",
        value: "A/B MAC - JF",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Vermont",
    value: "Vermont",
    children: [
      {
        mainValue: "NGS",
        label: "A/B MAC - JK",
        value: "A/B MAC - JK",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JA",
        value: "DME MAC - JA",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - JK",
        value: "HH + H MAC - JK",
      },
    ],
  },
  {
    label: "Virgin Islands",
    value: "Virgin Islands",
    children: [
      {
        mainValue: "First Coast Service Options, Inc.",
        label: "A/B MAC - JN",
        value: "A/B MAC - JN",
      },
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Virginia",
    value: "Virginia",
    children: [
      {
        mainValue: "Novitas Solutions",
        label: "A/B MAC - JL",
        value: "A/B MAC - JL",
      },
      {
        mainValue: "Palmetto GBA",
        label: "A/B MAC - JM",
        value: "A/B MAC - JM",
      },
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Washington",
    value: "Washington",
    children: [
      {
        mainValue: "Noridian",
        label: "A/B MAC - JF",
        value: "A/B MAC - JF",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "West Virginia",
    value: "West Virginia",
    children: [
      {
        mainValue: "Palmetto GBA",
        label: "A/B MAC - JM",
        value: "A/B MAC - JM",
      },
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JC",
        value: "DME MAC - JC",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
  {
    label: "Wisconsin",
    value: "Wisconsin",
    children: [
      {
        mainValue: "NGS",
        label: "A/B MAC - J6",
        value: "A/B MAC - J6",
      },
      {
        mainValue: "CGS Administrators",
        label: "DME MAC - JB",
        value: "DME MAC - JB",
      },
      {
        mainValue: "NGS",
        label: "HH + H MAC - J6",
        value: "HH + H MAC - J6",
      },
    ],
  },
  {
    label: "Wyoming",
    value: "Wyoming",
    children: [
      {
        mainValue: "Noridian",
        label: "A/B MAC - JF",
        value: "A/B MAC - JF",
      },
      {
        mainValue: "Noridian",
        label: "DME MAC - JD",
        value: "DME MAC - JD",
      },
      {
        mainValue: "CGS Administrators",
        label: "HH + H MAC - J15",
        value: "HH + H MAC - J15",
      },
    ],
  },
];
export const LidaPlottingInstructions = [
  "",
  "Plotting Instructions:",
  "0. everything is in lowercase.",
  "1. Always show top 5 for each case even time period or any other unless mentioned in query.",
  "2. Display amounts, payments, and charges in millions (M) and numerical values in thousands (K). Apply these formats to data labels as well.",
  "3. Always add data labels.",
  "4. Resize the figure to ensure all elements are visible, and increase the figure size if necessary.",
  "5. Ensure X and Y axes are visible, properly scaled, and labels are not overlapping.",
  "6. Rotate x-axis labels by 45° or 90° if they are too long, and align them using 'right' or 'center'.",
  "7. Use plt.tight_layout() for automatic layout adjustments, and apply explicit adjustments if needed.",
  "8. Check for overlapping labels on the x-axis to ensure bars remain visible.",
  "9. Use full names from columns when queries have incomplete values.",
  "10. Ensure a gap between the title and figure to avoid overlap.",
  "11. Adjust x-axis label alignment directly in plt.xticks() instead of using ha in set_tick_params.",
  "12. Handle label rotation and alignment within the plot, not through ha in ax.tick_params().",
  "13. Add annotations to highlight significant data points.",
  "14. Always use (data['Payer/Insurance Name'].str.contains('etc'))] if working with payer name.",
  "15. Always use (data['Payee / Provider Name'].str.contains('etc'))] if working with payee name.",
  "16. By default, take the latest year from data and query accordingly. Label the year and mention the year explicitly.",
  "17. If you are giving a line chart, include all points in the x-axis.",
  "18. Always sum values to minimize points over months.",
  "19. Ensure you convert the 'Month Of Year in number' column to integers before formatting it.",
  "20. Ensure every datatype is validated before working.",
  "21. Ensure column names and datatypes are accurate and consistent.",
  "22. Combine the 'Year' and 'Month Of Year in number' columns as strings and create a new 'date' column in the format 'YYYY-MM'. Then convert this string to datetime using `pd.to_datetime()` for accurate date handling.",
  //"22. Convert the 'Year' and 'Month Of Year in number' columns to datetime using `pd.to_datetime()` by assigning a default day (e.g., 1).",
  "23. Validate that 'Year' and 'Month Of Year in number' are integers before converting them to datetime to avoid errors.",
  "24. Losing money means Denial Amount.",
  "25. If asked for the reason for losing money, include 'Reason Code' and 'CPT Code'.",
  "26. For 'Where am I losing money?', use Payer/Insurance.",
  "27. claim / claims means records.",
  "28. Handle cases where required columns like 'Resolution Time' are missing, including fallback logic or error handling.",
  "29. Match column names exactly as per the dataset, considering case sensitivity and spaces.",
  "30. Always use one df dont Combine or groupby for answering",
  "always dont use this strftime",

  "Key Terms:",
  " - 'ha': Horizontal alignment ('left', 'center', 'right').",
  " - 'K': Thousands.",
  " - 'M': Millions.",
  " - 'plt.tight_layout()': Adjusts subplot parameters to fit all elements.",

  "Terminology:",
  "you have 835 and 837 data of different 'Insurance'.,",
  "**Payer Name**: Also known as 'Insurance'.",
  "**Payment Amount**: Also referred to as 'Revenue Amount'.",
  "**Service Line**: Also called 'CPT Code' or 'Procedure Codes'.",
  "**Revenue Code**: A 3-digit code within the Service Line or CPT Code.",
  "**CPT Code**: A 5-digit code within the Service Line or CPT Code.",
];
export const LidaPlottingInstructionsDataFrame = [
  "",
  "Instructions:",
  "0. everything is in lowercase.",
  "1. Always show top 5 for each case even time period or any other unless mentioned in query.",
  "2. Display amounts, payments, and charges in millions (M) and numerical values in thousands (K). Apply these formats to data labels as well.",
  //"3. Always add data labels."  ,
  //"4. Resize the figure to ensure all elements are visible, and increase the figure size if necessary."  ,
  //"5. Ensure X and Y axes are visible, properly scaled, and labels are not overlapping."  ,
  //"6. Rotate x-axis labels by 45° or 90° if they are too long, and align them using 'right' or 'center'.",
  //"7. Use plt.tight_layout() for automatic layout adjustments, and apply explicit adjustments if needed." ,
  //"8. Check for overlapping labels on the x-axis to ensure bars remain visible."  ,
  "3. try to give me answer in tow columns",
  "4. always use only columns  which you will use as for plot ",
  "9. Use full names from columns when queries have incomplete values.",
  //"10. Ensure a gap between the title and figure to avoid overlap."  ,
  //"11. Adjust x-axis label alignment directly in plt.xticks() instead of using ha in set_tick_params.",
  //"12. Handle label rotation and alignment within the plot, not through ha in ax.tick_params()."  ,
  //"13. Add annotations to highlight significant data points."  ,

  "14. Always use (data['Payer/Insurance Name'].str.contains('etc'))] if working with payer name.",
  "15. Always use (data['Payee / Provider Name'].str.contains('etc'))] if working with payee name.",
  "16. By default, take the latest year from data and query accordingly. Label the year and mention the year explicitly.",
  //"17. If you are giving a line chart, include all points in the x-axis."  ,
  "18. Always sum values to minimize points over months.",
  "19. Ensure you convert the 'Month Of Year in number' column to integers before formatting it.",
  "20. Ensure every datatype is validated before working.",
  "21. Ensure column names and datatypes are accurate and consistent.",
  "22. Combine the 'Year' and 'Month Of Year in number' columns as strings and create a new 'date' column in the format 'YYYY-MM'. Then convert this string to datetime using `pd.to_datetime()` for accurate date handling.",
  //"22. Convert the 'Year' and 'Month Of Year in number' columns to datetime using `pd.to_datetime()` by assigning a default day (e.g., 1).",
  "23. Validate that 'Year' and 'Month Of Year in number' are integers before converting them to datetime to avoid errors.",
  "24. Losing money means Denial Amount.",
  "25. If asked for the reason for losing money, include 'Reason Code' and 'CPT Code'.",
  "26. For 'Where am I losing money?', use Payer/Insurance.",
  "27. claim / claims means records.",
  "28. Handle cases where required columns like 'Resolution Time' are missing, including fallback logic or error handling.",
  "29. Match column names exactly as per the dataset, considering case sensitivity and spaces.",
  "6. dont go for Plotting",
  //"KeyError: '__import__' remove this error",

  "Terminology:",
  "you have 835 and 837 data of different 'Insurance'.,",
  "**Payer Name**: Also known as 'Insurance'.",
  "**Payment Amount**: Also referred to as 'Revenue Amount'.",
  "**Service Line**: Also called 'CPT Code' or 'Procedure Codes'.",
  "**Revenue Code**: A 3-digit code within the Service Line or CPT Code.",
  "**CPT Code**: A 5-digit code within the Service Line or CPT Code.",
];
export const LidaInstructions = [
  "make the chart height and width equal",
  "change the color of the chart to red and green",
  "translate the chart to Spanish, use font family of Arial",
  "add a title to the chart",
];

export const sampleQuestionsPrompts = [
  {
    image: PromptQuIcon,
    title: "Mac Based Question",
    titleMsg:
      "Seek advice from your Medicare Administrative Contractor for payment-related queries.",
    msg: `what are the appropriate anatomic modifiers for procedure codes 15820 and 15821? `,
    allow: ["WPS Insurance Corporation", "NGS"],
  },
  {
    image: PromptQuIcon,
    title: "Mac Based Question",
    titleMsg:
      "Seek advice from your Medicare Administrative Contractor for payment-related queries.",

    msg: `What are the latest Policy changes for <Payer>?`,
    allow: ["Palmetto GBA"],
  },
  {
    image: PromptQuIcon,
    title: "Mac Based Question",
    titleMsg:
      "Seek advice from your Medicare Administrative Contractor for payment-related queries.",

    msg: `Which HCPCS code specifies a segmental pneumatic appliance designed for use with a pneumatic compressor that includes calibrated gradient pressure, specifically for treating lymphedema or chronic venous insufficiency (CVI) with ulcers? `,
    allow: ["CGS Administrators"],
  },
  {
    image: PromptQuIcon,
    title: "Mac Based Question",
    titleMsg:
      "Seek advice from your Medicare Administrative Contractor for payment-related queries.",

    msg: `What is guideline to report CPT 97597 and 97598 in same encounter? `,
    allow: ["First Coast Service Options, Inc."],
  },
  {
    image: PromptQuIcon,
    title: "Mac Based Question",
    titleMsg:
      "Seek advice from your Medicare Administrative Contractor for payment-related queries.",

    msg: `Which modifier is used for voluntary issued ABN for CHD7 Gene Analysis? `,
    allow: ["Noridian"],
  },
  {
    image: PromptQuIcon,
    title: "Mac Based Question",
    titleMsg:
      "Seek advice from your Medicare Administrative Contractor for payment-related queries.",

    msg: `when can we use ICD code Z01.810 in Cardiovascular Nuclear Medicine procedure? `,
    allow: ["Novitas Solutions"],
  },
  // {
  //   image: PromptQuIcon,
  //   title: "Mac Based Question",
  //   msg: `Which modifier is required to be used with the procedure of induced abortion? `,
  // },
  {
    image: PromptQuIcon,
    title: "CMS National",
    msg: `What are the CMS guidelines for billing and reimbursement for durable medical equipment (DME) under Medicare?`,
    selectedTags: [{ id: "CMS_National", display: "CMS_National" }],
    convertedIndexName: `What are the CMS guidelines for billing and reimbursement for durable medical equipment (DME) under Medicare?`,
    simpleIndex: `What are the CMS guidelines for billing and reimbursement for durable medical equipment (DME) under Medicare?`,
  },
  {
    image: PromptQuIcon,
    title: "Private Insurance",
    msg: `What are the initial criteria for approval for pembrolizumab?
    @Aetna `,
    selectedTags: [{ id: "Aetna", display: "Aetna" }],
    convertedIndexName: `What are the initial criteria for approval for pembrolizumab?  @[Aetna](Aetna)`,
    simpleIndex: `What are the initial criteria for approval for pembrolizumab? Aetna`,
  },
  {
    image: PromptQuIcon,
    title: "NCCN Guidelines",
    msg: `What are the recommended options as per NCCN, for use in first line settings for metastatic kidney cancer?`,
  },
  {
    image: PromptQuIcon,
    title: "FDA",
    msg: `What is the indication for the use of Opdivo  as per FDA package insert?`,
  },
  {
    image: PromptQuIcon,
    title: "Medical Necessity",
    msg: `Is CPT code 97010 considered medically necessary as per Cigna?`,
  },
  {
    image: PromptQuIcon,
    title: "Allowed Units",
    msg: `How many units are allowed for code 90715 Practitioner? `,
  },
  {
    image: PromptQuIcon,
    title: "Bundle/Mutually exclusive",
    msg: `Are CPT 44376 and 43251 mutually exclusive? `,
  },
  // {
  //   image: PromptQuIcon,
  //   title: "Bundle/Mutually exclusive",
  //   msg: `Does CPT code 64450 bundle with 25426? `,
  // },
  {
    image: PromptQuIcon,
    title: "Fee schedule",
    msg: `What is allowed amount for CPT code 44388 in facility fee schedule? `,
  },
];
export const sampleQuestionsPromptsSuggested = [
  {
    image: PromptQuIcon,
    title: "MAC",
    titleMsg:
      "Seek advice from your Medicare Administrative Contractor for payment-related queries.",
    msg: `what are the appropriate anatomic modifiers for procedure codes 15820 and 15821? `,
    allow: ["WPS Insurance Corporation", "NGS"],
  },
  {
    image: PromptQuIcon,
    title: "MAC",
    titleMsg:
      "Seek advice from your Medicare Administrative Contractor for payment-related queries.",

    msg: `What are the latest Policy changes for <Payer>?`,
    allow: ["Palmetto GBA"],
  },
  {
    image: PromptQuIcon,
    title: "MAC",
    titleMsg:
      "Seek advice from your Medicare Administrative Contractor for payment-related queries.",

    msg: `Which HCPCS code specifies a segmental pneumatic appliance designed for use with a pneumatic compressor that includes calibrated gradient pressure, specifically for treating lymphedema or chronic venous insufficiency (CVI) with ulcers? `,
    allow: ["CGS Administrators"],
  },
  {
    image: PromptQuIcon,
    title: "MAC",
    titleMsg:
      "Seek advice from your Medicare Administrative Contractor for payment-related queries.",

    msg: `What is guideline to report CPT 97597 and 97598 in same encounter? `,
    allow: ["First Coast Service Options, Inc."],
  },
  {
    image: PromptQuIcon,
    title: "MAC",
    titleMsg:
      "Seek advice from your Medicare Administrative Contractor for payment-related queries.",

    msg: `Which modifier is used for voluntary issued ABN for CHD7 Gene Analysis? `,
    allow: ["Noridian"],
  },
  {
    image: PromptQuIcon,
    title: "MAC",
    titleMsg:
      "Seek advice from your Medicare Administrative Contractor for payment-related queries.",

    msg: `when can we use ICD code Z01.810 in Cardiovascular Nuclear Medicine procedure? `,
    allow: ["Novitas Solutions"],
  },

  {
    image: PromptQuIcon,
    title: "CMS National",
    titleMsg:
      "Inquire about policy coverage from CMS National for accurate guidance.",
    msg: `What are the CMS guidelines for billing and reimbursement for durable medical equipment (DME) under Medicare?`,
    selectedTags: [{ id: "CMS_National", display: "CMS_National" }],
    convertedIndexName: `What are the CMS guidelines for billing and reimbursement for durable medical equipment (DME) under Medicare?`,
    simpleIndex: `What are the CMS guidelines for billing and reimbursement for durable medical equipment (DME) under Medicare?`,
  },
  {
    image: PromptQuIcon,
    title: "Private Payers",
    titleMsg:
      "Get personalized coverage insights by consulting with private payers.",
    msg: `What are the initial criteria for approval for pembrolizumab?
    @Aetna `,
    selectedTags: [{ id: "Aetna", display: "Aetna" }],
    convertedIndexName: `What are the initial criteria for approval for pembrolizumab?  @[Aetna](Aetna)`,
    simpleIndex: `What are the initial criteria for approval for pembrolizumab? Aetna`,
  },

  {
    image: PromptQuIcon,
    title: "FDA",
    titleMsg:
      "Access procedural recommendations and regulatory insights from the FDA.",

    msg: `What is the indication for the use of Opdivo  as per FDA package insert?`,
  },
];
export const lunaSampleGroupQuestions = [
  {
    title: "Denial Trends/Analytics",
    questions: [
      {
        value: "Create a line chart showing monthly denials rates for <year>?",
      },
      {
        value:
          "What are the top five denial reasons for our claims this month?",
      },

      {
        value: "Which departments are experiencing the highest denial rates?",
        disabled: true,
      },
      {
        value:
          "How have our denial percentage trended over the past six months?",
      },
      {
        value:
          "What is the financial impact of denials on our revenue for this quarter?",
      },
      // {
      //   value:
      //     "Can you show the top 5 denied claims by dollar value for this month?",
      // },
    ],
  },
  {
    title: "Payer-Specific Denials",
    questions: [
      {
        value:
          "Which payers have the highest percentage of denied claims in last 6 months?",
      },
      {
        value: "What is the denial trend for Humana, Aetna?",
        disabled: true,
      },
      {
        value: "Are there specific trends in denials from Aetna?",
      },
      {
        value: "What are the common denial codes received from Cigna?",
      },
      {
        value: "What is the average time to resolve denials from [Payer Name]?",
        disabled: true,
      },
    ],
  },
  {
    title: "Root Cause Analysis ",
    questions: [
      {
        value:
          "What are the top 5 coding-related denials (or can ask any other categories)?",
        disabled: true,
      },
      {
        value:
          "Can you show me the denial trend for different ICD codes over the last year?",
        disabled: true,
      },
      {
        value: "Which CPT codes have the highest denial rates this quarter?",
      },
    ],
  },
  {
    title: "Operational Insights",
    questions: [
      {
        value:
          "Which staff members are resolving the most denials successfully?",
        disabled: true,
      },
      {
        value:
          "How does the time to resolve denials vary across different departments?",
        disabled: true,
      },
      {
        value:
          "How many denial appeals are submitted weekly, and what is the success rate?",
        disabled: true,
      },
      {
        value: "What percentage of denied claims are overturned after appeal?",
        disabled: true,
      },
      {
        value:
          "How much revenue has been recovered from denied claims this quarter?",
      },
    ],
  },
];
export const PolicyCopilotSampleGroupQuestions = [
  {
    title: "Billing and Coding Guidelines",
    questions: [
      {
        value: "What is the guideline to bill CPT <code> and <code> together?",
      },
      {
        value:
          "What is the guideline to report CPT <code> and <code> in the same encounter?",
      },
      {
        value: "Does CPT <code> bundle with <code>?",
      },
      {
        value: "Are CPT <code> and <code> mutually exclusive?",
      },
      {
        value:
          "How many units are allowed for CPT <code> for outpatient facility?",
      },
      {
        value: "How to report breast sonography if performed bilaterally?",
      },
      {
        value: "How many units are allowed for CPT <code> for Practitioner?",
      },
    ],
  },
  {
    title: "Coverage and Medical Necessity",
    questions: [
      {
        value: "Does Medicare cover CPT <code> and <code>?",
      },
      {
        value:
          "Which ICD-10 codes support medical necessity with CPT <code> and <code>?",
      },
      {
        value:
          "Which diagnosis code supports medical necessity with CPT <code> for alcoholic hepatitis with ascites?",
      },
      {
        value:
          "Which diagnosis code is required for the coverage of bariatric surgery?",
      },
      {
        value:
          "When can we use ICD code <Code> in cardiovascular nuclear medicine procedures?",
      },
      {
        value:
          "Is CPT <code> considered medically necessary for chiropractic services by <Payer>?",
      },
      {
        value:
          "Which diagnosis codes are supported with insertion of sacral nerve stimulator electrode for Medicare?",
      },
      {
        value:
          "Are bunion surgical treatments considered medically necessary? (mm/dd/yyyy)",
      },
    ],
  },
  {
    title: "Procedure and Modifier Guidelines",
    questions: [
      {
        value:
          "Which HCPCS code specifies a segmental pneumatic appliance designed for use with a pneumatic compressor that includes calibrated gradient pressure, specifically for treating lymphedema or chronic venous insufficiency (CVI) with ulcers?",
      },
      {
        value:
          "Which modifier is used for a voluntarily issued ABN for CHD7 Gene Analysis?",
      },
      {
        value:
          "What are the appropriate anatomic modifiers for procedure CPT <code> and <code>?",
      },
    ],
  },
  {
    title: "Fee Schedule and Reimbursement",
    questions: [
      {
        value:
          "What is the allowed amount for CPT <code> in facility fee schedule?",
      },
      {
        value:
          "How much is the allowed amount for CPT <code> in non-facility fee schedule?",
      },
    ],
  },
  {
    title: "CMS Administrative Guidelines",
    questions: [
      {
        value:
          "What is the CMS policy on coverage for preventive services under Medicare?",
      },
      {
        value:
          "What are the annual changes to the Medicare Part D drug formulary and cost-sharing guidelines?",
      },
      {
        value:
          "What are the CMS guidelines for billing and reimbursement for durable medical equipment (DME) under Medicare?",
      },
    ],
  },
  {
    title: "Policy Updates",
    questions: [
      {
        value: "What is the summary of Policy changes in <Payer> for December?",
      },
      {
        value: "What are the latest Policy changes for <Payer>?",
      },
    ],
  },
];

const { userPreferences } = store.getState().pReducers.user;

export const changeMacAddress = (macAddress, jurisdiction) => {
  const selectedRow = userPreferences?.NoOfRecordInTable
    ? userPreferences?.NoOfRecordInTable
    : 10;

  const selectedDateRange = userPreferences?.DateRange
    ? userPreferences?.DateRange
    : "3 Month";

  const filterPin = userPreferences?.PinFilter
    ? userPreferences?.PinFilter
    : false;

  const selectedCoordinator = userPreferences?.CoordinatorPreference
    ? userPreferences?.CoordinatorPreference
    : null;

  let obj = {
    NoOfRecordInTable: `${selectedRow}`,
    Navigation: "left",
    Theme: "null",
    DateRange: `${selectedDateRange}`,
    PinFilter: filterPin,
    CoordinatorPreference:
      selectedCoordinator === "none" ? null : selectedCoordinator,
    MacAddressPreference: JSON.stringify({
      macAddress: macAddress,
      jurisdiction: jurisdiction,
    }),
  };

  store.dispatch(saveUserPreferences_Call2(obj)).then((res) => {
    if (res?.success) {
      store.dispatch(setPreferences(obj));
      store.dispatch(
        setAllFiltersNoOfRecordInTable({ noOfRecords: `${selectedRow}` })
      );
    }
  });
};
const isParsableToJSON = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};
export const getMacAddressPreference = () => {
  let data = {};

  if (
    isParsableToJSON(
      store.getState().pReducers.user.userPreferences.MacAddressPreference
    )
  ) {
    if (
      JSON.parse(
        store.getState().pReducers.user.userPreferences.MacAddressPreference
      )?.macAddress
    ) {
      data = JSON.parse(
        store.getState().pReducers.user.userPreferences.MacAddressPreference
      )?.macAddress;
    } else {
      data = copilotStates?.[0];
    }
  } else {
    data = copilotStates?.[0];
  }
  return data;
};
export const convertLunaEncryptedFile = (data) => {
  try {
    const compressedData = Uint8Array.from(atob(data), (char) =>
      char.charCodeAt(0)
    );
    // Step 2: Decompress Gzip
    const decompressedData = pako.inflate(compressedData, { to: "string" });
    return decompressedData;
    // Step 3: Display the HTML content
    // setHtmlContent(decompressedData);
  } catch (error) {}
};
export const getJurisdictionPreference = () => {
  let data = {};
  if (
    isParsableToJSON(
      store.getState().pReducers.user.userPreferences.MacAddressPreference
    )
  ) {
    if (
      JSON.parse(
        store.getState().pReducers.user.userPreferences.MacAddressPreference
      )?.jurisdiction
    ) {
      data = JSON.parse(
        store.getState().pReducers.user.userPreferences.MacAddressPreference
      )?.jurisdiction;
    } else {
      data = copilotStates?.[0]?.children?.[0];
    }
  } else {
    data = copilotStates?.[0]?.children?.[0];
  }
  // data = copilotStates?.[0]?.children?.[0];

  return data;
};
export const generateGUID = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
};
export const preserveChat = (payload) => {
  genericAxiosCall(serverRoutes?.PRESERVE_POLICY_CHAT, "post", payload, "");
};
export const preserveHistory = (payload) => {
  genericAxiosCall(
    serverRoutes?.PRESERVE_POLICY_CHAT_HISTORY,
    "post",
    payload,
    ""
  );
};
export const getPageNumber = (text) => {
  const pagePattern = /\(Page: (\d+) of (\d+)\)/g;

  // Find all matches in the text
  const matches = text.match(pagePattern);

  // Check if there are any matches
  if (!matches || matches.length === 0) {
    // console.log(null);
    return null;
  } else {
    // Remove duplicates
    const uniqueMatches = [...new Set(matches)];

    // Extract X and Z values from the first match
    const [x, z] = uniqueMatches[0].match(/\d+/g);

    // Create an object with X and Z values
    const result = { From: x, To: z };

    return result;
  }
};
export const returnCotentMessage = (contentMessage) => {
  const pageNumber = getPageNumber(contentMessage);
  const citationString = pageNumber?.From
    ? `From Page ${pageNumber?.From}:\n\n${contentMessage}`
    : contentMessage;
  return citationString;
};

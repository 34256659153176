// Configuration for dev and prod Server URL

// for prod
// export const environment = "prod";

// for pre-prod
// export const environment = "pre-prod";

// for staging
// export const environment = "staging";

// for dev
export const environment = "dev";

export const CognitiveSearch =
  environment === "dev"
    ? "https://csroversdev.search.windows.net/indexes/devsql-index/docs/search?api-version=2016-09-01"
    : "https://csroversprod.search.windows.net/indexes/roversprod-index/docs/search?api-version=2016-09-01";

//domainID
export const domainID =
  environment === "dev"
    ? "8700a71f-b5f5-43a5-be17-492c67008132"
    : "0E02C258-3EB9-4E39-B60B-71EA229D48A3";
//open api url

export const openApiBaseUrl =
  environment === "dev"
    ? "https://api.datarovers.dev"
    : "https://api.datarovers.ai";
//Cognitive Index
export const CongnitiveIndex =
  environment === "dev" ? "devsql-index" : "roversprod-index";

export const CognitiveKey =
  environment === "dev"
    ? "LPQ8erdBbIAKhQkYvyNgs8S0rQsC53bJx6ZNvAsjusAzSeDOf4Zm"
    : "KCYMKogHU1Oj4V6jbRimoDqcyRDum3pvTyF1ixaZuDAzSeDQoeja";

export const SocketURL =
  environment === "dev"
    ? "https://mwroversnotificationdevapp.azurewebsites.net/notify"
    : "https://mwroversnotificationapp.azurewebsites.net/notify";

// Server URL configuration for Prod and Dev
export const SERVER_URL =
  environment === "dev"
    ? "https://mwroversdevapp.azurewebsites.net"
    : environment === "staging"
    ? "https://mwroversstagapp.azurewebsites.net"
    : environment === "pre-prod"
    ? "https://mwroversapp-preprod.azurewebsites.net"
    : "https://mwroversapp.azurewebsites.net";

// Bot URL configuration
export const BOT_SERVER_URL =
  environment === "dev"
    ? "https://roverspolicybot.azurewebsites.net"
    : "https://roverspolicybot.azurewebsites.net";

export const serverRoutes = {
  LOG_ERROR: `${SERVER_URL}/api/LogError`,
  SIGNUP: `${SERVER_URL}/customer`,
  VERIFY_EMAIL: `${SERVER_URL}/v1/auth/verify/email`,
  VERIFY_EMAIL_NEW: `${SERVER_URL}/customer/VerifyCustomer`,
  GENERATE_EMAIL_LINK: `${SERVER_URL}/customer/GenrateNewEmail`,
  LOGIN: `${SERVER_URL}/user/LocalUserLogin`,
  APPLICATION_TOKEN: `${SERVER_URL}/token`,
  MFA_CODE_VERIFICATION: `${SERVER_URL}/user/SendMFACode?email=`,
  RETRIEVE_USER_BY_EMAIL: `${SERVER_URL}/user/RetrieveUserProfile?email=`,
  RETRIEVE_USER_BY_EMAIL_SSO: `${SERVER_URL}/user/RetrieveUserBySSO?email=`,
  LOGIN_WITH_SSO: `${SERVER_URL}/api/auth/login`,
  AUTHENTICATE_WITH_SSO: `${SERVER_URL}/api/auth/Authenticate?`,
  GET_ALL_ORGANISATION_USERS: `${SERVER_URL}/api/v1/policy-docs/copilot/users`,
  GET_USER_TYPES: `${SERVER_URL}/user/GetUserTypes`,
  GET_USER_INFO: `${SERVER_URL}/Customer/GetAzureTennentAndAppId`,
  SAVE_NEW_TENANT_ID: `${SERVER_URL}/Customer/SaveAzureTennentAndApplicationID`,

  //
  UPDATE_ORGANIZATION: `${SERVER_URL}/customer/UpdateCustomerBasicInfo`,
  GET_CITIES: `${SERVER_URL}/city`,
  GET_STATES: `${SERVER_URL}/state`,
  GET_COUNTRY: `${SERVER_URL}/country`,

  // for save page column hide/show setting
  SAVE_PAGE_COLUMN_HIDE_SHOW_PREF: `${SERVER_URL}/user/SaveUserPagePreference`,
  GET_PAGE_COLUMN_HIDE_SHOW_PREF: `${SERVER_URL}/User/GetUserPagePreference?`,

  // getting serviceLines for CARC Trends
  GET_SERVICE_LINES_LIST: `${SERVER_URL}/CPTCode/GetDepartmentJson`,
  GET_RESET_SERVICE_LINES_LIST: `${SERVER_URL}/CPTCode/ResetServiceLines`,

  // 837 filters here
  CLAIM_REQUEST_SERVICELINE_FILTER: `${SERVER_URL}/ClaimPayment/ServiceLinesFilter?`,
  CLAIM_REQUEST_PAYERS_FILTER: `${SERVER_URL}/ClaimRequest/GetClaimByPayersFilter?`,
  CLAIM_REQUEST_FILLING_INDICATOR_FILTER: `${SERVER_URL}/ClaimRequest/GetClaimByFillingIndicatorFilter?`,
  CLAIM_REQUEST_PROVIDERS_FILTER: `${SERVER_URL}/ClaimRequest/GetClaimByProvidersFilter?`,
  CLAIM_REQUEST_FREQUENCY_TYPE_FILTER: `${SERVER_URL}/ClaimRequest/GetClaimByFrequencyFilter?`,
  CLAIM_REQUEST_DIAGNOSE_CODES_FILTER: `${SERVER_URL}/ClaimRequest/GetClaimByDiagnoseCodeFilter?`,
  CLAIM_REQUEST_CPT_CODES_FILTER: `${SERVER_URL}/claimrequest/GetClaimByCPTCodeFilter?`,
  CLAIM_REQUEST_DRG_CODES_FILTER: `${SERVER_URL}/claimrequest/GetClaimByDRGCodeFilter?`,
  CLAIM_REQUEST_BILLING_PROVIDER_FILTER: `${SERVER_URL}/claimrequest/GetClaimByBillingProviderFilter?`,
  CLAIM_REQUEST_RENDERING_PROVIDER_FILTER: `${SERVER_URL}/claimrequest/GetClaimByRenderingProviderFilter?`,
  CLAIM_REQUEST_ATTENDING_PROVIDER_FILTER: `${SERVER_URL}/claimrequest/GetClaimByAttendingProviderFilter?`,

  // 837 url
  GET_CLAIM_REQUEST_PAYERS_LIST: `${SERVER_URL}/claimrequest/PayerList?`,
  GET_CLAIM_REQUEST_PROVIDERS_LIST: `${SERVER_URL}/ClaimRequest/BillingProviders?`,
  GET_CLAIM_REQUEST_DIAGNOSIS_LIST: `${SERVER_URL}/claimrequest/DiagnosisCodes?`,
  GET_CLAIM_REQUEST_CPT_LIST: `${SERVER_URL}/claimrequest/CPTCodes?`,

  GET_CLAIM_REQUEST_837: `${SERVER_URL}/claimrequest/GetClaimRequests?`,
  GET_CLAIM_REQUEST_837_FILTERS_API: `${SERVER_URL}/claimrequest/GetClaimRequestsFiltered?`,
  GET_CLAIM_REQUEST_837_TABULAR_DATA_API: `${SERVER_URL}/claimrequest/GetClaimRequests?`,
  DELETE_CLAIM_837: `${SERVER_URL}/claimrequest/`,

  GET_SERVICELINE_FOR_DROPDOWN_837: `${SERVER_URL}/ClaimRequest/ClaimRequestDetail?ClaimRequestId=`,

  GET_FLAGS: `${SERVER_URL}/flag`,
  CREATE_FLAGS: `${SERVER_URL}/flag/CreateFlagging`,

  GET_TAGS: `${SERVER_URL}/tag`,
  CREATE_TAGS: `${SERVER_URL}/tag/CreateTagging`,

  GET_USERS: `${SERVER_URL}/user`,
  GET_USER_BY_ID: `${SERVER_URL}/user/GetUserById?`,

  CREATE_ASSIGNEE: `${SERVER_URL}/Assign/CreateAssigning`,

  // institual claim details
  // institual claim details
  REQUEST_DETAIL_837: `${SERVER_URL}/claimrequest/`,
  DETAIL_OTHER_SUBSCRIBER_INFO_837: `${SERVER_URL}/claimrequest/OtherSubscriber?claimRequestId=`,
  GET_ORIGINAL_CLAIM_ID_FOR_SHOW_BTN_837: `${SERVER_URL}/claimrequest/OrignalClaim/`,
  DETAIL_OTHER_CLAIM_SERVICE_837: `${SERVER_URL}/claimrequest/claimservice/`,
  DETAIL_SUBSCRIBER_MORE_INFO_837: `${SERVER_URL}/claimrequest/ClaimMoreDetail?claimRequestId=`,
  DETAIL_OTHER_DIAGNOSE_CODE_837: `${SERVER_URL}/claimrequest/ClaimDiagnosis?claimRequestId=`,
  DETAIL_SERVICE_ADJUSTMENTS_837: `${SERVER_URL}/claimpayment/GetServiceAdjustments/`,
  GET_STEPPER_SECTION_837: `${SERVER_URL}/claimrequest/ClaimLinkage/`,
  GET_DURATION_BUCKET_WISE_837: `${SERVER_URL}/claimrequest/GetClaimRequestsBuckets?`,
  GET_WRITE_OFF_CLAIM_837: `${SERVER_URL}/claimrequest/WriteOffClaims?`,

  GET_RELATED_CLAIMS: `${SERVER_URL}/ClaimRequest/ClaimLinkage/`,
  GET_RELATED_CLAIMS_837: `${SERVER_URL}/claimpayment/AllPaymentsByPayerCT/`,

  GET_RESUBMISSION_CLAIM_CLAIMS_837: `${SERVER_URL}/claimpayment/ResubmittedClaims?PayerClaimControlNo=`,

  // Filter API's for 835 module
  CLAIM_PAYMENT_PAYERS_FILTER: `${SERVER_URL}/ClaimPayment/PayerFilter?`,
  CLAIM_PAYMENT_SERVICELINE_FILTER: `${SERVER_URL}/ClaimPayment/ServiceLinesFilter?`,
  CLAIM_PAYMENT_CLAIMSTATUS_FILTER: `${SERVER_URL}/ClaimPayment/ClaimStatusCodeFilter?`,
  CLAIM_PAYMENT_REASONCODE_FILTER: `${SERVER_URL}/ClaimPayment/ReasonCodeFilter?`,
  CLAIM_PAYMENT_PROVIDER_FILTER: `${SERVER_URL}/ClaimPayment/PayeeFilter?`,
  CLAIM_PAYMENT_FILLING_INDICATOR_FILTER: `${SERVER_URL}/ClaimPayment/FilingIndicatorCodeFilter?`,
  CLAIM_PAYMENT_BILLING_PROVIDER_FILTER: `${SERVER_URL}/ClaimPayment/GetBillingProviderFilter?`,
  CLAIM_PAYMENT_RENDERING_PROVIDER_FILTER: `${SERVER_URL}/ClaimPayment/GetRenderingProviderFilter?`,
  CLAIM_PAYMENT_IPREMARK_CODE_FILTER: `${SERVER_URL}/ClaimPayment/RemarkCodeFilter?`,
  CLAIM_PAYMENT_CPTCODE_FILTER: `${SERVER_URL}/ClaimPayment/CPTCodeFilter?`,
  CLAIM_PAYMENT_GROUPREASONCODE_FILTER: `${SERVER_URL}/ClaimPayment/ReasonGroupCodeFilter?`,
  CLAIM_PAYMENT_DRG_FILTER: `${SERVER_URL}/ClaimPayment/ERAFilterDRGCode?`,
  // professional claim details
  DETAIL_CLAIM_SUMMARY_MISCELLANEOUS_INFO_837: `${SERVER_URL}/claimrequest/ProfessionalClaimMoreDetail?claimRequestId=`,
  DETAIL_CLAIM_SERVICE_MISCELLANEOUS_INFO_837: `${SERVER_URL}/claimrequest/ProClaimService/`,

  // 835 url
  GET_PAYMENT_835: `${SERVER_URL}/claimpayment/GetClaims?`,
  GET_PAYMENT_835_FILTERS: `${SERVER_URL}/claimpayment/GetClaimsFiltered?`,
  GET_PAYMENT_835_TABULAR_DATA: `${SERVER_URL}/claimpayment/GetClaims?`,
  GET_UNDER_PAYMENT_835_TABULAR_DATA: `${SERVER_URL}/ClaimPayment/GetClaimsWithMLAmountPrediction?`,
  DELETE_PAYMENT_835: `${SERVER_URL}/claimpayment/`,
  GET_PAYMENT_DROPDOWN_835: `${SERVER_URL}/ServicePayment/ClaimAmountDetail?ClaimPaymentId=`,
  GET_STEPPER_SECTION_835: `${SERVER_URL}/claimpayment/ClaimLinkage/`,
  CLAIM_837_FOLLOW_UP: `${SERVER_URL}/WorkQueue/InsertOrUpdateFollowUp`,
  //DELETE
  DELETE_FOLLOW_UP: `${SERVER_URL}/WorkQueue/DeleteFollowUPData`,

  GET_CLAIM_837_FOLLOW_UP: `${SERVER_URL}/WorkQueue/GetFollowUPData?ClaimPaymentId=`,
  SAVE_CLAIM_PAYMENT_CUSTOM_COLUMNS: `${SERVER_URL}/claimpayment/SaveClaimPaymentAdditionalInfo`,
  GET_CLAIM_PAYMENT_CUSTOM_COLUMNS: `${SERVER_URL}/Claimpayment/GetClaimpaymentAdditionInfoConfig?`,
  SAVE_CUSTOM_COLUMNS_CONFIG: `${SERVER_URL}/Subscription/SaveSubscriptionSettings`,
  GET_CLAIM_PAYMENT_CUSTOM_COLUMNS_LOOKUP_DATA: `${SERVER_URL}/Lookup/GetLookupByName?`,
  GET_CLAIM_PAYMENT_HISTORY_DATA: `${SERVER_URL}/AppealsConfig/GetClaimHistory?`,

  GET_PAYMENT_CPT_LIST: `${SERVER_URL}/cptcode/all?`,
  GET_JCODE_LIST: `${SERVER_URL}/cptcode/jcode?`,
  GET_PAYMENT_PAYERS_LIST: `${SERVER_URL}/claimpayment/PayerList?`,
  GET_PAYMENT_CLAIM_STATUS_LIST: `${SERVER_URL}/ClaimStatusCode/GetAllCSC?`,
  GET_PAYMENT_CARC_LIST: `${SERVER_URL}/common/GetCARCWithClaimAndServiceCount?`,

  GET_PAYMENT_DETAIL_835: `${SERVER_URL}/claimpayment/`,
  GET_PAYMENT_DETAIL_SERVICE_PAYMENT_835: `${SERVER_URL}/servicepayment/`,
  GET_PAYMENT_DETAIL_CLAIM_ADJUSTMENTS_835: `${SERVER_URL}/claimpayment/GetClaimAdjustments/`,
  GET_PAYMENT_DETAIL_CLAIM_MISCELLANEOUS_MORE_DATA_835: `${SERVER_URL}/claimpayment/MoreDetail?ClaimPaymentId=`,
  GET_PAYMENT_DETAIL_SERVICE_ADJUSTMENTS_835: `${SERVER_URL}/claimpayment/GetServiceAdjustments/`,
  GET_PAYMENT_DETAIL_CLAIM_TYPES_835: `${SERVER_URL}/claimpayment/GetPatientMultipleInsuranceCoverages?claimPaymentId=`,
  GET_PAYMENT_DETAIL_CLAIM_TYPES_835_PRINT: `${SERVER_URL}/ClaimPayment/GetClaimPaymentEOB?`,
  GET_PAYMENT_DETAIL_CPTS_BUNDLE_SUGGESTIONS: `${SERVER_URL}/claimrequest/NotAllowedCPTCodes?`,

  GET_PAYMENT_DETAIL_CLAIM_TYPES_835_UPDATE_API: `${SERVER_URL}/ClaimPayment/AllPayments/`,
  GET_SERVICELINE_DETAIL_ONLY_SELECTED_CPT_API: `${SERVER_URL}/servicepayment/ServicePaymentByCptCode?claimPaymentId=`,
  GET_CLAIN_ADJ_LINE_835_API: `${SERVER_URL}/claimpayment/GetClaimAdjustments/`,
  GET_SERVICELINE_DETAIL_WITH_PAGINATION_API: `${SERVER_URL}/claimrequest/WorkQueueClaimService?`,

  // patient time line screen
  GET_PATIENT_TIME_LINE_DATA: `${SERVER_URL}/claimpayment/GetPatientTimeLine/`,

  // application user screen
  GET_USER: `${SERVER_URL}/user?`,

  ADD_NEW_USER: `${SERVER_URL}/user`,
  UPDATE_USER_PWD: `${SERVER_URL}/password`,

  // DataSource Screen
  GET_ALL_DATA_SOURCE_LIST: `${SERVER_URL}/datasource/GetFTPDataSources`,
  GET_DATA_SOURCE_UPDATE_ITEM: `${SERVER_URL}/datasource/GetFTPDataSourceById?`,
  GET_FREQUENCY_TYPES_LIST: `${SERVER_URL}/datasource/GetFrequencyTypes`,
  GET_SOURCE_TYPES_LIST: `${SERVER_URL}/datasource/GetSourceTypes`,
  GET_SOURCE_FILES_SUCCESS_FAIL_LIST: `${SERVER_URL}/datasource/GetLogsDetailById?`,

  DELETE_FTP_SOURCE: `${SERVER_URL}/datasource/deleteftpsource/`,
  RUN_DATA_SOURCE_JOB: `${SERVER_URL}/datasource/ResetFTPSourceUploadFrequency/`,
  CREATE_NEW_DATA_SOURCE: `${SERVER_URL}/datasource/CreateFTPDataSource`,
  UPDATE_DATA_SOURCE: `${SERVER_URL}/datasource/UpdateFTPDataSource`,
  TEST_API_CONNECTION: `${SERVER_URL}/datasource/APIConnect`,
  TEST_FTP_SFTP_CONNECTION: `${SERVER_URL}/datasource/FTPConnect`,
  GET_DATA_SOURCE_ERRORS: `${SERVER_URL}/datasource/GetSourceErrors/`,
  GET_DATA_ACTIVITY_ERRORS: `${SERVER_URL}/datasource/GetSourcePositions/`,
  GET_JOB_EVENTS_ERRORS_BY_DATES: `${SERVER_URL}/datasource/GetSourceEventsByDates/`,
  GET_JOB_EVENTS_ERRORS: `${SERVER_URL}/datasource/GetSourceEvents?`,

  // Upload Screen
  UPLOAD_837_FILES: `${SERVER_URL}/FTPUploader/Upload`,
  UPLOAD_837_AND_835_FILES: `${SERVER_URL}/FTPUploader/Upload`,

  // Denials Detail screen
  GET_ALL_DENIALS_DETAIL: `${SERVER_URL}/Denials/detail?`,
  GET_ALL_DENIALS_DETAIL_GB_CPT_CODE: `${SERVER_URL}/Denials/detailGroupByCPTCode?`,
  GET_ALL_DENIALS_DETAIL_GB_REASON_CODE: `${SERVER_URL}/Denials/detailGroupByReasonCode?`,
  GET_ALL_DENIALS_DETAIL_GB_PAYER: `${SERVER_URL}/Denials/detailGroupByPayer?`,

  GET_DENIALS_GROUP_BY_SERVICE_LINE_GB_PAYER_CN: `${SERVER_URL}/Denials/servicedetailPCN?`,
  GET_DENIALS_GROUP_BY_SERVICE_LINE: `${SERVER_URL}/Denials/servicedetail?`,
  GET_DENIALS_GROUP_BY_SERVICE_LINE_FILTERS: `${SERVER_URL}/Denials/servicedetailfilter?`,

  // Exective Dashboard screen
  GET_EXECTIVE_DASH_KPIS: `${SERVER_URL}/Exectivedashboard/GetExecutiveDashBoardKpis?`,
  GET_EXECTIVE_DASH_FIRST_PASS_FAILURE_RATE: `${SERVER_URL}/SmartInsights/FirstAcceptanceRate?`,
  GET_EXECTIVE_DASH_HARD_AND_SOFT_DENIAL: `${SERVER_URL}/Denials/HardAndSoftDenial?`,
  GET_INSIGHTS_REASON_CODE_HEAT_MAP: `${SERVER_URL}/Exectivedashboard/GetHeatmapDashboardKpis?`,
  GET_INSIGHTS_REASON_CODE_HEAT_MAP_FILTERS: `${SERVER_URL}/Exectivedashboard/GetHeatmapFilters?`,
  GET_REVENUE_HEAT_MAP: `${SERVER_URL}/Exectivedashboard/GetRevenueHeatmap?`,

  GET_INSIGHTS_REASON_CODE_DEAPRTMENTS: `${SERVER_URL}/CPTCode/GetCPTLevel0Visible`,
  // Proveder Dashboard screen
  GET_PROVIDER_DASH_KPIS: `${SERVER_URL}/Exectivedashboard/GetProviderDashbaordkpis?`,

  // oncology Dashboard screen
  GET_ONCOLOGY_DASH_KPIS: `${SERVER_URL}/Analysis/GetOncologyDashbaordkpis?`,
  GET_ONCOLOGY_DASH_TOP_CARD_KPIS: `${SERVER_URL}/Analysis/GetOncologyTopCardkpis?`,
  GET_ONCOLOGY_DASH_TIMELINE_KPIS: `${SERVER_URL}/Analysis/GetOncologyTimelinekpis?`,
  GET_ONCOLOGY_DASH_TOP_DENIAL_CODE_KPIS: `${SERVER_URL}/Analysis/GetOncologyTopDenailCodekpis?`,
  GET_DRUG_REJECTED_BY_CODE: `${SERVER_URL}/CPTCode/GetDrugRejectedByCode?`,
  GET_DRUG_REJECTED_BY_CODE_FOR_ML: `${SERVER_URL}/ClaimPayment/GetPredictedServiceLinesFirstPass?`,

  GET_ONCOLOGY_DASH_FILTERS: `${SERVER_URL}/Analysis/GetOncologyFilterkpis?`,
  GET_ONCOLOGY_TOP_REASONCODE_BY_CPT_KPIS: `${SERVER_URL}/Analysis/GetOncologyTopReasonByCptkpis?`,
  GET_ONCOLOGY_DENIAL_DASH_KPIS: `${SERVER_URL}/Analysis/GetAllTreeMapData?`,
  GET_ONCOLOGY_DENIAL_DASH_CPT_JCODE_KPIS: `${SERVER_URL}/Analysis/GetTreeMapData/CPT/JCodes?`,
  GET_ONCOLOGY_DENIAL_DASH_CPT_ANCILLARY_KPIS: `${SERVER_URL}/Analysis/GetTreeMapData/CPT/Ancillary?`,
  GET_ONCOLOGY_DENIAL_DASH_CPT_PREMED_KPIS: `${SERVER_URL}/Analysis/GetTreeMapData/CPT/Premed?`,
  GET_ONCOLOGY_DENIAL_DASH_CPT_ALLCODES_KPIS: `${SERVER_URL}/Analysis/GetTreeMapData/CPT/AllCodes?`,
  GET_ONCOLOGY_DENIAL_DASH_CPT_ADMIN_CODE_KPIS: `${SERVER_URL}/Analysis/GetTreeMapData/CPT/AdminCode?`,
  GET_ONCOLOGY_DENIAL_DASH_CARC_ALLCODES_KPIS: `${SERVER_URL}/Analysis/GetTreeMapData/CARC/AllCodes?`,
  GET_ONCOLOGY_DENIAL_DASH_CARC_JCODE_KPIS: `${SERVER_URL}/Analysis/GetTreeMapData/CARC/JCodes?`,
  GET_ONCOLOGY_DENIAL_DASH_CARC_ANCILLARY_KPIS: `${SERVER_URL}/Analysis/GetTreeMapData/CARC/Ancillary?`,
  GET_ONCOLOGY_DENIAL_DASH_CARC_PREMED_KPIS: `${SERVER_URL}/Analysis/GetTreeMapData/CARC/PreMed?`,
  GET_ONCOLOGY_DENIAL_DASH_CARC_ADMINCODE_KPIS: `${SERVER_URL}/Analysis/GetTreeMapData/CARC/Admin?`,

  GET_ONCOLOGY_DASH_SOFTDENIAL_BARCHART: `${SERVER_URL}/Exectivedashboard/GetSoftDenialKPIBarChartTimeLine?`,

  //Oncology Divided Api's

  // Bar Chart Api's
  GET_ONCOLOGY_DASH_KPIS_BARCHART_JCODE: `${SERVER_URL}/Analysis/GetOncologyDashboardJCode?`,
  GET_ONCOLOGY_DASH_KPIS_BARCHART_ANCILLARY: `${SERVER_URL}/Analysis/GetOncologyDashboardAcillary?`,
  GET_ONCOLOGY_DASH_KPIS_BARCHART_PREMED: `${SERVER_URL}/Analysis/GetOncologyDashboardPremed?`,
  GET_ONCOLOGY_DASH_KPIS_BARCHART_ADMIN: `${SERVER_URL}/Analysis/GetOncologyDashboardAdminCode?`,
  // Line Chart Api's
  GET_ONCOLOGY_DASH_KPIS_LINECHART_ALL: `${SERVER_URL}/Analysis/GetOncologyAllCodeTimeline?`,
  GET_ONCOLOGY_DASH_KPIS_LINECHART_JCODE: `${SERVER_URL}/Analysis/GetOncologyJCodeTimeline?`,
  GET_ONCOLOGY_DASH_KPIS_LINECHART_ANCILLARY: `${SERVER_URL}/Analysis/GetOncologyAcillaryTimeline?`,
  GET_ONCOLOGY_DASH_KPIS_LINECHART_PREMED: `${SERVER_URL}/Analysis/GetOncologyPremedTimeline?`,
  GET_ONCOLOGY_DASH_KPIS_LINECHART_ADMIN: `${SERVER_URL}/Analysis/GetOncologyAdminCodeTimeline?`,

  // Top Drug Denials treeMap screen
  GET_TOP_DRUGS_KPIS: `${SERVER_URL}/Analysis/GetOncologyTopDrugskpis?`,

  // claim Dashboard screen
  GET_CLAIM_DASH_NORMAL_KPIS: `${SERVER_URL}/Exectivedashboard/GetClaimDashbaordkpis?`,
  GET_CLAIM_DASH_PRE_CALCULATED_KPIS: `${SERVER_URL}/Exectivedashboard/GetPreClaimDashbaordkpis?`,
  GET_CLAIM_DASH_DENIAL_BY_PAYER_AND_REASON_CODE_KPIS: `${SERVER_URL}/ExectiveDashboard/GetTopDenialByPayerAndReasonCode?`,

  // denial by facility Dashboard screen
  GET_DENIALS_BY_FACILITY_DASH_KPIS: `${SERVER_URL}/Analysis/GetServiceFacilitykpis?`,
  GET_DENIALS_BY_FACILITY_DASH_TABULAR_DATA: `${SERVER_URL}/denials/DenailsByFacility?`,
  GET_DENIALS_BY_FACILITY_DASH_TABULAR_ONE_ROW_DATA: `${SERVER_URL}/denials/DenailsByFacilityDetail?`,
  GET_DENIALS_BY_FACILITY_DASH_FILTER_KPIS: `${SERVER_URL}/Analysis/GetServiceFacilitySlicerkpis?`,

  // Denials Dashboard screen
  GET_DENIALS_DASH_KPIS: `${SERVER_URL}/Exectivedashboard/GetDenialDashbaordkpis?`,
  GET_DENIALS_OVERVIEW_KPIS: `${SERVER_URL}/Exectivedashboard/GetDenialOverviewkpis?`,

  // new denialsByCptScreen
  GET_NEW_DENIALS_BY_CPT_SCREEN: `${SERVER_URL}/denials/PatientCPTDenials?`,
  GET_ALL_FILTERS_DATA_DENIALS_BY_CPT_SCREEN: `${SERVER_URL}/denials/PatientCPTDenialsFilters?`,
  GET_ONE_RECORD_NEW_DENIALS_BY_CPT_SCREEN: `${SERVER_URL}/denials/PatientCPTDenialsDetail?`,

  // get drug acceptance rate
  GET_DRUG_ACC_RATE_SCREEN: `${SERVER_URL}/claimpayment/FirstAndSecondPass?`,
  GET_DRUG_ACC_RATE_DETAIL_SCREEN: `${SERVER_URL}/claimpayment/FirstAndSecondPassDetail?`,
  GET_DRUG_ACC_RATE_DETAIL_SCREEN_SECOND_PASS: `${SERVER_URL}/ClaimPayment/SecondPassDetail?`,
  GET_ALL_LOGIN_ACTIVITY: `${SERVER_URL}/user/GetLoginAttempts?`,

  // get files integration
  GET_ALL_FILES_INTEGRATION_DATA: `${SERVER_URL}/Customer/GetClaimTransferFileLog?`,
  DOWNLOAD_FILE_FILE_INTEGRATION: `${SERVER_URL}/Customer/DownloadLogCSVById?`,

  // get AI Dashboard Data
  GET_AI_DASHBOARD_SCREEN: `${SERVER_URL}/ExectiveDashboard/GetAIdata?`,

  //ALERT
  GET_ALERT_DASHBOARD_SCREEN: `${SERVER_URL}/Alerts/GetAll?`,
  GET_ALERT_DASHBOARD_SCREEN_BY_ID: `${SERVER_URL}/Alerts/GetById?`,
  SAVE_ALERT_DASHBOARD_SCREEN: `${SERVER_URL}/Alerts/Save`,
  DELETE_ALERT_DASHBOARD_SCREEN: `${SERVER_URL}/Alerts/Delete?`,

  //INBOX
  GET_INBOX_DASHBOARD_SCREEN: `${SERVER_URL}/Alerts/AlertsSummary?`,
  GET_INBOX_DETAIL_DASHBOARD_SCREEN: `${SERVER_URL}/Alerts/AlertsSummaryDetail?`,
  MOVE_TO_ARCHIVE: `${SERVER_URL}/Alerts/ArchiveAlertsSummary?`,
  MARK_AS_READ: `${SERVER_URL}/Alerts/MarkASReadAlertsSummary?`,

  // denials by payers Dashboard screen
  GET_DENIALS_BY_PAYERS_KPIS: `${SERVER_URL}/Exectivedashboard/GetDenialPayerskpis?`,
  GET_DENIALS_BY_PAYERS_PAYERS_SERVICE_LINE_LIST: `${SERVER_URL}/Exectivedashboard/GetDenialPayersFilterkpis?`,

  // mapBox tabular data screen
  GET_MAPBOX_TABULAR_DATA: `${SERVER_URL}/denials/PayerPer?`,
  GET_LEVEL_ONE_MAPBOX_TABULAR_DATA: `${SERVER_URL}/denials/PayerPerDetail?`,

  // health system tabular data screen
  GET_HEALTH_SYSTEM_DATA: `${SERVER_URL}/HealthSystem/All?`,
  GET_HEALTH_SYSTEM_ALL_PAYEE_LIST_DATA: `${SERVER_URL}/HealthSystem/GetAllPayee`,
  ADD_NEW_HEALTH_SYSTEM: `${SERVER_URL}/HealthSystem`,

  // Executive Dashboard Payer Data tabular data screen
  GET_EXECUTIVE_DASH_PAYER_KPIS: `${SERVER_URL}/Exectivedashboard/GetPayerDashBoardKpis?`,

  GET_EXECUTIVE_DASH_PAYER_TOP_CARDS: `${SERVER_URL}/ExectiveDashboard/GetPayerDashBoard?`,
  GET_EXECUTIVE_DASH_PAYER_TOP_FIVE: `${SERVER_URL}/ExectiveDashboard/Top5DenialsbyPayersTimeLine?`,
  GET_EXECUTIVE_DASH_PAYER_TOP_TEN: `${SERVER_URL}/ExectiveDashboard/Top10DenialsbyPayersTimeLine?`,

  // reports section

  // Drug Rejected api's here
  GET_DRUG_REJECTED_DATA: `${SERVER_URL}/CPTCode/GetDrugRejected?`,
  GET_DRUG_REJECTED_GB_REASON_CODE_DATA: `${SERVER_URL}/CPTCode/GetDrugRejectedGroupReasonCode?`,
  GET_DRUG_REJECTED_GB_CLAIM_DATA: `${SERVER_URL}/CPTCode/GetDrugRejectedByCode?`,
  GET_DRUG_REJECTED_GB_CLAIM_DATA_SEP_API: `${SERVER_URL}/CPTCode/GetDenialReportByClaims?`,
  GET_DRUG_REJECTED_GB_JCODE_DATA: `${SERVER_URL}/CPTCode/GetDrugRejected?`,

  GET_DRUG_REJECTED_DROP_DOWN_LEVEL_ONE: `${SERVER_URL}/CPTCode/GetDrugRejectedByReasonCode?`,
  GET_DRUG_REJECTED_DROP_DOWN_LEVEL_ONE_NEW_API: `${SERVER_URL}/CPTCode/GetDrugRejectedGroupReasonCode?`,
  GET_DRUG_REJECTED_DROP_DOWN_GROUP_CODE: `${SERVER_URL}/CPTCode/GetRejectedReasonCodeDetails?`,

  // details by PTCN data screen
  GET_DETAILS_BY_PTCN_DATA: `${SERVER_URL}/Exectivedashboard/GetExpDashbaordkpis?`,

  // reports section -> revenue by payer screen
  GET_REVENUE_BY_PAYER_GB_ALL: `${SERVER_URL}/Report/RevenueByPayer?`,
  GET_REVENUE_BY_PAYER_GB_PAYER_BY_REVENUE: `${SERVER_URL}/Report/PayersWithRevenue?`,

  // denials by payer screen on reports section
  GET_DENIAL_BY_PAYERS: `${SERVER_URL}/Report/DenialByPayer?`,

  // reports section -> service line denials
  GET_SERVICE_LINE_DENIALS_GB_CPT: `${SERVER_URL}/ServiceDenials/DenialsGBCPT?`,
  GET_SERVICE_LINE_DENIALS_FILTERS: `${SERVER_URL}/report/GetFiltersForServicelineAdjustment?`,

  //Oncology CARC Trends
  GET_OCOLOGY_CARC_FILTER_KPIS: `${SERVER_URL}/Analysis/GetOncologyCARCFilterkpis?`,
  GET_OCOLOGY_CARC_DASHBOARD_KPIS: `${SERVER_URL}/Analysis/GetOncologyCARCkpis?`, // use below line api url
  GET_OCOLOGY_CARC_DASHBOARD_TABULAR_DATA: `${SERVER_URL}/Analysis/GetOncologyCARCTable?`,
  GET_CARC_DASHBOARD_NEW_KPIS: `${SERVER_URL}/Exectivedashboard/PayersTimeLine?`,
  GET_CARC_DASHBOARD_NEW_KPIS_FILTERS: `${SERVER_URL}/Exectivedashboard/ExplorerPayerFilter?`,
  GET_ONE_REASON_CODE_OCOLOGY_CARC_DASHBOARD_KPIS: `${SERVER_URL}/Analysis/GetOncologyCARCGroupCodekpis?`,
  GET_OCOLOGY_CARC_DASHBOARD_KPIS_GRAPH: `${SERVER_URL}/Analysis/GetOncologyCARCTrendkpis?`,
  GET_OCOLOGY_CARC_BARCHART_HARD: `${SERVER_URL}/Exectivedashboard/GetStackBarchartHardAdjuestment?`,
  GET_OCOLOGY_CARC_BARCHART_SOFT: `${SERVER_URL}/Exectivedashboard/GetStackBarchartSoftAdjuestment?`,

  GET_OCOLOGY_CARC_TRENDS_DASHBOARD_KPIS_GRAPH: `${SERVER_URL}/Analysis/GetOncologyCARCTrends?`,

  GET_CARC_DASHBOARD_GRAPH_DATA: `${SERVER_URL}/Exectivedashboard/GetCARCDistributionData?`,

  //GOAL TRACKING
  GET_ALL_CODES: `${SERVER_URL}/AllCodes/GetAllCodes?`,
  CALCULATE_DENIAL_COUNT: `${SERVER_URL}/GoalTracker/GetGoalTrackDenialDetails?`,

  GET_GOAL_TRACKER_DATA: `${SERVER_URL}/GoalTracker/All?`,
  GET_GOAL_TRACKER_DATA_BY_ID: `${SERVER_URL}/GoalTracker`,
  GET_SINGLE_GOAL_CURRENT_DENIAL_RATE_BY_ID: `${SERVER_URL}/GoalTracker/RunNow?trackerId=`,
  GET_SINGLE_GOAL_DENIAL_RATE_BY_ID: `${SERVER_URL}/GoalTracker/GetCurrentDenialRate?trackerId=`,
  GET_SINGLE_GOAL_HISTORY_BY_ID: `${SERVER_URL}/GoalTracker/GoalHistory?`,

  CREATE_NEW_GOAL_TRACKER: `${SERVER_URL}/GoalTracker`,

  // advance setting get serviceLine exceptions
  GET_SERVICE_LINE_EXECPTIONS: `${SERVER_URL}/ClaimStatusCode/GetClaimStatusCode`,

  // procedure codes dashboard
  GET_DENIAL_BY_PROCEDURE_CODE: `${SERVER_URL}/ExectiveDashboard/GetDenialsByProcedureCode?`,
  GET_DENIAL_BY_PROCEDURE_CODE_DASHBOARD_PROVIDER_API: `${SERVER_URL}/ExectiveDashboard/GetDenialsByProcedureCodeProvider?`,
  GET_DENIAL_BY_PROCEDURE_CODE_DASHBOARD_PAYER_API: `${SERVER_URL}/ExectiveDashboard/GetDenialsByProcedureCodePayer?`,
  GET_DENIAL_BY_PROCEDURE_CODE_DASHBOARD_FACILITY_CODE_API: `${SERVER_URL}/ExectiveDashboard/GetDenialsByProcedureCodeFacility?`,
  GET_DENIAL_BY_PROCEDURE_CODE_DASHBOARD_FACILITY_TYPE_API: `${SERVER_URL}/ExectiveDashboard/GetDenialsByProcedureCodeFacilityType?`,
  GET_DENIAL_BY_PROCEDURE_CODE_DASHBOARD_CPT_API: `${SERVER_URL}/ExectiveDashboard/GetDenialsByProcedureCodeList?`,

  // GET_REASON_CODE_TRENDS_BY_PAYER_FILTER_API: `${SERVER_URL}/Exectivedashboard/GetPayerTimelineChart?`,
  GET_REASON_CODE_TRENDS_BY_PAYER_GRAPH_DATA_API: `${SERVER_URL}/Exectivedashboard/GetGroupCARCTimelineChart?`,

  //Landing Page
  LANDING_PAGE: `${SERVER_URL}/ExectiveDashboard/Overview?`,
  LANDING_PAGE_NEW_HOME: `${SERVER_URL}/Exectivedashboard/GetHomeDashborad?`,
  LANDING_PAGE_EXECUTIVE_DASHBOARD: `${SERVER_URL}/Exectivedashboard/AlphaHealthDashboradKpi?`,
  LANDING_PAGE_GET_PAYEES: `${SERVER_URL}/Exectivedashboard/GetAlphaHealthDashborad?`,

  MASTER_LIST_CODES: `${SERVER_URL}/AllCodes/GetAllCodes`,
  //Settings Departments Tree View
  GET_DEPARTMENTMENT_TREE_VIEW: `${SERVER_URL}/CPTCode/GetCPTLevelJSON?`,
  SET_AT_INITIAL_LEVEL: `${SERVER_URL}/CPTCode/SaveCPTLevel0`,
  SET_AT_LEVEL: `${SERVER_URL}/CPTCode/SaveCPTLevel`,
  EDIT_AT_LEVEL: `${SERVER_URL}/CPTCode/UpdateCPTLevel`,

  DELETE_LEVEL: `${SERVER_URL}/CPTCode/DeleteCPTLevel`,

  // Queue details screen
  GET_ALL_LOOKUP_LIST: `${SERVER_URL}/WorkQueue/GetAllLookup`,
  GET_ALL_LOOKUP_ICD10_LIST: `${SERVER_URL}/WorkQueue/GetICD10LookUp`,
  GET_ALL_LOOKUP_PROCEDURE_CODE_LIST: `${SERVER_URL}/WorkQueue/GetProcedureCodeLookUp`,
  GET_ALL_LOOKUP_LIST_ALERT: `${SERVER_URL}/Alerts/GetAllAlertLookup`,
  GET_SMART_QUEUE_DASHBOARD_DETAILS: `${SERVER_URL}/WorkQueue/SmartQueueDashboard?`,
  GET_ALL_VIEWS_LIST: `${SERVER_URL}/WorkQueue/GetViewsByParentId?ParentId=`,

  CLAIM_QUEUE_SAVE_QUERY: `${SERVER_URL}/WorkQueue/Save`,
  GET_ALL_QUEUES: `${SERVER_URL}/WorkQueue/GetAll?`,
  GET_QUEUE_BY_ID: `${SERVER_URL}/WorkQueue/GetWorkQueueById?WorkQueueId=`,
  DELETE_WORK_QUEUE_ITEM: `${SERVER_URL}/WorkQueue/Delete?WorkQueueId=`,
  GET_COMMENTS: `${SERVER_URL}/Comments/GetAllComments?`,
  CREATE_COMMENT: `${SERVER_URL}/Comments/CreateComment`,
  UPDATE_COMMENT: `${SERVER_URL}/Comments/UpdateComment`,
  DELETE_COMMENT: `${SERVER_URL}/Comments/DeleteComment?`,
  CREATE_MULTIPLE_COMMENTS: `${SERVER_URL}/Comments/CreateMultipleComments`,
  GET_ALL_BUCKETS: `${SERVER_URL}/WorkQueue/GetAllBuckets?`,
  CREATE_NEW_BUCKET: `${SERVER_URL}/WorkQueue/CreateQueueBucket`,
  EDIT_BUCKET: `${SERVER_URL}/WorkQueue/UpdateQueueBucket`,
  DELETE_BUCKET: `${SERVER_URL}/WorkQueue/DeleteBucket`,
  CLAIM_REQUEST_SUMMARY: `${SERVER_URL}/claimRequest/GetClaimSummaryOnNotes/`,
  GET_LOOKUP_ACTIVITIES: `${SERVER_URL}/Comments/GetActivityTypes`,
  CLAIM_PAYMENT_SUMMARY: `${SERVER_URL}/claimpayment/GetClaimSummaryOnNotes/`,
  GET_ALL_BUCKETS_AGAINST_ROLE_ID: `${SERVER_URL}/WorkQueue/GetBucketsByRole/`,
  GET_MOVE_CLAIM_DROPDOWN_DATA: `${SERVER_URL}/WorkQueue/GetQueusAndBuckets?`,
  MOVE_CLAIM: `${SERVER_URL}/WorkQueue/ChangeClaimQueue`,
  MOVE_CLAIM_BULK: `${SERVER_URL}/WorkQueue/MoveMultipleClaimsToQueue`,
  CHANGE_CLAIM_PRIORITY: `${SERVER_URL}/WorkQueue/InsertOrUpdateClaimPriority`,
  CHANGE_CLAIM_STATUS: `${SERVER_URL}/WorkQueue/ChangeClaimQueue`,
  EXECUTE_QUERY: `${SERVER_URL}/WorkQueue/ExecuteQuery`,
  EXECUTE_QUERY_MY_TASKS: `${SERVER_URL}/WorkQueue/GetMyTasks`,

  SUPERVISOR_APPROVAL: `${SERVER_URL}/WorkQueue/SupervisorApproval`,
  REVERT_QUEUE: `${SERVER_URL}/workqueue/revert-back?`,

  GET_MOVED_CLAIMS: `${SERVER_URL}/WorkQueue/GetMovedClaims?`,

  // WorkQueue/GetMovedClaims?transferedFromId=1273&start=0&limit=10&transferBy=197f1034-5176-43f8-9d64-a51706f94c2a

  CHANGE_APPEAL_STATUS: `${SERVER_URL}/AppealsConfig/ChangeAppealStatus?`,
  GET_ALL_QUEUES_AGAINST_BUCKET_ID: `${SERVER_URL}/WorkQueue/GetAll?`,
  DELETE_QUEUE: `${SERVER_URL}/WorkQueue/Delete?`,

  //Rank Bucket
  UPDATE_RANK_BUCKET: `${SERVER_URL}/WorkQueue/UpdateBucketRank`,
  UPDATE_RANK_QUEUE: `${SERVER_URL}/WorkQueue/UpdateQueueRank`,

  //Appeal Configuration

  APPEAL_CONFIG_LIST: `${SERVER_URL}/AppealsConfig/GetDenialCoordinators?`,
  ADD_NEW_APPEAL_CONFIG: `${SERVER_URL}/AppealsConfig/CreateDenialCoordinator`,
  UPDATE_APPEAL_CONFIG: `${SERVER_URL}/AppealsConfig/UpdateDenialCoordinator`,
  GET_APPEAL_BY_ID: `${SERVER_URL}/AppealsConfig/GetDenialCoordinatorsById?`,
  DELETE_BY_ID: `${SERVER_URL}/AppealsConfig/DeleteDenialCoordinator?`,

  //Payer Contact Info
  PAYER_CONTACT_LIST: `${SERVER_URL}/api/appeals-payer/list?`,
  CREATE_PAYER_CONTACT_LIST: `${SERVER_URL}/api/appeals-payer/create`,
  UPDATE_PAYER_CONTACT_LIST: `${SERVER_URL}/api/appeals-payer/update`,
  GET_PAYER_BY_ID: `${SERVER_URL}/api/appeals-payer/find?`,
  DELETE_PAYER_BY_ID: `${SERVER_URL}/api/appeals-payer/delete?`,

  //ChatBot Apis
  SAVE_USER_ADDITIONAL_DATA: `${SERVER_URL}/user/SaveUserAddtionalDetail`,
  SEND_MESSAGE_CHATBOT: `${SERVER_URL}/ChatBot/SendMessage?`,
  SEND_MESSAGE_CHATBOT_POLICY: `${SERVER_URL}/Chatbot/PolicyChatbot?`,

  SEND_MESSAGE_AZURE_COGNITIVE: `${SERVER_URL}/ChatBot/ChatBotSearch`,
  SEND_ASK_AVA: `${BOT_SERVER_URL}/chat`,
  SEND_ASK_LAMA: `${SERVER_URL}/ChatBot/AskAVA`,
  // SEND_ASK_CHATBOT: `https://drclaimbot.azurewebsites.net/chat`,
  // SEND_ASK_CHATBOT: `https://webapproversdevlidabot.azurewebsites.net/chat`,
  // SEND_ASK_CHATBOT: `${SERVER_URL}/ChatBot/AskLida`,
  SEND_ASK_CHATBOT: `${SERVER_URL}/ChatBot/AskLida`,
  SEND_ASK_CHATBOT_EXPLAIN: `${SERVER_URL}/ChatBot/AskLida/explain`,
  SEND_ASK_CHATBOT_REPAIR: `${SERVER_URL}/ChatBot/AskLida/repair`,

  // SEND_ASK_ROVERS: `${SERVER_URL}/ChatBot/PolicyBotAskZoe`,
  SEND_ASK_ROVERS: `${SERVER_URL}/ChatBot/AskZoe`,
  SEND_ASK_ROVERS3: `${SERVER_URL}/ChatBot/PolicyCopilot`,

  SEND_ASK_ORGANIZATION: `${SERVER_URL}/ChatBot/AlphaHealth`,
  SEND_ASK_CUSTOM: `${SERVER_URL}/ChatBot/CustomCopilot`,

  SEND_ASK_COPILOT: `${SERVER_URL}/Chatbot/WebCopilotSearch`,
  SEND_ASK_ALL_MAC: `${SERVER_URL}/ChatBot/AllMac`,
  PRESERVE_POLICY_CHAT: `${SERVER_URL}/api/conversation`,
  PRESERVE_POLICY_CHAT_HISTORY: `${SERVER_URL}/api/SaveChatHistory`,
  GET_POLICY_CHAT_HISTORY: `${SERVER_URL}/api/GetTop10ChatTitles?`,
  DELELTE_POLICY_CHAT_HISTORY: `${SERVER_URL}/api/SoftDeleteChat`,
  GET_CHAT_TITLE: `${SERVER_URL}/Chatbot/ChatTitle`,
  GET_CHAT_DETAILS_BY_ID: `${SERVER_URL}/api/GetChatByChatId?`,
  SEND_ASK_ROVERS2: `${SERVER_URL}/ChatBot/EligibilityCopilot`,
  GET_ELIGIBILITY_DOC: `${SERVER_URL}/ClaimAttachment/chatbot/findattachment?`,

  //Tour update api
  NEW_USER_TOUR: `${SERVER_URL}/User/UpdateTour`,
  //Appeal

  APPEAL_SERVICE: `${SERVER_URL}/servicepayment/GetAppealsServicePayments?`,
  // GENERATE_APPEAL_EMAIL: `${SERVER_URL}/ChatBot/GenerateEmail?`,
  GENERATE_APPEAL_EMAIL: `${SERVER_URL}/ChatBot/ServiceLineAppealLetter?`,
  // GENERATE_APPEAL_EMAIL_CLAIM: `${SERVER_URL}/ChatBot/ClaimAppealLetter?`,
  GENERATE_APPEAL_EMAIL_CLAIM: `${SERVER_URL}/ChatBot/ClaimLevelAppealLetter?`,
  // GENERATE_APPEAL_REASON: `${SERVER_URL}/Chatbot/AIReasoningDenialAppeal?`,
  GENERATE_APPEAL_REASON: `${SERVER_URL}/ChatBot/ServiceLineAppealReasoning?`,
  // GENERATE_APPEAL_REASON_CLAIM: `${SERVER_URL}/Chatbot/AIReasoningClaimAppeal?`,
  GENERATE_APPEAL_REASON_CLAIM: `${SERVER_URL}/ChatBot/ClaimAppealReasoning`,

  //GENERATE MEDICAL NOTES
  GENERATE_MEDICAL_NOTES: `${SERVER_URL}/ChatBot/ClinicalNote`,

  //Notifications
  GET_ALL_NOTIFICATIONS: `${SERVER_URL}/Notification/GetAll?`,
  MARK_AS_READ_NOTIFICATION: `${SERVER_URL}/Notification/MarkASReadNotification?`,
  MARK_AS_VIEWED_NOTIFICATION: `${SERVER_URL}/Notification/MarkASViewedNotification?`,
  DELETE_ALL_NOTIFICATIONS: `${SERVER_URL}/Notification/ClearAllNotification?`,
  DELETE_INDIVIDUAL_NOTIFICATION: `${SERVER_URL}/Notification/ClearNotification?`,

  GET_ALL_NOTIFICATION_TYPES: `${SERVER_URL}/Notification/GetAllNotificationType?`,

  //Appelas
  GET_APPEALS: `${SERVER_URL}/WorkQueue/GetAllAppealQueues?`,
  GET_APPEAL_QUEUE_FILTER_COUNT: `${SERVER_URL}/WorkQueue/GetAppealTopCard?`, //workQueueId
  //Appeals Dashboard
  GET_APPEALS_DASHBOARD_TOP_CARDS: `${SERVER_URL}/Appeals/TopCard?`,
  GET_APPEAL_DASHBOARD_LINECHART: `${SERVER_URL}/Appeals/PieChart?`, //from=2021-01-01&to=2021-01-31&appealState=&assigneeId=`,
  GET_APPEAL_DASHBOARD_USERS_AND_QUEUES: `${SERVER_URL}/Appeals/UserBuckets`,
  GET_APPEAL_DASHBOARD_QUEUES: `${SERVER_URL}/Appeals/Lookup`,
  GET_APPEAL_DASHBOARD_BARCHART: `${SERVER_URL}/Appeals/BarChart?`, //from=2021-01-01&to=2021-01-31&appealState=&assigneeId=`,

  //FAX
  GET_FAX_PAYERS: `${SERVER_URL}/fax/GetIncFaxDetails`,
  GET_FAX_INFO: `${SERVER_URL}/Fax/GetFaxById?`, // faxId
  CANCEL_FAX: `${SERVER_URL}/Fax/CancelFax?`, // faxId

  //Packets
  GET_ATTACHMENTS: `${SERVER_URL}/ClaimAttachment/Appeals/GetAttachments?`,
  UPLOAD_ATTACHMENTS: `${SERVER_URL}/ClaimAttachment/Appeals/UploadAttachment`,
  UPDATE_ATTACHMENTS: `${SERVER_URL}/Appeals/UpdateAttachment`, // remove claimAttachment?
  DELETE_SINGLE_ATTACHMENT: `${SERVER_URL}/ClaimAttachment/Appeals/DeleteAttachment?`,
  DELETE_MULTIPLE_ATTACHMENTS: `${SERVER_URL}/ClaimAttachment/Appeals/DeleteAttachments`,

  //Insurance form data
  GET_INSURANCE_FORM_DATA: `${SERVER_URL}/ClaimAttachment/Appeals/GetClaimReviewForm?`,
  GET_INSURANCE_FORM_RESET_DATA: `${SERVER_URL}/ClaimAttachment/Appeals/GetAppealClaimInfo?`,
  GET_INSURANCE_FORMS: `${SERVER_URL}/ClaimAttachment/Appeals/GetPayerAppealForm`,
  POST_INSURANCE_FORM_DATA: `${SERVER_URL}/ClaimAttachment/Appeals/SaveClaimReviewForm`,
  GET_APPEAL_FLAGS: `${SERVER_URL}/ClaimAttachment/Appeals/UpdateLetterStatus?`,

  UPDATE_APPEAL_LETTER_STATUS: `${SERVER_URL}/ClaimAttachment/Appeals/UpdateLetterStatus`,
  SAVE_APPEAL_GLOSSARY: `${SERVER_URL}/ClaimAttachment/Appeals/UpsertAppealGlossory`,
  GET_APPEAL_GLOSSARY: `${SERVER_URL}/ClaimAttachment/Appeals/GetAppealGlossory?`,
  GET_ALL_DEFAULT_COLUMNS: `${SERVER_URL}/ClaimAttachment/GetCsvColumns`,

  UPLOAD_ATTACHMENTS_837: `${SERVER_URL}/ClaimAttachment/UploadManualClaims`,
  UPLOAD_ATTACHMENTS_835: `${SERVER_URL}/ClaimAttachment/UploadManualClaims835`,

  //Subscription
  GET_ALL_SUBSCRIBED_MODULES: `${SERVER_URL}/SubscriptionModules/GetAllModules`,
  GET_ALL_BOUGHT_MODULES: `${SERVER_URL}/SubscriptionModules/GetModulesByDomainId?`,
  UPDATE_MODULE_PLAN: `${SERVER_URL}/SubscriptionModules/UpdateModulePlan`,
  MODULE_PRICING: `${SERVER_URL}/Subscription/ModulePricing?`,
  MODULE_USAGE: `${SERVER_URL}/Subscription/ModuleUsage?`,
  POLICY_ADVISOR_ADD_CREDIT: `${SERVER_URL}/Subscription/PolicyAdvisorCredit`,
  APPEAL_ADD_CREDIT: `${SERVER_URL}/Subscription/SmartAppealCredit`,
  POLICY_ADVISOR_CREDIT_CHECK: `${SERVER_URL}/ChatBot/PolicyAdvisorCreditCheck?`,
  SMART_APPEALS_CREDIT_CHECK: `${SERVER_URL}/ChatBot/SmartAppealsCreditCheck?`,
  GET_SAML_DETAILS: `${SERVER_URL}/Customer/GetSamlConfiguration?`,
  UPDATE_SAML_DETAILS: `${SERVER_URL}/Customer/SaveSamlConfiguration?`,
  REPLY_URL: `${SERVER_URL}/api/auth/AssertionConsumerService`,

  //Open Api
  GET_ALL_OPEN_API: `${SERVER_URL}/Subscription/AllAPIKey?`,
  SAVE_OPEN_API: `${SERVER_URL}/Subscription/GenerateNewAPIKey?`,
  DELETE_OPEN_API: `${SERVER_URL}/Subscription/DeleteAPIKey?`,

  //OPEN API DISPLAY
  OPEN_API_APPEAL: `${openApiBaseUrl}/Appeal`,

  //Upload Fax
  UPLOAD_FAX: `${SERVER_URL}/Fax/SendFax`,

  //Cognitive Search
  COGNITIVE_SEARCH: `${SERVER_URL}/DRSearch/Search`,

  //Existing Claims
  GET_EXISTING_CLAIMS: `${SERVER_URL}/WorkQueue/ExecuteQuery`,

  //Move All Claims
  MOVE_ALL_CLAIMS: `${SERVER_URL}/workqueue/move-all-claims`,
  //RARC
  GET_RARC: `${SERVER_URL}/Exectivedashboard/GetRARCDashboardData?`,
  GET_CARC: `${SERVER_URL}/Exectivedashboard/GetCARCDashboardData?`,

  //Upload Policy
  GET_ALL_POLICY: `${SERVER_URL}/api/v1/policy-docs/list?`,
  GET_ALL_POLICY_URL: `${SERVER_URL}/api/v1/policy-docs/list-urls?`,

  UPLOAD_BULK: `${SERVER_URL}/api/v1/policy-docs/upload`,
  DELETE_BULK: `${SERVER_URL}/api/v1/policy-docs/delete`,
  DOWNLOAD_BULK: `${SERVER_URL}/api/v1/policy-docs/download?`,

  //Upload Web Links
  UPLOAD_BULK_WEB_LINKS: `${SERVER_URL}/api/v1/policy-docs/saveUrl`,
  UPDATE_BULK_WEB_LINKS: `${SERVER_URL}/api/v1/policy-docs/updateUrl`,
  DELETE_BULK_WEB_LINKS: `${SERVER_URL}/api/v1/policy-docs/deleteUrls`,

  //INCREMENTAL INDEX
  INCREMENTAL_INDEX: `${SERVER_URL}/api/v1/policy-docs/incremental-index`,
  // FULL_INDEX: `${SERVER_URL}/api/v1/policy-docs/full-index`,
  FULL_INDEX: `${SERVER_URL}/api/v1/policy-docs/run-index?`,

  BUILD_INDEX: `${SERVER_URL}/api/v1/policy-docs/build-index`,
  CHECK_INDEX_STATUS: `${SERVER_URL}/api/v1/policy-docs/index-status`,

  // Clients Config screen
  GET_CLIENT_CONFIG_ALL_LIST: `${SERVER_URL}/Customers/GetAllCustomers?`,
  ADD_NEW_CLIENT: `${SERVER_URL}/Customers/Save`,
  UPDATE_CLIENT: `${SERVER_URL}/Customers/Update`,
  DELLETE_CLIENT_CONFIG_ITEM: `${SERVER_URL}/Customers/Delete`,

  // Claim Custom Status Code screen
  GET_CLAIM_CUSTOM_STATUS_CODE_LIST: `${SERVER_URL}/Customer/GetAllClaimStatus/list?`,
  ADD_STATUS_CODE: `${SERVER_URL}/Customer/ClaimStatus/save`,
  UPDATE_STATUS_CODE: `${SERVER_URL}/Customer/ClaimStatus/update`,
  DELETE_STATUS_CODE: `${SERVER_URL}/Customer/ClaimStatus/delete?customClaimStatusId`,
  RETREIEVEA_ALL_STATUS_CODE_LIST: `${SERVER_URL}/Customer/GetAllCustomClaimStatus?`,
  SAVE_STATUS_FOR_CLAIM_PAYMENT: `${SERVER_URL}/Customer/UpdateCustomClaimStatus`,

  // Custom procedure code screen
  GET_CUSTOM_PROCE_CODE_ALL_LIST: `${SERVER_URL}/servicepayment/PayerCPTPrefrence/list?`,
  GET_CUSTOM_PROCE_CODE_LOOK_UP_DATA: `${SERVER_URL}/servicepayment/PayerCPTPrefrence/LookUpData`,
  GET_CUSTOM_PROCE_CODE_SAVE: `${SERVER_URL}/servicepayment/PayerCPTPrefrence/save`,
  GET_CUSTOM_PROCE_CODE_UPDATE: `${SERVER_URL}/servicepayment/PayerCPTPrefrence/update`,
  GET_CUSTOM_PROCE_CODE_DELETE: `${SERVER_URL}/servicepayment/PayerCPTPrefrence/delete?Id=`,

  //Denails Categories Config Screen
  GET_DENIAL_CONFIG_ALL_LIST: `${SERVER_URL}/AllCodes/carc-categories/list?`,
  UPSERT_DENIAL_CONFIG: `${SERVER_URL}/AllCodes/carc-categories/upsert`,
  DELETE_DENIAL_CONFIG: `${SERVER_URL}/AllCodes/carc-categories/delete`,

  //Copilot CRUD
  //Policy Copilot
  GET_ALL_POLICY_COPILOT: `${SERVER_URL}/ChatBot/GetAllPolicyQuestions?`,
  CREATE_POLICY_COPILOT: `${SERVER_URL}/ChatBot/SavePolicyQuestion`,
  UPDATE_POLICY_COPILOT: `${SERVER_URL}/ChatBot/UpdatePolicyQuestion`,
  DELETE_POLICY_COPILOT: `${SERVER_URL}/ChatBot/DeletePolicyQuestion?`,
  //Eligibility Copilot
  GET_ALL_ELIGIBILITY_COPILOT: `${SERVER_URL}/ChatBot/GetAllEligibilityQuestions?`,
  CREATE_ELIGIBILITY_COPILOT: `${SERVER_URL}/ChatBot/SaveEligibilityQuestion`,
  UPDATE_ELIGIBILITY_COPILOT: `${SERVER_URL}/ChatBot/UpdateEligibilityQuestion`,
  DELETE_ELIGIBILITY_COPILOT: `${SERVER_URL}/ChatBot/DeleteEligibilityQuestion?`,

  //Payer Dashboard
  GET_CPT: `${SERVER_URL}/Exectivedashboard/GetCPTDashboardData?`,
  GET_DROPDOWN_CPT: `${SERVER_URL}/ExectiveDashboard/CPTDropDownbyPayer?`,
  GET_STACK_CHART_PAYER_DENIAL_PERCENTAGE: `${SERVER_URL}/ExectiveDashboard/GetEqualWeightDenialRateByPayer?`,
  //CSV
  UPLOAD_CSV_FILE: `${SERVER_URL}/ClaimAttachment/SaveAndUploadCsvColumns`,

  //Department Dashboard
  GET_TOP_TEN_DENIALS_DEPARTMENTS: `${SERVER_URL}/ExectiveDashboard/Top10DenialsbyDepartmentTimeLine?`,
  GET_TOP_CARD_DEPARTMENTS: `${SERVER_URL}/ExectiveDashboard/GetDepartmentTopCard?`,
  GET_TOP_FIVE_DEPARTMENTS: `${SERVER_URL}/ExectiveDashboard/Top5DenialsbyDepartmentTimeLine?`,

  //Departments Reason Code
  GET_TOP_TEN_CARC_FOR_TABLE: `${SERVER_URL}/ExectiveDashboard/CARCTablebyDepartment?`,
  GET_TOP_TEN_CARC_FOR_TREE_MAP: `${SERVER_URL}/ExectiveDashboard/CARCTreeMapbyDepartment?`,
  GET_CARC_HEADER_DEP: `${SERVER_URL}/ExectiveDashboard/CARCHeaderbyDepartment?`,
  GET_CARC_DEP_TABLE: `${SERVER_URL}/ExectiveDashboard/CARClevel0DropDown?`,

  //Departments Procedure Code
  GET_TOP_TEN_CPT_FOR_TABLE: `${SERVER_URL}/ExectiveDashboard/CPTTablebyDepartment?`,
  GET_TOP_TEN_CPT_FOR_TREE_MAP: `${SERVER_URL}/ExectiveDashboard/CPTTreeMapbyDepartment?`,
  GET_CPT_HEADER_DEP: `${SERVER_URL}/ExectiveDashboard/CPTHeaderbyDepartment?`,
  GET_CPT_DEP_TABLE: `${SERVER_URL}/ExectiveDashboard/CPTlevel0DropDown?`,

  //Departments Remark Code
  GET_TOP_TEN_RARC_FOR_TABLE: `${SERVER_URL}/ExectiveDashboard/RARCTablebyDepartment?`,
  GET_TOP_TEN_RARC_FOR_TREE_MAP: `${SERVER_URL}/ExectiveDashboard/RARCTreeMapbyDepartment?`,
  GET_RARC_HEADER_DEP: `${SERVER_URL}/ExectiveDashboard/RARCHeaderbyDepartment?`,
  GET_RARC_DEP_TABLE: `${SERVER_URL}/ExectiveDashboard/Rarclevel0DropDown?`,

  // Maintenance screen
  GET_MAINTENANCE_SCREEN_STATUS: `${SERVER_URL}/maintenance`,

  // Alpha health Bubble Chart
  GET_TOP_FIVE_BUBBLE_CHART_BY_PAYERS: `${SERVER_URL}/ExectiveDashboard/Top5BubbleChartbyPayers?`,
  GET_TOP_FIVE_BUBBLE_CHART_BY_DEPARTMENTS: `${SERVER_URL}/ExectiveDashboard/Top5BubbleChartbyDepartment?`,

  // Claim Progress
  GET_CLAIM_PROGRESS: `${SERVER_URL}/ExectiveDashboard/ClaimsProgress?`,
  //Custom Column Config
  GET_COLUMN_LOOKUP_DETAILS: `${SERVER_URL}/Lookup/GetLookupByName?`,
  SAVE_COLUMN_LOOKUP_DETAILS: `${SERVER_URL}/Lookup/InsertLookup`,
  REMOVE_COLUMN_LOOKUP_DETAILS: `${SERVER_URL}/Lookup/DeleteLookup?`,

  UPDATE_CLAIM_INFO_COLUMNS: `${SERVER_URL}/Claimpayment/UpdateClaimpaymentAdditionInfoConfigBulk`,

  //Custom Copilot
  CREATE_CUSTOM_COPILOT: `${SERVER_URL}/api/v1/policy-docs/copilot/create`,
  UPDATE_CUSTOM_COPILOT: `${SERVER_URL}/api/v1/policy-docs/copilot/update`,

  GET_CUSTOM_COPILOT: `${SERVER_URL}/api/v1/policy-docs/copilot/list?`,
  DELETE_CUSTOM_COPILOT: `${SERVER_URL}/api/v1/policy-docs/copilot/delete?`,
  GET_COPILOT_MEMBERS: `${SERVER_URL}/api/v1/policy-docs/copilot/getmembers?`,
  ADD_COPILOT_MEMBERS: `${SERVER_URL}/api/v1/policy-docs/copilot/addmember`,
  REMOVE_COPILOT_MEMBERS: `${SERVER_URL}/api/v1/policy-docs/copilot/removemember`,

  //Assignee dropdown
  GET_All_ASIGNEE_DATA: `${SERVER_URL}/WorkQueue/GetAllAssignees`,
  UPDATE_ASIGNEE: `${SERVER_URL}/WorkQueue/InsertOrUpdateAssignee`,
};

import React from "react";

const ChatIconBot = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 19.9997L4.3 16.0997C1.976 12.6627 2.874 8.22772 6.4 5.72572C9.926 3.22472 14.99 3.42972 18.245 6.20572C21.5 8.98272 21.94 13.4717 19.274 16.7067C16.608 19.9417 11.659 20.9217 7.7 18.9997L3 19.9997Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="17" cy="17" r="4" fill="white" />
      <path d="M15 17H19Z" fill="white" />
      <path
        d="M15 17H19"
        stroke="#07a784"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M17 15V19Z" fill="white" />
      <path
        d="M17 15V19"
        stroke="#07a784"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   class="icon icon-tabler icon-tabler-message-circle-2"
    //   width="24"
    //   height="24"
    //   viewBox="0 0 24 24"
    //   stroke-width="1"
    //   stroke="#fff"
    //   fill="none"
    //   stroke-linecap="round"
    //   stroke-linejoin="round"
    // >
    //   <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    //   <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1" />
    // </svg>
  );
};

export default ChatIconBot;

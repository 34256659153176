import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import browserRoute from '../constants/browserRoutes';

function PublicRoute() {
  const { userToken } = useSelector((state) => state.pReducers.user);

  return !userToken ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: browserRoute?.REACT_SERVER_DEFAULT_URL,
        state: { from: location },
      }}
    />
  );
}

export default PublicRoute;

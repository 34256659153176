import React, { useEffect, useRef, useState } from "react";

import {
  Avatar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Button,
  TextField,
} from "@mui/material";
import CommentSendIcon from "../../assets/svgs/CommentSendIcon";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ClearChatIcon from "../ChatBot/ClearChatIcon";
import genericAxiosCall from "../../AxiosConfig/genericAxiosCall";
import { serverRoutes } from "../../constants/serverRoutes";
import {
  removeDocAttached,
  removeDuplicatesFromArray,
  replaceDocAttached,
  returnIndexDocAttached,
} from "../common/Utils/utils";
import ChatbotLoading from "../../assets/images/ChatbotLoading.gif";

import browserRoute from "../../constants/browserRoutes";
import CrossIcon from "../../assets/svgs/CrossIcon";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ChatBody = () => {
  const chatbotEnum = {
    bot: "bot",
    user: "user",
    policy: "policy",
    claim: "claim",
  };
  const data = [
    {
      msg: "Welcome to the Datarovers Claim Assistant, How may i help you?",
      role: chatbotEnum?.bot,
      citation: "",
    },
  ];
  const dataPolicy = [
    {
      msg: "Welcome to the Datarovers Policy Assistant, How may i help you?",
      role: chatbotEnum?.bot,
      citation: "",
    },
  ];

  const messagesDivRef = useRef(null);
  const [input, setInput] = useState("");
  const [mainOutput, setMainOutput] = useState([]);
  const [showTableColumnFilter, setShowTableColumnFilter] = useState("");
  const [detailContent, setDetailContent] = useState("");
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const scrollToBottom = () => {
    if (messagesDivRef.current) {
      messagesDivRef.current.scrollTop =
        messagesDivRef.current.scrollHeight + 20;
    }
  };
  const handleTableColumnOpen = () => {
    if (showTableColumnFilter === "open") {
      setShowTableColumnFilter("");
    } else {
      setShowTableColumnFilter("open");
    }
  };
  const handleLoading = (val, output) => {
    setLoading(val);
    if (val) {
      output.push({
        msg: "loading",
        role: "loading",
        citation: "",
      });
    } else {
      output.pop();
    }
    setMainOutput(output);
  };
  const saveMessageToLocalHost = (obj) => {
    let savedMessages = localStorage.getItem("saved_Messages");
    setTimeout(scrollToBottom, 0);
    if (savedMessages) {
      let arr = [];
      savedMessages = JSON.parse(savedMessages);

      arr.push(...savedMessages);
      arr.push(obj);
      localStorage.setItem("saved_Messages", JSON.stringify(arr));
    } else {
      let arr = [];
      //  savedMessages = JSON.parse(savedMessages);
      //  arr.push([...savedMessages]);
      // arr.push(tempMessage);
      arr.push(obj);
      localStorage.setItem("saved_Messages", JSON.stringify(arr));
    }
  };
  const saveMessageToLocalHost_Policy = (obj) => {
    let savedMessages = localStorage.getItem("saved_Messages_Policy");
    setTimeout(scrollToBottom, 0);
    if (savedMessages) {
      let arr = [];
      savedMessages = JSON.parse(savedMessages);

      arr.push(...savedMessages);
      arr.push(obj);
      localStorage.setItem("saved_Messages_Policy", JSON.stringify(arr));
    } else {
      let arr = [];
      //  savedMessages = JSON.parse(savedMessages);
      //  arr.push([...savedMessages]);
      // arr.push(tempMessage);
      arr.push(obj);
      localStorage.setItem("saved_Messages_Policy", JSON.stringify(arr));
    }
  };
  const loadSavedMessages = () => {
    const messages = JSON.parse(localStorage.getItem("saved_Messages"));
    if (messages?.length) {
      setTimeout(scrollToBottom, 0);
      messages.unshift(...data);
      setMainOutput(messages);
    } else {
      setMainOutput(data);
    }
  };
  const loadSavedMessagesPolicy = () => {
    const messages = JSON.parse(localStorage.getItem("saved_Messages_Policy"));
    if (messages?.length) {
      setTimeout(scrollToBottom, 0);
      messages.unshift(...dataPolicy);
      setMainOutput(messages);
    } else {
      setMainOutput(dataPolicy);
    }
  };
  const deleteSavedMessages = () => {
    localStorage.removeItem("saved_Messages");
    setMainOutput(data);
  };
  const deleteSavedMessagesPolicy = () => {
    localStorage.removeItem("saved_Messages_Policy");
    setMainOutput(dataPolicy);
  };
  const handleSyntaxText = (msg, citationString, item) => {
    const citationArr = removeDuplicatesFromArray(returnIndexDocAttached(msg));
    let citation = citationString ? JSON.parse(citationString) : "";

    if (citationArr?.length && citation && citation?.citations?.length) {
      citation = citation?.citations;
      let resMsg = removeDocAttached(msg);

      // const message = this.createChatBotMessage(<pre>{resMsg}</pre>);

      let c = citationArr?.map((item, index) => {
        let citationIndex = citation[Number(item) - 1];

        const splitContent = citationIndex?.content?.split("\n");

        return (
          <>
            <p className="DR-tag-ui-lable">
              {splitContent && splitContent?.length && (
                <span
                  onClick={() => {
                    window.open(
                      `${
                        browserRoute?.CLAIM_PAYMENT_835_DETAIL.split("/:id")[0]
                      }/${citationIndex?.filepath}`
                    );
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {splitContent[0]}
                </span>
              )}
            </p>
          </>
        );
      });
      return (
        <pre style={{ whiteSpace: "pre-wrap" }}>
          {resMsg}
          <p className="mt-4">{c}</p>
        </pre>
      );

      // this.updateChatbotState(message);
      // this.saveMessageToLocalHost(
      //   JSON.stringify({ msg, citationString }),
      //   chatbotEnum?.bot,
      //   "text"
      // );
    } else {
      return <pre style={{ whiteSpace: "pre-wrap" }}>{msg}</pre>;
    }
  };
  const handleSyntaxText_Policy = (msg, citationString, item) => {
    const citationArr = removeDuplicatesFromArray(returnIndexDocAttached(msg));
    let citation = citationString ? JSON.parse(citationString) : "";
    if (citationArr?.length && citation && citation?.citations?.length) {
      citation = citation?.citations;
      let resMsg = replaceDocAttached(msg);

      // const message = this.createChatBotMessage(<pre>{resMsg}</pre>);

      let c = citationArr?.map((item, index) => {
        let citationIndex = citation[Number(item) - 1];

        const splitContent = citationIndex?.content?.split("\n");

        return (
          <>
            <p className="DR-tag-ui-lable">
              {splitContent && splitContent?.length && (
                <span
                  onClick={() => {
                    setDetailContent(citationIndex?.content);
                    handleTableColumnOpen();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {`${Number(item)}. ${citationIndex?.filepath}`}
                </span>
              )}
            </p>
          </>
        );
      });
      return (
        <pre style={{ whiteSpace: "pre-wrap" }}>
          <div dangerouslySetInnerHTML={{ __html: resMsg }} />
          <div className="mt-4">
            <div className="DR-chat-refrance">Reference:</div>
            <div>{c}</div>
          </div>
        </pre>
      );
    } else {
      return <pre style={{ whiteSpace: "pre-wrap" }}>{msg}</pre>;
    }
  };
  const getMessages = (newMsg, type) => {
    let newArr = [...mainOutput];
    let tempArr = [];
    newArr.shift();

    newArr?.map((item) => {
      const obj = {
        role: item?.role === chatbotEnum?.bot ? "assistant" : "user",
        content: item?.msg,
      };
      if (item?.citation) {
        const obj2 = {
          role: "tool",
          content: item?.citation,
        };
        tempArr.push(obj2);
      }
      tempArr.push(obj);
    });
    tempArr.push({
      role: "user",
      content: newMsg,
    });
    return tempArr;
  };
  const fetchData = async (msg, outputTemp) => {
    try {
      const params = {
        Messages: getMessages(msg),
        chatSession: true,
      };
      handleLoading(true, outputTemp);
      const response = await genericAxiosCall(
        value === 0
          ? serverRoutes?.SEND_MESSAGE_CHATBOT
          : serverRoutes?.SEND_MESSAGE_CHATBOT_POLICY,
        "post",
        params,
        ""
      );
      if (
        response?.data?.success &&
        response?.data?.data?.choices?.length &&
        response?.data?.data?.choices[0]?.messages?.length >= 2 &&
        response?.data?.data?.choices[0]?.messages[1]?.content
      ) {
        const resMsg = response?.data?.data?.choices[0]?.messages[1]?.content;
        const citation = response?.data?.data?.choices[0]?.messages[0]?.content;
        handleLoading(false, outputTemp);

        let output = [...outputTemp];
        const botMsg = {
          msg: resMsg,
          role: chatbotEnum?.bot,
          citation: citation,
          type: value === 0 ? chatbotEnum?.claim : chatbotEnum?.policy,
        };
        output.push(botMsg);
        value === 0
          ? saveMessageToLocalHost(botMsg)
          : saveMessageToLocalHost_Policy(botMsg);

        setMainOutput(output);

        return { resMsg, citation };

        // handleSyntaxText(resMsg, citation);
      } else {
        handleLoading(false, outputTemp);
        let output = [...outputTemp];
        const botMsg = {
          msg: "Error occurred while fetching data.",
          role: chatbotEnum?.bot,
          citation: "",
          type: value === 0 ? chatbotEnum?.claim : chatbotEnum?.policy,
        };
        output.push(botMsg);
        value === 0
          ? saveMessageToLocalHost(botMsg)
          : saveMessageToLocalHost_Policy(botMsg);
        setMainOutput(output);
        // return { resMsg: "Error occurred while fetching data.", citation: "" };
      }
    } catch (error) {
      handleLoading(false, outputTemp);
      let output = [...outputTemp];
      const botMsg = {
        msg: "Error occurred while fetching data.",
        role: chatbotEnum?.bot,
        citation: "",
        type: value === 0 ? chatbotEnum?.claim : chatbotEnum?.policy,
      };
      output.push(botMsg);
      value === 0
        ? saveMessageToLocalHost(botMsg)
        : saveMessageToLocalHost_Policy(botMsg);
      setMainOutput(output);

      // return { resMsg: "Error occurred while fetching data.", citation: "" };
    }
  };
  const handleChangeInput = (event) => {
    setInput(event.target.value);
  };
  // const handleKeyDown = (event) => {
  //   if (event.keyCode === 13) {
  //     handleSubmit();
  //   }
  // };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      handleSubmit();
    }
  };

  const handleSubmit = async (event) => {
    if (input) {
      setTimeout(scrollToBottom, 0);
      let output = [...mainOutput];
      setLoading(true);
      let userMsg = {
        msg: input,
        role: chatbotEnum?.user,
        citation: "",
      };
      output.push(userMsg);

      setMainOutput(output);
      value === 0
        ? saveMessageToLocalHost(userMsg)
        : saveMessageToLocalHost_Policy(userMsg);

      setInput("");
      fetchData(input, output);
    }
  };

  // For tabs

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value === 0) {
      loadSavedMessages();
    } else {
      loadSavedMessagesPolicy();
    }
  }, [value]);

  return (
    <>
      <div className="DR-Custom-Chat-main">
        <div className="DR-Custom-chat-header">Claim Assistant</div>
        <Tabs
          value={value}
          onChange={!loading && handleChange}
          aria-label="basic tabs example"
          className="DR-837-one-tabs DR-Chatbot-tabs"
        >
          {/* <Tab
            label="Claim"
            // {...a11yProps(0)}
          /> */}
          {/* Policy bot */}
          {/* <Tab
            label="Policy (Azure)"
            // {...a11yProps(1)}
          /> */}
        </Tabs>
        <div className="DR-Custom-Chat-msg-main mt-4" ref={messagesDivRef}>
          {mainOutput?.map((item, index) => {
            return (
              <div key={index}>
                {item?.role === chatbotEnum?.bot ? (
                  <div className="DR-Custom-Chat-msg-bot">
                    <div className="DR-Custom-Chat-msg-bot-inner">
                      {/* {item?.msg} */}
                      {item?.type === chatbotEnum?.policy
                        ? handleSyntaxText_Policy(
                            item?.msg,
                            item?.citation,
                            item
                          )
                        : handleSyntaxText(item?.msg, item?.citation, item)}
                    </div>
                  </div>
                ) : item?.role === "loading" ? (
                  <div className="DR-Custom-Chat-msg-bot">
                    <div className="DR-Custom-Chat-msg-bot-inner">
                      <img style={{ width: "50px" }} src={ChatbotLoading} />
                    </div>
                  </div>
                ) : (
                  <div className="DR-Custom-Chat-msg-user">
                    <div className="DR-Custom-Chat-msg-user-inner">
                      {item?.msg}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="DR-Custom-Chat-input-outer">
          <div className="DR-Custom-Chat-input">
            <TextField
              id="outlined-multiline-flexible"
              multiline
              maxRows={6}
              onChange={handleChangeInput}
              onKeyDown={handleKeyDown}
              placeholder="Write your message here..."
              inputMode="text"
              value={input}
              disabled={loading}
              style={{ width: "100%", height: "100%" }}
            />
            {/* <input
            type="search"
            value={input}
            disabled={loading}
            placeholder="Write your message here..."
            onChange={handleChangeInput}
            onKeyDown={handleKeyDown}
            /> */}
            <button onClick={handleSubmit} disabled={!input}>
              <CommentSendIcon />
            </button>
          </div>
          <button
            className="DR-chat-clear-icon"
            onClick={() => {
              value === 0 ? deleteSavedMessages() : deleteSavedMessagesPolicy();
            }}
            disabled={loading}
            style={{ cursor: "pointer" }}
          >
            <ClearChatIcon />{" "}
          </button>
        </div>
      </div>
      {/* Details Dropdown */}
      <div
        className={
          "filter-sidebar dr-v2-side DR-Alet-msg " + showTableColumnFilter
        }
        style={{ width: "650px" }}
      >
        <div>
          <div
            className="flex items-center justify-between"
            style={{
              borderBottom: "1px solid #efefef",
              backgroundColor: "#efefef",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
          >
            <h3 className="pl-3" style={{ fontWeight: "500" }}>
              Reference
            </h3>
            <div className="flex items-center gap-1">
              <span
                className="pr-3 cursor-pointer flex items-center justify-center"
                onClick={handleTableColumnOpen}
              >
                <span
                // className="Cross-icon-white-colr"
                >
                  <CrossIcon />
                </span>
              </span>
            </div>
          </div>
          <div className="DR-Chat-ref-details p-3">
            <pre style={{ whiteSpace: "pre-wrap" }}>{detailContent}</pre>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatBody;

import React from "react";

const V2ClaimManagerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-shield"
    >
      <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
    </svg>
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 14 14"
    //   height="24"
    //   width="24"
    // >
    //   <g>
    //     <path
    //       d="M9.5,1.5H11a1,1,0,0,1,1,1v10a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V2.5a1,1,0,0,1,1-1H4.5"
    //       fill="none"

    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //     ></path>
    //     <rect
    //       x="4.5"
    //       y="0.5"
    //       width="5"
    //       height="2.5"
    //       rx="1"
    //       fill="none"

    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //     ></rect>
    //     <line
    //       x1="7"
    //       y1="6"
    //       x2="7"
    //       y2="10"
    //       fill="none"

    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //     ></line>
    //     <line
    //       x1="9"
    //       y1="8"
    //       x2="5"
    //       y2="8"
    //       fill="none"

    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //     ></line>
    //   </g>
    // </svg>

    // <svg
    //   width="24"
    //   height="24"
    //   viewBox="0 0 24 24"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <g opacity="0.7">
    //     <path
    //       d="M16 22.75H7.99998C3.37998 22.75 2.51998 20.6 2.29998 18.51L1.54998 10.5C1.43998 9.45 1.40998 7.9 2.44998 6.74C3.34998 5.74 4.83998 5.26 6.99998 5.26H17C19.17 5.26 20.66 5.75 21.55 6.74C22.59 7.9 22.56 9.45 22.45 10.51L21.7 18.5C21.48 20.6 20.62 22.75 16 22.75ZM6.99998 6.75C5.30998 6.75 4.14998 7.08 3.55998 7.74C3.06998 8.28 2.90998 9.11 3.03998 10.35L3.78998 18.36C3.95998 19.94 4.38998 21.25 7.99998 21.25H16C19.6 21.25 20.04 19.94 20.21 18.35L20.96 10.36C21.09 9.11 20.93 8.28 20.44 7.74C19.85 7.08 18.69 6.75 17 6.75H6.99998Z"
    //       fill="#092C4C"
    //     />
    //     <path
    //       d="M16 6.75C15.59 6.75 15.25 6.41 15.25 6V5.2C15.25 3.42 15.25 2.75 12.8 2.75H11.2C8.74998 2.75 8.74998 3.42 8.74998 5.2V6C8.74998 6.41 8.40998 6.75 7.99998 6.75C7.58998 6.75 7.24998 6.41 7.24998 6V5.2C7.24998 3.44 7.24998 1.25 11.2 1.25H12.8C16.75 1.25 16.75 3.44 16.75 5.2V6C16.75 6.41 16.41 6.75 16 6.75Z"
    //       fill="#092C4C"
    //     />
    //     <path
    //       d="M12 16.75C9.24998 16.75 9.24998 15.05 9.24998 14.03V13C9.24998 11.59 9.58998 11.25 11 11.25H13C14.41 11.25 14.75 11.59 14.75 13V14C14.75 15.04 14.75 16.75 12 16.75ZM10.75 12.75C10.75 12.83 10.75 12.92 10.75 13V14.03C10.75 15.06 10.75 15.25 12 15.25C13.25 15.25 13.25 15.09 13.25 14.02V13C13.25 12.92 13.25 12.83 13.25 12.75C13.17 12.75 13.08 12.75 13 12.75H11C10.92 12.75 10.83 12.75 10.75 12.75Z"
    //       fill="#092C4C"
    //     />
    //     <path
    //       d="M14 14.77C13.63 14.77 13.3 14.49 13.26 14.11C13.21 13.7 13.5 13.32 13.91 13.27C16.55 12.94 19.08 11.94 21.21 10.39C21.54 10.14 22.01 10.22 22.26 10.56C22.5 10.89 22.43 11.36 22.09 11.61C19.75 13.31 16.99 14.4 14.09 14.77C14.06 14.77 14.03 14.77 14 14.77Z"
    //       fill="#092C4C"
    //     />
    //     <path
    //       d="M9.99998 14.78C9.96998 14.78 9.93998 14.78 9.90998 14.78C7.16998 14.47 4.49998 13.47 2.18998 11.89C1.84998 11.66 1.75998 11.19 1.98998 10.85C2.21998 10.51 2.68998 10.42 3.02998 10.65C5.13998 12.09 7.56998 13 10.07 13.29C10.48 13.34 10.78 13.71 10.73 14.12C10.7 14.5 10.38 14.78 9.99998 14.78Z"
    //       fill="#092C4C"
    //     />
    //   </g>
    // </svg>
  );
};

export default V2ClaimManagerIcon;

import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
var defaultEndDate = moment().format("YYYY-MM-DD");
var defaultStartDate = moment().subtract(3, "months").format("YYYY-MM-DD");
const initialState = {
  defaultStartDate: defaultStartDate,
  defaultEndDate: defaultEndDate,
  startDate: defaultStartDate,
  endDate: defaultEndDate,
  customStartDate: moment(defaultStartDate).format("MM/DD/YYYY"),
  customEndDate: moment(defaultEndDate).format("MM/DD/YYYY"),
  data: [],
  claimFilterBar: "",
  preferenceFilterBar: "",
  loadingClass: "",
  pagination: {
    page: 1,
    offSet: 0,
    totalCount: 10,
    countPerPage: 10,
    totalRecords: 0,
    pageRows: 0,
  },
  orderFilters: {
    dosOrder: "asc",
    order: "asc",
    noOrder: "asc",
    totalAmountOrder: "asc",
    paymentOrder: "asc",
    differenceOrder: "asc",
    patientOrder: "asc",
    orderBy: "PayerClaimControlNo",
  },
  payers: {
    totalPayers: [],
    selectedPayers: [],
    payerOpen: false,
  },
  admin: {
    adminCodes: [],
    selectedAdminCodes: [],
    adminCodeOpen: false,
  },
  jcodes: {
    jcodeCodes: [],
    selectedJCodes: [],
    jCodeOpen: false,
  },
  ancillary: {
    ancillaryCodes: [],
    selectedAncillaryCode: [],
    ancillaryCodeOpen: false,
  },
  premeds: {
    premedCodes: [],
    selectedPremedCode: [],
    premedCodeOpen: false,
  },
  claimStatusName: "All",
  selectedStatus: [],
  statusOpen: false,
  msgAlert: false,
  claimPaymentStatus: [],
  selectedClaimPaymentStatus: [],
};

export const CodeReducer = createSlice({
  name: "PayementReducer-835",
  initialState,
  reducers: {
    setDate(state, action) {
      const { startDate, endDate } = action.payload;
      return { ...state, startDate: startDate, endDate: endDate };
    },
    setCustomeDate(state, action) {
      const { customStartDate, customEndDate } = action.payload;
      return {
        ...state,
        customStartDate: customStartDate,
        customEndDate: customEndDate,
      };
    },
    setData(state, action) {
      return { ...state, data: action.payload };
    },
    setLoadingClass(state, action) {
      return { ...state, loadingClass: action.payload };
    },
    setClaimFilterBar(state, action) {
      return { ...state, claimFilterBar: action.payload };
    },
    setPreferenceFilterBar(state, action) {
      return { ...state, preferenceFilterBar: action.payload };
    },
    setStatusOpen(state, action) {
      return { ...state, statusOpen: action.payload };
    },
    setSelectedStatus(state, action) {
      return { ...state, selectedStatus: action.payload };
    },
    setMsgAlert(state, action) {
      return { ...state, msgAlert: action.payload };
    },
    setClaimStatusName(state, action) {
      return { ...state, claimStatusName: action.payload };
    },
    setPaymentStatus(state, action) {
      return { ...state, claimPaymentStatus: action.payload };
    },
    setSelectedClaimPaymentStatus(state, action) {
      return { ...state, selectedClaimPaymentStatus: action.payload };
    },
    setOrderFilters(state, action) {
      let orderFilters = { ...state.orderFilters };
      const { payload } = action;
      for (const key in payload) {
        Object.assign(orderFilters, { [key]: payload[key] });
      }
      return { ...state, orderFilters: orderFilters };
    },
    setPayers(state, action) {
      let payers = { ...state.payers };
      const { payload } = action;
      for (const key in payload) {
        Object.assign(payers, { [key]: payload[key] });
      }
      return { ...state, payers: payers };
    },
    setAdminCodes(state, action) {
      let admin = { ...state.admin };
      const { payload } = action;
      for (const key in payload) {
        Object.assign(admin, { [key]: payload[key] });
      }
      return { ...state, admin: admin };
    },
    setJcodes(state, action) {
      let jcodes = { ...state.jcodes };
      const { payload } = action;
      for (const key in payload) {
        Object.assign(jcodes, { [key]: payload[key] });
      }
      return { ...state, jcodes: jcodes };
    },
    setAncillary(state, action) {
      let ancillary = { ...state.ancillary };
      const { payload } = action;
      for (const key in payload) {
        Object.assign(ancillary, { [key]: payload[key] });
      }
      return { ...state, ancillary: ancillary };
    },
    setPremeds(state, action) {
      let premeds = { ...state.premeds };
      const { payload } = action;
      for (const key in payload) {
        Object.assign(premeds, { [key]: payload[key] });
      }
      return { ...state, premeds: premeds };
    },
    setPagination(state, action) {
      const { payload } = action;
      let pagination = { ...state.pagination };
      for (const key in payload) {
        Object.assign(pagination, { [key]: payload[key] });
      }
      state = { ...state, pagination: pagination };
      return state;
    },
  },
});

export const {
  setPagination,
  setPremeds,
  setAncillary,
  setJcodes,
  setAdminCodes,
  setPayers,
  setOrderFilters,
  setSelectedClaimPaymentStatus,
  setPaymentStatus,
  setClaimStatusName,
  setMsgAlert,
  setSelectedStatus,
  setStatusOpen,
  setPreferenceFilterBar,
  setClaimFilterBar,
  setData,
  setCustomeDate,
  setDate,
  setLoadingClass,
} = CodeReducer.actions;

export default CodeReducer.reducer;

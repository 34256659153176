import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import CommonService from "../../../actions/common";
import claims from "../../../actions/claims";
import UserService from "../../../actions/user";
import { serverRoutes } from "./../../../constants/serverRoutes";
import { setListingState } from "./claimManagerFilters";
import genericAxiosCall from "./../../../AxiosConfig/genericAxiosCall";

export const filterData = createSlice({
  name: "filterData",

  initialState: {
    cptCodesForClaimPayment: [],
    jCodesForPatientRepeatDenialArr: [],
    cptCodesForDenialDetail: [],
    payers: [],
    providers: [],
    cptCodesForManager: [],
    diagCodesForManager: [],
    payersForPayment: [],
    claimStatusType: [
      "All",
      "Fully Denied",
      "Partially Denied",
      "Reversal Amount",
    ],
    // claim type for pateint repeat denials screen
    claimType: ["All", "onCology"],
    reasonCodes: [],
    reasonCodes_ForClaimPayments: [],
    adminCodes: [],
    claimStatusCodes: [],
    claimStatusData: [
      // { id: 1, name: "Accepted" },
      // { id: 2, name: "Rejected" },
      // { id: 3, name: "Open" },
    ],

    serviceLineTypeArr: [
      { value: "All", label: "All Others" },
      { value: "oncology", label: "Oncology" },
    ],
  },

  reducers: {
    setCptCodesForClaimPayments: (state, action) => {
      let res = action.payload;
      if (res?.length) {
        let newArr = [];
        res.forEach((item, i) => {
          let userFriendlyName = item.Description.length
            ? `${item.CPTCode} - ${item.Description}`
            : `${item.CPTCode}`;
          newArr.push({
            value: item?.CPTCode,
            count: item?.Count,
            label: userFriendlyName,
          });
        });

        let descending = newArr.sort(
          (a, b) => Number(b.count) - Number(a.count)
        );

        state.cptCodesForClaimPayment = descending;
      } else {
        state.cptCodesForClaimPayment = [];
      }
    },

    setJCodesForPatientRepeatDenial: (state, action) => {
      let data = action.payload;
      if (data?.length) {
        let newArr = [];
        data.forEach((item, i) => {
          let userFriendlyName = item.Description.length
            ? `${item.CPTCode} - ${item.Description}`
            : `${item.CPTCode}`;
          newArr.push({
            value: item?.CPTCode,
            count: item?.Count,
            label: userFriendlyName,
          });
        });

        let descending = newArr.sort(
          (a, b) => Number(b.count) - Number(a.count)
        );

        state.jCodesForPatientRepeatDenialArr = descending;
      } else {
        state.jCodesForPatientRepeatDenialArr = [];
      }
    },

    setCptCodesForDenialDetail: (state, action) => {
      let res = action.payload;
      if (res?.length) {
        let newArr = [];
        res.forEach((item, i) => {
          let userFriendlyName = item?.Description?.length
            ? `${item?.cptCode} - ${item?.description}`
            : `${item?.cptCode}`;
          newArr.push({
            value: item.cptCode,
            label: userFriendlyName,
          });
        });
        state.cptCodesForDenialDetail = newArr;
      } else {
        state.cptCodesForDenialDetail = [];
      }
    },

    setPayersForClaimRequest: (state, action) => {
      if (action?.payload?.length) {
        let newArr = [];
        action?.payload?.forEach((item) => {
          newArr.push({
            label: item?.payer,
            value: item?.id,
            // id: item.id,
          });
        });

        state.payers = newArr;
      } else {
        state.payers = [];
      }
    },

    setProvidersForClaimRequest: (state, action) => {
      if (action?.payload?.length) {
        let newArr = [];
        action?.payload?.forEach((item) => {
          newArr.push({
            label: item?.name,
            value: item?.billingProviderId,
            // id: item.id,
          });
        });

        state.providers = newArr;
      } else {
        state.providers = [];
      }
    },

    setCptCodesForClaimRequest: (state, action) => {
      if (action?.payload?.length) {
        let newArr = [];
        action?.payload?.forEach((item) => {
          if (item?.cptCode !== "") {
            let userFriendlyName = item?.description
              ? `${item.cptCode} - ${item.description}`
              : `${item.cptCode}`;
            newArr.push({
              label: userFriendlyName,
              value: item?.cptCode,
            });
          }
        });

        state.cptCodesForManager = newArr;
      } else {
        state.cptCodesForManager = [];
      }
    },

    setDiagnosisCodesForClaimRequest: (state, action) => {
      if (action?.payload?.length) {
        let newArr = [];
        action?.payload?.forEach((item) => {
          newArr.push({
            label: item?.diagnosisCode,
            value: item?.diagnosisCode,
          });
        });

        state.diagCodesForManager = newArr;
      } else {
        state.diagCodesForManager = [];
      }
    },

    setPayersForServicePayment: (state, action) => {
      if (action?.payload?.length) {
        let newArr = [];
        action?.payload?.forEach((item) => {
          newArr.push({
            label: item?.name,
            value: item?.id,
          });
        });

        state.payersForPayment = newArr;
      } else {
        state.payersForPayment = [];
      }
    },

    setAdminCodes: (state, action) => {
      state.adminCodes = action.payload;
    },
    setAncillaryCodes: (state, action) => {
      state.ancillaryCodes = action.payload;
    },
    setJCodes: (state, action) => {
      state.jCodes = action.payload;
    },

    setReasonCodes: (state, action) => {
      let res = action.payload;

      if (res?.length) {
        let newArr = [];
        res.forEach((item, i) => {
          let userFriendlyName = `${item.code} - ${
            item.description.length <= 25
              ? item.description
              : `${item.description.substring(0, 25)}...`
          }`;
          newArr.push({
            value: item.code,
            label: userFriendlyName,
          });
        });

        state.reasonCodes = newArr;
      } else {
        state.reasonCodes = [];
      }
    },

    setReasonCodes_ForClaimPayments: (state, action) => {
      let res = action.payload;

      if (res?.length) {
        let newArr = [];
        res.forEach((item, i) => {
          let userFriendlyName = `${item.code} - ${item.description}`;

          newArr.push({
            value: item.code,
            label: userFriendlyName,
            count: item.ClaimCount,
            ServiceCount: item.ServiceCount,
          });
        });

        let descending = newArr.sort(
          (a, b) => Number(b.count) - Number(a.count)
        );

        state.reasonCodes_ForClaimPayments = descending;
      } else {
        state.reasonCodes_ForClaimPayments = [];
      }
    },

    setClaimStatusCodes: (state, action) => {
      let payload = action.payload;
      const { output } = payload;
      state.claimStatusCodes = output;
    },

    setClaimStatusData: (state, action) => {
      let payload = action.payload;
      const { output } = payload;

      if (output?.length) {
        let newArr = [];
        output.forEach((item, i) => {
          let userFriendlyName = `${item.ClaimStatusCodeId} - ${item.Description}`;
          newArr.push({
            value: item.ClaimStatusCodeId,
            label: userFriendlyName,
          });
        });

        state.claimStatusData = newArr;
      } else {
        state.claimStatusData = [];
      }
    },

    setClaimStatusCodes: (state, action) => {
      let payload = action.payload;
      const { output } = payload;
      state.claimStatusCodes = output;
    },
  },
});

export const {
  setCptCodesForClaimPayments,
  setJCodesForPatientRepeatDenial,
  setCptCodesForDenialDetail,
  setPayersForClaimRequest,
  setProvidersForClaimRequest,
  setCptCodesForClaimRequest,
  setDiagnosisCodesForClaimRequest,
  setPayersForServicePayment,
  setReasonCodes,
  setReasonCodes_ForClaimPayments,
  setAdminCodes,
  setAncillaryCodes,
  setJCodes,
  setClaimStatusCodes,
  setClaimStatusData,
  setClaimStatusType,
} = filterData.actions;

// get all payers list for Manager(837)
export const gettingPayers = (targetedFilter) => async (dispatch, getState) => {
  let sliceState = getState().npReducers.filters[targetedFilter];

  const res = await CommonService.getPayersForClaimRequest({
    ...(sliceState.from !== "" && {
      from: sliceState.from,
    }),
    ...(sliceState.to !== "" && {
      to: sliceState.to,
    }),
  });
  if (res?.data?.success) {
    dispatch(setPayersForClaimRequest(res?.data?.data));
  } else {
    dispatch(setPayersForClaimRequest([]));
  }
  return res?.data;
};

// get all providers list for Manager(837)
export const gettingProviders =
  (targetedFilter) => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters[targetedFilter];

    const res = await CommonService.getProvidersForClaimRequest({
      ...(sliceState.from !== "" && {
        from: sliceState.from,
      }),
      ...(sliceState.to !== "" && {
        to: sliceState.to,
      }),
    });
    if (res?.data?.success) {
      dispatch(setProvidersForClaimRequest(res?.data?.data));
    } else {
      dispatch(setProvidersForClaimRequest([]));
    }
    return res?.data;
  };

// get CPTCodes list for Manager(837)
export const gettingCPTCodesForManager =
  (targetedFilter) => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters[targetedFilter];

    const cptCodes = await CommonService.getCPTCodesForClaimRequest({
      ...(sliceState.from !== "" && {
        from: sliceState.from,
      }),
      ...(sliceState.to !== "" && {
        to: sliceState.to,
      }),
    });
    if (cptCodes?.data?.success) {
      dispatch(setCptCodesForClaimRequest(cptCodes?.data?.data));
    } else {
      dispatch(setCptCodesForClaimRequest([]));
    }
    return cptCodes?.data;
  };

// get DiagnosisCodes list for Manager(837)
export const gettingDiagnosisCodesForManager =
  (targetedFilter) => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters[targetedFilter];

    const diagCodes = await CommonService.getDiagnosisCodesForClaimRequest({
      ...(sliceState.from !== "" && {
        from: sliceState.from,
      }),
      ...(sliceState.to !== "" && {
        to: sliceState.to,
      }),
    });
    if (diagCodes?.data?.success) {
      dispatch(setDiagnosisCodesForClaimRequest(diagCodes?.data?.data));
    } else {
      dispatch(setDiagnosisCodesForClaimRequest([]));
    }
    return diagCodes?.data;
  };

// get all payers list for Payment(835)
export const gettingPayersForPayment =
  (targetedFilter) => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters[targetedFilter];
    const payers = await CommonService.getPayersForClaimPayments({
      ...(sliceState.from !== "" && {
        from: sliceState.from,
      }),
      ...(sliceState.to !== "" && {
        to: sliceState.to,
      }),
    });
    if (payers?.data?.success) {
      dispatch(setPayersForServicePayment(payers?.data?.data));
    } else {
      dispatch(setPayersForServicePayment([]));
    }
    return payers?.data?.data;
  };

// get ClaimStatus list for Payment(835)
export const gettingClaimStatusForPayment =
  () => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.claimPayment;
    const res = await CommonService.getClaimStatusForClaimRequest({
      ...(sliceState.from !== "" && {
        from: sliceState.from,
      }),
      ...(sliceState.to !== "" && {
        to: sliceState.to,
      }),
    });
    if (res?.data?.success) {
      dispatch(setClaimStatusData(res?.data?.data));
    } else {
      dispatch(setClaimStatusData([]));
    }

    return res?.data;
  };

export const gettingAdminCodes = () => async (dispatch) => {
  const adminCodes = await claims.getCptAdminCode();
  dispatch(setAdminCodes(adminCodes?.data?.data.output));
  return adminCodes?.data?.data.output;
};

export const gettingAncillaryCodes = () => async (dispatch) => {
  const ancillaryCode = await claims.getAncillaryCode();
  dispatch(setAncillaryCodes(ancillaryCode?.data?.data));
  return ancillaryCode?.data?.data;
};
//Jcode Codes
export const gettingJCodes = () => async (dispatch) => {
  const jCodes = await claims.getJcodesForCloudChart();
  dispatch(setJCodes(jCodes?.data?.data));
  return jCodes?.data?.data;
};

// get cptCodes for ClaimPayments (835) screen
export const gettingCptCodesForClaimPayment =
  (targetedFilter) => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters[targetedFilter];
    const cptCodes = await CommonService.getCPTCodes({
      ...(sliceState.from !== "" && {
        from: sliceState.from,
      }),
      ...(sliceState.to !== "" && {
        to: sliceState.to,
      }),
    });

    if (cptCodes?.data?.success) {
      dispatch(setCptCodesForClaimPayments(cptCodes?.data?.data));
    } else {
      // toast.error(`Something went wrong`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
      dispatch(setCptCodesForClaimPayments([]));
    }
    return cptCodes?.data;
  };

// get Jcodes for patient repeat denials  screen
export const gettingJCodesForPatientRepeatDenial =
  (targetedFilter) => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters[targetedFilter];
    const res = await CommonService.getAllJCodes({
      ...(sliceState.from !== "" && {
        from: sliceState.from,
      }),
      ...(sliceState.to !== "" && {
        to: sliceState.to,
      }),
    });

    if (res?.data?.success) {
      dispatch(setJCodesForPatientRepeatDenial(res?.data?.data));
    } else {
      // toast.error(`Something went wrong`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
      dispatch(setJCodesForPatientRepeatDenial([]));
    }
    return res?.data;
  };

// get cptCodes for denialsDetail screen
export const gettingCptCodesForDenialDetail =
  () => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.denialDetailsFilter;
    const cptCodes = await CommonService.getCPTCodes({
      ...(sliceState.from !== "" && {
        from: sliceState.from,
      }),
      ...(sliceState.to !== "" && {
        to: sliceState.to,
      }),
    });

    if (cptCodes?.data?.success) {
      dispatch(setCptCodesForDenialDetail(cptCodes?.data?.data));
    } else {
      // toast.error(`Something went wrong`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
      dispatch(setCptCodesForDenialDetail([]));
    }
    return cptCodes?.data;
  };

export const gettingReasonCodes = () => async (dispatch) => {
  const reasonCodes = await CommonService.getReasonCodes();

  if (reasonCodes?.data?.success) {
    dispatch(setReasonCodes(reasonCodes?.data?.data));
  } else {
    // toast.error(`Something went wrong`, {
    //   position: toast.POSITION.TOP_CENTER,
    // });
    dispatch(setReasonCodes([]));
  }

  return reasonCodes?.data?.data;
};

export const gettingReasonCodesForPayments =
  () => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.claimPayment;

    const reasonCodes = await CommonService.getReasonCodesForClaimPayments({
      ...(sliceState.from !== "" && {
        from: sliceState.from,
      }),
      ...(sliceState.to !== "" && {
        to: sliceState.to,
      }),
    });

    if (reasonCodes?.data?.success) {
      dispatch(setReasonCodes_ForClaimPayments(reasonCodes?.data?.data));
    } else {
      // toast.error(`Something went wrong`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
      dispatch(setReasonCodes_ForClaimPayments([]));
    }

    return reasonCodes?.data;
  };

//getting claim status codes
export const gettingClaimStatusCodes = () => async (dispatch) => {
  const res = await UserService.getClaimStatusCode();

  if (res?.data?.success) {
    dispatch(setClaimStatusCodes(res?.data?.data));
  } else {
    // toast.error(`Something went wrong`, {
    //   position: toast.POSITION.TOP_CENTER,
    // });
    dispatch(setClaimStatusCodes([]));
  }

  return res?.data?.data;
};

// just for static datePicker dispatch function
export const handleDatePickerStatic = () => async (dispatch) => {
  return true;
};

export default filterData.reducer;

import { createSlice } from "@reduxjs/toolkit";

import notifications from "./../../../actions/notifications";
import User from "./../../../actions/user";
import { toast } from "react-toastify";

import { setListingState } from "../filters/claimManagerFilters";

import { commonPagination } from "../../../components/common/Utils/utils";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";
import { serverRoutes } from "../../../constants/serverRoutes";
import { userData } from "../UserReducer";

export const userPreferenceSlice = createSlice({
  name: "userPreferenceSlice",
  initialState: {
    notificationArr: [],
    notificationArrOutputList: [],
    rowCount: 0,
    proActiveNotificationArr: [],
    notificationTypeArr: [],
    notificationTypeArrLoader: true,
  },
  reducers: {
    setNotification_data: (state, action) => {
      state.notificationArr = action?.payload;
    },
    setNotification_data_Output: (state, action) => {
      state.notificationArrOutputList = action?.payload;
      if (action?.payload && action?.payload?.length) {
        state.rowCount = action.payload[0].totalCount;
      }
    },
    setProactiveNotificatio_data: (state, action) => {
      state.proActiveNotificationArr = action?.payload;
    },
    setNotificationType_data: (state, action) => {
      if (action.payload.length > 0) {
        let modifiedArr = action?.payload.map((item) => {
          return { label: item.typeName, key: item.notificationTypeId };
        });
        state.notificationTypeArr = modifiedArr;
        // console.log("123123 L ", modifiedArr);
      } else {
        state.notificationTypeArr = [];
      }
      state.notificationTypeArrLoader = false;
    },
  },
});

export const {
  setNotification_data,
  setNotification_data_Output,
  setProactiveNotificatio_data,
  setNotificationType_data,
} = userPreferenceSlice.actions;

export const getNotifications_Call = () => async (dispatch, getState) => {
  let sliceState = getState().npReducers.filters.notificationsFilter;

  let start = sliceState.pageNumber;
  let limit = sliceState.pageSize;

  if (start >= 2) {
    let rec_val = (start - 1) * limit;
    // start = rec_val + 1;
    start = rec_val;
  }
  if (start === 1) {
    start = 0;
  }

  try {
    ///// API CALL //////
    const params = {
      start,
      limit,
      timestamp: new Date().getTime(),
    };

    // await genericAxiosCall(
    //   serverRoutes.GET_ALL_NOTIFICATION_TYPES,
    //   "get",
    //   "",
    //   params
    // ).then((res) => {
    //   const data = res.data.data;
    //   console.log("123123 res", data);
    // });

    commonPagination(
      getState,
      params,
      params,
      dispatch,
      setNotification_data,
      "notificationsFilter",
      "GET_ALL_NOTIFICATIONS",
      false,
      "",
      ""
    );

    commonPagination(
      getState,
      params,
      params,
      dispatch,
      setNotificationType_data,
      "notificationsTypeFilter",
      "GET_ALL_NOTIFICATION_TYPES",
      false,
      "",
      ""
    );

    ////// API CALL END //////////////
  } catch (e) {
    // console.log("123123 e--->>", e);

    dispatch(
      setListingState({
        listing: "notificationsFilter",
        value: {
          initialLoading: false,
          loadingMore: "",
          hasMore: false,
        },
      })
    );
    return false;
  }
};
export const getNotifications_Call_Output =
  () => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.notificationsFilterTable;
    let sliceStateForClientId =
      getState().npReducers.filters.insightsSreensCommonFilter;
    let sliceStateUser = getState().pReducers.user;

    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    try {
      ///// API CALL //////
      const paramsTemp = {};
      const params = {
        start,
        limit,
        ...(sliceStateUser?.loginUserData?.isRcm && {
          ...(sliceStateUser?.loginUserData?.isRcm && {
            clientId:
              Array.isArray(sliceStateForClientId?.clientId) &&
              sliceStateForClientId?.clientId.length > 0
                ? sliceStateForClientId?.clientId[0].value
                : sliceStateForClientId?.clientId || getCurrentClientId(),
          }),
        }),
      };

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setNotification_data_Output,
        "notificationsFilterTable",
        "GET_ALL_NOTIFICATIONS",
        false,
        "",
        ""
      );

      ////// API CALL END //////////////
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "notificationsFilterTable",
          value: {
            initialLoading: false,
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      return false;
    }
  };

export const markAllAsRead_Call = () => async (dispatch, getState) => {
  let sliceState = getState().pReducers.user;
  let userToken = sliceState.userToken;
  let body = {
    userId: userToken,
  };

  try {
    dispatch(
      setListingState({
        listing: "notificationsFilter",
        value: {
          loadingMore: "data-loading",
        },
      })
    );

    ///// API CALL //////
    const res = await notifications.readAllNotification(body);
    ////// API CALL END //////////////

    dispatch(
      setListingState({
        listing: "notificationsFilter",
        value: {
          //   hasMore: res?.data?.data?.activityLog?.length === sliceState.pageSize,
          loadingMore: "",
        },
      })
    );

    // dispatch(setNotification_data(res?.data?.data));
    return res?.data;
  } catch (e) {
    // console.log("e--->>", e);

    dispatch(
      setListingState({
        listing: "notificationsFilter",
        value: {
          initialLoading: false,
          loadingMore: "",
          hasMore: false,
        },
      })
    );
    return false;
  }
};

export const saveUserAdditionalDataIntoDB_Call =
  (obj) => async (dispatch, getState) => {
    // getting state here
    let sliceState = getState().pReducers.user;

    try {
      // console.log("obj--->>", obj);

      /// API CALL //////
      const res = await genericAxiosCall(
        serverRoutes?.SAVE_USER_ADDITIONAL_DATA,
        "post",
        obj,
        ""
      );
      //// API CALL END //////////////

      if (res?.data?.success) {
        // console.log("res_-->>", res?.data);
        //============= AdditionalDetail settings =============
        let finalUserModifiedObj = {};

        finalUserModifiedObj = {
          ...sliceState,
          AdditionalDetail: obj?.AdditionalDetail,
        };

        // console.log("finalUserModifiedObj--->>", finalUserModifiedObj);

        dispatch(userData(finalUserModifiedObj));

        //============= AdditionalDetail settings =============
      } else {
        // toast.error(`${res?.data?.message}`, {
        //   position: toast.POSITION.TOP_CENTER,
        // });
      }

      return res.data;
    } catch (e) {
      console.log("e--->>", e);

      return false;
    }
  };

export const saveUserPreferences_Call =
  (data) => async (dispatch, getState) => {
    // getting state here
    let sliceState = getState().pReducers.user;
    let { userId, email } = sliceState.loginUserData;

    try {
      ///// API CALL //////
      const res = await User.savePreferences({
        ...data,
        UserId: userId,
        email: email,
      });
      ////// API CALL END //////////////

      if (res?.data?.success) {
        // console.log("res_savePreferences--->>", res?.data);
        // toast.success(`${res?.data?.message}`, {
        //   position: toast.POSITION.TOP_CENTER,
        // });
      } else {
        toast.error(`${res?.data?.message}`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }

      return res.data;
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    } finally {
    }
  };

export const saveUserPreferences_Call2 =
  (data) => async (dispatch, getState) => {
    // getting state here
    let sliceState = getState().pReducers.user;
    let { userId, email } = sliceState.loginUserData;

    try {
      ///// API CALL //////
      const res = await User.savePreferences({
        ...data,
        UserId: userId,
        email: email,
      });
      ////// API CALL END //////////////

      if (res?.data?.success) {
        // console.log("res_savePreferences--->>", res?.data);
        // toast.success(`${res?.data?.message}`, {
        //   position: toast.POSITION.TOP_CENTER,
        // });
      } else {
        // toast.error(`${res?.data?.message}`, {
        //   position: toast.POSITION.TOP_CENTER,
        // });
      }

      return res.data;
    } catch (e) {
      return false;
    } finally {
    }
  };

export default userPreferenceSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

import { resetListingState } from "../filters/claimManagerFilters";
import {
  commonPagination,
  getMultiSelectFilterValueForApi,
  getTreeNodeObjInsights,
  getTreeNodeObjFullPath,
  getCurrentClientId,
} from "../../../components/common/Utils/utils";
import store from "../../store";

const transformData = (data, dataFilerKey, filterLimit) => {
  try {
    // console.log("transformData---_data", data);

    // Filter out objects with 'per' value greater than 0
    const filteredArr = data.filter((item) => item.percentage > filterLimit);

    // ============ handle extra legends here ========

    let filteredNamedArr = [];
    if (dataFilerKey == "facilityName") {
      filteredNamedArr = data.filter((item) => item?.percentage > 1);
      // console.log("filteredNamedArr_if--->>", dataFilerKey, filteredNamedArr);
    } else {
      filteredNamedArr = data.filter((item) => item?.percentage > 2);
      // console.log("filteredNamedArr_else--->>", dataFilerKey, filteredNamedArr);
    }

    const uniquePayerNames = [
      ...new Set(filteredNamedArr.map((item) => item[dataFilerKey])),
    ];
    // console.log("uniquePayerNames--->>", dataFilerKey, uniquePayerNames);

    const newArrExtraLegend = uniquePayerNames.map((item) =>
      item === "" ? "Other" : item
    );
    // console.log("newArrExtraLegend--->>", dataFilerKey, newArrExtraLegend);

    // ============ handle extra legends here ========

    let tempArr = filteredArr;
    let obj = {};
    let keyValues = {};
    let filterType;
    tempArr
      ?.sort((a, b) => b?.percentage - a?.percentage)
      ?.map((item, index) => {
        let monthArr = item?.month;
        filterType = item?.month.includes("W");

        let facilityName = item?.[dataFilerKey]
          ? item?.[dataFilerKey]
          : `Other`;
        if (monthArr) {
          if (obj[monthArr]) {
            let arr = obj[monthArr];
            keyValues[facilityName] = "";
            arr.push({
              denialAmount: item?.denailAmount,
              denialCount: item?.denialCount,
              [dataFilerKey]: facilityName,
              per: item?.percentage.toFixed(2),
              desc: item?.facilityTypeDescription
                ? item?.facilityTypeDescription
                : "",
            });
            obj[monthArr] = arr;
          } else {
            let arr = [];
            keyValues[facilityName] = "";
            arr.push({
              denialAmount: item?.denailAmount,
              denialCount: item?.denialCount,
              [dataFilerKey]: facilityName,
              per: item?.percentage.toFixed(2),
              desc: item?.facilityTypeDescription
                ? item?.facilityTypeDescription
                : "",
            });
            obj[monthArr] = arr;
          }
        }
      });

    let arrMain = [];
    Object.keys(obj)?.map((item1, index) => {
      let objNew = {
        country: item1,
        // item: obj[item1],
      };
      let total = 0;
      obj[item1]
        ?.sort((a, b) => b?.per - a?.per)
        ?.map((item2, index) => {
          objNew[item2?.[dataFilerKey]] = item2?.per;
          objNew[`item-${item2?.[dataFilerKey]}`] = item2;
          total = total + item2?.per;
        });
      objNew.total = total;
      arrMain.push(objNew);
    });

    let finalArr = [];

    arrMain = arrMain?.map((item, index) => {
      let tempArr = item;
      if (tempArr?.country?.includes("Week")) {
        tempArr = { ...tempArr, country: `Week 0${index + 1}` };
      }
      // console.log("arrMain--->>", tempArr);

      delete tempArr.total;

      // console.log("tempArr--->>", tempArr);

      // const sortedObj = sortObjectValuesDescending(tempArr);
      // console.log("sortedObj--->>", sortedObj);

      //==============================================

      //==============================================

      finalArr.push(tempArr);
    });

    // console.log("finalArr--->>", finalArr);

    const keyValuesMain = Object.keys(keyValues);

    // Define the order of months
    const monthOrder = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const weekOrder = Array.from(
      { length: 200 },
      (_, index) => `Week ${String(index + 1).padStart(2, "0")}`
    );

    // Sort the array based on the condition filterType
    finalArr.sort((a, b) => {
      const monthA = a.country;
      const monthB = b.country;

      // Check if the condition filterType is true
      if (filterType) {
        return weekOrder.indexOf(monthA) - weekOrder.indexOf(monthB);
      } else {
        return monthOrder.indexOf(monthA) - monthOrder.indexOf(monthB);
      }
    });

    //=====================
    // Your graphArr and legendsArray
    const graphArr = finalArr[0];

    const legendsArray = newArrExtraLegend;

    // Sorting legendsArray based on values in graphArr
    legendsArray.sort((a, b) => {
      const valueA = parseFloat(graphArr[a]) || 0; // Convert value to float, default to 0 if NaN
      const valueB = parseFloat(graphArr[b]) || 0;

      // Sort in descending order (highest value first)
      return valueB - valueA;
    });

    // console.log("legendsArray__--->>", legendsArray);

    //=====================

    // console.log(">> data finalArr--->>", finalArr);

    return {
      finalArr: finalArr,
      keyValues: keyValuesMain,
      // extraLegend: newArrExtraLegend,
      extraLegend: legendsArray,
    };
  } catch (e) {
    // console.log(">> data transformData_e--->>", e);
  }
};

// const transformDataForEqualWeightDenial = (data, dataFilerKey, filterLimit) => {
//   try {
//     // console.log("123123 ---_data", dataFilerKey);

//     // Filter out objects with 'per' value greater than 0
//     const filteredArr = data;
//     // .filter((item) => item.percentage > filterLimit);
//     // console.log("123123 FilterArr ", filteredArr);
//     // ============ handle extra legends here ========

//     let filteredNamedArr = [];
//     if (dataFilerKey == "facilityName") {
//       filteredNamedArr = data.filter((item) => item?.percentage > 1);
//       // console.log("filteredNamedArr_if--->>", dataFilerKey, filteredNamedArr);
//     } else {
//       filteredNamedArr = data.filter((item) => item?.percentage > 2);
//       // console.log("filteredNamedArr_else--->>", dataFilerKey, filteredNamedArr);
//     }

//     const uniquePayerNames = [
//       ...new Set(filteredNamedArr.map((item) => item[dataFilerKey])),
//     ];
//     // console.log("uniquePayerNames--->>", dataFilerKey, uniquePayerNames);

//     const newArrExtraLegend = uniquePayerNames.map((item) =>
//       item === "" ? "Other" : item
//     );
//     // console.log("newArrExtraLegend--->>", dataFilerKey, newArrExtraLegend);

//     // ============ handle extra legends here ========

//     let tempArr = filteredArr;
//     let obj = {};
//     let keyValues = {};
//     let filterType;
//     tempArr
//       ?.sort((a, b) => b?.denailAmount - a?.denailAmount)
//       ?.map((item, index) => {
//         let monthArr = item?.month;
//         filterType = item?.month.includes("W");

//         let facilityName = item?.[dataFilerKey]
//           ? item?.[dataFilerKey]
//           : `Other`;
//         if (monthArr) {
//           if (obj[monthArr]) {
//             let arr = obj[monthArr];
//             keyValues[facilityName] = "";
//             arr.push({
//               denialAmount: item?.denailAmount,
//               denialCount: item?.claimCount,
//               [dataFilerKey]: facilityName,
//               per: item?.percentage.toFixed(2),
//               desc: item?.facilityTypeDescription
//                 ? item?.facilityTypeDescription
//                 : "",
//             });
//             obj[monthArr] = arr;
//           } else {
//             let arr = [];
//             keyValues[facilityName] = "";
//             arr.push({
//               denialAmount: item?.denailAmount,
//               denialCount: item?.claimCount,
//               [dataFilerKey]: facilityName,
//               per: item?.percentage.toFixed(2),
//               desc: item?.facilityTypeDescription
//                 ? item?.facilityTypeDescription
//                 : "",
//             });
//             obj[monthArr] = arr;
//           }
//         }
//       });
//     // console.log("123123 > check temp 1 > ", obj);

//     // let newObj = { ...obj };
//     // for (let i = 1; i <= Object.keys(obj).length; i++) {
//     //   newObj[`${i}`] = obj[`${i}`].sort(
//     //     (a, b) => b?.denialAmount - a?.denialAmount
//     //   );
//     //   console.log("123123 > check temp 2 > ", newObj);
//     // }
//     // console.log("123123 > check temp 2 > ", newObj);

//     let arrMain = [];
//     Object.keys(obj)?.map((item1, index) => {
//       let objNew = {
//         country: item1,
//         // item: obj[item1],
//       };
//       let total = 0;
//       obj[item1]
//         ?.sort((a, b) => b?.per - a?.per)
//         ?.map((item2, index) => {
//           objNew[item2?.[dataFilerKey]] = item2?.per;
//           objNew[`item-${item2?.[dataFilerKey]}`] = item2;
//           total = total + item2?.per;
//         });
//       objNew.total = total;
//       arrMain.push(objNew);
//     });

//     let finalArr = [];

//     arrMain = arrMain?.map((item, index) => {
//       let tempArr = item;
//       if (tempArr?.country?.includes("Week")) {
//         tempArr = { ...tempArr, country: `Week 0${index + 1}` };
//       } else if (tempArr?.country?.length == 1) {
//         tempArr = { ...tempArr, country: `Week 0${tempArr?.country}` };
//       }
//       // console.log("arrMain--->>", tempArr);

//       delete tempArr.total;

//       // console.log("tempArr--->>", tempArr);

//       // const sortedObj = sortObjectValuesDescending(tempArr);
//       // console.log("sortedObj--->>", sortedObj);

//       //==============================================

//       //==============================================

//       finalArr.push(tempArr);
//     });

//     // console.log("finalArr--->>", finalArr);

//     const keyValuesMain = Object.keys(keyValues);

//     // Define the order of months
//     const monthOrder = [
//       "January",
//       "February",
//       "March",
//       "April",
//       "May",
//       "June",
//       "July",
//       "August",
//       "September",
//       "October",
//       "November",
//       "December",
//     ];
//     const weekOrder = Array.from(
//       { length: 200 },
//       (_, index) => `Week ${String(index + 1).padStart(2, "0")}`
//     );

//     // Sort the array based on the condition filterType
//     finalArr.sort((a, b) => {
//       const monthA = a.country;
//       const monthB = b.country;

//       // Check if the condition filterType is true
//       if (filterType) {
//         return weekOrder.indexOf(monthA) - weekOrder.indexOf(monthB);
//       } else {
//         return monthOrder.indexOf(monthA) - monthOrder.indexOf(monthB);
//       }
//     });

//     //=====================
//     // Your graphArr and legendsArray
//     const graphArr = finalArr[0];

//     const legendsArray = newArrExtraLegend;

//     // Sorting legendsArray based on values in graphArr
//     legendsArray.sort((a, b) => {
//       const valueA = parseFloat(graphArr[a]) || 0; // Convert value to float, default to 0 if NaN
//       const valueB = parseFloat(graphArr[b]) || 0;

//       // Sort in descending order (highest value first)
//       return valueB - valueA;
//     });

//     // console.log("legendsArray__--->>", legendsArray);

//     //=====================

//     // console.log("finalArr--->>", loadingFilterKey, finalArr);

//     return {
//       finalArr: finalArr,
//       keyValues: keyValuesMain,
//       // extraLegend: newArrExtraLegend,
//       extraLegend: legendsArray,
//     };
//   } catch (e) {
//     console.log("transformData_e--->>", e);
//   }
// };

const transformDataForEqualWeightDenial = (data, dataFilerKey, filterLimit) => {
  try {
    // Filter out objects with 'per' value greater than the filter limit
    const filteredArr = data.filter((item) => item.percentage > filterLimit);

    // Filter named array based on condition
    let filteredNamedArr = data.filter((item) => {
      if (dataFilerKey == "facilityName") {
        return item?.percentage > 1;
      } else {
        return item?.percentage > 2;
      }
    });

    const uniquePayerNames = [
      ...new Set(filteredNamedArr.map((item) => item[dataFilerKey] || "Other")),
    ];

    const newArrExtraLegend = uniquePayerNames.map((item) =>
      item === "" ? "Other" : item
    );

    let obj = {};
    let keyValues = {};
    let filterType;

    filteredArr
      .sort((a, b) => b?.denailAmount - a?.denailAmount)
      .forEach((item) => {
        let monthArr = item?.month;
        filterType = item?.month.includes("W");

        let facilityName = item?.[dataFilerKey] || "Other";
        if (monthArr) {
          if (!obj[monthArr]) {
            obj[monthArr] = [];
          }
          keyValues[facilityName] = "";
          obj[monthArr].push({
            denialAmount: item?.denailAmount,
            denialCount: item?.claimCount,
            [dataFilerKey]: facilityName,
            per: item?.percentage.toFixed(2),
            desc: item?.facilityTypeDescription || "",
          });
        }
      });

    let arrMain = Object.keys(obj).map((month) => {
      let monthData = obj[month].sort((a, b) => b.per - a.per);
      let total = monthData.reduce((sum, item) => sum + item.per, 0);

      let monthObj = { country: month };
      monthData.forEach((item) => {
        monthObj[item[dataFilerKey]] = item.per;
        monthObj[`item-${item[dataFilerKey]}`] = item;
      });
      monthObj.total = total;

      return monthObj;
    });

    let finalArr = arrMain.map((item, index) => {
      let country = item.country;
      if (country.includes("Week")) {
        country = `Week 0${index + 1}`;
      } else if (country.length === 1) {
        country = `Week 0${country}`;
      }
      return { ...item, country };
    });

    const keyValuesMain = Object.keys(keyValues);

    // Define the order of months
    const monthOrder = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const weekOrder = Array.from(
      { length: 200 },
      (_, index) => `Week ${String(index + 1).padStart(2, "0")}`
    );

    // Sort the array based on the condition filterType
    finalArr.sort((a, b) => {
      const monthA = a.country;
      const monthB = b.country;

      if (filterType) {
        return weekOrder.indexOf(monthA) - weekOrder.indexOf(monthB);
      } else {
        return monthOrder.indexOf(monthA) - monthOrder.indexOf(monthB);
      }
    });

    const graphArr = finalArr[0];
    const legendsArray = newArrExtraLegend.slice(); // Create a shallow copy to avoid modifying the original array

    legendsArray.sort((a, b) => {
      const valueA = parseFloat(graphArr[a]) || 0;
      const valueB = parseFloat(graphArr[b]) || 0;
      return valueB - valueA;
    });

    return {
      finalArr,
      keyValues: keyValuesMain,
      extraLegend: legendsArray,
    };
  } catch (e) {
    console.log("transformData_e--->>", e);
  }
};

export const ProcedureCodesDashboard = createSlice({
  name: "ProcedureCodesDashboard",
  initialState: {
    // procedureCodes filter array
    cptCodesFilterArr: [],

    // denialsByFacility
    denialsByFacilityGraphArr: [],
    denialsByFacilityKeyValuesArr: [],
    denialsByFacilityCustomLegendArr: [],

    // denialsByFacilityType
    denialsByFacilityTypeGraphArr: [],
    denialsByFacilityTypeKeyValuesArr: [],
    denialsByFacilityTypeCustomLegendArr: [],

    // denialsByPayer
    denialsByPayerGraphArr: [],
    denialsByPayerKeyValuesArr: [],
    denialsByPayerCustomLegendArr: [],

    // denialsByProvider
    denialsByProviderGraphArr: [],
    denialsByProviderKeyValuesArr: [],
    denialsByProviderCustomLegendArr: [],

    // serviceLines filter Array
    serviceLinesFilterArr: [],

    // hanlde loading
    filterLoader: {
      loadingPayers: true,
      loadingFacility: true,
      loadingProvider: true,
      loadingFacilityType: true,
      loadingServiceLine: true,
    },

    // denial distribution handle here
    // hanlde loading
    denialDistribtionfilterLoader: {
      loadingPayers: true,
    },

    // denialsByPayer
    denialsDistributionPayerGraphArr: [],
    denialsDistributionKeyValuesArr: [],
    denialsDistributionCustomLegendArr: [],

    // denialsPerByPayer
    denialsPerByPayerGraphArr: [],
    denialsPerByPayerKeyValuesArr: [],
    denialsPerByPayerCustomLegendArr: [],

    loadingDenialsPerByPayer: true,
    loadingDenialsPerByPayer2: true,

    // denialsRateByPayer
    denialsRateDistributionPayerGraphArr: [],
    denialsRateDistributionKeyValuesArr: [],
    denialsRateDistributionCustomLegendArr: [],

    denialRateDistribtionfilterLoader: {
      loadingRatePayers: true,
    },
  },
  reducers: {
    setCPTCodes_Data: (state, action) => {
      let data1 = action.payload;
      // denialsByFacility handle here
      if (data1 && data1?.length) {
        let newArr = [];
        data1?.forEach((item) => {
          let desc = item?.Code + ` - ${item?.Description}`;
          newArr.push({
            label: desc,
            value: item?.Code,
          });
        });

        state.cptCodesFilterArr = newArr;
      } else {
        state.cptCodesFilterArr = [];
      }
    },

    //Service Line Filters
    gettingServiceLineFilter: (state, action) => {
      state.filterLoader.loadingServiceLine = true;
    },
    successServiceLineFilter: (state, action) => {
      const data = action?.payload;
      if (data?.CPTCodeJSON) {
        state.serviceLinesFilterArr = JSON.parse(data?.CPTCodeJSON);
      } else {
        state.serviceLinesFilterArr = [];
      }
      state.filterLoader.loadingServiceLine = false;
    },

    gettingProviderFilter: (state, action) => {
      state.filterLoader.loadingProvider = true;
    },
    setProviderCode_Data: (state, action) => {
      let data1 = action.payload;
      try {
        // denialsByProvider handle here
        if (data1?.denialsByProvider?.length) {
          const resultArr = transformData(
            data1?.denialsByProvider,
            "payeeName",
            0
          );

          state.denialsByProviderGraphArr = resultArr?.finalArr;
          state.denialsByProviderKeyValuesArr = resultArr?.keyValues;
          state.denialsByProviderCustomLegendArr = resultArr?.extraLegend;
        } else {
          state.denialsByProviderGraphArr = [];
          state.denialsByProviderKeyValuesArr = [];
          state.denialsByProviderCustomLegendArr = [];
        }
        state.filterLoader.loadingProvider = false;
      } catch (err) {}
    },

    gettingPayerFilter: (state, action) => {
      state.filterLoader.loadingPayers = true;
    },

    gettingPayerDenialDistributionFilter: (state, action) => {
      state.denialDistribtionfilterLoader.loadingPayers = true;
    },

    setPayerCode_Data: (state, action) => {
      let data1 = action.payload;
      try {
        // console.log("setPayerCode_Data_Data_data1--->>", data1);

        // denialsByPayer handle here
        if (data1?.denialsByPayer?.length) {
          const resultArr = transformData(
            data1?.denialsByPayer,
            "payerName",
            0
          );

          state.denialsByPayerGraphArr = resultArr?.finalArr;
          state.denialsByPayerKeyValuesArr = resultArr?.keyValues;
          state.denialsByPayerCustomLegendArr = resultArr?.extraLegend;
        } else {
          state.denialsByPayerGraphArr = [];
          state.denialsByPayerKeyValuesArr = [];
          state.denialsByPayerCustomLegendArr = [];
        }
        state.filterLoader.loadingPayers = false;
      } catch (err) {
        // console.log("setPayerCode_Data__err--->>", err);
      }
    },

    setPayerCodeDenialDistribution_Data: (state, action) => {
      let data = action.payload;

      try {
        // denialsByPayer handle here
        if (data?.denialsByPayer?.length) {
          const resultArr = transformData(data?.denialsByPayer, "payerName", 0);

          state.denialsDistributionPayerGraphArr = resultArr?.finalArr;
          state.denialsDistributionKeyValuesArr = resultArr?.keyValues;
          state.denialsDistributionCustomLegendArr = resultArr?.extraLegend;
        } else {
          state.denialsDistributionPayerGraphArr = [];
          state.denialsDistributionKeyValuesArr = [];
          state.denialsDistributionCustomLegendArr = [];
        }
        state.denialDistribtionfilterLoader.loadingPayers = false;
      } catch (err) {}
    },

    //Service Line Filters
    gettingDenialPerByPayerLoader: (state, action) => {
      state.loadingDenialsPerByPayer = true;
      state.loadingDenialsPerByPayer2 = true;
    },
    setDenialPerByPayer_Data: (state, action) => {
      let data = action.payload;

      try {
        // denialsByPayer handle here
        if (data?.denialsByPayer?.length) {
          const resultArr = transformData(data?.denialsByPayer, "payerName", 0);

          state.denialsPerByPayerGraphArr = resultArr?.finalArr;
          state.denialsPerByPayerKeyValuesArr = resultArr?.keyValues;
          state.denialsPerByPayerCustomLegendArr = resultArr?.extraLegend;
        } else {
          state.denialsPerByPayerGraphArr = [];
          state.denialsPerByPayerKeyValuesArr = [];
          state.denialsPerByPayerCustomLegendArr = [];
        }
        state.loadingDenialsPerByPayer = false;
      } catch (err) {}
    },

    setPayerDenialRateDenialDistribution_Data: (state, action) => {
      let data = action.payload;

      try {
        // denialsByPayer handle here
        if (data?.denialsRateByPayer?.length) {
          const resultArr = transformDataForEqualWeightDenial(
            data?.denialsRateByPayer,
            "payerName",
            0
          );
          // console.log("123123 --second chk ->>", resultArr);

          state.denialsRateDistributionPayerGraphArr = resultArr?.finalArr;
          state.denialsRateDistributionKeyValuesArr = resultArr?.keyValues;
          state.denialsRateDistributionCustomLegendArr = resultArr?.extraLegend;
        } else {
          state.denialsRateDistributionPayerGraphArr = [];
          state.denialsRateDistributionKeyValuesArr = [];
          state.denialsRateDistributionCustomLegendArr = [];
        }
        state.loadingDenialsPerByPayer2 = false;
      } catch (err) {
        // console.log("err--->>", err);
      }
    },

    gettingFacilityTypeFilter: (state, action) => {
      state.filterLoader.loadingFacilityType = true;
    },
    setFacilityTypeCode_Data: (state, action) => {
      let data1 = action.payload;
      try {
        // denialsByFacilityType handle here
        if (data1?.denialsByFacilityType?.length) {
          const resultArr = transformData(
            data1?.denialsByFacilityType,
            "facilityTypeCode",
            0
          );

          state.denialsByFacilityTypeGraphArr = resultArr?.finalArr;
          state.denialsByFacilityTypeKeyValuesArr = resultArr?.keyValues;
          state.denialsByFacilityTypeCustomLegendArr = resultArr?.extraLegend;
        } else {
          state.denialsByFacilityTypeGraphArr = [];
          state.denialsByFacilityTypeKeyValuesArr = [];
          state.denialsByFacilityTypeCustomLegendArr = [];
        }
      } catch (e) {
        // console.log("err--->>", err);
      } finally {
        state.filterLoader.loadingFacilityType = false;
      }
    },

    gettingFacilityCodeFilter: (state, action) => {
      state.filterLoader.loadingFacility = true;
    },
    setFacilityCode_Data: (state, action) => {
      let data1 = action.payload;
      try {
        // console.log("setFacilityCode_Data_data1--->>", data1);

        // denialsByFacility handle here
        if (data1?.denialsByFacility?.length) {
          const resultArr = transformData(
            data1?.denialsByFacility,
            "facilityName",
            0
          );

          // console.log("resultArr_denialsByFacility--->>", resultArr);

          state.denialsByFacilityGraphArr = resultArr?.finalArr;
          state.denialsByFacilityKeyValuesArr = resultArr?.keyValues;
          state.denialsByFacilityCustomLegendArr = resultArr?.extraLegend;
        } else {
          state.denialsByFacilityGraphArr = [];
          state.denialsByFacilityKeyValuesArr = [];
          state.denialsByFacilityCustomLegendArr = [];
        }
      } catch (err) {
        // console.log("err--->>", err);
      } finally {
        state.filterLoader.loadingFacility = false;
      }
    },
  },
});

export const {
  setCPTCodes_Data,
  setProviderCode_Data,
  setPayerCode_Data,
  setFacilityTypeCode_Data,
  setFacilityCode_Data,
  gettingPayerFilter,
  gettingProviderFilter,
  gettingFacilityTypeFilter,
  gettingFacilityCodeFilter,
  successServiceLineFilter,
  gettingServiceLineFilter,
  setPayerCodeDenialDistribution_Data,
  setDenialPerByPayer_Data,
  gettingDenialPerByPayerLoader,
  setPayerDenialRateDenialDistribution_Data,
  gettingPayerDenialDistributionFilter,
} = ProcedureCodesDashboard.actions;

// procedure Code dashboard api
export const gettingProcedureCodeDash_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "procedureCodeDashFilter",
          ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceState = getState().npReducers.filters.procedureCodeDashFilter;

    let Start = sliceState.pageNumber;
    let Limit = sliceState.pageSize;

    if (Start >= 2) {
      let rec_val = (Start - 1) * Limit;
      Start = rec_val;
    }
    if (Start == 1) {
      Start = 0;
    }

    try {
      ///// API CALL //////
      let paramsTemp = {
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),

        // new filters
        ...(sliceStateForDate.dateMainState !== "" && {
          filterby:
            sliceStateForDate.dateMainState == "Months" ? "month" : "Year",
        }),

        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
        ...(sliceState?.proceureCodes?.length > 0 && {
          procCode: getMultiSelectFilterValueForApi(sliceState?.proceureCodes),
        }),
      };

      let params = {
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),

        // new filters
        ...(sliceStateForDate.dateMainState !== "" && {
          filterby:
            sliceStateForDate.dateMainState == "Months" ? "month" : "Year",
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
        ...(sliceState?.proceureCodes?.length > 0 && {
          procCode: getMultiSelectFilterValueForApi(sliceState?.proceureCodes),
        }),
      };

      // handle serviceLines filter here
      if (sliceState?.treeNodeServiceLine?.length > 0) {
        const treeSelected = getTreeNodeObjFullPath(
          sliceState.treeNodeServiceLine
        );
        if (treeSelected) {
          params = { ...params, ...treeSelected };
          paramsTemp = { ...paramsTemp, ...treeSelected };
        }
      }

      //  ProcedureCode  filter API
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setCPTCodes_Data,
        "procedureCodeDashFilter",
        "GET_DENIAL_BY_PROCEDURE_CODE_DASHBOARD_CPT_API",
        null,
        "",
        ""
      );

      // ServiceLine filter API
      dispatch(gettingServiceLineFilter());
      commonPagination(
        getState,
        "",
        "",
        dispatch,
        successServiceLineFilter,
        "procedureCodeDashFilter",
        "CLAIM_REQUEST_SERVICELINE_FILTER",
        null,
        "",
        ""
      );

      // API for payerCode graph

      dispatch(gettingPayerFilter());
      dispatch(gettingProviderFilter());
      dispatch(gettingFacilityCodeFilter());
      dispatch(gettingFacilityTypeFilter());

      await commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setPayerCode_Data,
        "procedureCodeDashFilter",
        "GET_DENIAL_BY_PROCEDURE_CODE_DASHBOARD_PAYER_API",
        null,
        "",
        ""
      );

      // API for providerCode graph
      // dispatch(gettingProviderFilter());
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setProviderCode_Data,
        "procedureCodeDashFilterProvider",
        "GET_DENIAL_BY_PROCEDURE_CODE_DASHBOARD_PROVIDER_API",
        null,
        "",
        ""
      );

      // API for facilityCode graph
      // dispatch(gettingFacilityCodeFilter());
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setFacilityCode_Data,
        "procedureCodeDashFilterFacilityCode",
        "GET_DENIAL_BY_PROCEDURE_CODE_DASHBOARD_FACILITY_CODE_API",
        null,
        "",
        ""
      );

      // API for facilityTypeCode graph
      // dispatch(gettingFacilityTypeFilter());
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setFacilityTypeCode_Data,
        "procedureCodeDashFilterFacilityType",
        "GET_DENIAL_BY_PROCEDURE_CODE_DASHBOARD_FACILITY_TYPE_API",
        null,
        "",
        ""
      );

      ////// API CALL END //////////////

      return 0;
    } catch (e) {
      console.log("e--->>", e);
    }
  };

//  Denials Distribution by Payer dashboard api
export const gettingDenialDistributionDash_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialDistributionDashFilter",
          ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceState = getState().npReducers.filters.denialDistributionDashFilter;
    let sliceStateUser = getState().pReducers.user;
    let Start = sliceState.pageNumber;
    let Limit = sliceState.pageSize;

    if (Start >= 2) {
      let rec_val = (Start - 1) * Limit;
      Start = rec_val;
    }
    if (Start == 1) {
      Start = 0;
    }

    try {
      ///// API CALL //////
      let paramsTemp = {
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),

        // new filters
        ...(sliceStateForDate.dateMainState !== "" && {
          filterby:
            sliceStateForDate.dateMainState == "Months" ? "month" : "Year",
        }),

        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
        ...(sliceState?.proceureCodes?.length > 0 && {
          procCode: getMultiSelectFilterValueForApi(sliceState?.proceureCodes),
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: getCurrentClientId(),
        }),
      };

      let params = {
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: getCurrentClientId(),
        }),

        // new filters
        ...(sliceStateForDate.dateMainState !== "" && {
          filterby:
            sliceStateForDate.dateMainState == "Months" ? "month" : "Year",
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
        ...(sliceState?.proceureCodes?.length > 0 && {
          procCode: getMultiSelectFilterValueForApi(sliceState?.proceureCodes),
        }),
      };

      let sliceState12 =
        getState().npReducers.filters["denialDistributionDashFilter"];
      let cacheData = { ...sliceState12.cachePagination };
      let cachParamsState = { ...sliceState12.cacheParams };
      let cache = cacheData[`page_${sliceState12.pageNumber}`];

      if (
        cache &&
        // cache.claims.length > 0 &&
        JSON.stringify(cachParamsState) === JSON.stringify(paramsTemp)
      ) {
        // do nothing
      } else {
        // API for payerCode graph
        dispatch(gettingPayerDenialDistributionFilter());
      }

      await commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setPayerCodeDenialDistribution_Data,
        "denialDistributionDashFilter",
        "GET_DENIAL_BY_PROCEDURE_CODE_DASHBOARD_PAYER_API",
        null,
        "",
        ""
      );

      ////// API CALL END //////////////

      return 0;
    } catch (e) {
      console.log("e--->>", e);
    }
  };

//  Denials Distribution by Payer dashboard api
export const gettingDenialPerByPayer_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialPerByPayerFilter",
          ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceState = getState().npReducers.filters.denialPerByPayerFilter;
    let sliceStateUser = getState().pReducers.user;

    let Start = sliceState.pageNumber;
    let Limit = sliceState.pageSize;

    if (Start >= 2) {
      let rec_val = (Start - 1) * Limit;
      Start = rec_val;
    }
    if (Start == 1) {
      Start = 0;
    }

    try {
      ///// API CALL //////
      let paramsTemp = {
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),

        // new filters
        ...(sliceStateForDate.dateMainState !== "" && {
          filterby:
            sliceStateForDate.dateMainState == "Months" ? "month" : "Year",
        }),

        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
        ...(sliceState?.proceureCodes?.length > 0 && {
          procCode: getMultiSelectFilterValueForApi(sliceState?.proceureCodes),
        }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : sliceStateForDate?.clientId || getCurrentClientId(),
        }),
      };

      let params = {
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),

        // new filters
        ...(sliceStateForDate.dateMainState !== "" && {
          filterby:
            sliceStateForDate.dateMainState == "Months" ? "month" : "Year",
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
        ...(sliceState?.proceureCodes?.length > 0 && {
          procCode: getMultiSelectFilterValueForApi(sliceState?.proceureCodes),
        }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : sliceStateForDate?.clientId || getCurrentClientId(),
        }),
      };

      let sliceState12 =
        getState().npReducers.filters["denialPerByPayerFilter"];
      let cacheData = { ...sliceState12.cachePagination };
      let cachParamsState = { ...sliceState12.cacheParams };
      let cache = cacheData[`page_${sliceState12.pageNumber}`];

      if (
        cache &&
        // cache.claims.length > 0 &&
        JSON.stringify(cachParamsState) === JSON.stringify(paramsTemp)
      ) {
        // do nothing
      } else {
        // API for payerCode graph
        dispatch(gettingPayerDenialDistributionFilter());
        dispatch(gettingDenialPerByPayerLoader());
      }

      await commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setDenialPerByPayer_Data,
        "denialPerByPayerFilter",
        "GET_DENIAL_BY_PROCEDURE_CODE_DASHBOARD_PAYER_API",
        null,
        "",
        ""
      );

      await commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setPayerDenialRateDenialDistribution_Data,
        "denialDistributionDashFilter",
        "GET_STACK_CHART_PAYER_DENIAL_PERCENTAGE",
        null,
        "",
        ""
      );

      ////// API CALL END //////////////

      return 0;
    } catch (e) {
      console.log("e--->>", e);
    }
  };

// //  Denials Distribution by Payer dashboard api
// export const gettingDenialRateDenialDistributionDash_Call =
//   ({ clear = false } = {}) =>
//   async (dispatch, getState) => {
//     if (clear) {
//       dispatch(
//         resetListingState({
//           listing: "denialDistributionDashFilter",
//           ignore: ["claimStatus", "payers", "frequencyType", "search"],
//         })
//       );
//     }

//     let sliceStateForDate =
//       getState().npReducers.filters.insightsSreensCommonFilter;

//     let sliceState = getState().npReducers.filters.denialDistributionDashFilter;

//     let Start = sliceState.pageNumber;
//     let Limit = sliceState.pageSize;

//     if (Start >= 2) {
//       let rec_val = (Start - 1) * Limit;
//       Start = rec_val;
//     }
//     if (Start == 1) {
//       Start = 0;
//     }

//     try {
//       ///// API CALL //////
//       let paramsTemp = {
//         ...(sliceState.order !== "" && {
//           Order: sliceState.order,
//         }),
//         ...(sliceState.order !== "" && {
//           OrderBy: sliceState.orderBy,
//         }),
//         ...(sliceStateForDate.from !== "" && {
//           from: sliceStateForDate.from,
//         }),
//         ...(sliceStateForDate.to !== "" && {
//           to: sliceStateForDate.to,
//         }),

//         // new filters
//         ...(sliceStateForDate.dateMainState !== "" && {
//           filterby:
//             sliceStateForDate.dateMainState == "Months" ? "month" : "Year",
//         }),

//         ...(sliceState?.smartFilter &&
//           sliceState?.smartFilterArr?.length > 0 && {
//             SmartFilter: getMultiSelectFilterValueForApi(
//               sliceState?.smartFilterArr
//             ),
//           }),
//         ...(sliceState?.proceureCodes?.length > 0 && {
//           procCode: getMultiSelectFilterValueForApi(sliceState?.proceureCodes),
//         }),
//       };

//       let params = {
//         ...(sliceState.order !== "" && {
//           Order: sliceState.order,
//         }),
//         ...(sliceState.order !== "" && {
//           OrderBy: sliceState.orderBy,
//         }),
//         ...(sliceStateForDate.from !== "" && {
//           from: sliceStateForDate.from,
//         }),
//         ...(sliceStateForDate.to !== "" && {
//           to: sliceStateForDate.to,
//         }),

//         // new filters
//         ...(sliceStateForDate.dateMainState !== "" && {
//           filterby:
//             sliceStateForDate.dateMainState == "Months" ? "month" : "Year",
//         }),
//         ...(sliceState?.smartFilter &&
//           sliceState?.smartFilterArr?.length > 0 && {
//             SmartFilter: getMultiSelectFilterValueForApi(
//               sliceState?.smartFilterArr
//             ),
//           }),
//         ...(sliceState?.proceureCodes?.length > 0 && {
//           procCode: getMultiSelectFilterValueForApi(sliceState?.proceureCodes),
//         }),
//         Start,
//         Limit,
//       };

//       let sliceState12 =
//         getState().npReducers.filters["denialDistributionDashFilter"];
//       let cacheData = { ...sliceState12.cachePagination };
//       let cachParamsState = { ...sliceState12.cacheParams };
//       let cache = cacheData[`page_${sliceState12.pageNumber}`];

//       if (
//         cache &&
//         // cache.claims.length > 0 &&
//         JSON.stringify(cachParamsState) === JSON.stringify(paramsTemp)
//       ) {
//         // do nothing
//       } else {
//         // API for payerCode graph
//         dispatch(gettingPayerDenialDistributionFilter());
//       }

//       await commonPagination(
//         getState,
//         paramsTemp,
//         params,
//         dispatch,
//         setPayerDenialRateDenialDistribution_Data,
//         "denialDistributionDashFilter",
//         "GET_STACK_CHART_PAYER_DENIAL_PERCENTAGE",
//         null,
//         "",
//         ""
//       );

//       ////// API CALL END //////////////

//       return 0;
//     } catch (e) {
//       // console.log("e--->>", e);
//     }
//   };

export default ProcedureCodesDashboard.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
// import DataSource from "../../../actions/dataSource";
import { serverRoutes } from "./../../../constants/serverRoutes";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";

import {
  setListingState,
  resetListingState,
} from "../filters/claimManagerFilters";

export const DataSourceSlice = createSlice({
  name: "DataSourceSlice",
  initialState: {
    dataSourceUpdateObj: {},
    dataSource_arr: [],
    frequency_arr: [],
    sourceType_arr: [],
    errorLogs_arr: [],
    activityLogs_arr: [],
  },
  reducers: {
    setDataSource_Data: (state, action) => {
      let data = action.payload;
      state.dataSource_arr = data;
    },

    setDataSourceUpdate_Data: (state, action) => {
      let data = action.payload;
      state.dataSourceUpdateObj = data;
    },

    updateDataSourceForRunJob_Data: (state, action) => {
      let id = action.payload;
      let ftpIndex = state.dataSource_arr.findIndex(
        (source) => source.ftpSourceId === id
      );
      if (ftpIndex !== -1) {
        let newSourceList = [...state.dataSource_arr];
        newSourceList[ftpIndex].lastRunOn = "1753-01-01T00:00:00";
        newSourceList[ftpIndex].isCompleted = false;
        state.dataSource_arr = newSourceList;
      }
    },

    setFrequency_Data: (state, action) => {
      let data = action.payload;
      state.frequency_arr = data;
    },

    setSourceType_Data: (state, action) => {
      let data = action.payload;
      state.sourceType_arr = data;
    },

    removeSourceById: (state, action) => {
      state.dataSource_arr = state?.dataSource_arr.filter(
        (item) => item.ftpSourceId !== action.payload
      );
    },

    setErrorLogs_Data: (state, action) => {
      let data = action.payload;
      state.errorLogs_arr = data;
    },

    setActivityLogs_Data: (state, action) => {
      let data = action.payload;
      state.activityLogs_arr = data;
    },
  },
});

export const {
  setDataSource_Data,
  removeSourceById,
  setSourceType_Data,
  setFrequency_Data,
  updateDataSourceForRunJob_Data,
  setErrorLogs_Data,
  setActivityLogs_Data,
  setDataSourceUpdate_Data,
} = DataSourceSlice.actions;

// get dataSources list
export const gettingDataSource_Call = (clear) => async (dispatch, getState) => {
  try {
    dispatch(
      setListingState({
        listing: "dataSourceFilter",
        value: {
          loadingMore: clear ? "" : "data-loading",
        },
      })
    );

    ///// API CALL //////

    const timestamp = new Date().getTime(); // Get the current timestamp

    const res = await genericAxiosCall(
      `${serverRoutes?.GET_ALL_DATA_SOURCE_LIST}?timestamp=${timestamp}`,
      "get"
    );

    ////// API CALL END //////////////

    if (!res?.data?.success) {
      // toast.error(`Something went wrong`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
      dispatch(setDataSource_Data([]));
      dispatch(
        setListingState({
          listing: "dataSourceFilter",
          value: {
            // hasMore: res?.data?.data.output.length === sliceState.pageSize,
            loadingMore: "",
          },
        })
      );
    } else {
      dispatch(
        setListingState({
          listing: "dataSourceFilter",
          value: {
            // hasMore: res?.data?.data?.output?.length === sliceState.pageSize,
            loadingMore: "",
          },
        })
      );
      dispatch(setDataSource_Data(res?.data?.data));
    }

    return res?.data;
  } catch (e) {
    // console.log("e--->>", e);

    dispatch(
      setListingState({
        listing: "dataSourceFilter",
        value: {
          initialLoading: false,
          loadingMore: "",
          hasMore: false,
        },
      })
    );
    return false;
  }
};

// get dataSources list
export const gettingDataSourceUpdateItme_Call =
  (sourceId) => async (dispatch, getState) => {
    try {
      dispatch(
        setListingState({
          listing: "dataSourceFilter",
          value: {
            updateLoading: "data-loading",
          },
        })
      );

      ///// API CALL //////

      const timestamp = new Date().getTime(); // Get the current timestamp
      let params = {
        ftpSourceId: sourceId,
        // timestamp: timestamp,
      };
      const res = await genericAxiosCall(
        `${serverRoutes?.GET_DATA_SOURCE_UPDATE_ITEM}`,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      if (!res?.data?.success) {
        // toast.error(`Something went wrong`, {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        dispatch(setDataSourceUpdate_Data([]));
        dispatch(
          setListingState({
            listing: "dataSourceFilter",
            value: {
              // hasMore: res?.data?.data.output.length === sliceState.pageSize,
              updateLoading: "",
            },
          })
        );
      } else {
        dispatch(
          setListingState({
            listing: "dataSourceFilter",
            value: {
              // hasMore: res?.data?.data?.output?.length === sliceState.pageSize,
              updateLoading: "",
            },
          })
        );
        dispatch(setDataSourceUpdate_Data(res?.data?.data));
      }

      return res?.data;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "dataSourceFilter",
          value: {
            initialLoading: false,
            updateLoading: "",
            hasMore: false,
          },
        })
      );
      return false;
    }
  };

// get DataSource Frequecy list
export const gettingFrequecy_Call = () => async (dispatch, getState) => {
  try {
    dispatch(
      setListingState({
        listing: "dataSourceFilter",
        value: {
          loadingMore: "data-loading",
        },
      })
    );

    ///// API CALL //////

    // const res = await DataSource.getSyncFrequencies();

    const res = await genericAxiosCall(
      serverRoutes?.GET_FREQUENCY_TYPES_LIST,
      "get"
    );

    ////// API CALL END //////////////

    if (!res?.data?.success) {
      // toast.error(`Something went wrong`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
      dispatch(setFrequency_Data([]));
      dispatch(
        setListingState({
          listing: "dataSourceFilter",
          value: {
            // hasMore: res?.data?.data.output.length === sliceState.pageSize,
            loadingMore: "",
          },
        })
      );
    } else {
      dispatch(
        setListingState({
          listing: "dataSourceFilter",
          value: {
            // hasMore: res?.data?.data?.output?.length === sliceState.pageSize,
            loadingMore: "",
          },
        })
      );
      dispatch(setFrequency_Data(res?.data?.data));
    }

    return res?.data;
  } catch (e) {
    // console.log("e--->>", e);

    dispatch(
      setListingState({
        listing: "dataSourceFilter",
        value: {
          initialLoading: false,
          loadingMore: "",
          hasMore: false,
        },
      })
    );
    return false;
  }
};

// get DataSource sourceType list
export const gettingSourceType_Call = () => async (dispatch, getState) => {
  try {
    dispatch(
      setListingState({
        listing: "dataSourceFilter",
        value: {
          loadingMore: "data-loading",
        },
      })
    );

    ///// API CALL //////

    const res = await genericAxiosCall(
      serverRoutes?.GET_SOURCE_TYPES_LIST,
      "get"
    );

    ////// API CALL END //////////////

    if (!res?.data?.success) {
      dispatch(setSourceType_Data([]));
      dispatch(
        setListingState({
          listing: "dataSourceFilter",
          value: {
            // hasMore: res?.data?.data.output.length === sliceState.pageSize,
            loadingMore: "",
          },
        })
      );
    } else {
      dispatch(
        setListingState({
          listing: "dataSourceFilter",
          value: {
            // hasMore: res?.data?.data?.output?.length === sliceState.pageSize,
            loadingMore: "",
          },
        })
      );
      dispatch(setSourceType_Data(res?.data?.data));
    }

    return res?.data;
  } catch (e) {
    // console.log("e--->>", e);

    dispatch(
      setListingState({
        listing: "dataSourceFilter",
        value: {
          initialLoading: false,
          loadingMore: "",
          hasMore: false,
        },
      })
    );
    return false;
  }
};

// delete data Source
export const deleteSource_Call = (sourceId) => async (dispatch) => {
  try {
    // const res = await DataSource.deleteDataSource(sourceId);

    const res = await genericAxiosCall(
      `${serverRoutes?.DELETE_FTP_SOURCE}/${sourceId}`,
      "delete"
    );

    if (res?.data?.success) {
      dispatch(removeSourceById(sourceId));
      return res;
    } else {
      return res;
    }
  } catch (e) {
    // console.log("catch_e--->>", e);
  }
};

// delete data Source
export const runDataSourceJob_Call = (sourceId) => async (dispatch) => {
  try {
    // const res = await DataSource.resetFTPSourceFrequncey(sourceId);

    const res = await genericAxiosCall(
      `${serverRoutes?.RUN_DATA_SOURCE_JOB}/${sourceId}`,
      "get"
    );

    if (res?.data?.success) {
      dispatch(updateDataSourceForRunJob_Data(sourceId));
      return res;
    } else {
      return res;
    }
  } catch (e) {
    // console.log("catch_e--->>", e);
  }
};

// create data Source
export const createNewDataSource_Call = (body) => async (dispatch) => {
  let res1;
  try {
    // const res = await DataSource.createDataSource(body);

    const res = await genericAxiosCall(
      serverRoutes?.CREATE_NEW_DATA_SOURCE,
      "post",
      body
    );

    return (res1 = res);
  } catch (e) {
    // console.log("catch_e--->>", e);
    res1 = e.response;
  } finally {
    return res1;
  }
};

// update data Source
export const updateDataSource_Call = (body) => async (dispatch) => {
  let res1;
  try {
    // const res = await DataSource.updateDataSource(body);

    const res = await genericAxiosCall(
      serverRoutes?.UPDATE_DATA_SOURCE,
      "post",
      body
    );
    return (res1 = res);
  } catch (e) {
    // console.log("catch_e--->>", e);
    res1 = e.response;
  } finally {
    return res1;
  }
};

// test API connection for saving into data Source
export const testAPI_Connection_Call = (body) => async () => {
  let res1;
  try {
    // const res = await DataSource.testAPIConnect(body);

    const res = await genericAxiosCall(
      serverRoutes?.TEST_API_CONNECTION,
      "post",
      body
    );

    res1 = res;
  } catch (e) {
    // console.log("catch_e--->>", e);
    res1 = e.response;
  } finally {
    return res1;
  }
};

// test FTP/FTPS/SFTP connection for saving into data Source
export const testFTP_SFTP_Connection_Call = (body) => async () => {
  let res1;
  try {
    // const res = await DataSource.testConnection(body);

    const res = await genericAxiosCall(
      serverRoutes?.TEST_FTP_SFTP_CONNECTION,
      "post",
      body
    );
    res1 = res;
  } catch (e) {
    // console.log("catch_e--->>", e);
    res1 = e.response;
  } finally {
    return res1;
  }
};

// getting ErrorLogs list
export const gettingErrorLogs_Call = () => async (dispatch, getState) => {
  let sliceState = getState().npReducers.filters.dataSource_ErrorLogsFilter;
  let sliceState_id = getState().npReducers.filters.dataSourceFilter;

  let id = sliceState_id.selectedItem_id;
  let start = sliceState.pageNumber;
  let limit = sliceState.pageSize;
  let search = sliceState.search;

  if (start >= 2) {
    let rec_val = (start - 1) * limit;
    start = rec_val;
  }
  if (start == 1) {
    start = 0;
  }

  try {
    dispatch(
      setListingState({
        listing: "dataSource_ErrorLogsFilter",
        value: {
          loadingMore: "data-loading",
        },
      })
    );

    ///// API CALL //////

    // const res = await DataSource.getDataSourcesErrors(
    //   id,
    //   start,
    //   limit,
    //   search === "" ? "%20" : search
    // );

    const res = await genericAxiosCall(
      `${serverRoutes?.GET_DATA_SOURCE_ERRORS}${id}/${start}/${limit}/${
        search === "" ? "%20" : search
      }/`,
      "get"
    );

    ////// API CALL END //////////////

    if (!res?.data?.success) {
      dispatch(setErrorLogs_Data([]));
      dispatch(
        setListingState({
          listing: "dataSource_ErrorLogsFilter",
          value: {
            // hasMore: res?.data?.data.output.length === sliceState.pageSize,
            loadingMore: "",
          },
        })
      );
    } else {
      dispatch(
        setListingState({
          listing: "dataSource_ErrorLogsFilter",
          value: {
            hasMore: res?.data?.data?.output?.length === sliceState.pageSize,
            loadingMore: "",
          },
        })
      );
      dispatch(setErrorLogs_Data(res?.data?.data));
    }

    return res?.data;
  } catch (e) {
    // console.log("e--->>", e);

    dispatch(
      setListingState({
        listing: "dataSource_ErrorLogsFilter",
        value: {
          initialLoading: false,
          loadingMore: "",
          hasMore: false,
        },
      })
    );
    return false;
  }
};

// getting ActivityLogs list
export const gettingActivityLogs_Call = () => async (dispatch, getState) => {
  let sliceState = getState().npReducers.filters.dataSource_ActivityLogsFilter;
  let sliceState_id = getState().npReducers.filters.dataSourceFilter;

  let id = sliceState_id.selectedItem_id;
  let start = sliceState.pageNumber;
  let limit = sliceState.pageSize;
  let search = sliceState.search;

  if (start >= 2) {
    let rec_val = (start - 1) * limit;
    start = rec_val;
  }
  if (start == 1) {
    start = 0;
  }

  try {
    dispatch(
      setListingState({
        listing: "dataSource_ActivityLogsFilter",
        value: {
          loadingMore: "data-loading",
        },
      })
    );

    ///// API CALL //////

    // const res = await DataSource.getSourcePositions(
    //   id,
    //   start,
    //   limit,
    //   search === "" ? "%20" : search
    // );

    const res = await genericAxiosCall(
      `${serverRoutes?.GET_DATA_ACTIVITY_ERRORS}${id}/${start}/${limit}/${
        search === "" ? "%20" : search
      }/`,
      "get"
    );

    ////// API CALL END //////////////

    if (!res?.data?.success) {
      dispatch(setActivityLogs_Data([]));
      dispatch(
        setListingState({
          listing: "dataSource_ActivityLogsFilter",
          value: {
            loadingMore: "",
          },
        })
      );
    } else {
      dispatch(
        setListingState({
          listing: "dataSource_ActivityLogsFilter",
          value: {
            hasMore: res?.data?.data?.output?.length === sliceState.pageSize,
            loadingMore: "",
          },
        })
      );
      dispatch(setActivityLogs_Data(res?.data?.data));
    }

    return res?.data;
  } catch (e) {
    // console.log("e--->>", e);

    dispatch(
      setListingState({
        listing: "dataSource_ActivityLogsFilter",
        value: {
          initialLoading: false,
          loadingMore: "",
          hasMore: false,
        },
      })
    );
    return false;
  }
};

export default DataSourceSlice.reducer;

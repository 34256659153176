import { createSlice } from "@reduxjs/toolkit";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";
import {
  commonPagination,
  commonPaginationForPost,
  getCurrentClientId,
  getMultiSelectFilterValueForApi,
} from "../../../components/common/Utils/utils";
import { serverRoutes } from "../../../constants/serverRoutes";
import {
  resetListingState,
  setListingState,
} from "../filters/claimManagerFilters";
import store from "../../store";
import { gettingAdvSettingData_Call } from "../Settings/advanceSettingSlice";

//////////////////////////////////////////////////////////////
//                      Reducers
//////////////////////////////////////////////////////////////

const slice = createSlice({
  name: "landing",
  initialState: {
    loading: false,
    list: null,
    error: null,

    landingPageNew: {},
    reSubmittedAdjustment: {},
    reSubmittedPayment: {},
    appealHome: {
      reSubmittedAdjustment: {},
      reSubmittedPayment: {},
      softPie: [],
      hardPie: [],
      totalPie: [],
    },
    kpisDashboard: [],
    comboChartLoader: true,
    newHome: {
      softPie: [],
      hardPie: [],
      totalPie: [],
      availableHospitalsList: [],
    },

    bubbleChartPayers: [],
    bubbleChartPayersLoader: true,
    bubbleChartDepartments: [],
    bubbleChartDepartmentsLoader: true,
    topCardListLoader: true,
  },
  reducers: {
    gettingNewsRequested: (state, action) => {
      state.loading = true;
    },
    gettingLandingPageData: (state, action) => {
      state.list = action.payload;
      state.loading = false;
    },
    gettingExecutiveLandingPageData: (state, action) => {
      try {
        const data = action?.payload?.payeeFilter;

        if (data?.length > 0) {
          let comboChartArr = [];
          data?.map((item, index) => {
            comboChartArr.push({
              name: `${
                ["m", "w"].includes(item?.filterType?.toLowerCase())
                  ? `Week ${index + 1}`
                  : item?.month
              }`,
              "Total Charge Amount": item?.totalAmount,
              "Payment Amount": item?.paymentAmount,
              "Denial Amount": item?.totalAdjustment,
            });
          });
          state.kpisDashboard = comboChartArr;
        } else {
          state.kpisDashboard = [];
        }
        state.comboChartLoader = false;
        // state.list = action.payload;
        // state.loading = false;
      } catch (err) {
        console.log("err--->>", err);
      }
    },
    gettingNewsFailed: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
    },
    //New Home
    successLandingPageNewHome: (state, action) => {
      let { homeDashoard, reSubmittedAdjuestment, reSubmittedPayment } =
        action?.payload?.dashboardData;
      const hospitalList = action?.payload?.hospitalList;
      if (homeDashoard && homeDashoard?.length > 0) {
        homeDashoard = homeDashoard[0];
        state.landingPageNew = homeDashoard;
      } else {
        state.landingPageNew = {};
      }
      if (reSubmittedAdjuestment && reSubmittedAdjuestment?.length > 0) {
        reSubmittedAdjuestment = reSubmittedAdjuestment[0];
        state.reSubmittedAdjustment = reSubmittedAdjuestment;
      } else {
        state.reSubmittedAdjustment = {};
      }
      if (reSubmittedPayment && reSubmittedPayment?.length > 0) {
        reSubmittedPayment = reSubmittedPayment[0];
        state.reSubmittedPayment = reSubmittedPayment;
      } else {
        state.reSubmittedPayment = {};
      }
      //Pie charts
      if (reSubmittedAdjuestment && reSubmittedPayment) {
        let softPie = [
          {
            id: "Resubmitted",
            label: "Resubmitted",
            value:
              (reSubmittedAdjuestment?.softAmount /
                reSubmittedAdjuestment?.totalAmount) *
              100,
            //reSubmittedAdjuestment?.softPercentage,
            color: "hsl(346, 70%, 50%)",
          },
          {
            id: "Payment",
            label: "Payment",
            value: reSubmittedPayment?.softPercentage,
            color: "hsl(346, 70%, 50%)",
          },
        ];
        let hardPie = [
          {
            id: "Resubmitted",
            label: "Resubmitted",
            value:
              (reSubmittedAdjuestment?.hardAmount /
                reSubmittedAdjuestment?.totalAmount) *
              100,
            color: "hsl(346, 70%, 50%)",
          },
          {
            id: "Payment",
            label: "Payment",
            value: reSubmittedPayment?.hardPercentage,
            color: "hsl(346, 70%, 50%)",
          },
        ];
        let totalPie = [
          {
            id: "Resubmitted",
            label: "Resubmitted",
            value: reSubmittedAdjuestment?.totalAmount,
            color: "hsl(346, 70%, 50%)",
          },
          {
            id: "Payment",
            label: "Payment",
            value: reSubmittedPayment?.totalAmount,
            color: "hsl(346, 70%, 50%)",
          },
        ];

        state.newHome.softPie = softPie;
        state.newHome.hardPie = hardPie;
        state.newHome.totalPie = totalPie;
      } else {
        state.newHome.softPie = [];
        state.newHome.hardPie = [];
        state.newHome.totalPie = [];
      }
      //Hospital list
      if (hospitalList && hospitalList?.length > 0) {
        let newArr = [];
        hospitalList?.forEach((item) => {
          if (item?.displayName && item?.payeeId) {
            newArr.push({
              label: item?.displayName,
              value: item?.hospital,
            });
          }
        });
        state.newHome.availableHospitalsList = newArr;
      } else {
        state.newHome.availableHospitalsList = [];
      }
    },
    //Appeal Home
    successLandingPageAppealHome: (state, action) => {
      let { reSubmittedAdjuestment, reSubmittedPayment } = action?.payload;

      if (reSubmittedAdjuestment && reSubmittedAdjuestment?.length > 0) {
        reSubmittedAdjuestment = reSubmittedAdjuestment[0];
        state.appealHome.reSubmittedAdjustment = reSubmittedAdjuestment;
      } else {
        state.appealHome.reSubmittedAdjustment = {};
      }
      if (reSubmittedPayment && reSubmittedPayment?.length > 0) {
        reSubmittedPayment = reSubmittedPayment[0];
        state.appealHome.reSubmittedPayment = reSubmittedPayment;
      } else {
        state.appealHome.reSubmittedPayment = {};
      }
      //Soft and Hard Pie

      if (reSubmittedAdjuestment && reSubmittedPayment) {
        let softPie = [
          {
            id: "Resubmitted",
            label: "Resubmitted",
            value:
              (reSubmittedAdjuestment?.softAmount /
                reSubmittedAdjuestment?.totalAmount) *
              100,
            //reSubmittedAdjuestment?.softPercentage,
            color: "hsl(346, 70%, 50%)",
          },
          {
            id: "Payment",
            label: "Payment",
            value: reSubmittedPayment?.softPercentage,
            color: "hsl(346, 70%, 50%)",
          },
        ];
        let hardPie = [
          {
            id: "Resubmitted",
            label: "Resubmitted",
            value:
              (reSubmittedAdjuestment?.hardAmount /
                reSubmittedAdjuestment?.totalAmount) *
              100,
            color: "hsl(346, 70%, 50%)",
          },
          {
            id: "Payment",
            label: "Payment",
            value: reSubmittedPayment?.hardPercentage,
            color: "hsl(346, 70%, 50%)",
          },
        ];
        let totalPie = [
          {
            id: "Resubmitted",
            label: "Resubmitted",
            value: reSubmittedAdjuestment?.totalAmount,
            color: "hsl(346, 70%, 50%)",
          },
          {
            id: "Payment",
            label: "Payment",
            value: reSubmittedPayment?.totalAmount,
            color: "hsl(346, 70%, 50%)",
          },
        ];

        state.appealHome.softPie = softPie;
        state.appealHome.hardPie = hardPie;
        state.appealHome.totalPie = totalPie;
      } else {
        state.appealHome.softPie = [];
        state.appealHome.hardPie = [];
        state.appealHome.totalPie = [];
      }
    },

    //bubble chart
    setBubbleChartDataByPayers: (state, action) => {
      const data = action?.payload?.data;
      // { code: "CO-97", count: "20866", amount: "13288458.73", size: "13" }
      if (data?.length > 0) {
        const arr = data?.map((item) => {
          return {
            code: item.payerName,
            denialAmount: item.softDenialAmount,
            amount: item.chargeAmount,
            size: `${item.chargeAmount / 100}`,
            claimCount: item.claimCount,
          };
        });

        state.bubbleChartPayers = arr;
      } else {
        state.bubbleChartPayers = [];
      }

      state.bubbleChartDepartmentsLoader = false;
    },
    setBubbleChartDataByDepartments: (state, action) => {
      try {
        const data = action?.payload?.data;
        if (data?.length > 0) {
          const arr = data?.map((item) => {
            return {
              code: item.level0Name,
              denialAmount: item.softDenialAmount,
              amount: item.chargeAmount,
              size: `${item.chargeAmount / 100}`,
              claimCount: item.claimCount,
            };
          });

          state.bubbleChartDepartments = arr;
        } else {
          state.bubbleChartDepartments = [];
        }

        state.bubbleChartPayersLoader = false;
        // console.log(">> setter2 ", data);
      } catch (err) {
        console.log("err--->>", err);
      }
    },
    setMainDashboardBubbleChartLoaders: (state, action) => {
      const data = action?.payload;
      state.bubbleChartPayersLoader = data;
      state.bubbleChartDepartmentsLoader = data;
      state.topCardListLoader = data;
    },
    setTopCardListLoader: (state, action) => {
      const data = action?.payload;
      state.topCardListLoader = data;
    },
    setComboChartLoader: (state, action) => {
      const data = action?.payload;
      state.comboChartLoader = data;
    },
  },
});
export default slice.reducer;
export const {
  gettingNewsRequested,
  gettingLandingPageData,
  gettingExecutiveLandingPageData,
  gettingNewsFailed,
  successLandingPageNewHome,
  successLandingPageAppealHome,
  setBubbleChartDataByPayers,
  setBubbleChartDataByDepartments,
  setMainDashboardBubbleChartLoaders,
  setComboChartLoader,
  setTopCardListLoader,
} = slice.actions;

/////////////////////////////////////////////////////////////////////
//                      Actions
/////////////////////////////////////////////////////////////////////
export const getLandingPage =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    try {
      dispatch(gettingNewsRequested());
      dispatch(setMainDashboardBubbleChartLoaders(true));
      dispatch(setTopCardListLoader(true));
      dispatch(setComboChartLoader(true));

      let defaultClientLocalStorage = localStorage.getItem("defaultClient");

      if (clear) {
        dispatch(
          resetListingState({
            listing: "mainOrgDashboardFilter",
            ignore: ["claimStatus,payers,cptCodes,CARC"],
          })
        );

        // ========= below code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - start ==============
        let advStngServiceLineExecList =
          getState().pReducers.user.advStngServiceLineExecList;
        let data = advStngServiceLineExecList?.split(",")?.map((item) => {
          return {
            label: item.toString(),
            value: item.toString(),
          };
        });
        dispatch(
          setListingState({
            listing: "mainOrgDashboardFilter",
            value: {
              smartFilterArr: data,
              pageNumber: 1,
            },
          })
        );
        // ========= above code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - end ==============
      }

      // ========= below code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - start ==============
      let advStngServiceLineExecList =
        getState().pReducers.user.advStngServiceLineExecList;
      if (advStngServiceLineExecList?.length) {
        // console.log("api_call_if--->>");
        let data = advStngServiceLineExecList?.split(",")?.map((item) => {
          return {
            label: item.toString(),
            value: item.toString(),
          };
        });
        dispatch(
          setListingState({
            listing: "reasonCodeDashboardFilter",
            value: {
              smartFilterArr: data,
              pageNumber: 1,
            },
          })
        );
      } else {
        // console.log("api_call_else--->>");
        await dispatch(gettingAdvSettingData_Call());
      }

      // ========= above code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - end ==============

      let sliceState = getState().npReducers.filters.insightsSreensCommonFilter;
      let sliceStateUser = getState().pReducers.user;
      let sliceStateForSmartFilters =
        getState().npReducers.filters.mainOrgDashboardFilter;
      let sliceStateForDate =
        getState().npReducers.filters.insightsSreensCommonFilter;

      // console.log("sliceStateForDate--->>", sliceStateForDate);

      let params = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...(sliceState?.dateMainState !== "" && {
          filter: sliceState.dateMainState == "Months" ? "W" : "Q",
        }),

        // ...(sliceStateUser?.loginUserData?.isRcm && {
        //   clientId: (() => {
        //     const clientIdFromDate =
        //       Array.isArray(sliceStateForDate?.clientId) &&
        //       sliceStateForDate?.clientId.length > 0
        //         ? sliceStateForDate.clientId[0].value
        //         : sliceStateForDate?.clientId;

        //     // If clientIdFromDate is NaN, null, or undefined, use sliceStateUser.clientId or defaultClientLocalStorage
        //     if (isNaN(clientIdFromDate) || clientIdFromDate == null) {
        //       return (
        //         sliceStateUser?.clientId ||
        //         defaultClientLocalStorage ||
        //         getCurrentClientId()
        //       );
        //     }

        //     return clientIdFromDate;
        //   })(),
        // }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: (() => {
            const clientIdValue =
              Array.isArray(sliceStateForDate?.clientId) &&
              sliceStateForDate.clientId.length > 0
                ? sliceStateForDate.clientId[0].value
                : sliceStateForDate?.clientId || getCurrentClientId();

            return clientIdValue &&
              clientIdValue !== "" &&
              !isNaN(clientIdValue)
              ? clientIdValue
              : undefined;
          })(),
          // clientId: getCurrentClientId(),
        }),

        ...(sliceStateForSmartFilters?.smartFilter &&
          sliceStateForSmartFilters?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceStateForSmartFilters?.smartFilterArr
            ),
          }),
      };

      let paramsTemp = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...(sliceState?.dateMainState !== "" && {
          filter: sliceState.dateMainState == "Months" ? "W" : "Q",
        }),
        // ...(sliceStateUser?.loginUserData?.isRcm && {
        //   clientId: (() => {
        //     const clientIdFromDate =
        //       Array.isArray(sliceStateForDate?.clientId) &&
        //       sliceStateForDate?.clientId.length > 0
        //         ? sliceStateForDate.clientId[0].value
        //         : sliceStateForDate?.clientId;

        //     // If clientIdFromDate is NaN, null, or undefined, use sliceStateUser.clientId or defaultClientLocalStorage
        //     if (isNaN(clientIdFromDate) || clientIdFromDate == null) {
        //       return (
        //         sliceStateUser?.clientId ||
        //         defaultClientLocalStorage ||
        //         getCurrentClientId()
        //       );
        //     }

        //     return clientIdFromDate;
        //   })(),
        // }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: (() => {
            const clientIdValue =
              Array.isArray(sliceStateForDate?.clientId) &&
              sliceStateForDate.clientId.length > 0
                ? sliceStateForDate.clientId[0].value
                : sliceStateForDate?.clientId || getCurrentClientId();

            return clientIdValue &&
              clientIdValue !== "" &&
              !isNaN(clientIdValue)
              ? clientIdValue
              : undefined;
          })(),
          // clientId: getCurrentClientId(),
        }),

        ...(sliceStateForSmartFilters?.smartFilter &&
          sliceStateForSmartFilters?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceStateForSmartFilters?.smartFilterArr
            ),
          }),
      };

      let paramsForBubbleChart = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...(sliceState?.dateMainState !== "" && {
          filter: sliceState.dateMainState == "Months" ? "W" : "Q",
        }),

        ...(sliceStateForSmartFilters?.smartFilter &&
          sliceStateForSmartFilters?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceStateForSmartFilters?.smartFilterArr
            ),
          }),
        // ...(sliceStateUser?.loginUserData?.isRcm && {
        //   clientId: (() => {
        //     const clientIdFromDate =
        //       Array.isArray(sliceStateForDate?.clientId) &&
        //       sliceStateForDate?.clientId.length > 0
        //         ? sliceStateForDate.clientId[0].value
        //         : sliceStateForDate?.clientId;

        //     // If clientIdFromDate is NaN, null, or undefined, use sliceStateUser.clientId or defaultClientLocalStorage
        //     if (isNaN(clientIdFromDate) || clientIdFromDate == null) {
        //       return (
        //         sliceStateUser?.clientId ||
        //         defaultClientLocalStorage ||
        //         getCurrentClientId()
        //       );
        //     }

        //     return clientIdFromDate;
        //   })(),
        // }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: (() => {
            const clientIdValue =
              Array.isArray(sliceStateForDate?.clientId) &&
              sliceStateForDate.clientId.length > 0
                ? sliceStateForDate.clientId[0].value
                : sliceStateForDate?.clientId || getCurrentClientId();

            return clientIdValue &&
              clientIdValue !== "" &&
              !isNaN(clientIdValue)
              ? clientIdValue
              : undefined;
          })(),
        }),
      };

      // Remove clientId if it is undefined
      if (paramsTemp.clientId === undefined) {
        delete paramsTemp.clientId;
      }
      // Remove clientId if it is undefined
      if (params.clientId === undefined) {
        delete params.clientId;
      }
      // Remove clientId if it is undefined
      if (paramsForBubbleChart.clientId === undefined) {
        delete paramsForBubbleChart.clientId;
      }

      // console.log("params_getLandingPage_Call--->>", params);

      //
      dispatch(
        setListingState({
          listing: "landingPageFilterExecutive",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      genericAxiosCall(
        `${serverRoutes?.LANDING_PAGE_GET_PAYEES}`,
        "get",
        "",
        params
      ).then((res) => {
        if (res?.data?.success) {
          const data = res?.data?.data?.hospitalList;
          const payeeIds = data?.map((item) => item?.payeeId);
          const executiveKpisParams = {
            ...params,
            payeeId: payeeIds?.toString(),
            // clientId: String(params?.clientId),
            ...(sliceStateUser?.loginUserData?.isRcm && {
              clientId: String(params?.clientId),
            }),
          };
          // console.log("executiveKpisParams--->>", executiveKpisParams);
          commonPaginationForPost(
            getState,
            executiveKpisParams,
            executiveKpisParams,
            dispatch,
            gettingExecutiveLandingPageData,
            "landingPageFilterExecutive",
            "LANDING_PAGE_EXECUTIVE_DASHBOARD",
            null
          );
        }
        dispatch(setTopCardListLoader(false));
      });

      // console.log(
      //   "paramsForBubbleChart--->>",
      //   paramsForBubbleChart,
      //   "sliceStateForDate.clientId:",
      //   sliceStateForDate.clientId,
      //   "getCurrentClientId():",
      //   getCurrentClientId()
      // );
      // -------------- bubble charts ----------------------
      genericAxiosCall(
        `${serverRoutes?.GET_TOP_FIVE_BUBBLE_CHART_BY_PAYERS}`,
        "get",
        "",
        paramsForBubbleChart
      ).then((res) => {
        if (res?.data?.success) {
          dispatch(setBubbleChartDataByPayers(res?.data));
        }
      });

      genericAxiosCall(
        `${serverRoutes?.GET_TOP_FIVE_BUBBLE_CHART_BY_DEPARTMENTS}`,
        "get",
        "",
        paramsForBubbleChart
      ).then((res) => {
        if (res?.data?.success) {
          dispatch(setBubbleChartDataByDepartments(res?.data));
        }
      });
      // -------------- bubble charts ----------------------

      commonPagination(
        getState,
        params,
        paramsTemp,
        dispatch,
        gettingLandingPageData,
        "landingPageFilter",
        "LANDING_PAGE",
        null
      );
    } catch (err) {
      console.log("err--->>", err);
    }
  };

export const getLandingPageNewHome = () => (dispatch, getState) => {
  let sliceState = getState().npReducers.filters.landingPageNewHomeFilter;
  let params = {
    ...(sliceState?.from !== "" && {
      from: sliceState.from,
    }),
    ...(sliceState?.to !== "" && {
      to: sliceState.to,
    }),
    ...(sliceState?.selHospitalsArr?.length > 0 && {
      hospitals: getMultiSelectFilterValueForApi(sliceState?.selHospitalsArr),
    }),
  };

  let paramsTemp = {
    ...(sliceState?.from !== "" && {
      from: sliceState.from,
    }),
    ...(sliceState?.to !== "" && {
      to: sliceState.to,
    }),
    ...(sliceState?.selHospitalsArr?.length > 0 && {
      hospitals: getMultiSelectFilterValueForApi(sliceState?.selHospitalsArr),
    }),
  };

  commonPagination(
    getState,
    params,
    paramsTemp,
    dispatch,
    successLandingPageNewHome,
    "landingPageNewHomeFilter",
    "LANDING_PAGE_NEW_HOME",
    null
  );
};
export const getLandingPageAppealHome = () => (dispatch, getState) => {
  let sliceState = getState().npReducers.filters.appealPageFilter;
  let params = {
    ...(sliceState?.from !== "" && {
      from: sliceState.from,
    }),
    ...(sliceState?.to !== "" && {
      to: sliceState.to,
    }),
  };

  let paramsTemp = {
    ...(sliceState?.from !== "" && {
      from: sliceState.from,
    }),
    ...(sliceState?.to !== "" && {
      to: sliceState.to,
    }),
  };

  commonPagination(
    getState,
    params,
    paramsTemp,
    dispatch,
    successLandingPageAppealHome,
    "appealPageFilter",
    "LANDING_PAGE_NEW_HOME",
    null
  );
};

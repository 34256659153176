import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { serverRoutes } from "../../../constants/serverRoutes";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";

import {
  setListingState,
  resetListingState,
} from "../filters/claimManagerFilters";
import {
  commonPagination,
  getMultiSelectFilterValueForApi,
  getDescArray,
} from "../../../components/common/Utils/utils";
import { getLegendsExtraShortName } from "./../../../components/common/Utils/utils";

export const DenialsByFacilityDashSlice = createSlice({
  name: "DenialsByFacilityDashSlice",
  initialState: {
    denialsByFacility: {
      tabularDataArr: [],
      cardsItemData: {},

      serviceFacilityChartArr: [],
      serviceFacilityFliterArr: [],
    },
  },
  reducers: {
    setDenialsByFacility_dataAction: (state, action) => {
      let data = action.payload;
      // console.log("setDenialsByFacility_dataAction--->>", data);

      try {
        // handle serviceFacilityCard here
        if (data?.serviceFacilityCard?.length) {
          let obj = data?.serviceFacilityCard[0];
          // console.log("obj--->>", obj);
          state.denialsByFacility.cardsItemData = obj;
        } else {
          state.denialsByFacility.cardsItemData = {};
        }

        // handle for serviceFacilityTreeMap  here
        if (data?.serviceFacilityTreeMap?.length) {
          let res_data = data?.serviceFacilityTreeMap;

          const tempArr = [];
          res_data.forEach((item, i) => {
            tempArr.push({
              name: item?.facilityName ? item?.facilityName : "Blank",
              facilityName: getLegendsExtraShortName(
                item?.facilityName ? item?.facilityName : "Blank"
              ),
              facilityNameOriginal: item?.facilityName
                ? item?.facilityName
                : "Blank",
              denailAmount: item?.softAdjustment,
              denailCount: item?.softAdjustmentCount,
              tempAmount: 10,
              tempdenailAmount: Math.abs(item?.softAdjustment),
              description: item?.description,
              facilityTypeCode: item?.facilityTypeCode,
            });
          });

          const newArr = [];
          const unique_facilityName = [
            ...new Set(tempArr.map((item) => item?.facilityName)),
          ];

          unique_facilityName.forEach((item_facilityName) => {
            let obj = {};
            const newCodeArr = tempArr.filter(
              (item) => item?.facilityName === item_facilityName
            );
            if (newCodeArr?.length) {
              let sortArr = getDescArray(newCodeArr, "denailAmount");

              obj["name"] = item_facilityName;
              // obj["children"] = newCodeArr;
              obj["children"] =
                sortArr?.length > 4 ? sortArr.slice(0, 5) : sortArr;
            }
            newArr.push(obj);
          });

          let obj2 = {
            name: "Facility Name",
            color: "hsl(294, 70%, 50%)",
            children: newArr,
          };

          state.denialsByFacility.serviceFacilityChartArr = obj2;
        } else {
          state.denialsByFacility.serviceFacilityChartArr = [];
        }

        // handle serviceFacilityNameFilter here
        if (data?.serviceFacilityNameFilter?.length > 0) {
          let newArr = [];
          // romoving empty facilityName from original array
          const filteredArray = data?.serviceFacilityNameFilter.filter(
            (obj) => obj.facilityName !== "" && !obj.facilityName.includes("-")
          );

          filteredArray?.forEach((item) => {
            newArr.push({
              label: item?.facilityName,
              value: item?.facilityName,
            });
          });

          state.denialsByFacility.serviceFacilityFliterArr = newArr;
        } else {
          state.denialsByFacility.serviceFacilityFliterArr = [];
        }
      } catch (e) {
        // console.log("error--->>", e);
      }
    },

    setDenialsTabularData_Action: (state, action) => {
      let data = action.payload;
      // handle serviceFacilityTable here

      if (data?.length > 0) {
        state.denialsByFacility.tabularDataArr = data;
      } else {
        state.denialsByFacility.tabularDataArr = [];
      }
    },
  },
});

export const { setDenialsByFacility_dataAction, setDenialsTabularData_Action } =
  DenialsByFacilityDashSlice.actions;

// get denial by facility chart data
export const gettingDenialsByFacilityData_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialByFacilityDashFilter",
          ignore: ["claimStatus,payers,frequencyType,search"],
        })
      );
    }
    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceState = getState().npReducers.filters.denialByFacilityDashFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      ///// API CALL //////
      let paramsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceState?.facilityName?.length > 0 && {
          facilityName: getMultiSelectFilterValueForApi(
            sliceState?.facilityName
          ),
        }),
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
      };

      let params = {
        start,
        limit,
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceState?.facilityName?.length > 0 && {
          facilityName: getMultiSelectFilterValueForApi(
            sliceState?.facilityName
          ),
        }),
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setDenialsByFacility_dataAction,
        "denialByFacilityDashFilter",
        "GET_DENIALS_BY_FACILITY_DASH_KPIS",
        null,
        "",
        ""
      );

      ////// API CALL END //////////////

      return false;
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };

// get denial by facility tabular arr data
export const gettingDenialsByFacilityTabularData_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialByFacilityDashFilterForTable",
          ignore: ["claimStatus,payers,frequencyType,search"],
        })
      );
    }

    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;
    let sliceState =
      getState().npReducers.filters.denialByFacilityDashFilterForTable;
    let sliceStateFilters =
      getState().npReducers.filters.denialByFacilityDashFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      ///// API CALL //////
      let paramsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateFilters?.facilityName?.length > 0 && {
          facilityName: getMultiSelectFilterValueForApi(
            sliceStateFilters?.facilityName
          ),
        }),
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
      };

      let params = {
        start,
        limit,
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateFilters?.facilityName?.length > 0 && {
          facilityName: getMultiSelectFilterValueForApi(
            sliceStateFilters?.facilityName
          ),
        }),
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setDenialsTabularData_Action,
        "denialByFacilityDashFilterForTable",
        "GET_DENIALS_BY_FACILITY_DASH_TABULAR_DATA",
        null,
        "",
        ""
      );

      return false;
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };

// get one Facility details
export const getOneFacilityData_Call =
  (facility_Name) => async (dispatch, getState) => {
    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;
    let sliceState = getState().npReducers.filters.denialByFacilityDashFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      ///// API CALL //////

      let params = {
        start,
        limit,
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...{
          facilityName: facility_Name,
        },
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_DENIALS_BY_FACILITY_DASH_TABULAR_ONE_ROW_DATA,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };

export default DenialsByFacilityDashSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import common from "../../../actions/common";

// localStorage.setItem("cardIsAMEX", "AMEX");

export const addressSlice = createSlice({
  name: "addressSlice",
  initialState: {
    city: [],
    state: [],
    register_trial: false,
    cardIsAMEX: "AMEX",
  },
  reducers: {
    cityCallAction: (state, action) => {
      state.city = action.payload;
    },
    stateCallAction: (state, action) => {
      state.state = action.payload;
    },

    setRegister_Trial: (state, action) => {
      state.register_trial = action.payload;
    },
    setRegister_CardType: (state, action) => {
      state.cardIsAMEX = action.payload;
      // localStorage.setItem("cardIsAMEX", action.payload);
    },
  },
});

export const {
  cityCallAction,
  stateCallAction,
  setRegister_Trial,
  setRegister_CardType,
} = addressSlice.actions;

export const getCityCall = () => async (dispatch) => {
  const cityData = await common.cities();
  dispatch(cityCallAction(cityData.data.data));
  return cityData.data.data;
};

export const getStateCall = () => async (dispatch) => {
  const stateData = await common.states();

  dispatch(stateCallAction(stateData.data.data));
  return stateData.data.data;
};

export function getCardType() {
  let val = localStorage.getItem("cardIsAMEX");
  // console.log("getCardType--->>", val);

  if (val === "AMEX") {
    // console.log("getCardType_if--->>", true);

    return true;
  } else {
    // console.log("getCardType_else--->>", false);

    return false;
  }
}

export default addressSlice.reducer;

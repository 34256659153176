import React, { useState } from "react";
import { useEffect } from "react";

import { useSelector } from "react-redux";

import UserDropdown from "../UserDropdown";

import BootstrapTooltip from "../common/TooltipUI/customToolTip";
import BackIcon from "../../assets/icons/BackIcon";
import { Button, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import browserRoutes from "../../constants/browserRoutes";
import NewCustomCopilot from "../NewCustomCopilot/index";
import CopilotNavDropDown from "../../pages/AskZoe/CopilotNavDropDown";
import AppLunchar from "../common/Sidebar/ModualSideDrop";
import { setCurrentModuleRedux } from "../../Redux/Reducers/UserReducer";
import { useDispatch } from "react-redux";
import { setListingState } from "../../Redux/Reducers/filters/claimManagerFilters";
import { botRoutes } from "../common/Utils/utils";
import { getCopilotHistoryOutputList } from "../../Redux/Reducers/UploadPolicy/UploadPolicySlice";

const DashboardTopNavBot = ({
  target,
  title,
  backButton,
  children,
  datePicker,
  setDateUpdate,
  deleteQuerywithoutDate,
  isTopNavSearch,
  clientDropDownComp,
  dateComp,
  searchComp,
  topSearchHide,
  topSearchBot,
  input,
  setInput,
  handleChange,
  handleSubmit,
  inputCognative,
  setInputCognative,
  handleChangeCognative,
  handleSubmitCognative,
}) => {
  const { currentModule } = useSelector((state) => state.pReducers.user);
  const { openDrawer } = useSelector(
    (state) => state.npReducers.filters.userPreferencesFilter
  );
  const nav = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const url = pathname.split("/")[1];
  const [searchVisible, setSearchVisible] = useState(false);
  const [inputMain, setInputMain] = useState("");
  const [inputCognativeSearch, setInputCognativeSearch] = useState("");
  const [currentModuleState, setCurrentModuleState] = useState(currentModule);

  const handleBackBtn = () => {
    nav(-1);
  };
  const handleChangeMain = (e) => {
    setInputMain(e.target.value);
  };
  const handleSideNavState = () => {
    dispatch(
      setListingState({
        listing: "userPreferencesFilter",
        value: {
          chatbotEpandWidth: false,
        },
      })
    );
    dispatch(
      setListingState({
        listing: "userPreferencesFilter",
        value: {
          openDrawer: !openDrawer,
          openDrawerChatbot: !openDrawer && false,
        },
      })
    );
  };
  const handleSelectModule = (modules) => {
    // console.log("handleSelectModule_1--->>", moduleRoles);

    dispatch(setCurrentModuleRedux(modules));
    setCurrentModuleState(modules);
  };
  const handleChangeCognativeSearch = (e) => {
    if (setInputCognative) {
      setInputCognative(e.target.value);
      handleChangeCognative();
    }
    setInputCognativeSearch(e.target.value);
  };

  const handleSubmitMain = (e) => {
    // if (inputMain) {
    //   nav(`${browserRoutes?.DR_SEARCH}?search=${inputMain}`);
    // }
    if (inputMain) {
      nav(`${browserRoutes?.CHAT_BOT_RESULT}?search=${inputMain}`);
    }
  };

  const handleSubmitCognativeSearch = (e) => {
    if (inputCognativeSearch) {
      nav(`${browserRoutes?.DR_SEARCH}?search=${inputCognativeSearch}`);
    }
  };

  useEffect(() => {
    if (
      pathname == browserRoutes.CLAIM_PAYMENT_835 ||
      pathname == browserRoutes.DENIALS_DETAIL ||
      pathname == browserRoutes.DENAIL_BY_SERVICELINE ||
      pathname == browserRoutes.CLAIM_MANAGER_837 ||
      pathname == browserRoutes.PARIALLY_DENIED ||
      pathname == browserRoutes.DRUG_ACC_RATE ||
      pathname == browserRoutes.USERS_LIST ||
      pathname == browserRoutes.REVERSAL_DENIED ||
      pathname == browserRoutes.CLAIM_PAYMENT_835_UNDER_PAYMENT
    ) {
      setSearchVisible(true);
    }
  }, []);

  return (
    <div
      className="DR-New-Design-top-bar DR-copilot-nav"
      // className={
      //   environment === "dev"
      //     ? "DR-New-Design-top-bar DR-top-nav-dev"
      //     : "DR-New-Design-top-bar"
      // }
    >
      <div
        className="flex justify-between"
        style={{ width: "234px", paddingLeft: "15px" }}
      >
        <AppLunchar
          isWhiteLogo={true}
          currentModule={currentModuleState}
          setCurrentModule={setCurrentModuleState}
          handleSelectModule={handleSelectModule}
        />
        <div className="DR-expand-llm" style={{ marginRight: "-20px" }}>
          <BootstrapTooltip title="Expand Left Bar">
            <span>
              <IconButton
                style={{
                  padding: "0px",
                  width: "24px",
                  height: "24px",
                  backgroundColor: "#fff",
                  transform: "rotate(180deg)",
                }}
                color="inherit"
                aria-label="open drawer"
                onClick={handleSideNavState}
                edge="start"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-align-justified"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#131722"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M4 6l16 0" />
                  <path d="M4 12l16 0" />
                  <path d="M4 18l12 0" />
                </svg>
              </IconButton>
            </span>
          </BootstrapTooltip>
        </div>
      </div>
      {backButton && (
        <BootstrapTooltip title="Back">
          <div className="pull-left mr-3">
            <IconButton
              className="DR-toll-btn dr-tool-bd-rit"
              onClick={handleBackBtn}
            >
              <BackIcon />
            </IconButton>
          </div>
        </BootstrapTooltip>
      )}
      <div
        className={`DR-v2-New-title-side w-full ${backButton ? "pl-4" : ""}`}
      >
        <div className="flex">
          {botRoutes?.includes(window?.location?.pathname?.split("?")?.[0]) && (
            <>
              <CopilotNavDropDown />
              <NewCustomCopilot />
            </>
          )}
        </div>

        <h2 className="V2-DR-page-title relative">{title}</h2>
        <div className="DR-Search-input-main-top">
          <div
            className="DR-Search-input"
            style={{
              flexDirection: "column",
              justifyContent: "center",
            }}
          ></div>
        </div>

        {/* {children} */}
        <div className="DR-V2-profile-div line-op-mian DR-llm-user-drop">
          <span className="DR-line-new-design mt-0 mr-2"></span>
          <UserDropdown target={target} hideBot={true} />
        </div>
      </div>
    </div>
  );
};

export default DashboardTopNavBot;

import axios from "../AxiosConfig/axios";
const subId = localStorage.getItem("subscriptionID");

export default {
  // run query
  runQuery(body) {
    return axios.post(`/QueryBuilder/Execution`, body);
  },

  // load query builder type
  getQueryBuilderType() {
    return axios.get(`/QueryBuilder/QueryBuilderType`);
  },

  // load prev query
  getPrevQuery(id) {
    return axios.get(`/QueryBuilder/GetAllQueries?QueryBuilderTypeId=${id}`);
  },

  // save query
  saveQuery(body) {
    return axios.post(`/QueryBuilder/Save`, body);
  },

  // get selected query parameter
  getQueryBuilderTypeColumns(tableName) {
    return axios.get(`/QueryBuilder/ColumnInfo?tableName=${tableName}`);
  },
};

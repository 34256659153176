import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Typography,
  Grid,
  Button,
  FormControlLabel,
  Input,
  InputAdornment,
  TextField,
  IconButton,
} from "@mui/material";
import logoDOW from "../../../assets/svgs/Logo.svg";
import { serverRoutes } from "../../../constants/serverRoutes";
import Loading from "../../../components/common/ExpandableTable/Loading";

const NewEmailVerify = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [regenerateButton, setRegenerateButton] = useState({
    visible: false,
    message: "",
    disabled: false,
  });
  useEffect(() => {
    if (id) {
      const res = axios
        .get(`${serverRoutes?.VERIFY_EMAIL_NEW}?customerId=${id}`)
        .then((res) => {
          if (res?.data?.success) {
            const generateLink = res?.data?.data?.genrateNewLink;
            if (generateLink) {
              setRegenerateButton({
                ...regenerateButton,
                visible: true,
                message: "Regenerate Link",
              });
            }
            setMessage(
              res?.data?.message
                ? res?.data?.message
                : "Your email has been verified."
            );
          } else {
            setMessage(
              res?.data?.message
                ? res?.data?.message
                : "Couldn't verify your email."
            );
          }
          setLoading(false);
        })
        .catch((error) => {
          setMessage(
            res?.data?.message
              ? res?.data?.message
              : "Couldn't verify your email."
          );
          setLoading(false);
        });
    }
  }, []);
  const regenrateLinkFunc = () => {
    setButtonLoading(true);
    const res = axios
      .get(`${serverRoutes?.GENERATE_EMAIL_LINK}?customerId=${id}`)
      .then((res) => {
        if (res?.data?.success) {
          setRegenerateButton({
            ...regenerateButton,
            message: "Link Sent",
            disabled: true,
          });
        }
        setButtonLoading(false);
      })
      .catch((error) => {
        setButtonLoading(false);
      });
  };

  return (
    <div
      className="row d-flex align-items-center justify-content-center new-login-bg"
      style={{ position: "relative", height: "100vh" }}
    >
      {loading ? (
        <div style={{ position: "relative" }}>
          <div>
            <div className="cliam-ui-table-2">
              <Loading compHeight="200" />
            </div>
          </div>
        </div>
      ) : (
        <Box className="login-form-side">
          <Grid container className="login-form-side">
            <Box className="login-form-inner">
              <div className="p-3">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "35px",
                  }}
                >
                  <a href="/">
                    <img src={logoDOW} alt="DataRovers Logo" width="240px" />
                  </a>
                </Box>
                <p
                  style={{
                    color: "#131722",
                    fontSize: "26px",
                    fontWeight: "600",
                    minWidth: "400px",
                    textAlign: "center",
                    marginBottom: "5px",
                  }}
                >
                  {message}
                </p>
                {regenerateButton?.visible && (
                  <Grid item xs={12}>
                    <Button
                      style={{
                        display: "block",
                        margin: "20px 0",
                        backgroundColor: "#007a56",
                        padding: "12px 20px",
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "20px",
                        color: "#ffffff",
                        border: "none",
                        width: "100%",
                        height: "42px",
                        borderRadius: "6px",
                        cursor: "pointer",
                        textDecoration: "none",
                        textTransform: "none",
                      }}
                      disabled={regenerateButton?.disabled}
                      variant="contained"
                      type="submit"
                      fullWidth
                      onClick={regenrateLinkFunc}
                      // className={
                      //   "btn-primary " + buttonLoading
                      // }
                      className={`btn-primary ${
                        buttonLoading ? "loading" : ""
                      }`}
                    >
                      {regenerateButton?.message}
                    </Button>
                  </Grid>
                )}
                <p
                  style={{
                    color: "#131722",
                    fontSize: "14px",
                    minWidth: "400px",
                    textAlign: "center",
                  }}
                >
                  Our team is working diligently on completing your request.{" "}
                </p>
              </div>
            </Box>
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default NewEmailVerify;

import axios from "axios";
import store from "../Redux/store";
import { serverRoutes } from "./../constants/serverRoutes";
const domainId = localStorage.getItem("domainID");

const subId = localStorage.getItem("subscriptionID");
export const serviceLineIndicatorApi = ({ formData }) => {
  return axios.get(
    `/ML/ServiceLineIndicator?ServiceRequestId=${formData.ClaimRequestId}&TransectionSetHeaderId=${formData.TransectionSetHeaderId}&ClaimRequestId=${formData.ClaimRequestId}&RevenueCode1=${formData.RevenueCode1}&LineItemControlNo=${formData.LineItemControlNo}&ServiceChargeAmount=${formData.ServiceChargeAmount}&AllowedAmount=${formData.AllowedAmount}&MeasurementUnitCode=${formData.MeasurementUnitCode}&ServiceUnitCount=${formData.ServiceUnitCount}&CreatedOn=${formData.CreatedOn}`
  );
};

// Claim Indicator-1 (Support Vector Machine)
export const ClaimIndicator1_SupportVectorMachine = ({ newData }) => {
  return axios.get(
    `/ML/ClaimIndicator1?ClaimRequestId=${newData.ClaimRequestId}&TransectionSetHeaderId=${newData.TransectionSetHeaderId}&MemberId=${newData.MemberId}&PatientControlNo=${newData.PatientControlNo}&From=${newData.From}&To=${newData.To}&ChargeAmount=${newData.ChargeAmount}&BilingProviderId=${newData.BilingProviderId}&Payer=${newData.Payer}&FacilityTypeQualifierCode=${newData.FacilityTypeQualifierCode}`
  );
};
//Claim Indicator-2 (Decision Forest)
export const ClaimIndicator2_DecisionForest = ({ newData }) => {
  return axios.get(
    `/ML/ClaimIndicator2?ClaimRequestId=${newData.ClaimRequestId}&TransectionSetHeaderId=${newData.TransectionSetHeaderId}&MemberId=${newData.MemberId}&PatientControlNo=${newData.PatientControlNo}&From=${newData.From}&To=${newData.To}&ChargeAmount=${newData.ChargeAmount}&BilingProviderId=${newData.BilingProviderId}&Payer=${newData.Payer}&FacilityTypeQualifierCode=${newData.FacilityTypeQualifierCode}`
  );
};
//Claim Indicator-3 (Neural Network)
export const ClaimIndicator3_NeuralNetwork = ({ newData }) => {
  return axios.get(
    `/ML/ClaimIndicator3?ClaimRequestId=${newData.ClaimRequestId}&TransectionSetHeaderId=${newData.TransectionSetHeaderId}&MemberId=${newData.MemberId}&PatientControlNo=${newData.PatientControlNo}&From=${newData.From}&To=${newData.To}&ChargeAmount=${newData.ChargeAmount}&BilingProviderId=${newData.BilingProviderId}&Payer=${newData.Payer}&FacilityTypeQualifierCode=${newData.FacilityTypeQualifierCode}`
  );
};

export const getClaimByPatientControlNo = ({
  selectedPatientControlNo,
  chargeAmount,
  domainID,
}) => {
  return axios.get(
    `/ML/GetClaimByPatientControlNo?PatientControlNo=${selectedPatientControlNo}&ChargeAmount=${chargeAmount}`,
    {
      headers: { "x-Domain": domainID },
    }
  );
};

export const getClaimServiceLineItem = (controlNo, amount, domainID) => {
  return axios.get(
    `/ML/GetClaimServiceLineItem?LineItemControlNo=${controlNo}&ChargeAmount=${amount}`,
    {
      headers: { "x-Domain": domainID },
    }
  );
};

export const getServiceLinePrediction = (controlNo, amount, domainID) => {
  return axios.get(
    ` /ML/ServiceLinePrediction?LineItemControlNo=${controlNo}&ChargeAmount=${amount}`,
    {
      headers: { "x-Domain": domainID },
    }
  );
};

export const modelDecisionForest = (data) => {
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization:
      "Bearer Ec3VRX9U97Bc83LYgPC3MgvW8BGST3KJZIljVHSd4hb0Uqr9/cK5RSDxT+be6/VOMYxluV+9J5tkXy3B1knDEA==",
  };
  return axios.post(
    `https://ussouthcentral.services.azureml.net/workspaces/4f92a6604b234c2fb0f153043f0c62b5/services/fd14a2d49dec4af286fdb8c6c12051f6/execute?api-version=2.0&format=swagger`,
    data,
    { headers: headers }
  );
};

export const getMLDataForPredictions = (claimId) => {
  // Must Remove claimId from query params
  const domainId = store.getState().pReducers.user.domainID;

  return axios.get(
    `${serverRoutes.GET_SERVICELINE_FOR_DROPDOWN_837}${claimId}`,
    {
      headers: { "x-Domain": domainId, "X-Subscription": subId },
    }
  );
};

export const getServiceLineIndicator = (formData) => {
  return axios.get(
    ` /ML/CPTCodePrediction?ClaimRequestId=${formData.ClaimRequestId}&TransectionSetHeaderId=${formData.TransectionSetHeaderId}&LineItemControlNo=${formData.LineItemControlNo}&PatientControlNo=${formData.PatientControlNo}&From=${formData.From}&To=${formData.To}&Payer=${formData.Payer}&CPTCode=${formData.CPTCode}&ServiceChargeAmount=${formData.ServiceChargeAmount}&ChargeAmount=${formData.ChargeAmount}`
  );
};

// ML one month Revenue Predction
export const getReveuePrediction = (
  MonthName,
  MonthNo,
  Year,
  PreviousMonthNo,
  PreviousRevenue
) => {
  return axios.get(
    `/ML/RevenueForecast?MonthName=${MonthName}&MonthNo=${MonthNo}&Year=${Year}&PreviousMonthNo=${PreviousMonthNo}&PreviousRevenue=${PreviousRevenue}`
  );
};

// ML one month Volume Predction
export const getVolumePrediction = (
  monthName,
  monthNo,
  year,
  prevMonthNo,
  prevVolume
) => {
  return axios.get(
    `/ML/VolumeForecast?MonthName=${monthName}&MonthNo=${monthNo}&Year=${year}&PreviousMonthNo=${prevMonthNo}&PreviousVolume=${prevVolume}`
  );
};

// ML one Week Revenue Predction
export const getOneWeekReveuePrediction = (
  MonthOfYear,
  WeekOfYear,
  PreviousRevenue,
  Year
) => {
  return axios.get(
    `/ML/RevenueForcastWeekly?MonthOfYear=${MonthOfYear}&WeekOfYear=${WeekOfYear}&PreviousRevenue=${PreviousRevenue}&Year=${Year}`
  );
};

// ML one Week Volume Predction
export const getOneWeekVolumePrediction = (
  MonthOfYear,
  WeekOfYear,
  PreviousRevenue,
  Year
) => {
  return axios.get(
    `/ML/VolumeForcastWeekly?MonthOfYear=${MonthOfYear}&WeekOfYear=${WeekOfYear}&PreviousVolume=${PreviousRevenue}&Year=${Year}`
  );
};

// ML three year Volume Predction
export const getThreeYearVolumePrediction = (PreviousVolume, Year) => {
  return axios.get(
    `/ML/VolumeForcast3Y?Year=${Year}&PreviousVolume=${PreviousVolume}`
  );
};

// ML three year Revenue Predction
export const getThreeYearRevenuePrediction = (PreviousRevenue, Year) => {
  return axios.get(
    `/ML/RevenueForcast3Y?Year=${Year}&PreviousRevenue=${PreviousRevenue}`
  );
};

import React, { useState } from "react";

import { IconButton, Paper } from "@mui/material";

import BootstrapTooltip from "./../../../components/common/TooltipUI/customToolTip";
import {
  handleMultipleCptCodeDecs,
  handleReasonCodeDesc,
} from "./../../../components/common/Utils/utils";

import ArrowDownIcon from "../../../assets/svgs/ArrowDownIcon";

import RichTooltip from "./RichTollTip.jsx";

export default function CPTPopOver({ cptArray, reasonCodeArray }) {
  // handle popover start ========================
  const [openPopover, setOpenPopover] = useState(false);
  const handleOpenPopover = () => {
    setOpenPopover(true);
  };
  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  // handle popover end ==========================
  return (
    <div>
      <RichTooltip
        content={
          <div
            className="DR-new-SP-table-compack DR-add-filter-ul p-2"
            component={Paper}
            style={{ maxHeight: "300px", overflow: "auto" }}
          >
            {cptArray && (
              <>
                <h4 className="DR-form-title-v3-related pl-2 pt-2">
                  Procedure Codes
                </h4>
                <div style={{ maxWidth: "500px", wordBreak: "break-word" }}>
                  {handleMultipleCptCodeDecs(cptArray)}
                </div>
              </>
            )}
            {reasonCodeArray && (
              <>
                <h4 className="DR-form-title-v3-related pl-2 pt-2">
                  Reason Codes
                </h4>
                <div style={{ maxWidth: "500px", wordBreak: "break-word" }}>
                  {handleReasonCodeDesc(reasonCodeArray)}
                </div>
              </>
            )}
          </div>
        }
        open={openPopover}
        setOpen={setOpenPopover}
        placement="bottom"
        onClose={handleClosePopover}
        className="DR-rich-pop"
      >
        <IconButton
          className="DR-view-Btn dr-ac-btn-icon mr-0 ml-0"
          onClick={handleOpenPopover}
        >
          <div>
            {/* <ArrowDownIcon /> */}
            ...
          </div>
        </IconButton>
      </RichTooltip>
    </div>
  );
}

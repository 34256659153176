import { useSelector } from "react-redux";

function useAuth() {
  const { userToken, loginUserData } = useSelector(
    (state) => state.pReducers.user
  );
  const roles = loginUserData?.roles;
  return [userToken, roles];
}

export default useAuth;

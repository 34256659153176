import React from 'react';

const TrashIcon = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_228_354)">
        <path
          d="M7.91016 7.80371C8.01612 15.6348 8.43912 20.0105 9.16016 27.8037C9.21953 28.9593 10.0602 29.8037 11.1602 29.8037H22.6602C23.7645 29.8037 24.5895 28.9593 24.6602 27.8037L25.9102 7.80371"
          stroke="#CC553D"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.9102 11.8037V25.8037M12.4102 11.8037L12.9102 25.8037M21.4102 11.8037L20.9102 25.8037"
          stroke="#CC553D"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M5.73828 4.25391L27.5242 7.31571Z" fill="#CC553D" />
        <path
          d="M5.73828 4.25391L27.5242 7.31571"
          stroke="#CC553D"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M12.6699 5.22866L13.0178 2.75299C13.0447 2.55768 13.1099 2.36961 13.2097 2.1996C13.3095 2.02959 13.4419 1.88098 13.5994 1.76234C13.7568 1.64369 13.9362 1.55735 14.1271 1.50827C14.3181 1.45918 14.5168 1.44834 14.712 1.47635L19.6633 2.17221C19.8586 2.19908 20.0467 2.26429 20.2167 2.3641C20.3867 2.46391 20.5353 2.59635 20.654 2.75379C20.7726 2.91124 20.859 3.09059 20.908 3.28153C20.9571 3.47247 20.968 3.67123 20.94 3.86637L20.592 6.34204"
          stroke="#CC553D"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_228_354">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.910156 0.803711)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TrashIcon;

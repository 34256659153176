import React from 'react';

const QueueIcon = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.756 19.2825L5.58721 21.6019C4.48721 22.1019 4.48721 22.9206 5.58721 23.4206L14.8372 27.6394C15.8928 28.12 17.6303 28.12 18.686 27.6394L27.936 23.4206C29.036 22.9206 29.036 22.1019 27.936 21.6019L22.9397 19.1975M27.931 8.60312L18.596 4.34688C17.5841 3.88438 15.9278 3.88438 14.916 4.34688L5.58721 8.60312C4.48721 9.10312 4.48721 9.92125 5.58721 10.4213L14.8372 14.64C15.8928 15.1213 17.6303 15.1213 18.686 14.64L27.936 10.4213C29.031 9.92125 29.031 9.1025 27.931 8.60312Z"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M10.7559 12.78L5.58086 15.1025C4.48086 15.6025 4.48086 16.4213 5.58086 16.9213L14.8309 21.1394C15.8865 21.6207 17.624 21.6207 18.6796 21.1394L27.9296 16.9213C29.0359 16.4213 29.0359 15.6025 27.9359 15.1025L22.7559 12.78"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  );
};

export default QueueIcon;

import { createSlice } from "@reduxjs/toolkit";
import claims from "../../../../actions/claims";

export const claimPaymentCardSlice = createSlice({
  name: "claimPaymentCardSlice",
  initialState: {
    claimCPTCodesData: [],
  },
  reducers: {
    claimCPTCodes: (state, action) => {
      state.claimCPTCodesData = action.payload;
    },
  },
});

const { claimCPTCodes } = claimPaymentCardSlice.actions;

export const claimsCPTCodes = () => async (dispatch) => {
  let startDate = "0001-01-01";
  let endDate = "0001-01-01";
  let payers = "";
  let ClaimStatus = "All,Paid,Partial,Denied";

  const claimCPTCodesData = await claims.getClaimsCPTCodes(
    startDate,
    endDate,
    payers,
    ClaimStatus
  );
 
  const { data: result } = claimCPTCodesData;

  dispatch(claimCPTCodes(result.data));
  return claimCPTCodesData;
};

//export const claimSumFiveYear  = state => state.claimDashboard.claimSumLastFiveYear;

export default claimPaymentCardSlice.reducer;

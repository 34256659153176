import React, { lazy, Suspense, useEffect, useMemo, useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import browserRoute from "./constants/browserRoutes";
import AskPolicyChat from "./pages/AskZoe/PolicyCopilotConversation";
// import { Helmet } from "react-helmet";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import {
  handleNewNotification,
  handleUserVeification,
  initialRenderModules,
  initialRenderNotification,
  moduleRolesEnum,
  refreshMsalToken,
  refreshToken,
} from "./components/common/Utils/utils";
import { loginRequest } from "./AuthenticationAD/authConfig";

const Error404 = lazy(() => import("./pages/ErrorPages/Error404"));
const Register = lazy(() => import("./pages/Auth/Register/index"));
const Forgotpassword = lazy(() => import("./pages/Auth/ForgotPassword"));
const ForgetEmail = lazy(() => import("./pages/Auth/ForgetEmail"));
const Verification = lazy(() => import("./pages/Auth/Verification"));
const Home = lazy(() => import("./components/common/Home"));
const Dashboard = lazy(() => import("./pages/Dashbaord/index"));
const Settings = lazy(() => import("./pages/Settings/index"));
const OthersLinksExperimental = lazy(() =>
  import("./pages/Settings/OthersLinks")
);
const DashboardLinks = lazy(() => import("./pages/Settings/DashboardLinks"));

const Profile = lazy(() => import("./pages/Users/Profile/index"));

const NewHome = lazy(() => import("./pages/NewHome/Index"));
const AiHome = lazy(() => import("./pages/Al/Index"));
const AppealHome = lazy(() => import("./pages/Appeal/Index"));

const QueuesBucket = lazy(() => import("./pages/ClaimQueue/QueuesBucket"));

const SSOScreen = lazy(() => import("./pages/SSO/index"));
const APIScreen = lazy(() => import("./pages/API/index"));

const Payment = lazy(() => import("./pages/Claim835/claim835New/index"));
const ClaimPaymentDND = lazy(() =>
  import("./pages/Claim835 copy/claim835New/index")
);

const UnderPayment = lazy(() => import("./pages/claim835UnderPayment/index"));
const ClaimDetail = lazy(() => import("./pages/Claim835Detail"));
const NewClaim835Detail = lazy(() => import("./pages/NewClaim835Detail"));

const PatientDetail = lazy(() => import("./pages/PatientDetail"));
const DenailByServiceLineDND = lazy(() =>
  import("./pages/Denials copy/DenialsDetailsPage/index")
);
const DenailByServiceLine = lazy(() =>
  import("./pages/Denials/DenialsDetailsPage/index")
);
const DenailByServiceLine2 = lazy(() =>
  import("./pages/Denials/DenialByServiceLine")
);
const PartiallyDeniedScreen = lazy(() =>
  import("./pages/Denials/PartiallyDenied")
);
const ReversalDenied = lazy(() => import("./pages/Denials/ReversalDenied"));
const Users = lazy(() => import("./pages/Users/NewUser"));
const Claim837 = lazy(() => import("./pages/Claim837/claim837New/index"));
const Claim837Detail = lazy(() => import("./pages/Claim837Detail"));
const NewClaim837Detail = lazy(() => import("./pages/NewClaim837Detail"));
const NewProClaimDetail = lazy(() =>
  import(
    "./pages/NewClaim837Detail/ProfessionalClaimDetail/ProfessionalDetails"
  )
);
const ProClaimDetail = lazy(() =>
  import("./pages/Claim837Detail/ProfessionalClaimDetail/ProfessionalDetails")
);
const ClaimAck = lazy(() => import("./pages/ClaimAck/index"));
const RARCDashboard = lazy(() => import("./pages/RARCDashboard/index"));
const PayerCPTAnalysis = lazy(() => import("./pages/PayerCPTAnalysis/index"));

const UploadDocoments = lazy(() => import("./pages/UploadDocoments/index"));
const CSVConfigration = lazy(() => import("./pages/CSVConfigration/index"));
const PDFViewCopilot = lazy(() => import("./pages/PDFViewer/PDFViewerCopilot"));
const PDFViewerDocument = lazy(() =>
  import("./pages/PDFViewer/PDFViewerDocument")
);

const DRMaintenance = lazy(() => import("./pages/DRMaintenance/index"));

const CallBack = lazy(() => import("./pages/CallBack"));
const LoginSSO = lazy(() => import("./pages/Auth/Login/LoginWithSSO"));

const ClientsScreen = lazy(() => import("./pages/ClientsScreen/index"));

const CustomProcedureCode = lazy(() =>
  import("./pages/CustomProcedureCode/index")
);

const DenialsCategory = lazy(() => import("./pages/DenialsCategory/index"));

const QuestionTemplate = lazy(() => import("./pages/QuestionTemplate/index"));
const ClaimProgress = lazy(() => import("./pages/ClaimProgress/index"));

const DataSource = lazy(() => import("./pages/DataSources/index"));
const LogsPage = lazy(() => import("./pages/Logs/index"));
const ActivityLog = lazy(() => import("./pages/ActivityLog/activityLog"));
const LoginAttempts = lazy(() => import("./pages/LoginAttempts"));
const Labels = lazy(() => import("./pages/labels"));
const Subscription = lazy(() => import("./pages/Subscription"));
const HealthSystem = lazy(() => import("./pages/HealthSystem"));
const DepartmentMain = lazy(() => import("./pages/Departments"));
const AdvanceSettings = lazy(() => import("./pages/Settings/AdvanceSettings"));
const ExceptionList = lazy(() => import("./pages/ExceptionList"));
const ReportsMain = lazy(() => import("./pages/Report/ReportsDefault"));
const RevenueByPayer = lazy(() => import("./pages/Report/RevenueByPayer"));
// const DrugDenialDetail = lazy(() =>
//   import("./pages/OncologyDashboard/DrugDenialDetail")
// );
const DrugDenialDetail = lazy(() => import("./pages/DragAndDropInt/index"));

const ClaimsDND = lazy(() => import("./pages/Claim837 copy/claim837New/index"));

const DenailsByPayer = lazy(() =>
  import("./pages/Report/DenialsByPayer/index")
);
const DrugsRejected = lazy(() => import("./pages/Report/DrugsRejected/index"));
const DurationWiseBuckets = lazy(() =>
  import("./pages/Claim837/DurationWiseBuckets/index")
);
const ServiceLineDenials = lazy(() =>
  import("./pages/Report/ServiceLineDenials")
);
const CARCTrends = lazy(() => import("./pages/CARCTrends"));
const PriorAuth = lazy(() => import("./pages/PriorAuth"));
const GraphDetails = lazy(() => import("./pages/GraphDetails"));
const MapboxTesting = lazy(() => import("./pages/MapboxTesting"));
const DragFromOutsideLayout = lazy(() =>
  import("./pages/DragDropLayout/SIdeDragDropLayout")
);
const GoalsTracking = lazy(() => import("./pages/GoalsTracking"));
const NevoCharts = lazy(() => import("./pages/Dashbaord/NevoCharts"));
const OncologyDashboard = lazy(() => import("./pages/OncologyDashboard"));
const BioSimilarDashboard = lazy(() =>
  import("./pages/BioSimilars/BoisimilarDashboard")
);
const DenialsByZIPCode = lazy(() => import("./pages/MapBox"));
const ReasonCodeHeatMap = lazy(() => import("./pages/ReasonCodeHeatMap"));
const RevenueHeatMap = lazy(() => import("./pages/RevenueHeatMap"));
const ReasonCode = lazy(() => import("./pages/ReasonCode"));
const ProcedureCode = lazy(() => import("./pages/ProcedureCode"));
const PayerDenialDashboard = lazy(() =>
  import("./pages/PayerDenialDashboard/index")
);

const CARCCategory = lazy(() => import("./pages/CARCCategory/index"));

const ChatBotResult = lazy(() => import("./pages/ChatBotResult/index"));

const VideoTutorial = lazy(() => import("./pages/VideoTutorial/index"));

const CARCDistribution = lazy(() => import("./pages/CARCDistribution/index"));

const FacilityDenials = lazy(() => import("./pages/FacilityDenials"));
const DenialsByCPTScreen = lazy(() => import("./pages/DenialsbyCPT"));
const DrugAccRate = lazy(() => import("./pages/DrugAccRate"));
const AlertCenter = lazy(() => import("./pages/AlertCenter"));
const AppealsQueueClaim = lazy(() => import("./pages/AppealsQueue/index"));
const AppealConfiguration = lazy(() =>
  import("./pages/AppealConfiguration/index")
);
const AetnaForm = lazy(() => import("./pages/AetnaForm/index"));

const AlertCenterDetails = lazy(() =>
  import("./pages/AlertCenter/AlertDetails/index")
);
const AllNotification = lazy(() =>
  import("./pages/AlertCenter/AllNotification/index")
);
const UploadClaim = lazy(() => import("./pages/UploadClaim/index"));

const SmartQueuesDashboard = lazy(() =>
  import("./pages/SmartQueuesDashboard/index")
);

const SmartQueuesSettings = lazy(() =>
  import("./pages/SmartQueuesSettings/index")
);

const AskAvaChat = lazy(() => import("./pages/AskAva/index"));
const AskLamaChat = lazy(() => import("./pages/AskAva/Lama"));

const AskZoeChat = lazy(() => import("./pages/AskZoe/index"));

const AskZoeExperimental = lazy(() => import("./pages/AskZoe/AskZoe2"));
const AskDomainChat = lazy(() => import("./pages/AskZoe/AskDomain"));
const AskCustom = lazy(() => import("./pages/AskZoe/AskCustom"));

const AskRoversChat = lazy(() => import("./pages/AskZoe/AskRovers2"));

const AppealsDashboard = lazy(() => import("./pages/AppealsDashboard/index"));

const QueueComments = lazy(() => import("./pages/ClaimQueue/QueueComments"));
const QueueCommentsOld = lazy(() =>
  import("./pages/ClaimQueue/QueueCommentsOld")
);
const DrugAccRateDetailTable = lazy(() =>
  import("./pages/DrugAccRate/DrugAccRateDetailTable/index")
);

const CARCTrendsDashboard = lazy(() =>
  import("./pages/CARCTrends/CARCTrendsDashboard")
);
const OncologyDenial = lazy(() =>
  import("./pages/CARCAnalysis/OncologyDenial")
);
const ProviderDashboard = lazy(() => import("./pages/ProviderDashboard"));
const PayerDashboard = lazy(() => import("./pages/PayerDashboard"));
const DepartmentDashboard = lazy(() =>
  import("./pages/DepartmentDashboard/index")
);

const NewPayerDashboard = lazy(() => import("./pages/PayerDashboardNew"));
const ClaimDashboard = lazy(() => import("./pages/ClaimDashboard"));
const ClaimQueue = lazy(() => import("./pages/ClaimQueue"));
const ClaimQueueDetail = lazy(() => import("./pages/ClaimQueue/QueueDetails"));
const QueueDetailsMyTask = lazy(() =>
  import("./pages/ClaimQueueMyTask/QueueDetails")
);
const DRSearchTable = lazy(() => import("./pages/Search/index"));
const Login = lazy(() => import("./pages/Auth/Login"));
const AppealAttachments = lazy(() => import("./pages/AppealAttachments"));
const AppealQueueDetails = lazy(() =>
  import("./pages/AppealsQueue/AppealQueueDetails")
);
// const ActivityTimeLine = lazy(() => import("./pages/ActivityTimeLine"));
const PolicyGuidelines = lazy(() => import("./pages/PolicyGuidelines"));
const ReasonCodeTrendsForPayerSceen = lazy(() =>
  import("./pages/ReasonCodeTrendsForPayer/index")
);
const CARCAnalysis = lazy(() => import("./pages/ReasonCode/CARCAnalysis"));
const DenialOverView = lazy(() => import("./pages/ReasonCode/DenialOverView"));

const CustomerConfigColumns = lazy(() =>
  import("./pages/CustomerConfigColumns/index")
);

const FilesIntegration = lazy(() => import("./pages/FilesIntegration/index"));

const DepartmentsDashboard = lazy(() =>
  import("./pages/DepartmentsDashboard/DepartmentCARC")
);
const DepartmentCPT = lazy(() =>
  import("./pages/DepartmentsDashboard/DepartmentCPT")
);
const DepartmentsRARC = lazy(() =>
  import("./pages/DepartmentsDashboard/DepartmentsRARC")
);

const CustomClaimStatusFeature = lazy(() =>
  import("./pages/CustomClaimStatusFeature")
);

import RequireAuth from "./components/common/RequireAuth/RequireAuth";
import RequireRoleBasedAuth from "./components/RequireRoleBasedAuth/RequireRoleBasedAuth";
import Loading from "./components/common/ExpandableTable/Loading";
import PublicRoute from "./Routes/publicRoute";
import NewEmailVerify from "./pages/Auth/NewEmailVerify";
// import DrugAccRateShowTable from "./pages/DrugAccRate/DrugAccRateShowTable";
// import "antd/dist/antd.css";
import SideBar from "./components/common/Sidebar/Sidebar";
import { environment, serverRoutes, SocketURL } from "./constants/serverRoutes";
import { QueryClient, QueryClientProvider } from "react-query";
import SessionTimeOut from "./pages/Auth/Login/SessionTimeOut";
import {
  HttpTransportType,
  HubConnectionBuilder,
  // LogLevel,
} from "@microsoft/signalr";
import RequireModuleBasedAuth from "./components/RequireRoleBasedAuth/RequireModuleBasedAuth";
import SideBarBot from "./components/common/Sidebar/SidebarBot";
import { SidebarMain } from "./components/common/Sidebar/SidebarMain";
import ChatBotResultNew from "./pages/ChatBotResultNew";
// import useRouteChangeListener from "./components/common/CustomHooks/useRouteChangeListener";

const DragAndDropDemo = lazy(() => import("./pages/DragAndDropDemo"));
const DragAndDropInt = lazy(() => import("./pages/DragAndDropInt"));

// import NewClaim835Detail from "./pages/NewClaim835Detail";
// import ActivityTimeLine from "./pages/ActivityTimeLine";

// import * as signalR from "@microsoft/signalr";
// import axios from "axios";
// import io from "socket.io-client";
// const socket = io("https://mwroversdevapp.azurewebsites.net/Notify");

const queryClient = new QueryClient();
const botRoutes = [
  browserRoute?.CHAT_BOT_RESULT,
  browserRoute?.CHAT_BOT_RESULT,
  browserRoute?.ASK_ZOE,
  browserRoute?.ASK_ROVERS,
  browserRoute?.DOMAIN_COPILOT,
  browserRoute?.ASK_CUSTOM,
  browserRoute?.UPLOAD_DOCOMENTS,
  browserRoute?.ASK_ZOE_CHAT,
];
function App() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const { userToken, loginType, loginUserData, subscriptionID, domainID } =
    useSelector((state) => state.pReducers.user);
  const [azureLoading, setAzureLoading] = useState(false);
  const [azureError, setAzureError] = useState(false);
  const [errorPage, setErrorPage] = useState(false);
  //SignalR
  const [connection, setConnection] = useState();
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  function getFaviconEl() {
    return document.getElementById("favicon");
  }

  const handleAuthenticatedMicrosoft = () => {
    setAzureLoading(true);
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        const accessToken = response?.accessToken;
        const email = response?.account?.username;
        const { tenantId } = response?.account;
        handleUserVeification({
          email: email,
          accessToken: accessToken,
          tenantId: tenantId,
          expiry: response?.expiresOn,
          setAzureLoading: setAzureLoading,
          dispatch: dispatch,
          instance: instance,
          setAzureError: setAzureError,
          azureError: azureError,
          history: history,
        });
      })
      .catch((error) => {
        setAzureError(true);
        setAzureLoading(false);
      });

    // setAzureLoading(false);
  };

  useEffect(() => {
    if (isAuthenticated && !userToken) {
      handleAuthenticatedMicrosoft();
    }
  }, [isAuthenticated]);
  // Token Refresh
  useEffect(() => {
    if (userToken) {
      const interval = setInterval(() => {
        // Code to be executed every 45 minutes
        if (loginType === "Local") {
          refreshToken();
        } else {
          refreshMsalToken();
        }
      }, 15 * 60 * 1000); // 15 minutes in milliseconds

      // Cleanup function to clear the interval on component unmount
      return () => {
        clearInterval(interval);
      };
    }
  }, [userToken]);
  //SignalR

  const joinRoom = async (userId, domainId) => {
    try {
      const connection = new HubConnectionBuilder()

        //.configureLogging(signalR.LogLevel.Information)
        .withAutomaticReconnect()

        .withUrl(SocketURL, HttpTransportType.WebSockets)
        .build();

      connection.on("ReceiveNotification", (userId, message, ...rest) => {
        if (
          message?.subscriptionId === subscriptionID &&
          message?.domainId === domainID
        ) {
          // console.log("hey khizer===", message);
          handleNewNotification(message);
          if (window.location.pathname === browserRoute?.ALERT_CENTER) {
            window.location.reload();
          }
        }
        setMessages((messages) => [...messages, { userId, message }]);
      });

      connection.on("UsersInRoom", (users) => {
        setUsers(users);
      });

      connection.onclose((e) => {
        setConnection();
        setMessages([]);
        setUsers([]);
      });

      await connection.start();
      await connection.invoke("JoinNotifyService", { userId, domainId });
      setConnection(connection);
    } catch (e) {
      // console.log("hey khizer error", e);
    }
  };
  useEffect(() => {
    if (userToken && loginUserData?.userId && loginUserData?.domainId) {
      joinRoom(loginUserData?.userId, loginUserData?.domainId);
      initialRenderNotification();
      initialRenderModules();
    }
  }, [userToken, loginUserData?.userId, loginUserData?.domainId]);

  useEffect(() => {
    const favicon = getFaviconEl(); // Accessing favicon element
    favicon.href =
      environment == "dev"
        ? "https://res.cloudinary.com/dcb0ivmhq/image/upload/v1697813839/logo-icon-color_m4fapx.png"
        : "https://res.cloudinary.com/dcb0ivmhq/image/upload/v1697813854/DR-logo-gr_mxomis.png";
  }, []);

  const memoizedSidebar = useMemo(() => {
    return <SideBar />;
  }, []);
  const memoizedSidebarBot = useMemo(() => {
    return <SideBarBot />;
  }, []);
  const memoizedSidebarMain = useMemo(() => {
    return <SidebarMain />;
  }, []);

  const refreshHandler = () => {
    if (loginType === "Local") {
      refreshToken();
    } else {
      refreshMsalToken();
    }
    // Replace the above line with the code you want to run every 10 minutes
  };
  const returnBotSideBar = () => {
    if (botRoutes?.includes(window.location.pathname)) {
      return memoizedSidebarBot;
    } else {
      return memoizedSidebar;
    }
  };
  // useEffect(() => {
  //   const lastExecutionTime = localStorage.getItem("lastExecutionTime");
  //   const currentTime = Date.now();
  //   const interval = 10 * 60 * 1000; // 10 minutes in milliseconds

  //   if (!lastExecutionTime || currentTime - lastExecutionTime >= interval) {
  //     refreshHandler();
  //     localStorage.setItem("lastExecutionTime", currentTime);
  //   }

  //   const intervalId = setInterval(() => {
  //     refreshHandler();
  //     localStorage.setItem("lastExecutionTime", Date.now());
  //   }, interval);

  //   return () => clearInterval(intervalId);
  // }, []);

  // useRouteChangeListener(); // This will enable route change blocking

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter basename={import.meta.env.DEV ? "/" : "/"}>
          {userToken &&
          !errorPage &&
          window.location.pathname !== browserRoute?.PDF_VIEW_COPILOT &&
          window.location.pathname !== browserRoute?.PDF_VIEW_DOCUMENT
            ? memoizedSidebarMain
            : null}
          <Suspense
            fallback={
              <Grid container>
                <Grid item xs={12}>
                  <div style={{ position: "relative" }}>
                    <div
                      className={"data-loading"}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "100vh",
                        background: "#f8fafa",
                      }}
                    >
                      <div
                        className="cliam-ui-table-2"
                        style={{
                          width: "100px",
                          height: "100px",
                        }}
                      >
                        <Loading />
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            }
          >
            <Routes>
              <Route
                index
                path={browserRoute.REACT_SERVER_DEFAULT_URL}
                element={
                  <Home
                    azureLoading={azureLoading}
                    setAzureLoading={setAzureLoading}
                    azureError={azureError}
                    setAzureError={setAzureError}
                  />
                }
              />
              <Route
                index
                path={browserRoute.DR_LANDING_PAGE_TWO}
                element={
                  <Home
                    azureLoading={azureLoading}
                    azureError={azureError}
                    setAzureError={setAzureError}
                  />
                }
              />

              {/* Screens with Admin and Login check */}
              <Route
                element={
                  <RequireRoleBasedAuth allowedRoles={["Administrator"]} />
                }
              >
                <Route
                  path={browserRoute.SETTING_DASHBOARD}
                  element={<Settings />}
                />

                <Route
                  path={browserRoute.Other_Links_Experimental}
                  element={<OthersLinksExperimental />}
                />
                <Route
                  path={browserRoute.DASHBOARD_LINKS}
                  element={<DashboardLinks />}
                />
                <Route
                  path={browserRoute.DATA_SOURCES}
                  element={<DataSource />}
                />
                <Route
                  path={browserRoute.GALLARY_FOR_TEST}
                  element={<NevoCharts />}
                />
                <Route
                  path={browserRoute.DRAG_AND_DROP}
                  element={<DragFromOutsideLayout />}
                />
                <Route
                  path={browserRoute.GRAPH_DETAILS}
                  element={<GraphDetails />}
                />
                <Route
                  path={browserRoute.CLAIM_PRIOR_AUTH}
                  element={<PriorAuth />}
                />
                <Route
                  path={browserRoute.MAPBOX_TESTING}
                  element={<MapboxTesting />}
                />

                <Route
                  path={browserRoute.LABELS_FOR_TEST}
                  element={<Labels />}
                />
                <Route
                  path={browserRoute.SUBSCRIPTION}
                  element={<Subscription />}
                />
                <Route
                  path={browserRoute.HEALTH_SYSTEM}
                  element={<HealthSystem />}
                />
                <Route
                  path={browserRoute.USERS_LOGIN_ATTEMPTS}
                  element={<LoginAttempts />}
                />
                <Route
                  path={browserRoute.SETTING_ADVANCE_SETTING}
                  element={<AdvanceSettings />}
                />
                <Route
                  path={browserRoute.USERS_ACTIVITY_LOG}
                  element={<ActivityLog />}
                />
                <Route path={browserRoute.USERS_LIST} element={<Users />} />
                <Route
                  path={browserRoute.EXCEPTION_LIST}
                  element={<ExceptionList />}
                />
                <Route
                  path={browserRoute.DEPARTMENT_MAIN}
                  element={<DepartmentMain />}
                />
              </Route>

              {/* Screens without check */}

              <Route element={<PublicRoute />}>
                <Route
                  path={browserRoute.AUTH_CODE_VERIFICATION}
                  element={<Verification />}
                />
                <Route path={browserRoute.CALLBACK} element={<CallBack />} />
                <Route path={browserRoute.LOGINSSO} element={<LoginSSO />} />

                <Route path="/sessionTimeout" element={<SessionTimeOut />} />

                <Route
                  path={browserRoute.NEW_TANENT_EMAIL_VERIFICATION}
                  element={<NewEmailVerify />}
                />

                <Route path={browserRoute.LOGIN} element={<Login />} />
                <Route path={browserRoute.REGISTER} element={<Register />} />
                <Route
                  path={browserRoute.FORGOT_PASSWORD}
                  element={<Forgotpassword />}
                />
                <Route
                  path={browserRoute.CHANGE_PASSWORD}
                  element={<ForgetEmail />}
                />
              </Route>

              {/* screens with login check  */}
              <Route element={<RequireAuth />}>
                <Route path="/dash" element={<Dashboard />} />

                <Route
                  path={browserRoute.CLAIM_PAYMENT_835}
                  // element={<Payment />}
                  element={
                    environment === "dev" ? <ClaimPaymentDND /> : <Payment />
                  }
                />
                <Route
                // path={browserRoute.CLAIM_PAYMENT_835_DND}
                // element={<ClaimPaymentDND />}
                />
                <Route
                  path={browserRoute.CLAIM_MANAGER_837_DND}
                  element={<ClaimsDND />}
                />

                <Route
                  path={browserRoute.CLAIM_PAYMENT_835_UNDER_PAYMENT}
                  element={<UnderPayment />}
                />
                <Route
                  path={browserRoute.PATIENT_DETAIL_BY_ID}
                  element={<PatientDetail />}
                />
                <Route
                  path={browserRoute.CLAIM_PAYMENT_835_DETAIL}
                  // element={<ClaimDetail />}
                  element={<NewClaim835Detail />}
                />

                <Route
                  path={browserRoute.OLD_CLAIM_PAYMENT_835_DETAIL}
                  element={<ClaimDetail />}
                />

                <Route
                  path={browserRoute.DENIALS_DETAIL}
                  // element={<DenailByServiceLine />}
                  element={
                    environment === "dev" ? (
                      <DenailByServiceLineDND />
                    ) : (
                      <DenailByServiceLine />
                    )
                  }
                />
                <Route
                  path={browserRoute.DENAIL_BY_SERVICELINE}
                  element={<DenailByServiceLine2 />}
                />
                <Route
                  path={browserRoute.PARIALLY_DENIED}
                  element={<PartiallyDeniedScreen />}
                />
                <Route
                  path={browserRoute.REVERSAL_DENIED}
                  element={<ReversalDenied />}
                />
                <Route
                  path={browserRoute.CLAIM_MANAGER_837}
                  element={<ClaimsDND />}

                  // element={<Claim837 />}
                  // element={environment === "dev" ? <ClaimsDND /> : <Claim837 />}
                />
                <Route path={browserRoute.CLAIM_ACK} element={<ClaimAck />} />

                <Route path={browserRoute.LOGS_PAGE} element={<LogsPage />} />

                <Route
                  path={browserRoute.DR_SEARCH}
                  element={<DRSearchTable />}
                />

                <Route
                  path={browserRoute.BIOSIMILAR_DASHBOARD}
                  element={<BioSimilarDashboard />}
                />
                <Route
                  path={browserRoute.DENIALS_BY_ZIP_CODE}
                  element={<DenialsByZIPCode />}
                />

                <Route
                  path={browserRoute.BIOSIMILAR_DASHBOARD}
                  element={<BioSimilarDashboard />}
                />
                <Route
                  path={browserRoute.DENIALS_BY_ZIP_CODE}
                  element={<DenialsByZIPCode />}
                />

                <Route
                  // path={browserRoute.CLAIM_MANAGER_837_DETAIL}
                  path={browserRoute.NEW_CLAIM_MANAGER_837_DETAIL}
                  element={<Claim837Detail />}
                />

                <Route
                  path={browserRoute.CLAIM_MANAGER_837_DETAIL}
                  element={<NewClaim837Detail />}
                />

                {/* <Route
                  path={browserRoute.NEW_CLAIM_MANAGER_837_PRO_DETAIL}
                  element={<ProClaimDetail />}
                /> */}

                <Route
                  path={browserRoute.CLAIM_MANAGER_837_PRO_DETAIL}
                  element={<NewProClaimDetail />}
                />
                <Route
                  path={browserRoute.REPORTS_DASHBOARD}
                  element={<ReportsMain />}
                />
                <Route
                  path={browserRoute.DENAILS_BY_PAYER}
                  element={<DenailsByPayer />}
                />
                <Route
                  path={browserRoute.REPORTS_REVENUE_BY_PAYER}
                  element={<RevenueByPayer />}
                />
                <Route
                  path={browserRoute.REPORTS_DRUGS_REJECTED}
                  element={<DrugsRejected />}
                />
                <Route
                  path={browserRoute.ALERT_CENTER}
                  element={<AlertCenter />}
                />
                <Route
                  path={`${browserRoute.ALERT_CENTER_Details}/:id`}
                  element={<AlertCenterDetails />}
                />

                <Route
                  path={browserRoute.RACR_DASHBOARD}
                  element={<RARCDashboard />}
                />

                <Route
                  path={browserRoute.PAYER_CPT_ANALYSIS}
                  element={<PayerCPTAnalysis />}
                />

                <Route
                  path={browserRoute.UPLOAD_DOCOMENTS}
                  element={<UploadDocoments />}
                />

                <Route
                  path={browserRoute.CSV_CONFIGRATION}
                  element={<CSVConfigration />}
                />

                <Route
                  path={browserRoute.PDF_VIEW_COPILOT}
                  element={<PDFViewCopilot />}
                />
                <Route
                  path={browserRoute.PDF_VIEW_DOCUMENT}
                  element={<PDFViewerDocument />}
                />
                <Route
                  path={browserRoute.SYSTEM_MAINTENANCE}
                  element={<DRMaintenance errSetter={setErrorPage} />}
                />

                <Route
                  path={browserRoute.COSTOM_STATUS_CODES_SCREEN}
                  element={<CustomClaimStatusFeature />}
                />

                <Route
                  path={browserRoute.CLIENTS}
                  element={<ClientsScreen />}
                />

                <Route
                  path={browserRoute.CUSTOM_PROCEDURE_CODE}
                  element={<CustomProcedureCode />}
                />

                <Route
                  path={browserRoute.DENIALS_CATEGORY}
                  element={<DenialsCategory />}
                />

                <Route
                  path={browserRoute.QUESTION_TEMPLATE}
                  element={<QuestionTemplate />}
                />

                <Route
                  path={browserRoute.CLAIM_PROGRESS}
                  element={<ClaimProgress />}
                />

                {/* Appeals Module Authorization */}
                <Route
                  element={
                    <RequireModuleBasedAuth
                      allowedRoles={[moduleRolesEnum?.Appeals]}
                    />
                  }
                >
                  <Route
                    path={browserRoute.APPEALS_QUEUE_CLAIM}
                    element={<AppealsQueueClaim />}
                  />
                  <Route
                    path={browserRoute.APPEAL_QUEUE_DETAIL}
                    element={<AppealQueueDetails />}
                  />
                  <Route
                    path={`${browserRoute.ATTACHMENTS_PREVIEW}/:claimPaymentId/:payerClaimControlNo/:id/:type/:name/:showAppeal`}
                    element={<AppealAttachments />}
                  />
                </Route>
                {/* Appeals Module Authorization End */}
                {/* Denials Module Authorization */}
                <Route
                  element={
                    <RequireModuleBasedAuth
                      allowedRoles={[moduleRolesEnum?.Denials]}
                    />
                  }
                >
                  <Route
                    path={browserRoute.REASON_CODE_HEATMAP}
                    element={<ReasonCodeHeatMap />}
                  />
                  <Route
                    path={browserRoute.DEPARTMENTS_DASHBOARD}
                    element={<DepartmentsDashboard />}
                  />
                  <Route
                    path={browserRoute.DEPARTMENTS_CPT}
                    element={<DepartmentCPT />}
                  />
                  <Route
                    path={browserRoute.DEPARTMENTS_RARC}
                    element={<DepartmentsRARC />}
                  />

                  <Route
                    path={browserRoute.REVENUE_HEATMAP}
                    element={<RevenueHeatMap />}
                  />
                  <Route
                    path={browserRoute.ONCOLOGY_DASHBOARD}
                    element={<OncologyDashboard />}
                  />
                  <Route
                    path={browserRoute.ONCOLOGY_DENIAL}
                    element={<OncologyDenial />}
                  />
                  <Route
                    path={browserRoute.CARC_TRENDS_DASHBOARD}
                    element={<CARCTrendsDashboard />}
                  />
                  <Route
                    path={browserRoute.CARC_TRENDS}
                    element={<CARCTrends />}
                  />
                  <Route
                    path={browserRoute.DRUG_ACC_RATE}
                    element={<DrugAccRate />}
                  />
                  <Route
                    path={browserRoute.DRUG_ACC_RATE_DETAIL}
                    element={<DrugAccRateDetailTable />}
                  />
                  <Route
                    path={browserRoute.DENIALS_BY_CPT_SCREEN}
                    element={<DenialsByCPTScreen />}
                  />
                  <Route
                    path={browserRoute.CLAIM_QUEUE}
                    element={<ClaimQueue />}
                  />
                  <Route
                    path={browserRoute.SSO_SCREEN}
                    element={<SSOScreen />}
                  />
                  <Route
                    path={browserRoute.API_SCREEN}
                    element={<APIScreen />}
                  />
                </Route>
                {/* Denials Module Authorization End */}

                <Route
                  path={browserRoute.APPEALS_CONFIG}
                  element={<AppealConfiguration />}
                />

                <Route path={browserRoute.AETNA_FORM} element={<AetnaForm />} />

                <Route
                  path={browserRoute.ALL_NOTIFICATION}
                  element={<AllNotification />}
                />

                <Route
                  path={browserRoute.UPLOAD_CLAIM_NEW}
                  element={<UploadClaim />}
                />

                <Route
                  path={browserRoute.SMART_QUEUES_DASHBOARD}
                  element={<SmartQueuesDashboard />}
                />

                <Route
                  path={browserRoute.SMART_QUEUES_SETTINGS}
                  element={<SmartQueuesSettings />}
                />

                <Route
                  element={
                    <RequireModuleBasedAuth
                      allowedRoles={[moduleRolesEnum?.Policy]}
                    />
                  }
                >
                  {/* <Route path={browserRoute.ASK_AVA} element={<AskAvaChat />} /> */}
                  <Route
                    path={browserRoute.ASK_AVA}
                    element={<AskLamaChat />}
                  />
                  <Route path={browserRoute.ASK_ZOE} element={<AskZoeChat />} />
                  <Route
                    path={browserRoute.ASK_ZOE_CHAT}
                    element={<AskPolicyChat />}
                  />

                  <Route
                    path={browserRoute.ASK_ZOE2}
                    element={<AskZoeExperimental />}
                  />

                  <Route
                    path={browserRoute.DOMAIN_COPILOT}
                    element={<AskDomainChat />}
                  />
                  <Route
                    path={browserRoute.ASK_CUSTOM}
                    element={<AskCustom />}
                  />

                  <Route
                    path={browserRoute.ASK_ROVERS}
                    element={<AskRoversChat />}
                  />
                </Route>

                <Route
                  path={browserRoute.REASON_CODE_TRENDS_FOR_PAYER}
                  element={<ReasonCodeTrendsForPayerSceen />}
                />

                {/* <Route
                  path={browserRoute.ACTIVITY_TIMELINE}
                  element={<ActivityTimeLine />}
                /> */}

                <Route
                  path={browserRoute.POLICY_GUIDELINES}
                  element={<PolicyGuidelines />}
                />

                <Route
                  path={browserRoute.CUSTOMER_CONFIG_COLUMNS}
                  element={<CustomerConfigColumns />}
                />

                <Route
                  path={browserRoute.FILES_INTIGRATION}
                  element={<FilesIntegration />}
                />

                <Route
                  path={browserRoute.APPEALS_DASHBOARD}
                  element={<AppealsDashboard />}
                />
                {/* 
                <Route
                  path={browserRoute.Queue_COMMENTS}
                  element={
                    environment === "dev" ? (
                      <QueueComments />
                    ) : (
                      <QueueCommentsOld />
                    )
                  }
                /> */}

                <Route
                  path={browserRoute.Queue_COMMENTS}
                  element={<QueueComments />}
                />
                <Route
                  path={browserRoute.CLAIM_MANAGER_837_CLAIM_AGING_REPORT}
                  element={<DurationWiseBuckets />}
                />
                <Route
                  path={browserRoute.REPORTS_SERVICE_LINE_DENIALS}
                  element={<ServiceLineDenials />}
                />

                <Route
                  path={browserRoute.GOALS_TRAKING}
                  element={<GoalsTracking />}
                />

                <Route
                  path={browserRoute.REASON_CODE}
                  element={<ReasonCode />}
                />
                {/* <Route
                  path={browserRoute.CARC_DISTRIBUTION_ANALYSIS}
                  element={<CARCAnalysis />}
                /> */}
                <Route
                  path={browserRoute.CODE_EXPLORER_DASHBOARD}
                  element={<CARCAnalysis />}
                />
                <Route
                  path={browserRoute.DENIAL_OVERVIEW}
                  element={<DenialOverView />}
                />
                <Route
                  path={browserRoute.PROCEDURE_CODE}
                  element={<ProcedureCode />}
                />
                <Route
                  path={browserRoute.PAYER_DENIAL_DASHBOARD}
                  element={<PayerDenialDashboard />}
                />

                <Route
                  path={browserRoute.CHAT_BOT_RESULT}
                  element={<ChatBotResult />}
                />

                <Route
                  path={browserRoute.CHAT_BOT_RESULT_NEW}
                  element={<ChatBotResultNew />}
                />

                <Route
                  path={browserRoute.VIDEO_TUTORIAL}
                  element={<VideoTutorial />}
                />

                <Route
                  path={browserRoute.CARC_CATEGORY}
                  element={<CARCCategory />}
                />

                <Route
                  path={browserRoute.CARC_DISTRIBUTOIN}
                  element={<CARCDistribution />}
                />

                <Route
                  path={browserRoute.FACITILY_DENIALS}
                  element={<FacilityDenials />}
                />
                <Route
                  path={browserRoute.PROVIDER_DASHBOARD}
                  element={<ProviderDashboard />}
                />
                <Route
                  path={browserRoute.INSIGHTS_PAYER_DASHBOARD}
                  element={<PayerDashboard />}
                />

                <Route
                  path={browserRoute.DEPARTMENT_DASHBOARD}
                  element={<DepartmentDashboard />}
                />

                <Route
                  path={browserRoute.NEW_INSIGHTS_PAYER_DASHBOARD}
                  element={<NewPayerDashboard />}
                />
                <Route
                  path={browserRoute.CLAIMS_DASHBOARD}
                  element={<ClaimDashboard />}
                />
                <Route path={browserRoute.NEW_HOME} element={<NewHome />} />
                <Route path={browserRoute.AI_HOME} element={<AiHome />} />
                <Route
                  path={browserRoute.APPEAL_HOME}
                  element={<AppealHome />}
                />

                <Route
                  path={browserRoute.CLAIM_QUEUE_DETAIL}
                  element={<ClaimQueueDetail />}
                />

                <Route
                  path={browserRoute.CLAIM_QUEUE_DETAIL_MY_TASK}
                  element={<QueueDetailsMyTask />}
                />

                <Route
                  path={browserRoute.DRUG_DENIAL_DETAIL}
                  // element={<DrugDenialDetail />}
                  element={<DrugDenialDetail />}
                />
                <Route
                  path={browserRoute.DRAG_AND_DROP_DEMO}
                  element={<DragAndDropDemo />}
                />
                <Route
                  path={browserRoute.DRAG_AND_DROP_INT}
                  element={<DragAndDropInt />}
                />
                <Route path={browserRoute.PROFILE} element={<Profile />} />
              </Route>

              <Route
                path={browserRoute.QUEUES_BUCKET}
                element={<QueuesBucket />}
              />

              <Route path="*" element={<Error404 errSetter={setErrorPage} />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </QueryClientProvider>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        // autoClose={false}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;

import React from "react";

const V2LeftNavIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      width="24"
      height="24"
      stroke-width="1"
    >
      {" "}
      <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>{" "}
      <path d="M9 4v16"></path> <path d="M15 10l-2 2l2 2"></path>{" "}
    </svg>
    // <svg
    //   width="24"
    //   height="24"
    //   viewBox="0 0 24 24"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75C6.07 22.75 1.25 17.93 1.25 12ZM21.25 12C21.25 6.9 17.1 2.75 12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12Z"
    //     fill="#007a56"
    //   />
    //   <path
    //     d="M8.99008 12C8.99008 11.81 9.06008 11.62 9.21008 11.47L12.7401 7.94C13.0301 7.65 13.5101 7.65 13.8001 7.94C14.0901 8.23 14.0901 8.71 13.8001 9L10.8001 12L13.8001 15C14.0901 15.29 14.0901 15.77 13.8001 16.06C13.5101 16.35 13.0301 16.35 12.7401 16.06L9.21008 12.53C9.06008 12.38 8.99008 12.19 8.99008 12Z"
    //     fill="#007a56"
    //   />
    // </svg>
  );
};

export default V2LeftNavIcon;

import { Grid, TextField } from "@mui/material";
import { Popover } from "antd";
import React, { useEffect, useState } from "react";
import BootstrapTooltip from "../../components/common/TooltipUI/customToolTip";
import DRDisLikeIcon from "../../assets/ZoeImages/DRDisLikeIcon";
import DRLikeIcon from "../../assets/ZoeImages/DRLikeIcon";
import { returnUserId } from "../../components/common/Utils/utils";
import { useSelector } from "react-redux";
import { preserveChat } from "../../components/common/Utils/copilot";
import CommentSendGreenZoe from "../../assets/svgs/CommentSendGreenZoe";
import CommentSendIcon from "../../assets/svgs/CommentSendIcon";

const FeedbackPolicyCopilot = ({
  item,
  generateHistoryMessageFeedback,
  index,
  mainOutput,
  setMainOutput,
}) => {
  const feedbackEnum = {
    like: "like",
    dislike: "dislike",
  };
  const dislikeOptions = [
    "Response was incorrect",
    "Don't like style",
    "Not factually correct",
    "Didn't fully follow instructions",
    "Refused when it shouldn't have",
    "Being Lazy",
    "Unsafe or problematic",
  ];
  const { domainID } = useSelector((state) => state.pReducers.user);
  const [open, setOpen] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [input, setInput] = useState("");
  const hide = () => {
    setOpen(false);
    setInput("");
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const sendFeedback = (type, reason = "") => {
    const feedbackPayload = {
      ChatId: item?.guid,
      UserId: returnUserId(),
      DomainId: domainID,
      CopilotType: "policy",
      Message: {
        Question: item?.userMsg,
        Answer: item?.msg,
        Reason: reason,
        Feedback: type,
      },
    };
    setFeedback(type);
    const output = [...mainOutput];
    const tempObj = {
      ...output[index],
      feedbackType: type,
      feedbackReason: reason,
    };
    output[index] = tempObj;
    setMainOutput(output);

    preserveChat(feedbackPayload);
    if (generateHistoryMessageFeedback) {
      generateHistoryMessageFeedback(output);
    }
  };
  const handleLikeFeedback = () => {
    sendFeedback(feedback === feedbackEnum?.like ? "" : feedbackEnum?.like);
  };
  const handleDislikeFeedback = (reason) => {
    sendFeedback(
      feedback === feedbackEnum?.dislike ? "" : feedbackEnum?.dislike,
      feedback === feedbackEnum?.dislike ? "" : reason
    );
    hide();
  };
  const handleOpen = () => {
    if (feedback === feedbackEnum?.dislike) {
      sendFeedback("", "");
    } else {
      setOpen(true);
    }
  };
  const handleSubmitInput = () => {
    if (input?.trim()) {
      handleDislikeFeedback(input);
    }
  };

  useEffect(() => {
    if (!open) {
      setInput("");
    }
  }, [open]);

  return (
    <>
      {item?.feedback && (
        <>
          <button
            style={{
              // backgroundColor: "#fff",
              background: "none",
              boxShadow: "none",
              cursor: "pointer",
              top: "40px",
            }}
            className="Zoe-copy-btn"
            onClick={handleLikeFeedback}
          >
            <BootstrapTooltip title="Like">
              <span className="likesvg">
                <DRLikeIcon fill={feedback === feedbackEnum?.like && true} />
              </span>
            </BootstrapTooltip>
          </button>
          <Popover
            open={open}
            overlayStyle={{ zIndex: "1610" }}
            content={
              <div>
                <Grid
                  container
                  direction="row"
                  alignItems="stretch"
                  spacing={3}
                >
                  <Grid item sm={12}>
                    <ul className="DR-Bad-comment-UI mt-4">
                      {dislikeOptions?.map((option, index) => {
                        return (
                          <li
                            onClick={() => {
                              handleDislikeFeedback(option);
                            }}
                            key={index}
                          >
                            {option}
                          </li>
                        );
                      })}
                    </ul>
                    <div className="mb-4 mt-4 ">
                      <lable className="vel-input-lable">Comment</lable>
                      <TextField
                        className="DR-new-input-mui-lb DR-question-multiline feed-input"
                        placeholder="Add Your Comment"
                        variant="filled"
                        value={input}
                        onChange={(e) => {
                          setInput(e.target.value);
                        }}
                        fullWidth
                        multiline
                        minRows={3}
                        maxRows={3}
                      />
                      <button
                        onClick={handleSubmitInput}
                        className={`${
                          input?.length
                            ? "DR-input-icon-color-Zoe copliot-feeb-sendbtn"
                            : "copliot-feeb-sendbtn"
                        }`}
                        disabled={!input?.length}
                      >
                        <BootstrapTooltip title="Send Message">
                          <span>
                            {input?.length ? (
                              <CommentSendGreenZoe />
                            ) : (
                              <CommentSendIcon />
                            )}
                          </span>
                        </BootstrapTooltip>
                      </button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            }
            onOpenChange={handleOpenChange}
            trigger="click"
            placement="topRight"
            overlayClassName="DR-like-comment-main"
          >
            <button
              style={{
                // backgroundColor: "#fff",

                background: "none",
                boxShadow: "none",
                cursor: "pointer",
                top: "80px",
              }}
              className="Zoe-copy-btn"
              onClick={handleOpen}
            >
              <BootstrapTooltip title="Bad Response">
                <span className="dislikesvg">
                  <DRDisLikeIcon
                    fill={feedback === feedbackEnum?.dislike && true}
                  />
                </span>
              </BootstrapTooltip>
            </button>
          </Popover>
        </>
      )}
    </>
  );
};

export default FeedbackPolicyCopilot;

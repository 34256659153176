import { createSlice } from '@reduxjs/toolkit';
import { serverRoutes } from '../../../constants/serverRoutes';
import genericAxiosCall from '../../../AxiosConfig/genericAxiosCall';

import {
  setListingState,
  resetListingState,
} from '../filters/claimManagerFilters';
import {
  commonPagination,
  getLegendsExtraShortName,
  getLegendsShortName,
} from '../../../components/common/Utils/utils';

export const DenialsDashboardSlice = createSlice({
  name: 'DenialsDashboardSlice',
  initialState: {
    denailCountAmount: [],
    denailCountAmountCPTArr: [],
    denailCountAmountICD10Arr: [],
    denialOverviewCARCArr: [],
    denialPayersArr: [],
    denialCard: [],
    denialTimeLine: [],
  },
  reducers: {
    setDenialsDashBaord_data: (state, action) => {
      let data = action.payload;
      //handle graph data
      if (data?.denialTimeLine?.length) {
        let res = data?.denialTimeLine.map((item, index) => {
          return {
            'gained > 100$': item?.paymentAmount,
            // "gained <= 100$": gain,
            'lost <= 100$': -item?.denialAmount,
            // "lost > 100$": -highLoss,
            user: getLegendsExtraShortName(item?.payerName),
            item: item,
          };
        });
        state.denialTimeLine = res;
      } else {
        state.denialTimeLine = [];
      }
      if (data?.denailCountAmountCPT?.length) {
        // handle for denailCountAmountCPT array here
        state.denailCountAmountCPTArr = data?.denailCountAmountCPT;
      } else {
        state.denailCountAmountCPTArr = [];
      }

      // handle for denailCountAmountICD10 array here
      if (data?.denailCountAmountICD10?.length) {
        state.denailCountAmountICD10Arr = data?.denailCountAmountICD10;
      } else {
        state.denailCountAmountICD10Arr = [];
      }

      // handle for denailCountAmount array here
      if (data?.denailCountAmount?.length) {
        state.denailCountAmount = data?.denailCountAmount[0];
      } else {
        state.denailCountAmount = [];
      }
      if (data?.denialCard?.length) {
        state.denialCard = data?.denialCard[0];
      } else {
        state.denialCard = [];
      }

      // handle for denialPayers array here
      if (data?.denialPayers?.length) {
        let list = [];
        data?.denialPayers?.forEach((item) => {
          //   let name = item?.payerName.split(" ");
          //   var payerName = name[0];
          //   if (typeof name[1] !== "undefined") {
          //     payerName = payerName + " " + name[1];
          //   }
          //   if (typeof name[2] !== "undefined") {
          //     payerName = payerName + "...";
          //   }
          list.push({
            payer: item?.payerName,
            payer_Charge_Amount: item?.payer_Charge_Amount,
            payer_Denial_Count: item?.payer_Denial_Count,
          });
        });

        state.denialPayersArr = list;
      } else {
        state.denialPayersArr = [];
      }

      // handle for denialCARC array here

      if (data?.denialCARC?.length) {
        state.denialOverviewCARCArr = data?.denialCARC;
      } else {
        state.denialOverviewCARCArr = [];
      }
    },
  },
});

export const { setDenialsDashBaord_data } = DenialsDashboardSlice.actions;

// get denials dash list
export const gettingDenialsDashboardKpis_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: 'denialsDashboardFilter',
          //   ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.denialsDashboardFilter;

    try {
      dispatch(
        setListingState({
          listing: 'denialsDashboardFilter',
          value: {
            loadingMore: 'data-loading',
          },
        })
      );

      ///// API CALL //////

      let params = {
        ...(sliceState?.from !== '' && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== '' && {
          to: sliceState.to,
        }),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_DENIALS_DASH_KPIS,
        'get',
        '',
        params
      );

      ////// API CALL END //////////////

      if (!res?.data?.success) {
        // toast.error(`Something went wrong`, {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        dispatch(setDenialsDashBaord_data([]));
        dispatch(
          setListingState({
            listing: 'exectiveDashboardFilter',
            value: {
              loadingMore: '',
            },
          })
        );
      } else {
        dispatch(setDenialsDashBaord_data(res?.data?.data));
        dispatch(
          setListingState({
            listing: 'exectiveDashboardFilter',
            value: {
              // hasMore: res?.data?.data?.length === sliceState.pageSize,
              loadingMore: '',
            },
          })
        );
      }
      return res;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: 'exectiveDashboardFilter',
          value: {
            initialLoading: false,
            loadingMore: '',
            hasMore: false,
          },
        })
      );

      dispatch(setDenialsDashBaord_data([]));
      return false;
    }
  };
// get denials dash list
export const gettingDenialsDashboardKpis_Call2 =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: 'denialsDashboardFilter',
          //   ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.denialsDashboardFilter;

    try {
      ///// API CALL //////
      let paramsTemp = {
        ...(sliceState?.from !== '' && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== '' && {
          to: sliceState.to,
        }),
      };
      let params = {
        ...(sliceState?.from !== '' && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== '' && {
          to: sliceState.to,
        }),
      };
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setDenialsDashBaord_data,
        'denialsDashboardFilter',
        'GET_DENIALS_DASH_KPIS',
        null
      );

      ////// API CALL END //////////////
    } catch (e) {
      // console.log("e--->>", e);
    }
  };
// get denials dash list
export const gettingCARCTrendsDashboardKpis_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: 'carcTrendsDashboardFilter',
          ignore: ['claimStatus', 'payers', 'frequencyType', 'search'],
        })
      );
    }

    let sliceState = getState().npReducers.filters.carcTrendsDashboardFilter;

    try {
      dispatch(
        setListingState({
          listing: 'carcTrendsDashboardFilter',
          value: {
            loadingMore: 'data-loading',
          },
        })
      );

      ///// API CALL //////

      let params = {
        ...(sliceState?.from !== '' && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== '' && {
          to: sliceState.to,
        }),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_DENIALS_DASH_KPIS,
        'get',
        '',
        params
      );

      ////// API CALL END //////////////

      return res;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: 'carcTrendsDashboardFilter',
          value: {
            loadingMore: '',
          },
        })
      );

      dispatch(setDenialsDashBaord_data([]));
      return false;
    }
  };

export default DenialsDashboardSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const errorSlice = createSlice({
  name: "errorSlice",
  initialState: {
    message: "",
    type: "",
    interval: 2000,
  },
  reducers: {
    setMessage: (state, action) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.interval = action.payload.interval
        ? action.payload.interval
        : state.interval;
    },
  },
});

export const { setMessage } = errorSlice.actions;

export default errorSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import users from "../../../actions/user";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";
import { serverRoutes } from "../../../constants/serverRoutes";
import {
  setAllSubscriptionModules,
  setDenialCoordinatorList,
  updateBoughtModules,
  updateMasterListCodes,
  updateMasterListReasonCodes,
  updateModulePricing,
  updateModuleUsage,
  updateUserModuleInfo,
} from "../UserReducer";
import axios from "axios";
import user from "../../../actions/user";
import {
  ErrorToast,
  moduleRolesEnum,
  moduleRolesEnumPolicy,
  subscriptionPlanNameKey,
  SuccessToast,
  UnSuccessfullToast,
} from "../../../components/common/Utils/utils";
import { setListingState } from "../filters/claimManagerFilters";

export const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    user: "",
    openApi: {
      loading: false,
      data: [],
    },
    samlInfo: {},
  },
  reducers: {
    userAction: (state, action) => {
      state.city = action.payload;
    },
    gettingOpenApi: (state, action) => {
      state.openApi.loading = true;
    },
    successOpenApi: (state, action) => {
      const data = action.payload;
      state.openApi.data = data;
      state.openApi.loading = false;
    },
    failedOpenApi: (state, action) => {
      state.openApi.loading = false;
    },
    successSamlInfo: (state, action) => {
      const data = action.payload;
      if (Object.keys(data)?.length) {
        state.samlInfo = data;
      } else {
        state.samlInfo = {};
      }
    },
  },
});

export const {
  userAction,
  gettingOpenApi,
  successOpenApi,
  failedOpenApi,
  successSamlInfo,
} = userSlice.actions;

export const userRegisterationCall = async (values) => {
  return await users.register(values);
};
export const updateMasterListCodesFunction =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    try {
      ///// API CALL //////

      const res = await genericAxiosCall(
        serverRoutes?.MASTER_LIST_CODES,
        "get",
        "",
        ""
      );
      if (res?.data?.success) {
        const data = res?.data?.data;
        let obj = {};
        let objReasonCodes = {};
        data?.cptCode?.map((item) => {
          obj[item?.code.replace(/\s+/g, " ").trim()] = item;
        });
        data?.reasonCodes?.map((item) => {
          objReasonCodes[item?.code] = item;
        });
        dispatch(updateMasterListReasonCodes(objReasonCodes));
        dispatch(updateMasterListCodes(obj));
      }
      ////// API CALL END //////////////

      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };
export const getAllDenialCoordinator =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    try {
      ///// API CALL //////
      const params = {
        timestamp: new Date()?.getTime(),
      };
      const res = await genericAxiosCall(
        serverRoutes?.APPEAL_CONFIG_LIST,
        "get",
        "",
        params
      );
      if (res?.data?.success) {
        const data = res?.data?.data;
        dispatch(setDenialCoordinatorList(data));
      }
      ////// API CALL END //////////////
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };

export const getAllSubsriptionModules =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    try {
      ///// API CALL //////
      const res = await genericAxiosCall(
        serverRoutes?.GET_ALL_SUBSCRIBED_MODULES,
        "get",
        "",
        ""
      );
      // const res = await axios.get(serverRoutes?.GET_ALL_SUBSCRIBED_MODULES);
      if (res?.data?.success) {
        const data = res?.data?.data;
        let allModulesObj = {};

        data?.map(
          (item) => (allModulesObj[item?.moduleName] = item?.moduleName)
        );
        dispatch(
          setAllSubscriptionModules({
            allSubscriptionModules: data,
            allModulesObj: allModulesObj,
          })
        );

        // dispatch(setDenialCoordinatorList(data));
      }
      ////// API CALL END //////////////
    } catch (e) {
      return false;
    }
  };
export const getAllBoughtModules =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    try {
      let { domainId } = getState().pReducers.user.loginUserData;
      ///// API CALL //////
      const params = { domainId: domainId, timestamp: new Date().getTime() };
      const res = await genericAxiosCall(
        serverRoutes?.GET_ALL_BOUGHT_MODULES,
        "get",
        "",
        params
      );
      if (res?.data?.success) {
        const data = res?.data?.data;
        await dispatch(updateBoughtModules(data));
        // data?.map(
        //   (item) => (allModulesObj[item?.moduleName] = item?.moduleName)
        // );
        // dispatch(
        //   setAllSubscriptionModules({
        //     allSubscriptionModules: data,
        //     allModulesObj: allModulesObj,
        //   })
        // );

        // dispatch(setDenialCoordinatorList(data));
      }
      ////// API CALL END //////////////
    } catch (e) {
      return false;
    }
  };
const filterPricingModules = (data) => {
  if (data?.length) {
    let obj = {};
    Object.keys(moduleRolesEnum)?.map((item) => {
      const key = moduleRolesEnum[item];
      let findData = data?.find((item2) => item2?.Module?.includes(key));
      if (findData) {
        obj[key] = findData;
      }
    });
    return obj;
  } else {
    return {};
  }
};
export const getModulePricing =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    try {
      let { domainId } = getState().pReducers.user.loginUserData;
      ///// API CALL //////
      const params = { domainId: domainId, timeStamp: new Date().getTime() };
      const res = await genericAxiosCall(
        serverRoutes?.MODULE_PRICING,
        "get",
        "",
        params
      );

      if (res?.data?.success) {
        const data = res?.data?.data;
        const temp = filterPricingModules(data);
        // const tempData = {
        //   [moduleRolesEnum?.Appeals]:
        //     data?.length &&
        //     data?.find((item) => item?.Module === "Smart Appeals")
        //       ? data?.find((item) => item?.Module === "Smart Appeals")
        //       : {},
        //   [moduleRolesEnum?.Policy]:
        //     data?.length &&
        //     data?.find((item) => item?.Module === "Policy Advisor")
        //       ? data?.find((item) => item?.Module === "Policy Advisor")
        //       : {},
        // };
        dispatch(updateModulePricing(temp));
      }
      ////// API CALL END //////////////
    } catch (e) {
      // console.log("hey khizer error2===", e);
      return false;
    }
  };
export const getModuleUsage =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    try {
      let { domainId } = getState().pReducers.user.loginUserData;
      ///// API CALL //////
      const params = { timeStamp: new Date().getTime(), domainId: domainId };

      const res = await genericAxiosCall(
        serverRoutes?.MODULE_USAGE,
        "get",
        "",
        params
      );
      if (res?.data?.success) {
        const data = res?.data?.data;
        let tempData = {
          [moduleRolesEnum?.Appeals]: data?.smartAppealsData?.length
            ? data?.smartAppealsData?.[0]
            : {},
        };
        let temp2 = {};
        Object.keys(moduleRolesEnumPolicy)?.map((item) => {
          let obj =
            data?.policyAdvisorData?.length &&
            data?.policyAdvisorData?.some(
              (val) =>
                val?.[subscriptionPlanNameKey] === moduleRolesEnumPolicy?.[item]
            )
              ? data?.policyAdvisorData?.filter(
                  (val) =>
                    val?.[subscriptionPlanNameKey] ===
                    moduleRolesEnumPolicy?.[item]
                )?.[0]
              : {};

          if (Object.keys(obj)?.length) {
            let newObj = {
              ...temp2,
              [moduleRolesEnumPolicy?.[item]]: obj,
            };
            temp2 = newObj;
          }
        });
        tempData = { ...tempData, ...temp2 };

        dispatch(updateModuleUsage(tempData));
      }
      ////// API CALL END //////////////
    } catch (e) {
      return false;
    }
  };
export const getAllOpenApi =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    try {
      let { domainId } = getState().pReducers.user.loginUserData;
      ///// API CALL //////
      const params = {
        domainId: domainId,
        timestamp: new Date().getTime(),
      };
      dispatch(gettingOpenApi());
      const res = await genericAxiosCall(
        serverRoutes?.GET_ALL_OPEN_API,
        "get",
        "",
        params
      );
      if (res?.data?.success) {
        const data = res?.data?.data;
        dispatch(successOpenApi(data));
      } else {
        dispatch(failedOpenApi());
      }
      ////// API CALL END //////////////
    } catch (e) {
      dispatch(failedOpenApi());

      return false;
    }
  };
export const getSamlDetails =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    try {
      ///// API CALL //////
      let domain = getState().pReducers.user.domainRealName;
      const params = {
        domainName: domain,
        timestamp: new Date().getTime(),
      };
      dispatch(
        setListingState({
          listing: "SAMLFilter",
          value: {
            loadingMore: "loading",
          },
        })
      );
      const res = await genericAxiosCall(
        serverRoutes?.GET_SAML_DETAILS,
        "get",
        "",
        params
      );
      // const res = await axios.get(serverRoutes?.GET_ALL_SUBSCRIBED_MODULES);
      if (res?.data?.success) {
        const data = res?.data?.data;
        dispatch(successSamlInfo(data));

        // dispatch(setDenialCoordinatorList(data));
      } else {
        dispatch(successSamlInfo({}));
      }
      dispatch(
        setListingState({
          listing: "SAMLFilter",
          value: {
            loadingMore: "",
          },
        })
      );
      ////// API CALL END //////////////
    } catch (e) {
      dispatch(
        setListingState({
          listing: "SAMLFilter",
          value: {
            loadingMore: "",
          },
        })
      );
      dispatch(successSamlInfo({}));

      return false;
    }
  };
export const updateSamlDetails =
  (input, setLoading) => async (dispatch, getState) => {
    try {
      ///// API CALL //////
      let domainId = getState().pReducers.user.domainID;

      const params = {
        domainId,
      };
      const body = {
        name: input?.organizationName,
        idPMetadata: input?.idp,
        issuer: input?.identifier,
        signatureAlgorithm: input?.signatureAlgorithm,
        certificateValidationMode: "None",
        revocationMode: "NoCheck",
      };
      setLoading(true);
      const res = await genericAxiosCall(
        serverRoutes?.UPDATE_SAML_DETAILS,
        "put",
        body,
        params
      );
      // const res = await axios.get(serverRoutes?.GET_ALL_SUBSCRIBED_MODULES);
      if (res?.data?.success) {
        dispatch(getSamlDetails());
        SuccessToast(res, "Updated Successfully!");

        // dispatch(setDenialCoordinatorList(data));
      } else {
        UnSuccessfullToast(res);
        dispatch(successSamlInfo({}));
      }
      setLoading(false);

      ////// API CALL END //////////////
    } catch (e) {
      setLoading(false);

      ErrorToast(e);
      dispatch(successSamlInfo({}));

      return false;
    }
  };
export const getUserDetailsForSelectedModules =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    try {
      let { userId } = getState().pReducers.user.loginUserData;
      ///// API CALL //////
      const params = { userId: userId, timestamp: new Date().getTime() };
      const res = await genericAxiosCall(
        serverRoutes?.GET_USER_BY_ID,
        "get",
        "",
        params
      );
      if (res?.data?.success) {
        const data = res?.data?.data;
        await dispatch(updateUserModuleInfo(data?.selectedModules));
      }
      ////// API CALL END //////////////
    } catch (e) {
      return false;
    }
  };
export default userSlice.reducer;

import { Navigate, Outlet, useLocation } from "react-router-dom";
import browserRoute from "../../constants/browserRoutes";
import useAuth from "../common/CustomHooks/useAuth";

function RequireRoleBasedAuth({ allowedRoles }) {
  const [userToken, roles] = useAuth();
  const location = useLocation();
  return roles?.find((role) => allowedRoles?.includes(role.name)) &&
    userToken ? (
    <Outlet />
  ) : (
    <Navigate
      to={browserRoute.REACT_SERVER_DEFAULT_URL}
      state={{ from: location }}
      replace
    />
  );
}

export default RequireRoleBasedAuth;

import {
  encodeData,
  refreshMsalToken,
  refreshToken,
} from "../components/common/Utils/utils";
import browserRoute from "../constants/browserRoutes";
import { setAllFiltersNoOfRecordInTable } from "../Redux/Reducers/UserReducer";
import store from "../Redux/store";
import axios from "./axios";

export const checkTokenExpiration = async () => {
  // Get the expiration time from storage

  try {
    const { applicationTokenExpiry, loginType } =
      store?.getState()?.pReducers?.user;
    if (applicationTokenExpiry) {
      const expiryDate = new Date(applicationTokenExpiry.toString());

      if (
        loginType &&
        loginType === "Azure AD" &&
        expiryDate &&
        new Date().getTime() >=
          new Date(
            expiryDate?.setMinutes(expiryDate?.getMinutes() - 20)
          ).getTime()
      ) {
        await refreshMsalToken();
      } else if (
        loginType &&
        loginType === "Local" &&
        expiryDate &&
        new Date().getTime() >=
          new Date(
            expiryDate?.setMinutes(expiryDate?.getMinutes() - 20)
          ).getTime()
      ) {
        await refreshToken();
      }
    }
  } catch (error) {}
};

// const genericAxiosCall = async (url, method, data, params) => {
//   await checkTokenExpiration();

//   return axios({
//     url: `${url}${params ? encodeData(params) : ""}`,
//     method: method,
//     data: data,
//   });
// };

// const genericAxiosCall = async (url, method, data, params) => {
//   try {
//     const { userPreferences } = store?.getState()?.pReducers.user;

//     // Check for token expiration first
//     await checkTokenExpiration();

//     // Log params for debugging

//     // If params exists and contains limit, ensure it's valid
//     if (params && params.hasOwnProperty("limit")) {
//       const limit = params?.limit;
//       // console.log("params--->>", url, params);

//       // If limit is invalid, update with user preference or fallback value
//       if (!limit || limit === "0") {
//         params.limit = userPreferences?.NoOfRecordInTable || 10;
//         store.dispatch(
//           setAllFiltersNoOfRecordInTable({
//             noOfRecords: params.limit,
//           })
//         );
//       }
//     }

//     // Make the axios call with updated params
//     return axios({
//       url: `${url}${params ? encodeData(params) : ""}`,
//       method: method,
//       data: data,
//     });
//   } catch (err) {
//     console.error("Error in genericAxiosCall:--->>", err);
//   }
// };

const genericAxiosCall = async (url, method, data, params) => {
  try {
    const { userPreferences } = store?.getState()?.pReducers.user;

    // Check for token expiration first
    await checkTokenExpiration();

    // Log params for debugging

    // Normalize params and check for limit (case-insensitive)
    if (params) {
      const limitKey = Object.keys(params).find(
        (key) => key.toLowerCase() === "limit"
      );
      if (limitKey) {
        const limit = params[limitKey];

        // If limit is invalid, update with user preference or fallback value
        if (!limit || limit == "0") {
          params[limitKey] = userPreferences?.NoOfRecordInTable || 10;
          store.dispatch(
            setAllFiltersNoOfRecordInTable({
              noOfRecords: params[limitKey],
            })
          );
        }
      }
    }

    // Make the axios call with updated params
    return axios({
      url: `${url}${params ? encodeData(params) : ""}`,
      method: method,
      data: data,
    });
  } catch (err) {
    console.error("Error in genericAxiosCall:--->>", err);
  }
};

export default genericAxiosCall;

import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import logger from "redux-logger";
import { encryptTransform } from "redux-persist-transform-encrypt";
import userSlice from "./Reducers/user/userSlice";
import UserReducer from "./Reducers/UserReducer";
import PaymentReducer from "./Reducers/PaymentReducer";
import BoisimilarReducer from "./Reducers/BoisimilarReducer";
import barChartSlice from "./Reducers/Claims/claimDashboard/barChartSlice";
import claimCardSlice from "./Reducers/Claims/claimDashboard/claimCardSlice";
import ClaimDashSlice from "./Reducers/Claims/claimDashboard/ClaimDashSlice";
import claimManagerSlice from "./Reducers/Claims/claimManagerReducer/claimManagerSlice";
import claimPaymentSlice from "./Reducers/Claims/claimPaymentReducer/claimPaymentSlice";
import ClaimAckSlice from "./Reducers/Claims/ClaimAck/ClaimAckSlice";
import addressSlice from "./Reducers/SignUpForm/addressSlice";
import claimPaymentCardSlice from "./Reducers/Claims/claimDashboard/claimPymentCardSlice";
import tagSlice from "./Reducers/Claims/claimManagerReducer/tagSlice";
import claimAdjustmentReasonCodeSlice from "./Reducers/AdminDashboard/CARC/claimAdjustmentReasonCodeSlice";
import queryGeneratorSlice from "./Reducers/queryGenerator/queryGeneratorSlice";
import showErrorSuccessMsg from "./Reducers/ShowErrorSuccessMsg/showErrorSuccessMsgSlice";
import claimManagerFilters from "./Reducers/filters/claimManagerFilters";
import cptOverviewSlice from "./Reducers/DenialsDashboard/overviewReducer/cptOverviewSlice";

import denialDetailsSlice from "./Reducers/DenialDetails/denialDetailsSlice";
import filterData from "./Reducers/filters/filtersApi";
import commonData from "./Reducers/CommonData/commonDataReducer";
import revenueByPayerSlice from "./Reducers/ReportsDashboard/revenueByPayerSlice";
import denialByCptSlice from "./Reducers/DenialDetails/denialByCptSlice";
import denialByPayerSlice from "./Reducers/DenialDetails/denialByPayerSlice";
import GroupByCptReportSlice from "./Reducers/ReportsDashboard/SeviceLineDenials/GroupByCptReportSlice";
import activityLogSlice from "./Reducers/Settings/activityLogSlice";
import advanceSettingSlice from "./Reducers/Settings/advanceSettingSlice";
import dataSourceSlice from "./Reducers/Settings/dataSourceSlice";
import PatientDetailSlice from "./Reducers/Claims/PatientDetails/PatientDetailSlice";
import drugsRejectedSlice from "./Reducers/ReportsDashboard/drugsRejectedSlice";
import userPreferenceSlice from "./Reducers/Settings/userPreferenceSlice";
import applicationUsersSlice from "./Reducers/Settings/applicationUsersSlice";
import claimManagerTestSlice from "./Reducers/Claims/claimManagerReducer/claimManagerTestSlice";
import ExectiveDashboardKpisSlice from "./Reducers/ExectiveDashboard/ExectiveDashboardKpisSlice";
import denialsByCptScreenSlice from "./Reducers/DenialDetails/denialsByCptScreenSlice";
import denialsDashboardSlice from "./Reducers/DenialsDashboard/denialsDashboardSlice";
import OncologyDashboardKpisSlice from "./Reducers/OncologyDashboard/OncologyDashboardKpisSlice";
import denialOverviewDashSlice from "./Reducers/DenialsDashboard/denialOverviewDashSlice";
import MapBoxSlice from "./Reducers/MapBox/MapBoxSlice";
import DenialsByFacilityDashSlice from "./Reducers/DenialsDashboard/denialsByFacilityDashSlice";

import durationWiseBucketSlice from "./Reducers/Claims/claimManagerReducer/durationWiseBucketSlice";
import LogsSlice from "./Reducers/Logs/LogsSlice";
import ExecutiveDashKpisSlice from "./Reducers/ExectiveDashboard/ExecutiveDashKpisSlice";
import OncologyCARCSlice from "./Reducers/OncologyCARC/OncologyCARCSlice";
import RevenueHeatmapSlice from "./Reducers/RevenueHeatmap/RevenueHeatmapSlice";

import GoalTrackingSlice from "./Reducers/GoalTracking/GoalTrackingSlice";
import Landing from "./Reducers/Landing";
import departmentsSlice from "./Reducers/DepartmentsTreeView/departmentsSlice";
import HealthSystemSlice from "./Reducers/HealthSystem/healthSystemSlice";

import onCologyDenialDashKpisSlice from "./Reducers/OncologyDashboard/onCologyDenialDashKpisSlice";
import OnCologyDenialDashKpisSlice from "./Reducers/OncologyDashboard/onCologyDenialDashKpisSlice";
import ClaimQueueSlice from "./Reducers/QueueDetails/claimQueueSlice";
import ErrorSlice from "./Reducers/Error/ErrorSlice";
import CommentsSlice from "./Reducers/Comments/commentsSlice";
import LoginActivitySlice from "./Reducers/LoginActivity/LoginActivitySlice";
import AiDashboardSlice from "./Reducers/AIDashboard/AiDashboaardSlice";
import AppealConfiguration from "./Reducers/AppealConfiguration";

import claimAllFiltersSlice from "./Reducers/DenialDetails/claimAllFiltersSlice";
import AlertCenterSlice from "./Reducers/AlertCenter";
import ProcedureCodesDashboard from "./Reducers/ProcedureCodesDashboard";
import claim837AllFiltersSlice from "./Reducers/DenialDetails/claim837AllFiltersSlice";
import RARCSlice from "./Reducers/RARC/RARCSlice";
import UploadPolicy from "./Reducers/UploadPolicy/UploadPolicySlice";
import CopilotSlice from "./Reducers/Copilot/CopilotSlice";

import ClientsConfigSlice from "./Reducers/ClientsConfig/ClientsConfigSlice";
import DenialsCategoriesConfigSlice from "./Reducers/DenialCategoriesConfig/DenialsCategoriesConfigSlice";
import CustomProceCodeSlice from "./Reducers/CustomProceCodeConfig/CustomProceCodeSlice";
import claimProgressSlice from "./Reducers/ClaimProgress/ClaimProgressSlice";
import ClaimCustomStatusCodeSlice from "./Reducers/ClaimCustomStatusCode/ClaimCustomStatusCodeSlice";
import FilesIntegrationSlice from "./Reducers/FilesIntegrationCSV/FilesIntegrationSlice";

const pReducersForEncryption = combineReducers({
  user: UserReducer,
});

const npReducers = combineReducers({
  userSlice: userSlice,
  payment: PaymentReducer,
  boisimilar: BoisimilarReducer,
  claimCard: claimCardSlice,
  ClaimDash: ClaimDashSlice,
  claimPayment: claimPaymentCardSlice,
  claimManager: claimManagerSlice,
  claimManagerTest: claimManagerTestSlice,
  claimPayments: claimPaymentSlice,
  claimAck: ClaimAckSlice,
  address: addressSlice,
  barChart: barChartSlice,
  CARC: claimAdjustmentReasonCodeSlice,
  tags: tagSlice,
  queryGenerator: queryGeneratorSlice,
  showErrorSuccessMsg: showErrorSuccessMsg,
  filters: claimManagerFilters,
  claimProgress: claimProgressSlice,
  cptOverview: cptOverviewSlice,
  denialDetails: denialDetailsSlice,
  claimAllFilters: claimAllFiltersSlice,
  claim837AllFilters: claim837AllFiltersSlice,
  denialByCpt: denialByCptSlice,
  denialByPayer: denialByPayerSlice,
  revenueByPayer: revenueByPayerSlice,
  filterData: filterData,
  commonData: commonData,
  userPreference: userPreferenceSlice,
  applicationUsers: applicationUsersSlice,
  GroupByCptReport: GroupByCptReportSlice, // report dash slice
  drugsRejected: drugsRejectedSlice, // report -> drugsRejected slice
  activityLogin: LoginActivitySlice, // setting -> login activity slice
  filesIntegarion: FilesIntegrationSlice, // setting -> login activity slice
  aiDashboard: AiDashboardSlice,
  appealConfiguration: AppealConfiguration,
  alertCenter: AlertCenterSlice,
  activityLog: activityLogSlice, // setting - > activityLog slice
  advanceSetting: advanceSettingSlice, // setting - > AdvanceSetting slice
  dataSource: dataSourceSlice, // setting - > dataSource slice
  patientDetail: PatientDetailSlice,
  exectiveDashboard: ExectiveDashboardKpisSlice, // Exective dashboard slice
  denialsByCptNewScreen: denialsByCptScreenSlice, // new denialsByCptScreen
  denialsDashobaord: denialsDashboardSlice, // new denials Dashboard
  oncologyDashobaord: OncologyDashboardKpisSlice, // new oncology Dashboard
  oncologyDenialDash: OnCologyDenialDashKpisSlice, // oncology Denial Dashboard screen
  denialOverview: denialOverviewDashSlice, // denial Overview screen
  mapBox: MapBoxSlice, // mapbox tabular data screen
  denialsByFacilityDash: DenialsByFacilityDashSlice, // denials by facility screen
  durationWiseBucket: durationWiseBucketSlice, // duration wise bucket screen
  claimQueue: ClaimQueueSlice, // claim queue screen
  logsData: LogsSlice,
  clientsConfig: ClientsConfigSlice,
  claimCustomCodeConfig: ClaimCustomStatusCodeSlice,
  customProceCode: CustomProceCodeSlice,
  exectiveDashboardPayer: ExecutiveDashKpisSlice,
  carcTrendsDash: OncologyCARCSlice,
  goalTracking: GoalTrackingSlice,
  landingDashboard: Landing,
  Departments: departmentsSlice,
  healthSystem: HealthSystemSlice,
  revenueHeatmap: RevenueHeatmapSlice,
  RARC: RARCSlice,
  uploadPolicy: UploadPolicy,
  denialsCategoriesConfig: DenialsCategoriesConfigSlice,
  copilot: CopilotSlice,
  oncologyDenialDash: onCologyDenialDashKpisSlice, // oncology Denial Dashboard screen
  error: ErrorSlice,
  commentsModule: CommentsSlice,
  procedureCodeDash: ProcedureCodesDashboard,
});

const persistConfig = {
  key: "root",
  storage,
  transforms: [
    encryptTransform({
      secretKey: "my-super-secret-key",
      onError: function (error) {
        // console.log("error occured while encryption");
      },
    }),
  ],
};

const pReducers = persistReducer(persistConfig, pReducersForEncryption);

let splitItem = window.location.href.split("/");
splitItem = splitItem[2].includes("localhost");

let store;
if (splitItem) {
  store = configureStore({
    reducer: {
      pReducers: pReducers,
      npReducers: npReducers,
    },

    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(logger),
  });
} else {
  store = configureStore({
    reducer: {
      pReducers: pReducers,
      npReducers: npReducers,
    },

    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
}

export default store;

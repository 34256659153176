import { createSlice } from "@reduxjs/toolkit";

import {
  resetListingState,
  setListingState,
} from "../filters/claimManagerFilters";
import {
  commonPagination,
  commonPaginationForPost,
  ErrorToast,
  getMultiSelectFilterValueForApi,
  SuccessToast,
  UnSuccessfullToast,
} from "../../../components/common/Utils/utils";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";
import { serverRoutes } from "../../../constants/serverRoutes";
import store from "../../store";

export const Copilot = createSlice({
  name: "Copilot",
  initialState: {
    policyCopilot: {
      list: [],
      count: 0,
    },
    eligibilityCopilot: {
      list: [],
      count: 0,
    },
    policy: {
      list: [],
    },
    eligibility: {
      list: [],
    },
  },
  reducers: {
    successPolicyCoPilotList: (state, action) => {
      const data = action?.payload;
      if (data?.length) {
        state.policyCopilot.list = data;
        state.policyCopilot.count = data?.[0]?.rowCount;
      } else {
        state.policyCopilot.list = [];
        state.policyCopilot.count = 0;
      }
    },
    successPolicyList: (state, action) => {
      const data = action?.payload;
      if (data?.length) {
        state.policy.list = data;
      } else {
        state.policy.list = [];
      }
    },

    successEligibilityCoPilotList: (state, action) => {
      const data = action?.payload;
      if (data?.length) {
        state.eligibilityCopilot.list = data;
        state.eligibilityCopilot.count = data?.[0]?.rowCount;
      } else {
        state.eligibilityCopilot.list = [];
        state.eligibilityCopilot.count = 0;
      }
    },
    successEligibilityList: (state, action) => {
      const data = action?.payload;
      if (data?.length) {
        state.eligibility.list = data;
      } else {
        state.eligibility.list = [];
      }
    },
  },
});

export const {
  successPolicyCoPilotList,
  successPolicyList,
  successEligibilityCoPilotList,
  successEligibilityList,
} = Copilot.actions;

export const getAllCopilot =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    dispatch(getPolicyCopilot());
    dispatch(getEligibilityCopilot());
  };
export const getAllCopilotBot =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    dispatch(getPolicyBot());
    dispatch(getEligibilityBot());
  };

//policy copilot crud
export const getPolicyCopilot =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "PolicyCopilotFilter",
        })
      );
    }

    let sliceState = getState().npReducers.filters.PolicyCopilotFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      // dispatch(gettingSmartInsightReasonCodeHeatmap_Call());

      let paramsTemp = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),

        timestamp: new Date().getTime(),
      };

      let params = {
        start,
        limit,
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),

        timestamp: new Date().getTime(),
      };

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        successPolicyCoPilotList,
        "PolicyCopilotFilter",
        "GET_ALL_POLICY_COPILOT",
        null,
        "",
        ""
      );
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };
export const getPolicyBot =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "policyFilterMain",
        })
      );
    }

    let sliceState = getState().npReducers.filters.policyFilterMain;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      // dispatch(gettingSmartInsightReasonCodeHeatmap_Call());

      let paramsTemp = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),

        timestamp: new Date().getTime(),
      };

      let params = {
        start,
        limit: 100,
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),

        timestamp: new Date().getTime(),
      };

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        successPolicyList,
        "policyFilterMain",
        "GET_ALL_POLICY_COPILOT",
        null,
        "",
        ""
      );
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };
export const addNewPolicyCopilot = (question, setLoading, handleClose) => {
  const params = {
    question,
  };
  setLoading(true);

  genericAxiosCall(`${serverRoutes?.CREATE_POLICY_COPILOT}`, "post", params, "")
    .then((res) => {
      if (res?.data?.success) {
        SuccessToast(res, "Question added successfully!");
        handleClose();
        store.dispatch(getPolicyCopilot());
        store.dispatch(getPolicyBot());
      } else {
        UnSuccessfullToast(res);
      }
    })
    .catch((err) => {
      ErrorToast(err);
    })
    .finally(() => {
      setLoading(false);
    });
};
export const updatePolicyCopilot = (question, id, setLoading, handleClose) => {
  const params = {
    id,
    question,
  };
  setLoading(true);

  genericAxiosCall(`${serverRoutes?.UPDATE_POLICY_COPILOT}`, "put", params, "")
    .then((res) => {
      if (res?.data?.success) {
        SuccessToast(res, "Question updated successfully!");
        handleClose();
        store.dispatch(getPolicyCopilot());
        store.dispatch(getPolicyBot());
      } else {
        UnSuccessfullToast(res);
      }
    })
    .catch((err) => {
      ErrorToast(err);
    })
    .finally(() => {
      setLoading(false);
    });
};
export const deletePolicyCopilot = (id, setLoading, handleClose, list) => {
  const params = {
    id: id?.toString(),
  };
  setLoading(true);

  genericAxiosCall(
    `${serverRoutes?.DELETE_POLICY_COPILOT}`,
    "delete",
    "",
    params
  )
    .then((res) => {
      if (res?.data?.success) {
        SuccessToast(res, "Question updated successfully!");
        handleClose();
        if (list.length === 1) {
          store.dispatch(
            setListingState({
              listing: "PolicyCopilotFilter",
              value: { pageNumber: 1 },
            })
          );
        }
        store.dispatch(getPolicyCopilot());
        store.dispatch(getPolicyBot());
      } else {
        UnSuccessfullToast(res);
      }
    })
    .catch((err) => {
      ErrorToast(err);
    })
    .finally(() => {
      setLoading(false);
    });
};

//eligibility copilot crud
export const getEligibilityCopilot =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "EligibilityCopilotFilter",
        })
      );
    }

    let sliceState = getState().npReducers.filters.EligibilityCopilotFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      // dispatch(gettingSmartInsightReasonCodeHeatmap_Call());

      let paramsTemp = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),

        timestamp: new Date().getTime(),
      };

      let params = {
        start,
        limit,
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),

        timestamp: new Date().getTime(),
      };

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        successEligibilityCoPilotList,
        "EligibilityCopilotFilter",
        "GET_ALL_ELIGIBILITY_COPILOT",
        null,
        "",
        ""
      );
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };
export const getEligibilityBot =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "eligibilityFilterMain",
        })
      );
    }

    let sliceState = getState().npReducers.filters.eligibilityFilterMain;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      // dispatch(gettingSmartInsightReasonCodeHeatmap_Call());

      let paramsTemp = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),

        timestamp: new Date().getTime(),
      };

      let params = {
        start,
        limit: 100,
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),

        timestamp: new Date().getTime(),
      };

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        successEligibilityList,
        "eligibilityFilterMain",
        "GET_ALL_ELIGIBILITY_COPILOT",
        null,
        "",
        ""
      );
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };
export const addNewEligibilityCopilot = (question, setLoading, handleClose) => {
  const params = {
    question,
  };
  setLoading(true);

  genericAxiosCall(
    `${serverRoutes?.CREATE_ELIGIBILITY_COPILOT}`,
    "post",
    params,
    ""
  )
    .then((res) => {
      if (res?.data?.success) {
        SuccessToast(res, "Question added successfully!");
        handleClose();
        store.dispatch(getEligibilityCopilot());
        store.dispatch(getEligibilityBot());
      } else {
        UnSuccessfullToast(res);
      }
    })
    .catch((err) => {
      ErrorToast(err);
    })
    .finally(() => {
      setLoading(false);
    });
};
export const updateEligibilityCopilot = (
  question,
  id,
  setLoading,
  handleClose
) => {
  const params = {
    id,
    question,
  };
  setLoading(true);

  genericAxiosCall(
    `${serverRoutes?.UPDATE_ELIGIBILITY_COPILOT}`,
    "put",
    params,
    ""
  )
    .then((res) => {
      if (res?.data?.success) {
        SuccessToast(res, "Question updated successfully!");
        handleClose();
        store.dispatch(getEligibilityCopilot());
        store.dispatch(getEligibilityBot());
      } else {
        UnSuccessfullToast(res);
      }
    })
    .catch((err) => {
      ErrorToast(err);
    })
    .finally(() => {
      setLoading(false);
    });
};
export const deleteEligibilityCopilot = (id, setLoading, handleClose) => {
  const params = {
    id: id?.toString(),
  };
  setLoading(true);

  genericAxiosCall(
    `${serverRoutes?.DELETE_ELIGIBILITY_COPILOT}`,
    "delete",
    "",
    params
  )
    .then((res) => {
      if (res?.data?.success) {
        SuccessToast(res, "Question updated successfully!");
        handleClose();
        store.dispatch(getEligibilityCopilot());
        store.dispatch(getEligibilityBot());
      } else {
        UnSuccessfullToast(res);
      }
    })
    .catch((err) => {
      ErrorToast(err);
    })
    .finally(() => {
      setLoading(false);
    });
};

export default Copilot.reducer;

import axios from "../AxiosConfig/axios";
import { encodeData } from "./../components/common/Utils/utils";
const subId = localStorage.getItem("subscriptionID");
const report = {
  // revenue by payer get all payer  with full detail list
  getAllPayerByRevenueDetailList(params) {
    return axios.get(`/Report/RevenueByPayer?${encodeData(params)}`);
  },

  // revenue by payer get all payer list
  getAllPayerByRevenue(params) {
    return axios.get(`/Report/PayersWithRevenue?${encodeData(params)}`);
  },

  // revenue by payer get one payer details
  getOnePayerData(params) {
    return axios.get(`/Report/RevenueByPayerDetail?${encodeData(params)}`);
  },

  getDrugRejected(start, limit, from, to) {
    return axios.get(
      `/CPTCode/GetDrugRejected?Start=${start}&Limit=${limit}&From=${from}&To=${to}`,
      {
        headers: { "X-Subscription": subId },
      }
    );
  },

  getDrugRejected_Data(params) {
    return axios.get(`/CPTCode/GetDrugRejected?${encodeData(params)}`);
  },

  // Report dashboard api's

  // service line claim GroupbyCptReport api
  getDenialsGBCPT(params) {
    return axios.get(`/ServiceDenials/DenialsGBCPT?${encodeData(params)}`);
  },

  // service line claim GroupbyCptReport dropdown api
  getDenialsGBCPTDropdown(params) {
    return axios.get(`/ServiceDenials/DenialsDetailCPT?${encodeData(params)}`);
  },

  // service line claim GroupbyPayerReport api
  getDenialsGBPayer(params) {
    return axios.get(`/ServiceDenials/DenialsGBPayer?${encodeData(params)}`);
  },

  // service line claim GroupbyPayerReport dropdown api
  getDenialsGBPayerDropdown(params) {
    return axios.get(
      `/ServiceDenials/DenialsDetailPayer?${encodeData(params)}`
    );
  },

  // service line claim GroupbyReasonCodeReport api
  getDenialsGBReasonCode(params) {
    return axios.get(`/ServiceDenials/DenialsGBReason?${encodeData(params)}`);
  },

  // service line claim GroupbyReasonCodeReport dropdown api
  getDenialsGBReasonCodeDropdown(params) {
    return axios.get(
      `/ServiceDenials/DenialsDetailPayer?${encodeData(params)}`
    );
  },
};

export default report;

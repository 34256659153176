import React from "react";

const V2OncologyIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 21.0002C7 21.0002 16.286 11.1592 16.286 7.15923C16.3178 6.60224 16.2286 6.04497 16.0246 5.52572C15.8206 5.00647 15.5065 4.53756 15.104 4.15123C14.6934 3.75691 14.2057 3.45174 13.6715 3.25487C13.1374 3.058 12.5683 2.97369 12 3.00723C11.4318 2.97356 10.8627 3.05771 10.3286 3.25441C9.79442 3.4511 9.30669 3.75609 8.896 4.15023C8.49324 4.53679 8.17903 5.00602 7.97498 5.52564C7.77093 6.04525 7.68189 6.60291 7.714 7.16023C7.714 11.1592 17 21.0002 17 21.0002"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    // <svg
    //   width="24"
    //   height="24"
    //   viewBox="0 0 48 48"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     fill-rule="evenodd"
    //     clip-rule="evenodd"
    //     d="M26.9238 4C27.8914 4 28.7899 4.21665 29.5913 4.67461C30.276 5.06587 31.0533 5.60468 31.628 6.40924L31.6156 6.41808L31.6269 6.4332L33.9585 9.45545L34.7795 10.5502C37.3105 13.8928 37.31 18.5471 34.7781 21.8893L30.2702 27.7084L37.2191 36.6521C37.8623 37.4799 37.7572 38.6639 36.9781 39.3654L32.9122 43.0268C32.0516 43.8018 30.7148 43.6861 30.0001 42.7749L24.2094 35.3917L18.3652 42.843C17.6916 43.7019 16.4538 43.862 15.5838 43.2029L11.2054 39.8859C10.3155 39.2118 10.1498 37.9398 10.8372 37.0602L18.1511 27.7016L13.6469 21.9878L13.641 21.98C11.109 18.6379 11.1085 13.9837 13.6394 10.641L13.641 10.639L14.4523 9.55709L16.7887 6.52844C16.8241 6.48089 16.8604 6.43373 16.8975 6.38697C18.0073 4.84975 19.8517 4 21.6762 4H26.9238ZM28.5991 6.4111C29.1501 6.726 29.6182 7.07086 29.9466 7.49896L29.932 7.51045L30.0321 7.64017C31.076 9.05028 31.0686 11.0297 30.0099 12.4317C29.0758 11.862 27.965 11.5093 26.8334 11.5093H21.5857C20.4634 11.5093 19.3185 11.8099 18.3446 12.4333C17.8617 11.7462 17.6095 10.8772 17.6095 10.0667C17.6095 9.25167 17.8658 8.43472 18.3832 7.73584L18.4982 7.5867L18.5089 7.57171C19.2104 6.58966 20.4332 6 21.6762 6H26.9238C27.5847 6 28.1338 6.14525 28.5991 6.4111ZM19.574 14.0266L24.1743 19.9687L28.7743 14.0271C28.1756 13.7019 27.496 13.5093 26.8334 13.5093H21.5857C20.8665 13.5093 20.1664 13.6876 19.574 14.0266ZM28.9964 26.0871L25.4438 21.5961L31.5099 13.7607C32.1353 12.9766 32.535 12.0671 32.709 11.1228L33.1838 11.7559C35.1741 14.3831 35.1752 18.049 33.187 20.6774L28.9964 26.0871ZM19.4258 29.3185L12.4131 38.2918L16.7915 41.6087L22.9373 33.7729L19.4258 29.3185ZM16.9091 13.8515C16.2873 13.0717 15.8932 12.14 15.717 11.2043L15.2381 11.8429L15.2352 11.8467C13.2457 14.4728 13.2438 18.1368 15.2295 20.7648L24.9963 33.1543L31.5738 41.5406L35.6398 37.8792L28.2204 28.3299L23.4252 22.2681L16.9091 13.8515Z"
    //   />
    // </svg>

    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 14 14"
    //   height="24"
    //   width="24"
    // >
    //   <g>
    //     <line
    //       x1="7"
    //       y1="3.14"
    //       x2="7"
    //       y2="4.64"
    //       fill="none"
    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //     ></line>
    //     <path
    //       d="M8.51,7.15a3.62,3.62,0,0,0,1.49-3A3.29,3.29,0,0,0,7,.64a3.28,3.28,0,0,0-3,3.5,3.62,3.62,0,0,0,1.49,3L2.28,10.82a.51.51,0,0,0,0,.65l1.37,1.7a.47.47,0,0,0,.38.19.52.52,0,0,0,.39-.17A23,23,0,0,0,7,9.64a23,23,0,0,0,2.6,3.55.52.52,0,0,0,.39.17.47.47,0,0,0,.38-.19l1.37-1.7a.5.5,0,0,0,0-.64Z"
    //       fill="none"
    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //     ></path>
    //   </g>
    // </svg>

    // <svg
    //   width="24"
    //   height="24"
    //   viewBox="0 0 24 24"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <g opacity="0.7">
    //     <path
    //       d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H14C14.41 1.25 14.75 1.59 14.75 2C14.75 2.41 14.41 2.75 14 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V10C21.25 9.59 21.59 9.25 22 9.25C22.41 9.25 22.75 9.59 22.75 10V15C22.75 20.43 20.43 22.75 15 22.75Z"
    //       fill="#092C4C"
    //     />
    //     <path
    //       d="M22 10.75H18C14.58 10.75 13.25 9.42 13.25 6V2C13.25 1.7 13.43 1.42 13.71 1.31C13.99 1.19 14.31 1.26 14.53 1.47L22.53 9.47C22.74 9.68 22.81 10.01 22.69 10.29C22.57 10.57 22.3 10.75 22 10.75ZM14.75 3.81V6C14.75 8.58 15.42 9.25 18 9.25H20.19L14.75 3.81Z"
    //       fill="#092C4C"
    //     />
    //     <path
    //       d="M13 13.75H7C6.59 13.75 6.25 13.41 6.25 13C6.25 12.59 6.59 12.25 7 12.25H13C13.41 12.25 13.75 12.59 13.75 13C13.75 13.41 13.41 13.75 13 13.75Z"
    //       fill="#092C4C"
    //     />
    //     <path
    //       d="M11 17.75H7C6.59 17.75 6.25 17.41 6.25 17C6.25 16.59 6.59 16.25 7 16.25H11C11.41 16.25 11.75 16.59 11.75 17C11.75 17.41 11.41 17.75 11 17.75Z"
    //       fill="#092C4C"
    //     />
    //   </g>
    // </svg>
  );
};

export default V2OncologyIcon;

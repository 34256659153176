import { Navigate, Outlet, useLocation } from "react-router-dom";
import browserRoute from "../../constants/browserRoutes";
import useModules from "../common/CustomHooks/useModules";
import {
  getterRoleBasedModules,
  subscriptionPlanNameKey,
} from "../common/Utils/utils";

function RequireModuleBasedAuth({ allowedRoles }) {
  const [userToken, roles] = useModules();
  const userRoles = getterRoleBasedModules();
  const location = useLocation();

  return roles?.find((role) =>
    allowedRoles?.includes(role?.[subscriptionPlanNameKey])
  ) &&
    userRoles?.find((role) =>
      allowedRoles?.includes(role?.[subscriptionPlanNameKey])
    ) &&
    userToken ? (
    <Outlet />
  ) : (
    <Navigate
      to={browserRoute.REACT_SERVER_DEFAULT_URL}
      state={{ from: location }}
      replace
    />
  );
}

export default RequireModuleBasedAuth;

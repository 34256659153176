import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { serverRoutes } from "../../../constants/serverRoutes";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";

import {
  setListingState,
  resetListingState,
} from "../filters/claimManagerFilters";
import {
  getAscArray,
  getLegendsExtraShortName,
  getMultiSelectFilterIdForApi_ForId,
} from "../../../components/common/Utils/utils";

export const DenialOverviewDashboardSlice = createSlice({
  name: "DenialOverviewDashboardSlice",
  initialState: {
    denialOverviewVolumeArr: [],
    denailOverviewPayerArr: [],

    denailOverviewCPTAmtArr: [],
    denailOverviewCPTCodesArr: [],

    denialOverviewCARCAmtArr: [],
    denialOverviewCARCodesArr: [],

    denialOverviewPayersAmtArr: [],
    denialOverviewPayersNameListArr: [],

    denialOverviewVoumeAmtArr: [],
    denialOverviewVolumeNameListArr: [],

    // for hanlde denial by payers screen data
    denialByPayers_denailOverviewCPTAmtArr: [],
    denialByPayers_denailOverviewCPTCodesArr: [],

    denialByPayers_denialOverviewCARCAmtArr: [],
    denialByPayers_denialOverviewCARCodesArr: [],

    denialByPayers_denialOverviewPayersAmtArr: [],

    denialByPayers_denialOverviewVolumeArr: [],

    // for filter arr
    PayersForServiceLineFilterArr: [],
    PayersForClaimLevelFilterArr: [],
    PayersFilterArr: [],
  },
  reducers: {
    setDenialOverview_data: (state, action) => {
      let data = action.payload;

      // handle for denailOverviewCPT array here
      if (data?.denailOverviewCPT?.length) {
        let newArr = [];
        let codes = [];

        const asc_order = getAscArray(data?.denailOverviewCPT, "denailAmount");

        asc_order.forEach((item, index) => {
          const cpt_name = item?.cpt;

          let obj = {};
          obj["country"] = item?.cpt;
          obj[cpt_name] = item?.denailAmount;

          newArr.push(obj);
          codes.push(item?.cpt);
        });

        // const asc_codes = getAscArrayForNumberValus(codes);

        state.denailOverviewCPTAmtArr = newArr;
        state.denailOverviewCPTCodesArr = codes;
      } else {
        state.denailOverviewCPTAmtArr = [];
        state.denailOverviewCPTCodesArr = [];
      }

      // handle for denialOverviewCARC array here
      if (data?.denialOverviewCARC?.length) {
        let newArr = [];
        let codes = [];

        const asc_order = getAscArray(
          data?.denialOverviewCARC,
          "denial_Amount"
        );

        asc_order.forEach((item, index) => {
          const reasonCode_name = item?.reasonCode;

          let obj = {};
          obj["country"] = item?.reasonCode;
          obj["description"] = item?.description;
          obj["denial_Count"] = item?.denial_Count;
          obj[reasonCode_name] = item?.denial_Amount;

          newArr.push(obj);
          codes.push(item?.reasonCode);
        });

        // const asc_codes = getAscArrayForNumberValus(codes);

        state.denialOverviewCARCAmtArr = newArr;
        state.denialOverviewCARCodesArr = codes;
      } else {
        state.denialOverviewCARCAmtArr = [];
        state.denialOverviewCARCodesArr = [];
      }

      // handle for denailOverviewPayer array here
      if (data?.denailOverviewPayer?.length) {
        let res_data = data?.denailOverviewPayer;

        let newArr = [];
        let codesArr = [];
        var obj = res_data[0];
        // for handle Three year logic
        if ("year" in obj) {
          const uniqueYear = [...new Set(res_data.map((item) => item?.year))];
          const uniquePayer = [
            ...new Set(res_data.map((item) => item?.payerName)),
          ];
          codesArr = [
            ...new Set(
              res_data.map((item, i) => item?.payerName.replace(/ /g, "_"))
            ),
          ];

          uniqueYear.forEach((year) => {
            const datayear = res_data.filter((item) => item?.year === year);

            let obj = {};
            uniquePayer.forEach((payer, i) => {
              const payerobj = datayear.filter(
                (payerItem) => payerItem?.payerName === payer
              )[0];

              if (payerobj) {
                obj[payer.replace(/ /g, "_")] = payerobj?.denailAmount;
                // obj[getLegendsExtraShortName(payer)] = payerobj?.denailAmount;
              }
            });
            obj["country"] = year;
            newArr.push(obj);
          });
        } else {
          const uniqueMonthName = [
            ...new Set(res_data.map((item) => item?.monthName)),
          ];
          const uniquePayer = [
            ...new Set(res_data.map((item) => item?.payerName)),
          ];
          codesArr = [
            ...new Set(
              res_data.map((item, i) => item?.payerName.replace(/ /g, "_"))
            ),
          ];

          uniqueMonthName.forEach((month) => {
            const dataMonth = res_data.filter(
              (item) => item?.monthName === month
            );

            let obj = {};
            uniquePayer.forEach((payer, i) => {
              const payerobj = dataMonth.filter(
                (payerItem) => payerItem?.payerName === payer
              )[0];

              if (payerobj) {
                obj[payer.replace(/ /g, "_")] = payerobj?.denailAmount;
                // obj[getLegendsExtraShortName(payer)] = payerobj?.denailAmount;
              }
            });
            obj["country"] = month.substring(0, 3);
            newArr.push(obj);
          });
        }

        state.denialOverviewPayersAmtArr = newArr;
        state.denialOverviewPayersNameListArr = codesArr;
      } else {
        state.denialOverviewPayersAmtArr = [];
        state.denialOverviewPayersNameListArr = [];
      }

      // handle for denialOverviewVolume array here
      if (data?.denialOverviewVolume?.length) {
        let res_data = data?.denialOverviewVolume;

        let newArr = [];
        var obj = res_data[0];
        // for handle Three year logic
        if ("year" in obj) {
          const uniquePayer = [
            ...new Set(res_data.map((item) => item?.payerName)),
          ];

          uniquePayer.forEach((payer) => {
            const payer_data = res_data.filter(
              (item) => item?.payerName === payer
            );
            let newPayerArr = [];

            payer_data.forEach((item, i) => {
              newPayerArr.push({
                x: item?.year,
                y: item?.denailCount,
                payer: item?.payerName,
              });
            });

            let obj = {};
            obj["id"] = getLegendsExtraShortName(payer);
            obj["payer"] = payer;
            obj["color"] = "hsl(116, 70%, 50%)";
            obj["data"] = newPayerArr;
            newArr.push(obj);
          });
        } else {
          const uniquePayer = [
            ...new Set(res_data.map((item) => item?.payerName)),
          ];

          uniquePayer.forEach((payer) => {
            const payer_data = res_data.filter(
              (item) => item?.payerName === payer
            );

            let newPayerArr = [];

            payer_data.forEach((item, i) => {
              newPayerArr.push({
                x: item?.monthName.substring(0, 3),
                y: item?.denailCount,
                payer: item?.payerName,
              });
            });

            let obj = {};
            obj["id"] = getLegendsExtraShortName(payer);
            obj["payer"] = payer;
            obj["color"] = "hsl(116, 70%, 50%)";
            obj["data"] = newPayerArr;
            newArr.push(obj);
          });
        }

        state.denialOverviewVolumeArr = newArr;
      } else {
        state.denialOverviewVolumeArr = [];
      }
    },

    // for denial by payer dash screen
    setPayersForDenialsByPayer_Data: (state, action) => {
      let data = action.payload;

      // service Line Chart filter arr
      if (data?.denialPayersFilter?.length) {
        let newArr = [];
        data?.denialPayersFilter.forEach((item) => {
          newArr.push({
            label: item?.payerName,
            value: item?.payerID,
          });
        });

        state.PayersForServiceLineFilterArr = newArr;
      } else {
        state.PayersForServiceLineFilterArr = [];
      }

      // caliam Level Chart filter arr
      if (data?.denialPayersNameFilter?.length) {
        let newArr = [];
        data?.denialPayersNameFilter.forEach((item) => {
          newArr.push({
            label: item?.payerName,
            value: item?.payerID,
          });
        });

        state.PayersForClaimLevelFilterArr = newArr;
      } else {
        state.PayersForClaimLevelFilterArr = [];
      }
    },

    // for for denial By Payers screen
    setDenialByPayersKpis_data: (state, action) => {
      let data = action.payload;

      // handle for denial By Payers screen denailPayersCPT array here
      if (data?.denailPayersCPT?.length) {
        let newArr = [];
        let codes = [];

        const asc_order = getAscArray(data?.denailPayersCPT, "denailAmount");

        asc_order.forEach((item, index) => {
          const cpt_name = item?.cpt;

          let obj = {};
          obj["country"] = item?.cpt;
          obj[cpt_name] = item?.denailAmount ? item?.denailAmount : 0;

          newArr.push(obj);
          codes.push(item?.cpt);
        });

        state.denialByPayers_denailOverviewCPTAmtArr = newArr;
        state.denialByPayers_denailOverviewCPTCodesArr = codes;
      } else {
        state.denialByPayers_denailOverviewCPTAmtArr = [];
        state.denialByPayers_denailOverviewCPTCodesArr = [];
      }

      // handle for denial By Payers screen denialPayersCARC array here
      if (data?.denialPayersCARC?.length) {
        let newArr = [];
        let codes = [];
        const asc_order = getAscArray(data?.denialPayersCARC, "denailAmount");
        asc_order.slice(0, 5).forEach((item, index) => {
          const reasonCode_name = item?.carc;
          let obj = {};
          obj["country"] = item?.carc;
          obj["description"] = item?.desc;
          // obj["denial_Count"] = item?.denial_Count;
          obj[reasonCode_name] = item?.denailAmount;
          newArr.push(obj);
          codes.push(item?.carc);
        });
        state.denialByPayers_denialOverviewCARCAmtArr = newArr;
        state.denialByPayers_denialOverviewCARCodesArr = codes;
      } else {
        state.denailPayersAmount = [];
        state.denialByPayers_denialOverviewCARCodesArr = [];
      }

      // handle for denial By Payers screen denailPayersAmount array here
      if (data?.denailPayersAmount?.length) {
        let res_data = data?.denailPayersAmount;

        let newArr = [];
        var obj = res_data[0];
        // for handle Three year logic
        if ("year" in obj) {
          const uniquePayer = [
            ...new Set(res_data.map((item) => item?.payerName)),
          ];

          uniquePayer.forEach((payer) => {
            const payer_data = res_data.filter(
              (item) => item?.payerName === payer
            );
            let newPayerArr = [];

            payer_data.forEach((item, i) => {
              newPayerArr.push({
                x: item?.year,
                y: item?.denailAmount,
                payer: item?.payerName,
              });
            });

            let obj = {};
            obj["id"] = getLegendsExtraShortName(payer);
            obj["payer"] = payer;
            obj["color"] = "hsl(116, 70%, 50%)";
            obj["data"] = newPayerArr;
            newArr.push(obj);
          });
        } else {
          const uniquePayer = [
            ...new Set(res_data.map((item) => item?.payerName)),
          ];

          uniquePayer.forEach((payer) => {
            const payer_data = res_data.filter(
              (item) => item?.payerName === payer
            );

            let newPayerArr = [];

            payer_data.forEach((item, i) => {
              newPayerArr.push({
                x: item?.monthName.substring(0, 3),
                y: item?.denailAmount,
                payer: item?.payerName,
              });
            });

            let obj = {};
            obj["id"] = getLegendsExtraShortName(payer);
            obj["payer"] = payer;
            obj["color"] = "hsl(116, 70%, 50%)";
            obj["data"] = newPayerArr;
            newArr.push(obj);
          });
        }

        state.denialByPayers_denialOverviewPayersAmtArr = newArr;
      } else {
        state.denialByPayers_denialOverviewPayersAmtArr = [];
      }

      // handle for denial By Payers screen denialPayersVolume array here
      if (data?.denialPayersVolume?.length) {
        let res_data = data?.denialPayersVolume;

        let newArr = [];
        var obj = res_data[0];
        // for handle Three year logic
        if ("year" in obj) {
          const uniquePayer = [
            ...new Set(res_data.map((item) => item?.payerName)),
          ];

          uniquePayer.forEach((payer) => {
            const payer_data = res_data.filter(
              (item) => item?.payerName === payer
            );
            let newPayerArr = [];

            payer_data.forEach((item, i) => {
              newPayerArr.push({
                x: item?.year,
                y: item?.denailCount,
                payer: item?.payerName,
              });
            });

            let obj = {};
            obj["id"] = getLegendsExtraShortName(payer);
            obj["payer"] = payer;
            obj["color"] = "hsl(116, 70%, 50%)";
            obj["data"] = newPayerArr;
            newArr.push(obj);
          });
        } else {
          const uniquePayer = [
            ...new Set(res_data.map((item) => item?.payerName)),
          ];

          uniquePayer.forEach((payer) => {
            const payer_data = res_data.filter(
              (item) => item?.payerName === payer
            );

            let newPayerArr = [];

            payer_data.forEach((item, i) => {
              newPayerArr.push({
                x: item?.monthName.substring(0, 3),
                y: item?.denailCount,
                payer: item?.payerName,
              });
            });

            let obj = {};
            obj["id"] = getLegendsExtraShortName(payer);
            obj["payer"] = payer;
            obj["color"] = "hsl(116, 70%, 50%)";
            obj["data"] = newPayerArr;
            newArr.push(obj);
          });
        }

        state.denialByPayers_denialOverviewVolumeArr = newArr;
      } else {
        state.denialByPayers_denialOverviewVolumeArr = [];
      }
    },

    // for denial by payer dash screen initial data
    setDenialByPayersKpisInitial_data: (state, action) => {
      let data = action.payload;

      if (data.payerFilter && data?.payerFilter?.length) {
        const filter = data?.payerFilter;
        let res = filter.map((item, index) => {
          return {
            value: item?.payerName,
            label: item?.payerName,
          };
        });
        state.PayersFilterArr = res;
      } else {
        state.PayersFilterArr = [];
      }
      if (data?.denailOverviewCPT?.length) {
        // handle for denial By Payers screen denailOverviewCPT array here
        let newArr = [];
        let codes = [];

        const asc_order = getAscArray(data?.denailOverviewCPT, "denailAmount");

        asc_order.forEach((item, index) => {
          const cpt_name = item?.cpt;

          let obj = {};
          obj["country"] = item?.cpt;
          obj[cpt_name] = item?.denailAmount;

          newArr.push(obj);
          codes.push(item?.cpt);
        });

        // const asc_codes = getAscArrayForNumberValus(codes);

        state.denialByPayers_denailOverviewCPTAmtArr = newArr;
        state.denialByPayers_denailOverviewCPTCodesArr = codes;
      } else {
        state.denialByPayers_denailOverviewCPTAmtArr = [];
        state.denialByPayers_denailOverviewCPTCodesArr = [];
      }

      // handle for denial By Payers screen denialOverviewCARC array here
      if (data?.denialOverviewCARC?.length) {
        let newArr = [];
        let codes = [];

        const asc_order = getAscArray(
          data?.denialOverviewCARC,
          "denial_Amount"
        );

        asc_order.forEach((item, index) => {
          const reasonCode_name = item?.reasonCode;

          let obj = {};
          obj["country"] = item?.reasonCode;
          obj["description"] = item?.description;
          obj["denial_Count"] = item?.denial_Count;
          obj[reasonCode_name] = item?.denial_Amount;

          newArr.push(obj);
          codes.push(item?.reasonCode);
        });

        // const asc_codes = getAscArrayForNumberValus(codes);

        state.denialByPayers_denialOverviewCARCAmtArr = newArr;
        state.denialByPayers_denialOverviewCARCodesArr = codes;
      } else {
        state.denialByPayers_denialOverviewCARCAmtArr = [];
        state.denialByPayers_denialOverviewCARCodesArr = [];
      }

      // handle for denial By Payers screen denailOverviewPayer array here
      if (data?.denailOverviewPayer?.length) {
        let res_data = data?.denailOverviewPayer;

        let newArr = [];
        var obj = res_data[0];
        // for handle Three year logic
        if ("year" in obj) {
          const uniquePayer = [
            ...new Set(res_data.map((item) => item?.payerName)),
          ];

          uniquePayer.forEach((payer) => {
            const payer_data = res_data.filter(
              (item) => item?.payerName === payer
            );
            let newPayerArr = [];

            payer_data.forEach((item, i) => {
              newPayerArr.push({
                x: item?.year,
                y: item?.denailAmount,
                payer: item?.payerName,
              });
            });

            let obj = {};
            obj["id"] = getLegendsExtraShortName(payer);
            obj["payer"] = payer;
            obj["color"] = "hsl(116, 70%, 50%)";
            obj["data"] = newPayerArr;
            newArr.push(obj);
          });
        } else {
          const uniquePayer = [
            ...new Set(res_data.map((item) => item?.payerName)),
          ];

          uniquePayer.forEach((payer) => {
            const payer_data = res_data.filter(
              (item) => item?.payerName === payer
            );

            let newPayerArr = [];

            payer_data.forEach((item, i) => {
              newPayerArr.push({
                x: item?.monthName.substring(0, 3),
                y: item?.denailAmount,
                payer: item?.payerName,
              });
            });

            let obj = {};
            obj["id"] = getLegendsExtraShortName(payer);
            obj["payer"] = payer;
            obj["color"] = "hsl(116, 70%, 50%)";
            obj["data"] = newPayerArr;
            newArr.push(obj);
          });
        }

        state.denialByPayers_denialOverviewPayersAmtArr = newArr;
      } else {
        state.denialByPayers_denialOverviewPayersAmtArr = [];
      }

      // handle for denial By Payers screen denialOverviewVolume array here
      if (data?.denialOverviewVolume?.length) {
        let res_data = data?.denialOverviewVolume;

        let newArr = [];
        var obj = res_data[0];
        // for handle Three year logic
        if ("year" in obj) {
          const uniquePayer = [
            ...new Set(res_data.map((item) => item?.payerName)),
          ];

          uniquePayer.forEach((payer) => {
            const payer_data = res_data.filter(
              (item) => item?.payerName === payer
            );
            let newPayerArr = [];

            payer_data.forEach((item, i) => {
              newPayerArr.push({
                x: item?.year,
                y: item?.denailCount,
                payer: item?.payerName,
              });
            });

            let obj = {};
            obj["id"] = getLegendsExtraShortName(payer);
            obj["payer"] = payer;
            obj["color"] = "hsl(116, 70%, 50%)";
            obj["data"] = newPayerArr;
            newArr.push(obj);
          });
        } else {
          const uniquePayer = [
            ...new Set(res_data.map((item) => item?.payerName)),
          ];

          uniquePayer.forEach((payer) => {
            const payer_data = res_data.filter(
              (item) => item?.payerName === payer
            );

            let newPayerArr = [];

            payer_data.forEach((item, i) => {
              newPayerArr.push({
                x: item?.monthName.substring(0, 3),
                y: item?.denailCount,
                payer: item?.payerName,
              });
            });

            let obj = {};
            obj["id"] = getLegendsExtraShortName(payer);
            obj["payer"] = payer;
            obj["color"] = "hsl(116, 70%, 50%)";
            obj["data"] = newPayerArr;
            newArr.push(obj);
          });
        }

        state.denialByPayers_denialOverviewVolumeArr = newArr;
      } else {
        state.denialByPayers_denialOverviewVolumeArr = [];
      }
    },
  },
});

export const {
  setDenialOverview_data,
  setPayersForDenialsByPayer_Data,
  setDenialByPayersKpis_data,
  setDenialByPayersKpisInitial_data,
} = DenialOverviewDashboardSlice.actions;

// get denials dash list
export const gettingDenialsOverviewKpis_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialOverviewFilter",
          //   ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.denialOverviewFilter;

    try {
      dispatch(
        setListingState({
          listing: "denialOverviewFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      ///// API CALL //////

      let params = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...(sliceState?.selFilterType !== "" && {
          filter:
            sliceState.selFilterType == "3M"
              ? "3M"
              : sliceState.selFilterType == "1Y"
              ? "1Y"
              : sliceState.selFilterType == "3Y"
              ? "3Y"
              : sliceState.selFilterType,
        }),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_DENIALS_OVERVIEW_KPIS,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      if (!res?.data?.success) {
        // toast.error(`Something went wrong`, {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        dispatch(setDenialOverview_data([]));
        dispatch(
          setListingState({
            listing: "denialOverviewFilter",
            value: {
              loadingMore: "",
            },
          })
        );
      } else {
        dispatch(setDenialOverview_data(res?.data?.data));
        dispatch(
          setListingState({
            listing: "denialOverviewFilter",
            value: {
              // hasMore: res?.data?.data?.length === sliceState.pageSize,
              loadingMore: "",
            },
          })
        );
      }
      return res;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "denialOverviewFilter",
          value: {
            initialLoading: false,
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      dispatch(setDenialOverview_data([]));
      return false;
    }
  };

// get payers list for denial by payer dash screen
export const getPayersForDenialsByPayersDash_Call =
  () => async (dispatch, getState) => {
    let sliceState =
      getState().npReducers.filters.denialByPayersDashboardFilter;

    try {
      dispatch(
        setListingState({
          listing: "denialByPayersDashboardFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      ///// API CALL //////

      let params = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_DENIALS_BY_PAYERS_PAYERS_SERVICE_LINE_LIST,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      if (!res?.data?.success) {
        // toast.error(`Something went wrong`, {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        dispatch(setPayersForDenialsByPayer_Data([]));
        dispatch(
          setListingState({
            listing: "denialByPayersDashboardFilter",
            value: {
              loadingMore: "",
            },
          })
        );
      } else {
        dispatch(setPayersForDenialsByPayer_Data(res?.data?.data));
        dispatch(
          setListingState({
            listing: "denialByPayersDashboardFilter",
            value: {
              loadingMore: "",
            },
          })
        );
      }
      return res;
    } catch (e) {
      // console.log("catch_e--->>", e);

      dispatch(
        setListingState({
          listing: "denialByPayersDashboardFilter",
          value: {
            loadingMore: "",
          },
        })
      );

      dispatch(setPayersForDenialsByPayer_Data([]));

      return false;
    }
  };

// get data for denial by payer dash screen
export const gettingDenialsByPayerKpis_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialByPayersDashboardFilter",
          //   ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState =
      getState().npReducers.filters.denialByPayersDashboardFilter;

    try {
      if (!sliceState?.payers?.length) {
        dispatch(gettingDenialsByPayerKpisInitialData_Call());
      } else {
        dispatch(
          setListingState({
            listing: "denialByPayersDashboardFilter",
            value: {
              loadingMore: "data-loading",
            },
          })
        );

        ///// API CALL //////

        let params = {
          ...(sliceState?.from !== "" && {
            from: sliceState.from,
          }),
          ...(sliceState?.to !== "" && {
            to: sliceState.to,
          }),

          ...(sliceState?.payers?.length > 0 && {
            payerIds: getMultiSelectFilterIdForApi_ForId(sliceState?.payers),
          }),

          ...(sliceState?.selFilterType !== "" && {
            filter:
              sliceState.selFilterType == "3M"
                ? "3M"
                : sliceState.selFilterType == "1Y"
                ? "1Y"
                : sliceState.selFilterType == "3Y"
                ? "3Y"
                : sliceState.selFilterType,
          }),
        };

        const res = await genericAxiosCall(
          serverRoutes?.GET_DENIALS_BY_PAYERS_KPIS,
          "get",
          "",
          params
        );

        ////// API CALL END //////////////

        if (!res?.data?.success) {
          // toast.error(`Something went wrong`, {
          //   position: toast.POSITION.TOP_CENTER,
          // });
          dispatch(setDenialByPayersKpis_data([]));
          dispatch(
            setListingState({
              listing: "denialByPayersDashboardFilter",
              value: {
                loadingMore: "",
              },
            })
          );
        } else {
          dispatch(setDenialByPayersKpis_data(res?.data?.data));
          dispatch(
            setListingState({
              listing: "denialByPayersDashboardFilter",
              value: {
                loadingMore: "",
              },
            })
          );
        }
        return res;
      }
    } catch (e) {
      // console.log("catch_e--->>", e);

      dispatch(
        setListingState({
          listing: "denialByPayersDashboardFilter",
          value: {
            initialLoading: false,
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      dispatch(setDenialByPayersKpis_data([]));
      return false;
    }
  };

// get data for denial by payer dash screen initial data
export const gettingDenialsByPayerKpisInitialData_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "denialByPayersDashboardFilter",
          ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState =
      getState().npReducers.filters.denialByPayersDashboardFilter;

    try {
      if (sliceState?.payers?.length) {
        dispatch(gettingDenialsByPayerKpis_Call());
      } else {
        dispatch(
          setListingState({
            listing: "denialByPayersDashboardFilter",
            value: {
              loadingMore: "data-loading",
            },
          })
        );

        ///// API CALL //////

        let params = {
          ...(sliceState?.from !== "" && {
            from: sliceState.from,
          }),
          ...(sliceState?.to !== "" && {
            to: sliceState.to,
          }),
          ...(sliceState?.selFilterType !== "" && {
            filter:
              sliceState.selFilterType == "3M"
                ? "3M"
                : sliceState.selFilterType == "1Y"
                ? "1Y"
                : sliceState.selFilterType == "3Y"
                ? "3Y"
                : "Q",
          }),
        };

        const res = await genericAxiosCall(
          serverRoutes?.GET_DENIALS_OVERVIEW_KPIS,
          "get",
          "",
          params
        );
        ////// API CALL END //////////////

        if (!res?.data?.success) {
          // toast.error(`Something went wrong`, {
          //   position: toast.POSITION.TOP_CENTER,
          // });
          dispatch(setDenialByPayersKpisInitial_data([]));
          dispatch(
            setListingState({
              listing: "denialByPayersDashboardFilter",
              value: {
                loadingMore: "",
              },
            })
          );
        } else {
          dispatch(setDenialByPayersKpisInitial_data(res?.data?.data));
          dispatch(
            setListingState({
              listing: "denialByPayersDashboardFilter",
              value: {
                // hasMore: res?.data?.data?.length === sliceState.pageSize,
                loadingMore: "",
              },
            })
          );
        }
        return res;
      }
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "denialByPayersDashboardFilter",
          value: {
            initialLoading: false,
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      dispatch(setDenialByPayersKpisInitial_data([]));
      return false;
    }
  };

export default DenialOverviewDashboardSlice.reducer;

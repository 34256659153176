import axios from "../AxiosConfig/axios";
import store from "../Redux/store";

import { encodeData } from "./../components/common/Utils/utils";
import { serverRoutes } from "./../constants/serverRoutes";

const user = {
  login(body) {
    return axios.get(`/user/${body.email}/${body.password}`);
  },
  loginAttempts(offSet, limit) {
    return axios.get(`/user/GetLoginAttempts/${offSet}/${limit}`);
  },

  // getting claim status code
  getClaimStatusCode() {
    return axios.get(`/ClaimStatusCode/GetClaimStatusCode`);
  },

  // getting logAcivity
  getActivityLogs(params) {
    return axios.get(`/ActivityLog/Log?${encodeData(params)}`);
  },

  // setting logAcivity
  setActivityLogs(body) {
    return axios.post(`/ActivityLog/Save`, body);
  },
  renewToken(body) {
    return axios.get(`/user/RenewToken`, {
      headers: {
        Authorization: `Bearer ${body.accessToken}`,
        // ["X-Version"]: "1.0",
        // ["X-Domain"]: "8700a71f-b5f5-43a5-be17-492c67008132",
      },
    });
  },
  verifyUser(body) {
    return axios.get(
      `/user/VerifyMFA/?email=${body.email}&userid=${body?.verificationCode}&code=${body?.code}`
    );
  },
  forgotPassword(body) {
    return axios.get(`/password/${body.email}`);
  },
  resetPassword(body) {
    return axios.post(`/password`, {
      code: body.code,
      password: body.password,
    });
  },
  register(body) {
    return axios.post(`${serverRoutes.SIGNUP}`, body);
  },
  getAllCustomers() {
    return axios.get(`/customer/0/100`);
  },

  // get all customers
  getAllCustomerLicence() {
    return axios.get(`/Customer/CustomerLicence`);
  },

  // get single customer data
  getSingleCustomer(id) {
    return axios.get(`/customer/${id}`);
  },

  getJCode(start = 0, limit = 10, filter = "", orderBy = "", order = "") {
    // return axios.get(`CPTCode/GetJCode`);
    return axios.get(
      `https://roversmwdev.azurewebsites.net/CPTCode/GetJCode?start=${start}&limit=${limit}&OrderBy=${orderBy}&Order=${order}&Filter=${filter}`
    );
  },

  AddJCode(body) {
    return axios.post(`CPTCode/SaveJCode`, body);
  },

  updateJCode(body) {
    return axios.post(`CPTCode/SaveJCode`, body);
  },

  deleteJCode(id) {
    return axios.delete(`CPTCode/DeleteJCode?JCodeId=${id}`);
  },

  getAdminCode(start = 0, limit = 10, orderBy = "", order = "", filter = "") {
    return axios.get(
      `https://roversmwdev.azurewebsites.net/CPTCode/GetAdminCode?start=${start}&limit=${limit}&OrderBy=${orderBy}&Order=${order}&Filter=${filter}`
    );
  },

  addAdminCode(body) {
    return axios.post(`CPTCode/SaveAdminCode`, body);
  },

  deleteAdminCode(id) {
    return axios.delete(`CPTCode/DeleteAdminCode?AdminCodeId=${id}`);
  },

  updateAdminCode(body) {
    return axios.post(`CPTCode/SaveAdminCode`, body);
  },

  getAllRoles() {
    return axios.get(`Role`);
  },

  getAllUsers(domainId) {
    return axios.get(serverRoutes.GET_USERS);
  },
  getSingleUser(userId) {
    return axios.get(`${serverRoutes.GET_USERS}/${userId}`);
  },

  createUser(body) {
    return axios.post(`/user`, {
      firstName: body.firstname,
      lastName: body.lastname,
      email: body.email,
      contactNo: body.contactNo,
      displayName: body.displayname,
      password: body.password,
      Roles: body.roles,
      domainID: body.domainID,
      mfaDeliverChoice: body.mfaDeliverChoice,
      Subscriptions: [{ subscriptionId: body.Subscriptions }],
    });
  },
  updateUser(body) {
    return axios.put("/user", body);
  },
  updateCustomer(body) {
    return axios.put(`/customer`, {
      customerId: body.customerId,
      firstName: body.firstname,
      lastName: body.lastname,
      email: body.email,
      contactNo: body.contactNo,
      orgName: body.orgName,
      domainId: body.domainId,
      Addresses: body.Addresses,
      Subscriptions: body.Subscriptions,
    });
  },
  changePassword(body) {
    return axios.put(
      `/password?userId=${body.id}&newPassword=${body.password}&email=${body.email}`
    );
  },
  deleteCustomer(body) {
    return axios.delete(`/customer/${body.customer_id}`);
  },

  activeCustomerSubs(obj) {
    return axios.post(
      `/customer/ActiveCustomer?CustomerId=${obj.customerId}&DomainId=${obj.domainId}&SubscriptionId=${obj.subscriptionId}`
    );
  },

  deleteUser(user_id) {
    return axios.delete(`/user/${user_id}`);
  },
  getUser(user_id) {
    return axios.get(`/user/GetUserById?userid=${user_id}`);
  },

  getMicrosoftUser(email) {
    try {
      let accessToken = store.getState().pReducers.user.applicationToken;
      return axios.get(`${serverRoutes?.RETRIEVE_USER_BY_EMAIL}${email}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          ["X-Version"]: "1.0",
          ["X-Domain"]: "8700a71f-b5f5-43a5-be17-492c67008132",
        },
      });
    } catch (err) {
      // console.log("getMicrosoftUser_err--->>", err);
    }
  },
  updateProfile(body) {
    return axios.post(`/user/UpdateUserProfile/?UserId=${body.UserId}`, body);
  },
  sendVerificationCode(email) {
    return axios.post(`${serverRoutes.MFA_CODE_VERIFICATION}${email}`);
  },
  updateMcfChoise(email, value) {
    return axios.post(
      `/user/UpdateUserMFAChoice?email=${email}&mfachoice=${value}`
    );
  },
  adminResetPassword(body) {
    return axios.put(
      `https://roversmwdev.azurewebsites.net/password?email=${body.email}&newPassword=${body.password}`
    );
  },

  updateLicense(body) {
    return axios.put(
      `https://roversmwdev.azurewebsites.net/customer/UpdateSeats?SubcriptionId=${body.subscriptionId}&Seats=${body.seats}`
    );
  },
  savePreferences(body) {
    // let queryparams = `UserId=${body.userId}&NoOfRecordInTable=${body.NoOfRecordInTable}&Theme=${body.Theme}&Navigation=${body.Navigation}&email=${body.email}`;
    // return axios.post(
    //   `https://roversmwdev.azurewebsites.net/user/SaveUserPreference?${queryparams}`
    // );
    return axios.post(`/user/SaveUserPreference`, body);
  },

  saveDenialsStatusCodeConfig(params) {
    return axios.post(
      `/ClaimStatusCode/SaveDenialStatusCode?${encodeData(params)}`
    );
  },
};

export default user;

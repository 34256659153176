import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import browserRoute from "../../../constants/browserRoutes";
import useAuth from "../CustomHooks/useAuth";

function RequireAuth() {
  const [userToken] = useAuth();
  const location = useLocation();
  return userToken ? (
    <Outlet />
  ) : (
    <Navigate
      to={browserRoute.REACT_SERVER_DEFAULT_URL}
      state={{ from: location }}
      replace
    />
  );
}

export default RequireAuth;

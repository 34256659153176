import { createSlice } from "@reduxjs/toolkit";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";
import { serverRoutes } from "../../../constants/serverRoutes";
import { getCurrentClientId } from "../../../components/common/Utils/utils";

export const claimProgressSlice = createSlice({
  name: "claimProgress",
  initialState: {
    claimProgressData: [],
    claimProgressLoader: false,
  },
  reducers: {
    setClaimProgressData: (state, action) => {
      let payload = action.payload;
      if (payload?.length == 1) {
        state.claimProgressData = payload?.[0];
      } else {
        state.claimProgressData = {};
      }
      state.claimProgressLoader = false;
    },
    setClaimProgressLoader: (state, action) => {
      let payload = action.payload;
      state.claimProgressLoader = payload;
    },
  },
});

export const { setClaimProgressData, setClaimProgressLoader } =
  claimProgressSlice.actions;

// getting Claim Progress data
export const claimProgressRequestCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    dispatch(setClaimProgressLoader(true));
    let sliceState = getState().npReducers.filters.claimProgress;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;
    let sliceStateUser = getState().pReducers.user;
    let sliceStateForDate =
      getState().npReducers.filters.denialSreensCommonFilter;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    try {
      ///// API CALL //////

      let params = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };

      let resTest2 = await genericAxiosCall(
        serverRoutes?.GET_CLAIM_PROGRESS,
        "get",
        "",
        params
      );
      dispatch(setClaimProgressData(resTest2.data.data));

      return resTest2;

      ////// //////////////
    } catch (e) {
      return false;
    }
  };

export default claimProgressSlice.reducer;

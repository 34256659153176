import {
  encodeData,
  refreshMsalToken,
  refreshToken,
} from "../components/common/Utils/utils";
import store from "../Redux/store";
import axios from "./axiosFormData";

const checkTokenExpiration = async () => {
  // Get the expiration time from storage

  try {
    const { applicationTokenExpiry, loginType } =
      store?.getState()?.pReducers?.user;
    if (applicationTokenExpiry) {
      const expiryDate = new Date(applicationTokenExpiry.toString());

      if (
        loginType &&
        loginType === "Azure AD" &&
        expiryDate &&
        new Date().getTime() >=
          new Date(
            expiryDate?.setMinutes(expiryDate?.getMinutes() - 20)
          ).getTime()
      ) {
        await refreshMsalToken();
      } else if (
        loginType &&
        loginType === "Local" &&
        expiryDate &&
        new Date().getTime() >=
          new Date(
            expiryDate?.setMinutes(expiryDate?.getMinutes() - 20)
          ).getTime()
      ) {
        await refreshToken();
      }
    }
  } catch (error) {}
};

const genericAxiosCallFormData = async (url, method, data, params) => {
  await checkTokenExpiration();

  return axios({
    url: `${url}${params ? encodeData(params) : ""}`,
    method: method,
    data: data,
  });
};

export default genericAxiosCallFormData;

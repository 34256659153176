import React, { useEffect, useRef, useState } from "react";

import { Box, Button, TextField } from "@mui/material";
import CommentSendIcon from "../../assets/svgs/CommentSendIcon";
import PropTypes from "prop-types";

import ClearChatIcon from "../ChatBot/ClearChatIcon";
import genericAxiosCall, {
  checkTokenExpiration,
} from "../../AxiosConfig/genericAxiosCall";
import { serverRoutes } from "../../constants/serverRoutes";
import { headerVersion, returnUserId } from "../common/Utils/utils";
import ChatbotLoading from "../../assets/images/ChatbotLoading.gif";

import axios from "axios";
import { useSelector } from "react-redux";
import { Popover } from "antd";
import ExplainIcon from "./ExplainIcon";
import RepairIcon from "./RepairIcon";
import ChatIconBot from "../../pages/AskZoe/ChatIconBot";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ChatBodyDashboard = () => {
  const chatbotEnum = {
    bot: "bot",
    user: "user",
    policy: "policy",
    claim: "claim",
  };
  const data = [
    {
      msg: "Welcome to the DataRovers Insights, How may i help you?",
      role: chatbotEnum?.bot,
      citation: "",
    },
  ];
  const errorsList = ["An error occurred while processing the request"];
  const errorHandlerMsg =
    "Hi! I'm a visualization chatbot here to help with your queries and visualize them. If your question isn't about visualization, could you rephrase it?";

  const msgPointList = [
    "date",
    "payer",
    "cpt",
    "reason",
    "code",
    "charge",
    "denial",
    "facility",
    "group",
  ];

  const messagesDivRef = useRef(null);
  const [input, setInput] = useState("");
  const [mainOutput, setMainOutput] = useState([]);
  const [showTableColumnFilter, setShowTableColumnFilter] = useState("");

  const [loading, setLoading] = useState(false);
  const [promptOpen, setPromptOpen] = useState("");
  const [repairMsg, setRepairMsg] = useState("");
  const scrollToBottom = () => {
    if (messagesDivRef.current) {
      messagesDivRef.current.scrollTop =
        messagesDivRef.current.scrollHeight + 20;
    }
  };

  const closePrompt = (index) => {
    setPromptOpen("");
  };
  const handleLoading = (val, output) => {
    setLoading(val);
    if (val) {
      output.push({
        msg: "loading",
        role: "loading",
        citation: "",
      });
    } else {
      output.pop();
    }
    setMainOutput(output);
  };
  const saveMessageToLocalHost = (obj) => {
    let savedMessages = localStorage.getItem("saved_Messages_Dashboard");
    setTimeout(scrollToBottom, 0);
    if (savedMessages) {
      let arr = [];
      savedMessages = JSON.parse(savedMessages);

      arr.push(...savedMessages);
      arr.push(obj);
      localStorage.setItem("saved_Messages_Dashboard", JSON.stringify(arr));
    } else {
      let arr = [];
      //  savedMessages = JSON.parse(savedMessages);
      //  arr.push([...savedMessages]);
      // arr.push(tempMessage);
      arr.push(obj);
      localStorage.setItem("saved_Messages_Dashboard", JSON.stringify(arr));
    }
  };

  const loadSavedMessages = () => {
    const messages = JSON.parse(
      localStorage.getItem("saved_Messages_Dashboard")
    );
    if (messages?.length) {
      setTimeout(scrollToBottom, 0);
      messages.unshift(...data);
      setMainOutput(messages);
    } else {
      setMainOutput(data);
    }
  };

  const deleteSavedMessages = () => {
    localStorage.removeItem("saved_Messages_Dashboard");
    setMainOutput(data);
  };

  const handleSyntaxText = (msg, item) => {
    if (item?.image) {
      // const imageUrl = `data:image/png;base64,${responseBase64}`;

      return <img src={msg} />;
    } else {
      return <pre style={{ whiteSpace: "pre-wrap" }}>{msg}</pre>;
    }
  };

  const fetchData = async (msg, outputTemp) => {
    try {
      const params = {
        prompt: msg,
        instructions: [],
      };
      handleLoading(true, outputTemp);
      const containsElement = msgPointList.some((element) =>
        msg?.toLowerCase().includes(element)
      );
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${applicationToken}`,
        ["X-Version"]: "1.0",
        [!domainID ? "verification-api" : "X-Domain"]: domainID,
        ["X-Subscription"]: subscriptionID,
        ["TimeZoneHeader"]: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      if (containsElement) {
        axios
          .post(serverRoutes?.SEND_ASK_CHATBOT, params, {
            // responseType: "arraybuffer",
            headers: headers,
          })
          .then((response) => {
            if (
              errorsList?.includes(response?.data?.response) ||
              !response?.data?.success
            ) {
              handleLoading(false, outputTemp);

              let output = [...outputTemp];
              const botMsg = {
                msg: errorHandlerMsg,
                role: chatbotEnum?.bot,
                image: false,
                userMsg: msg,
                // citation: citation,
                // type: chatbotEnum?.policy,
              };
              output.push(botMsg);

              saveMessageToLocalHost(botMsg);

              setMainOutput(output);
            } else {
              const data = JSON.parse(response?.data?.response);
              const raster = data?.raster;
              const code = data?.code;
              const imageUrl = `data:image/png;base64,${raster}`;
              // const imageUrl = `data:${
              //   response.headers["content-type"]
              // };base64,${btoa(
              //   String.fromCharCode(...new Uint8Array(response.data))
              // )}`;

              handleLoading(false, outputTemp);

              let output = [...outputTemp];
              const botMsg = {
                msg: imageUrl,
                role: chatbotEnum?.bot,
                image: true,
                userMsg: msg,
                code: code,

                // citation: citation,
                // type: chatbotEnum?.policy,
              };
              output.push(botMsg);

              saveMessageToLocalHost(botMsg);

              setMainOutput(output);
            }
          })
          .catch((err) => {
            handleLoading(false, outputTemp);
            let output = [...outputTemp];

            const botMsg = {
              msg:
                err?.response?.status === 503
                  ? err?.response?.data?.message
                  : errorHandlerMsg,
              role: chatbotEnum?.bot,
              citation: "",
              userMsg: msg,
            };
            output.push(botMsg);
            saveMessageToLocalHost(botMsg);

            setMainOutput(output);
          });
      } else {
        handleLoading(false, outputTemp);
        let output = [...outputTemp];
        const botMsg = {
          msg: errorHandlerMsg,
          role: chatbotEnum?.bot,
          image: false,
          hitUrl: true,
          userMsg: msg,

          // citation: citation,
          // type: chatbotEnum?.policy,
        };
        output.push(botMsg);

        saveMessageToLocalHost(botMsg);
        setInput("");

        setMainOutput(output);
      }

      // handleSyntaxText(resMsg, citation);
    } catch (error) {
      // return { resMsg: "Error occurred while fetching data.", citation: "" };
    }
  };
  const { applicationToken, domainID, subscriptionID } = useSelector(
    (state) => state.pReducers.user
  );
  const fetchData2 = async (msg, outputTemp) => {
    try {
      // const params = {
      //   Messages: getMessages(msg),
      //   chatSession: true,
      // };

      const params = {
        UserMsg: msg,
        chatSession: true,
        userId: returnUserId(),
        // index: index,
        // mac: macAddress,
      };
      handleLoading(true, outputTemp);
      // const response = await genericAxiosCall(
      //   serverRoutes?.SEND_ASK_ROVERS,
      //   "post",
      //   params,
      //   ""
      // );
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${applicationToken}`,
        ["X-Version"]: "1.0",
        [!domainID ? "verification-api" : "X-Domain"]: domainID,
        ["X-Subscription"]: subscriptionID,
        ["TimeZoneHeader"]: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      await checkTokenExpiration();
      const response = await axios.post(
        serverRoutes?.SEND_ASK_COPILOT,
        params,
        {
          //  cancelToken: cancelToken.token,
          headers: headers,
        }
      );
      handleLoading(false, outputTemp);
      if (
        response?.data?.success &&
        response?.data?.data?.choices?.length &&
        response?.data?.data?.choices[0]?.message &&
        response?.data?.data?.choices[0]?.message?.content
      ) {
        const responseMsg = response?.data?.data?.choices[0]?.message?.content;

        let output = [...outputTemp];

        const botMsg = {
          msg: responseMsg,
          role: chatbotEnum?.bot,
          image: false,
          // citation: citation,
          // type: chatbotEnum?.policy,
        };
        output.push(botMsg);

        saveMessageToLocalHost(botMsg);

        setMainOutput(output);
      } else {
        let output = [...outputTemp];
        const botMsg = {
          msg: errorHandlerMsg,
          role: chatbotEnum?.bot,
          image: false,
          // citation: citation,
          // type: chatbotEnum?.policy,
        };
        output.push(botMsg);

        saveMessageToLocalHost(botMsg);

        setMainOutput(output);
      }
    } catch (error) {
      handleLoading(false, outputTemp);

      let output = [...outputTemp];
      const botMsg = {
        msg: errorHandlerMsg,
        role: chatbotEnum?.bot,
        image: false,
        // citation: citation,
        // type: chatbotEnum?.policy,
      };
      output.push(botMsg);

      saveMessageToLocalHost(botMsg);

      setMainOutput(output);
    }
  };
  const fetchDataWithSample2 = async (msg) => {
    setTimeout(scrollToBottom, 0);
    let output = [...mainOutput];
    setLoading(true);
    let userMsg = {
      msg: msg,
      role: chatbotEnum?.user,
      citation: "",
      time: new Date().getTime(),
    };
    output.push(userMsg);
    setMainOutput(output);
    saveMessageToLocalHost(userMsg);
    setInput("");
    fetchData2(msg, output);
  };
  const notFoundLink = (item) => {
    fetchDataWithSample2(item?.userMsg);
  };
  const handleChangeInput = (event) => {
    setInput(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      handleSubmit();
    }
  };

  const handleSubmit = async (event) => {
    if (input) {
      setTimeout(scrollToBottom, 0);
      let output = [...mainOutput];
      setLoading(true);
      let userMsg = {
        msg: input,
        role: chatbotEnum?.user,
        citation: "",
      };
      output.push(userMsg);

      setMainOutput(output);

      saveMessageToLocalHost(userMsg);

      setInput("");
      fetchData(input, output);
    }
  };

  const handleExplain = (item) => {
    let outputTemp = [...mainOutput];
    handleLoading(true, outputTemp);

    const params = {
      prompt: item?.code,
      instructions: [],
    };
    axios
      .post(serverRoutes?.SEND_ASK_CHATBOT_EXPLAIN, params, {
        // responseType: "arraybuffer",
        headers: headerVersion(),
      })
      .then((response) => {
        if (response?.data?.success && response?.data?.response) {
          const data = JSON.parse(response?.data?.response)?.[0]?.[0];

          // const imageUrl = `data:${
          //   response.headers["content-type"]
          // };base64,${btoa(
          //   String.fromCharCode(...new Uint8Array(response.data))
          // )}`;

          handleLoading(false, outputTemp);

          let output = [...outputTemp];
          const botMsg = {
            msg: data?.explanation,
            role: chatbotEnum?.bot,
            image: false,
            // userMsg: msg,
            // code: code,

            // citation: citation,
            // type: chatbotEnum?.policy,
          };
          output.push(botMsg);

          saveMessageToLocalHost(botMsg);

          setMainOutput(output);
        } else {
          let output = [...outputTemp];
          const botMsg = {
            msg: errorHandlerMsg,
            role: chatbotEnum?.bot,
            image: false,
            // userMsg: msg,
            // citation: citation,
            // type: chatbotEnum?.policy,
          };
          output.push(botMsg);

          saveMessageToLocalHost(botMsg);

          setMainOutput(output);
        }
      })
      .catch((err) => {
        handleLoading(false, outputTemp);
        let output = [...outputTemp];

        const botMsg = {
          msg:
            err?.response?.status === 503
              ? err?.response?.data?.message
              : errorHandlerMsg,
          role: chatbotEnum?.bot,
          citation: "",
          userMsg: msg,
        };
        output.push(botMsg);
        saveMessageToLocalHost(botMsg);

        setMainOutput(output);
      });
  };
  const handleRepair = (item) => {
    if (repairMsg?.trim()) {
      let outputTemp = [...mainOutput];
      handleLoading(true, outputTemp);
      closePrompt();
      setTimeout(scrollToBottom, 0);

      const params = {
        prompt: item?.code,
        instructions: [repairMsg],
      };
      axios
        .post(serverRoutes?.SEND_ASK_CHATBOT_REPAIR, params, {
          // responseType: "arraybuffer",
          headers: headerVersion(),
        })
        .then((response) => {
          if (
            errorsList?.includes(response?.data?.response) ||
            !response?.data?.success
          ) {
            handleLoading(false, outputTemp);

            let output = [...outputTemp];
            const botMsg = {
              msg: errorHandlerMsg,
              role: chatbotEnum?.bot,
              image: false,
              // userMsg: msg,
              // citation: citation,
              // type: chatbotEnum?.policy,
            };
            output.push(botMsg);

            saveMessageToLocalHost(botMsg);

            setMainOutput(output);
          } else {
            const data = JSON.parse(response?.data?.response);
            const raster = data?.raster;
            const code = data?.code;
            const imageUrl = `data:image/png;base64,${raster}`;
            // const imageUrl = `data:${
            //   response.headers["content-type"]
            // };base64,${btoa(
            //   String.fromCharCode(...new Uint8Array(response.data))
            // )}`;

            handleLoading(false, outputTemp);

            let output = [...outputTemp];
            const botMsg = {
              msg: imageUrl,
              role: chatbotEnum?.bot,
              image: true,
              // userMsg: msg,
              // code: code,

              // citation: citation,
              // type: chatbotEnum?.policy,
            };
            output.push(botMsg);

            saveMessageToLocalHost(botMsg);

            setMainOutput(output);
          }
        })
        .catch((err) => {
          console.log("hey khizer===", err);
          handleLoading(false, outputTemp);
          let output = [...outputTemp];

          const botMsg = {
            msg:
              err?.response?.status === 503
                ? err?.response?.data?.message
                : errorHandlerMsg,
            role: chatbotEnum?.bot,
            citation: "",
            // userMsg: msg,
          };
          output.push(botMsg);
          saveMessageToLocalHost(botMsg);

          setMainOutput(output);
        });
    }
  };
  // For tabs

  useEffect(() => {
    loadSavedMessages();
  }, []);
  useEffect(() => {
    if (!promptOpen) {
      setRepairMsg("");
    }
  }, [promptOpen]);

  return (
    <>
      <div className="DR-Custom-Chat-main">
        <div className="DR-Custom-chat-header">DataRovers Insights</div>

        <div className="DR-Custom-Chat-msg-main mt-4" ref={messagesDivRef}>
          {mainOutput?.map((item, index) => {
            return (
              <div key={index}>
                {item?.role === chatbotEnum?.bot ? (
                  <div
                    className="DR-Custom-Chat-msg-bot"
                    style={
                      item?.code
                        ? { marginBottom: "30px" }
                        : { marginBottom: "5px" }
                    }
                  >
                    <div className="DR-Custom-Chat-msg-bot-inner">
                      {/* {item?.msg} */}
                      {handleSyntaxText(item?.msg, item)}
                      <div
                        className="DR-like-box DR-lida-action"
                        style={{ bottom: "-25px", left: "0" }}
                      >
                        {item?.code && (
                          <>
                            <button
                              // disabled={index?.toString() === copiedItem}
                              style={{
                                background: "none",
                                boxShadow: "none",
                                border: "none",
                                marginRight: "10px",
                                cursor: "pointer",

                                borderRadius: "5px",
                                display: "flex",
                                alignItems: "center",
                              }}
                              disabled={loading}
                              onClick={() => {
                                handleExplain(item);
                              }}
                            >
                              <ExplainIcon /> Explain
                            </button>
                            <Popover
                              open={promptOpen === index ? true : false}
                              onOpenChange={(newOpen) => {
                                setPromptOpen("");
                              }}
                              content={
                                <div>
                                  <div
                                    className="DR-Upload-Input"
                                    style={{
                                      minHeight: "150px",
                                      width: "400px",
                                    }}
                                  >
                                    <TextField
                                      className="DR-new-input-mui-lb DR-question-multiline"
                                      id="question"
                                      placeholder="Add Comment"
                                      name="question"
                                      variant="filled"
                                      fullWidth
                                      multiline
                                      maxRows={4}
                                      minRows={3}
                                      onChange={(e) => {
                                        setRepairMsg(e.target.value);
                                      }}
                                    />
                                  </div>
                                  <Box
                                    className="mt-3"
                                    style={{
                                      display: "flex",
                                      alignItems: "flex-end",
                                      justifyContent: "flex-end",
                                      height: "100%",
                                      gap: "12px",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      className="cancel-button"
                                      onClick={() => {
                                        closePrompt(index);
                                      }}
                                    >
                                      Cancel
                                    </Button>

                                    <Button
                                      variant="contained"
                                      type="submit"
                                      color="primary"
                                      style={{
                                        backgroundColor: "#67D091",
                                        color: "#ffffff",
                                      }}
                                      className="ok-button "
                                      disabled={loading || !repairMsg?.trim()}
                                      onClick={() => {
                                        handleRepair(item);
                                      }}
                                    >
                                      Send
                                    </Button>
                                  </Box>
                                </div>
                              }
                              title="Add Repair Comment"
                              trigger="click"
                              placement="topRight"
                              overlayStyle={{ zIndex: "1600" }}
                            >
                              <button
                                style={{
                                  background: "none",
                                  boxShadow: "none",
                                  border: "none",
                                  marginRight: "10px",
                                  cursor: "pointer",

                                  borderRadius: "5px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                disabled={loading}
                                onClick={() => {
                                  setPromptOpen(index);
                                }}
                              >
                                <RepairIcon /> Repair
                              </button>
                            </Popover>
                          </>
                        )}
                      </div>
                      {item?.hitUrl && (
                        <div className="DR-chat-refrance mt-4">
                          <div>
                            Do you want to search this on Web?{" "}
                            <button
                              className="DR-yes-no-btn"
                              onClick={() => {
                                notFoundLink(item);
                              }}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : item?.role === "loading" ? (
                  <div className="DR-Custom-Chat-msg-bot">
                    <div className="DR-Custom-Chat-msg-bot-inner">
                      <img style={{ width: "50px" }} src={ChatbotLoading} />
                    </div>
                  </div>
                ) : (
                  <div className="DR-Custom-Chat-msg-user">
                    <div className="DR-Custom-Chat-msg-user-inner">
                      {item?.msg}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="DR-Custom-Chat-input-outer">
          <div className="DR-Custom-Chat-input">
            <TextField
              id="outlined-multiline-flexible"
              multiline
              maxRows={6}
              onChange={handleChangeInput}
              onKeyDown={handleKeyDown}
              placeholder="Write your message here..."
              inputMode="text"
              value={input}
              disabled={loading}
              style={{ width: "100%", height: "100%" }}
            />
            {/* <input
            type="search"
            value={input}
            disabled={loading}
            placeholder="Write your message here..."
            onChange={handleChangeInput}
            onKeyDown={handleKeyDown}
            /> */}
            <button onClick={handleSubmit} disabled={!input}>
              <CommentSendIcon />
            </button>
          </div>
          <button
            className="DR-chat-clear-icon"
            onClick={() => {
              deleteSavedMessages();
            }}
            disabled={loading}
            style={{ cursor: "pointer", backgroundColor: "#07a784" }}
          >
            {/* <ClearChatIcon />{" "} */}
            <ChatIconBot />
          </button>
        </div>
      </div>
      {/* Details Dropdown */}
      <div
        className={
          "filter-sidebar dr-v2-side DR-Alet-msg " + showTableColumnFilter
        }
        style={{ width: "650px" }}
      ></div>
    </>
  );
};

export default ChatBodyDashboard;

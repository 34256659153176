import { createSlice } from "@reduxjs/toolkit";
import CARCService from "../../../../actions/CARCService";

export const CliamAdjustmentReasonCode = createSlice({
  name: "CARC",
  initialState: {
    CARCList: [],
    loadingClass: false,
  },
  reducers: {
    CARCAction: (state, action) => {
      state.CARCList = action.payload;
    },
  },
});

export const { CARCAction } = CliamAdjustmentReasonCode.actions;

export const getCARCList = () => async (dispatch) => {
  const CARCList = await CARCService.getList();
  dispatch(CARCAction(CARCList.data.data));
  return CARCList.data.data;
};

export default CliamAdjustmentReasonCode.reducer;

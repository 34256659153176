import React from 'react';

const ArrowDownMenu = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-chevron-down"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke={color ? `${color}` : '#9e9e9e'}
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <polyline points="6 9 12 15 18 9" />
    </svg>
  );
};

export default ArrowDownMenu;

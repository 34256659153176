import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { serverRoutes } from "../../../../constants/serverRoutes";
import {
  resetListingState,
  setListingState,
} from "../../filters/claimManagerFilters";
import genericAxiosCall from "../../../../AxiosConfig/genericAxiosCall";
import {
  commonPagination,
  getMultiSelectFilterValueForApi,
} from "../../../../components/common/Utils/utils";

import { gettingSmartFilterForOncologyDenial_Call } from "./../../OncologyDashboard/OncologyDashboardKpisSlice";
import {
  getAscArray,
  getLegendsExtraShortName,
} from "./../../../../components/common/Utils/utils";

export const ClaimDashSlice = createSlice({
  name: "ClaimDashSlice",
  initialState: {
    // claim DashBoard data here
    claimDashBoardKpiData: {
      claimTimeLineForNormalArr: [],
      claimTimeLineForPreCalculatedArr: [],
      executiveDashBoardKpi: {},
      firstPassRate: [],
      denialByPayerBarChart: [],
      denialByReasonCodeBarChart: [],
      reasonCodeCategoryBarChart: [],
      hardAndSoftDenialArr: [],

      // ======
      loading: false,
      list: null,
    },
  },
  reducers: {
    setClaimDashNormal_dataAction: (state, action) => {
      let data = action.payload;

      if (data?.claimTimeline && data?.claimTimeline?.length) {
        let denailAmount = [];
        let paymentAmount = [];
        let totalChargeAmount = [];

        data?.claimTimeline.forEach((item, index) => {
          denailAmount.push({
            x: item?.year,
            y: item?.denailAmount,
          });

          paymentAmount.push({
            x: item?.year,
            y: item?.paymentAmount,
          });

          totalChargeAmount.push({
            x: item?.year,
            y: item?.totalChargeAmount,
          });
        });

        let newAmountArr = [
          {
            id: "Denail Amount",
            color: "hsl(116, 70%, 50%)",
            data: denailAmount,
          },

          {
            id: "Payment Amount",
            color: "hsl(116, 70%, 50%)",
            data: paymentAmount,
          },

          {
            id: "Total Charge Amount",
            color: "hsl(116, 70%, 50%)",
            data: totalChargeAmount,
          },
        ];

        state.claimDashBoardKpiData.claimTimeLineForNormalArr = newAmountArr;
      } else {
        state.claimDashBoardKpiData.claimTimeLineForNormalArr = [];
      }
    },
    setClaimDashPreCalculated_dataAction: (state, action) => {
      let data = action.payload;

      // preClaimTimeline handle here
      if (data?.preClaimTimeline && data?.preClaimTimeline?.length) {
        let denialAmount = [];
        let paymentAmount = [];
        let totalChargeAmount = [];

        data?.preClaimTimeline.forEach((item, index) => {
          denialAmount.push({
            x: item?.year,
            y: item?.denailAmount,
          });

          paymentAmount.push({
            x: item?.year,
            y: item?.paymentAmount,
          });

          totalChargeAmount.push({
            x: item?.year,
            y: item?.totalChargeAmount,
          });
        });

        let newAmountArr = [
          {
            id: "Denial Amount",
            color: "hsl(116, 70%, 50%)",
            data: denialAmount,
          },

          {
            id: "Payment Amount",
            color: "hsl(116, 70%, 50%)",
            data: paymentAmount,
          },

          {
            id: "Total Charge Amount",
            color: "hsl(116, 70%, 50%)",
            data: totalChargeAmount,
          },
        ];

        state.claimDashBoardKpiData.claimTimeLineForPreCalculatedArr =
          newAmountArr;
      } else {
        state.claimDashBoardKpiData.claimTimeLineForPreCalculatedArr = [];
      }
    },

    setExectiveDashBaordKpiCards_data: (state, action) => {
      try {
        let data = action.payload;
        state.claimDashBoardKpiData.executiveDashBoardKpi = data;
      } catch (e) {
        // console.log("123123 -- ", e);
      }
    },

    setDenialyByPayerAndReasonCode_data: (state, action) => {
      let data = action.payload;
      // denialByPayer handle here

      if (
        data &&
        data?.top5DenialModel &&
        data?.top5DenialModel?.denialByPayer &&
        data?.top5DenialModel?.denialByPayer?.length
      ) {
        let amountArr = [];
        data?.top5DenialModel?.denialByPayer.forEach((item) => {
          amountArr.push({
            country: getLegendsExtraShortName(item?.payerName),
            "Charge Amount": item?.chargeAmount,
            "Denial Amount": item?.denialAmount,
            denialCount: item?.denialCount,
            denialWeitage: item?.denialWeitage,
            totalCount: item?.totalCount,
            payerName: item?.payerName,
          });
        });

        let asc_amountArr = getAscArray(amountArr, "Denial Amount");

        state.claimDashBoardKpiData.denialByPayerBarChart = asc_amountArr;
      } else {
        state.claimDashBoardKpiData.denialByPayerBarChart = [];
      }

      // denialByReasonCode handle here
      if (
        data &&
        data?.top5DenialModel &&
        data?.top5DenialModel?.denialByReasonCode &&
        data?.top5DenialModel?.denialByReasonCode?.length
      ) {
        let amountArr = [];
        data?.top5DenialModel?.denialByReasonCode.forEach((item) => {
          amountArr.push({
            country: item?.reasonCode,
            "Charge Amount": item?.chargeAmount,
            "Denial Amount": item?.denailAmount,
            denialCount: item?.denielCount,
            description: item?.description,
            percentage: item?.denialWeithage,
            totalCount: item?.totalCount,
          });
        });

        let asc_amountArr = getAscArray(amountArr, "Denial Amount");

        state.claimDashBoardKpiData.denialByReasonCodeBarChart = asc_amountArr;
      } else {
        state.claimDashBoardKpiData.denialByReasonCodeBarChart = [];
      }

      // denialByReasonCode handle here
      if (data && data?.singleBarChart && data?.singleBarChart?.length) {
        let amountArr = [];
        data?.singleBarChart?.forEach((item) => {
          // let tempCountry = item?.reasonCodeCategory
          //   ? getLegendsExtraShortName(item?.reasonCodeCategory)
          //   : getLegendsExtraShortName("Blank");

          amountArr.push({
            // country: tempCountry,
            country: item?.reasonCodeCategory
              ? item?.reasonCodeCategory
              : "Blank",
            // tempCountry: item?.denialAmount,
            reasonCodeCategory: item?.reasonCodeCategory
              ? item?.reasonCodeCategory
              : "Blank",
            reasonCodeDescription: item?.reasonCodeDescription
              ? item?.reasonCodeDescription
              : "Not available",
            denialCount: item?.denielCount,
            denialAmountOriginal: item?.denailAmount,
            denialAmount:
              item?.denailAmount < 0
                ? item?.denailAmount * -1
                : item?.denailAmount,
            // chargeAmount: item?.chargeAmount,
          });
        });

        // data?.singleBarChart?.forEach((item, i) => {
        //   let tempCountry = item?.reasonCodeCategory
        //     ? getLegendsExtraShortName(item?.reasonCodeCategory)
        //     : getLegendsExtraShortName("Blank");
        //   let tempCountry2 = item?.reasonCodeCategory
        //     ? item?.reasonCodeCategory
        //     : "Blank";

        //   amountArr[i][`${tempCountry}`] = item?.denailAmount;
        //   amountArr[i][`${tempCountry2}`] = item?.denailAmount;
        // });

        let asc_amountArr = getAscArray(amountArr, "denialAmount");

        state.claimDashBoardKpiData.reasonCodeCategoryBarChart = asc_amountArr;
      } else {
        state.claimDashBoardKpiData.reasonCodeCategoryBarChart = [];
      }
    },

    setClaimDashFirstPass_dataAction: (state, action) => {
      let data = action.payload;
      state.claimDashBoardKpiData.firstPassRate = data;
    },
    setClaimDashHardAndSoft_dataAction: (state, action) => {
      let data = action.payload;
      let totalSoftAndHard =
        Number(data?.TotalSoftDenial) + Number(data?.TotalHardDenial);
      let tempData = { ...data };
      tempData.totalSoftAndHard = totalSoftAndHard;
      state.claimDashBoardKpiData.hardAndSoftDenialArr = tempData;
    },

    gettingLandingPageData: (state, action) => {
      state.list = action.payload;
      state.loading = false;
    },
  },
});

export const {
  setClaimDashNormal_dataAction,
  setExectiveDashBaordKpiCards_data,
  setClaimDashPreCalculated_dataAction,
  setClaimDashFirstPass_dataAction,
  setDenialyByPayerAndReasonCode_data,
  setClaimDashHardAndSoft_dataAction,
  gettingLandingPageData,
} = ClaimDashSlice.actions;

// get claim dash normal list
export const gettingClaimDashNormal_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "claimDashboardFilter",
          //   ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.claimDashboardFilter;

    try {
      dispatch(
        setListingState({
          listing: "claimDashboardFilter",
          value: {
            loadingMoreForNormalCalculation: "data-loading",
          },
        })
      );
      let params = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...(sliceState?.selFilterType !== "" && {
          filter:
            sliceState.selFilterType === "3M"
              ? "3M"
              : sliceState.selFilterType === "1Y"
              ? "1Y"
              : sliceState.selFilterType === "3Y"
              ? "3Y"
              : "Q",
        }),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_CLAIM_DASH_NORMAL_KPIS,
        "get",
        "",
        params
      );

      if (!res?.data?.success) {
        // toast.error(`Something went wrong`, {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        dispatch(setClaimDashNormal_dataAction([]));
        dispatch(
          setListingState({
            listing: "claimDashboardFilter",
            value: {
              loadingMoreForNormalCalculation: "",
            },
          })
        );
      } else {
        dispatch(setClaimDashNormal_dataAction(res?.data?.data));
        dispatch(
          setListingState({
            listing: "claimDashboardFilter",
            value: {
              // hasMore: res?.data?.data?.length === sliceState.pageSize,
              loadingMoreForNormalCalculation: "",
            },
          })
        );
      }
      return res;
    } catch (e) {
      dispatch(
        setListingState({
          listing: "claimDashboardFilter",
          value: {
            initialLoading: false,
            loadingMoreForNormalCalculation: "",
            hasMore: false,
          },
        })
      );
      dispatch(setClaimDashNormal_dataAction([]));

      return false;
    }
  };

// get claim dash normal list
export const gettingClaimDashPreCalculated_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "claimDashboardFilter",
          //   ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceState = getState().npReducers.filters.claimDashboardFilter;

    try {
      dispatch(gettingExecutiveDashBoardKpiCards_Call());
      dispatch(gettingClaimDashFirstPass_FailureRate_Call());
      // dispatch(gettingClaimDashHardAndSoftDenial_Call());

      dispatch(
        setListingState({
          listing: "claimDashboardFilter",
          value: {
            loadingCards: "data-loading",
          },
        })
      );

      ///// API CALL //////

      let params = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.selFilterType !== "" && {
          filter:
            sliceStateForDate.selFilterType === "3M"
              ? "3M"
              : sliceStateForDate.selFilterType === "1Y"
              ? "1Y"
              : sliceStateForDate.selFilterType === "3Y"
              ? "3Y"
              : "Q",
        }),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_CLAIM_DASH_PRE_CALCULATED_KPIS,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////
      if (!res?.data?.success) {
        dispatch(setClaimDashPreCalculated_dataAction([]));
        dispatch(
          setListingState({
            listing: "claimDashboardFilter",
            value: {
              loadingCards: "",
            },
          })
        );
      } else {
        dispatch(setClaimDashPreCalculated_dataAction(res?.data?.data));
        dispatch(
          setListingState({
            listing: "claimDashboardFilter",
            value: {
              // hasMore: res?.data?.data?.length === sliceState.pageSize,
              loadingCards: "",
            },
          })
        );
      }
      return res;
    } catch (e) {
      // console.log("e--->>", e);
      dispatch(setClaimDashPreCalculated_dataAction([]));
      dispatch(
        setListingState({
          listing: "claimDashboardFilter",
          value: {
            loadingCards: "",
          },
        })
      );

      return false;
    }
  };

export const getLandingPageForKPIs = () => (dispatch, getState) => {
  let sliceState = getState().npReducers.filters.insightsSreensCommonFilter;
  let params = {
    ...(sliceState?.from !== "" && {
      from: sliceState.from,
    }),
    ...(sliceState?.to !== "" && {
      to: sliceState.to,
    }),
    ...(sliceState?.dateMainState !== "" && {
      filter: sliceState.dateMainState == "Months" ? "W" : "Q",
    }),
  };

  let paramsTemp = {
    ...(sliceState?.from !== "" && {
      from: sliceState.from,
    }),
    ...(sliceState?.to !== "" && {
      to: sliceState.to,
    }),
    ...(sliceState?.dateMainState !== "" && {
      filter: sliceState.dateMainState == "Months" ? "W" : "Q",
    }),
  };

  commonPagination(
    getState,
    params,
    paramsTemp,
    dispatch,
    gettingLandingPageData,
    "landingPageFilter",
    "LANDING_PAGE",
    null
  );
};

// get barChart data denialByPayer, denialByReasonCode
export const gettingDenialbyPayerAndReasonCode_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "claimDashboardFilter",
          ignore: ["claimStatus,payers,frequencyType,search"],
        })
      );
    }
    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;
    let sliceState = getState().npReducers.filters.claimDashboardFilter;

    try {
      dispatch(
        gettingSmartFilterForOncologyDenial_Call("claimDashboardFilter")
      );

      dispatch(
        setListingState({
          listing: "claimDashboardFilter",
          value: {
            loadingForBarChart: "data-loading",
          },
        })
      );

      ///// API CALL //////

      let params = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceState?.smartFilterArr
            ),
          }),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_CLAIM_DASH_DENIAL_BY_PAYER_AND_REASON_CODE_KPIS,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////
      if (!res?.data?.success) {
        dispatch(setDenialyByPayerAndReasonCode_data([]));
        dispatch(
          setListingState({
            listing: "claimDashboardFilter",
            value: {
              loadingForBarChart: "",
            },
          })
        );
      } else {
        dispatch(setDenialyByPayerAndReasonCode_data(res?.data?.data));
        dispatch(
          setListingState({
            listing: "claimDashboardFilter",
            value: {
              // hasMore: res?.data?.data?.length === sliceState.pageSize,
              loadingForBarChart: "",
            },
          })
        );
      }
      return res;
    } catch (e) {
      //   console.log("e--->>", e);
      dispatch(setDenialyByPayerAndReasonCode_data([]));
      dispatch(
        setListingState({
          listing: "claimDashboardFilter",
          value: {
            loadingForBarChart: "",
          },
        })
      );

      return false;
    }
  };

// get  Exective DashBaordKpi Cards_data
export const gettingExecutiveDashBoardKpiCards_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "claimDashboardFilter",
          ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;
    // let sliceState = getState().npReducers.filters.claimDashboardFilter;

    try {
      dispatch(
        setListingState({
          listing: "claimDashboardFilter",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      ///// API CALL //////

      let params = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_EXECTIVE_DASH_KPIS,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      if (!res?.data?.success) {
        // toast.error(`Something went wrong`, {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        dispatch(setExectiveDashBaordKpiCards_data([]));
        dispatch(
          setListingState({
            listing: "claimDashboardFilter",
            value: {
              loadingMore: "",
            },
          })
        );
      } else {
        dispatch(setExectiveDashBaordKpiCards_data(res?.data?.data));

        dispatch(
          setListingState({
            listing: "claimDashboardFilter",
            value: {
              loadingMore: "",
            },
          })
        );
      }
      return res;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "claimDashboardFilter",
          value: {
            loadingMore: "",
          },
        })
      );
      dispatch(setExectiveDashBaordKpiCards_data([]));

      return false;
    }
  };

// get exec dash first pass failure and pass rate
export const gettingClaimDashFirstPass_FailureRate_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "claimDashboardFilter",
          //   ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }
    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceState = getState().npReducers.filters.claimDashboardFilter;

    try {
      dispatch(
        setListingState({
          listing: "claimDashboardFilter",
          value: {
            loadingCards2: "data-loading",
          },
        })
      );

      ///// API CALL //////

      let params = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_EXECTIVE_DASH_FIRST_PASS_FAILURE_RATE,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////
      if (!res?.data?.success) {
        dispatch(setClaimDashFirstPass_dataAction([]));
        // dispatch(
        //   setListingState({
        //     listing: "claimDashboardFilter",
        //     value: {
        //       loadingCards2: "",
        //     },
        //   })
        // );
      } else {
        dispatch(setClaimDashFirstPass_dataAction(res?.data?.data));
        dispatch(
          setListingState({
            listing: "claimDashboardFilter",
            value: {
              // hasMore: res?.data?.data?.length === sliceState.pageSize,
              loadingCards2: "",
            },
          })
        );
      }
      return res;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "claimDashboardFilter",
          value: {
            loadingCards2: "",
          },
        })
      );
      dispatch(setClaimDashFirstPass_dataAction([]));

      return false;
    }
  };

// get exec dash hard and soft denial
export const gettingClaimDashHardAndSoftDenial_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "claimDashboardFilter",
          //   ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }
    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;

    try {
      dispatch(
        setListingState({
          listing: "claimDashboardFilter",
          value: {
            loadingCards3: "data-loading",
          },
        })
      );

      ///// API CALL //////

      let params = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_EXECTIVE_DASH_HARD_AND_SOFT_DENIAL,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////
      if (!res?.data?.success) {
        dispatch(setClaimDashHardAndSoft_dataAction([]));
        dispatch(
          setListingState({
            listing: "claimDashboardFilter",
            value: {
              loadingCards3: "",
            },
          })
        );
      } else {
        dispatch(setClaimDashHardAndSoft_dataAction(res?.data?.data));
        dispatch(
          setListingState({
            listing: "claimDashboardFilter",
            value: {
              // hasMore: res?.data?.data?.length === sliceState.pageSize,
              loadingCards3: "",
            },
          })
        );
      }
      return res;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "claimDashboardFilter",
          value: {
            loadingCards3: "",
          },
        })
      );
      dispatch(setClaimDashHardAndSoft_dataAction([]));

      return false;
    }
  };

export default ClaimDashSlice.reducer;

import React, { useEffect } from "react";
import { Select } from "antd";

const SingleTagsDropDownZoe = ({
  data,
  defaultValue,
  selected,
  setSelected,
  labelTarget,
  valueTarget,
  none,
  mainTarget,
  noneLabel,
  disabled,
  mainMac,
  setJurisdictionOption,
  setSelectedJurisdiction,
}) => {
  const onChange = (value, item) => {
    setSelected(item);
    if (mainMac) {
      setJurisdictionOption(item?.children);
      setSelectedJurisdiction(item?.children?.[0]);
    }
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const renderOptions = () => {
    let arr = [];
    if (none) {
      arr.push({ value: "none", label: "None" });
    }

    if (mainTarget && data?.length) {
      arr = [...data];
    } else {
      data?.map((item, index) => {
        arr.push({
          ...item,
          value: item?.[valueTarget]?.toString(),
          label: item?.[labelTarget],
        });
      });
    }

    return arr;
  };

  return (
    <Select
      showSearch
      // defaultValue={
      //   defaultValue ? defaultValue : noneLabel ? noneLabel : "none"
      // }
      value={selected?.value}
      optionFilterProp="children"
      onChange={onChange}
      filterOption={filterOption}
      options={renderOptions()}
      disabled={disabled}
    />
  );
};

export default SingleTagsDropDownZoe;

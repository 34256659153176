import React from "react";

const SessionIcon = () => {
  return (
    <svg
      width="150"
      height="150"
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75 67.9688V44.5312"
        stroke="#007a56"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M75 25.7812V21.0938M38.6719 38.6719L35.1562 35.1562"
        stroke="#007a56"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M75 89.0625C80.1777 89.0625 84.375 84.8652 84.375 79.6875C84.375 74.5098 80.1777 70.3125 75 70.3125C69.8223 70.3125 65.625 74.5098 65.625 79.6875C65.625 84.8652 69.8223 89.0625 75 89.0625Z"
        stroke="#007a56"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M75 28.125C64.8019 28.125 54.8328 31.1491 46.3534 36.8148C37.874 42.4806 31.2651 50.5336 27.3625 59.9554C23.4598 69.3772 22.4387 79.7447 24.4283 89.7468C26.4178 99.749 31.3287 108.937 38.5398 116.148C45.751 123.359 54.9385 128.27 64.9407 130.259C74.9428 132.249 85.3103 131.228 94.7321 127.325C104.154 123.422 112.207 116.814 117.873 108.334C123.538 99.8547 126.563 89.8856 126.563 79.6875C126.563 66.0123 121.13 52.8972 111.46 43.2273C101.79 33.5575 88.6752 28.125 75 28.125Z"
        stroke="#007a56"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default SessionIcon;

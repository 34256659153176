import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { common } from "../../../../actions";

import {
  commonPagination,
  getCurrentClientId,
  getMultiSelectFilterIdForApi_ForId,
  getMultiSelectFilterValueForApi,
  getTreeNodeObj,
} from "../../../../components/common/Utils/utils";

import {
  setListingState,
  resetListingState,
  resetListingStateForResetButton,
} from "../../filters/claimManagerFilters";
import { getClaimAllFiltersData_Call } from "../../DenialDetails/claimAllFiltersSlice";
import store from "../../../store";
import { environment, serverRoutes } from "../../../../constants/serverRoutes";
import genericAxiosCall from "../../../../AxiosConfig/genericAxiosCall";
import { setCustomColumnsClaimPayment, userData } from "../../UserReducer";

export const claimPaymentSlice = createSlice({
  name: "claimPayment",
  initialState: {
    claimPaymentData: [],
    claimPaymentDetail: [],
    claimPaymentConfigData: [],
    newRemittanceDetail_Data: {
      output: [],
      count: null,
    },

    claimPaymentTable_Data: {
      count: null,
      totalPaymentAmount: null,
      totalChargeAmount: null,
      TotalDeniedAmount: null,
      output: [],
    },
    underPaymentTable_Data: {
      count: null,
      expectedPercentage: null,
      totalExpectedPayout: null,
      TotalDeniedAmount: null,
      output: [],
    },
    filterLoader: {
      payers: true,
      serviceLine: true,
      claimStatus: true,
      reasoncode: true,
      facility: true,
      provider: true,
      fillingIndicator: true,
      ipRemarkCode: true,
      cptCode: true,
      groupReasonCode: true,
    },
    // below code for new logic for filters on 835 screen ==== myCode
    serviceLineFilterArr: [],
    cptCodesForFilterArr: [],
    groupReasonCodeFilterArr: [],
    claimStatusForFilterArr: [],
    payersForFilterArr: [],
    payeeForFilterArr: [],
    facilityForFilterArr: [],
    claimAdjReasonCodesForFilterArr: [],
    claimfillingIndiCodeForFilterArr: [],
    jCodesForFilterArr: [],
    IPRemarkCodesForFilterArr: [],
    OPRemarkCodesForFilterArr: [],
  },

  reducers: {
    claimPayment_DataAction: (state, action) => {
      let data = action.payload;
      if (data?.claimPayments?.length) {
        state.claimPaymentData = data;
      } else {
        state.claimPaymentData = [];
      }
    },

    // 835 tabular data here
    setClaimPaymentTabular_Data: (state, action) => {
      let data = action.payload;

      if (data?.claimPayments?.length) {
        state.claimPaymentTable_Data.output = data?.claimPayments;
        state.claimPaymentTable_Data.count = data?.claimPayments[0]?.count;
        state.claimPaymentTable_Data.totalChargeAmount =
          data?.claimPayments[0]?.totalChargeAmount;
        state.claimPaymentTable_Data.totalPaymentAmount =
          data?.claimPayments[0]?.totalPaymentAmount;
        state.claimPaymentTable_Data.TotalDeniedAmount =
          data?.claimPayments[0]?.totalDeniedAmount;
      } else {
        state.claimPaymentTable_Data.output = [];
        state.claimPaymentTable_Data.count = null;
        state.claimPaymentTable_Data.totalChargeAmount = null;
        state.claimPaymentTable_Data.totalPaymentAmount = null;
        state.claimPaymentTable_Data.TotalDeniedAmount = null;
      }
    },
    setClaimPaymentConfigData: (state, action) => {
      const data = action?.payload;
      if (data?.length) {
        state.claimPaymentConfigData = data;
      } else {
        state.claimPaymentConfigData = [];
      }
    },

    // 835 ->  underPayment screen tabular data here
    setUnderPaymentTabular_Data: (state, action) => {
      try {
        let data = action.payload;

        if (data?.claimPayments?.length) {
          state.underPaymentTable_Data.output = data?.claimPayments;
          state.underPaymentTable_Data.count = data?.claimPayments[0]?.count;
          state.underPaymentTable_Data.expectedPercentage =
            data?.claimPayments[0]?.totalPaymentAmount;
          state.underPaymentTable_Data.totalExpectedPayout =
            data?.claimPayments[0]?.totalExpectedPayout;
          state.underPaymentTable_Data.TotalDeniedAmount =
            data?.claimPayments[0]?.totalDeniedAmount;
        } else {
          state.underPaymentTable_Data.output = [];
          state.underPaymentTable_Data.count = null;
          state.underPaymentTable_Data.expectedPercentage = null;
          state.underPaymentTable_Data.totalExpectedPayout = null;
          state.underPaymentTable_Data.TotalDeniedAmount = null;
        }
      } catch (err) {
        // console.log("err--->>", err);
      }
    },

    claimPaymentDetailAction: (state, action) => {
      state.claimPaymentDetail = action.payload;
    },
    setNewRemittanceDetail_Data: (state, action) => {
      try {
        let data = action.payload;

        if (data?.length) {
          state.newRemittanceDetail_Data.output = data;
          state.newRemittanceDetail_Data.count = data?.[0]?.totalCount;
        } else {
          state.newRemittanceDetail_Data.output = [];
          state.newRemittanceDetail_Data.count = null;
        }
      } catch (err) {
        // console.log("err--->>", err);
      }
    },
    removeClaimDetailById: (state, action) => {
      state.claimPaymentData = state?.claimPaymentData.filter(
        (item) => item.claimPaymentId !== action.payload
      );
    },
  },
});

export const {
  claimPayment_DataAction,
  claimPaymentDetailAction,
  setClaimPaymentConfigData,
  removeClaimDetailById,
  setClaimPaymentTabular_Data,
  setUnderPaymentTabular_Data,
  setNewRemittanceDetail_Data,
} = claimPaymentSlice.actions;

export const claimPaymentRequestCall =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear === true) {
      dispatch(
        resetListingState({
          listing: "claimPayment",
          ignore: ["claimStatus,payers,cptCodes,CARC,search"],
        })
      );
    }

    if (clear === "resetBtn") {
      dispatch(
        resetListingStateForResetButton({
          listing: "claimPayment",
          ignore: ["claimStatus,payers,cptCodes,CARC,search"],
        })
      );
    }

    let sliceState = getState().npReducers.filters.claimPayment;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    try {
      dispatch(
        setListingState({
          listing: "claimPayment",
          value: {
            loadingMore: "data-loading",
          },
        })
      );

      ///// API CALL //////
      const res = await common.getClaimsPayments({
        start,
        limit,
        ...(sliceState?.claimPaymentStatus?.length > 0 && {
          claimPaymentStatus: getMultiSelectFilterValueForApi(
            sliceState?.claimPaymentStatus
          ),
        }),
        ...(sliceState?.search && { Filter: sliceState?.search }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),
        ...(sliceState?.cptCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),

        ...(sliceState?.CARC?.length > 0 && {
          CARC: getMultiSelectFilterValueForApi(sliceState?.CARC),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),

        ...(!sliceState?.search &&
          sliceState.from !== "" && {
            from: sliceState.from,
          }),
        ...(!sliceState?.search &&
          sliceState.to !== "" && {
            to: sliceState.to,
          }),
      });
      /////////////////////

      if (res?.data?.success) {
        dispatch(claimPayment_DataAction(res?.data?.data));
        dispatch(
          setListingState({
            listing: "claimPayment",
            value: {
              hasMore:
                res?.data?.data?.claimPayments?.length === sliceState.pageSize,
              loadingMore: "",
            },
          })
        );
      } else {
        // toast.error(`Something went wrong`, {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        dispatch(claimPayment_DataAction([]));
        dispatch(
          setListingState({
            listing: "claimPayment",
            value: {
              // hasMore:
              //   res?.data?.data?.claimPayments?.length === sliceState.pageSize,
              loadingMore: "",
            },
          })
        );
      }

      return res?.data;
    } catch (e) {
      // console.log("catch_e --->>", e);

      dispatch(
        setListingState({
          listing: "claimPayment",
          value: {
            initialLoading: false,
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      dispatch(claimPayment_DataAction([]));
      return false;
    }
  };

// 835 -> underPayment screen slicer

export const gettingUnderPaymentTabular_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    // handle clear logic for all filters and tabular filter (835 screen)
    const claimPaymetnFiltersArr = ["underPaymentFilters"];
    if (clear === true) {
      claimPaymetnFiltersArr.map((listing) =>
        dispatch(
          resetListingState({
            listing,
            ignore: ["claimStatus,payers,cptCodes,CARC"],
          })
        )
      );
    }

    if (clear === "resetBtn") {
      claimPaymetnFiltersArr.map((listing) =>
        dispatch(
          resetListingStateForResetButton({
            listing,
            ignore: ["claimStatus,payers,cptCodes,CARC"],
          })
        )
      );
    }
    let sliceState = getState().npReducers.filters.underPaymentFilters;
    let sliceStateUser = getState().pReducers.user;
    let sliceStateForClientId =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceStateForDate =
      getState().npReducers.filters.denialSreensCommonFilter;
    let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;
    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable
        ?.underPaymentFilterNoOfRecordInTable;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceState?.claimPaymentStatus?.length > 0 && {
          claimPaymentStatus: getMultiSelectFilterValueForApi(
            sliceState?.claimPaymentStatus
          ),
        }),
        ...(sliceState?.search && { Filter: sliceState?.search }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),
        ...(sliceState?.facility?.length > 0 && {
          ServiceFacilityIds: getMultiSelectFilterValueForApi(
            sliceState?.facility
          ),
        }),

        ...(sliceState?.billingProviders?.length > 0 && {
          billingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...(sliceState?.renderingProviders?.length > 0 && {
          renderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceState?.groupReasonCode?.length > 0 && {
          groupcode: getMultiSelectFilterValueForApi(
            sliceState?.groupReasonCode
          ),
        }),
        ...(sliceState?.jCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(sliceState?.jCodes),
        }),
        ...(sliceState?.cptCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),
        ...(sliceState?.DRGs?.length > 0 && {
          DRGNo: getMultiSelectFilterValueForApi(sliceState?.DRGs),
        }),

        ...(sliceState?.serviceLine?.length > 0 && {
          type: getMultiSelectFilterIdForApi_ForId(sliceState?.serviceLine),
        }),

        ...(sliceState?.CARC?.length > 0 && {
          CARC: getMultiSelectFilterValueForApi(sliceState?.CARC),
        }),

        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterValueForApi(
            sliceState?.filingIndicator
          ),
        }),

        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.IPRemarkCodes
          ),
        }),

        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.OPRemarkCodes
          ),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),

        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
        ...(clear instanceof Date && { timestamp: clear.getTime() }), // Add a timestamp if clear is a Date object
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };
      let params = {
        start,
        limit,
        ...(sliceState?.claimPaymentStatus?.length > 0 && {
          claimPaymentStatus: getMultiSelectFilterValueForApi(
            sliceState?.claimPaymentStatus
          ),
        }),
        ...(sliceState?.search && { Filter: sliceState?.search }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),
        ...(sliceState?.facility?.length > 0 && {
          ServiceFacilityIds: getMultiSelectFilterValueForApi(
            sliceState?.facility
          ),
        }),

        ...(sliceState?.billingProviders?.length > 0 && {
          billingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...(sliceState?.renderingProviders?.length > 0 && {
          renderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceState?.groupReasonCode?.length > 0 && {
          groupcode: getMultiSelectFilterValueForApi(
            sliceState?.groupReasonCode
          ),
        }),
        ...(sliceState?.jCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(sliceState?.jCodes),
        }),
        ...(sliceState?.cptCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),
        ...(sliceState?.DRGs?.length > 0 && {
          DRGNo: getMultiSelectFilterValueForApi(sliceState?.DRGs),
        }),

        ...(sliceState?.serviceLine?.length > 0 && {
          type: !sendOncology
            ? getMultiSelectFilterIdForApi_ForId(sliceState?.serviceLine)
            : "oncology",
        }),

        ...(sliceState?.CARC?.length > 0 && {
          CARC: getMultiSelectFilterValueForApi(sliceState?.CARC),
        }),

        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterValueForApi(
            sliceState?.filingIndicator
          ),
        }),

        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.IPRemarkCodes
          ),
        }),

        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.OPRemarkCodes
          ),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),

        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };
      if (sliceState?.treeNodeServiceLine?.length > 0) {
        const treeSelected = getTreeNodeObj(sliceState.treeNodeServiceLine);
        if (treeSelected) {
          params = { ...params, ...treeSelected };
          paramsTemp = { ...paramsTemp, ...treeSelected };
        }
      }

      const cptParams = {
        CPTCode: "",
        start: 0,
        limit: 100,
        ...(sliceState?.cptCodes?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };
      const payerParams = {
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };
      const reasonCodeParams = {
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      const providerParams = {
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      const billingProviderParams = {
        searchkeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.billingProviders?.length > 0 && {
          selectedBillingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };
      const renderingProviderParams = {
        searchkeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.renderingProviders?.length > 0 && {
          selectedRenderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      const drgCodeParams = {
        searchKeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.DRGs?.length > 0 && {
          selectedDRGs: getMultiSelectFilterValueForApi(sliceState?.DRGs),
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate?.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate?.to,
        }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      await commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setUnderPaymentTabular_Data,
        "underPaymentFilters",
        "GET_UNDER_PAYMENT_835_TABULAR_DATA",
        false,
        "",
        ""
      );

      // dispatch for all filters array
      dispatch(
        getClaimAllFiltersData_Call(
          cptParams,
          payerParams,
          reasonCodeParams,
          providerParams,
          billingProviderParams,
          renderingProviderParams,
          drgCodeParams,
          {
            isServiceLineAPI: true,
            isPayersAPI: true,
            isClaimStatusAPI: true,
            isReasonCodeAPI: true,
            isProviderAPI: false,
            isFillingIndicatorAPI: true,
            isBillingProviderAPI: true,
            isRenderingProviderAPI: true,
            isIPOPRemartCodeAPI: true,
            isCptCodeAPI: true,
            isGroupCodeAPI: true,
            isDrgAPI: true,
          }
        )
      );
    } catch (e) {
      console.log("error --->>", e);

      return false;
    }
  };

export const claimPaymentRequest_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    // handle clear logic for all filters and tabular filter (835 screen)
    const claimPaymetnFiltersArr = ["claimPayment"];
    if (clear === true) {
      claimPaymetnFiltersArr.map((listing) =>
        dispatch(
          resetListingState({
            listing,
            ignore: ["claimStatus,payers,cptCodes,CARC"],
          })
        )
      );
    }

    if (clear === "resetBtn") {
      claimPaymetnFiltersArr.map((listing) =>
        dispatch(
          resetListingStateForResetButton({
            listing,
            ignore: ["claimStatus,payers,cptCodes,CARC"],
          })
        )
      );
    }
    let sliceState = getState().npReducers.filters.claimPayment;

    let sliceStateUser = getState().pReducers.user;

    let sliceStateForClientId =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceStateForDate =
      getState().npReducers.filters.denialSreensCommonFilter;
    let start = sliceState.pageNumber;
    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable
        ?.claimPaymentFilterNoOfRecordInTable;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }
    const serviceNames = ["jcode", "ancillary", "premed", "admin"];
    let valTemp = sliceState?.serviceLine?.map((item, index) => {
      return item?.value;
    });
    let res = serviceNames.filter((item, index) => {
      if (valTemp?.indexOf(item) > -1) {
        return item;
      }
    });

    const sendOncology =
      res?.length === serviceNames?.length &&
      sliceState?.serviceLine?.length === serviceNames?.length
        ? true
        : false;

    try {
      // console.log(
      //   "claimPaymentRequest_Call--->>",
      //   sliceStateUser?.globalObjNoOfRecordInTable
      //     ?.claimPaymentFilterNoOfRecordInTable
      // );

      let paramsTemp = {
        ...(sliceState?.claimPaymentStatus?.length > 0 && {
          claimPaymentStatus: getMultiSelectFilterValueForApi(
            sliceState?.claimPaymentStatus
          ),
        }),
        ...(sliceState?.search && { Filter: sliceState?.search }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),
        ...(sliceState?.facility?.length > 0 && {
          ServiceFacilityIds: getMultiSelectFilterValueForApi(
            sliceState?.facility
          ),
        }),

        ...(sliceState?.billingProviders?.length > 0 && {
          billingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...(sliceState?.renderingProviders?.length > 0 && {
          renderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceState?.groupReasonCode?.length > 0 && {
          groupcode: getMultiSelectFilterValueForApi(
            sliceState?.groupReasonCode
          ),
        }),
        ...(sliceState?.jCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(sliceState?.jCodes),
        }),
        ...(sliceState?.cptCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),
        ...(sliceState?.DRGs?.length > 0 && {
          DRGNo: getMultiSelectFilterValueForApi(sliceState?.DRGs),
        }),

        ...(sliceState?.serviceLine?.length > 0 && {
          type: getMultiSelectFilterIdForApi_ForId(sliceState?.serviceLine),
        }),

        ...(sliceState?.CARC?.length > 0 && {
          CARC: getMultiSelectFilterValueForApi(sliceState?.CARC),
        }),

        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterValueForApi(
            sliceState?.filingIndicator
          ),
        }),

        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.IPRemarkCodes
          ),
        }),

        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.OPRemarkCodes
          ),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),

        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
        ...(clear instanceof Date && { timestamp: clear.getTime() }), // Add a timestamp if clear is a Date object
        ...(sliceState?.fetchLatest === true && { timestamp: Date.now() }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };
      let params = {
        start,
        limit,
        ...(sliceState?.claimPaymentStatus?.length > 0 && {
          claimPaymentStatus: getMultiSelectFilterValueForApi(
            sliceState?.claimPaymentStatus
          ),
        }),
        ...(sliceState?.search && { Filter: sliceState?.search }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),
        ...(sliceState?.facility?.length > 0 && {
          ServiceFacilityIds: getMultiSelectFilterValueForApi(
            sliceState?.facility
          ),
        }),

        ...(sliceState?.billingProviders?.length > 0 && {
          billingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...(sliceState?.renderingProviders?.length > 0 && {
          renderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceState?.groupReasonCode?.length > 0 && {
          groupcode: getMultiSelectFilterValueForApi(
            sliceState?.groupReasonCode
          ),
        }),
        ...(sliceState?.jCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(sliceState?.jCodes),
        }),
        ...(sliceState?.cptCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),
        ...(sliceState?.DRGs?.length > 0 && {
          DRGNo: getMultiSelectFilterValueForApi(sliceState?.DRGs),
        }),

        ...(sliceState?.serviceLine?.length > 0 && {
          type: !sendOncology
            ? getMultiSelectFilterIdForApi_ForId(sliceState?.serviceLine)
            : "oncology",
        }),

        ...(sliceState?.CARC?.length > 0 && {
          CARC: getMultiSelectFilterValueForApi(sliceState?.CARC),
        }),

        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterValueForApi(
            sliceState?.filingIndicator
          ),
        }),

        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.IPRemarkCodes
          ),
        }),

        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.OPRemarkCodes
          ),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),

        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),

        ...(sliceState?.fetchLatest === true && { timestamp: Date.now() }),
      };
      if (sliceState?.treeNodeServiceLine?.length > 0) {
        const treeSelected = getTreeNodeObj(sliceState.treeNodeServiceLine);
        if (treeSelected) {
          params = { ...params, ...treeSelected };
          paramsTemp = { ...paramsTemp, ...treeSelected };
        }
      }

      const cptParams = {
        CPTCode: "",
        start: 0,
        limit: 100,
        ...(sliceState?.cptCodes?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };
      const payerParams = {
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: getCurrentClientId(),
        }),
      };
      const reasonCodeParams = {
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: getCurrentClientId(),
        }),
      };

      const providerParams = {
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      const billingProviderParams = {
        searchkeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.billingProviders?.length > 0 && {
          selectedBillingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };
      const renderingProviderParams = {
        searchkeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.renderingProviders?.length > 0 && {
          selectedRenderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: getCurrentClientId(),
        }),
      };
      const drgCodeParams = {
        searchKeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.DRGs?.length > 0 && {
          selectedDRGs: getMultiSelectFilterValueForApi(sliceState?.DRGs),
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate?.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate?.to,
        }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      await commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setClaimPaymentTabular_Data,
        "claimPayment",
        "GET_PAYMENT_835_TABULAR_DATA",
        false,
        "",
        ""
      );

      dispatch(
        getClaimAllFiltersData_Call(
          cptParams,
          payerParams,
          reasonCodeParams,
          providerParams,
          billingProviderParams,
          renderingProviderParams,
          drgCodeParams,
          {
            isServiceLineAPI: true,
            isPayersAPI: true,
            isClaimStatusAPI: true,
            isReasonCodeAPI: true,
            isProviderAPI: true,
            isFillingIndicatorAPI: true,
            isBillingProviderAPI: true,
            isRenderingProviderAPI: true,
            isIPOPRemartCodeAPI: true,
            isCptCodeAPI: true,
            isGroupCodeAPI: true,
            isDrgAPI: true,
          }
        )
      );
    } catch (e) {
      console.log("error --->>", e);

      return false;
    }
  };
export const claimPaymentRequest_Call_TimeStamp =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    // handle clear logic for all filters and tabular filter (835 screen)
    const claimPaymetnFiltersArr = ["claimPayment"];
    if (clear === true) {
      claimPaymetnFiltersArr.map((listing) =>
        dispatch(
          resetListingState({
            listing,
            ignore: ["claimStatus,payers,cptCodes,CARC"],
          })
        )
      );
    }

    if (clear === "resetBtn") {
      claimPaymetnFiltersArr.map((listing) =>
        dispatch(
          resetListingStateForResetButton({
            listing,
            ignore: ["claimStatus,payers,cptCodes,CARC"],
          })
        )
      );
    }
    let sliceState = getState().npReducers.filters.claimPayment;
    let sliceStateUser = getState().pReducers.user;

    let sliceStateForDate =
      getState().npReducers.filters.denialSreensCommonFilter;
    let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;
    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable
        ?.claimPaymentFilterNoOfRecordInTable;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }
    const serviceNames = ["jcode", "ancillary", "premed", "admin"];
    let valTemp = sliceState?.serviceLine?.map((item, index) => {
      return item?.value;
    });
    let res = serviceNames.filter((item, index) => {
      if (valTemp?.indexOf(item) > -1) {
        return item;
      }
    });

    const sendOncology =
      res?.length === serviceNames?.length &&
      sliceState?.serviceLine?.length === serviceNames?.length
        ? true
        : false;

    try {
      let paramsTemp = {
        ...(sliceState?.claimPaymentStatus?.length > 0 && {
          claimPaymentStatus: getMultiSelectFilterValueForApi(
            sliceState?.claimPaymentStatus
          ),
        }),
        ...(sliceState?.search && { Filter: sliceState?.search }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),
        ...(sliceState?.facility?.length > 0 && {
          ServiceFacilityIds: getMultiSelectFilterValueForApi(
            sliceState?.facility
          ),
        }),

        ...(sliceState?.billingProviders?.length > 0 && {
          billingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...(sliceState?.renderingProviders?.length > 0 && {
          renderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceState?.groupReasonCode?.length > 0 && {
          groupcode: getMultiSelectFilterValueForApi(
            sliceState?.groupReasonCode
          ),
        }),
        ...(sliceState?.jCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(sliceState?.jCodes),
        }),
        ...(sliceState?.cptCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),

        ...(sliceState?.serviceLine?.length > 0 && {
          type: getMultiSelectFilterIdForApi_ForId(sliceState?.serviceLine),
        }),

        ...(sliceState?.CARC?.length > 0 && {
          CARC: getMultiSelectFilterValueForApi(sliceState?.CARC),
        }),

        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterValueForApi(
            sliceState?.filingIndicator
          ),
        }),

        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.IPRemarkCodes
          ),
        }),

        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.OPRemarkCodes
          ),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),

        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
        ...(clear instanceof Date && { timestamp: clear.getTime() }), // Add a timestamp if clear is a Date object

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: getCurrentClientId(),
        }),
        timestamp: new Date().getTime(),
      };
      let params = {
        start,
        limit,
        ...(sliceState?.claimPaymentStatus?.length > 0 && {
          claimPaymentStatus: getMultiSelectFilterValueForApi(
            sliceState?.claimPaymentStatus
          ),
        }),
        ...(sliceState?.search && { Filter: sliceState?.search }),
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterValueForApi(sliceState?.payers),
        }),
        ...(sliceState?.facility?.length > 0 && {
          ServiceFacilityIds: getMultiSelectFilterValueForApi(
            sliceState?.facility
          ),
        }),

        ...(sliceState?.billingProviders?.length > 0 && {
          billingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...(sliceState?.renderingProviders?.length > 0 && {
          renderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceState?.groupReasonCode?.length > 0 && {
          groupcode: getMultiSelectFilterValueForApi(
            sliceState?.groupReasonCode
          ),
        }),
        ...(sliceState?.jCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(sliceState?.jCodes),
        }),
        ...(sliceState?.cptCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),

        ...(sliceState?.serviceLine?.length > 0 && {
          type: !sendOncology
            ? getMultiSelectFilterIdForApi_ForId(sliceState?.serviceLine)
            : "oncology",
        }),

        ...(sliceState?.CARC?.length > 0 && {
          CARC: getMultiSelectFilterValueForApi(sliceState?.CARC),
        }),

        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterValueForApi(
            sliceState?.filingIndicator
          ),
        }),

        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.IPRemarkCodes
          ),
        }),

        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterValueForApi(
            sliceState?.OPRemarkCodes
          ),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),

        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: getCurrentClientId(),
        }),
        timestamp: new Date().getTime(),
      };
      if (sliceState?.treeNodeServiceLine?.length > 0) {
        const treeSelected = getTreeNodeObj(sliceState.treeNodeServiceLine);
        if (treeSelected) {
          params = { ...params, ...treeSelected };
          paramsTemp = { ...paramsTemp, ...treeSelected };
        }
      }

      const cptParams = {
        CPTCode: "",
        start: 0,
        limit: 100,
        ...(sliceState?.cptCodes?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };
      const payerParams = {
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: getCurrentClientId(),
        }),
      };
      const reasonCodeParams = {
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: getCurrentClientId(),
        }),
      };

      const providerParams = {
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      const billingProviderParams = {
        searchkeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.billingProviders?.length > 0 && {
          selectedBillingProviders: getMultiSelectFilterValueForApi(
            sliceState?.billingProviders
          ),
        }),
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };
      const renderingProviderParams = {
        searchkeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.renderingProviders?.length > 0 && {
          selectedRenderingProviders: getMultiSelectFilterValueForApi(
            sliceState?.renderingProviders
          ),
        }),

        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: getCurrentClientId(),
        }),
      };
      const drgCodeParams = {
        searchKeyword: "",
        start: 0,
        limit: 100,
        ...(sliceState?.DRGs?.length > 0 && {
          selectedDRGs: getMultiSelectFilterValueForApi(sliceState?.DRGs),
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate?.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate?.to,
        }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      await commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setClaimPaymentTabular_Data,
        "claimPayment",
        "GET_PAYMENT_835_TABULAR_DATA",
        false,
        "",
        ""
      );
      // console.log("2.1__compo--->>");

      dispatch(
        getClaimAllFiltersData_Call(
          cptParams,
          payerParams,
          reasonCodeParams,
          providerParams,
          billingProviderParams,
          renderingProviderParams,
          drgCodeParams,
          {
            isServiceLineAPI: true,
            isPayersAPI: true,
            isClaimStatusAPI: true,
            isReasonCodeAPI: true,
            isProviderAPI: true,
            isFillingIndicatorAPI: true,
            isBillingProviderAPI: true,
            isRenderingProviderAPI: true,
            isIPOPRemartCodeAPI: true,
            isCptCodeAPI: true,
            isGroupCodeAPI: true,
            isDrgAPI: true,
          }
        )
      );
    } catch (e) {
      console.log("error --->>", e);

      return false;
    }
  };

export const deleteClaimPaymentRequestCall = (claimId) => async (dispatch) => {
  try {
    const res = await common.deleteClaim(claimId);

    if (res?.data?.success) {
      dispatch(removeClaimDetailById(claimId));
      return res;
    } else {
      return res;
    }
  } catch (e) {
    // console.log("catch_e--->>", e);
  }
};

export const getClaimPaymentByIdCall = (claimId) => async (dispatch) => {
  const claim = await common.getClaimPaymentById(claimId);
  dispatch(claimPaymentDetailAction(claim.data.data));
  return claim.data.data;
};

// claim followUp api call
export const claimFollowUp_Call =
  (
    obj,
    setComment,
    setCommentDate,
    setLoadingClass,
    handleClose,
    targetFilter,
    loadData
  ) =>
  async (dispatch) => {
    try {
      setLoadingClass("loading");

      const res = await genericAxiosCall(
        `${serverRoutes?.CLAIM_837_FOLLOW_UP}`,
        "post",
        obj,
        ""
      );
      // console.log("res--->>", res);

      if (!res?.data?.success) {
        // console.log("res--->>", res);
        toast.error(res?.data?.message);
      } else {
        // toast.success(res?.data?.message);
        setComment("");
        // setCommentDate("");
        handleClose();

        // setting filter for getting latest data
        dispatch(
          setListingState({
            listing: targetFilter,
            value: {
              fetchLatest: true,
            },
          })
        );

        // api call for latest data
        loadData();
      }
    } catch (err) {
      console.log("err--->>", err);
    } finally {
      setLoadingClass("");
    }
  };
export const claimFollowUp_Call_Trigger =
  (obj, targetFilter, setLoadingClass, loadData) => async (dispatch) => {
    try {
      setLoadingClass("loading");

      const res = await genericAxiosCall(
        `${serverRoutes?.CLAIM_837_FOLLOW_UP}`,
        "post",
        obj,
        ""
      );
      // console.log("res--->>", res);

      if (!res?.data?.success) {
        // console.log("res--->>", res);
        toast.error(res?.data?.message);
      } else {
        // toast.success(res?.data?.message);

        // setting filter for getting latest data
        dispatch(
          setListingState({
            listing: targetFilter,
            value: {
              fetchLatest: true,
            },
          })
        );

        // api call for latest data
        // loadData();
      }
    } catch (err) {
      console.log("err--->>", err);
    } finally {
      setLoadingClass("");
    }
  };

// get claim followUp api call
export const getClaimFollowUpData_Call =
  (claimId, setLoadingClassGet) => async (dispatch) => {
    try {
      setLoadingClassGet("loading");

      const res = await genericAxiosCall(
        `${serverRoutes?.GET_CLAIM_837_FOLLOW_UP}${claimId}`,
        "get",
        "",
        ""
      );
      // console.log("res--->>", res);

      if (!res?.data?.success) {
        toast.error(res?.data?.message);
      } else {
        // toast.success(res?.data?.message);
        return res?.data;
      }
    } catch (err) {
      console.log("err--->>", err);
    } finally {
      setLoadingClassGet("");
    }
  };

export const getService_Payment_Information =
  (setLoadingClass1, claimRequest_Id) => async (dispatch, getState) => {
    try {
      if (typeof setLoadingClass1 === "function") {
        setLoadingClass1("data-loading");
      }
      let sliceStateUser = getState().pReducers.user;

      let sliceState = getState().npReducers.filters.newRemittanceDetail;
      let start = sliceState.pageNumber;
      // let limit = sliceState.pageSize;
      //

      let limit =
        sliceStateUser?.globalObjNoOfRecordInTable
          ?.newRemittanceDetailNoOfRecordInTable;

      let order = sliceState.order;
      let orderby = sliceState.orderBy;

      if (start >= 2) {
        let rec_val = (start - 1) * limit;
        start = rec_val;
      }
      if (start === 1) {
        start = 0;
      }

      const { data: response } = await common.getServicePaymentsFotRaDet(
        claimRequest_Id,
        start,
        limit,
        order,
        orderby
      );

      if (response.success) {
        dispatch(setNewRemittanceDetail_Data(response?.data));
      } else {
        dispatch(setNewRemittanceDetail_Data([]));
      }

      if (typeof setLoadingClass1 === "function") {
        setLoadingClass1("");
      }
    } catch (error) {
      // console.error("<<<Error fetching service payments:", error);
    } finally {
      // Ensure loading class is cleared
      if (typeof setLoadingClass1 === "function") {
        setLoadingClass1("");
      }
    }
  };

export const saveCustomColumnsClaimPayment =
  (obj, setLoadingClassSave) => async (dispatch, getState) => {
    try {
      setLoadingClassSave("loading");

      const res = await genericAxiosCall(
        `${serverRoutes?.SAVE_CLAIM_PAYMENT_CUSTOM_COLUMNS}`,
        "post",
        obj,
        ""
      );
      // console.log("res?.data--->>", res?.data);

      if (!res?.data?.success) {
        toast.error(res?.data?.message);
      } else {
        toast.success(res?.data?.message);
        return res?.data;
      }
    } catch (err) {
      console.log("err--->>", err);
    } finally {
      setLoadingClassSave("");
    }
  };

// get claim followUp api call
export const getCustomColumnsClaimPaymentLookupData_Call =
  (params, setLoadingClassGet) => async (dispatch) => {
    try {
      setLoadingClassGet("loading");

      const res = await genericAxiosCall(
        `${serverRoutes?.GET_CLAIM_PAYMENT_CUSTOM_COLUMNS_LOOKUP_DATA}`,
        "get",
        "",
        params
      );

      // console.log("res?.data--->>", res?.data);

      if (!res?.data?.success) {
        toast.error(res?.data?.message);
      } else {
        // toast.success(res?.data?.message);
        return res?.data;
      }
    } catch (err) {
      console.log("err--->>", err);
    } finally {
      setLoadingClassGet("");
    }
  };

// get claim followUp api call
export const getClaimPaymentCustomColumns_Call =
  (claimId) => async (dispatch) => {
    try {
      const res = await genericAxiosCall(
        `${serverRoutes?.GET_CLAIM_PAYMENT_CUSTOM_COLUMNS}`,
        "get",
        "",
        ""
      );
      // console.log("res?.data--->>", res?.data);

      if (!res?.data?.success) {
        // toast.error(res?.data?.message);
      } else {
        // toast.success(res?.data?.message);
        dispatch(setCustomColumnsClaimPayment(res?.data?.data));
        return res?.data;
      }
    } catch (err) {
      console.log("err--->>", err);
    } finally {
    }
  };
export const getClaimPaymentCustomColumns_Call_Common =
  () => async (dispatch, getState) => {
    try {
      // console.log("res?.data--->>", res?.data);
      const params = { timestamp: new Date().getTime() };
      const paramsTemp = { timestamp: new Date().getTime() };
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setClaimPaymentConfigData,
        "claimPaymentConfigFilter",
        "GET_CLAIM_PAYMENT_CUSTOM_COLUMNS",
        false,
        "",
        ""
      );
    } catch (err) {
      console.log("err--->>", err);
    } finally {
    }
  };

export const Config_CustomColumns_HideShow =
  (payload, handleCloseModal, setCustomColumnEnabled) =>
  async (dispatch, getState) => {
    try {
      const user2 = store.getState().pReducers.user;

      // console.log("Config_CustomColumns_HideShow--->>", payload);
      const res = await genericAxiosCall(
        serverRoutes?.SAVE_CUSTOM_COLUMNS_CONFIG,
        "post",
        payload,
        ""
      );

      if (res?.data?.success == true) {
        setCustomColumnEnabled(payload.EnableCustomColumn);
        handleCloseModal();

        //============= EnableCustomColumn settings =============
        let finalUserModifiedObj = {};

        finalUserModifiedObj = {
          ...user2,
          SubsCustomColumn: payload.EnableCustomColumn,
        };

        // console.log("finalUserModifiedObj--->>", finalUserModifiedObj);

        dispatch(userData(finalUserModifiedObj));

        //============= EnableCustomColumn settings =============

        return res;
      } else {
      }
    } catch (error) {
      console.log("error--->>", error);
    }
  };

// get claim followUp api call
export const getClaimPaymentHistory_Call =
  (claimId, setLoadingClass) => async (dispatch) => {
    try {
      setLoadingClass("loading");
      let obj = {
        claimpaymentid: claimId,
      };
      const res = await genericAxiosCall(
        `${serverRoutes?.GET_CLAIM_PAYMENT_HISTORY_DATA}`,
        "get",
        "",
        obj
      );
      // console.log("res?.data--->>", res?.data);

      if (!res?.data?.success) {
        // toast.error(res?.data?.message);
      } else {
        // toast.success(res?.data?.message);

        return res?.data?.data;
      }
    } catch (err) {
      console.log("err--->>", err);
    } finally {
      setLoadingClass("");
    }
  };

export default claimPaymentSlice.reducer;
